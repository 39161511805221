import checkIcon from "@iconify-icons/mdi/check";
import chevronDown from "@iconify-icons/mdi/chevron-down";
import trashCanOutline from "@iconify-icons/mdi/trash-can-outline";
import Icon from "@iconify/react";
import { Checkbox, Dropdown, Input, Spin } from "antd";
import { isEmpty, isString } from "lodash";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { If } from "react-extras";
import { AiFillFolder } from "react-icons/ai";
import RCButton from "src/components/lib/button/button";
import Divider from "src/components/lib/divider/divider";
import { permissionPresets, roleToFriendly } from "src/constants/permission_roles";
import UserRoles from "src/constants/roles";
import { useGetCampaigns, useGetCampaignTags } from "src/hooks/campaigns";
import { useCredentialsInOrg, usePermissionsInOrg } from "src/hooks/org";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { pluralize } from "src/lib/strings";
import { Presets } from "src/reducers/permissions/types";
import {
  CreatePermission,
  createPermission,
  IUpdatePermission,
  updatePermission,
} from "../../../action_managers/permissions";
import CustomizeRoleModal from "../../modals/customize_teamSeat_role";
import {
  AccessFormAction,
  AccessFormReducer,
  AccessFormState,
  AccessInitialState,
  calculateInitialValues,
  calculateRole,
  determineOverrides,
  getPermPresetsFromUserRole,
  presetRoleRanking,
} from "./team_seats_utilities";

/**
 * Helper mapping for feature access in access viewer
 */
interface AccessLogicMap {
  showOrgOwnerText: boolean;
  seeDefaultRoleSection: boolean;
  showNotSetSection: boolean;
  AddTeamMemberCTA: boolean;
  ResendInviteCTA: boolean;
  TrashCan: boolean;
  canSeeEditRolePermissions: boolean;
  canSeeViewRolePermissions: boolean;
  canEditSectionRolePermissions: boolean;
  canEditEntityPermissionDropdown: (entityUUID: string) => boolean;
}

interface IAccessViewer {
  credentialUUID: string;
  setShowDeleteTeamseat: (val: boolean) => void;
}

const AccessViewer = ({ credentialUUID, setShowDeleteTeamseat }: IAccessViewer) => {
  const dispatch = useReduxDispatch();

  const {
    isLoading: isUserLoading,
    user,
    currentRole: userRole,
  } = useSelectorTS((state) => state.user);

  // DATA FETCHING
  const { credentialsMapByUUID } = useCredentialsInOrg();
  const {
    permissionMapByKey,
    isLoading: isPermissionsLoading,
    permissions: allOrgPermissions,
    initialFetched: permissionsInitialFetch,
  } = usePermissionsInOrg();
  const { shows: showsData, isLoading: isShowsLoading } = useSelectorTS((state) => state?.shows);
  const { campaigns: campaignsData, isLoading: isCampaignsLoading } = useGetCampaigns();
  const campaignTagsData = useGetCampaignTags();

  // append item types during loading
  const shows: Record<string, any> = useMemo(() => {
    if (isShowsLoading || !showsData) return {};
    return Object.keys(showsData).reduce(
      (acc, showUUID) => ({
        [showUUID]: { ...showsData[showUUID], permissionType: "show" },
        ...acc,
      }),
      {}
    );
  }, [showsData, isShowsLoading]);

  const campaigns: Record<string, any> = useMemo(() => {
    if (isCampaignsLoading || !campaignsData) return {};
    return Object.keys(campaignsData).reduce(
      (acc, campaignUUID) => ({
        [campaignUUID]: { ...campaignsData[campaignUUID], permissionType: "campaign" },
        ...acc,
      }),
      {}
    );
  }, [campaignsData, isCampaignsLoading]);

  const campaignTags = useMemo(
    () => campaignTagsData.map((tag) => ({ ...tag, permissionType: "campaignTag" })),
    [campaignTagsData]
  );

  const orgUUID = user.uuid;
  const isCurrentUserOrgOwner =
    permissionMapByKey[`${user.credentialUUID}-${orgUUID}`]?.preset === permissionPresets.orgAdmin;
  const isSelectedOrgOwner =
    permissionMapByKey[`${credentialUUID}-${orgUUID}`]?.preset === permissionPresets.orgAdmin;
  const isSelectedCurrentUser = credentialUUID === user.credentialUUID;
  const currentDefaultRole =
    permissionMapByKey[`${credentialUUID}-${orgUUID}`]?.preset ?? permissionPresets.noAccess;

  const {
    firstName = "",
    lastName = "",
    email = "",
  } = credentialsMapByUUID?.[credentialUUID] || {};

  const isPermissionsForPodcasts = userRole === UserRoles.Creator || userRole === UserRoles.Admin;
  const entityList = isPermissionsForPodcasts
    ? Object.values(shows)
    : [...Object.values(campaigns), ...campaignTags];

  const inputSearchText = isPermissionsForPodcasts
    ? "Search Podcasts"
    : "Search Campaigns or Folders";

  const sectionRoles = getPermPresetsFromUserRole(userRole);

  /**
   * State
   */

  // Search text
  const [searchText, setSearchText] = useState("");

  // Access View State
  const [accessState, dispatchAccessState] = useReducer(AccessFormReducer, AccessInitialState);

  // Sets the current role section for customize role modal
  const [currentRole, setCurrentRole] = useState<Presets>(permissionPresets.noAccess);

  // Controls Customize role modal visibility and read/edit access
  const [modalState, setModalState] = useState({
    visible: false,
    viewOnly: false,
  });

  const notSetEntities = accessState.entities.filter((entity) => entity.currentRole === "notSet");

  // Helper function
  const getCurrentUserRole = useCallback(
    (entityUUID?: string) =>
      calculateRole(user.credentialUUID, orgUUID, permissionMapByKey, entityUUID),
    [user?.credentialUUID, orgUUID, permissionMapByKey]
  );

  const getSelectedUserRole = useCallback(
    (entityUUID?: string) => calculateRole(credentialUUID, orgUUID, permissionMapByKey, entityUUID),
    [credentialUUID, orgUUID, permissionMapByKey]
  );

  /**
   * Only see "Not Set" section in advertiser accounts. Org Owner will always see it. Non org owners will see it
   * when there are items available and the default role is not "No Access". Since we don't want users to be aware
   * of what they don't have access to.
   */
  const showNotSetSection = useMemo(() => {
    return (
      userRole === UserRoles.Advertiser &&
      (isCurrentUserOrgOwner ||
        (notSetEntities.length > 0 && currentDefaultRole !== permissionPresets.noAccess))
    );
  }, [userRole, isCurrentUserOrgOwner, notSetEntities?.length, currentDefaultRole]);

  /**
   * Handlers
   */
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    isString(event?.target?.value) && setSearchText(event?.target?.value);

  const handleUpdateDefaultRole = async (newDefaultRole: Presets) => {
    const updates: IUpdatePermission[] = [];

    const selectedUserOrgPermission = permissionMapByKey[`${credentialUUID}-${orgUUID}`];

    // take into account if there are overrides in permissions under the new default role.
    const newRoleOverrides = determineOverrides({
      credentialUUID,
      currentRole: newDefaultRole,
      allOrgPermissions,
    });

    if (!isEmpty(selectedUserOrgPermission)) {
      updates.push({
        ...selectedUserOrgPermission,
        overrides: newRoleOverrides,
        preset: newDefaultRole,
      });

      try {
        await dispatch(updatePermission(updates));
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    }
  };

  // Logic map, holds flags to determine what is seen or hidden
  const logicMap: AccessLogicMap = useMemo(() => {
    return {
      showOrgOwnerText: isSelectedOrgOwner,
      seeDefaultRoleSection: isCurrentUserOrgOwner,
      showNotSetSection,
      AddTeamMemberCTA: isCurrentUserOrgOwner,
      ResendInviteCTA: isCurrentUserOrgOwner,
      TrashCan:
        (isCurrentUserOrgOwner && !isSelectedCurrentUser) ||
        (!isCurrentUserOrgOwner && isSelectedCurrentUser),
      canSeeEditRolePermissions: isCurrentUserOrgOwner,
      canSeeViewRolePermissions: !isCurrentUserOrgOwner && isSelectedCurrentUser,
      canEditSectionRolePermissions: isCurrentUserOrgOwner,
      canEditEntityPermissionDropdown: (entityUUID: string) => {
        return (
          isCurrentUserOrgOwner ||
          (presetRoleRanking[getCurrentUserRole(entityUUID)] >= 5 &&
            presetRoleRanking[getSelectedUserRole(entityUUID)] < 5)
        );
      },
    };
  }, [
    isCurrentUserOrgOwner,
    isSelectedCurrentUser,
    isSelectedOrgOwner,
    permissionMapByKey,
    getCurrentUserRole,
    getSelectedUserRole,
    showNotSetSection,
  ]);

  // Reset Initial Values
  useEffect(() => {
    if (!isShowsLoading && !isPermissionsLoading && !isUserLoading) {
      const initialValues = calculateInitialValues(
        entityList,
        credentialUUID,
        user.uuid,
        permissionMapByKey,
        searchText
      );
      dispatchAccessState({ type: "reInitialize", formState: initialValues });
    }
  }, [
    isShowsLoading,
    isPermissionsLoading,
    isUserLoading,
    credentialUUID,
    searchText,
    permissionMapByKey,
  ]);

  const isPageLoading =
    isUserLoading || !permissionsInitialFetch || isShowsLoading || isCampaignsLoading;

  return (
    <div className="AccessInfo">
      <div className="AccessInfo-header p-as">
        <div className="AccessInfo-header-info">
          <div className="AccessInfo-header-info--fullname m-a0">
            <h1 className="m-b0">{firstName}</h1>
            <h1 className="m-b0">{lastName}</h1>
          </div>
          <p className="AccessInfo-header-info--email  m-a0">{`${email}`}</p>
        </div>
        <If condition={logicMap.TrashCan}>
          <div
            className="AccessInfo-header-trashcan m-la m-rxs pointer"
            onClick={() => setShowDeleteTeamseat(true)}>
            <Icon icon={trashCanOutline} />
          </div>
        </If>
      </div>
      <Divider marginTop={0} marginBottom={16} />
      <If condition={isPageLoading}>
        <div className="width-100 height-100 flex-row-container align-center justify-center">
          <Spin spinning={true} />
        </div>
      </If>
      <If condition={logicMap.showOrgOwnerText && !isPageLoading}>
        <div className="justify-self-center align-self-center m-va m-hxs">
          <h4 className="m-a0 m-bxs text-center">
            Org Owner has full access to all{" "}
            {isPermissionsForPodcasts ? "podcasts" : "campaigns and folders"} in the Org. Contact
            them for any account/permissions updates.
          </h4>
        </div>
      </If>
      <If condition={!logicMap.showOrgOwnerText && !isPageLoading}>
        <Input.Search
          id="searchShowRoles"
          className="m-bxs m-hxs"
          style={{ width: "auto" }}
          placeholder={inputSearchText}
          onChange={handleSearchChange}
          allowClear={true}
        />
        <form className="AccessInfo-form">
          {sectionRoles.map((sectionRole) => {
            const filteredByRole = accessState.entities.filter(
              (item) => item.currentRole === sectionRole
            );
            return (
              <React.Fragment key={sectionRole}>
                <If
                  condition={
                    sectionRole !== permissionPresets.noAccess ||
                    isCurrentUserOrgOwner ||
                    (sectionRole === permissionPresets.noAccess && !isSelectedCurrentUser)
                  }>
                  <AccessSection
                    selectedCredUUID={credentialUUID}
                    entities={filteredByRole}
                    sectionRole={sectionRole}
                    roleToFriendly={roleToFriendly}
                    logicMap={logicMap}
                    dispatchAccessState={dispatchAccessState}
                    onViewRolePermissionsClick={() => {
                      setCurrentRole(sectionRole);
                      setModalState({
                        viewOnly: true,
                        visible: true,
                      });
                    }}
                    onCustomizeRoleClick={() => {
                      setCurrentRole(sectionRole);
                      setModalState({
                        viewOnly: false,
                        visible: true,
                      });
                    }}
                  />
                  <Divider marginTop={0} marginBottom={0} />
                </If>
              </React.Fragment>
            );
          })}

          <If condition={logicMap.seeDefaultRoleSection}>
            <Divider marginTop={0} marginBottom={0} />
            <div className="flex-row-container align-center justify-space-between m-vxxs p-ls p-rl p-vxxs">
              <p className="bold teamSeatsPage-text--4 m-a0">
                Assign the following role to everything else in the organization
              </p>
              <RoleDropDown
                selectedRole={currentDefaultRole}
                type={2}
                allRoles={sectionRoles}
                roleToFriendly={roleToFriendly}
                onOk={(newDefaultRole) => {
                  return handleUpdateDefaultRole(newDefaultRole);
                }}
              />
            </div>
          </If>

          <If condition={logicMap.showNotSetSection}>
            <>
              <Divider marginTop={0} marginBottom={0} />
              <Divider marginTop={0} marginBottom={0} />
              <AccessSection
                selectedCredUUID={credentialUUID}
                entities={notSetEntities}
                sectionRole={"notSet"}
                roleToFriendly={roleToFriendly}
                logicMap={logicMap}
                dispatchAccessState={dispatchAccessState}
              />
            </>
          </If>
        </form>
      </If>
      <CustomizeRoleModal
        visible={modalState.visible}
        setVisible={(visible: boolean) => {
          setModalState((prev) => ({
            viewOnly: prev.viewOnly,
            visible,
          }));
        }}
        entities={accessState.entities}
        credentialUUID={credentialUUID}
        currentRole={currentRole}
        firstName={firstName}
        lastName={lastName}
        viewOnly={modalState.viewOnly}
      />
    </div>
  );
};

interface IAccessSection {
  selectedCredUUID: string;
  entities: AccessFormState["entities"];
  sectionRole: Presets;
  roleToFriendly: Record<Presets, string>;
  logicMap: AccessLogicMap;
  dispatchAccessState: React.Dispatch<AccessFormAction>;
  onCustomizeRoleClick?: () => void;
  onViewRolePermissionsClick?: () => void;
}

const AccessSection = ({
  selectedCredUUID,
  entities,
  sectionRole,
  roleToFriendly,
  logicMap,
  dispatchAccessState,
  onCustomizeRoleClick,
  onViewRolePermissionsClick,
}: IAccessSection) => {
  const dispatch = useReduxDispatch();

  const { currentRole } = useSelectorTS((state) => state.user);
  const { permissionMapByKey, permissions: allOrgPermissions } = usePermissionsInOrg();
  const sectionRoles = getPermPresetsFromUserRole(currentRole);

  /**
   * Handlers
   */
  const updateAllCheckboxes = (checked: boolean) => {
    entities.forEach((entity) => {
      entity.checked !== checked &&
        dispatchAccessState({ type: "updateEntityCheck", entityUUID: entity.uuid, checked });
    });
  };

  const handleUpdateEntities = async (entities: AccessFormState["entities"], newRole: Presets) => {
    const updates: IUpdatePermission[] = [];
    const additions: CreatePermission[] = [];

    entities.forEach((entity) => {
      const permkey = `${selectedCredUUID}-${entity.uuid}`;
      const permission = permissionMapByKey[permkey];

      // In case new role is the default role which has overrides set up
      const newRoleDefaultOverrides = determineOverrides({
        credentialUUID: selectedCredUUID,
        currentRole: newRole,
        allOrgPermissions,
      });

      if (permission) {
        updates.push({
          ...permission,
          overrides: newRoleDefaultOverrides,
          preset: newRole,
        });
      } else {
        // Permission Object Does Not exist/ Need to Create instead of Update
        additions.push({
          credentialUUID: selectedCredUUID,
          objectUUID: entity.uuid,
          objectType: entity.type,
          overrides: newRoleDefaultOverrides,
          preset: newRole,
        });
      }
    });

    try {
      if (updates?.length > 0) {
        await dispatch(updatePermission(updates));
      }

      if (additions?.length > 0) {
        await dispatch(createPermission(additions));
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const entitiesChecked = entities.filter((entity) => entity.checked);
  const numberChecked = entitiesChecked.length;
  const selectAll = numberChecked === entities.length;
  const isPermissionsForPodcasts =
    currentRole === UserRoles.Creator || currentRole === UserRoles.Admin;
  const entityTypeString = isPermissionsForPodcasts ? "Podcast" : "Campaign/Folder";

  return (
    <div className="m-hs p-vs">
      <div className="flex-row-container align-center justify-space-between">
        <h4 className="m-a0 teamSeatsPage-text--3">{roleToFriendly[sectionRole]}</h4>
        <span className="m-lxxs m-ra teamSeatsPage-text--4">{`(${entities?.length} ${pluralize(
          entityTypeString,
          entities.length
        )})`}</span>

        <If
          condition={
            !!onCustomizeRoleClick &&
            logicMap.canSeeEditRolePermissions &&
            sectionRole !== permissionPresets.noAccess &&
            entities?.length > 0
          }>
          <button type="button" onClick={onCustomizeRoleClick} className="btn btn-link">
            Customize Role
          </button>
        </If>
        <If
          condition={
            !!onViewRolePermissionsClick &&
            logicMap.canSeeViewRolePermissions &&
            sectionRole !== permissionPresets.noAccess
          }>
          <button type="button" onClick={onViewRolePermissionsClick} className="btn btn-link">
            View Permissions
          </button>
        </If>
      </div>
      <p>
        {sectionRole === "notSet" && (
          <p className="small">
            Any campaigns without explicit permissions will follow the permissions on its containing
            folder. If there are no permissions set on the folder, the default permission will be
            used.
          </p>
        )}
      </p>

      <If condition={entities?.length > 0 && logicMap.canEditSectionRolePermissions}>
        <div
          className="flex-row-container align-center justify-space-between m-txxs"
          style={{ minHeight: "35px" }}>
          <Checkbox
            className="m-ra"
            checked={selectAll}
            onChange={() => {
              updateAllCheckboxes(!selectAll);
            }}>
            <span className="teamSeatsPage-text--4 ">
              {numberChecked === 0
                ? "Select All"
                : `${numberChecked} ${pluralize(entityTypeString, numberChecked)} Selected`}
            </span>
          </Checkbox>
          <If condition={numberChecked >= 2}>
            <RoleDropDown
              selectedRole={sectionRole}
              allRoles={sectionRoles}
              roleToFriendly={roleToFriendly}
              onOk={(newRole) => handleUpdateEntities(entitiesChecked, newRole)}
            />
          </If>
        </div>
      </If>

      <div>
        {entities.map((entity) => {
          const { name, imgUrl, checked, specificType } = entity;
          const showFolderIcon = specificType === "campaignTag";

          return (
            <div
              key={entity.uuid}
              className="AccessInfo-border flex-row-container align-center justify-space-between m-bxxs p-hxs p-vxxs">
              <If condition={!logicMap.canEditSectionRolePermissions}>
                <div className="flex-row-container align-center justify-center">
                  {imgUrl && <img className="AccessInfo-showImg m-rxxs" src={imgUrl} alt={name} />}
                  {showFolderIcon && (
                    <AiFillFolder style={{ height: 11, width: 11, marginRight: "5px" }} />
                  )}
                  <span className="teamSeatsPage-text--4 bold line-clamp-1">{name}</span>
                </div>
              </If>

              <If condition={logicMap.canEditSectionRolePermissions}>
                <Checkbox
                  className="flex-row-container align-center justify-center ant-checkbox-height-fix "
                  style={{ display: "flex", alignItems: "center" }}
                  onChange={() => {
                    dispatchAccessState({
                      type: "updateEntityCheck",
                      entityUUID: entity.uuid,
                      checked: !checked,
                    });
                  }}
                  checked={checked}>
                  <div className="flex-row-container align-center justify-center">
                    {imgUrl && (
                      <img className="AccessInfo-showImg m-rxxs" src={imgUrl} alt={name} />
                    )}
                    {showFolderIcon && (
                      <AiFillFolder style={{ height: 18, width: 18, marginRight: "5px" }} />
                    )}
                    <span className="teamSeatsPage-text--4 bold line-clamp-1 m-txxxs">{name}</span>
                  </div>
                </Checkbox>
              </If>

              <If condition={logicMap?.canEditEntityPermissionDropdown?.(entity.uuid)}>
                <RoleDropDown
                  selectedRole={sectionRole}
                  allRoles={sectionRoles}
                  roleToFriendly={roleToFriendly}
                  type={2}
                  onOk={(newRole) => handleUpdateEntities([entity], newRole)}
                />
              </If>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface IRoleDropdown {
  selectedRole: Presets; // Currently selected Role
  allRoles: Presets[]; // All Available role options
  roleToFriendly: Partial<Record<Presets, string>>;
  open?: boolean; // Conditional Controlled Open Component
  type?: number; // Different styled button
  dropdownText?: string; // If different from current role selected
  dropdownBtnText?: string; // Text shown in the component within btn/select dropdown
  onRoleChange?: (newRole: Presets) => void;
  onVisibleChange?: (visible: boolean) => void;
  onOk?: (newRole: Presets) => Promise<any> | any;
}

const RoleDropDown = (props: IRoleDropdown) => {
  const {
    selectedRole,
    allRoles,
    roleToFriendly,
    onOk,
    open = false,
    type = 1,
    dropdownText,
    dropdownBtnText = "Ok",
    onRoleChange,
    onVisibleChange,
  } = props;

  /**
   * Role Dropdown needs functionality of allowing user to change the selected role without immediately
   * closing the dropdown. internal controlled state for dropdown open is needed.
   */
  const [dropdownOpen, setDropdownOpen] = useState(open);

  /**
   * Role Dropdown needs functionality of allowing user to change the selected role without immediately
   * closing the dropdown or changing the role to the selected role until the ok button is pressed, hence,
   * temporary internal state is needed for currently selected role
   */
  const [tempSelect, setTempSelect] = useState(selectedRole);

  // State to control how to close dropdown on click outside
  const [allowBtnClose, setAllowBtnClose] = useState(true);
  const [allowBlurClose, setAllowBlurClose] = useState(false);
  const [allowBtnClick, setAllowBtnClick] = useState(true);

  // Incase controlled component needed, matches open props provided by parent
  useEffect(() => {
    setDropdownOpen(open);
  }, [open]);

  // Syncing changes to provided selected role and internal selected role state
  useEffect(() => {
    selectedRole !== tempSelect && setTempSelect(selectedRole);
  }, [selectedRole]);

  let button;
  switch (type) {
    case 1:
      button = (
        <button
          type="button"
          onBlur={() => allowBtnClose && setDropdownOpen(false)}
          className="btn btn-secondary m-a0 p-v0 p-hxxs teamSeatsPage-text--5"
          onClick={() =>
            allowBtnClick ? setDropdownOpen((prev) => !prev) : setAllowBtnClick(true)
          }>
          {dropdownText || roleToFriendly[selectedRole]}
        </button>
      );
      break;

    case 2:
      // Plain styled button with arrow
      button = (
        <RCButton
          size="small"
          onBlur={() => allowBtnClose && setDropdownOpen(false)}
          className="p-hxs teamSeatsPage-text--5 flex-row-container align-center justify-space-between"
          style={{ display: "flex" }}
          onClick={() =>
            allowBtnClick ? setDropdownOpen((prev) => !prev) : setAllowBtnClick(true)
          }>
          {dropdownText || roleToFriendly[selectedRole]}{" "}
          <Icon className="m-lxs" icon={chevronDown} />
        </RCButton>
      );
      break;

    default:
      // Red bordered button
      button = (
        <button
          type="button"
          onBlur={() => allowBtnClose && setDropdownOpen(false)}
          className="btn btn-secondary m-a0 p-v0 p-hxxs teamSeatsPage-text--5"
          onClick={() =>
            allowBtnClick ? setDropdownOpen((prev) => !prev) : setAllowBtnClick(true)
          }>
          {dropdownText || roleToFriendly[selectedRole]}
        </button>
      );
  }

  const items = [
    ...allRoles.map((roleOption) => ({
      key: roleOption,
      title: roleToFriendly[roleOption],
      label: (
        <div className="flex-row-container justify-start">
          <span className="m-rxxxs" style={{ minWidth: "30px" }}>
            <If condition={roleOption === tempSelect}>
              <Icon icon={checkIcon} color="#EA404D" />
            </If>
          </span>
          <span>{roleToFriendly[roleOption]}</span>
        </div>
      ),
    })),
    {
      key: "button" as any, // Need Any since adding a stand alone bottom item to hold a button,
      title: "button",
      label: (
        <div>
          <button
            type="button"
            className="btn btn-primary m-a0 p-vxxxs p-hxxs"
            style={{ minWidth: "50px" }}
            onClick={() => {
              onOk?.(tempSelect);
              !open && tempSelect !== selectedRole && setTempSelect(selectedRole);
              setDropdownOpen(false);
            }}>
            {dropdownBtnText}
          </button>
        </div>
      ),
    },
  ];

  return (
    <Dropdown
      open={dropdownOpen}
      onOpenChange={(open) => {
        !open && tempSelect !== selectedRole && setTempSelect(selectedRole);
        onVisibleChange?.(open);
      }}
      placement="bottomRight"
      trigger={["click"]}
      menu={{
        selectable: true,
        selectedKeys: [tempSelect],
        style: {
          minWidth: "150px",
        },
        onMouseEnter: () => {
          allowBtnClose && setAllowBtnClose(false);
          allowBlurClose && setAllowBlurClose(false);
        },
        onMouseLeave: () => {
          allowBtnClose || setAllowBtnClose(true);
          allowBlurClose || setAllowBlurClose(true);
        },
        onBlur: () => {
          allowBlurClose && setDropdownOpen(false);
          allowBtnClick || setAllowBtnClick(true);
        },
        onSelect: ({ key }) => {
          if (allRoles.includes(key as any)) {
            onRoleChange?.(key as (typeof allRoles)[number]);
            setTempSelect(key as (typeof allRoles)[number]);
            setAllowBtnClick(false);
          }
        },
        items: items,
      }}>
      {button}
    </Dropdown>
  );
};

export default AccessViewer;
