import { useEffect, useRef } from "react";
import { getEpisode } from "src/actions/episodes";
import { useReduxDispatch, useSelectorTS } from "./redux-ts";
import { isUUID } from "src/lib/uuid";

type useEpisodeConfig = {
  episodeUUID?: string;
};

const MAX_ATTEMPTS = 5;

export const useEpisode = ({ episodeUUID }: useEpisodeConfig) => {
  const { isLoading, episodeByUUID } = useSelectorTS((state) => state.episodes);
  const isUUIDString = isUUID(episodeUUID);
  const currentEpisode =
    typeof episodeUUID === "string" && isUUIDString ? episodeByUUID?.[episodeUUID] : undefined;
  const dispatch = useReduxDispatch();
  const fetchAttempts = useRef<number>(1);

  // Reset fetch attempts counter on episodeUUID change
  useEffect(() => {
    fetchAttempts.current = 1;
  }, [episodeUUID]);

  useEffect(() => {
    if (episodeUUID && isUUIDString && fetchAttempts.current < MAX_ATTEMPTS) {
      if (isLoading) {
        // Wait till it finishes
      } else {
        // Currently not loading
        if (!currentEpisode) {
          // Current episode is not available in redux state, must fetch
          fetchAttempts.current++;
          dispatch(getEpisode(episodeUUID));
        }
      }
    }
  }, [isLoading, episodeUUID, currentEpisode]);

  return { episode: currentEpisode, isLoading: isLoading || !currentEpisode };
};
