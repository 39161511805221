import { Button, Col, Popover, Row, Switch } from "antd";
import get from "lodash/get";
import { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { classNames } from "react-extras";
import { useParams } from "react-router-dom";
import { copyToClipboard } from "redcircle-lib";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import RCButton from "src/components/lib/button/button";
import InfoTooltip from "src/components/lib/info";
import { showSuccess } from "../../../actions/app";
import { useReduxDispatch, useSelectorTS } from "../../../hooks/redux-ts";
import { getMegapodURL, getPodcacheURL, getPricingPageURL } from "../../../lib/config";
import { shouldShowTierPerm } from "../../../lib/feature_flag";
import { minimumAllowedTier } from "../../../lib/tier-utils";
import { tierLevel } from "../../../reducers/types";
import ExternalLink from "../../lib/external_link";
import ProductTourPopover from "../../lib/product_tour_popover/product_tour_popover";
import "./show_embed_page.scss";
import ShowPageWrapper from "./show_page_wrapper";

interface Params {
  showUUID: string;
}

const settings = {
  THEME: "theme",
  COLOR: "color",
} as const;

const themes = {
  LIGHT: "light",
  DARK: "dark",
  BLURRED: "blurred image",
} as const;

const ShowEmbedPage = () => {
  const dispatch = useReduxDispatch();
  const params = useParams<Params>();
  const showUUID = params.showUUID;
  const currentShow = useSelectorTS((state) => get(state.shows.shows, showUUID));
  const { tier } = useSelectorTS((state) => state?.user);

  const [currentSetting, setCurrentSetting] = useState<(typeof settings)[keyof typeof settings]>(
    settings.THEME
  );
  const [currentTheme, setCurretTheme] = useState<(typeof themes)[keyof typeof themes]>(
    themes.LIGHT
  );
  const [color, setColor] = useState("#FFFFFF");
  const [showPopOver, setShowPopOver] = useState(true);

  // Tier Logic
  const allowLightThemeCopy = minimumAllowedTier(tierLevel.growth, tier, true);
  const allowThemeOrColorCustomize = minimumAllowedTier(tierLevel.pro, tier, true);
  const allowCopyBtn = () => {
    if (currentSetting === settings.THEME) {
      if (currentTheme === themes.LIGHT && allowLightThemeCopy) {
        return true;
      } else if (allowThemeOrColorCustomize) {
        return true;
      } else {
        return false;
      }
    } else if (currentSetting === settings.COLOR) {
      if (allowThemeOrColorCustomize) {
        return true;
      } else {
        return false;
      }
    }
  };

  let bottomText = (
    <>
      Customize your podcast player and copy the code to embed it on your website.
      <ExternalLink
        href="https://support.redcircle.com/embedding-your-podcast-on-another-website"
        className="showEmbedPage__helpText--link">
        {" "}
        <strong>Learn More</strong>
      </ExternalLink>
    </>
  );

  if (shouldShowTierPerm()) {
    switch (tier?.level) {
      case tierLevel.core:
        bottomText = (
          <>
            The show-level web player is available starting from the RedCircle Growth Plan.
            <ExternalLink
              href={`${getMegapodURL()}pricing?toApp=1`}
              className="showEmbedPage__helpText--link">
              {" "}
              <br />
              {`Upgrade Your Plan >`}
            </ExternalLink>
          </>
        );
        break;
      case tierLevel.growth:
        bottomText = (
          <>
            You have access to the Light Mode version of the show-level web player in your current
            plan. Additional customization of the show-level web player is available starting from
            the RedCircle Pro Plan.
            <ExternalLink
              href={`${getMegapodURL()}pricing?toApp=1`}
              className="showEmbedPage__helpText--link">
              {" "}
              <br />
              {`Upgrade Your Plan >`}
            </ExternalLink>
          </>
        );
        break;
      case tierLevel.pro:
      case tierLevel.enterprise:
        bottomText = (
          <>
            Customize your podcaster player and copy the code to embed it on your website.
            <ExternalLink
              href="https://support.redcircle.com/embedding-your-podcast-on-another-website"
              className="showEmbedPage__helpText--link">
              {" "}
              <strong>Learn More</strong>
            </ExternalLink>
          </>
        );
        break;
    }
  }

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const title = currentShow?.title ? `Embed ${currentShow?.title}` : "Embed Show";

  const updateTheme = (theme: (typeof themes)[keyof typeof themes]) => {
    if (currentSetting === settings.THEME && Object.values(themes).some((el) => el === theme)) {
      setCurretTheme(theme);
    }
  };

  const handleColorChange = ({ hex }: { hex: string }) => {
    setColor(hex);
  };

  const getURL = (direct = false) => {
    let queryString = "?";
    switch (currentSetting) {
      case settings.THEME:
        switch (currentTheme) {
          case themes.LIGHT:
            queryString += "theme=light";
            break;
          case themes.DARK:
            queryString += "theme=dark";
            break;
          case themes.BLURRED:
            queryString += "theme=blurred";
            break;
          default:
            queryString = "";
        }
        break;
      case settings.COLOR:
        queryString += `bgColor=${encodeURIComponent(color)}`;
        break;
      default:
        queryString = "";
    }

    return direct
      ? `${getMegapodURL()}embedded-show-webplayer/${showUUID}${queryString}`
      : `${getPodcacheURL()}embedded-show-player/sh/${showUUID}${queryString}`;
  };

  const getCopyPasteHTML = () => {
    return `<script async defer onload="redcircleShowIframe();" src="${getURL()}"></script>
      <div class="${`redcirclePlayer-${showUUID}`}" style="height: auto;"></div>
      <style>
      .redcircle-link:link {
          color: #ea404d;
          text-decoration: none;
      }
      .redcircle-link:hover {
          color: #ea404d;
      }
      .redcircle-link:active {
          color: #ea404d;
      }
      .redcircle-link:visited {
          color: #ea404d;
      }
  </style>
  <p style="margin-top:3px;margin-left:11px;font-family: sans-serif;font-size: 10px; color: gray;">Powered by <a class="redcircle-link" href="https://redcircle.com?utm_source=rc_embedded_player&utm_medium=web&utm_campaign=embedded_v1">RedCircle</a></p>`;
  };

  const handleCopyToClipboard = () => {
    const html = getCopyPasteHTML();
    copyToClipboard(html);
    dispatch(showSuccess(`Copied HTML to clipboard`));
  };

  // Scroll to top on page load.
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // On web player settings change, update url to the iframe, this method
  // stops iframe url reload from interfering with parent browser history.
  useEffect(() => {
    if (iframeRef?.current?.contentWindow) {
      iframeRef.current.contentWindow.location.replace(getURL(true));
    }
  }, [currentSetting, currentTheme, color]);

  return (
    <ShowPageWrapper show={currentShow}>
      <Breadcrumbs
        crumbs={[
          { path: "/shows", name: "All Podcasts" },
          { path: `/shows/${showUUID}`, name: currentShow?.title },
          { path: `/shows/${showUUID}/embed-show`, name: "Embed Podcast Player", active: true },
        ]}
      />

      <Row>
        <ShowPageWrapper.Sidebar />
        <ShowPageWrapper.Body>
          <ShowPageWrapper.Header title={title} />

          <div className="showEmbedPage">
            <Row>
              <Col xs={24} className="showEmbedPage__row">
                <div className="showEmbedPage__themes">
                  <span className="showEmbedPage__themes--text">Theme</span>
                  <Switch
                    className="showEmbedPage__themes--switch"
                    checked={currentSetting === settings.THEME}
                    onClick={() =>
                      setCurrentSetting((prev) =>
                        prev === settings.THEME ? settings.COLOR : settings.THEME
                      )
                    }
                  />
                </div>

                <div className="showEmbedPage__bgcolor">
                  <span className="showEmbedPage__bgcolor--text">Color</span>
                  <Switch
                    className="showEmbedPage__bgcolor--switch"
                    checked={currentSetting === settings.COLOR}
                    onClick={() =>
                      setCurrentSetting((prev) =>
                        prev === settings.THEME ? settings.COLOR : settings.THEME
                      )
                    }
                  />
                  {showPopOver && (
                    <span className="showEmbedPage__bgcolor--popover">
                      <ProductTourPopover
                        index={1}
                        currentIndex={1}
                        className="showEmbedPage__bgcolor--popover-pop"
                        style={{ marginTop: "-190px", marginLeft: "-149px" }}>
                        <div style={{ width: "218px" }} className="flex-column-container">
                          <span>
                            You can also toggle on <b>color</b> to customize your personal theme
                          </span>
                          <RCButton
                            type="primary"
                            className="m-la m-txxs"
                            onClick={() => setShowPopOver(false)}>
                            Ok
                          </RCButton>
                        </div>
                      </ProductTourPopover>
                    </span>
                  )}
                </div>
                <div className="showEmbedPage__btnGroup">
                  <button
                    disabled={currentSetting !== settings.THEME}
                    onClick={() => updateTheme(themes.LIGHT)}
                    className={classNames("showEmbedPage__btnGroup--btn", {
                      ["btnActive"]: currentTheme === themes.LIGHT,
                    })}>
                    Light
                  </button>
                  <button
                    disabled={currentSetting !== settings.THEME}
                    onClick={() => updateTheme(themes.DARK)}
                    className={classNames("showEmbedPage__btnGroup--btn", {
                      btnActive: currentTheme === themes.DARK,
                    })}>
                    Dark
                  </button>
                  <button
                    disabled={currentSetting !== settings.THEME}
                    onClick={() => updateTheme(themes.BLURRED)}
                    className={classNames("showEmbedPage__btnGroup--btn", {
                      ["btnActive"]: currentTheme === themes.BLURRED,
                    })}>
                    Blurred Background
                  </button>
                </div>
                <div className="colorPicker">
                  <Popover
                    placement="bottomRight"
                    trigger="click"
                    content={
                      <div className={classNames("colorPicker__container")}>
                        <SketchPicker
                          color={color}
                          onChange={handleColorChange}
                          onChangeComplete={handleColorChange}
                        />
                      </div>
                    }>
                    <Button
                      type="text"
                      disabled={currentSetting !== settings.COLOR}
                      className={classNames("colorPicker__btn", {
                        pickerDisabled: currentSetting !== settings.COLOR,
                      })}>
                      <div className="flex-row-container">
                        <div
                          className="colorPicker__btn--color"
                          style={{ backgroundColor: color }}
                        />
                        <span className="colorPicker__btn--text">{color}</span>
                      </div>
                    </Button>
                  </Popover>
                </div>
                <div className="showEmbedPage__vl" />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <span className="showEmbedPage__title">
                  webplayer preview
                  <InfoTooltip
                    direction="top"
                    helpText="The player’s width will be auto responsive depending on your window size."
                    style={{
                      color: "#e5e5e5",
                    }}
                  />
                </span>
                <div id="showEmbedPage__player" className="showEmbedPage__player">
                  <iframe
                    title="RedCircle Embedded Player"
                    ref={iframeRef}
                    allow="clipboard-read; clipboard-write"
                    style={{
                      border: "none",
                      maxWidth: "100%",
                      width: "1px",
                      minWidth: "100%",
                      height: "100%",
                    }}
                    seamless
                    scrolling="no"
                  />
                </div>
                <div className="showEmbedPage__copyBtn">
                  {allowCopyBtn() ? (
                    <RCButton
                      type="primary"
                      size="large"
                      className="m-ym"
                      onClick={handleCopyToClipboard}>
                      Copy HTML to clipboard
                    </RCButton>
                  ) : (
                    <RCButton
                      type="primary"
                      size="large"
                      className="m-ym"
                      onClick={() => getPricingPageURL()}>
                      Upgrade Your Plan to Copy HTML
                    </RCButton>
                  )}
                </div>
                <span className="showEmbedPage__helpText">{bottomText}</span>
              </Col>
            </Row>
          </div>
        </ShowPageWrapper.Body>
      </Row>
    </ShowPageWrapper>
  );
};

export default ShowEmbedPage;
