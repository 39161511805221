import TargetIcon from "@iconify/icons-mdi/target";
import { Icon } from "@iconify/react";
import { Button, Divider, Modal, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { isEmpty } from "lodash";
import { PeaksInstance } from "peaks.js";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { showWarning } from "src/actions/app";
import { hideModal } from "src/actions/modal";
import { getMarkersByEpisodeUUID, updateMarkers } from "src/action_managers/audio_management";
import LoadingButton from "src/components/forms/loading_button/loading_button";
import RCButton from "src/components/lib/button";
import ContextMenu from "src/components/lib/context_menu";
import ExternalLink from "src/components/lib/external_link";
import InfoTooltip from "src/components/lib/info";
import { oneLevelUp } from "src/components/lib/routing";
import TimeFormatter from "src/components/lib/time_formatter";
import WaveForm from "src/components/lib/waveform";
import { permissionTypes } from "src/constants/permission_roles";
import { useGetAudioBlocks } from "src/hooks/audio_blocks";
import { useEpisode } from "src/hooks/episodes";
import useGetMarkers from "src/hooks/markers";
import { useMediaFile } from "src/hooks/mediaFiles";
import { useReduxDispatch } from "src/hooks/redux-ts";
import { useShow } from "src/hooks/shows";
import { useCanAccess } from "src/lib/permissions";
import { newUUID } from "src/lib/uuid";
import { Marker, MarkerPosition } from "src/reducers/markers/types";
import { MediaFileConversionState } from "src/reducers/media_file_upload/types";
import classes from "./insertion_points_modal.module.scss";

type TableRecord = {
  key: string;
  offsetMilliSeconds: number;
  title: string;
  markerLabeltext: string;
  position: MarkerPosition;
  audioBlockUUID: string;
  currentAudioBlockName: string;
  limit: number;
  spots: number;
};

type NewMarker = Pick<
  Marker,
  "episodeUUID" | "offsetMilliSeconds" | "audioBlockUUID" | "uuid" | "position" | "createdAt"
> & { isNewMarker: true };

type DeletedMarker = Marker & { delete: true };

type UpdatedMarker = Marker & { updated: true };

type LocalStateMarker = Marker | UpdatedMarker | NewMarker | DeletedMarker;

const UNASSIGNED_AUDIOBLOCK = "UNASSIGNED_AUDIOBLOCK";
const FiveMinutes = 1000 * 60 * 5;
const TwentyMinutes = 1000 * 60 * 20;

const formatPosition = (position: MarkerPosition, type: "table" | "marker") => {
  const formatTable: Record<MarkerPosition, string> = {
    MIDROLL: "Mid Roll",
    POSTROLL: "Post Roll",
    PREROLL: "Pre Roll",
  };

  const formatMarker: Record<MarkerPosition, string> = {
    MIDROLL: "Mid",
    POSTROLL: "Post",
    PREROLL: "Pre",
  };

  switch (type) {
    case "table":
      return formatTable[position];
    case "marker":
      return formatMarker[position];
    default:
      return formatTable[position];
  }
};

const cleanLocalMarkers = (localMarkers: any[]) => {
  const changedMarkers = [...localMarkers]
    .map((item) => ({ ...item, offsetMilliSeconds: Math.floor(item?.offsetMilliSeconds) }))
    .filter((marker) => marker?.delete || marker?.isNewMarker || marker?.updated)
    .filter((marker) => {
      if (marker?.delete) {
        if (marker?.isNewMarker) {
          return false;
        }
      }

      return true;
    });

  return changedMarkers.map((marker) => {
    const newMarker = { ...marker };

    if (newMarker?.isNewMarker) {
      delete newMarker.uuid;
      delete newMarker.isNewMarker;
    }

    if (newMarker?.updated) {
      if (typeof newMarker?.offsetBytes === "number") {
        delete newMarker.offsetBytes;
      }
      delete newMarker.updated;
    }

    if (newMarker.audioBlockUUID === UNASSIGNED_AUDIOBLOCK) {
      newMarker.audioBlockUUID = "";
    }

    return newMarker;
  });
};

const calculatePositionFromTime = (timeInMilliSeconds: number, TotalDurationInMilliSeconds = 0) => {
  if (TotalDurationInMilliSeconds <= TwentyMinutes) {
    if (timeInMilliSeconds <= Math.floor(TotalDurationInMilliSeconds * 0.1)) {
      return MarkerPosition.PREROLL;
    } else if (timeInMilliSeconds >= Math.floor(TotalDurationInMilliSeconds * 0.9)) {
      return MarkerPosition.POSTROLL;
    } else {
      return MarkerPosition.MIDROLL;
    }
  }

  if (timeInMilliSeconds < FiveMinutes) {
    return MarkerPosition.PREROLL;
  } else if (timeInMilliSeconds > TotalDurationInMilliSeconds - FiveMinutes) {
    return MarkerPosition.POSTROLL;
  } else {
    return MarkerPosition.MIDROLL;
  }
};

const InsertionPointsModal = () => {
  const dispatch = useReduxDispatch();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{ showUUID: string; episodeUUID: string }>([
    "/shows/:showUUID/ep/:episodeUUID/insertion-points",
    "/dynamic-insertion/insertion-points/episodes/:episodeUUID",
  ]);

  if (!match?.params) return null;

  const { showUUID, episodeUUID } = match?.params;
  const [isVisible, setIsVisible] = useState(true);

  const { episode, isLoading: episodeIsLoading } = useEpisode({ episodeUUID });
  const { show } = useShow({ showUUID: showUUID || episode?.showUUID });

  const canEditMarkers = useCanAccess(permissionTypes.editMarkers, show?.uuid);

  const episodeHasAudio =
    !episodeIsLoading &&
    typeof episode?.contentMediaFileUUID === "string" &&
    episode?.contentMediaFileUUID?.length > 0;

  const { mediaFile, isLoading: isMediaFileLoading } = useMediaFile({
    mediaFileUUID: episode?.contentMediaFileUUID,
    episodeUUID,
  });
  const { audioBlocks } = useGetAudioBlocks();
  const { markers, markersByEpisodeUUID, isMarkersLoading } = useGetMarkers({ episodeUUID });

  // State
  const [localMarkerState, setLocalMarkerState] = useState<LocalStateMarker[]>([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [newTimeMarker, setNewTimeMarker] = useState<number>(0);
  const [allowMarkerAdd, setAllowMarkerAdd] = useState(false);
  const [calculatedAudioDuration, setCalculatedAudioDuration] = useState(
    episode?.duration ?? Number.POSITIVE_INFINITY
  );
  const [isPeaksAvailable, setIsPeaksAvailable] = useState(false);

  const PeaksInstanceRef = useRef<PeaksInstance | null>(null);

  /**
   * Ensuring audioDuration is grabbed from mediafile and using calculated Audio Duration as fallback
   */
  const audioDuration = useMemo(
    () => calculatedAudioDuration ?? mediaFile?.duration ?? episode?.duration,
    [episode?.duration, mediaFile?.duration, calculatedAudioDuration]
  );

  const episodeMarkers =
    episodeUUID && !isEmpty(markersByEpisodeUUID) && !isEmpty(markers)
      ? markersByEpisodeUUID?.[episodeUUID]?.map((markerUUID) => ({ ...markers?.[markerUUID] }))
      : [];

  /**
   * Determines when the episode markers need to be re calculated to local state (i.e only when
   * a marker has been added/deleted and updated)
   */
  const episodeMarkersChangeID = episodeMarkers
    ?.map((marker) => `${marker?.uuid}_${marker?.updatedAt}`)
    ?.join("-");

  const markerCounter = {
    [MarkerPosition.PREROLL]: 0,
    [MarkerPosition.POSTROLL]: 0,
    [MarkerPosition.MIDROLL]: 0,
  };

  const metaDataByMarkerUUID: {
    [markerUUID: string]: {
      tableTitle: string;
      markerTitle: string;
      order: number;
    };
  } = localMarkerState
    .filter((localMarker) => !(localMarker as DeletedMarker).delete)
    .sort((a, b) =>
      a.offsetMilliSeconds - b.offsetMilliSeconds === 0
        ? a.createdAt - b.createdAt
        : a.offsetMilliSeconds - b.offsetMilliSeconds
    )
    .reduce(
      (accu, currentMarker, index) => {
        markerCounter[currentMarker.position] += 1;

        accu[currentMarker.uuid] = {
          tableTitle: `${formatPosition(currentMarker.position, "table")} ${
            markerCounter[currentMarker.position]
          }`,
          markerTitle: `${formatPosition(currentMarker.position, "marker")} ${
            markerCounter[currentMarker.position]
          }`,
          order: index,
        };

        return accu;
      },
      {} as {
        [markerUUID: string]: {
          tableTitle: string;
          markerTitle: string;
          order: number;
        };
      }
    );

  const listOfAudioBlocks = isEmpty(audioBlocks) ? [] : Object.values(audioBlocks);

  /**
   * Table Data
   */
  const tableData: TableRecord[] = localMarkerState
    .filter((localMarker) => !(localMarker as DeletedMarker).delete)
    .sort((a, b) => metaDataByMarkerUUID[a.uuid]?.order - metaDataByMarkerUUID[b.uuid]?.order)
    .map(({ uuid, offsetMilliSeconds, position, audioBlockUUID }) => {
      return {
        key: uuid,
        offsetMilliSeconds,
        title: metaDataByMarkerUUID[uuid].tableTitle,
        markerLabeltext: metaDataByMarkerUUID[uuid].markerTitle,
        position,
        audioBlockUUID: audioBlockUUID || UNASSIGNED_AUDIOBLOCK,
        currentAudioBlockName: audioBlockUUID ? audioBlocks[audioBlockUUID]?.name : "",
        limit:
          audioBlockUUID && audioBlockUUID !== UNASSIGNED_AUDIOBLOCK
            ? audioBlocks[audioBlockUUID]?.limit
            : 0,
        spots:
          audioBlockUUID && audioBlockUUID !== UNASSIGNED_AUDIOBLOCK
            ? audioBlocks[audioBlockUUID]?.items?.length
            : 0,
      };
    });

  const tableColumns: ColumnsType<TableRecord> = [
    {
      title: "target",
      dataIndex: "offsetMilliSeconds",
      key: "target",
      render: (offsetMilliSeconds: number) => {
        return (
          <div
            className={`flex-row-container justify-center align-center ${
              isPeaksAvailable ? "pointer" : "cursor-disabled"
            }`}
            onClick={() => {
              handleSeek(offsetMilliSeconds);
            }}>
            <Icon
              icon={TargetIcon}
              color="#EA404D"
              className={classes.zoomview_controls__icon}
              height={18}
            />
          </div>
        );
      },
      width: 25,
    },
    {
      title: "Position",
      dataIndex: "title",
      key: "title,",
      align: "center",
      render: (text: string) => {
        return (
          <div className="flex-direction-row justify-center align-center">
            <p className="bold m-a0 lh-15">{text}</p>
          </div>
        );
      },
    },
    {
      title: "time",
      dataIndex: "offsetMilliSeconds",
      key: "offsetMilliSeconds,",
      align: "center",
      render: (value, record) => {
        return (
          <TimeFormatter
            timeInMilliseconds={value}
            onTimeUpdate={(newTime) => {
              handleMarkerTimeUpdate(record.key, newTime);
            }}
            max={audioDuration}
          />
        );
      },
    },
    {
      title: "Audio Block",
      dataIndex: "currentAudioBlockName",
      key: "currentAudioBlockName,",
      align: "center",
      render: (name, record) => {
        return (
          <Select<string, { key: string; value: string; record: TableRecord }>
            value={record.audioBlockUUID}
            style={{ width: "80%", minWidth: "200px", maxWidth: "200px" }}
            virtual={false}
            onChange={handleSelectChange}>
            {listOfAudioBlocks?.map(({ name, uuid }) => {
              return (
                <Select.Option key={uuid} value={uuid} record={record}>
                  {name}
                </Select.Option>
              );
            })}
            <Select.Option
              key={UNASSIGNED_AUDIOBLOCK}
              value={UNASSIGNED_AUDIOBLOCK}
              record={record}>
              Unassigned
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: "Spots",
      dataIndex: "limit",
      key: "limit,",
      render: (text, record, index) => {
        return `${text || 0} Spots`;
      },
    },
    {
      title: "Menu",
      key: "menu",
      dataIndex: "key",
      align: "right",
      render: (uuid, record, index) => {
        return (
          <div>
            <ContextMenu
              noCircle={true}
              menuItems={{
                "Delete Insertion Point": {
                  title: "Delete insertion Point",
                  onSelect: () => {
                    handleMarkerDelete(uuid);
                  },
                },
              }}
            />
          </div>
        );
      },
      width: 50,
    },
  ];

  const numOfMarkers = localMarkerState.filter(
    (localMarker) => !(localMarker as DeletedMarker).delete
  )?.length;

  const totalSlots = tableData?.reduce((accu, curr) => (accu += curr.limit), 0);

  const waveFormMarkers = localMarkerState
    .filter((localMarker) => !(localMarker as DeletedMarker).delete)
    .map(({ offsetMilliSeconds, uuid, createdAt }) => {
      return {
        id: uuid,
        time: offsetMilliSeconds / 1000,
        editable: true,
        color: "#577D9E",
        labelText: metaDataByMarkerUUID[uuid].markerTitle,
        createdAt,
        zIndex: metaDataByMarkerUUID[uuid].order,
      };
    });

  /**
   * Handlers
   */
  const handleCancelModal = () => isVisible && setIsVisible(false);

  const afterClose = () => {
    // In order to keep the close animation of the modal, will update redux modal
    // state and url route after animation has finished
    history.push(oneLevelUp(location));

    if (!canEditMarkers) {
      dispatch(showWarning("You do not have permission to edit insertion-points for this podcast"));
    }
  };

  // Handles individual marker time update in waveform
  const handleMarkerTimeUpdate = useCallback(
    (markerID: string, newTimeInMilliseconds: number) => {
      setLocalMarkerState((prevState) => {
        const newMarkers = [...prevState];
        const changedMarkerIndex = newMarkers.findIndex((marker) => marker.uuid === markerID);
        if (changedMarkerIndex >= 0) {
          newMarkers[changedMarkerIndex].offsetMilliSeconds = newTimeInMilliseconds;
          newMarkers[changedMarkerIndex].position = calculatePositionFromTime(
            newTimeInMilliseconds,
            audioDuration
          );

          (newMarkers[changedMarkerIndex] as UpdatedMarker).updated = true;
        }

        return newMarkers;
      });
    },
    [setLocalMarkerState, audioDuration]
  );

  const handleMarkerDelete = useCallback(
    (markerID: string) => {
      setLocalMarkerState((prevState) => {
        const newMarkers = [...prevState];
        const changedMarkerIndex = newMarkers.findIndex((marker) => marker.uuid === markerID);
        if (changedMarkerIndex >= 0)
          (newMarkers[changedMarkerIndex] as DeletedMarker).delete = true;
        return newMarkers;
      });
    },
    [setLocalMarkerState]
  );

  // Handles play head time update
  const handlePlayHeadTimeUpdate = useCallback(
    (newTimeInSeconds: number) => {
      setCurrentTime(newTimeInSeconds * 1000);
    },
    [setCurrentTime]
  );

  const handleMarkerAdd = (newMarkerInfo: {
    offsetMilliSeconds: number;
    position: MarkerPosition;
    audioBlockUUID?: string;
  }) => {
    const newMarker: NewMarker = {
      uuid: newUUID(),
      episodeUUID,
      offsetMilliSeconds: newMarkerInfo.offsetMilliSeconds,
      position: newMarkerInfo.position,
      audioBlockUUID: newMarkerInfo?.audioBlockUUID || UNASSIGNED_AUDIOBLOCK,
      isNewMarker: true,
      createdAt: Math.floor(Date.now() / 1000),
    };

    setLocalMarkerState((prevState) => {
      const newMarkers = [...prevState];
      newMarkers.push(newMarker);
      return newMarkers;
    });
  };

  const handleSelectChange: (
    value: string,
    option:
      | {
          key: string;
          value: string;
          record: TableRecord;
        }
      | {
          key: string;
          value: string;
          record: TableRecord;
        }[]
  ) => void = (audioBlockUUID, option) => {
    const record = Array.isArray(option) ? option[0].record : option.record;
    setLocalMarkerState((prevState) => {
      const newMarkers = [...prevState];
      const changedIndex = newMarkers.findIndex((marker) => marker.uuid === record.key);

      if (changedIndex >= 0) {
        newMarkers[changedIndex].audioBlockUUID = audioBlockUUID;
        (newMarkers[changedIndex] as UpdatedMarker).updated = true;
      }
      return newMarkers;
    });
  };
  const handleSave = () => {
    const changedMarkers = cleanLocalMarkers(localMarkerState);

    setIsSaving(true);

    dispatch(updateMarkers(changedMarkers))
      .then(() => {
        return dispatch(getMarkersByEpisodeUUID(episodeUUID));
      })
      .catch((err) => {
        //** Catch any issues and show them in the console */
        console.error("error", err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onReady = useCallback(
    (PeaksInstance: PeaksInstance | null, durationInSeconds: number) => {
      PeaksInstanceRef.current = PeaksInstance;
      if (typeof durationInSeconds === "number") {
        setCalculatedAudioDuration(Math.floor(durationInSeconds * 1000));
      }

      setIsPeaksAvailable(true);
    },
    [setCalculatedAudioDuration, setIsPeaksAvailable]
  );

  const handleSeek = useCallback(
    (timeInMilliseconds: number) => {
      if (isPeaksAvailable) {
        PeaksInstanceRef.current?.player?.seek?.(timeInMilliseconds / 1000);
      }
    },
    [isPeaksAvailable]
  );

  /**
   * Use Effects
   */

  useEffect(() => {
    if (Array.isArray(episodeMarkers) && episodeMarkers.length > 0) {
      setLocalMarkerState(episodeMarkers.map((marker) => ({ ...marker })));
    }
  }, [episodeMarkers?.length, episodeMarkersChangeID, audioDuration]);

  const isWaveFormLoading =
    mediaFile?.conversionState !== MediaFileConversionState.done ||
    !mediaFile?.waveformURL ||
    isSaving;

  let loadingMessage;
  if (isWaveFormLoading) {
    loadingMessage = "Audio is still processing";

    if (isSaving) loadingMessage = "Saving new insertion point markers";
  }

  if (show?.uuid && canEditMarkers === false) {
    handleCancelModal();
  }

  return (
    <div>
      <Modal
        wrapClassName="insertion-points-modal-overide footer-shadow"
        width={"calc(100vw - 100px)"}
        visible={isVisible}
        centered={true}
        onCancel={() => handleCancelModal()}
        afterClose={afterClose}
        destroyOnClose={true}
        maskClosable={false}
        title={
          <div>
            <h4 className="fs-28 lh-m m-bxxs">Add Audio Insertion Points</h4>
            <p className="fs-15 lh-s m-b0">
              Set insertion points and assign audio blocks to dynamically insert ads and custom
              audio into this episode.{" "}
              <ExternalLink href="https://support.redcircle.com/what-is-dynamic-insertion">
                Learn More
              </ExternalLink>
            </p>
            <Divider style={{ margin: "12px 0px 4px 0px" }} />
          </div>
        }
        footer={
          <div className="flex-row-container align-center justify-space-between">
            <RCButton type="link" onClick={() => handleCancelModal()}>
              Cancel
            </RCButton>
            <LoadingButton disabled={isSaving} isLoading={isSaving} onClick={handleSave}>
              Save
            </LoadingButton>
          </div>
        }>
        <div>
          <div className="flex-column-container align-start justify-center">
            <span className={`bold fs-11 lh-16 m-v0 uppercase grey ${classes.show_title}`}>
              {show?.title}
            </span>
            <div className="flex-row-container align-center m-bxxxs justify-space-between width-100">
              <span className="bold fs-20 lh-m m-v0">{episode?.title}</span>
              <div className="flex-row-container align-center">
                <RCButton
                  type="primary"
                  size="small"
                  disabled={!episodeHasAudio}
                  className="bold fs-13 lh-xs m-lxs flex-row-container align-center"
                  onClick={() => {
                    handleMarkerAdd({ offsetMilliSeconds: 0, position: MarkerPosition.PREROLL });
                  }}>
                  + Pre Roll
                </RCButton>
                <RCButton
                  type="primary"
                  size="small"
                  disabled={!episodeHasAudio}
                  className="bold fs-13 lh-xs m-lxs flex-row-container align-center"
                  onClick={() => {
                    const time =
                      calculatePositionFromTime(currentTime, audioDuration) ===
                      MarkerPosition.MIDROLL
                        ? currentTime
                        : audioDuration / 2;

                    handleMarkerAdd({
                      offsetMilliSeconds: time,
                      position: MarkerPosition.MIDROLL,
                    });
                  }}>
                  + Mid Roll
                </RCButton>
                <RCButton
                  type="primary"
                  size="small"
                  disabled={!episodeHasAudio}
                  className="bold fs-13 lh-xs m-lxs flex-row-container align-center"
                  onClick={() => {
                    handleMarkerAdd({
                      offsetMilliSeconds: audioDuration,
                      position: MarkerPosition.POSTROLL,
                    });
                  }}>
                  + Post Roll
                </RCButton>
              </div>
            </div>
          </div>
          <WaveForm
            datFile={mediaFile?.waveformURL}
            convertedURL={mediaFile?.convertedURL}
            markers={waveFormMarkers}
            handleMarkerTimeUpdate={handleMarkerTimeUpdate}
            handleMarkerDelete={handleMarkerDelete}
            onPlayHeadTimeUpdate={handlePlayHeadTimeUpdate}
            onReady={onReady}
            isLoading={isWaveFormLoading}
            loadingMessage={loadingMessage}
          />
          <div className="flex-row-container align-center justify-start">
            <span className="bold fs-15 lh-m m-rxs">{`Insertion Points (${numOfMarkers})`}</span>
            <span className="fs-15 lh-m">{`Total Spots ${totalSlots}`}</span>
            <InfoTooltip
              direction="top"
              helpText="Spots refer to the number of audio clips inserted from the assigned Audio Block. Total Spots sums up all spots from all assigned Audio Blocks in the episode."
              style={{ color: "#c6c6c6" }}
            />
            <div className="m-la flex-row-container align-center">
              <TimeFormatter
                timeInMilliseconds={newTimeMarker}
                onManualInputUpdate={({ timeInMilliseconds, isCorrectFormat }) => {
                  if (isCorrectFormat && typeof timeInMilliseconds === "number") {
                    setNewTimeMarker(timeInMilliseconds);
                  }
                  setAllowMarkerAdd(isCorrectFormat);
                }}
                max={audioDuration}
                disabled={!episodeHasAudio}
              />
              <Button
                type="primary"
                ghost
                size="small"
                disabled={!episodeHasAudio || !allowMarkerAdd}
                className="fs-13 lh-xs m-lxss flex-row-container align-center"
                onClick={() => {
                  handleMarkerAdd({
                    offsetMilliSeconds: newTimeMarker,
                    position: calculatePositionFromTime(newTimeMarker, audioDuration),
                  });
                }}>
                Add
              </Button>
            </div>
          </div>
          <Table
            loading={isSaving || isMarkersLoading}
            dataSource={tableData}
            columns={tableColumns}
            showHeader={false}
            size="small"
            locale={{
              emptyText: () => {
                return isSaving || isMarkersLoading ? null : (
                  <div className="flex-row-container justify-center align-center">
                    <span> No insertion points.</span>{" "}
                  </div>
                );
              },
            }}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
};

export default InsertionPointsModal;
