import { Tabs, Tooltip } from "antd";
import moment from "moment-timezone";
import React, { createContext, useContext, useState } from "react";
import { classNames } from "react-extras";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showModal } from "src/actions/modal";
import { getCampaign, pauseCampaignItem } from "src/action_managers/campaigns";
import { AlbumArt } from "src/components/lib/album_art";
import Button from "src/components/lib/button";
import ContextMenu from "src/components/lib/context_menu";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import { svgIcon as Icon } from "src/components/lib/icon";
import MediaPlayer from "src/components/lib/media_player";
import RedDot from "src/components/lib/red_dot/red_dot";
import RCTable from "src/components/lib/tables/rc_table";
import { CONFIRMATION_MODAL, PIXEL_MODAL } from "src/components/modals/modal_root";
import { sortCampaignItemsByDueDate } from "src/components/pages/ad_platform/show_advertising_page/show_advertising_page_util";
import {
  CampaignItemStateAccepted,
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCompleted,
  CampaignItemStateDeclined,
  CampaignItemStateDraft,
  CampaignItemStateExpired,
  CampaignItemStateNeedsScript,
  CampaignItemStatePaused,
  CampaignItemStateRunning,
  CampaignItemStateSent,
  CampaignItemStateToFriendly,
  CampaignItemStateToFriendlyPodcaster,
} from "src/constants/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { TAppendedCampaignItem } from "src/hooks/campaigns";
import { useSelectorTS } from "src/hooks/redux-ts";
import { ReactComponent as DownChevron } from "src/icons/down-chevron.svg";
import { campaignIsDiscrete, getAverageCPM, isAudioSwapRequested } from "src/lib/campaigns";
import { localDate, localTime } from "src/lib/date";
import { formatMoney } from "src/lib/format-money";
import { canAdvertiserAccess } from "src/lib/permissions";
import { ICampaign, ICampaignStatsByUUID } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "src/reducers/shows";
import { User } from "src/reducers/user";
import { RemovePodcastFromCart } from "../../../modals/remove_podcast_from_cart";
import { AudioSwapModalTableState } from "./campaign_page_dashboard";
import {
  formatStartAndEndDates,
  getCampaignItemContextText,
  getDaysLeftAndClockTimeString,
  getSpotRateFromCampaignItem,
  getTotalSpotsFromCampaignItem,
} from "./campaign_page_utils";
import CampaignProgress, { getCampaignProgress } from "./campaign_progress";

interface IProps {
  campaignItemsByState: Record<ICampaignItem["state"], ICampaignItem[]>;
  campaign?: ICampaign;
  campaignStats?: ICampaignStatsByUUID[string];
  audioSwapModalState: AudioSwapModalTableState;
  setAudioSwapModalState: React.Dispatch<React.SetStateAction<AudioSwapModalTableState>>;
  tabBarExtraContent?: any;
}

const CampaignContext = createContext({} as ICampaign);
const DAYS_BOLD_CUTOFF = 2;

export default function CampaignDashboardTable({
  campaignItemsByState,
  campaign,
  campaignStats,
  audioSwapModalState,
  setAudioSwapModalState,
  tabBarExtraContent,
}: IProps) {
  const { user } = useSelectorTS((state) => state?.user);
  const dispatch = useDispatch();
  const [expandedRowKeys, setExpandedRowKeys] = useState<{ [key: string]: boolean }>({});

  const [itemUUIDToRemove, setItemUUIDToRemove] = useState<string | undefined>(undefined);

  // all states besides draft
  const {
    sent,
    accepted,
    running,
    paused,
    completed,
    expired,
    declined,
    [CampaignItemStateNeedsScript]: needsScript,
    [CampaignItemStateAwaitingAudio]: awaitingAudio,
    [CampaignItemStateAudioSwapRequested]: audioSwapRequested,
  } = campaignItemsByState;

  // we have a default sort order for all items
  const allItems = [
    ...needsScript,
    ...audioSwapRequested,
    ...sortCampaignItemsByDueDate([...awaitingAudio, ...sent]),
    ...running,
    ...accepted,
    ...paused,
    ...completed,
    ...expired,
    ...declined,
  ];
  const acceptedItems = [
    ...needsScript,
    ...audioSwapRequested,
    ...awaitingAudio,
    ...running,
    ...accepted,
    ...paused,
    ...completed,
  ];
  const pendingActionItems = [
    ...needsScript,
    ...audioSwapRequested,
    ...sortCampaignItemsByDueDate([...awaitingAudio, ...sent]),
    ...paused,
  ];
  const runningItems = [...running];

  // we track expanded rows through react state and not antd
  const handleRowExpandClick = (campaignUUID: string) => {
    const rows = { ...expandedRowKeys };
    if (rows[campaignUUID]) {
      delete rows[campaignUUID];
    } else {
      rows[campaignUUID] = true;
    }
    setExpandedRowKeys(rows);
  };

  const showPauseModal = (callback: () => any) =>
    dispatch(
      showModal(CONFIRMATION_MODAL, {
        modalTitle: "Are you sure?",
        confirmationMessage:
          'Ads should only be stopped if there is a serious problem. Selecting "Report a Problem" will alert our support team to reach out to you.',
        callback,
        actionButtonName: "Report a Problem",
      })
    );
  const showPixelModal = (campaignItem: ICampaignItem) =>
    dispatch(showModal(PIXEL_MODAL, { campaignItem }));
  const pauseCampaignItemDispatch = (uuid: string) => dispatch(pauseCampaignItem(uuid));
  const getCampaignDispatch = (uuid: string) => dispatch(getCampaign(uuid));

  const columns = [
    {
      title: "Podcast",
      key: "podcast",
      render: (campaignItem: ICampaignItem) => {
        const isPodcasterViewing = user.uuid === campaignItem?.creatorUUID;
        const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);

        const showAudioSwapMessage =
          !isPodcasterViewing && isAudioSwapActive && !isPodcasterInitiated;

        return (
          <TablePodcastCell
            rowIsExpanded={!!expandedRowKeys[campaignItem.uuid]}
            campaignItem={campaignItem}
            onClickExpand={handleRowExpandClick}
            showAudioSwapMessage={showAudioSwapMessage}
          />
        );
      },
      sorter: (a: ICampaignItem, b: ICampaignItem) => {
        return a.show?.title?.localeCompare(b.show?.title ?? "") ?? 0;
      },
    },
    {
      title: "Progress",
      key: "progress",
      responsive: ["sm"],
      render: (campaignItem: ICampaignItem) => (
        <CampaignProgress campaignItem={campaignItem} campaignStats={campaignStats} />
      ),
    },
    {
      title: "Status",
      key: "status",
      responsive: ["sm"],
      render: (campaignItem: ICampaignItem) => (
        <TableStatusCell campaignItem={campaignItem} user={user} isPodcaster={false} />
      ),
      sorter: (a: ICampaignItem, b: ICampaignItem) => a.state.localeCompare(b.state),
    },
    {
      title: "Ad Audio",
      key: "context",
      render: (campaignItem: ICampaignItem) => {
        const isPodcasterViewing = user.uuid === campaignItem?.creatorUUID;
        const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);

        const showAudioSwapMessage =
          !isPodcasterViewing && isAudioSwapActive && !isPodcasterInitiated;

        return (
          <TableContextCell
            campaign={campaign}
            campaignItem={campaignItem}
            showPauseItemModal={showPauseModal}
            showPixelModal={showPixelModal}
            pauseCampaignItem={pauseCampaignItemDispatch}
            getCampaign={getCampaignDispatch}
            audioSwapModalState={audioSwapModalState}
            setAudioSwapModalState={setAudioSwapModalState}
            showUnpauseAudio={showAudioSwapMessage}
            setItemUUIDToRemove={setItemUUIDToRemove}
          />
        );
      },
    },
  ];

  const sharedTableProps = {
    columns,
    rowKey: (campaignItem: ICampaignItem) => campaignItem.uuid,
    pagination: false,
    expandable: {
      expandIcon: () => false,
      expandIconColumnIndex: -1,
      expandedRowRender: (campaignItem: ICampaignItem & { campaign: ICampaign; show: IShow }) => (
        <TableExpandedRow campaignItem={campaignItem} campaign={campaign} />
      ),
      expandedRowKeys: Object.keys(expandedRowKeys),
    },
  };

  // Audio Swap Modal Info

  return (
    <CampaignContext.Provider value={campaign || ({} as ICampaign)}>
      <Tabs
        defaultActiveKey={"all"}
        className="campaign-dashboard-table"
        onChange={() => setExpandedRowKeys({})}
        tabBarExtraContent={tabBarExtraContent}>
        <Tabs.TabPane tab={`All (${allItems.length})`} key="all">
          <TableWithEmptyState
            dataSource={allItems}
            emptyState="There are currently no podcasts in this campaign."
            {...sharedTableProps}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Accepted (${acceptedItems.length})`} key="accepted">
          <TableWithEmptyState
            dataSource={acceptedItems}
            emptyState="There are currently no podcasts that have accepted this campaign."
            {...sharedTableProps}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <div className="flex-row-container align-center">
              Pending Action ({pendingActionItems.length})
              {pendingActionItems.length > 0 && <RedDot size={8} className="m-lxxxs m-bxxs" />}
            </div>
          }
          key="pending">
          <TableWithEmptyState
            dataSource={pendingActionItems}
            emptyState="There are currently no podcasts with pending invites for this campaign."
            {...sharedTableProps}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Running (${runningItems.length})`} key="running">
          <TableWithEmptyState
            dataSource={runningItems}
            emptyState="There are currently no podcasts actively running in this campaign."
            {...sharedTableProps}
          />
        </Tabs.TabPane>
      </Tabs>
      <RemovePodcastFromCart
        itemUUIDToRemove={itemUUIDToRemove}
        setItemUUIDToRemove={setItemUUIDToRemove}
      />
    </CampaignContext.Provider>
  );
}

export const TableWithEmptyState = ({ dataSource, emptyState = "No Data", ...props }: any) => {
  if (!dataSource || dataSource.length === 0) {
    return (
      <div className="table-empty-state">
        <span className="text-subtle m-ts m-bs m-rs m-ls">{emptyState}</span>
      </div>
    );
  }
  return <RCTable dataSource={dataSource} {...props} />;
};

export const TablePodcastCell = ({
  campaignItem,
  rowIsExpanded,
  onClickExpand,
  showAudioSwapMessage,
}: {
  campaignItem: any;
  rowIsExpanded: boolean;
  onClickExpand: (campaignUUID: string) => void;
  showAudioSwapMessage: boolean;
}) => {
  const campaign = useContext(CampaignContext);
  const show = campaignItem.show;
  if (!show) return null;

  const isDiscrete = campaignIsDiscrete(campaign);
  const isDisabled =
    campaignItem.state === CampaignItemStateExpired ||
    campaignItem.state === CampaignItemStateDeclined ||
    campaignItem.state === CampaignItemStatePaused;

  let total;
  let cpm;
  let spotRate;

  const handleClickExpand = () => {
    onClickExpand(campaignItem.uuid);
  };

  if (isDiscrete) {
    total = formatMoney(
      getTotalSpotsFromCampaignItem(campaignItem) * getSpotRateFromCampaignItem(campaignItem)
    );
    spotRate = formatMoney(getSpotRateFromCampaignItem(campaignItem));
  } else {
    total = formatMoney(campaignItem.totalBudget);
    cpm = formatMoney(getAverageCPM({ show, campaign, campaignItem }));
  }

  return (
    <div>
      {showAudioSwapMessage && (
        <span className="flex-row-container align-center m-bxxxs fs-11 lh-xs">
          Replacement audio request pending{" "}
          <Tooltip title="Campaign paused on show until audio is swapped or request is canceled">
            <Icon
              name="info"
              className="m-hxxs"
              style={{
                height: "13px",
                width: "13px",
                color: "#C6C6C6",
              }}
            />
          </Tooltip>
        </span>
      )}

      <div className={classNames("campaign-table-cell", isDisabled && "disabled")}>
        <Link to={`/browse/${show.uuid}`}>
          <AlbumArt
            src={show.imageURL}
            style={{ width: 48, marginRight: 12 }}
            imageSize={"64x64"}
          />
        </Link>
        <div className="flex-column-container">
          <strong className="line-clamp-1 title" title={show.title}>
            {show.title}
          </strong>

          <div className="flex-row-container align-center">
            <span className="m-rxxs m-txxxs fs-13">
              Total: <strong>{total}</strong> | {isDiscrete ? "Spot Rate" : "CPM"}:{" "}
              <strong>{isDiscrete ? spotRate : cpm}</strong>
            </span>
            {campaignItem?.offerRates?.enabled && (
              <Tooltip title="This is the updated rate you offered this show.">
                <Icon name="money-tag" className="money-tag m-rxxs" />
              </Tooltip>
            )}
            <button
              type="button"
              className={classNames("svg-button", rowIsExpanded && "expanded")}
              onClick={handleClickExpand}>
              <DownChevron />
            </button>
          </div>

          <span className="d-block d-none-sm">
            <ExclusiveTag className={`no-wrap tag-${campaignItem.state} podcaster`}>
              {
                CampaignItemStateToFriendlyPodcaster[
                  campaignItem.state as keyof typeof CampaignItemStateToFriendlyPodcaster
                ]
              }
            </ExclusiveTag>
          </span>
        </div>
      </div>
    </div>
  );
};

export const TableStatusCell = ({
  campaignItem,
  user,
  isPodcaster,
  className,
}: {
  campaignItem: ICampaignItem;
  user: User;
  isPodcaster: boolean;
  className?: string;
}) => {
  const isPodcasterViewing = user.uuid === campaignItem?.creatorUUID;
  const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);

  const today = Math.floor(Date.now() / 1000);
  const audioSwapDate = Number(campaignItem?.swapAudioInfo?.requestedAt);
  const fiveDay = 60 * 60 * 24 * 5;
  const showNewAudioText = today <= audioSwapDate + fiveDay;

  const showNewAudioMessage =
    !isPodcasterViewing && isAudioSwapActive && showNewAudioText && isPodcasterInitiated;

  const { percent } = getCampaignProgress(campaignItem);
  const inProgressButZero = percent === 0 && campaignItem.state === CampaignItemStateRunning;
  // The campaignItem state can show in progress but nothing has been inserted yet, showing
  // the "not started" state on this condition for more intuitive UI experience.
  const campaignItemStateUsed = inProgressButZero ? CampaignItemStateAccepted : campaignItem.state;

  return (
    <span className={className}>
      {showNewAudioMessage && (
        <p className="color-primary d-inline-block m-rxxs m-t0">New Audio!</p>
      )}
      <ExclusiveTag
        className={`no-wrap tag-${campaignItemStateUsed} ${isPodcaster ? "podcaster" : ""}`}>
        {isPodcaster
          ? CampaignItemStateToFriendlyPodcaster[campaignItemStateUsed]
          : CampaignItemStateToFriendly[campaignItemStateUsed]}
      </ExclusiveTag>
    </span>
  );
};

export const TableContextCell = ({
  campaign,
  campaignItem,
  showPixelModal,
  setAudioSwapModalState,
  showUnpauseAudio,
  setItemUUIDToRemove,
}: any) => {
  const menuItems: { [key: string]: any } = {};

  if (
    canAdvertiserAccess(permissionTypes.editCampaign, campaign) &&
    campaignItem.state !== CampaignItemStateDraft &&
    campaignItem.state !== CampaignItemStateCompleted &&
    campaignItem.state !== CampaignItemStateDeclined &&
    campaignItem.state !== CampaignItemStateExpired
  ) {
    menuItems["Configure Pixel URL"] = () => {
      showPixelModal(campaignItem);
    };
  }
  if (
    (campaignItem.state === CampaignItemStateRunning ||
      campaignItem.state === CampaignItemStateAccepted ||
      campaignItem.state === CampaignItemStateAudioSwapRequested) &&
    canAdvertiserAccess(permissionTypes.editCampaign, campaign)
  ) {
    if (showUnpauseAudio) {
      menuItems["Unpause Ad Audio"] = () => {
        setAudioSwapModalState((prev: any) => ({
          ...prev,
          currentCampaignItemUUID: campaignItem?.uuid,
          cancelAudioSwapModal: true,
        }));
      };
    } else {
      menuItems["Report Audio Issue"] = () => {
        setAudioSwapModalState((prev: any) => ({
          ...prev,
          currentCampaignItemUUID: campaignItem?.uuid,
          audioSwapModal: true,
        }));
      };
    }
  }
  if (
    campaignItem.state == CampaignItemStateDeclined ||
    campaignItem.state == CampaignItemStateExpired
  ) {
    menuItems["Remove Podcast"] = () => {
      setItemUUIDToRemove(campaignItem?.uuid);
    };
  }

  const showMediaPlayer = !!campaignItem.mediaFileUUID;
  const showText =
    campaignItem.state === CampaignItemStateSent ||
    campaignItem.state === CampaignItemStateAwaitingAudio;
  const showEndDate =
    campaignItem.state === CampaignItemStateExpired ||
    campaignItem.state === CampaignItemStateDeclined;
  const showCTA = campaignItem.state === CampaignItemStateNeedsScript;
  const showContextMenu = Object.keys(menuItems).length > 0;

  return (
    <div className="campaign-table-cell context">
      {showMediaPlayer && <MediaPlayer mediaFileUUID={campaignItem.mediaFileUUID} mini />}
      {campaignItem.state === CampaignItemStateNeedsScript && (
        <TableDueDate timestamp={campaignItem?.scriptDueBy} />
      )}
      {showText && <span className="fs-13">{getCampaignItemContextText(campaignItem)}</span>}
      {showEndDate && (
        <span className="fs-13 end-date">{getCampaignItemContextText(campaignItem)}</span>
      )}
      {showCTA && <TableCTA campaignItem={campaignItem} />}
      {showContextMenu && <ContextMenu menuItems={menuItems} noCircle={true} />}
    </div>
  );
};

export const TableExpandedRow = ({
  campaignItem,
  campaign,
}: {
  campaignItem: TAppendedCampaignItem;
  campaign?: ICampaign;
}) => {
  const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);
  const today = new Date().getTime() / 1000;
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    hour12: true,
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  const audioSwapDate = dateFormatter.format(
    Number(campaignItem?.swapAudioInfo?.requestedAt ?? 0) * 1000
  );
  const showNewAudioText =
    today <= Number(campaignItem?.swapAudioInfo?.requestedAt ?? 0) + 5 * 24 * 60 * 60;
  const showSwapAudioInfoBlock = isAudioSwapActive && showNewAudioText;

  const SwapAudioInfoBlock = (
    <>
      <p className="m-bxxs lh-s fs-13">
        {isPodcasterInitiated ? "New Audio live" : "Campaign Paused"} as of{" "}
        <strong>
          {audioSwapDate} - {dateFormatter.resolvedOptions().timeZone}
        </strong>
      </p>
      {campaignItem?.swapAudioInfo?.reason?.length > 0 && (
        <p className="m-bxxs lh-s fs-13">
          Reason for Replacement Audio ({isPodcasterInitiated ? "Podcaster" : "Brand"}):{" "}
          <strong>{campaignItem?.swapAudioInfo?.reason}</strong>
        </p>
      )}
    </>
  );

  const showDeclinedReason =
    campaignItem.state === CampaignItemStateDeclined &&
    typeof campaignItem.declinedReason === "string" &&
    campaignItem.declinedReason.length > 0;

  const declinedReasonBlock = (
    <p className="m-bxxs lh-s fs-13">
      Reason for decline: <strong>{campaignItem?.declinedReason}</strong>
    </p>
  );

  return (
    <div className="p-vxxs m-lxl">
      <p className="m-bxxs lh-s fs-13">
        Estimated Timeline:{" "}
        <strong>
          {formatStartAndEndDates(campaignItem, campaignItem.campaign, campaignItem.show, "LL")}
        </strong>
      </p>
      {showSwapAudioInfoBlock && SwapAudioInfoBlock}
      {showDeclinedReason && declinedReasonBlock}
    </div>
  );
};

export const TableDueDate = ({ timestamp }: { timestamp: number | undefined }) => {
  if (!timestamp) return null;
  const { daysLeft } = getDaysLeftAndClockTimeString(timestamp);
  const date = localDate(moment.unix(timestamp));
  const time = localTime(moment.unix(timestamp));
  if (daysLeft < DAYS_BOLD_CUTOFF) {
    return (
      <div className="due-date flex-column-container fs-13 d-none d-flex-sm">
        <span>
          Due by <strong>{date}</strong>
        </span>
        <strong>{time}</strong>
      </div>
    );
  }
  return (
    <div className="due-date flex-column-container fs-13 d-none d-flex-sm">
      <span>Due by {date}</span>
      <span>{time}</span>
    </div>
  );
};

const TableCTA = ({ campaignItem }: { campaignItem: ICampaignItem }) => {
  const path = `/campaigns/${campaignItem.campaignUUID}/script`;

  const renderLink = (copy: string, isPriority?: boolean) => (
    <Link to={path} className="cta">
      <Button type={isPriority ? "primary" : "secondary"}>{copy}</Button>
    </Link>
  );

  if (campaignItem.state === CampaignItemStateNeedsScript && campaignItem.scriptDueBy) {
    const { daysLeft } = getDaysLeftAndClockTimeString(campaignItem.scriptDueBy);
    return renderLink("Assign Script", daysLeft < DAYS_BOLD_CUTOFF);
  }
  return null;
};
