import { Col, Row } from "antd";
import React, { useMemo } from "react";
import { validateCampaign } from "src/lib/validate-campaign";
import { ICampaign } from "src/reducers/campaigns/types";

export const CampaignIncompletePage = ({ campaign }: { campaign: ICampaign }) => {
  const [, missingFields] = useMemo(() => {
    return validateCampaign(campaign);
  }, [campaign, campaign?.uuid]);

  return (
    <Row justify="center">
      <Col
        className="p-hxxs"
        xs={{
          span: 20,
        }}>
        <p className="fs-15 lh-s">Missing fields</p>
        <ul>
          {missingFields.map((field) => (
            <li key={field} className="bold m-bxxs">
              {field}
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};
