import { Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "redcircle-ui";
import { submitShowFeedImport } from "src/actions/feed_import";
import { FetchUserManager } from "src/action_managers/account";
import SignUpActionManager from "src/action_managers/sign_up";
import DirectorySearchAutocomplete from "src/components/lib/directory_search_autocomplete";
import ExternalLink from "src/components/lib/external_link/external_link";
import LoggedOutFrame from "src/components/lib/logged_out_frame/logged_out_frame";
import UserRoles from "src/constants/roles";
import { useReduxDispatch } from "src/hooks/redux-ts";
import { getMegapodURL } from "src/lib/config";
import { GoogleTagManager, GTM_CONSTS } from "src/lib/google_tag_manager";
import { UserRole } from "src/reducers/user";

interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  podcastName: string;
  feedURL: string;
}

export default function RemoteSignUpPage() {
  const dispatch = useReduxDispatch();
  const history = useHistory();
  const [showRSSFeed, setShowRSSFeed] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values: IFormValues) => {
    const fieldData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      role: UserRoles.Creator as Exclude<UserRole, "admin">,
      signUpSource: "openRAPWaitlist",
    };

    const res = await dispatch(
      new SignUpActionManager({ formJSON: fieldData, forRole: UserRoles.Creator }).run()
    );

    if (res.status === 200) {
      GoogleTagManager.fireEvent(GTM_CONSTS.SIGN_UP_SUCCESS, {
        role: UserRoles.Creator,
        signUpSource: "openRAPWaitlist",
      });
      history.push("/waitlist");

      // import the show but not the episodes as remote
      await dispatch(submitShowFeedImport({ feedURL: values.feedURL, isRemote: true }));

      const user = res.json;
      // Must re-fetch user entity, post show feed import, new wait listed shows are now available in user.openRAPWaitlistInfo
      await dispatch(
        new FetchUserManager({ userUUID: user.uuid, authToken: user.authToken }).run()
      );
    }
  };

  const handleToggleRSS = () => {
    setShowRSSFeed(!showRSSFeed);
    form.setFieldsValue({ feedURL: "" });
  };

  return (
    <LoggedOutFrame
      className="openrap-sign-up-page"
      title="Join the OpenRAP Waitlist"
      subtitle={
        <>
          OpenRAP is rolling out over time to new podcasts and new hosting companies. Sign up for
          the waitlist below to get on the list. Early customers get up to <strong>$250*</strong>{" "}
          towards their first advertising deal.
          <small style={{ fontSize: 8 }} className="m-lxxxs">
            <ExternalLink href={`${getMegapodURL()}/terms`} style={{ color: "inherit" }}>
              SEE TERMS
            </ExternalLink>
          </small>
        </>
      }>
      <Row>
        <Col xs={24} md={{ span: 12, offset: 6 }}>
          <Form onFinish={handleSubmit} form={form}>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <label className="redcircle-form-label fs-11" htmlFor="firstName">
                  First Name
                </label>
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: "Please enter a first name" }]}>
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <label className="redcircle-form-label fs-11" htmlFor="lastName">
                  Last Name
                </label>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: "Please enter a last name" }]}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <label className="redcircle-form-label fs-11" htmlFor="email">
                  Email Address
                </label>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Please enter an email" }]}>
                  <Input size="large" />
                </Form.Item>

                <label className="redcircle-form-label fs-11" htmlFor="password">
                  Password
                </label>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please enter a password" },
                    { min: 8, message: "Password must be at least 8 characters long." },
                    { pattern: /[A-Z]/, message: "Password must have 1 uppercase letter." },
                    { pattern: /[a-z]/, message: "Password must have 1 lowercase letter." },
                    { pattern: /[1-9]/, message: "Password must contain a number." },
                  ]}>
                  <Input size="large" type="password" />
                </Form.Item>

                <label className="redcircle-form-label fs-11" htmlFor="podcastName">
                  Podcast
                </label>
                {!showRSSFeed && (
                  <Form.Item
                    name="feedURL"
                    className="m-b0"
                    rules={[{ required: true, message: "Please select a podcast" }]}>
                    <DirectorySearchAutocomplete placeholder="Search for your podcast..." />
                  </Form.Item>
                )}
                {showRSSFeed && (
                  <Form.Item
                    name="feedURL"
                    className="m-b0"
                    rules={[{ required: true, message: "Please enter a RSS feed URL" }]}>
                    <Input size="large" placeholder="RSS Feed" />
                  </Form.Item>
                )}
                <span className="flex-row-container align-center m-bxs">
                  Not sure about your {!showRSSFeed ? "podcast name" : "RSS feed"}?{" "}
                  <Button type="link" className="p-a0 m-xxxxs" onClick={handleToggleRSS}>
                    {showRSSFeed ? "Use your podcast name" : "Use your RSS feed"}
                  </Button>{" "}
                  instead.
                </span>

                <Form.Item className="m-bxxs">
                  <Button type="primary" size="large" className="width-100" htmlType="submit">
                    Join the Waitlist
                  </Button>
                </Form.Item>

                <div className="text-center">
                  <small>
                    By signing up, you are creating a RedCircle account, and you agree to our{" "}
                    <ExternalLink href="https://redcircle.com/terms">Terms of Service</ExternalLink>{" "}
                    and{" "}
                    <ExternalLink href="https://redcircle.com/privacy">Privacy Policy</ExternalLink>
                    . This site is protected by reCAPTCHA and the Google{" "}
                    <ExternalLink href="https://policies.google.com/privacy">
                      Privacy Policy
                    </ExternalLink>{" "}
                    and{" "}
                    <ExternalLink href="https://policies.google.com/terms">
                      Terms of Service
                    </ExternalLink>
                    .
                  </small>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </LoggedOutFrame>
  );
}
