import { Select } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { useLocation } from "react-router-dom";
import { permissionTypes } from "../../../../constants/permission_roles";
import { useSelectorTS } from "../../../../hooks/redux-ts";
import { canAccess } from "../../../../lib/permissions";
import { isShowRedirected, isShowWaitListed } from "../../../../lib/show";
import { IShow } from "../../../../reducers/shows/types";
import { svgIcon as Icon } from "../../../lib/icon";
import {
  ALL_PODCASTS,
  defaultAllPodcastsCutoff,
  handleFilterOptions,
  handleFilterSort,
  someProgrammaticEnabled,
  useTierAnalyticsPerm,
} from "../analyticsUtility";
import RevenueOverview from "../section_cards/revenue_overview";
import RevenueSection from "../section_cards/revenue_section";

const RevenueTab = () => {
  const { user } = useSelectorTS((state) => state.user);

  // TODO: we should really be tracking showUUIDs in routes...
  const location = useLocation<{ defaultStatsShowUUID?: string }>();
  const defaultShowFromNavigation = location?.state?.defaultStatsShowUUID;

  const permissionsState = useSelectorTS((state) => state.permissions);
  const shows: Record<string, IShow> = useSelectorTS((state) => state?.shows?.shows);
  const tierPermission = useTierAnalyticsPerm();
  const listOfShows = Object.keys(shows)
    .map((key) => shows[key])
    .filter(
      (show) =>
        canAccess(user, permissionsState, permissionTypes.viewMoney, show.uuid) &&
        !isShowWaitListed(show)
    )
    .sort((a, b) => a?.title?.localeCompare(b?.title as string) as number);

  const showAllPodcastsOption =
    tierPermission.General.AggregationAllowed && listOfShows?.length > 1;

  const defaultSelectedShow =
    defaultShowFromNavigation ??
    (showAllPodcastsOption && listOfShows?.length < defaultAllPodcastsCutoff
      ? ALL_PODCASTS
      : listOfShows[0]?.uuid);

  const [selectedShow, setSelectedShow] = useState(defaultSelectedShow);

  const showRevenueSection =
    tierPermission.Extra.showExtraWidgets || someProgrammaticEnabled(shows);

  // Handler
  const handleSelectPodcast = (podcast: string) => setSelectedShow(podcast);
  return (
    <Grid fluid>
      <Row>
        <Col className="p-h0 m-bxs">
          <div className="flex-row-container align-center">
            {!isEmpty(listOfShows) && (
              <Select
                showSearch
                filterOption={handleFilterOptions}
                filterSort={handleFilterSort}
                size="small"
                className="RC-Antd-Override-Dropdown m-rxxs"
                style={{ width: "250px" }}
                value={selectedShow}
                onSelect={handleSelectPodcast}
                virtual={false}>
                {listOfShows?.length > 1 && (
                  <Select.Option key={ALL_PODCASTS} value={ALL_PODCASTS}>
                    All Podcasts
                  </Select.Option>
                )}
                {listOfShows.map((show) => {
                  const disabled = isShowRedirected(show);
                  return (
                    <Select.Option key={show?.uuid} value={show?.uuid} disabled={disabled}>
                      {show?.title}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
            {tierPermission.General.ExportDataPageLevel && (
              <Icon name="reportDownload" className="pointer" height={20} width={20} />
            )}
          </div>
        </Col>
      </Row>
      <Row className="m-bs">
        <Col xs={12} className="p-h0">
          <RevenueOverview selectedShow={selectedShow} />
        </Col>
      </Row>
      <If condition={showRevenueSection}>
        <Row className="m-bs">
          <Col xs={12} className="p-h0">
            <RevenueSection selectedShow={selectedShow} />
          </Col>
        </Row>
      </If>
    </Grid>
  );
};

export default RevenueTab;
