import { DatePicker as AntdDatePicker, Select, Tag } from "antd";
import isEmpty from "lodash/isEmpty";
import moment, { Moment } from "moment-timezone";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { FunctionComponent, useEffect, useState } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { useLocation } from "react-router-dom";
import RCButton from "src/components/lib/button/button";
import EmptyStateBlock from "src/components/lib/empty_state_block/EmptyStateBlock";
import { permissionTypes } from "../../../../constants/permission_roles";
import { useSelectorTS } from "../../../../hooks/redux-ts";
import { canAccess } from "../../../../lib/permissions";
import { isShowRedirected, isShowWaitListed } from "../../../../lib/show";
import { IShow } from "../../../../reducers/shows/types";
import { svgIcon as Icon } from "../../../lib/icon";
import {
  ALL_PODCASTS,
  defaultAllPodcastsCutoff,
  handleFilterOptions,
  handleFilterSort,
  ShortPresetMap,
  useTierAnalyticsPerm,
} from "../analyticsUtility";
import DownloadsOverview from "../section_cards/downloads_overview";
import DownloadsSection from "../section_cards/downloads_section";
import EpisodePeformance from "../section_cards/episode_performance";
import ExclusiveDownloads from "../section_cards/exclusive_downloads";
import GeoLocation from "../section_cards/geolocation_performance";
import ListeningCard from "../section_cards/listening_card";
import PodcastPerformance from "../section_cards/podcast_performance";
import QuickAccess from "../section_cards/quick_access";
import TimeMap from "../section_cards/TimeMap";
import TextSwitch from "../TextSwitch";

const DatePicker = AntdDatePicker.generatePicker<Moment>(momentGenerateConfig);

interface IDownloadsTab {
  presetRanges: Record<string, [Moment, Moment]>;
}

const DownloadsTab: FunctionComponent<IDownloadsTab> = ({ presetRanges }) => {
  const { user } = useSelectorTS((state) => state.user);
  const permissionsState = useSelectorTS((state) => state.permissions);
  const shows: Record<string, IShow> = useSelectorTS((state) => state?.shows?.shows);
  const tierPermission = useTierAnalyticsPerm();

  const location = useLocation<{ defaultStatsShowUUID?: string }>();
  const defaultShowFromNavigation = location?.state?.defaultStatsShowUUID;

  const listOfShows = Object.keys(shows)
    .map((key) => shows[key])
    .filter(
      (show) =>
        canAccess(user, permissionsState, permissionTypes.viewStats, show.uuid) &&
        !isShowWaitListed(show)
    )
    .sort((a, b) => a?.title?.localeCompare(b?.title as string) as number);

  const showAllPodcastsOption =
    tierPermission.General.AggregationAllowed && listOfShows?.length > 1;

  const defaultSelectedShowUUID =
    defaultShowFromNavigation ??
    (showAllPodcastsOption && listOfShows?.length < defaultAllPodcastsCutoff
      ? ALL_PODCASTS
      : listOfShows[0]?.uuid);

  // Controlled state for calendar rangepicker dates
  const [calendarRange, setCalendarRange] = useState<[Moment, Moment]>(
    presetRanges["Last 30 Days"]
  );
  const [calendarOpen, setCalendarOpen] = useState(false);

  // Date ranges that get passed down to all children, will sync with calendarRange when appropriate
  const [dateRange, setDateRange] = useState<[Moment, Moment]>(presetRanges["Last 30 Days"]);
  const [selectedShowUUID, setSelectedShowUUID] = useState(defaultSelectedShowUUID);
  const selectedShow = shows[selectedShowUUID];

  const timeZone = moment.tz.guess();

  const mobileOptions = Object.keys(ShortPresetMap).map((item) => ({ value: item, key: item }));

  // Handler
  const handleSelectPodcast = (podcastUUID: string) => setSelectedShowUUID(podcastUUID);
  // Manually assigning Preset date ranges to calendar.
  const handleSelectPreset = (dates: [Moment, Moment]) => () => {
    setCalendarRange(dates); // update calendar,
  };

  // Updates Calendar date range and propagates this value to all section cards
  const handleLoadData = () => {
    setCalendarOpen(false);
    const [start, end] = calendarRange;
    const newStart = start.clone();
    const newEnd = end.clone();
    setDateRange([newStart, newEnd]);
  };

  // Reset calendar date picker dates to previously chosen date range
  const handleResetData = () => {
    const [start, end] = dateRange;
    const newStart = start.clone();
    const newEnd = end.clone();
    setCalendarRange([newStart, newEnd]);
  };

  // Close date picker if clicked outside, (date epicker is a portal outside of root)
  useEffect(() => {
    const container = document.getElementById("root");
    const clickEventLister = (event: any) => {
      // if the user clicks on the range picker input (outside portal modal) do not close
      if (event?.target?.closest(".downloads-daterange-container")) return;

      if (calendarOpen) {
        setCalendarOpen(false);
        handleResetData();
      }
    };
    container?.addEventListener("click", clickEventLister);

    return () => {
      container?.removeEventListener("click", clickEventLister);
    };
  }, [calendarOpen]);

  // syncs show dropdown default option to defaultoption change (when tier changes)
  useEffect(() => {
    setSelectedShowUUID(defaultSelectedShowUUID);
  }, [defaultSelectedShowUUID]);

  const selectionIsRemote = selectedShowUUID === ALL_PODCASTS ? false : selectedShow?.isRemote;

  return (
    <Grid fluid>
      <Row>
        <Col className="p-h0 m-bxs">
          <div className="flex-row-container align-center">
            {!isEmpty(listOfShows) && (
              <Select
                showSearch
                filterOption={handleFilterOptions}
                filterSort={handleFilterSort}
                size="small"
                className="RC-Antd-Override-Dropdown m-rxxs"
                style={{ width: "250px" }}
                value={selectedShowUUID}
                onSelect={handleSelectPodcast}
                virtual={false}>
                {showAllPodcastsOption && (
                  <Select.Option key={ALL_PODCASTS} value={ALL_PODCASTS}>
                    All Podcasts
                  </Select.Option>
                )}
                {listOfShows.map((show) => {
                  const disabled = isShowRedirected(show);

                  return (
                    <Select.Option key={show?.uuid} value={show?.uuid} disabled={disabled}>
                      {show?.title}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
            {tierPermission.General.ExportDataPageLevel && (
              <Icon name="reportDownload" className="pointer" height={20} width={20} />
            )}
          </div>
        </Col>
      </Row>
      {selectedShowUUID && selectionIsRemote && (
        <EmptyStateBlock>
          Download analytics are only available for shows hosted on RedCircle.
        </EmptyStateBlock>
      )}
      {selectedShowUUID && !selectionIsRemote && (
        <>
          <Row>
            <Col xs={12} className="p-h0">
              <DownloadsOverview selectedShow={selectedShowUUID} timeZone={timeZone} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="p-h0 d-none d-block-md">
              <h4 className="seedcast-label uppercase m-ts">Select time</h4>
              <div
                className="downloads-daterange-container d-inline-block m-bs"
                onClick={() => calendarOpen || setCalendarOpen(true)}>
                <DatePicker.RangePicker
                  size="small"
                  allowClear={false}
                  showTime={false}
                  value={calendarRange}
                  open={calendarOpen}
                  onCalendarChange={(dates) => {
                    // On calendar date changes update calendar controlled state
                    if (dates?.[0]?.isValid() && dates?.[1]?.isValid()) {
                      const [start, end] = dates;
                      const newStart = start.clone();
                      const newEnd = end.clone();
                      newStart.startOf("day");
                      newEnd.endOf("day");
                      setCalendarRange([newStart, newEnd]);
                    }
                  }}
                  renderExtraFooter={() => {
                    // Manually rendering Date preset buttons
                    return (
                      <div className="flex-column-container p-hxs">
                        <div className="flex-row-container flex-wrap">
                          {Object.entries(presetRanges).map((preset) => {
                            const [key, range] = preset;
                            return (
                              <RCButton
                                type="link"
                                className="p-a0"
                                key={key}
                                onClick={handleSelectPreset(range)}>
                                <Tag color="red">{key}</Tag>
                              </RCButton>
                            );
                          })}
                        </div>
                        <button
                          className="btn btn-primary uppercase p-vxxxs p-hpxxs"
                          style={{ minWidth: "auto", alignSelf: "flex-end" }}
                          onClick={() => {
                            setCalendarOpen(false);
                            handleLoadData();
                          }}>
                          ok
                        </button>
                      </div>
                    );
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="p-hxxs m-vxs d-block d-none-md">
              <TextSwitch
                options={mobileOptions}
                defaultSelected={mobileOptions[2]?.value}
                onSelect={({ key }) => setDateRange(presetRanges[ShortPresetMap[key]])}
              />
            </Col>
          </Row>
          <Row className="m-bs">
            <Col xs={12} className="p-h0">
              <DownloadsSection
                dateRange={dateRange}
                timeZone={timeZone}
                selectedShow={selectedShowUUID}
              />
            </Col>
          </Row>
          <Row className="m-bs">
            <Col xs={12} className="p-h0">
              <EpisodePeformance
                dateRange={dateRange}
                timeZone={timeZone}
                selectedShow={selectedShowUUID}
              />
            </Col>
          </Row>
          <div className="m-bs analyticsPage-Row--3">
            <ListeningCard
              type="App"
              dateRange={dateRange}
              timeZone={timeZone}
              selectedShow={selectedShowUUID}
            />
            <ListeningCard
              type="Device"
              dateRange={dateRange}
              timeZone={timeZone}
              selectedShow={selectedShowUUID}
            />
            <ListeningCard
              type="Category"
              dateRange={dateRange}
              timeZone={timeZone}
              selectedShow={selectedShowUUID}
            />
          </div>
          <If condition={tierPermission.Extra.showExtraWidgets}>
            <div className="m-bs analyticsPage-Row--2">
              <PodcastPerformance
                dateRange={dateRange}
                timeZone={timeZone}
                selectedShow={selectedShowUUID}
              />
              <TimeMap dateRange={dateRange} timeZone={timeZone} selectedShow={selectedShowUUID} />
            </div>
          </If>
          <Row className="m-bs">
            <Col xs={12} className="p-h0">
              <GeoLocation
                dateRange={dateRange}
                timeZone={timeZone}
                selectedShow={selectedShowUUID}
              />
            </Col>
          </Row>
          <If condition={tierPermission.Extra.showExtraWidgets}>
            <Row className="m-bs">
              <Col xs={12} className="p-h0">
                <ExclusiveDownloads
                  dateRange={dateRange}
                  timeZone={timeZone}
                  selectedShow={selectedShowUUID}
                />
              </Col>
            </Row>
          </If>
          <Row className="m-bs">
            <Col xs={12} className="p-h0">
              <QuickAccess />
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};

export default DownloadsTab;
