import { capitalize } from "lodash";
import find from "lodash/find";
import size from "lodash/size";
import { appendUrlWithHTTP } from "redcircle-lib";
import ExternalLink from "src/components/lib/external_link/external_link";
import { permissionTypes } from "src/constants/permission_roles";
import daastAdCategories from "src/data/daast-ad-categories";
import { canAdvertiserAccess } from "src/lib/permissions";
import { ICampaign } from "src/reducers/campaigns/types";
import {
  DistributionTypeContinuous,
  pacingToFriendly,
  ProductExchangeTypeInstructions,
  ProductExchangeTypeMailing,
} from "../../../constants/campaigns";
import {
  SummaryPageInfo,
  SummaryPageSectionWrapper,
} from "../../lib/summary-page-sections/summary-page-sections";
import { redemptionCodeValueToDisplay } from "./campaign_editor_helpers";
import { ageOptions, genderOptions } from "./target_audience";

const positionToFriendlyNames = { preRoll: "Pre-Roll", midRoll: "Mid-Roll", postRoll: "Post-Roll" };

export const SummaryPage = ({
  campaign,
  updatePage,
}: {
  campaign?: ICampaign;
  updatePage?: (pageNumber?: number) => void;
}) => {
  if (!campaign) return null;

  const targetingOptions = Object.entries(campaign?.targetingOptions ?? [])
    .filter(([, val]) => val)
    .map(([key]) => positionToFriendlyNames[key as keyof typeof positionToFriendlyNames])
    .join(", ");

  let primaryAge = campaign?.targetAges
    ?.sort()
    .map((age) => find(ageOptions, { value: age })?.display)
    .join(", ");
  if (size(campaign?.targetAges) === 0) {
    primaryAge = "Any Age";
  }

  const brandCategoryToFriendly = (iabCategory: string) => {
    for (const c of daastAdCategories) {
      if (c.value === iabCategory) return c.title;
      if (c.children) {
        for (const child of c.children) {
          if (child.value === iabCategory) return child.title;
        }
      }
    }
    return iabCategory;
  };

  const updatePagePermissioned = canAdvertiserAccess(permissionTypes.editCampaign, campaign)
    ? updatePage
    : undefined;

  return (
    <div className={"summary-page"}>
      <SummaryPageSectionWrapper
        title={"campaign details"}
        updatePage={updatePagePermissioned}
        pageNumber={0}>
        <SummaryPageInfo title={"Product or Service"} text={campaign.productName} />
        {campaign.referenceNumber && (
          <SummaryPageInfo title="Reference Number" text={campaign.referenceNumber} />
        )}
      </SummaryPageSectionWrapper>

      {campaign.brand?.name && (
        <SummaryPageSectionWrapper title="brand" updatePage={updatePagePermissioned} pageNumber={1}>
          <SummaryPageInfo title={"Brand Name"} text={campaign.brand.name} />
          <SummaryPageInfo
            title={"Brand Category"}
            text={brandCategoryToFriendly(campaign.brand.iabCategory)}
          />
          <SummaryPageInfo
            title={"Brand Website"}
            text={
              campaign.brand.domain && (
                <ExternalLink href={appendUrlWithHTTP(campaign.brand.domain)}>
                  <strong>{campaign.brand.domain}</strong>
                </ExternalLink>
              )
            }
          />
          <SummaryPageInfo title={"Brand Description"} text={campaign.brand?.description} newline />
        </SummaryPageSectionWrapper>
      )}

      <SummaryPageSectionWrapper
        title={"product"}
        updatePage={updatePagePermissioned}
        pageNumber={2}>
        <SummaryPageInfo
          noColon
          title={"Free product samples?"}
          text={redemptionCodeValueToDisplay[campaign.productExchangeType]}
          newline
        />
        {(campaign.productExchangeType === ProductExchangeTypeInstructions ||
          campaign.productExchangeType === ProductExchangeTypeMailing) && (
          <>
            <SummaryPageInfo
              title={"Redemption Instructions"}
              dangerouslySetInnerHTML={campaign.productExchangeInstructions}
              newline
            />
            {campaign.redemptionCodes && (
              <SummaryPageInfo
                title={"Number of Redemption Codes Uploaded"}
                text={`${Object.keys(campaign.redemptionCodes).length}`}
              />
            )}
          </>
        )}

        <SummaryPageInfo
          title={"Personal Endorsement"}
          text={
            typeof campaign?.requiresEndorsement === "boolean"
              ? campaign?.requiresEndorsement
                ? "Yes, podcaster will need to personally endorse this product."
                : "No, podcaster is not required to personally endorse this product."
              : ""
          }
          newline
        />
      </SummaryPageSectionWrapper>

      <SummaryPageSectionWrapper
        title={"target audience"}
        updatePage={updatePagePermissioned}
        pageNumber={3}>
        <SummaryPageInfo
          title={"Primary Gender"}
          text={find(genderOptions, { value: campaign.targetGender })?.display}
        />
        <SummaryPageInfo title={"Primary Age"} text={primaryAge} />
      </SummaryPageSectionWrapper>

      <SummaryPageSectionWrapper
        title={"distribution reach"}
        updatePage={updatePagePermissioned}
        pageNumber={4}>
        <SummaryPageInfo title={"Pacing"} text={pacingToFriendly[`${Boolean(campaign.pacing)}`]} />
        {!!campaign.pacing && (
          <SummaryPageInfo
            title={"Set campaign end date as deadline?"}
            text={`${campaign.hardEndDate ? "Yes" : "No"}`}
          />
        )}
        <SummaryPageInfo
          title={"Recent Episodes Only?"}
          text={`${campaign?.recentEpisodesOnly ? "Yes" : "No"}`}
        />
        {Array.isArray(campaign?.frequencyConfigs) && (
          <SummaryPageInfo
            title={"Frequency Capping"}
            text={
              campaign.frequencyConfigs.length > 0
                ? campaign.frequencyConfigs
                    .map(({ interval, maxCount }) => `${capitalize(interval)} : ${maxCount}`)
                    .join(", ")
                : "Unlimited"
            }
          />
        )}
        <SummaryPageInfo
          title={"Ad Placement"}
          text={campaign.targetingOptions && targetingOptions}
        />
        <p className="fs-11 lh-xs">
          {campaign.distributionType === DistributionTypeContinuous && (
            <>
              This advertisement will play in the {targetingOptions} across All Episodes on a show’s
              catalog.
            </>
          )}
        </p>
      </SummaryPageSectionWrapper>
    </div>
  );
};
