import isEmpty from "lodash/isEmpty";
import numeral from "numeral";
import React, { FunctionComponent, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "src/components/lib/button";
import { showError, showInfo } from "../../../../actions/app";
import { InsertionStatsActionManager } from "../../../../action_managers/stats";
import { useReduxDispatch, useSelectorTS } from "../../../../hooks/redux-ts";
import HighlightBox from "../../../lib/highlight_box";
import UpgradeTag from "../../../lib/upgrade-tag";
import {
  ALL_PODCASTS,
  canOptInToRAP,
  InsertionGraphTypes,
  insertionOverviewRequestPrefix,
  isCrossPromoEnabled,
  isHostReadEnabled,
  isProgrammaticEnabled,
  RequestResponseDataPoint,
  StatsRequestFilter,
  useSharedSubscriptionRequest,
  useTierAnalyticsPerm,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";

// Utility Functions

const transformData = (data: RequestResponseDataPoint["json"]) => {
  let RAPHostread = 0;
  let RAPProgrammatic = 0;
  let CrossPromotions = 0;
  let Other = 0;

  data?.forEach((insertion) => {
    const [, isProgrammatic, type] = insertion?.pathValues;

    if (type === "ad") {
      // campaign
      if (isProgrammatic === "false") {
        RAPHostread += insertion?.count;
      } else {
        // Programmatic
        RAPProgrammatic += insertion?.count;
      }
    } else if (type === "cross_promo") {
      // cross promotion
      CrossPromotions += insertion?.count;
    } else if (type === "audio_file" || type === "custom_audio") {
      // Other insertion
      Other += insertion?.count;
    } else {
      // should never get here
    }
  });

  const total = RAPHostread + RAPProgrammatic + CrossPromotions + Other;

  return [total, RAPHostread, RAPProgrammatic, CrossPromotions, Other];
};

interface IInsertionOverview {
  selectedShow: string;
  timeZone: string;
}

const InsertionOverview: FunctionComponent<IInsertionOverview> = ({ selectedShow, timeZone }) => {
  const dispatch = useReduxDispatch();
  const user = useSelectorTS((state) => state?.user?.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const history = useHistory();
  const tierPermission = useTierAnalyticsPerm();
  const cacheID = `${insertionOverviewRequestPrefix}-${selectedShow}`;

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );
  useSharedSubscriptionRequest();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [total, RAPHostread, RAPProgrammatic, CrossPromotions, Other] =
    transformData(statsCacheData);

  const showHostReadOptIn =
    selectedShow !== ALL_PODCASTS &&
    RAPHostread == 0 &&
    canOptInToRAP(shows[selectedShow]) &&
    !isHostReadEnabled(shows[selectedShow]);

  const showProgrammaticOptIn =
    selectedShow !== ALL_PODCASTS &&
    RAPProgrammatic == 0 &&
    canOptInToRAP(shows[selectedShow]) &&
    !isProgrammaticEnabled(shows[selectedShow]);

  const showCrossPromoOptIn =
    selectedShow !== ALL_PODCASTS &&
    CrossPromotions === 0 &&
    !isCrossPromoEnabled(shows?.[selectedShow]);

  const insertionsMap = {
    [InsertionGraphTypes[0]]: {
      value: total,
      link: "",
      showOptIn: false,
    },
    [InsertionGraphTypes[1]]: {
      value: RAPHostread,
      link: `/ad-platform/${selectedShow}`,
      showOptIn: showHostReadOptIn,
    },
    [InsertionGraphTypes[2]]: {
      value: RAPProgrammatic,
      link: `/ad-platform/${selectedShow}`,
      showOptIn: showProgrammaticOptIn,
    },
    [InsertionGraphTypes[3]]: {
      value: CrossPromotions,
      link: "/promotions",
      showOptIn: showCrossPromoOptIn,
    },
    [InsertionGraphTypes[4]]: {
      value: Other,
      link: "",
      showOptIn: false,
    },
  };

  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheData)) {
      setIsDataLoading(true);
      id = setTimeout(() => {
        let filters: StatsRequestFilter = {
          isUnique: true,
          timeRange: "allTime",
          timezone: timeZone,
          type: "insertions",
          interval: "1y",
          bucketTerms: "insertion.isProgrammatic,insertion.type,insertion.showUUID",
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters = { ...filters, showUUID: selectedShow };
        }

        dispatch(
          new InsertionStatsActionManager({
            filters,
            user,
            requestID: cacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsDataLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsDataLoading(false);
          });
      }, 250);
    } else {
      setIsDataLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [selectedShow]);

  return (
    <InfoCard title={{ text: "Dynamic Insertions Overview" }}>
      <div className="analyticsPage-InsertionsOverview">
        {InsertionGraphTypes.map((type) => {
          let title;
          let content;
          let infoText;

          if (type === InsertionGraphTypes[0] || type === InsertionGraphTypes[4]) {
            const split = type.split(" ");
            split.pop();

            title = (
              <>
                {`${split.join(" ")}`} <br /> Insertions
              </>
            );
          } else {
            title = (
              <>
                {`${type}`} <br /> Insertions
              </>
            );
          }

          if (type === InsertionGraphTypes[4]) {
            infoText = "Insertions from self-created Audio Blocks";
          }

          if (tierPermission.Insertions.Overview) {
            if (insertionsMap[type].showOptIn) {
              content = (
                <Button
                  type="secondary"
                  size="small"
                  onClick={() => history.push(insertionsMap[type].link)}>
                  Opt In
                </Button>
              );
            } else {
              content = (
                <h3 className="m-v0">{numeral(insertionsMap[type].value).format("0,0")}</h3>
              );
            }
          } else {
            content = <UpgradeTag />;
          }

          return (
            <HighlightBox
              key={type}
              analytics={true}
              title={title}
              isLoading={isDataLoading}
              infoText={infoText}>
              {content}
            </HighlightBox>
          );
        })}
      </div>
    </InfoCard>
  );
};

// Optimizing re-rendering from parent.
const areEqual = (prevProps: IInsertionOverview, nextProps: IInsertionOverview) => {
  const timeZoneIsSame = prevProps?.timeZone === nextProps?.timeZone;
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;

  return timeZoneIsSame && selectShowIsSame;
};

const MemoizedInsertionOverview = React.memo(InsertionOverview, areEqual);

export default MemoizedInsertionOverview;
