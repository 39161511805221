import React from "react";
import Select from "react-select";
import { primaryColor, primaryColor25, primaryColor50, primaryColor75 } from "../../constants/css";
import DownChevron from "../../icons/down-chevron.svg";
// https://react-select.com/styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    "&:hover": { ...provided["&:hover"], border: "none", cursor: "pointer" },
    fontFamily: "Gilroy-Bold",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.theme.colors.primary,
    fontFamily: "Gilroy-Bold",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": { ...provided["&:hover"], cursor: "pointer" },
    fontFamily: "Gilroy-Regular",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: state.theme.colors.primary,
    color: "white",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: state.theme.colors.primary,
    color: "white",
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
};
const RedCircleSelect = ({ placeholder, ...props }) => {
  return (
    <Select
      styles={customStyles}
      placeholder={
        <span>
          {placeholder} <img src={DownChevron} alt="down-chevron" />
        </span>
      }
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: primaryColor,
          primary25: primaryColor25,
          primary50: primaryColor50,
          primary75: primaryColor75,
        },
      })}
      {...props}
    />
  );
};

export default RedCircleSelect;
