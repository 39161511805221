import { Checkbox as AntdCheckbox, CheckboxProps } from "antd";
import filter from "lodash/filter";
import React from "react";
import InfoTooltip from "../lib/info";

interface ICheckBox extends CheckboxProps {
  checkboxLabel: JSX.Element;
  infoText?: JSX.Element;
  infoDirection?: "top" | "right" | "bottom" | "left";
  useAntd?: boolean; // Backward compatibility
  disabled?: boolean;
}

const Checkbox = (props: ICheckBox) => {
  const { checkboxLabel, infoText, infoDirection, ...rest } = props;
  let children = [
    <span key={"label"} style={{ opacity: rest.disabled ? 0.5 : 1 }}>
      {checkboxLabel}
    </span>,
  ];

  if (infoText) {
    children = children.concat(
      <InfoTooltip key={"tooltip"} helpText={infoText} direction={infoDirection} />
    );
  }
  return <AntdCheckbox {...rest}>{children}</AntdCheckbox>;
};

export const CheckboxField = (props: any) => {
  const { value, options, onChange } = props;

  const currentValue = value || [];

  return options.map((option: any, index: number) => {
    return (
      <div key={index} className="m-bxxs">
        <Checkbox
          key={index}
          checkboxLabel={option.display}
          onChange={() => {
            if (currentValue.includes(option.value)) {
              onChange(filter(currentValue, (v) => v !== option.value));
            } else {
              onChange(currentValue.concat([option.value]));
            }
          }}
          checked={currentValue.includes(option.value)}
        />
      </div>
    );
  });
};

export default Checkbox;
