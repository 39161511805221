import { IShow, IShowMap } from "redcircle-types";
import { isUndefined } from "lodash";

export const isShowRedirected = (show?: IShow) => {
  if (isUndefined(show)) return false;

  // show is part of the small select group of softredirect aways, do not mark them as redirected
  if (show?.softRedirectAwayURL?.length > 0) {
    return false;
  }

  if (typeof show?.redirectAwayURL !== "string") {
    return false;
  }

  // check if redirect away URL has been set and is not empty string;
  return show?.redirectAwayURL?.length > 0;
};

export const getActiveShows = (shows: IShowMap | IShow[]) => {
  const showArray = Array.isArray(shows) ? shows : Object.values(shows);

  const visibleShows = showArray.filter((show) => !show?.isHardDeleted && !!show.isVisible);

  return visibleShows.filter((show) => !isShowRedirected(show));
};
