import { HolderOutlined, WarningFilled } from "@ant-design/icons";
import { Divider, Popover, Select } from "antd";
import moment from "moment";
import React, { ReactElement, ReactNode } from "react";
import { Draggable } from "react-beautiful-dnd";
import { classNames } from "react-extras";
import Button from "src/components/lib/button";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import { ReactComponent as CloseIcon } from "src/icons/modal-close-icon.svg";
import { ReactComponent as RedCircleIconSimple } from "src/icons/redcircle-icon-simple.svg";
import { CustomAudio } from "src/reducers/custom_audio";
import { MediaFile } from "src/reducers/media_file_upload";
import { getCustomAudioInvalids } from "../dynamic_insertion_util";
import styles from "./audio_blocks.module.scss";
import {
  AD_TYPE_AUDIO_FILE,
  AD_TYPE_CROSS_PROMO,
  AD_TYPE_CUSTOM,
  AD_TYPE_DEFAULT,
  AD_TYPE_HUMANIZED,
  IAudioBlockFormItem,
  PLAY_ORDER_INORDER,
  PLAY_ORDER_RANDOM,
} from "./consts";

export default function AudioSelector({
  customAudios = {},
  audioFile,
  index,
  labelIndex,
  onSelect,
  onRemove,
  playStyle,
  disabled,
  isRAPEnabled,
  isCrossPromoEnabled,
  mediaFiles,
}: {
  customAudios?: Record<string, CustomAudio>;
  audioFile: IAudioBlockFormItem;
  index: number;
  labelIndex: number; // index to be rendered
  onSelect: (value: IAudioBlockFormItem) => void;
  onRemove: () => void;
  playStyle: string;
  disabled?: boolean;
  isRAPEnabled?: boolean;
  isCrossPromoEnabled?: boolean;
  isFallback?: boolean;
  mediaFiles?: Record<string, MediaFile>;
}) {
  const hasCustomAudio = customAudios && Object.keys(customAudios).length > 0;
  const isSelected = audioFile.type;
  const customAudio =
    audioFile.customAudioUUID && customAudios ? customAudios[audioFile.customAudioUUID] : undefined;
  const mediaFile =
    mediaFiles && audioFile.type === AD_TYPE_AUDIO_FILE && audioFile.mediaFileUUID
      ? mediaFiles[audioFile.mediaFileUUID]
      : undefined;

  const handleSelectOption = (value: string) => {
    // value can be customAudioId or AD_TYPE - because value type has to be string
    // id is a temp uuid created for tracking individual items in this list
    if (value === AD_TYPE_CROSS_PROMO || value === AD_TYPE_DEFAULT) {
      onSelect({ id: audioFile.id, type: value });
    } else {
      onSelect({ id: audioFile.id, type: AD_TYPE_CUSTOM, customAudioUUID: value });
    }
  };

  const handleSearch = (input: string, option: any) => {
    const { value } = option; // key being validated against
    const searchTerm = input.toLowerCase();

    // default options
    if ([AD_TYPE_DEFAULT, AD_TYPE_CROSS_PROMO].includes(value)) {
      return AD_TYPE_HUMANIZED[value as keyof typeof AD_TYPE_HUMANIZED]
        .toLowerCase()
        .includes(searchTerm);
    }

    // custom audio titles
    return customAudios[value]?.name?.toLowerCase().includes(searchTerm);
  };

  return (
    <Draggable draggableId={audioFile.id} index={index} isDragDisabled={disabled}>
      {(provided) => (
        <div
          key={index}
          className="flex-row-container align-center"
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}>
          <span className="m-rxs" style={{ width: 12 }}>
            {playStyle === PLAY_ORDER_INORDER && labelIndex + 1}
            {playStyle === PLAY_ORDER_RANDOM && "•"}
          </span>

          {/* no item selected */}
          {!isSelected && (
            <Select
              showSearch
              placeholder="Select audio file"
              filterOption={handleSearch}
              onChange={handleSelectOption}
              className="flex-1"
              dropdownRender={(menu) => (
                <CustomAudioDropdown menu={menu} customAudios={customAudios} />
              )}>
              {isRAPEnabled && (
                <Select.Option key={AD_TYPE_DEFAULT} value={AD_TYPE_DEFAULT}>
                  <OptionLabel type={AD_TYPE_DEFAULT} />
                </Select.Option>
              )}

              {isCrossPromoEnabled && (
                <Select.Option key={AD_TYPE_CROSS_PROMO} value={AD_TYPE_CROSS_PROMO}>
                  <OptionLabel type={AD_TYPE_CROSS_PROMO} />
                </Select.Option>
              )}

              {(isRAPEnabled || isCrossPromoEnabled) && (
                <Select.Option disabled key="divider" value="divider">
                  <Divider style={{ margin: "10px 0 5px" }} />
                </Select.Option>
              )}

              {hasCustomAudio &&
                Object.values(customAudios).map((customAudio: any) => {
                  return (
                    <Select.Option key={customAudio.uuid} value={customAudio.uuid}>
                      <OptionLabel type={AD_TYPE_CUSTOM} customAudio={customAudio} />
                    </Select.Option>
                  );
                })}
            </Select>
          )}

          {/* item selected */}
          {isSelected && (
            <div className={styles["audio-block-selected"]}>
              <OptionPopover customAudio={customAudio}>
                <div className="flex-row-container align-center flex-1">
                  {!disabled && <HolderOutlined className="m-rxxs" />}
                  <div className="flex-row-container align-center">
                    <OptionLabel
                      type={audioFile.type}
                      customAudio={customAudio}
                      mediaFile={mediaFile}
                      isSelected
                    />
                  </div>
                </div>
              </OptionPopover>

              {!disabled && (
                <Button type="link" onClick={onRemove} style={{ height: "auto" }}>
                  <CloseIcon className="flex-row-container" width={16} height={16} opacity={0.5} />
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
}

const CustomAudioDropdown = ({
  menu,
  customAudios,
}: {
  menu: ReactElement;
  customAudios: Record<string, CustomAudio>;
}) => {
  const hasCustomAudio = customAudios && Object.keys(customAudios).length > 0;
  return (
    <>
      {menu}
      {!hasCustomAudio && (
        <div className="text-subtle m-lxs m-rxs text-center m-bxxs">
          No Custom Audio Files - to insert Custom Audio into this Audio Block, first upload a file
          on the Custom Audio tab
        </div>
      )}
    </>
  );
};

export const OptionPopover = ({
  customAudio,
  children,
}: {
  customAudio?: CustomAudio;
  children: ReactNode;
}) => {
  if (!customAudio) return <>{children}</>;

  const { insertions, maxInsertions, isPaused, startAt, endAt } = customAudio;
  const startAtFormatted = startAt ? moment(startAt * 1000).format("M/D/YY") : "N/A";
  const endAtFormatted = endAt ? moment(endAt * 1000).format("M/D/YY") : "N/A";
  const invalidReasons = getCustomAudioInvalids(customAudio);
  const isInvalid = Object.keys(invalidReasons).length > 0;

  const TargetingRuleLabel = ({
    rule,
    label,
    value,
  }: {
    rule: "paused" | "impressions" | "date";
    label: string;
    value: string | ReactNode;
  }) => {
    return (
      <span className={classNames(invalidReasons[rule] && styles.invalid, "m-bxxxs")}>
        {invalidReasons[rule] && <WarningFilled className="m-rxxxs" />}
        {label} | {value}
      </span>
    );
  };

  const content = (
    <small>
      <div className="flex-column-container">
        <strong className="m-bxxs">This audio will be {isInvalid ? "skipped" : "played"}.</strong>
        {insertions ||
          (maxInsertions && (
            <TargetingRuleLabel
              rule="impressions"
              label="Impressions"
              value={`${insertions || 0}${maxInsertions ? `/${maxInsertions}` : ""}`}
            />
          ))}
        {(startAt || endAt) && (
          <TargetingRuleLabel
            rule="date"
            label="Timeframe"
            value={`${startAtFormatted} - ${endAtFormatted}`}
          />
        )}
        <TargetingRuleLabel
          rule="paused"
          label="Status"
          value={
            isPaused ? (
              <ExclusiveTag className="tag-sent">Paused</ExclusiveTag>
            ) : (
              <ExclusiveTag className="tag-running">Active</ExclusiveTag>
            )
          }
        />
      </div>
    </small>
  );
  return <Popover content={content}>{children}</Popover>;
};

export const OptionLabel = ({
  type,
  customAudio,
  isSelected,
  mediaFile,
}: {
  type?: string;
  customAudio?: CustomAudio;
  isSelected?: boolean;
  mediaFile?: MediaFile;
}) => {
  const invalidReasons = getCustomAudioInvalids(customAudio);
  const isInvalid = Object.keys(invalidReasons).length > 0;

  return (
    <div className="flex-column-container">
      {isSelected && isInvalid && (
        <small className={classNames(styles.invalid, "m-txxxs")}>
          <WarningFilled className={"m-rxxxs"} />
          Audio will be skipped
        </small>
      )}
      <div className="flex-row-container align-center">
        {(type === AD_TYPE_CROSS_PROMO || type === AD_TYPE_DEFAULT) && (
          <>
            <RedCircleIconSimple className="m-rxxxs" />
            <span>{AD_TYPE_HUMANIZED[type as keyof typeof AD_TYPE_HUMANIZED]}</span>
          </>
        )}
        {type === AD_TYPE_CUSTOM && customAudio && (
          <>
            {isInvalid && !isSelected && (
              <WarningFilled className={classNames("m-rxxxs", styles.invalid)} />
            )}
            <span className={classNames(isInvalid && "text-subtle")}>{customAudio.name}</span>
          </>
        )}
        {type === AD_TYPE_AUDIO_FILE && mediaFile && <span>{mediaFile.fileName}</span>}
        {type === AD_TYPE_AUDIO_FILE && !mediaFile && <span>Audio File</span>}
      </div>
    </div>
  );
};
