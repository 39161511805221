import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { Component } from "react";
import Dimensions from "react-dimensions";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { hummanizeNumber } from "../../../lib/numbers";

class DownloadsGraph extends Component {
  state = {
    timeRange: "year",
  };

  componentDidMount() {
    this.setState({ timeRange: "year" });
    this.fetchData("year");
  }

  fetchData = (timeRange) => {
    this.props.fetchStats(
      {
        isUnique: true,
        [this.props.uuidType]: this.props.uuid,
        timeRange: timeRange,
      },
      this.props.user,
      this.statsRequestID()
    );
  };

  statsRequestID = () => {
    return this.props.uuid + "downloads-graph";
  };

  getData = () => {
    var stats = get(this.props.stats, this.statsRequestID(), []);
    var data = stats.map((item) => {
      return {
        x: new Date(item.date * 1000),
        y: item.count,
      };
    });
    return data;
  };

  getDomain = (data) => {
    if (!data) {
      return {
        x: [
          new Date(new Date().valueOf() - 1000 * 3600 * 24 * 14), // two weeks ago
          new Date(),
        ],
        y: [0, 100],
      };
    }
    var yData = data.map((item) => {
      return item.y;
    });
    var xData = data.map((item) => {
      return item.x;
    });

    var highestValue = Math.max(...yData);
    var yMin = 0;
    var yMax = highestValue !== 0 ? highestValue : 100;
    var xMin = new Date(Math.min(...xData));
    var xMax = new Date(Math.max(...xData));
    var domain = {
      y: [yMin, yMax],
      x: [xMin, xMax],
    };
    return domain;
  };

  getDataWithLabels = (data) => {
    return data.map((item) => {
      return { ...item, label: this.renderDateLabel(item.x) + " - " + hummanizeNumber(item.y) };
    });
  };

  renderDateLabel = (date) => {
    switch (this.state.timeRange) {
      case "day":
        return moment(date, "X").format("M/D h:mm");
      case "week":
      case "month":
        return moment(date, "X").format("M/D");
      default:
        return moment(date, "X").format("MMM");
    }
  };

  render() {
    var data = this.getData();
    if (isEmpty(data)) {
      return null;
    }
    return (
      <div className="stats-width-graph-height">
        <div className="downloads-graph__time-selector">
          <select
            className="form-control"
            onChange={(e) => {
              this.setState({ timeRange: e.target.value });
              this.fetchData(e.target.value);
            }}>
            <option value="year">12 Months</option>
            <option value="month">30 Days</option>
            <option value="week">7 Days</option>
            <option value="day">24 Hours</option>
          </select>
        </div>
        <VictoryChart
          containerComponent={<VictoryVoronoiContainer />}
          height={this.props.containerHeight}
          width={this.props.containerWidth}
          padding={{ left: 50, top: 30, right: 30, bottom: 30 }}
          scale={{ x: "time" }}
          domain={this.getDomain(data)}>
          <VictoryAxis
            style={{
              parent: { fontFamily: "Gilroy-Bold, sans-serif" },
              axis: { stroke: "#000", opacity: 0.11 },
              tickLabels: {
                fontSize: 15,
                padding: 5,
                fontFamily: "Gilroy-Bold, sans-serif",
              },
            }}
            tickFormat={this.renderDateLabel}
            fixLabelOverlap={true}
            tickCount={4}
          />
          <VictoryAxis
            dependentAxis
            tickCount={3}
            style={{
              axis: { stroke: "none", fontFamily: "Gilroy-Bold, sans-serif" },
              grid: { stroke: "#000", opacity: 0.11 },
              tickLabels: {
                fontSize: 15,
                padding: 5,
                fontFamily: "Gilroy-Bold, sans-serif",
              },
              parent: { fontFamily: "Gilroy-Bold, sans-serif" },
            }}
            tickFormat={(y) => hummanizeNumber(y)}
          />
          <VictoryLine
            style={{
              data: { stroke: "#577D9E" },
            }}
            data={data}
          />
          <VictoryScatter
            style={{
              data: { fill: "#577D9E", stroke: "#010831", strokeWidth: 1 },
            }}
            size={3}
            data={this.getDataWithLabels(data)}
            labelComponent={
              <VictoryTooltip
                cornerRadius={4}
                style={{
                  fontFamily: "Gilroy-Bold, sans-serif",
                  fill: "#000",
                }}
                flyoutStyle={{
                  fill: "#FFF",
                }}
                pointerLength={5}
                dy={-15}
              />
            }
          />
        </VictoryChart>
      </div>
    );
  }
}

export default Dimensions()(DownloadsGraph);
