import colors from "./colors.module.scss";

type ColorMap = {
  PRIMARY_COLOR: string;
  PRIMARY_COLOR_HOVER: string;
  PRIMARY_COLOR_ACTIVE: string;
  PRIMARY_COLOR_DARK: string;
  DIVIDER_COLOR: string;
  COLOR_SUCCESS: string;
  COLOR_WARNING: string;
  BLUE_MEDIUM: string;
  BLUE_LIGHT: string;
  BLUE_LIGHTER: string;
  WHITE_ANTI_FLASH: string;
  GRAY_LIGHT: string;
  GRAY_LIGHTER: string;
  GRAY_LIGHTEST: string;
  GRAY_DARK: string;
};

export const COLORS: ColorMap = colors as ColorMap;
