import { Modal } from "antd";
import React, { useState } from "react";
import { showError } from "../../../actions/app";
import { cancelAudioSwap } from "../../../action_managers/campaigns";
import { useReduxDispatch } from "../../../hooks/redux-ts";
import LoadingButton from "../../forms/loading_button/loading_button";
import Divider from "../../lib/divider";
import "./swap_audio_modal.scss";

interface ICancelAudioSwap {
  visible: boolean;
  setVisible: (newState: boolean) => void;
  campaignItemUUID: string;
  onCancel?: () => void;
}

const CancelAudioSwap = (props: ICancelAudioSwap) => {
  const { visible, setVisible, campaignItemUUID } = props;

  const dispatch = useReduxDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const pages = [
    {
      title: (
        <div>
          <h4 className="bold title--2 m-bxxs">Cancel Audio Replacement Request</h4>
          <Divider marginTop={16} marginBottom={0} />
        </div>
      ),
      body: (
        <p className="title--4 m-vs">
          Canceling this request will unpause the campaign on this podcast and their previous ad
          audio will continue to run.
        </p>
      ),
      footer: (
        <div className="flex-row-container align-center justify-end">
          <LoadingButton
            isLoading={isLoading}
            onClick={() => handleCancelAudioSwap()}
            className="btn btn-primary btn-large fs-xs lh-s p-hl m-b0 capitalize">
            Confirm
          </LoadingButton>
        </div>
      ),
    },
  ];

  // Handlers

  const handleCancelAudioSwap = () => {
    setIsLoading(true);
    return dispatch(cancelAudioSwap(campaignItemUUID))
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(
            showError(
              "There was an issue cancelling the audio swap request, please try again later",
              5000
            )
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
        handleCancel();
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setCurrentPage(0);
  };

  return (
    <Modal
      wrapClassName="RC-Antd-Override-Modal footer-shadow"
      width={600}
      open={visible}
      centered={true}
      onCancel={() => handleCancel()}
      title={pages[currentPage]?.title}
      footer={pages[currentPage]?.footer}>
      {pages[currentPage]?.body}
    </Modal>
  );
};

export default CancelAudioSwap;
