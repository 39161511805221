import { useState } from "react";
import { AiOutlineTool } from "react-icons/ai";
import { matchPath, useLocation } from "react-router-dom";
import RCButton from "src/components/lib/button/button";
import RCDrawer from "src/components/lib/drawer/Drawer";
import { useSelectorTS } from "src/hooks/redux-ts";
import styles from "./admin_panel.module.scss";
import TierControls from "./tier-controls";

const Section = ({ children }: { children: React.ReactNode }) => (
  <div className="p-axs m-bxs" style={{ border: `1px solid lightgray`, borderRadius: 4 }}>
    {children}
  </div>
);

const AdminPanel = () => {
  const [open, setOpen] = useState(false);
  const path = useLocation().pathname;

  const { user } = useSelectorTS((state) => state.user);

  const { shows } = useSelectorTS((state) => state.shows);
  const showUUIDPath: any = matchPath(path, { path: "/shows/:showUUID" })?.params;
  const showUUIDPath2: any = matchPath(path, { path: "/ad-platform/:showUUID" })?.params;
  const showUUID = showUUIDPath?.showUUID || showUUIDPath2?.showUUID;

  const campaigns = useSelectorTS((state) => state.campaigns.campaigns);
  const campaignUUIDPath: any = matchPath(path, { path: "*/campaigns/:campaignUUID" })?.params;
  const campaignUUID = campaignUUIDPath?.campaignUUID;

  if (!user) return null;
  return (
    <>
      {!open && (
        <RCButton type="icon" size="large" className={styles.button} onClick={() => setOpen(!open)}>
          <AiOutlineTool />
        </RCButton>
      )}

      <RCDrawer open={open} onClose={() => setOpen(false)} anchor="right">
        {open && (
          <>
            <RCDrawer.Header>
              <h2>Admin Panel</h2>
            </RCDrawer.Header>

            <RCDrawer.Body>
              <Section>
                <TierControls />
              </Section>

              {campaignUUID && campaigns[campaignUUID] && (
                <Section>
                  <h4>Current Campaign: {campaigns[campaignUUID]?.name}</h4>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://tools.redcircle.com/campaigns/?campaign_uuid=${campaignUUID}`}>
                    Snacktrack Link
                  </a>
                </Section>
              )}

              {showUUID && shows[showUUID] && (
                <Section>
                  <h4>Current Show: {shows[showUUID].title}</h4>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://tools.redcircle.com/shows/${showUUID}`}>
                    Snacktrack Link
                  </a>
                </Section>
              )}

              {user && (
                <Section>
                  <h4>
                    Current User: {user.firstName} {user.lastName}
                  </h4>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://tools.redcircle.com/users/${user.uuid}`}>
                    Snacktrack Link
                  </a>
                </Section>
              )}
            </RCDrawer.Body>
          </>
        )}
      </RCDrawer>
    </>
  );
};

export default AdminPanel;
