import { Modal } from "redcircle-ui";
import { removeCampaignItems } from "src/action_managers/campaigns";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { isUUID } from "src/lib/uuid";

interface IRemovePodcastFromCart {
  itemUUIDToRemove?: string;
  setItemUUIDToRemove: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const RemovePodcastFromCart = ({
  itemUUIDToRemove = "",
  setItemUUIDToRemove,
}: IRemovePodcastFromCart) => {
  const dispatch = useReduxDispatch();
  const { campaignItems } = useSelectorTS((state) => state.campaignItems);
  const campaignItem = campaignItems?.[itemUUIDToRemove];
  const publicShows = useSelectorTS((state) => state.publicShows);

  const showUUID = campaignItem?.showUUID ?? "";
  const publicShow = publicShows?.[showUUID];

  const visible = isUUID(itemUUIDToRemove);

  const handleClose = () => {
    setItemUUIDToRemove(undefined);
  };

  const handleSubmit = () => {
    const campaignUUID = campaignItem.campaignUUID;
    dispatch(
      removeCampaignItems(campaignUUID, [itemUUIDToRemove], {
        successMessage: `${publicShow.title} has been successfully removed from cart.`,
      })
    ).then((resp) => {
      if (resp.status === 200) {
        handleClose();
      }
    });
  };

  return (
    <Modal size="xs" open={visible} onClose={handleClose} onSubmit={handleSubmit}>
      <Modal.Title>Remove Podcast from Cart</Modal.Title>
      <Modal.Body>
        <p>
          Are you sure you want to remove <strong>{publicShow?.title}</strong> ?
        </p>
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton>Remove</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};
