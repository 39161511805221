import dayjs from "dayjs";
import {
  ForecastEndDateFailureResponse,
  ForecastEndDateRequest,
  ForecastEndDateResponse,
  ForecastImpressionFailureResponse,
  ForecastImpressionResponse,
  ForecastImpressionsRequest,
  getCampaignForecastEndDates,
  getCampaignForecastImpressions,
} from "src/api/campaigns";
import { getCampaignCPMsForShow } from "src/lib/campaigns";
import { UnixTimeStamp } from "src/lib/date";
import { ICampaignItem } from "src/reducers/campaign_items";
import { ICampaign } from "src/reducers/campaigns/types";
import { PublicShow, PublicShowsReduxState } from "src/reducers/public_show";
import { User } from "src/reducers/user";
import { useReduxDispatch } from "./redux-ts";
import { useEffect, useReducer, useRef } from "react";
import {
  IMaxImpressionReducer,
  IMinEndDatesReducer,
  convertRespToImpressionsMap,
  maxImpressionReducer,
  minEndDatesReducer,
} from "src/components/modals/campaign_schedule_podcast/continuous_scheduler_helpers";
import { parallelLimit } from "async";
import { showWarning } from "src/actions/app";

const MAX_SHOWS_PER_STREAMULATOR_REQUEST = 20;
const MAX_NUM_OF_PARALLEL_REQUEST = 4;

/**
 * Builds a array of requests to fetch forecast end date. This helper function takes into
 * MAX_SHOWS_PER_STREAMULATOR_REQUEST, and ommit requests that have already been cached
 */
const constructForecastEndDatesRequest = ({
  draftItems,
  campaign,
  budgets,
  startDate,
  mapCampaignItemUUIDToShow,
  mapShowtoCampaignItemUUID,
  cache,
}: {
  draftItems: ICampaignItem[];
  campaign: ICampaign;
  budgets: { [campaignItemUUID: string]: number };
  startDate: number;
  mapCampaignItemUUIDToShow: {
    [campaignItemUUID: string]: PublicShow;
  };
  mapShowtoCampaignItemUUID: {
    [showUUID: string]: string;
  };
  cache: Partial<
    Record<
      string,
      Record<
        string,
        ForecastEndDateResponse["resultsByShowUUID"][keyof ForecastEndDateResponse["resultsByShowUUID"]]
      >
    >
  >;
}) => {
  const cacheKey = constructEndDateCachekey({ campaign, startDate });

  const unCachedDraftItems = draftItems.filter((campaignItem) => {
    const { showUUID } = campaignItem;
    const showKey = constructEndDateShowKey({ showUUID, budgets, mapShowtoCampaignItemUUID });

    const cachedVal = (cache[cacheKey] ?? {})[showKey];

    return typeof cachedVal?.estimatedEndTime !== "number";
  });

  const positions = Object.entries(campaign.targetingOptions).reduce(
    (accu, curr) => {
      const [key, val] = curr;

      if (val) {
        accu[key.toUpperCase() as keyof ForecastEndDateRequest["positions"]] = {};
      }
      return accu;
    },
    {} as ForecastEndDateRequest["positions"]
  );

  const requests: ForecastEndDateRequest[] = [];
  let currReqIndex = 0;

  for (let i = 0; i < unCachedDraftItems.length; i++) {
    if (typeof requests?.[currReqIndex] !== "object") {
      requests.push({
        campaignItems: [],
        positions,
        startDate,
        recentEpisodesOnly: campaign?.recentEpisodesOnly,
        frequencyConfigs: campaign?.frequencyConfigs,
      });
    }

    const item = unCachedDraftItems[i];
    const publicShow = mapCampaignItemUUIDToShow[item.uuid];
    const formattedCPM = Object.entries(
      getCampaignCPMsForShow({ campaign, campaignItem: item, show: publicShow })
    ).reduce(
      (accu, curr) => {
        const [key, val] = curr;
        accu[key?.toUpperCase() as keyof ForecastEndDateRequest["campaignItems"][number]["cpm"]] =
          (val || 0) * 1000;

        return accu;
      },
      {} as ForecastEndDateRequest["campaignItems"][number]["cpm"]
    );

    requests?.[currReqIndex]?.campaignItems?.push({
      showUUID: item.showUUID,
      cpm: formattedCPM,
      budget: Math.round(budgets[item.uuid] * 1000),
    });

    if (requests?.[currReqIndex]?.campaignItems.length >= MAX_SHOWS_PER_STREAMULATOR_REQUEST) {
      currReqIndex++;
    }
  }

  return requests;
};
const constructEndDateCachekey = ({
  campaign,
  startDate,
}: {
  campaign: ICampaign;
  startDate: number;
}) => {
  const positions = `${Object.keys(campaign.targetingOptions).sort().join("-")}`;
  const freqConfigsStr = Object.values(campaign.frequencyConfigs ?? {})
    .sort((a, b) => a.interval.localeCompare(b.interval))
    .map(({ interval, maxCount }) => `${interval}:${maxCount}`)
    .join(",");

  return `${campaign.uuid}_${dayjs
    .unix(startDate)
    .format("MM/DD/YYYY")}_${positions}_${freqConfigsStr}_${campaign.recentEpisodesOnly}`;
};
const constructEndDateShowKey = ({
  showUUID,
  budgets,
  mapShowtoCampaignItemUUID,
}: {
  showUUID: string;
  budgets: { [campaignItemUUID: string]: number };
  mapShowtoCampaignItemUUID: {
    [showUUID: string]: string;
  };
}) => {
  return `${showUUID}_${budgets[mapShowtoCampaignItemUUID[showUUID]]}`;
};

/**
 * Constructs an array of streamulator impression forecast requests. Takes into account cached
 * showUUIDs and only returns a request array for showUUIDs that are not cached
 */
const constructForecastImpressionsRequest = ({
  campaign,
  draftItems,
  startDate,
  endDate,
  cache,
}: {
  campaign: ICampaign;
  draftItems: ICampaignItem[];
  startDate: number;
  endDate: number;
  cache: Partial<
    Record<
      string,
      Record<
        string,
        ForecastImpressionResponse["resultsByShowUUID"][keyof ForecastEndDateResponse["resultsByShowUUID"]]
      >
    >
  >;
}) => {
  const cacheKey = constructImpressionsCachekey({ campaign, startDate, endDate });

  const unCachedDraftItems = draftItems.filter((campaignItem) => {
    const { showUUID } = campaignItem;
    const showKey = constructImpressionsShowKey({ showUUID });

    const cachedVal = (cache[cacheKey] ?? {})[showKey];

    return (
      typeof cachedVal?.streamulatorErrored !== "boolean" ||
      (typeof cachedVal?.streamulatorErrored === "boolean" &&
        cachedVal.streamulatorErrored === true)
    );
  });

  const positions = Object.entries(campaign.targetingOptions).reduce(
    (accu, curr) => {
      const [key, val] = curr;

      if (val) {
        accu[key.toUpperCase() as keyof ForecastImpressionsRequest["positions"]] = {};
      }
      return accu;
    },
    {} as ForecastImpressionsRequest["positions"]
  );

  const requests: ForecastImpressionsRequest[] = [];
  let currReqIndex = 0;

  for (let i = 0; i < unCachedDraftItems.length; i++) {
    if (typeof requests?.[currReqIndex] !== "object") {
      requests.push({
        showUUIDs: [],
        positions,
        startDate,
        endDate,
        recentEpisodesOnly: campaign?.recentEpisodesOnly,
        frequencyConfigs: campaign?.frequencyConfigs,
      });
    }

    const item = unCachedDraftItems[i];
    const { showUUID } = item;

    requests?.[currReqIndex]?.showUUIDs?.push(showUUID);

    if (requests?.[currReqIndex]?.showUUIDs.length >= MAX_SHOWS_PER_STREAMULATOR_REQUEST) {
      currReqIndex++;
    }
  }

  return requests;
};
const constructImpressionsCachekey = ({
  campaign,
  startDate,
  endDate,
}: {
  campaign: ICampaign;
  startDate: number;
  endDate: number;
}) => {
  const positions = `${Object.keys(campaign.targetingOptions).sort().join("-")}`;
  const freqConfigsStr = Object.values(campaign.frequencyConfigs ?? {})
    .sort((a, b) => a.interval.localeCompare(b.interval))
    .map(({ interval, maxCount }) => `${interval}:${maxCount}`)
    .join(",");

  return `${campaign.uuid}_${dayjs.unix(startDate).format("MM/DD/YYYY")}_${dayjs
    .unix(endDate)
    .format("MM/DD/YYYY")}_${positions}_${freqConfigsStr}_${campaign.recentEpisodesOnly}`;
};
const constructImpressionsShowKey = ({ showUUID }: { showUUID: string }) => {
  return `${showUUID}`;
};

/**
 * Helper Hook to encapsulate forecast timeline logic
 */
export const useForecastEndDates = ({
  isPacing,
  user,
  campaign,
  draftItems,
  publicShows,
  budgets,
  startDate,
}: {
  isPacing: boolean;
  user: User;
  campaign: ICampaign;
  draftItems: ICampaignItem[];
  publicShows: PublicShowsReduxState;
  budgets: { [campaignItemUUID: string]: number };
  startDate: UnixTimeStamp;
}) => {
  const dispatch = useReduxDispatch();

  const [minEndDatesState, dispatchMinEndDates] = useReducer<IMinEndDatesReducer>(
    minEndDatesReducer,
    { isLoading: false, minEndDates: {}, error: undefined }
  );

  const isPublicShowsLoaded =
    publicShows?.isLoading === false &&
    draftItems?.every(({ showUUID }) => publicShows?.[showUUID]?.uuid === showUUID);

  const isBudgetsEmpty = draftItems?.length > 0 && Object.keys(budgets)?.length === 0;

  const { mapCampaignItemUUIDToShow, mapShowtoCampaignItemUUID } = draftItems?.reduce(
    (accu, curr) => {
      const { showUUID, uuid } = curr;

      accu.mapCampaignItemUUIDToShow[uuid] = publicShows?.[showUUID] ?? {};

      accu.mapShowtoCampaignItemUUID[showUUID] = uuid;

      return accu;
    },
    { mapCampaignItemUUIDToShow: {}, mapShowtoCampaignItemUUID: {} } as {
      mapCampaignItemUUIDToShow: { [campaignItemUUID: string]: PublicShow };
      mapShowtoCampaignItemUUID: { [showUUID: string]: string };
    }
  );

  // All data needed for starting requests
  const preReqDataIsReady =
    !isBudgetsEmpty &&
    isPublicShowsLoaded &&
    !!publicShows &&
    !isPacing &&
    !!user &&
    !!campaign &&
    typeof startDate === "number" &&
    draftItems?.length > 0;

  const budgetTotal = Object.values(budgets ?? {}).reduce((accu, curr) => accu + curr, 0);
  const budgetLength = Object.keys(budgets ?? {}).length;

  const budgetChangeSignal = `${budgetTotal}_${budgetLength}`;

  const cache = useRef<
    Partial<
      Record<
        string,
        Record<
          string,
          ForecastEndDateResponse["resultsByShowUUID"][keyof ForecastEndDateResponse["resultsByShowUUID"]]
        >
      >
    >
  >({});
  const cacheKey = constructEndDateCachekey({ campaign, startDate });

  /**
   * Request new forecasted timeline
   */
  useEffect(() => {
    if (preReqDataIsReady && !minEndDatesState.isLoading) {
      // Array of requests, already partitioned for MaxShowPerRequest limit, and cached
      // requests have been omitted
      const requests = constructForecastEndDatesRequest({
        campaign,
        draftItems,
        budgets,
        startDate,
        mapCampaignItemUUIDToShow,
        mapShowtoCampaignItemUUID,
        cache: cache.current,
      });

      const tasks = requests.map((request) => (callback: any) => {
        getCampaignForecastEndDates(request, user)
          .then((resp) => {
            return resp?.json().then((val) => {
              if (resp.status !== 200) {
                callback(null, {
                  status: resp.status,
                  val: val.details,
                  message: val.message,
                });
              } else {
                callback(null, { status: resp.status, val, message: "" });
              }
            });
          })
          .catch((err) => callback(err));
      });

      dispatchMinEndDates({ type: "request" });

      parallelLimit<
        { status: number; val: ForecastEndDateResponse; message: string },
        ForecastEndDateFailureResponse
      >(tasks, MAX_NUM_OF_PARALLEL_REQUEST, (err, results) => {
        const listOfFailedPodcasts: string[] = [];
        if (Array.isArray(results)) {
          for (const item of results) {
            if (typeof item === "undefined") continue; // if the result item is undefined, go to next one;

            const { status, message, val } = item;

            const respMinEndDates = val["resultsByShowUUID"];

            for (const [showUUID, value] of Object.entries(respMinEndDates)) {
              const showKey = constructEndDateShowKey({
                showUUID,
                budgets,
                mapShowtoCampaignItemUUID,
              });

              // Cache the results
              if (cache.current) {
                if (typeof cache.current[cacheKey] === "undefined") {
                  cache.current[cacheKey] = {};
                }

                cache.current[cacheKey][showKey] = value;
              }

              if (value.streamulatorErrored) {
                listOfFailedPodcasts.push(publicShows[showUUID].title ?? "");
              }
            }
          }
        }

        const finalErrorMessage = `The inventory of ${listOfFailedPodcasts.length > 1 ? "these podcasts" : "this podcast"} cannot be predicted: ${listOfFailedPodcasts.join(", ")}. Please remove ${listOfFailedPodcasts.length > 1 ? "these" : "this"} podcast from the list to continue or size your campaigns manually.`;

        if (listOfFailedPodcasts.length > 0) {
          dispatch(showWarning(finalErrorMessage, 10000));
        }

        /**
         *  Build response from cached values. Cache  will have any podcasts streamulator estimate that succeeded,
         *  even if 1 or more failures occurred in a batch as long as there were successful estimated, it will be
         *  found here.
         */
        const finalMinEndDates = draftItems.reduce(
          (accu, campaignItem) => {
            const { showUUID } = campaignItem;
            const showKey = constructEndDateShowKey({
              showUUID,
              budgets,
              mapShowtoCampaignItemUUID,
            });
            const cachedVal = (cache.current[cacheKey] ?? {})[showKey];

            accu[showUUID] = { ...cachedVal };

            return accu;
          },
          {} as ForecastEndDateResponse["resultsByShowUUID"]
        );

        // Update final state
        dispatchMinEndDates({
          type: listOfFailedPodcasts.length > 0 ? "failure" : "success", // its a failure state if even 1 podcast (in any batch) fails.
          error: listOfFailedPodcasts.length > 0 ? finalErrorMessage : undefined,
          minEndDates: finalMinEndDates,
        });
      });
    }
  }, [preReqDataIsReady, budgetChangeSignal, startDate]);

  return minEndDatesState;
};

export const useForecastImpressions = ({
  isPacing,
  user,
  campaign,
  publicShows,
  draftItems,
  startDate,
  endDate,
}: {
  isPacing: boolean;
  user: User;
  campaign: ICampaign;
  publicShows: PublicShowsReduxState;
  draftItems: ICampaignItem[];
  startDate: UnixTimeStamp;
  endDate: UnixTimeStamp;
}) => {
  const dispatch = useReduxDispatch();
  const [maxImpressionsState, dispatchMaxImpressions] = useReducer<IMaxImpressionReducer>(
    maxImpressionReducer,
    { isLoading: false, maxImpressions: {}, error: undefined }
  );

  // All data needed for starting requests
  const preReqDataIsReady =
    isPacing &&
    !!user &&
    !!campaign?.targetingOptions &&
    draftItems?.length > 0 &&
    typeof startDate === "number" &&
    typeof endDate === "number";

  const cache = useRef<
    Partial<
      Record<
        string,
        Record<
          string,
          ForecastImpressionResponse["resultsByShowUUID"][keyof ForecastEndDateResponse["resultsByShowUUID"]]
        >
      >
    >
  >({});

  const cacheKey = constructImpressionsCachekey({ campaign, startDate, endDate });

  useEffect(() => {
    if (preReqDataIsReady && !maxImpressionsState.isLoading) {
      const requests = constructForecastImpressionsRequest({
        campaign,
        draftItems,
        startDate,
        endDate,
        cache: cache.current,
      });

      dispatchMaxImpressions({ type: "request" });

      const tasks = requests.map((request) => (callback: any) => {
        getCampaignForecastImpressions(request, user)
          .then((resp) => {
            return resp?.json().then((val) => {
              if (resp.status !== 200) {
                callback(null, {
                  status: resp.status,
                  val: val.details,
                  message: val.message,
                });
              } else {
                callback(null, { status: resp.status, val, message: "" });
              }
            });
          })
          .catch((err: ForecastImpressionFailureResponse) => {
            callback(err);
          });
      });

      parallelLimit<
        { status: number; val: ForecastImpressionResponse; message: string },
        ForecastImpressionFailureResponse
      >(tasks, MAX_NUM_OF_PARALLEL_REQUEST, (err, results) => {
        const listOfFailedPodcasts: string[] = [];

        if (Array.isArray(results)) {
          // iterate over the results array, which is an array of streamulator forecast impressions responses
          for (const item of results) {
            if (typeof item === "undefined") continue; // if the result item is undefined, go to next one;
            const { status, val, message } = item;
            const resultsByShowUUID = val["resultsByShowUUID"];

            for (const [showUUID, value] of Object.entries(resultsByShowUUID)) {
              const showKey = constructImpressionsShowKey({
                showUUID,
              });

              // cache the values
              if (cache.current) {
                if (typeof cache.current[cacheKey] === "undefined") {
                  cache.current[cacheKey] = {};
                }

                cache.current[cacheKey][showKey] = value;
              }

              if (value.streamulatorErrored) {
                listOfFailedPodcasts.push(publicShows[showUUID].title ?? "");
              }
            }
          }
        }

        const finalErrorMessage = `The inventory of ${listOfFailedPodcasts.length > 1 ? "these podcasts" : "this podcast"} cannot be predicted: ${listOfFailedPodcasts.join(", ")}. Please remove ${listOfFailedPodcasts.length > 1 ? "these" : "this"} podcast from the list to continue or size your campaigns manually.`;

        if (listOfFailedPodcasts.length > 0) {
          dispatch(showWarning(finalErrorMessage, 10000));
        }

        /**
         *  Build response from cached values. Cache  will have any podcasts streamulator estimate that succeeded,
         *  even if 1 or more failures occurred in a batch as long as there were successful estimated, it will be
         *  found here.
         */
        const finalMaxImpressions = draftItems.reduce(
          (accu, campaignItem) => {
            const { showUUID } = campaignItem;
            const showKey = constructImpressionsShowKey({
              showUUID,
            });
            const cachedVal = (cache.current[cacheKey] ?? {})[showKey];

            accu[showUUID] = { ...cachedVal };

            return accu;
          },
          {} as ForecastImpressionResponse["resultsByShowUUID"]
        );

        // Update final state
        dispatchMaxImpressions({
          type: listOfFailedPodcasts.length > 0 ? "failure" : "success", // its a failure state if even 1 podcast (in any batch) fails.
          error: listOfFailedPodcasts.length > 0 ? finalErrorMessage : undefined,
          maxImpressions: convertRespToImpressionsMap({
            resultsByShowUUID: finalMaxImpressions,
          }),
        });
      });
    }
  }, [startDate, endDate, preReqDataIsReady, draftItems?.length]);

  return maxImpressionsState;
};
