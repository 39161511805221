import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioGroupProps,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useCallback } from "react";
import LoadingImage from "src/components/lib/image/image";
import RCTable from "src/components/lib/tables/rc_table/rc_table";
import { CampaignItemStateDraft } from "src/constants/campaigns";
import { MULTIPLE_CHOICE_TYPE_CHECKBOX, VETTING_QUESTION_TYPES } from "src/constants/vetting";
import { useGetCampaignItems } from "src/hooks/campaignItems";
import { useSelectorTS } from "src/hooks/redux-ts";
import {
  useGetVettingForm,
  useGetVettingFormsForCampaign,
  useGetVettingInvitations,
} from "src/hooks/vetting";
import { cloneDateAs12amEST, UnixTimeStamp } from "src/lib/date";
import { pluralize } from "src/lib/strings";
import { isUUID } from "src/lib/uuid";
import { isInvitationPendingOnItem, sortVettingInvitationsByStatus } from "src/lib/vetting";
import { ICampaignItem } from "src/reducers/campaign_items";
import {
  CampaignItemVettingStatus,
  CheckBoxHeaderComponent,
  colConfig,
  MaxMultipleChoiceFields,
  MinMultipleChoiceFields,
  VettingFormValues,
} from "./vetting_form_modal_helpers";
import { AiFillInfoCircle } from "react-icons/ai";
import QuillEditor from "src/components/forms/quill_component";

interface IVettingFormInitializePage {
  form: FormInstance<VettingFormValues>;
  campaignUUID?: string;
  formUUID: string;
  setFormUUID: Dispatch<SetStateAction<string>>;
}

export const VettingFormInitializePage = ({
  form,
  campaignUUID = "",
  formUUID,
  setFormUUID,
}: IVettingFormInitializePage) => {
  const { formsByUUID, formsByCampaignUUID, isLoading } =
    useGetVettingFormsForCampaign(campaignUUID);
  const { invitationsByCampaignUUID } = useGetVettingInvitations(campaignUUID);
  const campaignInvites = invitationsByCampaignUUID?.[campaignUUID] ?? [];

  const vettingForms =
    typeof campaignUUID === "string" && Array.isArray(formsByCampaignUUID?.[campaignUUID])
      ? formsByCampaignUUID?.[campaignUUID]
      : [];

  const latestForm =
    vettingForms.length > 1
      ? vettingForms?.sort((a, b) => b.createdAt - a.createdAt)?.[0]
      : vettingForms?.length === 1
        ? vettingForms[0]
        : undefined;

  const prevNumOfSentInvites = campaignInvites?.reduce((accu, curr) => {
    if (curr && latestForm && curr?.vettingFormUUID === latestForm?.uuid) {
      accu++;
    }
    return accu;
  }, 0);

  const handleFormPick: RadioGroupProps["onChange"] = (e) => {
    const uuid = e.target.value;
    setFormUUID(uuid);
    if (isUUID(uuid)) {
      const vettingForm = formsByUUID[uuid];
      if (vettingForm) {
        form.setFieldsValue({
          brandName: vettingForm.brandName,
          campaignInfo: vettingForm.campaignInfo,
          visibility: {
            shareBudget: vettingForm.visibility.shareBudget,
            shareTimeline: vettingForm.visibility.shareTimeline,
          },
          // we don't include UUID in questions to generate a new one
          question: {
            prompt: vettingForm.questions[0].prompt,
            type: vettingForm.questions[0].type,
            required: vettingForm.questions[0].validations.required,
            fields:
              vettingForm.questions[0].type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE
                ? Array.isArray(vettingForm.questions[0].fields)
                  ? [...vettingForm.questions[0].fields]
                  : undefined
                : undefined,
          },
          additionalQuestions: vettingForm.questions.slice(1).map((question) => {
            return {
              prompt: question.prompt,
              type: question.type,
              required: question.validations.required,
              allowOtherChoice: question?.properties?.allowOtherChoice,
              allowMultipleChoice:
                question?.properties.multipleChoiceType === MULTIPLE_CHOICE_TYPE_CHECKBOX,
              fields:
                question.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE
                  ? Array.isArray(question.fields)
                    ? [...question.fields]
                    : undefined
                  : undefined,
            };
          }),
        });
      }
    }
  };

  const sendExistingForm = isUUID(formUUID);
  const text = `${latestForm?.questions?.length} questions | ${
    prevNumOfSentInvites > 0
      ? `Previously sent to ${prevNumOfSentInvites} ${pluralize("podcast", prevNumOfSentInvites)}`
      : "Draft"
  }`;

  return (
    <Row>
      <Col {...colConfig}>
        <div
          className="flex-row-container justify-center align-center width-100"
          style={{ height: 400 }}>
          <Spin spinning={isLoading}>
            <>
              <Card className="m-bxs">
                <Radio
                  checked={sendExistingForm}
                  value={latestForm?.uuid}
                  onChange={handleFormPick}>
                  <div className="flex-column-container align-start m-lxs">
                    <h4 className="m-bxxs">Send Existing Form</h4>
                    <p className="m-b0">{text}</p>
                  </div>
                </Radio>
              </Card>

              <Card>
                <Radio checked={!sendExistingForm} value={undefined} onChange={handleFormPick}>
                  <div className="flex-column-container align-start m-lxs">
                    <h4 className="m-bxxs">Start a New Form</h4>
                    <p>
                      Sending a new form to an already vetted podcast will keep responses for any
                      podcasts that responded, but will cancel any vetting request that didn't
                      receive a response.
                    </p>
                    <p className="m-b0">
                      After starting a new form, you will not be able to send the previous form to
                      more podcasts.
                    </p>
                  </div>
                </Radio>
              </Card>
            </>
          </Spin>
        </div>
      </Col>
    </Row>
  );
};

interface IVettingFormCreationPageProps {
  form: FormInstance<VettingFormValues>;
}

export const VettingFormCreationPage = ({ form }: IVettingFormCreationPageProps) => {
  // Flags
  const showQuestionFields =
    Form.useWatch(["question"], form)?.["type"] === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE;

  const showAdditionalQuestionFields = Form.useWatch(["additionalQuestions"], form)?.map((item) => {
    return item?.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE;
  });

  return (
    <Row>
      <Col {...colConfig}>
        <Form.Item
          name="brandName"
          label="Brand Name"
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}>
          <Input />
        </Form.Item>
        <Form.Item
          name="campaignInfo"
          label="Campaign Info"
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}>
          <QuillEditor />
        </Form.Item>
        <h6 className="p-bxxs lh-22 fs-14">Visibility Settings</h6>
        <Form.Item
          className="m-b0"
          name={["visibility", "shareBudget"]}
          labelCol={{ span: 24 }}
          valuePropName="checked">
          <Checkbox>Share Budget with Podcaster</Checkbox>
        </Form.Item>
        <Form.Item
          name={["visibility", "shareTimeline"]}
          labelCol={{ span: 24 }}
          valuePropName="checked">
          <Checkbox>Share Timeline with Podcaster</Checkbox>
        </Form.Item>
        <Card
          className="m-bxxs"
          title="Participation Question"
          extra={
            <Tooltip title="This is a required participation question, it cannot be changed">
              <QuestionCircleOutlined />
            </Tooltip>
          }>
          <Form.Item
            name={["question", "prompt"]}
            label="Question prompt"
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            name={["question", "type"]}
            label="Answer type"
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}>
            <Select disabled options={[{ value: "yes_no", label: "Yes/No" }]} />
          </Form.Item>
          <Form.List
            name={["question", "fields"]}
            rules={[
              {
                validator: async (_, names) => {
                  if (showQuestionFields && (!names || names.length < MinMultipleChoiceFields)) {
                    return Promise.reject(new Error("At least 2 choices are required."));
                  }
                },
                message: "At least 2 choices are required.",
              },
            ]}>
            {(fields, { add, remove }, { errors }) => {
              const hideAddBtn = !showQuestionFields || fields.length >= MaxMultipleChoiceFields;
              const rules = showQuestionFields
                ? [
                    {
                      required: true,
                      message: "Choice field cannot be empty",
                    },
                  ]
                : undefined;

              return (
                <>
                  {fields.map((field) => {
                    const { key, name } = field;

                    return (
                      <Form.Item
                        key={key}
                        label={name === 0 ? "Choices" : ""}
                        hidden={!showQuestionFields}
                        name={[name, "label"]}
                        rules={rules}
                        labelCol={{ span: 24 }}>
                        <Input addonAfter={<DeleteOutlined onClick={() => remove(name)} />} />
                      </Form.Item>
                    );
                  })}
                  <Form.Item hidden={hideAddBtn}>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add multiple choice field
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
          <Form.Item
            name={["question", "required"]}
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
            valuePropName="checked">
            <Checkbox disabled>Required</Checkbox>
          </Form.Item>
        </Card>
        <Form.List name="additionalQuestions">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field) => {
                  const { key, name: AddQIndex } = field;

                  const showFields = showAdditionalQuestionFields?.[AddQIndex];
                  return (
                    <Card
                      title={`Additional Question ${AddQIndex + 1}`}
                      className="m-bxxs"
                      key={key}
                      extra={<DeleteOutlined onClick={() => remove(AddQIndex)} />}>
                      <Form.Item
                        name={[AddQIndex, "prompt"]}
                        label="Question Prompt"
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}>
                        <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
                      </Form.Item>
                      <Form.Item
                        name={[AddQIndex, "type"]}
                        label="Answer type"
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}>
                        <Select
                          options={[
                            { value: "yes_no", label: "Yes/No" },
                            { value: "short_text", label: "Short Text" },
                            { value: "long_text", label: "Long Text" },
                            { value: "multiple_choice", label: "Multiple Choice" },
                          ]}
                        />
                      </Form.Item>
                      <Form.List
                        name={[AddQIndex, "fields"]}
                        rules={[
                          {
                            validator: async (fields, names) => {
                              const allowOtherChoice = form.getFieldValue([
                                "additionalQuestions",
                                AddQIndex,
                                "allowOtherChoice",
                              ]);
                              if (
                                showFields &&
                                (!names ||
                                  (names.length < MinMultipleChoiceFields && !allowOtherChoice) ||
                                  (names.length < MinMultipleChoiceFields - 1 && allowOtherChoice))
                              ) {
                                return Promise.reject(
                                  new Error("At least 2 choices are required.")
                                );
                              }
                            },
                            message: "At least 2 choices are required.",
                          },
                        ]}>
                        {(fields, { add, remove }, { errors }) => {
                          const hideAddBtn =
                            !showFields || fields.length >= MaxMultipleChoiceFields;
                          const rules = showFields
                            ? [{ required: true, message: "Choice field cannot be empty." }]
                            : undefined;
                          return (
                            <>
                              {fields.map((field) => {
                                const { key, name: MultiChoiceFieldIndex } = field;
                                return (
                                  <Form.Item
                                    key={key}
                                    label={MultiChoiceFieldIndex === 0 ? "Choices" : ""}
                                    hidden={!showFields}
                                    name={[MultiChoiceFieldIndex, "label"]}
                                    rules={rules}
                                    labelCol={{ span: 24 }}>
                                    <Input
                                      addonAfter={
                                        <DeleteOutlined
                                          onClick={() => remove(MultiChoiceFieldIndex)}
                                        />
                                      }
                                    />
                                  </Form.Item>
                                );
                              })}
                              <Form.Item hidden={hideAddBtn}>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}>
                                  Add multiple choice field
                                </Button>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          );
                        }}
                      </Form.List>
                      <Form.Item
                        name={[AddQIndex, "required"]}
                        className="m-b0"
                        labelCol={{ span: 24 }}
                        valuePropName="checked">
                        <Checkbox>Required</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name={[AddQIndex, "allowMultipleChoice"]}
                        className="m-b0"
                        labelCol={{ span: 24 }}
                        valuePropName="checked"
                        hidden={!showFields}>
                        <Checkbox>
                          <span className="flex-row-container align-center">
                            Allow multiple choices{" "}
                            <Tooltip
                              title="Allow the Podcaster to choose multiple choices instead of one"
                              className="m-lxxxs">
                              <AiFillInfoCircle />
                            </Tooltip>
                          </span>
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        className="m-b0"
                        name={[AddQIndex, "allowOtherChoice"]}
                        labelCol={{ span: 24 }}
                        valuePropName="checked"
                        hidden={!showFields}>
                        <Checkbox>
                          <span className="flex-row-container align-center">
                            Include "Other" choice{" "}
                            <Tooltip
                              title={`Allow the Podcaster to write in "Other" value`}
                              className="m-lxxxs">
                              <AiFillInfoCircle />
                            </Tooltip>
                          </span>
                        </Checkbox>
                      </Form.Item>
                    </Card>
                  );
                })}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Question
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Col>
    </Row>
  );
};

interface IVettingFormSelectionPageProps {
  campaignUUID?: string;
  campaignItemsToSend: string[];
  setCampaignItemsToSend: Dispatch<SetStateAction<string[]>>;
  responseDate: UnixTimeStamp;
  setResponseDate: Dispatch<SetStateAction<number>>;
  formUUID?: string;
}

export const VettingFormSelectionsPage = ({
  campaignUUID,
  campaignItemsToSend,
  setCampaignItemsToSend,
  responseDate,
  setResponseDate,
  formUUID,
}: IVettingFormSelectionPageProps) => {
  const campaignItems = useGetCampaignItems(campaignUUID);
  const publicShows = useSelectorTS((state) => state.publicShows);
  const draftItems = campaignItems?.filter((item) => item.state === CampaignItemStateDraft) ?? [];

  const allItems = campaignItems?.map(({ uuid }) => uuid) ?? [];

  const { invitationsByCampaignItemUUID, invitationsByCampaignUUID } =
    useGetVettingInvitations(campaignUUID);

  const columns: ColumnsType<ICampaignItem> = [
    {
      key: "podcast",
      title: "PODCAST",
      dataIndex: "showUUID",
      width: "45%",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        const showA = publicShows[a?.showUUID];
        const showB = publicShows[b?.showUUID];
        return (showA?.title ?? "").localeCompare(showB?.title ?? "");
      },
      render: (item: any, record) => {
        const show = publicShows?.[record?.showUUID];

        return (
          <div className="flex-row-container align-center">
            <LoadingImage
              className="m-rxxs"
              src={show?.imageURL}
              width={40}
              height={40}
              wrapperStyle={{ minWidth: 40 }}
            />
            <span className="line-clamp-2">{show?.title}</span>
          </div>
        );
      },
    },
    {
      key: "status",
      title: "VETTING STATUS",
      dataIndex: "uuid",
      align: "center",
      width: "45%",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        const vettingA = invitationsByCampaignItemUUID[a?.uuid] ?? [];
        const vettingB = invitationsByCampaignItemUUID[b?.uuid] ?? [];
        return sortVettingInvitationsByStatus(vettingA, vettingB);
      },
      render: (_, record) => {
        const vettingInvitations = invitationsByCampaignItemUUID[record?.uuid] ?? [];
        return <CampaignItemVettingStatus vettingInvitations={vettingInvitations} />;
      },
    },
  ];

  const dataSource = draftItems
    ?.map((item) => {
      return { ...item, key: item.uuid };
    })
    .sort((a, b) => {
      const nameA = publicShows?.[a.showUUID]?.title ?? "";
      const nameB = publicShows?.[b.showUUID]?.title ?? "";
      return nameA.localeCompare(nameB);
    });

  /**
   * Helpers
   */

  const isItemUnVetted = (campaignItemUUID?: string) => {
    if (
      typeof campaignUUID === "string" &&
      typeof campaignItemUUID === "string" &&
      isUUID(campaignItemUUID)
    ) {
      return Array.isArray(invitationsByCampaignUUID?.[campaignUUID])
        ? invitationsByCampaignUUID[campaignUUID].filter(
            (invite) => invite.campaignItemUUID === campaignItemUUID
          )?.length === 0
        : true;
    }

    return false;
  };

  const isItemDisabled = useCallback(
    (campaignItemUUID: string = "") => {
      const invitations = invitationsByCampaignItemUUID?.[campaignItemUUID] ?? [];
      return invitations?.some(
        (invitation) => isUUID(formUUID) && invitation.vettingFormUUID === formUUID
      );
    },
    [invitationsByCampaignItemUUID, formUUID]
  );

  /**
   * Handlers
   */

  const disabledDate: DatePickerProps["disabledDate"] = (current) =>
    current && current <= dayjs().endOf("day");

  const dateValue = dayjs.unix(responseDate);

  const handleDateChange: DatePickerProps["onChange"] = (date) => {
    if (date) {
      const timeStamp = cloneDateAs12amEST(date.unix());
      setResponseDate(timeStamp);
    }
  };

  const handleRowSelection: NonNullable<TableProps<ICampaignItem>["rowSelection"]>["onChange"] = (
    _,
    selectedRows
  ) => {
    const newKeys = selectedRows?.map((item) => item.uuid);
    setCampaignItemsToSend(newKeys);
  };

  const handleCheckboxProps: NonNullable<
    TableProps<ICampaignItem>["rowSelection"]
  >["getCheckboxProps"] = (record) => {
    return { disabled: isItemDisabled(record.uuid) };
  };

  return (
    <Row>
      <Col {...colConfig}>
        <div className="flex-column-container align-start">
          <p className="m-bxxxs">Response Due Date</p>
          <DatePicker
            className="m-bxxs"
            value={dateValue}
            disabledDate={disabledDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="flex-column-container align-stretch">
          <div className="flex-row-container align-center p-hxxs m-bxxs bg-gray-40 rounded-corners width-100">
            <CheckBoxHeaderComponent
              selectedItems={campaignItemsToSend}
              setSelectedItems={setCampaignItemsToSend}
              allItems={allItems}
              isItemUnVetted={isItemUnVetted}
              isItemDisabled={isItemDisabled}
            />
          </div>

          <RCTable<ICampaignItem>
            dataSource={dataSource}
            columns={columns}
            rowSelection={{
              selectedRowKeys: campaignItemsToSend,
              onChange: handleRowSelection,
              hideSelectAll: true,
              getCheckboxProps: handleCheckboxProps,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

interface IVettingFormConfirmationPage {
  selectedCampaignItems: string[];
  formUUID: string;
  responseDate: UnixTimeStamp;
}

export const VettingFormConfirmationPage = ({
  selectedCampaignItems,
  formUUID,
  responseDate,
}: IVettingFormConfirmationPage) => {
  const vettingForm = useGetVettingForm(formUUID);
  const { invitationsByCampaignItemUUID } = useSelectorTS((state) => state.vetting);

  const numOfQuestions = vettingForm?.questions?.length ?? 0;

  let sharingText: string | undefined = undefined;

  if (vettingForm?.visibility.shareBudget)
    sharingText = "Campaign pricing will be shared with the podcaster.";
  if (vettingForm?.visibility.shareTimeline)
    sharingText = "Campaign Timelines will be shared with the podcaster.";
  if (vettingForm?.visibility?.shareBudget && vettingForm.visibility.shareTimeline)
    sharingText = "Pricing and Campaign Timelines will be shared with the podcaster.";

  const alreadySentPodcast = selectedCampaignItems.filter((itemUUID) => {
    const invitations = invitationsByCampaignItemUUID[itemUUID];
    const [isInvitationAlreadyPending] = isInvitationPendingOnItem(invitations);
    return isInvitationAlreadyPending;
  });

  const count = alreadySentPodcast.length;

  const responseDayjs = dayjs.unix(responseDate);
  const now = dayjs();
  const numOfDays = responseDayjs.diff(now, "day");

  return (
    <Row>
      <Col {...colConfig}>
        <div className="flex-column-container align-start justify-center">
          <h3 className="m-bxxs">Double check your details!</h3>
          <ul style={{ paddingLeft: "2rem" }}>
            <li className="fs-xs m-bxs">
              Podcasters will be asked {numOfQuestions} question{numOfQuestions > 1 ? "s" : ""}.
            </li>
            {sharingText && <li className="fs-xs m-bxs">{sharingText}</li>}
            {count > 0 && (
              <li className="fs-xs m-bxs">
                You are sending this vetting request to {count} podcasts that have already been sent
                a vetting request. Please avoid re-vetting podcasts if possible!
              </li>
            )}
            <li className="fs-xs m-bxs">
              The deadline to respond is{" "}
              {`${responseDayjs.format("MM/DD")} - ${numOfDays} day${numOfDays > 1 ? "s" : ""}`}{" "}
              from now.
            </li>
          </ul>
          <p className="fs-xs lh-m bold">After you send a vetting request you cannot edit it.</p>
        </div>
      </Col>
    </Row>
  );
};
