import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export const formatDurationHumanized = (durationInSeconds: number) => {
  const d = dayjs.duration(durationInSeconds, "milliseconds");
  const numHours = d.hours();
  return d.format(`${numHours ? "H[h] " : ""}m[m]`);
};

export const formatDurationPlayback = (durationInSeconds: number) => {
  return dayjs.duration(durationInSeconds, "seconds").format("HH:mm:ss");
};

export const formatDateHumanized = (unixDate: number) => {
  return dayjs(unixDate).format("MMM D, YYYY");
};
