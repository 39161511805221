import isEmpty from "lodash/isEmpty";
import React, { CSSProperties, FunctionComponent } from "react";
import { classNames } from "react-extras";
import Button from "src/components/lib/button";
import { ReactComponent as PriceLock } from "../../../icons/price-lock.svg";
import RCButton from "../button/button";
import "./BlockFeature.scss";

export interface IBlockFeature {
  // Control blocking
  block: boolean;

  // Call to action
  CTA: {
    text: string | JSX.Element;
    type?: "red" | "blue";
    btn?: {
      text: string;
      handler?: () => any;
    };
  };

  // Block type
  blockbyBlur?: boolean;
  blockByBorder?: boolean;
  blockByImg?: {
    src: string;
    style?: CSSProperties;
    minHeight: CSSProperties["minHeight"];
  };

  children?: React.ReactNode;
  minHeight?: CSSProperties["minHeight"];
}

const BlockFeature: FunctionComponent<IBlockFeature> = ({
  block,
  CTA = {
    type: "red",
    btn: {
      text: "Upgrade your Plan",
    },
  },
  blockbyBlur,
  blockByBorder,
  blockByImg,
  children,
  minHeight,
}) => {
  // No Blocking stop here
  if (!block) {
    return <>{children}</>;
  }

  // Block By Blur
  if (blockbyBlur) {
    return (
      <div className="BlockFeature" style={{ minHeight }}>
        <div className="BlockFeature--blur">
          {children}
          <div className="BlockFeature--overlay" />
        </div>
        <div className="BlockFeature--center">
          <PriceLock className={classNames("BlockFeature--svg", CTA?.type || "")} />
          {typeof CTA?.text === "string" ? (
            <h3 className="m-tm m-b0 align-center-self text-center p-hs">{CTA?.text}</h3>
          ) : (
            CTA?.text
          )}

          <RCButton
            type="primary"
            className={classNames("m-tm", { "bg-medium-blue": CTA?.type === "blue" })}
            onClick={CTA?.btn?.handler}>
            {CTA?.btn?.text}
          </RCButton>
        </div>
      </div>
    );
  }

  // Block By Border
  if (blockByBorder) {
    return (
      <div className="BlockFeature BlockFeature--bordered">
        <div className="BlockFeature--header m-bxxs">
          <PriceLock className="BlockFeature--svg price-lock-small" />
          <span className="header--text">{CTA.text}</span>
        </div>
        <div className="BlockFeature--white">{children}</div>
        <Button className="CTA" type="primary" onClick={CTA.btn?.handler}>
          {CTA.btn?.text}
        </Button>
      </div>
    );
  }

  // Block By Image
  if (!isEmpty(blockByImg)) {
    let imgStyle: CSSProperties = {};

    if (blockByImg?.src) {
      imgStyle.position = "absolute";
      imgStyle.backgroundImage = `linear-gradient(180deg, rgba(159, 182, 200, 0.1) 0%, rgba(159, 182, 200, 0.1) 100%), url(${blockByImg.src})`;
    }

    if (blockByImg?.style) {
      imgStyle = { ...blockByImg?.style, ...imgStyle };
    }

    return (
      <div className="BlockFeature" style={{ minHeight: blockByImg?.minHeight }}>
        <div className="BlockFeature--blur" style={imgStyle} />

        <div className="BlockFeature--center">
          <PriceLock className={classNames("BlockFeature--svg", CTA?.type || "")} />
          {typeof CTA?.text === "string" ? (
            <h3 className="m-tm m-b0 align-center-self text-center p-hs">{CTA?.text}</h3>
          ) : (
            CTA?.text
          )}
          <RCButton
            type="primary"
            size="large"
            className={classNames("m-tm", { "bg-medium-blue": CTA?.type === "blue" })}
            onClick={CTA?.btn?.handler}>
            {CTA?.btn?.text}
          </RCButton>
        </div>
      </div>
    );
  }

  // if no settings can be determined dont block
  return <>{children}</>;
};

export default BlockFeature;
