import { Col, Form, FormProps, Row } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MultiModal } from "redcircle-ui";
import {
  createVettingForm,
  sendVettingInvitations,
  updateVettingForm,
} from "src/action_managers/vetting";
import LoadingButton from "src/components/forms/loading_button/loading_button";
import RCButton from "src/components/lib/button/button";
import { permissionTypes } from "src/constants/permission_roles";
import { VETTING_QUESTION_TYPES } from "src/constants/vetting";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { useGetVettingFormsForCampaign, useGetVettingInvitations } from "src/hooks/vetting";
import { cloneDateAs12amEST, UnixTimeStamp } from "src/lib/date";
import { canAdvertiserAccess } from "src/lib/permissions";
import { pluralize } from "src/lib/strings";
import { isUUID } from "src/lib/uuid";
import { formIsInDraft, getLatestVettingForm } from "src/lib/vetting";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import {
  colConfig,
  transformValuesToRequest,
  transformValuesToUpdateRequest,
  VettingFormValues,
} from "./vetting_form_modal_helpers";
import {
  VettingFormConfirmationPage,
  VettingFormCreationPage,
  VettingFormInitializePage,
  VettingFormSelectionsPage,
} from "./vetting_form_modal_pages";

interface IVettingFormModal {
  campaign?: ICampaign;
  draftItems?: ICampaignItem[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const pagesIDs = {
  InitializePage: "InitializePage",
  FormCreation: "FormCreation",
  SelectPodcast: "SelectPodcast",
  Confirmation: "Confirmation",
} as const;

export const VettingFormModal = (props: IVettingFormModal) => {
  const { campaign, draftItems, open, setOpen } = props;
  const { user } = useSelectorTS((state) => state.user);

  const [form] = Form.useForm<VettingFormValues>();

  const [formUUID, setFormUUID] = useState<string>("");

  // Keep local state on which campaigns are being selected for vetting
  const [campaignItemsToSend, setCampaignItemsToSend] = useState<string[]>([]);

  const defaultResponseDate = cloneDateAs12amEST(dayjs().add(5, "days").unix());
  const [responseDate, setResponseDate] = useState<UnixTimeStamp>(defaultResponseDate);

  // Current modal Page
  const [currentPage, setCurrentPage] = useState(0);

  // Loaders flags for buttons loading states
  const [isFormUpdating, setIsFormUpdating] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSendVettingLoading, setIsSendVettingLoading] = useState(false);

  const dispatch = useReduxDispatch();
  const campaignUUID = campaign?.uuid;

  const { invitationsByCampaignUUID } = useGetVettingInvitations(campaignUUID);
  const { formsByCampaignUUID, formsByUUID } = useGetVettingFormsForCampaign(campaignUUID);
  const vettingForms =
    typeof campaignUUID === "string" && Array.isArray(formsByCampaignUUID?.[campaignUUID])
      ? formsByCampaignUUID?.[campaignUUID]
      : [];
  const hasExistingForms = vettingForms?.length > 0;

  const canCreateEditVettingForm = canAdvertiserAccess(permissionTypes.editCampaign, campaign);

  const isUserDemo = user?.userAttributes?.sendCampaignBlocked === "true";
  const canSendVettingRequests =
    canAdvertiserAccess(permissionTypes.sendCampaign, campaign) && !isUserDemo;

  const latestForm = getLatestVettingForm(vettingForms);
  const latestFormIsInDraft = formIsInDraft(
    latestForm?.uuid,
    invitationsByCampaignUUID[campaignUUID ?? ""]
  );

  /**
   * Handlers
   */
  const handleChangePage = (direction: "next" | "back") => () => {
    const currentID = pages[currentPage]?.id;
    const idToPageNumMap = pages.reduce(
      (accu, curr, index) => {
        if (typeof curr?.id === "string") {
          accu[curr?.id] = index;
        }
        return accu;
      },
      {} as Record<string, number>
    );

    if (typeof currentID !== "string") return;

    let nextPage = 0;

    if (direction === "next") {
      switch (currentID) {
        case pagesIDs.InitializePage:
          nextPage = isUUID(formUUID)
            ? latestFormIsInDraft
              ? idToPageNumMap[pagesIDs.FormCreation]
              : idToPageNumMap[pagesIDs.SelectPodcast]
            : idToPageNumMap[pagesIDs.FormCreation];

          break;
        case pagesIDs.FormCreation:
          nextPage = idToPageNumMap[pagesIDs.SelectPodcast];
          break;
        case pagesIDs.SelectPodcast:
          nextPage = idToPageNumMap[pagesIDs.Confirmation];
          break;
        case pagesIDs.Confirmation:
          handleClose();
          break;
      }
    } else {
      switch (currentID) {
        case pagesIDs.InitializePage:
          handleClose();
          break;
        case pagesIDs.FormCreation:
          if (!hasExistingForms) handleClose();
          nextPage = idToPageNumMap[pagesIDs.InitializePage];
          break;
        case pagesIDs.SelectPodcast:
          nextPage = latestFormIsInDraft
            ? idToPageNumMap[pagesIDs.FormCreation]
            : idToPageNumMap[pagesIDs.InitializePage];
          break;
        case pagesIDs.Confirmation:
          nextPage = idToPageNumMap[pagesIDs.SelectPodcast];
          break;
      }
    }

    setCurrentPage(nextPage);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setCampaignItemsToSend([]); // reset selected podcasts state in vetting table
    setCurrentPage(0);
    form.resetFields(); // Reset form fields when closing modal
    setFormUUID(""); // Reset formUUID when closing modal
  }, [setOpen, setCurrentPage]);

  const handleOnFormFinish: FormProps<VettingFormValues>["onFinish"] = (values) => {
    const campaignUUID = campaign?.uuid;
    if (typeof campaignUUID === "string" && isUUID(campaignUUID)) {
      const isUpdatingForm = isUUID(formUUID) && formsByUUID[formUUID];
      if (isUpdatingForm) {
        const vettingForm = formsByUUID[formUUID];
        const req = transformValuesToUpdateRequest({ values, currentValues: vettingForm });

        dispatch(
          updateVettingForm(formUUID, req, {
            successMessage: "Vetting form updated successfully",
          })
        )
          .then((resp) => {
            if (resp.status === 200) {
              setIsFormLoading(false);
              handleChangePage("next")();
            }
          })
          .finally(() => {
            setIsFormLoading(false);
          });
      } else {
        const req = transformValuesToRequest(values, campaignUUID);
        setIsFormLoading(true);
        dispatch(createVettingForm(req, { successMessage: "Vetting form saved successfully" }))
          .then((resp) => {
            if (resp.status === 200) {
              const newForm = resp.json;
              setFormUUID(newForm.uuid);
              setIsFormLoading(false);
              handleChangePage("next")();
            }
          })
          .finally(() => {
            setIsFormLoading(false);
          });
      }
    }
  };

  const handleSaveDraft = async () => {
    try {
      const values = await form.validateFields();
      if (typeof campaign?.uuid !== "string") throw new Error("campaign uuid is undefined");

      setIsFormUpdating(true);

      if (isUUID(formUUID) && formsByUUID[formUUID]) {
        const vettingForm = formsByUUID[formUUID];
        const req = transformValuesToUpdateRequest({ values, currentValues: vettingForm });

        await dispatch(
          updateVettingForm(formUUID, req, {
            successMessage: "Vetting form draft updated successfully",
          })
        );
      } else {
        const req = transformValuesToRequest(values, campaign.uuid);

        const resp = await dispatch(
          createVettingForm(req, { successMessage: "Vetting form draft saved successfully" })
        );

        if (resp.status === 200) {
          const newForm = resp.json;
          setFormUUID(newForm.uuid);
        }
      }
    } catch (err) {
      console.error("Error happened", err);
    } finally {
      setIsFormUpdating(false);
    }
  };

  const handleSelectPodcasts = async () => {
    form.submit();
  };

  const handleVetPodcasts = () => {
    if (isUUID(formUUID) && typeof responseDate === "number" && campaignItemsToSend?.length > 0) {
      setIsSendVettingLoading?.(true);
      dispatch(
        sendVettingInvitations(
          formUUID,
          {
            campaignItemUUIDs: campaignItemsToSend,
            responseDueAt: responseDate,
          },
          {
            successMessage: "Successfully sent vetting invitations",
          }
        )
      )
        .then((resp) => {
          if (resp.status === 200) {
            setIsSendVettingLoading?.(false);
            handleClose();
          }
        })
        .finally(() => {
          setIsSendVettingLoading?.(false);
        });
    }
  };

  const disableSelectPodcastNextPage = campaignItemsToSend?.length === 0;

  const pages = [
    {
      id: pagesIDs.InitializePage,
      title: (
        <Row>
          <Col {...colConfig}>
            <h2> Create new form or send existing form? </h2>
          </Col>
        </Row>
      ),
      body: (
        <VettingFormInitializePage
          form={form}
          campaignUUID={campaign?.uuid}
          formUUID={formUUID}
          setFormUUID={setFormUUID}
        />
      ),
      footer: (
        <div className="width-100">
          <Row>
            <Col {...colConfig} className="flex-row-container">
              <RCButton type="link" size="large" onClick={handleClose}>
                Close
              </RCButton>
              <div className="flex-row-container align-center m-la">
                <LoadingButton className="m-lxxs" onClick={handleChangePage("next")}>
                  Next
                </LoadingButton>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      id: pagesIDs.FormCreation,
      title: (
        <Row>
          <Col {...colConfig}>
            <h2>{`Vetting ${campaign?.name}`} </h2>
          </Col>
        </Row>
      ),
      subtitle: (
        <Row>
          <Col {...colConfig}>
            <h4 className="m-txxs">{`${draftItems?.length} ${pluralize(
              "Podcast",
              draftItems?.length
            )}`}</h4>
          </Col>
        </Row>
      ),
      body: <VettingFormCreationPage form={form} />,
      footer: (
        <div className="width-100">
          <Row>
            <Col {...colConfig} className="flex-row-container">
              <RCButton type="link" size="large" onClick={handleChangePage("back")}>
                Back
              </RCButton>
              <div className="flex-row-container align-center m-la">
                <RCButton
                  type="dashed"
                  size="large"
                  tooltipMessage={
                    !canCreateEditVettingForm
                      ? "You do not have permissions to create/edit vetting form"
                      : undefined
                  }
                  disabled={isFormUpdating || !canCreateEditVettingForm}
                  onClick={handleSaveDraft}>
                  Save Draft
                </RCButton>
                <LoadingButton
                  className="m-lxxs"
                  disabledMessage={
                    !canCreateEditVettingForm &&
                    "You do not have permissions to create/edit vetting form"
                  }
                  disabled={isFormLoading || !canCreateEditVettingForm}
                  isLoading={isFormLoading}
                  onClick={handleSelectPodcasts}>
                  Select Podcasts
                </LoadingButton>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      id: pagesIDs.SelectPodcast,
      title: (
        <Row>
          <Col {...colConfig}>
            <h2>Select Podcasts</h2>
          </Col>
        </Row>
      ),
      subtitle: (
        <Row>
          <Col {...colConfig}>
            <h4 className="m-txxs">Select the podcasts you'd like to vet.</h4>
          </Col>
        </Row>
      ),
      body: (
        <VettingFormSelectionsPage
          campaignUUID={campaign?.uuid}
          campaignItemsToSend={campaignItemsToSend}
          setCampaignItemsToSend={setCampaignItemsToSend}
          responseDate={responseDate}
          setResponseDate={setResponseDate}
          formUUID={formUUID}
        />
      ),
      footer: (
        <div className="width-100">
          <Row>
            <Col {...colConfig} className="flex-row-container">
              <RCButton type="link" size="large" onClick={handleChangePage("back")}>
                Back
              </RCButton>
              <div className="flex-row-container align-center m-la">
                <LoadingButton
                  className="m-lxxs"
                  disabled={disableSelectPodcastNextPage}
                  onClick={handleChangePage("next")}>
                  Next
                </LoadingButton>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      id: pagesIDs.Confirmation,
      title: (
        <Row>
          <Col {...colConfig}>
            <h3>
              Are you sure you want to send this vetting request to {campaignItemsToSend.length}{" "}
              {pluralize("podcast", campaignItemsToSend.length)}?
            </h3>
          </Col>
        </Row>
      ),
      body: (
        <VettingFormConfirmationPage
          formUUID={formUUID}
          selectedCampaignItems={campaignItemsToSend}
          responseDate={responseDate}
        />
      ),
      footer: (
        <div className="width-100">
          <Row>
            <Col {...colConfig} className="flex-row-container">
              <RCButton type="link" size="large" onClick={handleChangePage("back")}>
                Back
              </RCButton>
              <div className="flex-row-container align-center m-la">
                <LoadingButton
                  className="m-lxxs"
                  disabledMessage={
                    !canSendVettingRequests &&
                    "You do not have permissions to send vetting requests"
                  }
                  disabled={isSendVettingLoading || !canSendVettingRequests}
                  isLoading={isSendVettingLoading}
                  onClick={handleVetPodcasts}>
                  Send
                </LoadingButton>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const initialValues: VettingFormValues = useMemo(() => {
    return {
      brandName: campaign?.brand?.name ?? "",
      campaignInfo: "",
      visibility: {
        shareBudget: false,
        shareTimeline: false,
      },
      question: {
        prompt: "Are you interested in this opportunity?",
        type: VETTING_QUESTION_TYPES.YES_NO,
        fields: [
          {
            order: 1,
            label: undefined,
            tooltip: undefined,
          },
        ],
        required: true,
        allowOtherChoice: false,
        allowMultipleChoice: false,
      },
      additionalQuestions: [],
    };
  }, [campaign?.brand?.name]);

  /**
   * Initialize the form on correct start page
   */
  useEffect(() => {
    if (open) {
      if (!hasExistingForms) {
        setCurrentPage(1);
      }
    }
  }, [open]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      scrollToFirstError={{ behavior: "smooth" }}
      onFinish={handleOnFormFinish}>
      <MultiModal
        open={open}
        pages={pages}
        currentPage={currentPage}
        onClose={handleClose}
        size="md"
      />
    </Form>
  );
};
