import { useEffect, useState } from "react";
import { getMarkersByEpisodeUUID } from "src/action_managers/audio_management";
import { getMarkersByShowUUID } from "src/action_managers/markers";
import { useReduxDispatch, useSelectorTS } from "./redux-ts";

type IUseGetMarkers = {
  episodeUUID?: string;
  showUUID?: string;
};

const useGetMarkers = ({ episodeUUID, showUUID }: IUseGetMarkers) => {
  const dispatch = useReduxDispatch();
  const [calledEpisodeMarkersOnce, setCalledEpisodeMarkersOnce] = useState(false);
  const [calledShowMarkersOnce, setCalledShowMarkersOnce] = useState(false);

  const {
    isLoading: isMarkersLoading,
    markers,
    markersByEpisodeUUID,
    markersByShowUUID,
  } = useSelectorTS((state) => state.markers);

  useEffect(() => {
    if (isMarkersLoading) {
      // User is Loading
    } else {
      // User is not loading
      if (!calledEpisodeMarkersOnce && typeof episodeUUID === "string" && episodeUUID?.length > 0) {
        dispatch(getMarkersByEpisodeUUID(episodeUUID));
        setCalledEpisodeMarkersOnce(true);
      }
    }
  }, [isMarkersLoading, episodeUUID, calledEpisodeMarkersOnce]);

  useEffect(() => {
    if (isMarkersLoading) {
      // User is Loading
    } else {
      // User is not loading
      if (!calledShowMarkersOnce && typeof showUUID === "string" && showUUID?.length > 0) {
        dispatch(getMarkersByShowUUID(showUUID));
        setCalledShowMarkersOnce(true);
      }
    }
  }, [isMarkersLoading, showUUID, calledShowMarkersOnce]);

  return { markers, markersByEpisodeUUID, markersByShowUUID, isMarkersLoading };
};

export default useGetMarkers;
