import { Col, Divider, Row } from "antd";
import isEmpty from "lodash/isEmpty";
import mapValues from "lodash/mapValues";
import Moment from "moment";
import React from "react";
import { permissionTypes } from "src/constants/permission_roles";
import { canAdvertiserAccess } from "src/lib/permissions";
import { updateCampaignMeta } from "../../../action_managers/campaigns";
import { HttpError } from "../../../lib/http";
import { ContinuousScheduler } from "./continuous_scheduler";
export const getContinuousPage = ({
  budgets,
  updateBudgets,
  campaign,
  draftItems,
  totalCost,
  timeline,
  httpDispatch,
  updateOneWeekAmount,
  disableContinuousPage,

  ...props
}) => ({
  Content: (
    <ContinuousScheduler
      {...{
        budgets,
        draftItems,
        updateBudgets,
        campaign,
        totalCost,
        timeline,
        disableContinuousPage,

        ...props,
      }}
    />
  ),
  subTitle: `Select your timeline and distribute your campaign budget across each show.`,
  disabled:
    Object.values(budgets)?.some((budget) => !budget) ||
    Boolean(disableContinuousPage?.disable) ||
    !canAdvertiserAccess(permissionTypes.sendCampaign, campaign),
  disabledMessage: disableContinuousPage?.disableMessage,
  submitText: "Save & Continue",
  backText: "Back",
  containerClass: "scroll-body-y-override",
  onSubmit: async () => {
    if (isEmpty(budgets)) {
      return;
    }

    const startDate = timeline[0];
    let resp = await props.updateCampaignItems(
      campaign.uuid,
      mapValues(budgets, (totalBudget) => ({
        totalBudget: Math.round(totalBudget),
        startAt: Moment(startDate).unix(),
        pacingEstimatedEndAt: campaign?.pacing ? timeline[1]?.unix() : undefined,
      }))
    );
    if (resp.status !== 200) {
      props.showWarning("Error Updating Shows");
      throw new HttpError("Something went wrong");
    }

    const campaignUpdates = {
      startsAt: startDate.unix(),
      estimatedEndsAt: timeline[1].unix(),
    };

    resp = await httpDispatch(updateCampaignMeta(campaign.uuid, campaignUpdates));
    if (resp.status !== 200) {
      props.showWarning("Error Updating Campaign");
      throw new HttpError("Something went wrong");
    }
    resp = await props.campaignOneWeek(campaign.uuid);
    if (resp.status === 200) {
      await updateOneWeekAmount(resp.json.amount);
      //update campaignItems in the reducer
      await props.getCampaign(campaign.uuid);
    } else {
      throw new HttpError("Something went wrong");
    }
  },
  title: "Scheduled Podcasts",
  header: (
    <Row className="m-ts" style={{ padding: "0px 10px" }}>
      <Col xs={24} xl={{ span: 22, offset: 1 }}>
        <div className="flex-column-container align-start">
          <span className="bold fs-m lh-36 m-bxxs">Schedule Podcasts</span>
          <span>{`Select your timeline and distribute your campaign budget across each show.`}</span>
          <Divider className="m-txs m-bxs" />
        </div>
      </Col>
    </Row>
  ),
});
