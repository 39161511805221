import { AnyAction } from "redux";
import {
  TARGETING_RULES_FETCH_ERROR,
  TARGETING_RULES_FETCH_START,
  TARGETING_RULES_FETCH_SUCCESS,
  TARGETING_RULE_PUT_CLEAR,
  TARGETING_RULE_PUT_ERROR,
  TARGETING_RULE_PUT_INVALID,
  TARGETING_RULE_PUT_SET_TYPE,
  TARGETING_RULE_PUT_START,
  TARGETING_RULE_PUT_SUCCESS,
} from "../../actions/targeting_rules";
import { PutTargetingRulesReduxState, TargetingRulesReduxState } from "./types";

const initialState: TargetingRulesReduxState = {
  isLoading: false,
  rules: {},
};

export function targetingRules(state = initialState, action: AnyAction) {
  switch (action.type) {
    case TARGETING_RULES_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case TARGETING_RULES_FETCH_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newRules = Object.assign({}, state.rules);
      for (const rule of action.rules) {
        newRules[rule.uuid] = rule;
      }
      return Object.assign({}, state, {
        isLoading: false,
        rules: newRules,
      });
    case TARGETING_RULES_FETCH_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

const initialPutState: PutTargetingRulesReduxState = {
  validationErrors: [],
  isLoading: false,
  isSuccess: false,
  selectedRuleType: null,
};

export function putTargetingRules(state = initialPutState, action: AnyAction) {
  switch (action.type) {
    case TARGETING_RULE_PUT_CLEAR:
      return Object.assign({}, initialPutState);
    case TARGETING_RULE_PUT_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case TARGETING_RULE_PUT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
      });
    case TARGETING_RULE_PUT_INVALID:
      return Object.assign({}, state, {
        isLoading: false,
        validationErrors: action.validationErrors,
      });
    case TARGETING_RULE_PUT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case TARGETING_RULE_PUT_SET_TYPE:
      return Object.assign({}, state, {
        selectedRuleType: action.ruleType,
      });
    default:
      return state;
  }
}
