import groupBy from "lodash/groupBy";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { AlbumArt } from "src/components/lib/album_art";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import Button from "src/components/lib/button";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import ShowAdvertisingSettingsModal from "src/components/modals/show_advertising_settings_modal/show_advertising_settings_modal";
import { emptyShows } from "src/constants/bucketed_audience_size";
import {
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateNeedsScript,
} from "src/constants/campaigns";
import { ADVERTISING_OPT_IN_THRESHOLD } from "src/constants/misc";
import { permissionTypes } from "src/constants/permission_roles";
import { HAS_SEEN_ADVERTISING_PAGE } from "src/constants/user_attributes";
import { useGetShowCampaignItems } from "src/hooks/campaigns";
import { useSelectorTS } from "src/hooks/redux-ts";
import { useShow } from "src/hooks/shows";
import { useGetShowVettingInfo } from "src/hooks/vetting";
import { useCanAccess } from "src/lib/permissions";
import { isShowRedirected } from "src/lib/show";
import { ICampaignItem } from "src/reducers/campaign_items";
import { CampaignItemState } from "src/reducers/campaign_items/types";
import AdvertisingMetadata from "./advertising_metadata";
import AdvertisingTable from "./advertising_table";
import { AudioSwapWarning } from "./advertising_warnings";
import RAPGettingStarted from "./getting_started";
import PostVettingModal from "./post_vetting_modal";
import { sortCampaignItems, useGetConflicts } from "./show_advertising_page_util";
import VettingResponseDrawer from "./vetting_response_drawer";

export default function ShowAdvertisingPage() {
  const history = useHistory();
  const { showUUID, modifier, modifierUUID } = useParams<{
    showUUID: string;
    modifier?: string;
    modifierUUID?: string;
  }>();

  const { user } = useSelectorTS((state) => state.user);
  const { show, isLoading: isShowLoading } = useShow({ showUUID });

  // get all campaign items for the show
  const { showCampaignItems, isShowCampaignsLoading } = useGetShowCampaignItems(showUUID);
  const { vettingCampaignItems, isLoading: isVettingInfoLoading } = useGetShowVettingInfo(showUUID);
  const allCampaignItems = [...Object.values(vettingCampaignItems), ...showCampaignItems];
  const conflicts = useGetConflicts(allCampaignItems);

  const [showPostVettingModal, setPostVettingModal] = useState(false);
  const showVettingModal = Boolean(modifier === "vetting" && modifierUUID);
  const showSettingsModal = Boolean(modifier === "settings" && modifierUUID);

  const canEditRap = useCanAccess(permissionTypes.editRap, showUUID);
  const hasReceivedAdMarketplaceEmail = !!show?.invitedToRAP;
  const hasSetupAdvertising = show?.advertisementSettings || false;
  const showTooSmall = show && show.estimatedWeeklyDownloads < ADVERTISING_OPT_IN_THRESHOLD;
  const isProgrammaticAllowed = !show?.isRemote;
  const isProgrammaticEnabled = Boolean(show?.programmaticAdSettings?.enabled);
  const isAnyLoading = isShowLoading || isShowCampaignsLoading || isVettingInfoLoading;

  const emptyShowAudienceSizes = emptyShows.map((item) => item.value);
  const showNotCalculated = emptyShowAudienceSizes.some(
    (size: string) => size === show?.bucketedAudienceSize
  );

  const campaignItemsByState: Record<CampaignItemState, ICampaignItem[]> = sortCampaignItems({
    vetting: [],
    draft: [],
    sent: [],
    accepted: [],
    declined: [],
    running: [],
    completed: [],
    expired: [],
    paused: [],
    [CampaignItemStateAwaitingAudio]: [],
    [CampaignItemStateAudioSwapRequested]: [],
    [CampaignItemStateNeedsScript]: [],
    ...groupBy(allCampaignItems, "state"),
  });

  const allAcceptedItems = [
    ...campaignItemsByState.accepted,
    ...campaignItemsByState[CampaignItemStateAudioSwapRequested],
    ...campaignItemsByState[CampaignItemStateAwaitingAudio],
    ...campaignItemsByState.running,
    ...campaignItemsByState.completed,
  ];

  // set whether user has seen advertising page
  useEffect(() => {
    if (show && !showTooSmall && !showNotCalculated) {
      const showsWhoHasShownAdvertisingTo = (
        user?.userAttributes?.[HAS_SEEN_ADVERTISING_PAGE] ?? ""
      ).split(",");

      if (!showsWhoHasShownAdvertisingTo.includes(show.uuid)) {
        /**
         * Temporary commenting code to fix ENG-401.
         */
        // dispatch(
        //   setUserAttribute(
        //     HAS_SEEN_ADVERTISING_PAGE,
        //     showsWhoHasShownAdvertisingTo.concat(show.uuid).join(",")
        //   )
        // );
      }
    }
  }, [showTooSmall, showNotCalculated]);

  const renderNonAdvertisingCopy = () => {
    if (isShowLoading || hasSetupAdvertising) return null;
    if (!show?.isRemote) {
      if (showNotCalculated && !hasReceivedAdMarketplaceEmail) {
        return (
          <div>
            <p>
              Your show is too new to opt-in to advertising. It takes RedCircle a few days to ensure
              that your show is large enough to participate.
            </p>
            <p>Come back in a few days once your show size has been calculated.</p>
          </div>
        );
      }
      if (showTooSmall && !hasReceivedAdMarketplaceEmail) {
        return (
          <div>
            <p>
              Your show isn’t large enough to join RedCircle's Ad Platform (RAP). Once your audience
              grows to an average of at least 500 weekly downloads, you can opt-in.
            </p>
            <p>
              In the meantime, check out{" "}
              <Link to={"/shows/" + showUUID + "/i/exclusive-content"}>Exclusive Content</Link> to
              start making money, or <Link to={"/promotions"}>Cross Promotions</Link> to help expand
              your show’s reach.
            </p>
          </div>
        );
      }
    }

    return <RAPGettingStarted show={show} />;
  };

  return (
    <Page className="show-advertising-page" pageTitle="Ad Platform">
      <Breadcrumbs
        crumbs={[
          { path: "/ad-platform", name: "All Podcasts" },
          { path: `/ad-platform/${showUUID}`, name: show?.title, active: true },
        ]}
      />

      {campaignItemsByState[CampaignItemStateAudioSwapRequested].length > 0 && (
        <AudioSwapWarning
          audioSwapRequested={campaignItemsByState[CampaignItemStateAudioSwapRequested]}
        />
      )}

      <Page.Header className="flex-row-container align-center justify-space-between flex-wrap">
        <div className="flex-row-container flex-1">
          <AlbumArt
            imageSize={"64x64"}
            className="medium m-rxs"
            src={show?.imageURL}
            title={show?.title}
            isRedirected={isShowRedirected(show)}
          />
          <div className="flex-column-container">
            <span className="preheader uppercase line-clamp-1">{show?.title}</span>
            <Page.Title className="line-clamp-1">RedCircle Ad Platform (RAP)</Page.Title>
          </div>
        </div>

        {hasSetupAdvertising && (
          <div className="flex-row-container m-txxs flex-0">
            {canEditRap && (
              <Button
                type="secondary"
                size="small"
                className="m-rs"
                onClick={() => history.push(`/ad-platform/${showUUID}/settings/rates`)}>
                RAP Settings
              </Button>
            )}
            <Link to={`/browse/${showUUID}`}>
              <Button type="secondary" size="small">
                View Profile
              </Button>
            </Link>
          </div>
        )}
      </Page.Header>
      <Page.Divider />

      {isShowLoading && <Loading />}

      {!isShowLoading && hasSetupAdvertising && (
        <>
          <Page.Section>
            <AdvertisingMetadata
              className="m-bm"
              isProgrammaticAllowed={isProgrammaticAllowed}
              isProgrammaticEnabled={isProgrammaticEnabled}
              allAcceptedItems={allAcceptedItems}
              onClickOptIn={() => {
                history.push(`/ad-platform/${showUUID}/settings/programmatic`);
              }}
            />
          </Page.Section>

          <Page.Section>
            {isShowCampaignsLoading && <Loading />}
            {!isShowCampaignsLoading &&
              (!allCampaignItems.length ? (
                <span>
                  Thanks for setting up your RedCircle Ad Platform (RAP) profile. When you receive
                  campaign offers, they will show up here.
                </span>
              ) : (
                <AdvertisingTable
                  campaignItemsByState={campaignItemsByState}
                  conflicts={conflicts}
                />
              ))}
          </Page.Section>
        </>
      )}

      {renderNonAdvertisingCopy()}

      <ShowAdvertisingSettingsModal
        show={show}
        open={showSettingsModal}
        onClose={() => history.push(`/ad-platform/${showUUID}`, { shallow: true })}
      />

      <VettingResponseDrawer
        open={showVettingModal}
        onClose={() => history.push(`/ad-platform/${showUUID}`, { shallow: true })}
        onAfterSubmit={() => setPostVettingModal(true)}
        campaignItemUUID={modifierUUID}
        isLoading={isVettingInfoLoading}
      />

      <PostVettingModal open={showPostVettingModal} onClose={() => setPostVettingModal(false)} />
    </Page>
  );
}
