import { Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { classNames } from "react-extras";
import {
  getDefaultPermissionsForRole,
  getPermCategoriesFromUserRole,
} from "src/lib/permission_roles";
import { Presets } from "src/reducers/permissions/types";
import {
  createPermission,
  CreatePermission,
  IUpdatePermission,
  updatePermission,
} from "../../action_managers/permissions";
import {
  permissionsConfigMap,
  roleToFriendly,
  UIPermissionsTypes,
} from "../../constants/permission_roles";
import { usePermissionsInOrg } from "../../hooks/org";
import { useReduxDispatch, useSelectorTS } from "../../hooks/redux-ts";
import LoadingButton from "../forms/loading_button/loading_button";
import Divider from "../lib/divider";
import {
  AccessFormState,
  cleanFinalOverrides,
  determineOverrides,
} from "../pages/account/team_seats_utilities";
import "./modal.scss";

// Helper functions

// TYPES
interface ICustomizeTeamSeatRole {
  visible: boolean; // Parent controlled modal visibility
  setVisible: (visible: boolean) => void; // Parent change function form visibility
  entities: AccessFormState["entities"];
  credentialUUID: string;
  currentRole: Presets;
  firstName: string;
  lastName: string;
  viewOnly: boolean;
}

// MODAL COMPONENT
const CustomizeRole = (props: ICustomizeTeamSeatRole) => {
  const {
    visible = false,
    setVisible,
    entities,
    credentialUUID,
    currentRole,
    firstName,
    lastName,
    viewOnly,
  } = props;
  const dispatch = useReduxDispatch();

  // REDUX STATE
  const {
    permissionMapByKey,
    permissions: allOrgPermissions,
    isLoading: isOrgPermsLoading,
    permissionsMapByCred,
  } = usePermissionsInOrg();
  const { currentRole: userRole } = useSelectorTS((state) => state.user);

  const defaultRolePermissions = getDefaultPermissionsForRole(currentRole);

  // local State
  const [formState, setFormState] =
    useState<Partial<Record<UIPermissionsTypes, boolean>>>(defaultRolePermissions);
  const [submitting, setIsSubmitting] = useState(false);

  const permissionCategories = getPermCategoriesFromUserRole(userRole);

  const selectedCredOrgPermission = permissionsMapByCred[credentialUUID]?.find(
    (perm) => perm.objectType === "org"
  );

  // Handlers
  const handleCancel = () => {
    setVisible(false);
  };

  const handleUpdateRoleForEntities = async () => {
    const updates: IUpdatePermission[] = [];
    const additions: CreatePermission[] = [];

    const entitiesWithCurrentRole = entities.filter((entity) => entity.currentRole === currentRole);
    const newOverrides = cleanFinalOverrides(formState, currentRole);

    entitiesWithCurrentRole.forEach((entity) => {
      const permission = permissionMapByKey[`${credentialUUID}-${entity.uuid}`];
      if (permission) {
        updates.push({
          ...permission,
          overrides: newOverrides,
        });
      } else {
        // Permission Object Does Not exist/ Need to Create instead of Update
        additions.push({
          credentialUUID,
          objectUUID: entity.uuid,
          objectType: entity.type,
          preset: entity.currentRole,
          overrides: newOverrides,
        });
      }
    });

    // If the current customized role is also the preset role for the selected credential, updates the override on
    // selected member org permission
    if (selectedCredOrgPermission && selectedCredOrgPermission.preset === currentRole) {
      updates.push({
        ...selectedCredOrgPermission,
        overrides: newOverrides,
      });
    }

    try {
      if (!viewOnly) {
        setIsSubmitting(true);
        if (updates?.length > 0) {
          await dispatch(updatePermission(updates));
        }

        if (additions?.length > 0) {
          await dispatch(createPermission(additions));
        }
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      setIsSubmitting(false);
      setVisible(false);
    }
  };

  useEffect(() => {
    if (
      !isOrgPermsLoading &&
      typeof credentialUUID === "string" &&
      typeof currentRole === "string"
    ) {
      const overrides = determineOverrides({
        credentialUUID,
        currentRole,
        allOrgPermissions,
      });

      setFormState({ ...defaultRolePermissions, ...overrides });
    }
  }, [credentialUUID, currentRole, isOrgPermsLoading, allOrgPermissions?.length]);

  const title = viewOnly
    ? `${roleToFriendly[currentRole]} Role`
    : `Customize ${roleToFriendly[currentRole]} Role`;

  return (
    <Modal
      wrapClassName="RC-Antd-Override-Modal"
      bodyStyle={{ maxHeight: "50vh", overflow: "auto", paddingTop: 0 }}
      width={600}
      open={visible}
      centered={true}
      onCancel={handleCancel}
      title={
        <div>
          {viewOnly || (
            <p className="title--1 uppercase">
              User: {firstName} {lastName}
            </p>
          )}

          <h2 className="title--2 m-b0">{title}</h2>
          <Divider marginTop={8} marginBottom={0} />
        </div>
      }
      footer={
        <div>
          <LoadingButton
            className="btn-primary btn-large fs-xs lh-s p-hl"
            isLoading={Boolean(submitting)}
            onClick={handleUpdateRoleForEntities}>
            {viewOnly ? "Ok" : "Save Changes"}
          </LoadingButton>
        </div>
      }>
      <form>
        {permissionCategories.map((category) => {
          const permissionsList = Object.keys(permissionsConfigMap).filter((permName) => {
            const metaData = permissionsConfigMap[permName as keyof typeof permissionsConfigMap];

            if (metaData.category === category && metaData[currentRole] !== null) return true;

            return false;
          }) as (keyof typeof permissionsConfigMap)[];

          return (
            <div key={category} className="m-vxs">
              <p className="bold title--1 uppercase m-bxxs" style={{ color: "black" }}>
                {category}
              </p>
              {permissionsList.map((permissionName) => {
                const { name } = permissionsConfigMap[permissionName];
                const permMetaData = permissionsConfigMap[permissionName][currentRole];

                const { canBeModified } = permMetaData ?? {};

                const checked = Boolean(formState?.[permissionName]);

                return (
                  <div
                    key={permissionName}
                    className={classNames("flex-row-container align-center justify-space-between", {
                      enable: canBeModified,
                    })}>
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        setFormState((prev) => {
                          return { ...prev, [permissionName]: !checked };
                        });
                      }}
                      disabled={!canBeModified || viewOnly}
                      className="m-bxxs title--5 justify-space-between"
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        width: "100%",
                      }}>
                      {name}
                    </Checkbox>
                  </div>
                );
              })}
            </div>
          );
        })}
      </form>
    </Modal>
  );
};

export default CustomizeRole;
