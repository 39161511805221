import { Col, Row } from "antd";
import React from "react";
import { Elements } from "react-stripe-elements";
import { InvoicingPaymentMethod } from "../../../constants/campaigns";
import StripeForm from "../../lib/stripe_form";

export const getPaymentPage = ({
  options,
  card,
  token,
  budgets,
  updateBudgets,
  handleCardSubmit,
  setPage2Ready,
  stripeFormRef,
  page2Ready,
  paymentMethodType,
  ...props
}) => ({
  Content: (
    <Row justify="center">
      <Col xs={20} md={12} className="p-hxxs">
        <Elements fonts={options.fonts}>
          <StripeForm
            defaultSelectedCardID={card?.id}
            ref={stripeFormRef}
            newCardOnly={false}
            onFormStatus={setPage2Ready}
            onLoadingStatus={() => {}}
            {...{
              token,
              card,
              budgets,
              updateBudgets,
              getMyCards: props.getMyCards,
              submit: handleCardSubmit,
            }}
          />
        </Elements>
      </Col>
    </Row>
  ),
  disabled: !page2Ready,
  title: "Payment",
  onSubmit: handleCardSubmit,
  hide: paymentMethodType === InvoicingPaymentMethod,
});
