import { isUndefined, maxBy } from "lodash";
import filter from "lodash/filter";
import get from "lodash/get";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { ICampaign } from "src/reducers/campaigns/types";
import { IShowMap } from "../reducers/shows/showReducer";
import { IShow } from "../reducers/shows/types";
import { User } from "../reducers/user";
import { store } from "src";

export const AudienceBucketSizes = ["0-100", "100-1000", "1000-5000", "5000-20000", "20000+"];

function isValidAudienceBucketSize(show: IShow) {
  const bucketedAudienceSize = get(show, "bucketedAudienceSize");
  return AudienceBucketSizes.indexOf(bucketedAudienceSize) !== -1;
}

function getAdjacentAudienceBucketSizes(show: IShow) {
  const bucketedAudienceSize = get(show, "bucketedAudienceSize");
  const index = AudienceBucketSizes.indexOf(bucketedAudienceSize);
  if (index === -1) {
    return ["calculating", ""];
  }
  if (0 < index && index < AudienceBucketSizes.length - 1) {
    return [AudienceBucketSizes[index - 1], AudienceBucketSizes[index + 1]];
  } else if (0 < index) {
    return [AudienceBucketSizes[index - 1]];
  } else {
    return [AudienceBucketSizes[index + 1], "calculating", ""];
  }
}

export function getAllAdjacentAudienceBucketSizes(shows: IShow[] = []) {
  const bucketedAudienceSizeSet = new Set();
  if (shows && shows.length > 0) {
    shows.forEach((show) => {
      if (isValidAudienceBucketSize(show)) {
        const sizes = getAdjacentAudienceBucketSizes(show);
        sizes.forEach((size) => {
          bucketedAudienceSizeSet.add(size);
        });
        bucketedAudienceSizeSet.add(show.bucketedAudienceSize);
      }
    });
  }

  // If no bucketedAudienceSizes are found, default to "", calculating
  if (bucketedAudienceSizeSet.size === 0) {
    bucketedAudienceSizeSet.add("");
    bucketedAudienceSizeSet.add("calculating");
    bucketedAudienceSizeSet.add("0-100");
  }
  return Array.from(bucketedAudienceSizeSet);
}

// returns array of all shows a user owns
export function getUserShows(user: User, shows: IShowMap = {}) {
  const userUUID = get(user, "uuid", undefined);
  return filter(shows, (show) => {
    const showUserUUID = get(show, "userUUID");
    return userUUID === showUserUUID;
  });
}

export function isShowEligibleForCrossPromo(show: Partial<IShow> = {}) {
  const canShareAudienceSize = get(show, "canShareAudienceSize", false);
  const isVisible = get(show, "isVisible", false);
  const redirectAwayURL = get(show, "redirectAwayURL", "");
  const redirectVerified = get(show, "redirectVerified", false);
  const importedURL = get(show, "importedURL", "");

  if (!isEmpty(importedURL) && redirectVerified === false) {
    return false;
  }
  if (!isEmpty(redirectAwayURL)) {
    return false;
  }
  if (!isVisible) {
    return false;
  }
  return canShareAudienceSize;
}

export function getBiggestShow(shows: IShow[] = []) {
  const sortedShows = sortBy(shows, (show) => {
    const bucketSize = get(show, "bucketedAudienceSize", "");
    const index = AudienceBucketSizes.indexOf(bucketSize);
    return index;
  });
  if (!sortedShows.length) {
    return {};
  }
  return head(sortedShows);
}

export const isShowRedirected = (show?: IShow) => {
  if (isUndefined(show)) return false;

  // show is part of the small select group of softredirect aways, do not mark them as redirected
  if (show?.softRedirectAwayURL?.length > 0) {
    return false;
  }

  if (typeof show?.redirectAwayURL !== "string") {
    return false;
  }

  // check if redirect away URL has been set and is not empty string;
  return show?.redirectAwayURL?.length > 0;
};

/**
 * Helper func, to determine if a show is currently wait listed.
 * @param showUUID
 * @returns {boolean}
 */
export const isShowWaitListed = (show: IShow) => {
  // Grabbed user state directly for convenience. (not needing to pass User object to helper func)
  // It is implied this function is only used with logged in users
  const user = store.getState()?.user?.user;

  const showStatus = user?.openRAPWaitlistInfo?.waitlistedShowUUIDs?.[show?.uuid];
  return showStatus;
};
/**
 * List of Podcasts that:
 *  - Are not Remote Shows
 *  - Are not redirected Away
 *  - Are not Hard deleted
 *  - Are not Soft deleted
 *
 *  Useful for calculating core tier podcast limit logic
 * @param shows
 * @returns {IShows[]}
 */
export const getActiveHostedPodcasts = (shows: IShowMap | IShow[]) => {
  const showArray = Array.isArray(shows) ? shows : Object.values(shows);

  const visibleShows = showArray.filter((show) => !show?.isHardDeleted && !!show.isVisible);

  return visibleShows
    .filter((show) => !isShowRedirected(show))
    .filter((show) => !isShowRemote(show));
};

export const showHasExclusiveContent = (show: IShow) => {
  const monthlyAmount = get(show, ["subscriptionDetails", "amount"]);
  const weeklyAmount = get(show, ["subscriptionDetails", "amountWeekly"]);
  const annualAmount = get(show, ["subscriptionDetails", "amountAnnual"]);
  return monthlyAmount || weeklyAmount || annualAmount;
};

export const getShowPrimaryGenderAge = (show: IShow) => {
  const demographics = show.listenerDemographics;
  if (!demographics) return {};

  const { genderBreakdown, ageBuckets = [] } = demographics;
  const primaryGender = Object.keys(genderBreakdown).reduce((a, b) =>
    genderBreakdown[a as keyof typeof genderBreakdown] >
    genderBreakdown[b as keyof typeof genderBreakdown]
      ? a
      : b
  );
  const genderPercentage = Math.round(
    genderBreakdown[primaryGender as keyof typeof genderBreakdown] * 100
  );

  const primaryAge = maxBy(ageBuckets, (a) => a.percentage)?.label;
  const bucket = ageBuckets.find((a) => a.label === primaryAge);
  const agePercentage = bucket ? Math.round(bucket.percentage * 100) : null;

  return { primaryGender, genderPercentage, primaryAge, agePercentage };
};

export const isShowRemote = (show?: IShow) => Boolean(show?.isRemote);

/**
 * Check if show is deleted (both hard deleted and soft deleted)
 * @param show
 * @returns {boolean}
 */
export const isShowRemoved = (show?: IShow) => {
  return show?.isHardDeleted || !show?.isVisible;
};

export const isShowExcludingCampaign = (show?: IShow, campaign?: ICampaign) => {
  if (!show || !campaign) return false;

  if (show.advertisementSettings?.excludedCategories) {
    const brandCategory = campaign?.brand?.iabCategory;
    if (brandCategory) {
      const hasExcluded = show.advertisementSettings.excludedCategories.some(
        (c) => c === brandCategory || brandCategory.startsWith(c)
      );
      if (hasExcluded) return true;
    }
  }

  if (show.advertisementSettings?.excludedAdvertiserUUIDs) {
    const advertiserUUID = campaign?.userUUID;
    if (
      advertiserUUID &&
      show.advertisementSettings.excludedAdvertiserUUIDs.includes(advertiserUUID)
    ) {
      return true;
    }
  }

  if (show.advertisementSettings?.excludedBrandUUIDs) {
    const brandUUID = campaign?.brand?.instanceUUID;
    if (brandUUID && show.advertisementSettings.excludedBrandUUIDs.includes(brandUUID)) {
      return true;
    }
  }

  return false;
};
