import dayjs from "dayjs";
import moment from "moment";
import {
  CampaignItemStateAwaitingAudio,
  CampaignItemStateDeclined,
  CampaignItemStateDraft,
  CampaignItemStateExpired,
  CampaignItemStateNeedsScript,
  CampaignItemStateSent,
  CampaignStateCreated,
} from "src/constants/campaigns";
import { getEstimatedEndDateForCampaignItem } from "src/lib/campaigns";
import { BudgetsReduxState } from "src/reducers/budgets";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShow, PublicShowsReduxState } from "src/reducers/public_show";
import { IShow } from "src/reducers/shows";
import { IShowMap } from "src/reducers/shows/showReducer";

export const getDaysLeftAndClockTimeString = (unixTimeStamp: number) => {
  const respondByAt = moment.unix(unixTimeStamp).utc();
  const currentAt = moment();
  const daysLeft = Math.max(Math.round(respondByAt.diff(currentAt, "days", true)), 0);
  const clockTimeLocal = respondByAt.local().format("hh:mm A");
  return { daysLeft, clockTimeLocal };
};

const generateDaysLeftText = (daysLeft: number) => {
  if (daysLeft > 1) return `${daysLeft} days`;
  if (daysLeft === 1) return "1 day";
  return "less than a day";
};

export const getCampaignItemContextText = (campaignItem: ICampaignItem) => {
  if (campaignItem.state === CampaignItemStateSent && campaignItem.respondByAt) {
    const { daysLeft } = getDaysLeftAndClockTimeString(campaignItem.respondByAt);
    return `Has ${generateDaysLeftText(daysLeft)} to respond`;
  }

  if (campaignItem.state === CampaignItemStateDeclined) {
    const date = campaignItem.respondedAt
      ? moment.unix(campaignItem.respondedAt).local().format("l LT")
      : "";
    return `${campaignItem.state} ${date}`;
  }

  if (campaignItem.state === CampaignItemStateExpired) {
    const date = campaignItem.expiredAt
      ? moment.unix(campaignItem.expiredAt).local().format("l LT")
      : "";
    return `${campaignItem.state} ${date}`;
  }

  if (campaignItem.state === CampaignItemStateAwaitingAudio && campaignItem.uploadAudioBy) {
    const { daysLeft } = getDaysLeftAndClockTimeString(campaignItem.uploadAudioBy);
    return `Accepted, has ${generateDaysLeftText(daysLeft)} to upload`;
  }

  // PODCASTER ONLY
  if (campaignItem.state === CampaignItemStateNeedsScript && campaignItem.scriptDueBy) {
    return `Brand will provide script by ${moment.unix(campaignItem.scriptDueBy).format("M/D/YY")}`;
  }
};

export const getTotalSpotsFromCampaignItem = (campaignItem: ICampaignItem) => {
  return campaignItem?.flightConfigs?.reduce(
    (agg, item) => agg + item.targetEpisodeCountInTimeRange,
    0
  );
};

/**
 * Formats start and end dates for campaign item, takes into account Pacing campaigns.
 * For Pacing campaign items, the end date is equal to the pacingEstimatedEndDate of the campaign
 * @param campaignItem
 * @param campaign
 * @param show
 * @param formatString
 * @returns formatted date strings
 */
export const formatStartAndEndDates = (
  campaignItem: ICampaignItem,
  campaign: ICampaign | undefined,
  show: IShow | PublicShow | undefined,
  formatString = "ll"
) => {
  const estimatedEndDate = getEstimatedEndDateForCampaignItem({
    campaign,
    campaignItem,
    show,
    budget: campaignItem.budget,
  });

  const isPacing = campaign && Boolean(campaign.pacing);
  const pacingEndDate = campaignItem.pacingEstimatedEndAt;

  const formattedStartDate = dayjs.unix(campaignItem?.startAt).format(formatString);

  const formattedDates =
    isPacing && typeof pacingEndDate === "number"
      ? `${formattedStartDate} - ${dayjs.unix(pacingEndDate).format(formatString)}`
      : `${formattedStartDate} - ${dayjs.unix(estimatedEndDate).format(formatString)}`;

  return formattedDates;
};

export const getSpotRateFromCampaignItem = (campaignItem: ICampaignItem) =>
  campaignItem?.offerRates?.enabled
    ? campaignItem.offerRates.finalAdjustedSpotRate
    : campaignItem.spotRate;

// TODO: move to backend
export const getCampaignEstimatedDates = (
  shows?: PublicShowsReduxState | IShowMap,
  campaign?: ICampaign,
  campaignItems?: ICampaignItem[],
  budgets?: BudgetsReduxState
) => {
  if (!campaign || !campaignItems) return null;
  if (campaign.state === CampaignStateCreated) return null;

  let earliestStartDate = Infinity;
  let latestEndDate = 0;
  campaignItems.forEach((campaignItem) => {
    if (
      [CampaignItemStateExpired, CampaignItemStateDeclined, CampaignItemStateDraft].includes(
        campaignItem.state
      )
    )
      return;

    const show = shows?.[campaignItem.showUUID];
    const budget = campaignItem?.budgetUUID ? budgets?.[campaignItem?.budgetUUID] : undefined;
    const s = campaignItem.startAt;
    const e = getEstimatedEndDateForCampaignItem({
      campaign,
      campaignItem,
      show,
      budget,
    });
    if (s < earliestStartDate) earliestStartDate = s;
    if (e > latestEndDate) latestEndDate = e;
  });

  if (earliestStartDate >= latestEndDate) return null; // something wild happened here
  return { start: earliestStartDate, end: latestEndDate };
};
