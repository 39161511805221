import { useEffect, useRef } from "react";
import { fetchSubscriptionsByShowUUID } from "src/action_managers/subscriptions";
import { Subscription } from "src/reducers/subscriptions";
import { useReduxDispatch, useSelectorTS } from "./redux-ts";

interface IUseInterface {
  showUUID?: string;
}

const useSubscriptions = ({ showUUID }: IUseInterface) => {
  const fetchedShowSubs = useRef<{ [showUUID: string]: boolean }>({});

  const dispatch = useReduxDispatch();
  const { isLoading, subscriptions, subscriptionsByShowUUIDs } = useSelectorTS(
    (state) => state.subscriptions
  );

  const showSubscriptions =
    typeof showUUID === "string" ? subscriptionsByShowUUIDs?.[showUUID] ?? [] : [];

  const allSubscriptions = Object.keys(subscriptions).reduce((accu, curr) => {
    accu.push(subscriptions[curr]);
    return accu;
  }, [] as Subscription[]);

  useEffect(() => {
    if (!isLoading && showUUID) {
      !fetchedShowSubs.current?.[showUUID] &&
        dispatch(fetchSubscriptionsByShowUUID({ showUUID })).finally(() => {
          if (fetchedShowSubs?.current) fetchedShowSubs.current[showUUID] = true;
        });
    }
  }, [isLoading, showUUID]);

  return { showSubscriptions, allSubscriptions, subscriptionsByShowUUIDs, isLoading };
};

export default useSubscriptions;
