import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ProgressBar from "src/components/lib/progress_bar/progress_bar";
import WarningPanelv2 from "src/components/lib/warning_panel/warning_panel_v2";
import {
  CONVERSION_STATE_KEY_DONE,
  CONVERSION_STATE_KEY_FAILED,
  CONVERSION_STATE_KEY_NEEDS_PROCESSING,
  CONVERSION_STATE_KEY_PROCESSING,
} from "src/constants/conversion_states";
import { usePollingMediaFile } from "src/hooks/mediaFiles";
import { IEpisode } from "src/reducers/episodes";

const PROGRESS_BAR_UPPER_PERCENTAGE = 95;
const PROGRESS_BAR_LOWER_PERCENTAGE = 5;

const estimateConversionDuration = (mediaFile: any) => {
  const duration = mediaFile.duration; // in milliseconds
  // benchmark - 1.45 hr show = 5 min import? test on prod
  const sampleShowDuration = 1.45 * 60 * 60 * 1000; // in milliseconds
  const sampleConversionDuration = 5 * 60 * 1000; // in milliseconds
  const rate = sampleConversionDuration / sampleShowDuration;
  return Math.round(duration * rate);
};

export default function EpisodePageMediaProgress({ episode }: { episode: IEpisode }) {
  const { mediaFile, isLoading: isMediaLoading } = usePollingMediaFile({
    mediaFileUUID: episode.contentMediaFileUUID,
  });

  const [showBar, setShowBar] = useState<boolean | undefined>();

  useEffect(() => {
    if (mediaFile && !isMediaLoading && !showBar) {
      if (
        mediaFile.conversionState !== CONVERSION_STATE_KEY_DONE &&
        mediaFile.conversionState !== CONVERSION_STATE_KEY_FAILED
      ) {
        setShowBar(true);
      }
    }
  }, [mediaFile]);

  const info = { subtitle: "", percent: 0 };
  if (mediaFile) {
    switch (mediaFile.conversionState) {
      case CONVERSION_STATE_KEY_NEEDS_PROCESSING:
        info.subtitle = "Waiting for processing to start";
        info.percent = 5;
        break;
      case CONVERSION_STATE_KEY_PROCESSING:
        const startTime = mediaFile?.updatedAt;
        const elapsed = dayjs().diff(dayjs.unix(startTime), "millisecond");
        const estimatedTime = estimateConversionDuration(mediaFile);
        const percentage = Math.round((elapsed / estimatedTime) * 100);
        info.percent = Math.max(
          Math.min(percentage, PROGRESS_BAR_UPPER_PERCENTAGE),
          PROGRESS_BAR_LOWER_PERCENTAGE
        );
        info.subtitle = "Your episode is processing...";
        break;
      case CONVERSION_STATE_KEY_DONE:
        info.subtitle = "All set! Your episode has been processed";
        info.percent = 100;
        break;
      case CONVERSION_STATE_KEY_FAILED:
        info.subtitle =
          "Something went wrong processing your media file. Try uploading again, or contacting support.";
        info.percent = 90;
        break;
    }
  }

  return (
    <>
      {showBar && (
        <div>
          <ProgressBar
            title={`Processing ${episode.title}`}
            current={info.percent}
            subtitle={info.subtitle}
            total={100}
          />
        </div>
      )}
      {!showBar && mediaFile?.convertError && (
        <WarningPanelv2>
          There is an issue with the audio file. Please try with a different file or contact
          support.
        </WarningPanelv2>
      )}
    </>
  );
}
