import { Select } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "redcircle-ui";
import { showSuccess } from "src/actions/app";
import { createCampaignItems } from "src/action_managers/campaigns";
import EmptyStateBlock from "src/components/lib/empty_state_block";
import { permissionPresets } from "src/constants/permission_roles";
import { useGetCampaigns } from "src/hooks/campaigns";
import { useGetMyPermissions } from "src/hooks/org";
import { useReduxDispatch } from "src/hooks/redux-ts";
import { isUserPresetAllowed } from "src/lib/permission_roles";
import { isShowExcludingCampaign } from "src/lib/show";
import { ICampaign } from "src/reducers/campaigns/types";
import { IShow } from "src/reducers/shows";

export default function AddToCampaignModal({
  visible,
  onClose,
  show,
  onSubmit,
}: {
  visible: boolean;
  onClose: () => void;
  show: IShow | null | undefined;
  onSubmit?: (campaignUUID?: string) => void;
}) {
  const dispatch = useReduxDispatch();

  const { orgPermission } = useGetMyPermissions();
  const canAddNewCampaign = isUserPresetAllowed({
    userPreset: orgPermission.preset,
    limitPreset: permissionPresets.campaignEditor,
  });

  const campaignsReducer = useGetCampaigns();
  const { campaignsForUser = [], campaigns } = campaignsReducer;
  const [selectedCampaignUUID, setSelectedCampaignUUID] = useState("");
  const userCampaigns = campaignsForUser.map((campaignUUID: string) => campaigns[campaignUUID]);

  useEffect(() => {
    if (userCampaigns.length > 0 && !selectedCampaignUUID) {
      setSelectedCampaignUUID(userCampaigns[0].uuid);
    }
  }, [userCampaigns]);

  const handleSubmit = () => {
    if (selectedCampaignUUID && show) {
      const campaign = campaigns[selectedCampaignUUID];
      dispatch(createCampaignItems(selectedCampaignUUID, [{ showUUID: show.uuid }])).then((res) => {
        if (res.status === 200) {
          dispatch(showSuccess(`${show.title} added to ${campaign.name}`));
          onClose();
          if (onSubmit) onSubmit(selectedCampaignUUID);
        }
      });
    }
  };

  const handleSearch = (input: string, option?: { label: string; value: string }) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const options = userCampaigns
    .map((campaign: ICampaign) => {
      const disabled = isShowExcludingCampaign(show ?? undefined, campaign);
      return {
        label: disabled ? `${campaign.name} (excluded)` : campaign.name,
        value: campaign.uuid,
        disabled,
      };
    })
    .sort((a, b) => {
      if (a.disabled && !b.disabled) return 1;
      if (!a.disabled && b.disabled) return -1;
      return a.label.localeCompare(b.label);
    });

  return (
    <Modal open={visible} size="sm" onClose={onClose}>
      <Modal.Title>Add To Campaign</Modal.Title>
      {show && (
        <Modal.Body className="flex-column-container">
          <span className="m-bxxs">
            Select the campaign you'd like to add the show <strong>{show.title}</strong> to below:
          </span>
          {userCampaigns.length > 0 ? (
            <Select
              showSearch
              filterOption={handleSearch}
              placeholder="Select a campaign..."
              value={selectedCampaignUUID}
              onSelect={setSelectedCampaignUUID}
              options={options}
            />
          ) : (
            <EmptyStateBlock>
              <span>
                It looks like you don't have any campaigns to add to.{" "}
                {canAddNewCampaign && (
                  <>
                    You can create a new one{" "}
                    <Link to="/campaigns/new">
                      <strong>here</strong>
                    </Link>
                    .
                  </>
                )}
              </span>
            </EmptyStateBlock>
          )}
        </Modal.Body>
      )}
      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton onClick={handleSubmit} disabled={!selectedCampaignUUID}>
          Add
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
}
