import { Image } from "antd";
import moment from "moment";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { formatMoney } from "redcircle-lib";
import logo from "src/images/logo-redcircle-dark.svg";
import { getCurrentSubscriptionAmounts } from "src/lib/tier-utils";
import { setHideSidebar, setHideWarningBar, setSidebarOpen, showError } from "../../../actions/app";
import { userLogout } from "../../../actions/user";
import { getPaymentsForTier, updateTierAction } from "../../../action_managers/tiers";
import { TIER_LEVEL_ANNUAL, TIER_LEVEL_MONTHLY } from "../../../constants/tiers";
import { ReduxDispatch, useReduxDispatch, useSelectorTS } from "../../../hooks/redux-ts";
import { useComponentDidMount } from "../../../lib/hook";
import UserStore from "../../../lib/user_store";
import Divider from "../../lib/divider";

interface IUpdateTierInfoProps {
  isYearly: boolean;
  copyForPlan: { yearlyPrice?: string; monthlyPrice?: string };
  userHasFreeTrial: boolean;
  levelDisplay: string;
  showAddCard: boolean;
  title: string;
  hideDueToday?: boolean;
  hideNextPayment?: boolean;
  showLegacyPrice?: boolean;
}

interface IUpdateTierHeader {
  title: string;
  showAddCard: boolean;
  className?: string;
}

export const UpdateTierHeader = ({ title, showAddCard, className }: IUpdateTierHeader) => (
  <div className={className}>
    <h1>{title}</h1>
    {showAddCard ? (
      <p>Add your payment info to upgrade your plan.</p>
    ) : (
      <p>Confirm your payment method to update your plan.</p>
    )}
    <Divider marginTop={7} />
  </div>
);

export const UpdateTierInfo = ({
  isYearly,
  copyForPlan,
  userHasFreeTrial,
  levelDisplay,
  showAddCard,
  title = "",
  hideDueToday = false,
  hideNextPayment = false,
  showLegacyPrice,
}: IUpdateTierInfoProps) => {
  let { yearlyPrice, monthlyPrice } = copyForPlan;

  const tier = useSelectorTS((state) => state.user.tier);
  const currentAmounts = getCurrentSubscriptionAmounts(tier);

  if (showLegacyPrice) {
    // we can reuse the same price for both , since this flag is enabled only, when the user
    // does not change any configurations to the current tier (tier plan and tier interval is the same)
    // if the user changes the config then it reverts to the default pricing
    yearlyPrice = formatMoney(currentAmounts.pricePerMonth * 12, {
      maximumFractionDigits: 0,
    }).toString();
    monthlyPrice = formatMoney(currentAmounts.pricePerMonth, {
      maximumFractionDigits: 0,
    }).toString();
  }

  const nextPaymentAt = useSelectorTS((state) => state.payments?.userTierPayment?.nextPaymentAt);
  const dueToday = () => {
    if (userHasFreeTrial) {
      return "$0";
    }
    if (nextPaymentAt > 0) {
      return "$0";
    }
    return isYearly ? yearlyPrice : monthlyPrice;
  };

  const nextPaymentDate = () => {
    if (nextPaymentAt > 0) {
      return moment.unix(nextPaymentAt).format("MM/DD/YY");
    }
    if (userHasFreeTrial) {
      return moment().add(7, "d").format("MM/DD/YY");
    }
    const date = moment();
    if (isYearly) {
      date.add(1, "y");
    } else {
      date.add(1, "M");
    }

    return date.format("MM/DD/YY");
  };
  return (
    <>
      <UpdateTierHeader title={title} showAddCard={showAddCard} className="hidden-xs" />
      <div className={"p-am bg-offwhite m-bs"}>
        Below are the details of your new plan which is effective immediately:
        <br />
        <br />
        <div>
          <span className="bold">Account Plan:</span> RedCircle {levelDisplay} Plan
        </div>
        <div>
          <span className="bold">Plan Details: </span>
          {isYearly ? yearlyPrice : monthlyPrice}/{isYearly ? "year" : "month"}
        </div>
        <If condition={!hideDueToday && !hideNextPayment}>
          <div>
            <span className="bold">Due Today: </span>
            {dueToday()} {userHasFreeTrial && "(Free 7 Day Trial)"}
          </div>
        </If>
        <If condition={!hideNextPayment}>
          <div className="small m-txs">
            Your next payment will be {isYearly ? yearlyPrice : monthlyPrice} on {nextPaymentDate()}
          </div>
        </If>
      </div>
    </>
  );
};

export const UpdateTierNavBar = ({ signOutOnly = false }: { signOutOnly?: boolean }) => {
  const dispatch = useDispatch();
  const topRight = () => {
    if (signOutOnly) {
      return (
        <Button
          bsStyle={"link"}
          className="redcircle-big-link"
          onClick={() => {
            UserStore.remove();
            dispatch(userLogout());
          }}>
          Sign Out
        </Button>
      );
    }
    return (
      <NavLink
        className="redcircle-big-link"
        to={"/account/payment"}
        activeClassName="logged-out-frame__nav-link--active">
        Cancel
      </NavLink>
    );
  };
  return (
    <div
      className="logged-out-frame__topbar"
      style={{
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.04), 0px 0px 24px rgba(0, 0, 0, 0.08)",
        borderRadius: "0px 0px 8px 8px",
      }}>
      <div className="flex-row-container align-center justify-space-between">
        <a href="https://redcircle.com">
          <Image src={logo} height={25} preview={false} alt={"RedCircle Logo"} />
        </a>
        <div className="logged-out-frame__nav">{topRight()}</div>
      </div>
    </div>
  );
};

export const useHideSidebar = () => {
  const dispatch = useDispatch();
  useComponentDidMount(() => {
    dispatch(setHideSidebar(true));
    return () => {
      dispatch(setHideSidebar(false));
    };
  });
};

export const useHideWarningbar = () => {
  const dispatch = useDispatch();
  useComponentDidMount(() => {
    dispatch(setHideWarningBar(true));
    return () => {
      dispatch(setHideWarningBar(false));
    };
  });
};

export const updateTierAndRefreshPayment = (
  dispatch: ReduxDispatch,
  currentPlan: string,
  stripeCardId?: string,
  isYearly?: boolean
) => {
  return dispatch(
    updateTierAction({
      interval: isYearly ? TIER_LEVEL_ANNUAL : TIER_LEVEL_MONTHLY,
      level: currentPlan,
      stripeCardId,
    })
  ).then((resp) => {
    if (resp.status !== 200 || resp.json?.state === "payment_failed") {
      throw new Error("update failed");
    }
    return dispatch(getPaymentsForTier());
  });
};

export const useUpdateTier = (destination = "/account/payment") => {
  const dispatch = useReduxDispatch();
  const history = useHistory();
  return (stripeCardId: string, isYearly: boolean, currentPlan: string, errorMessage = "") =>
    updateTierAndRefreshPayment(dispatch, currentPlan, stripeCardId, isYearly)
      .then((resp) => {
        if (resp.status !== 200) {
          return Promise.reject();
        }
        history.push(destination, { paymentTab: true });
      })
      .catch(() => {
        if (errorMessage) {
          dispatch(showError(errorMessage));
        }
      });
};

export const AccountOnHold = ({ subTitle = "" }) => {
  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <UpdateTierNavBar signOutOnly={true} />
        </Col>
      </Row>
      <Row className={"m-txxxl"}>
        <Col xs={8} xsOffset={2} md={4} mdOffset={4} className={"text-center"}>
          <h2>Account On Hold</h2>
          <div>{subTitle}</div>
        </Col>
      </Row>
    </Grid>
  );
};
