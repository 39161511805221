import { Col, Row } from "antd";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { If } from "react-extras";
import { CreditCardPaymentMethod, InvoicingPaymentMethod } from "../../../constants/campaigns";
import { isNegotiatedRateInCampaign } from "../../../lib/campaigns";
import { formatMoney } from "../../../lib/format-money";
import PaymentConfirmation from "../../lib/payment_confirmation/payment_confirmation";

export const getPaymentConfirmationPage = ({
  paymentMethodType,
  oneWeekAmount,
  totalCost,
  budgets,
  card,
  initialPaymentMethod,
  onSubmit,
  campaign,
  user,
}) => ({
  Content: (
    <Row justify="center">
      <Col xs={20} md={16} className="p-hxxs">
        <PaymentConfirmation
          {...{
            paymentMethodType: paymentMethodType,
            brand: card?.brand,
            last4: card?.last4,
            amount: paymentMethodType === CreditCardPaymentMethod ? oneWeekAmount : totalCost,
            isRateNegotiated: isNegotiatedRateInCampaign(campaign, true),
            chargeTypeText:
              paymentMethodType === CreditCardPaymentMethod
                ? "One-Time Authorization"
                : "Total Budget",
            additionalInfo: (
              <>
                <If condition={paymentMethodType === CreditCardPaymentMethod}>
                  <div className={"info m-bl"}>
                    We will hold these funds until the podcasters accept your offer and the campaign
                    starts. After the campaign starts, you will be billed incrementally as the
                    campaign runs, up to your maximum budget amount of{" "}
                    {formatMoney(
                      Object.entries(budgets).reduce((agg, [, amount]) => agg + amount, 0)
                    )}
                    .
                  </div>
                </If>
                <If condition={paymentMethodType === InvoicingPaymentMethod}>
                  <div className="info m-bl">
                    <If condition={!isEmpty(initialPaymentMethod)}>
                      <div>
                        You've selected <b>Pay by Invoice</b> for the entirety of this campaign.
                      </div>
                    </If>
                    <div>RedCircle will invoice you incrementally as the campaign runs.</div>
                    <div>Payment terms will be indicated on each invoice.</div>
                  </div>
                </If>
              </>
            ),
          }}
        />
      </Col>
    </Row>
  ),
  title: "Payment",
  onSubmit: onSubmit,
  submitText: "Submit",
  disabled: user?.userAttributes?.sendCampaignBlocked === "true",
});
