import React from "react";
import { classNames } from "react-extras";
import { useShowNotification } from "src/hooks/shows";
import { isShowRedirected, isShowRemote } from "src/lib/show";
import { IShow } from "src/reducers/shows";
import ExclusiveTag from "../exclusive_tag/exclusive_tag";
import LoadingImage from "../image";
import RedDot from "../red_dot/red_dot";
import styles from "./album_art.module.scss";

interface ShowAlbumArtProps {
  show: IShow;
  tag?: any;
  className?: any;
  size?: string;
  style?: React.CSSProperties;
}

export const ShowAlbumArt = ({
  show,
  className,
  size = "200x200",
  style = {},
}: ShowAlbumArtProps) => {
  return (
    <LoadingImage
      imageURL={`${show.imageURL}?d=${size}`}
      className={classNames("rounded-image", className)}
      alt={show.title || "Show Image"}
      title={show.title}
      style={style}
    />
  );
};

export const ShowAlbumArtWithNotification = ({
  show,
  tag,
  className,
  size = "200x200",
}: ShowAlbumArtProps) => {
  const { showNotification } = useShowNotification(show.uuid);
  const showIsRedirected = isShowRedirected(show);
  const showIsRemote = isShowRemote(show);

  return (
    <div key={show.uuid} className={classNames(styles["show-album-art-container"], className)}>
      <div className={classNames("show-list-image-wrapper", "rounded-image")}>
        <LoadingImage
          imageURL={`${show.imageURL}?d=${size}`}
          className="loading-image show-list-image"
          style={showIsRedirected ? { opacity: 0.4 } : {}}
          alt={show.title || "Show Image"}
          title={show.title}
        />
        {!tag && showIsRemote && (
          <ExclusiveTag
            className="album-art-tag"
            fontSize="8px"
            color="green"
            backgroundRGB={[255, 231, 190]}
            backgroundOpacity={0.9}>
            <b>REMOTE</b>
          </ExclusiveTag>
        )}
        {!tag && showIsRedirected && (
          <ExclusiveTag
            className="album-art-tag"
            fontSize="8px"
            backgroundRGB={[255, 231, 190]}
            backgroundOpacity={0.9}>
            <b>REDIRECTED</b>
          </ExclusiveTag>
        )}
        {!showIsRedirected && tag}
      </div>

      {showNotification && (
        <RedDot size={24} style={{ position: "absolute", right: -12, top: -12 }} />
      )}
      <p className="show-list-title">{show.title}</p>
    </div>
  );
};
