export type MediaFileUploadReduxState = {
  isLoading: boolean;
  mediaFiles: MediaFile[] | null;
  bitrate: number;
  loadingUUIDs: string[];
  progress: number;
};

export type MediaFile = {
  uuid: string;
  fileType: FileType;
  fileName: string;
  userUUID: string;
  url: string;
  convertedURL: string;
  createdAt: number;
  updatedAt: number;
  contentSize: number;
  sourceBitrate: number;
  duration: number;
  durationIsSet: boolean;
  conversionState: MediaFileConversionState;
  bitrate: number;
  id3DataSize: number;
  waveformURL: string;
  markersAdded: boolean;
  convertError: string | null;
  isLoading: boolean;
};

export enum MediaFileConversionState {
  "needs_processing" = "needs_processing",
  "processing" = "processing",
  "done" = "done",
  "failed" = "failed",
  "not_applicable" = "not_applicable",
}

type FileType =
  | "image/jpeg"
  | "image/png"
  | "image/jpg"
  | "audio/mpeg"
  | "audio/mp3"
  | "audio/x-m4a"
  | "audio/m4a"
  | "binary/octet-stream";
