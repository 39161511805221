import { Input } from "antd";
import { useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { copyToClipboard } from "redcircle-lib";
import { Button, MultiModal } from "redcircle-ui";
import { showSuccess } from "src/actions/app";
import { useReduxDispatch } from "src/hooks/redux-ts";
import { useShow } from "src/hooks/shows";
import VASTURLPollPage, { VASTURLPollFooter } from "./vast_url_poll";

const positionOrder: Record<string, number> = { PREROLL: 0, MIDROLL: 1, POSTROLL: 2 };

export default function VastSetupModal({
  onClose,
  params,
}: {
  onClose: () => void;
  params: { showUUID: string };
}) {
  const { showUUID } = params;
  const { show } = useShow({ showUUID });
  const dispatch = useReduxDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  const sortedURLs = show?.vastURLs?.sort((a, b) => {
    if (positionOrder[a.position] < positionOrder[b.position]) return -1;
    if (positionOrder[a.position] > positionOrder[b.position]) return 1;
    return a.positionIndex - b.positionIndex;
  });

  const numURLs = sortedURLs?.length || 0;
  const firstURL = sortedURLs?.[0];
  const otherURLs = sortedURLs?.slice(1);

  const copy = (text: string, index: number) => {
    copyToClipboard(text);
    dispatch(showSuccess(`Copied URL ${index} to clipboard`));
  };

  const footer = (
    <>
      <Button type="link" size="large" onClick={onClose} className="p-a0">
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        className="m-la"
        disabled={!sortedURLs || !sortedURLs.length}
        onClick={() => setCurrentPage(currentPage + 1)}>
        Continue
      </Button>
    </>
  );

  const megaphonePage = {
    title: "Configure VAST for Megaphone",
    body: (
      <>
        <p>
          Follow the instructions below to configure your podcast's VAST connection to RedCircle. We
          won't start inserting ads until you're ready.
        </p>
        <h4>Create a Campaign</h4>
        <ol>
          <li>
            Start by creating a new campaign called <strong>RedCircle Integration</strong>, leave
            the budget blank.
          </li>
          <li>
            For the advertiser, create a new one called <strong>RedCircle</strong> -- probably best
            to set the advertising category to something obscure; Megaphone has a feature called
            "competitive separation". If it is enabled, RedCircle ads may conflict with the ads you
            are currently running.
          </li>
        </ol>
        <h4>Create {numURLs} Orders</h4>
        <ol>
          <li>Create your first order under the campaign you create above.</li>
          <li>Keep the impression goal empty, the rate, empty, etc.</li>
          <li>
            Position: set <strong>no position</strong>. If you are using no other VAST connections,
            you can leave the default priority.
          </li>
          <li>Geotargeting: set no geotargeting.</li>
          <li>End Date: leave blank if not required, otherwise, set it to many years from now.</li>
          <li>
            Advertisements: copy the following URL and paste in the ad in the{" "}
            {firstURL && (
              <strong>
                {firstURL.position} {firstURL.positionIndex + 1}
              </strong>
            )}{" "}
            position:
            {firstURL && (
              <div className="flex-row-container align-center m-bxxs">
                <Input
                  type="filled"
                  value={firstURL.url}
                  disabled
                  addonBefore={`${firstURL.position} ${firstURL.positionIndex + 1}`}
                />
                <Button type="primary" className="m-lxxs" onClick={() => copy(firstURL.url, 1)}>
                  <AiOutlineCopy />
                </Button>
              </div>
            )}
          </li>
          <li>Targeting: target this show</li>
          {otherURLs && (
            <li>
              <strong>
                Repeat steps 1-7, replace the URL in step 6 with the following VAST URLs and
                positions:
              </strong>
              <p>
                <em>
                  Megaphone does have a "copy order" function, but the dates may be reset - check to
                  make sure all details are correct.
                </em>
              </p>
              {otherURLs.map((url, index) => (
                <div className="flex-row-container align-center m-bxxs" key={url.url}>
                  <Input
                    type="filled"
                    value={url.url}
                    disabled
                    addonBefore={`${url.position} ${url.positionIndex + 1}`}
                  />
                  <Button
                    type="primary"
                    className="m-lxxs"
                    onClick={() => copy(url.url, index + 2)}>
                    <AiOutlineCopy />
                  </Button>
                </div>
              ))}
            </li>
          )}
        </ol>
        <h4>Validate</h4>
        <p>
          When you are complete, you should have <strong>{numURLs} orders</strong> associated with
          the RedCircle Integration campaign. Please check to make sure all details are correct.
        </p>
      </>
    ),
    footer,
  };

  const genericPage = {
    title: "Configure VAST",
    body: (
      <>
        <p>
          For detailed instructions on how to apply the VAST URLs below, please reach out to our
          support team at{" "}
          <a href="https://support.redcircle.com/kb-tickets/new" target="_blank" rel="noreferrer">
            support.redcircle.com
          </a>
          .
        </p>
        {sortedURLs?.map((url, index) => (
          <div className="flex-row-container align-center m-bxxs" key={url.url}>
            <Input
              type="filled"
              value={url.url}
              disabled
              addonBefore={`${url.position} ${url.positionIndex + 1}`}
            />
            <Button type="primary" className="m-lxxs" onClick={() => copy(url.url, index + 2)}>
              <AiOutlineCopy />
            </Button>
          </div>
        ))}
      </>
    ),
    footer,
  };

  const verifyingPage = {
    title: "Verifying Vast Setup",
    body: <VASTURLPollPage showUUID={show?.uuid} onBack={() => setCurrentPage(currentPage - 1)} />,
    footer: (
      <VASTURLPollFooter
        show={show}
        onBack={() => setCurrentPage(currentPage - 1)}
        onFinish={onClose}
      />
    ),
  };

  const pages = show?.importedURL?.includes("megaphone")
    ? [megaphonePage, verifyingPage]
    : [genericPage, verifyingPage];

  return (
    <MultiModal
      open={!!show}
      onClose={onClose}
      pages={pages}
      currentPage={currentPage}
      size="sm"
      maskClosable={false}
    />
  );
}
