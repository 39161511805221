import { Popover } from "antd";
import React from "react";
import { classNames } from "react-extras";
import { useHistory } from "react-router-dom";
import { AlbumArt } from "src/components/lib/album_art";
import ContextMenu from "src/components/lib/context_menu";
import RCTable from "src/components/lib/tables/rc_table";
import { permissionTypes } from "src/constants/permission_roles";
import { secondsToString } from "src/lib/numbers";
import { useCanAccessBound } from "src/lib/permissions";
import { AudioBlock } from "src/reducers/audio_blocks";
import { IEpisode } from "src/reducers/episodes";
import { Marker } from "src/reducers/markers";
import { IShow } from "src/reducers/shows";
import styles from "./insertion_points.module.scss";
import dayjs from "dayjs";

interface IProps {
  episodes: (IEpisode & { show: IShow })[];
  markersByEpisodeUUID: Record<string, Marker[]>;
  audioBlocks: Record<string, AudioBlock>;
}

type TTableRow = IEpisode & {
  key: string;
  imgURL?: string;
  markers?: Marker[];
};

export default function InsertionPointsTable({
  episodes,
  markersByEpisodeUUID,
  audioBlocks,
}: IProps) {
  const canAccess = useCanAccessBound();
  const history = useHistory();

  const columns = [
    {
      title: "Episode",
      key: "title",
      dataIndex: "title",
      render: (_: any, episode: TTableRow) => <TableEpisodeCell episode={episode} />,
      sorter: (a: TTableRow, b: TTableRow) => a.title.localeCompare(b.title),
    },
    {
      title: "Insertion Points",
      key: "insertion-points",
      dataIndex: "insertion-points",
      render: (_: any, episode: TTableRow) => (
        <TableMarkersCell
          episode={{ ...episode, markers: markersByEpisodeUUID[episode.uuid] || [] }}
          audioBlocks={audioBlocks}
        />
      ),
    },
    {
      title: "Published Date",
      key: "published-date",
      dataIndex: "publishedAt",
      render: (_: any, episode: TTableRow) => (
        <span>{dayjs.unix(episode.publishedAt).format("ll")}</span>
      ),
      sorter: episodeSortFunc("publishedAt"),
    },
    {
      title: "Created Date",
      key: "created-date",
      dataIndex: "createdAt",
      render: (_: any, episode: TTableRow) => (
        <span>{dayjs.unix(episode.createdAt).format("ll")}</span>
      ),
      sorter: episodeSortFunc("createdAt"),
    },
    {
      key: "context-menu",
      width: 50,
      render: (_: any, episode: TTableRow) => {
        if (!canAccess(permissionTypes.editMarkers, episode.showUUID)) return null;
        return (
          <ContextMenu
            menuItems={{
              Edit: () =>
                history.push(`/dynamic-insertion/insertion-points/episodes/${episode.uuid}`),
            }}
            noCircle={true}
          />
        );
      },
    },
  ];

  const data: TTableRow[] = episodes
    .filter((e) => !e?.show?.isRemote)
    .map((episode) => ({
      key: episode.uuid,
      imageURL: episode.imageURL || episode?.show?.imageURL,
      ...episode,
    }));

  return <RCTable columns={columns} dataSource={data} pagination={false} />;
}

const TableEpisodeCell = ({ episode }: { episode: TTableRow }) => (
  <div className="flex-row-container align-center">
    <AlbumArt className="m-rxxs" src={episode.imageURL} alt="album-art" style={{ width: 48 }} />
    <strong>{episode.title}</strong>
  </div>
);

const TableMarkersCell = ({
  episode,
  audioBlocks,
}: {
  episode: TTableRow;
  audioBlocks: Record<string, AudioBlock>;
}) => {
  const sortedMarkers = episode?.markers?.sort((a, b) => a.offsetBytes - b.offsetBytes);
  if (!sortedMarkers) return null;
  return (
    <div className="flex-row-container flex-wrap">
      {sortedMarkers.map((marker: Marker) => (
        <TableMarkerIcon key={marker.uuid} marker={marker} audioBlocks={audioBlocks} />
      ))}
    </div>
  );
};

const TableMarkerIcon = ({
  marker,
  audioBlocks,
}: {
  marker: Marker;
  audioBlocks: Record<string, AudioBlock>;
}) => {
  const audioBlock = marker.audioBlockUUID && audioBlocks[marker.audioBlockUUID];
  const popoverContent = (
    <div className="flex-column-container">
      <span className="m-bxxs">
        {audioBlock ? (
          <span>
            <b>Audio Block: </b> {audioBlock.name}
          </span>
        ) : (
          <b>No Audio Block Selected</b>
        )}
      </span>
      <span className="m-bxxs">
        <b>Insertion Time: </b> {secondsToString(marker.offsetMilliSeconds / 1000, true)}
      </span>
      <span>
        <b>Position: </b> {marker.position}
      </span>
    </div>
  );

  return (
    <Popover content={popoverContent} trigger="click">
      <svg
        className={classNames(styles["blue-circle"], audioBlock && styles["filled"])}
        viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" />
      </svg>
    </Popover>
  );
};

const episodeSortFunc = (key: keyof TTableRow) => (a: TTableRow, b: TTableRow) => {
  const aVal = a?.[key];
  const bVal = b?.[key];
  /**
   * Being defensive in case undefined values for any reason
   */
  if (typeof aVal === "number" && typeof bVal === "number") {
    return aVal - bVal;
  } else if (typeof aVal === "number") {
    return -1;
  } else {
    return 1;
  }
};
