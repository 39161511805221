import { showError } from "../../../actions/app";
import { showModal } from "../../../actions/modal";
import { getCredentialsInOrg } from "../../../action_managers/credentials";
import { getDealsForUser } from "../../../action_managers/deals";
import { getShowForUser } from "../../../action_managers/shows";
import { tierLevels, TIER_LEVEL_MONTHLY } from "../../../constants/tiers";
import { ReduxDispatch } from "../../../hooks/redux-ts";
import { isShowRedirected, isShowRemote } from "../../../lib/show";
import { IShow } from "../../../reducers/shows/types";
import { CONFIRMATION_MODAL } from "../../modals/modal_root";
import { updateTierAndRefreshPayment } from "./tier_utils";
import { Tier } from "redcircle-types";

export interface IPlanCopy {
  level: string;
  title: string;
  desc: (tier: any) => string;
  ctaText?: string;
  canTrial?: boolean;
  downgradeCheck?: (
    dispatch: ReduxDispatch
  ) => Promise<{ copy: string; link?: string }[] | boolean>;
  shouldHide?: (user: any) => boolean;
  onSuccess?: (dispatch: ReduxDispatch) => void;
  showUpdatePlan?: boolean;
}

const checkTeamSeats = async (dispatch: ReduxDispatch, maxSeatsAllowed = 1) => {
  const resp = await dispatch(getCredentialsInOrg());
  if (resp.status !== 200) {
    dispatch(showError("error checking team seats"));
    return false;
  }
  if (resp?.json?.length > maxSeatsAllowed) {
    return [
      {
        copy: `Only ${maxSeatsAllowed} team seat${maxSeatsAllowed > 1 ? "s" : ""} allowed. Remove all additional Team Seats from your account.`,
        link: "https://support.redcircle.com/team-seats",
      },
    ];
  }
  return [];
};

// getPrice encapsulates the logic for determining the monthly tier price to
// show a user - if the user is already subscribed to that tier, it'll return
// their current price, otherwise it'll return the current price for that tier
const getMonthlyPrice = (tier: any, monthlyPrice: number, annualPriceAsMonthly: number) => {
  let price = tier.interval === TIER_LEVEL_MONTHLY ? monthlyPrice : annualPriceAsMonthly;
  if (tier?.amount > 0) {
    price = tier.interval === TIER_LEVEL_MONTHLY ? tier.amount / 100 : tier.amount / (100 * 12);
  }
  return price;
};

export const accountPlanCopy: IPlanCopy[] = [
  {
    level: tierLevels.core,
    title: "RedCircle Core Plan",
    shouldHide: (user: any) => user.userAttributes?.canAccessOGTier === "true",
    desc: () => "FREE | Standard features for the individual podcaster.",
    downgradeCheck: async (dispatch: ReduxDispatch) => {
      let blockers = [];
      let resp = await dispatch(getShowForUser());
      if (resp.status !== 200) {
        return false;
      }
      if (
        resp.json
          ?.filter((show: IShow) => !isShowRemote(show)) // Only checking non remote shows for downgrade  check
          .filter((show: IShow) => show.isVisible && !isShowRedirected(show)).length > 1
      ) {
        blockers.push({
          copy: "Remove all but 1 podcast from your account.",
          link: "https://support.redcircle.com/deleting-your-podcast",
        });
      }
      resp = await dispatch(getDealsForUser());
      if (resp.status !== 200) {
        return false;
      }
      if (
        resp.json?.filter((deal: any) => deal.state === "accepted" || deal.state === "running")
          .length > 0
      ) {
        blockers.push({
          copy: "Wait for all live Cross Promotions to complete. You currently have at least 1 Cross Promotion that is accepted or running. Once the Cross Promotion is complete, you’ll be able to downgrade to Core Plan.",
        });
      }
      const teamSeatError = await checkTeamSeats(dispatch);
      if (teamSeatError) {
        blockers = blockers.concat(teamSeatError);
      }
      return blockers;
    },
  },
  {
    level: tierLevels.og,
    title: "RedCircle Original Plan",
    desc: () => "FREE | Special plan for our earliest RedCircle customers.",
    shouldHide: (user: any) => user.userAttributes?.canAccessOGTier !== "true",
    downgradeCheck: async (dispatch) => {
      return checkTeamSeats(dispatch);
    }, // check number of seats
    onSuccess: (dispatch: ReduxDispatch) => {
      dispatch(
        showModal(CONFIRMATION_MODAL, {
          modalTitle: "Are you sure?",
          confirmationMessage:
            "This action will change your account back to the RedCircle Original Plan immediately.",
          callback: () => {
            updateTierAndRefreshPayment(dispatch, tierLevels.core).catch(() =>
              dispatch(showError("Error updating tier"))
            );
          },
        })
      );
    },
  },
  {
    level: tierLevels.growth,
    title: "RedCircle Growth Plan",
    canTrial: true,
    showUpdatePlan: true,
    desc: (tier: Tier) =>
      `$${getMonthlyPrice(
        tier,
        19.99,
        14.99
      )}/mth | Advanced features for the podcaster looking to grow.`,
    downgradeCheck: async (dispatch) => {
      return checkTeamSeats(dispatch);
    }, // check number of seats
  },
  {
    level: tierLevels.pro,
    showUpdatePlan: true,

    title: "RedCircle Pro Plan",
    canTrial: true,

    desc: (tier: Tier) =>
      `$${getMonthlyPrice(tier, 34.99, 29.99)}/mth | Premium features for the professional podcaster.`,
    downgradeCheck: async (dispatch) => {
      return checkTeamSeats(dispatch, 5);
    }, // check number of seats
  },
  {
    level: tierLevels.enterprise,
    title: "RedCircle Enterprise Plan",
    showUpdatePlan: true,
    desc: (tier: Tier) =>
      `$${getMonthlyPrice(tier, 119.99, 99.99)}/mth | The most comprehensive and custom features for networks.`,
  },
];
export const tierOrder = {
  [tierLevels.core]: 0,
  [tierLevels.og]: 0,
  [tierLevels.growth]: 1,
  [tierLevels.pro]: 2,
  [tierLevels.enterprise]: 3,
};
export const getMailtoLink = (email: string) =>
  `mailto:support@getredcircle.com?subject=RedCircle%20Enterprise%20Plan%20Upgrade%20Inquiry&body=Hi%20there%2C%0D%0AI%E2%80%99m%20interested%20in%20speaking%20to%20someone%20about%20upgrading%20to%20the%20RedCircle%20Enterprise%20Plan%20and%20learning%20more.%0D%0AThank%20you!%0D%0A%0D%0AAccount%20email%3A%20${encodeURIComponent(
    email
  )}`;
