import { Link } from "react-router-dom";
import WarningPanelv2 from "src/components/lib/warning_panel/warning_panel_v2";
import { CampaignItemStateAccepted, CampaignItemStateRunning } from "src/constants/campaigns";
import { campaignIsDiscrete } from "src/lib/campaigns";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";

export const NextStepsBanner = ({
  campaignItem,
  campaign,
}: {
  campaignItem?: ICampaignItem;
  campaign: ICampaign;
}) => {
  const statesToShow = new Set([CampaignItemStateRunning, CampaignItemStateAccepted]);
  if (!campaignItem || !statesToShow.has(campaignItem.state) || !campaignIsDiscrete(campaign)) {
    return null;
  }

  return (
    <WarningPanelv2 className={"m-bs"} withShadow>
      <h4>Next Steps</h4>
      <div className="seedcast-label">PUBLISH EPISODES</div>
      Go to your{" "}
      <Link className={"bold"} to={`/shows/${campaignItem.showUUID}/ep`}>
        Episodes Page
      </Link>{" "}
      to upload and publish new episodes based on the timeline below.
    </WarningPanelv2>
  );
};

export const AudioSwapWarningBanner = () => (
  <WarningPanelv2 className="m-bs">
    <h3 className="m-b0">Campaign Paused</h3>
    <p className="subtitle uppercase m-b0 lh-xs">Replace ad audio now</p>
    <p className="text m-b0 lh-s">
      The brand found an issue with your ad audio and paused this campaign until your audio is
      replaced.
    </p>
  </WarningPanelv2>
);
