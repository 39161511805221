import size from "lodash/size";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import {
  CampaignItemStateAwaitingAudio,
  CampaignItemStateSent,
} from "../../../constants/campaigns";
import activeLineIcon from "../../../icons/campaign_steps/active.svg";
import nonActiveLineIcon from "../../../icons/campaign_steps/non_active.svg";
import receiveOfferIcon from "../../../icons/campaign_steps/receive_offer.svg";
import receiveOfferCompleteIcon from "../../../icons/campaign_steps/receive_offer_complete.svg";
import uploadAdAudioIcon from "../../../icons/campaign_steps/upload_ad_audio.svg";
import uploadAdAudioCompleteIcon from "../../../icons/campaign_steps/upload_ad_audio_complete.svg";
import uploadEpisodesIcon from "../../../icons/campaign_steps/upload_episodes.svg";
import uploadEpisodesCompleteIcon from "../../../icons/campaign_steps/upload_episodes_complete.svg";
import { campaignIsDiscrete } from "../../../lib/campaigns";
import { localDateAndTime } from "../../../lib/date";
import { campaignHasProductExchange } from "../../modals/campaign_schedule_podcast/campaign_schedule_podcast_utils";
import { getTotalSpotsFromCampaignItem } from "../campaigns/campaign_page/campaign_page_utils";

export const AdDetailsStepsRenderProp = ({ campaign, campaignItem, render, horizontalLines }) => {
  const steps = [
    {
      offIcon: receiveOfferIcon,
      onIcon: receiveOfferCompleteIcon,
      copy: "Accept Ad Offer",
      isNext: () => campaignItem.state === CampaignItemStateSent,
      subCopy: () => {
        return (
          <small>
            <b>Due by {localDateAndTime(moment.unix(campaignItem?.respondByAt))}</b>
          </small>
        );
      },
    },
    {
      offIcon: uploadAdAudioIcon,
      onIcon: uploadAdAudioCompleteIcon,
      copy: campaignHasProductExchange(campaign) ? (
        <span>
          Receive Product; <br /> Record & Upload Ad
        </span>
      ) : (
        "Record & Upload Ad"
      ),
      isNext: () => campaignItem.state === CampaignItemStateAwaitingAudio,
      subCopy: () => {
        const time = moment.unix(campaignItem?.uploadAudioBy);
        return (
          <small>
            <b>Due by {localDateAndTime(time)}</b>
          </small>
        );
      },
    },
    ...(campaignIsDiscrete(campaign)
      ? [
          {
            offIcon: uploadEpisodesIcon,
            onIcon: uploadEpisodesCompleteIcon,
            copy: "Publish New Episodes",
            isNext: () =>
              campaignItem?.flightConfigs.some(
                (config) => size(config.assignedEpisodeUUIDs) < config.targetEpisodeCountInTimeRange
              ),
            subCopy: () => `${getTotalSpotsFromCampaignItem(campaignItem)} total episodes`,
          },
        ]
      : []),
  ];

  const currentStep = steps.findIndex(({ isNext }) => isNext());
  const renderProps = steps.map(({ offIcon, onIcon, copy, subCopy }, i) => {
    const shouldShowOnIcon = currentStep === -1 || i < currentStep;
    return {
      copy,
      subCopy: currentStep === i ? subCopy() : "",
      betweenIcon: i < steps.length - 1 && (
        <div>
          <img
            style={{
              ...(horizontalLines && { transform: "rotate(90deg)" }),
              padding: "0 8px",
            }}
            src={shouldShowOnIcon ? activeLineIcon : nonActiveLineIcon}
            alt=""
          />
        </div>
      ),
      icon: shouldShowOnIcon ? onIcon : offIcon,
    };
  });
  return renderProps.map(render);
};

export const AdDetailsSteps = ({ campaign, campaignItem }) => {
  return (
    <AdDetailsStepsRenderProp
      render={({ icon, betweenIcon, copy, subCopy }, i) => {
        return (
          <div key={i}>
            <div className={"m-vxs flex-row-container align-center"}>
              <img src={icon} alt="" />
              <div className={"m-lxxs"}>
                {copy}
                <br />
                {subCopy}
              </div>
            </div>
            {betweenIcon}
          </div>
        );
      }}
      campaign={campaign}
      campaignItem={campaignItem}
    />
  );
};
export const AdDashBoardSteps = ({ campaignItem, hide }) => {
  const campaign = useSelector((state) => state.campaigns.campaigns?.[campaignItem.campaignUUID]);
  if (hide) {
    return null;
  }
  return (
    <div className={"flex-row-container align-center m-lm"}>
      <AdDetailsStepsRenderProp
        horizontalLines={true}
        render={({ icon, betweenIcon, copy, subCopy }, i) => {
          return (
            <div key={i} className={"flex-row-container"}>
              <img src={icon} alt="" />
              <div className={"m-hxxxs"}>{betweenIcon}</div>
            </div>
          );
        }}
        campaign={campaign}
        campaignItem={campaignItem}
      />
    </div>
  );
};
