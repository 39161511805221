// start and end are moment obj
export const formatDates = (start, end) => {
  if (start.isSame(end, "month")) {
    return `${start.format("MMM DD")} - ${end.format("DD, YYYY")}`;
  }
  if (start.isSame(end, "year")) {
    return `${start.format("MMM DD")} - ${end.format("MMM DD, YYYY")}`;
  }
  return `${start.format("ll")} - ${end.format("ll")}`;
};

export const getTotalSpots = (item) =>
  item.flightConfigs?.reduce((agg, config) => agg + config.targetEpisodeCountInTimeRange, 0);

export const targetingOptionsToPlacements = (targetingOptions = {}) => {
  let placements = [];
  if (targetingOptions.preRoll) placements.push("Pre-Roll");
  if (targetingOptions.midRoll) placements.push("Mid-Roll");
  if (targetingOptions.postRoll) placements.push("Post-Roll");
  return placements.join(", ");
};
