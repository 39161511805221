import { Col, Row } from "antd";
import React, { ReactNode, useState } from "react";
import { If } from "react-extras";
import { getFormValues } from "redux-form";
import { useSelectorTS } from "../../../hooks/redux-ts";
import { ValidationError } from "../../../lib/errors";
import { HttpError } from "../../../lib/http";
import LoadingButton from "../../forms/loading_button/loading_button";
import BaseModalV2, { IBaseModalV2Props } from "../base_modal_v2";
type submitFuncType = (formValues?: any) => PromiseLike<any> | undefined;

export interface IPageProps extends IBaseModalV2Props {
  formName?: string;
  hide?: boolean | (() => boolean);
  onSubmit: submitFuncType;
  disabled: boolean;
  shouldDisable: () => boolean;
  submitText?: string;
  closeOnly?: boolean;
  hideBack?: boolean;
  nextText?: string;
  cancelText?: string;
  backText?: string;
  disabledMessage?: string;
  backFunc: () => void | Promise<void>;
  extraButton?: (props: {
    nextPage: () => void;
    nextPageOrSubmit: () => Promise<void>;
    currentPageNumber: number;
  }) => false | ReactNode;
}

interface IMultiStepModalProps {
  startingPage?: number;
  pageNumber?: number;
  onUpdatePage?: (pageNumber: number) => void;
  pages: IPageProps[];
  close?: () => void;
  closeText?: string;
  hideCloseButton?: boolean;
  onSubmit?: submitFuncType;
  isLoading?: boolean;
  commonSettings: any;
  modalProps?: any;
  submitText?: string;
  nextText?: string;
  cancelText?: string;
  backText?: string;
}

export const MultiStepModal = (props: IMultiStepModalProps) => {
  const {
    startingPage = 0,
    pageNumber,
    onUpdatePage,
    pages,
    close,
    closeText = "Close",
    hideCloseButton,
    onSubmit,
    isLoading,
    commonSettings,
    modalProps,
    submitText = "Submit",
    nextText = "Next",
    cancelText = "Cancel",
    backText = "Back",
  } = props;

  // eslint-disable-next-line prefer-const
  let [currentPageNumber, updatePageInternal] = useState(startingPage);
  const [submitting, updateSubmitting] = useState(false);
  if (pageNumber !== undefined) {
    currentPageNumber = pageNumber;
  }

  const formValues = useSelectorTS((state) => {
    const formName = pages?.[currentPageNumber]?.formName;
    return formName && getFormValues(formName)(state);
  });
  if (!Array.isArray(pages) || !pages.length) {
    return null;
  }

  const updatePage = (val: number) => {
    onUpdatePage !== undefined ? onUpdatePage(val) : updatePageInternal(val);
  };

  const currentPage = { ...pages[currentPageNumber], ...commonSettings };
  currentPage.isLoading = currentPage.isLoading || submitting; // prevents flickering when you click next

  const prevPageOrClose = async () => {
    let prevPageNumber = currentPageNumber - 1;

    while (
      pages[prevPageNumber] &&
      "hide" in pages[prevPageNumber] &&
      (typeof pages[prevPageNumber].hide === "function"
        ? pages[prevPageNumber].hide()
        : pages[prevPageNumber].hide)
    ) {
      prevPageNumber -= 1;
    }
    if (currentPage.backFunc) {
      await callSubmit(currentPage.backFunc, () => updatePage(prevPageNumber));
      return;
    }
    if (pages[prevPageNumber] === undefined) {
      if (close) {
        close();
      }
      return;
    }
    updatePage(prevPageNumber);
  };

  const callSubmit = async (submitFunc: submitFuncType, onSuccess = nextPage) => {
    updateSubmitting(true);
    return Promise.resolve(submitFunc(formValues))
      .then(onSuccess)
      .catch((e) => {
        // we want to throw the rest because real errors like syntax issues can silently fail
        if (!(e instanceof HttpError) && !(e instanceof ValidationError)) {
          throw e;
        }
      })
      .finally(() => updateSubmitting(false));
  };

  const nextPage = (newPageNum?: number) => {
    let nextPageNumber =
      typeof newPageNum === "number" && newPageNum >= 0 && newPageNum < pages.length
        ? newPageNum // adding optional absolute new page number parameter to control flow of modal
        : currentPageNumber + 1;

    while (
      pages[nextPageNumber] &&
      "hide" in pages[nextPageNumber] &&
      (typeof pages[nextPageNumber].hide === "function"
        ? pages[nextPageNumber].hide()
        : pages[nextPageNumber].hide)
    ) {
      nextPageNumber += 1;
    }

    if (pages[nextPageNumber] === undefined) {
      if (close) {
        close();
      }
      return;
    }
    updatePage(nextPageNumber);
  };

  const nextPageOrSubmit = async () => {
    if (!currentPage.validator || currentPage.validator(formValues)) {
      if (currentPage.onSubmit) {
        await callSubmit(currentPage.onSubmit);
        return;
      }

      if (currentPageNumber === pages.length - 1 && onSubmit) {
        await callSubmit(onSubmit);
        return;
      }

      nextPage();
    }
  };
  return (
    <BaseModalV2
      fillScreen={Boolean(currentPage?.fillScreen)}
      modalProps={modalProps}
      close={close}
      hideCloseButton={hideCloseButton}
      Footer={
        <Row className="height-100 p-hm" align="middle">
          <If condition={!currentPage.closeOnly}>
            <If condition={!currentPage.hideBack}>
              <Col xs={12}>
                <div className="flex-row-container justify-start">
                  <LoadingButton
                    antdBtnProps={{ type: "link" }}
                    isLoading={currentPage.backFunc && (submitting || isLoading)}
                    onClick={prevPageOrClose}>
                    {currentPage.backText || (currentPageNumber === 0 ? cancelText : backText)}
                  </LoadingButton>
                </div>
              </Col>
            </If>
            <Col xs={currentPage?.hideBack ? 24 : 12}>
              <div className="flex-row-container justify-end">
                {typeof currentPage?.extraButton === "function" &&
                  currentPage?.extraButton?.({ nextPage, nextPageOrSubmit, currentPageNumber })}
                <LoadingButton
                  isLoading={isLoading || submitting}
                  className="align-center"
                  disabledMessage={currentPage?.disabledMessage}
                  disabled={
                    currentPage.disabled ||
                    (currentPage.shouldDisable && currentPage.shouldDisable(formValues))
                  }
                  antdBtnProps={{
                    size: "large",
                  }}
                  onClick={nextPageOrSubmit}>
                  {currentPage.submitText ||
                    (currentPageNumber < pages.length - 1 ? nextText : submitText)}
                </LoadingButton>
              </div>
            </Col>
          </If>
          <If condition={!!currentPage.closeOnly}>
            <Col xs={24}>
              <div className="flex-row-container justify-end align-center">
                <button className="btn btn-primary m-b0" onClick={close}>
                  {currentPage.closeText || closeText}
                </button>
              </div>
            </Col>
          </If>
        </Row>
      }
      {...currentPage}
    />
  );
};
