import { Checkbox, Divider, Form, Input, Radio, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { AiFillInfoCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { showSuccess } from "src/actions/app";
import { respondToVettingInvitation } from "src/action_managers/vetting";
import RCButton from "src/components/lib/button/button";
import Drawer from "src/components/lib/drawer";
import Loading from "src/components/lib/loading";
import { permissionTypes } from "src/constants/permission_roles";
import {
  MULTIPLE_CHOICE_TYPE_CHECKBOX,
  MULTIPLE_CHOICE_TYPE_RADIO,
  OTHER_OPTION_VALUE,
  VETTING_QUESTION_TYPES,
} from "src/constants/vetting";
import { useReduxDispatch } from "src/hooks/redux-ts";
import { LOCAL_STORAGE_KEYS, useLocalStorage } from "src/hooks/useLocalStorage";
import { useGetVettingInfoForCampaignItem } from "src/hooks/vetting";
import { formatMoney } from "src/lib/format-money";
import { useCanAccess } from "src/lib/permissions";
import { VettingQuestion } from "src/reducers/vetting";
import { getCampaignItemStart } from "./show_advertising_page_util";
import styles from "./vetting_response_drawer.module.scss";

interface IDrawerProps {
  open: boolean;
  onClose: () => void;
  onAfterSubmit: () => void;
  campaignItemUUID?: string;
  isLoading: boolean;
}

const PARTICIPATION_PROMPT = "Are you interested in this opportunity?";
const PARTICIPATION_DECLINE_REASONS = [
  "Not interested in this brand/product",
  "Currently advertising with a competitor of this brand/product",
  "Offer amount is too low",
  "Timeline doesn’t work",
  "Other",
];
const SHORT_TEXT_MAX_LENGTH = 500;
const LONG_TEXT_MAX_LENGTH = 25000;

export default function VettingResponseDrawer({
  open,
  onClose,
  onAfterSubmit,
  campaignItemUUID,
  isLoading,
}: IDrawerProps) {
  const dispatch = useReduxDispatch();

  const {
    invitation,
    form: vettingForm,
    campaignItem,
    isLoading: isInvitationLoading,
  } = useGetVettingInfoForCampaignItem(campaignItemUUID);
  const isAnyLoading = isLoading || isInvitationLoading;

  const canEditRap = useCanAccess(permissionTypes.editRap, invitation?.showUUID);
  const [userHasSeenPostVettingModal, setUserHasSeenPostVettingModal] = useLocalStorage(
    LOCAL_STORAGE_KEYS.user_has_seen_post_vetting_modal,
    false
  );

  const [form] = Form.useForm();
  const formFields = Form.useWatch([], form) || {};
  const fieldsWithOtherSelected: Record<string, boolean> = Object.keys(formFields).reduce(
    (acc, key) => {
      const value = formFields[key];
      if (
        value === OTHER_OPTION_VALUE ||
        (Array.isArray(value) && value.includes(OTHER_OPTION_VALUE))
      ) {
        return { ...acc, [key]: true };
      }
      return acc;
    },
    {}
  );

  const isExpired = invitation?.responseDueAt ? invitation.responseDueAt < dayjs().unix() : false;
  const hasResponded = !!invitation?.respondedAt;
  const isDisabled = !canEditRap || hasResponded || isExpired;
  const questions = vettingForm?.questions || [];
  const participationQuestionUUID = vettingForm?.questions?.find(
    (q) => q.prompt === PARTICIPATION_PROMPT
  )?.uuid;
  const userIsNotInterested =
    participationQuestionUUID && formFields[participationQuestionUUID] === "no";

  const showBudget = vettingForm?.visibility.shareBudget && campaignItem?.totalCreatorAmount !== 0;
  const showTimeline =
    vettingForm?.visibility.shareTimeline && campaignItem?.startAt && campaignItem.startAt > 0;

  useEffect(() => {
    // fill in form with existing responses
    if (form && hasResponded && questions && invitation?.responses) {
      const initialValues = questions.reduce((acc, q) => {
        const newAcc: Record<string, any> = { ...acc };
        const response = invitation.responses?.find((r) => r.questionUUID === q.uuid);
        if (response) {
          // check if question type is multiple choice
          if (
            q.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE &&
            q.properties.multipleChoiceType === MULTIPLE_CHOICE_TYPE_CHECKBOX
          ) {
            newAcc[q.uuid] = response.answer;
          } else {
            newAcc[q.uuid] = response.answer[0];
          }

          if (response.other) {
            newAcc[`${q.uuid}_other`] = response.other;
          }

          // check for special case of participation prompt
          if (
            q.uuid === participationQuestionUUID &&
            response.answer[0] === "no" &&
            response.other
          ) {
            if (PARTICIPATION_DECLINE_REASONS.includes(response.other)) {
              newAcc[`${q.uuid}_other`] = response.other;
            } else {
              newAcc[`${q.uuid}_other`] = "Other";
              newAcc[`${q.uuid}_other_reason`] = response.other;
            }
          }
        }
        return newAcc;
      }, {});

      form.setFieldsValue(initialValues);
    }
  }, [hasResponded, form, questions, participationQuestionUUID]);

  const requiredValidation = (q: VettingQuestion) => ({
    required: !userIsNotInterested && q.validations?.required,
    message: "Response required!",
  });

  const handleFormSubmit = (values: Record<string, any>) => {
    const responses = questions.map((q) => {
      const questionUUID = q.uuid;
      const answer = values[questionUUID];

      return {
        questionUUID,
        answer: Array.isArray(answer) ? answer : [answer],
        // override the "Other" answer with the "Other Reason" answer (for participation prompt)
        other: values[`${questionUUID}_other_reason`] || values[`${questionUUID}_other`],
      };
    });

    if (invitation?.uuid) {
      dispatch(respondToVettingInvitation(invitation.uuid, responses)).then((res) => {
        if (res.status === 200) {
          if (!userHasSeenPostVettingModal) {
            onAfterSubmit();
            setUserHasSeenPostVettingModal(true);
          } else {
            dispatch(
              showSuccess(
                <span>
                  Response submitted successfully!{" "}
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      onAfterSubmit();
                    }}>
                    Learn more about what happens next.
                  </a>
                </span>
              )
            );
          }

          onClose();
        }
      });
    }
  };

  return (
    <Drawer open={open} onClose={onClose}>
      {open && (!campaignItem || !invitation || isAnyLoading) && (
        <>
          <Drawer.Header />
          <Drawer.Body className="flex-column-container align-center">
            <Loading />
          </Drawer.Body>
        </>
      )}
      {open && campaignItem && invitation && !isAnyLoading && (
        <>
          <Drawer.Header className="align-start">
            <div className="flex-column-container">
              <span className="redcircle-form-label">Brand Inquiry:</span>
              <h3>{vettingForm?.brandName}</h3>
              {invitation?.responseDueAt && (
                <span className="redcircle-form-label m-bxxxs">
                  Due By: {dayjs.unix(invitation.responseDueAt).format("MMM DD, YYYY")}
                </span>
              )}
              {invitation?.respondedAt && (
                <span className="redcircle-form-label m-b0 color-primary">
                  <AiOutlineInfoCircle /> Responded:{" "}
                  {dayjs.unix(invitation.respondedAt).format("MMM DD, YYYY")}
                </span>
              )}
              {!invitation?.respondedAt && isExpired && (
                <span className="redcircle-form-label m-b0 color-primary">
                  <AiOutlineInfoCircle /> This invitation has expired.
                </span>
              )}
            </div>
          </Drawer.Header>

          <Drawer.Body>
            <Divider className="m-t0 m-bs" />
            <strong>Brand Name</strong>
            <p>{vettingForm?.brandName}</p>
            <strong>Campaign Info</strong>
            <div
              className={styles["campaign-info"]}
              dangerouslySetInnerHTML={{
                __html: vettingForm?.campaignInfo ? vettingForm.campaignInfo : "",
              }}
            />

            <strong>
              Potential Budget{" "}
              <Tooltip title="This is the estimated budget that RedCircle thinks will be possible for this campaign and may not exactly match the advertiser’s final deal.">
                <AiFillInfoCircle />
              </Tooltip>
            </strong>
            <p>
              {showBudget
                ? formatMoney(campaignItem.totalCreatorAmount)
                : "No set budget has been determined yet; the advertiser is exploring interest."}
            </p>

            <strong>
              Potential Timeline{" "}
              <Tooltip title="This is the estimated start and end dates that RedCircle thinks this campaign will run for. It may not exactly match the advertiser’s final plan.">
                <AiFillInfoCircle />
              </Tooltip>
            </strong>
            <p>
              {showTimeline
                ? getCampaignItemStart(campaignItem)?.format("MMM DD, YYYY")
                : "No set timeline has been determined yet; the advertiser is exploring interest."}
            </p>
            <Divider className="m-vxs" />

            <Form form={form} onFinish={handleFormSubmit}>
              <div className="flex-column-container m-ts">
                {questions.map((q) => {
                  return (
                    <div key={q.uuid} className="m-bs">
                      <span className="flex-row-container align-center m-bxxs">
                        <label htmlFor={q.uuid} className="m-a0">
                          <strong>
                            <h5>
                              {q.prompt}
                              {q.validations?.required && "*"}
                            </h5>
                          </strong>
                        </label>
                        {q.tooltip && (
                          <Tooltip title={q.tooltip} className="m-lxxxs">
                            <AiFillInfoCircle />
                          </Tooltip>
                        )}
                      </span>

                      <Form.Item name={q.uuid} rules={[requiredValidation(q)]} className="m-b0">
                        {q.type === VETTING_QUESTION_TYPES.YES_NO && (
                          <Radio.Group size="large" disabled={isDisabled}>
                            <Radio.Button value="yes">Yes</Radio.Button>
                            <Radio.Button value="no">No</Radio.Button>
                          </Radio.Group>
                        )}

                        {q.type === VETTING_QUESTION_TYPES.SHORT_TEXT && (
                          <Input
                            type="text"
                            size="large"
                            disabled={isDisabled}
                            maxLength={SHORT_TEXT_MAX_LENGTH}
                          />
                        )}

                        {q.type === VETTING_QUESTION_TYPES.LONG_TEXT && (
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            size="large"
                            disabled={isDisabled}
                            maxLength={LONG_TEXT_MAX_LENGTH}
                          />
                        )}

                        {q.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE && q.fields && (
                          <>
                            {q.properties.multipleChoiceType === MULTIPLE_CHOICE_TYPE_RADIO && (
                              <>
                                {q.fields.length <= 5 && (
                                  <Radio.Group
                                    size="large"
                                    className="flex-column-container"
                                    disabled={isDisabled}>
                                    {q.fields.map((field) => {
                                      return (
                                        <Radio
                                          className="m-bxxs"
                                          value={field.label}
                                          key={field.label}>
                                          <span className="flex-row-container align-center">
                                            <span>{field.label} </span>
                                            {field.tooltip && (
                                              <Tooltip title={field.tooltip} className="m-lxxxs">
                                                <AiFillInfoCircle />
                                              </Tooltip>
                                            )}
                                          </span>
                                        </Radio>
                                      );
                                    })}
                                    {q.properties.allowOtherChoice && (
                                      <Radio
                                        className="m-bxxs"
                                        value={OTHER_OPTION_VALUE}
                                        disabled={isDisabled}>
                                        Other
                                      </Radio>
                                    )}
                                  </Radio.Group>
                                )}
                                {q.fields.length > 5 && (
                                  <Select
                                    size="large"
                                    className="m-bxxs"
                                    placeholder="Select an option"
                                    disabled={isDisabled}>
                                    {q.fields.map((field) => {
                                      return (
                                        <Select.Option value={field.label} key={field.label}>
                                          {field.label}
                                        </Select.Option>
                                      );
                                    })}
                                    {q.properties.allowOtherChoice && (
                                      <Select.Option
                                        value={OTHER_OPTION_VALUE}
                                        disabled={isDisabled}>
                                        Other
                                      </Select.Option>
                                    )}
                                  </Select>
                                )}
                              </>
                            )}

                            {q.properties.multipleChoiceType === MULTIPLE_CHOICE_TYPE_CHECKBOX && (
                              <Checkbox.Group
                                className="flex-column-container"
                                disabled={isDisabled}>
                                {q.fields.map((field) => {
                                  return (
                                    <Checkbox
                                      className="m-bxxs"
                                      value={field.label}
                                      key={field.label}>
                                      <span className="flex-row-container align-center">
                                        <span>{field.label} </span>
                                        {field.tooltip && (
                                          <Tooltip title={field.tooltip} className="m-lxxxs">
                                            <AiFillInfoCircle />
                                          </Tooltip>
                                        )}
                                      </span>
                                    </Checkbox>
                                  );
                                })}
                                {q.properties.allowOtherChoice && (
                                  <Checkbox value={OTHER_OPTION_VALUE} disabled={isDisabled}>
                                    Other
                                  </Checkbox>
                                )}
                              </Checkbox.Group>
                            )}
                          </>
                        )}
                      </Form.Item>

                      {fieldsWithOtherSelected[q.uuid] && (
                        <Form.Item
                          name={`${q.uuid}_other`}
                          rules={[{ required: true, message: "Please enter other reason" }]}>
                          <Input
                            type="text"
                            size="large"
                            placeholder="Other"
                            disabled={isDisabled}
                          />
                        </Form.Item>
                      )}

                      {/* we need a special field for the "Participation Prompt" */}
                      {q.uuid === participationQuestionUUID && formFields[q.uuid] === "no" && (
                        <>
                          <Form.Item
                            name={`${q.uuid}_other`}
                            className={formFields[`${q.uuid}_other`] ? "m-bxxxs" : ""}
                            rules={[{ required: true, message: "Please enter reason" }]}>
                            <Radio.Group
                              size="large"
                              className="flex-column-container m-txxs"
                              disabled={isDisabled}>
                              {PARTICIPATION_DECLINE_REASONS.map((reason) => {
                                return (
                                  <Radio className="m-bxxs" value={reason} key={reason}>
                                    {reason}
                                  </Radio>
                                );
                              })}
                            </Radio.Group>
                          </Form.Item>

                          {/* ...and another special special field for No -> Other */}
                          {formFields[`${q.uuid}_other`] === "Other" && (
                            <Form.Item
                              name={`${q.uuid}_other_reason`}
                              rules={[{ required: true, message: "Please enter reason" }]}>
                              <Input
                                type="text"
                                size="large"
                                placeholder="Please provide other reason"
                                disabled={isDisabled}
                              />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </Form>
          </Drawer.Body>

          <Drawer.Footer className="flex-row-container justify-space-between">
            <RCButton type="link" size="large" onClick={onClose}>
              Cancel
            </RCButton>
            <Tooltip
              title={
                !canEditRap
                  ? "You do not have permission to edit this response!"
                  : hasResponded && "You have already responded to this invitation!"
              }
              trigger={isDisabled ? ["hover", "click"] : []}>
              <div>
                <RCButton
                  type="primary"
                  size="large"
                  onClick={() => form.submit()}
                  disabled={isDisabled}>
                  Submit
                </RCButton>
              </div>
            </Tooltip>
          </Drawer.Footer>
        </>
      )}
    </Drawer>
  );
}
