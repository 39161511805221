import { Table, TableProps } from "antd";
import { classNames } from "react-extras";
import "./rc_table.scss";

interface IRCTableProps<T> extends TableProps<T> {}

// latest version of our table, using Antd as base and applying styling on it
const RCTable = <T extends Record<string, any>>(props: IRCTableProps<T>) => {
  const { className, ...rest } = props;

  return <Table<T> className={classNames(className, "redcircle-table")} {...rest} />;
};

export default RCTable;
