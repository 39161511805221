import { Col, Row } from "antd";
import { Button } from "redcircle-ui";
import { SignOutActionManager } from "src/action_managers/account";
import ExternalLink from "src/components/lib/external_link/external_link";
import LoggedOutFrame from "src/components/lib/logged_out_frame/logged_out_frame";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { getMegapodURL } from "src/lib/config";
import { useHideSidebar, useHideWarningbar } from "../account/tier_utils";

export const WaitlistPage = () => {
  const { user } = useSelectorTS((state) => state.user);
  const dispatch = useReduxDispatch();

  useHideSidebar();
  useHideWarningbar();

  return (
    <LoggedOutFrame
      withShadow
      noBar
      hideSignUp
      hideSignIn
      manualLink={
        <Button
          type="link"
          size="large"
          onClick={() => {
            dispatch(new SignOutActionManager({ token: user.authToken }).run());
          }}>
          <strong>Sign Out</strong>
        </Button>
      }>
      <Row>
        <Col className="text-center">
          <>
            <h1 className="m-bxs">You are on the List!</h1>
            <WaitlistContent className="fs-xs lh-s" />
          </>
        </Col>
      </Row>
    </LoggedOutFrame>
  );
};

/**
 * Waitlist content, used in both waitlist page and waitlist modal
 */
export const WaitlistContent = ({
  className,
  hideCTA,
}: {
  className?: string;
  hideCTA?: boolean;
}) => {
  const startNewPodcastLink = `${getMegapodURL()}pricing?toApp=1&wait=0`;
  const switchYourHostingLink = `${getMegapodURL()}pricing?toApp=1&wait=0`; //TODO: Need to figure out which new page this might be
  return (
    <>
      <p className={className}>
        We're hard at work onboarding new podcasters to OpenRAP and adding new interconnections to
        hosting platforms. We'll reach out as soon as we are ready to bring you the best ad
        automation out there.
      </p>
      {!hideCTA && (
        <p className={className}>
          In the meantime, you can{" "}
          <ExternalLink target="" href={startNewPodcastLink}>
            start a new podcast
          </ExternalLink>{" "}
          or{" "}
          <ExternalLink href={switchYourHostingLink}>
            {" "}
            switch your hosting to RedCircle
          </ExternalLink>
          .
        </p>
      )}
    </>
  );
};
