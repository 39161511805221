import { mergeWith } from "lodash";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import union from "lodash/union";
import uniq from "lodash/uniq";
import {
  FETCH_SHOW_CAMPAIGNS_ACTION,
  FETCH_SHOW_CAMPAIGN_ACTION,
  RESPOND_TO_CAMPAIGN_OFFER_ACTION,
} from "../../actions/campaigns";
import {
  ASSIGN_SCRIPT_TO_ITEM,
  CANCEL_AUDIO_SWAP,
  CREATE_CAMPAIGN_ITEM,
  GET_CAMPAIGN,
  INITATE_AUDIO_SWAP,
  REMOVE_CAMPAIGN_ITEMS,
  RESET_CAMPAIGN_ITEMS,
  UPDATE_CAMPAIGN_ITEM,
  UPDATE_CAMPAIGN_ITEMS,
} from "../../action_managers/campaigns";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { CampaignItemsReduxState, ICampaignItem } from "./types";
import { AnyAction } from "redux";

export const initialState: CampaignItemsReduxState = {
  // TODO: merge these two in the components
  campaignItemByCampaignUUID: {},
  campaignItems: {},
  showCampaignItemUUIDs: {},
  isLoading: false,
  audioSwapReqIsLoading: false,
};

const resetCampaignItems = (state = initialState, action: AnyAction) => {
  if (action.type === RESET_CAMPAIGN_ITEMS) {
    return {
      ...initialState,
    };
  }

  return state;
};

const getCampaigns = httpReducer(GET_CAMPAIGN, initialState, {
  success: (state = initialState, action) => {
    return {
      ...state,
      campaignItemByCampaignUUID: {
        ...state.campaignItemByCampaignUUID,
        [action.payload.resp.uuid]: action.payload.resp.items?.map(
          ({ item }: { item: ICampaignItem }) => item.uuid
        ),
      },
      campaignItems: {
        ...state.campaignItems,
        ...keyBy(
          action.payload.resp.items?.map(({ item }: { item: ICampaignItem }) => item),
          "uuid"
        ),
      },
    };
  },
});

const createCampaignItem = httpReducer(CREATE_CAMPAIGN_ITEM, initialState, {
  success: (state, action) => {
    const byCampaignUUID = mapValues(
      groupBy(action.payload.resp, "campaignUUID"),
      (updatedItems, campaignUUID) =>
        uniq(
          updatedItems
            .map(({ uuid }) => uuid)
            .concat(state.campaignItemByCampaignUUID[campaignUUID] ?? [])
        )
    );
    return {
      ...state,
      campaignItemByCampaignUUID: {
        ...state.campaignItemByCampaignUUID,
        ...byCampaignUUID,
      },
      campaignItems: { ...state.campaignItems, ...keyBy(action.payload.resp, "uuid") },
    };
  },
});

const getShowCampaignItem = httpReducer(FETCH_SHOW_CAMPAIGN_ACTION, initialState, {
  success: (state, action) => {
    const resp = action.payload.resp;
    const campaignItem = resp.campaignItem;
    const showUUID = get(action, ["data", "showUUID"]);

    const campaignItems = {
      ...state.campaignItems,
      [campaignItem.uuid]: campaignItem,
    };

    const showCampaignItemUUIDs = {
      ...state.showCampaignItemUUIDs,
      [showUUID]: union([campaignItem.uuid], get(state.showCampaignItemUUIDs, showUUID, [])),
    };

    return {
      ...state,
      campaignItems,
      showCampaignItemUUIDs,
      campaignItemByCampaignUUID: mergeWith(
        state.campaignItemByCampaignUUID,
        { [campaignItem?.campaignUUID]: [campaignItem?.uuid] },
        (entryValues: string[] = [], newValues: string[] = []) => {
          return uniq([...entryValues, ...newValues]);
        }
      ),
    };
  },
});

const respondToCampaignOffer = httpReducer(RESPOND_TO_CAMPAIGN_OFFER_ACTION, initialState, {
  success: (state, action) => {
    const campaignItem = action.payload.resp;

    const campaignItems = {
      ...state.campaignItems,
      [campaignItem.uuid]: {
        ...(state.campaignItems?.[campaignItem.uuid] ?? {}),
        ...campaignItem,
        // fresh state is used to tell the rest of the app that the state just updated.
        // has implications for podcaster campaign page
        freshState: true,
      },
    };

    const showCampaignItemUUIDs = {
      ...state.showCampaignItemUUIDs,
      [campaignItem.showUUID]: union(
        [campaignItem.uuid],
        state.showCampaignItemUUIDs?.[campaignItem.showUUID] ?? []
      ),
    };

    return {
      ...state,
      campaignItems,
      showCampaignItemUUIDs,
    };
  },
});

const getShowCampaignItems = httpReducer(FETCH_SHOW_CAMPAIGNS_ACTION, initialState, {
  success: (state, action) => {
    const resp = action.payload.resp;
    const campaignItems = get(resp, "campaignItems", []) as ICampaignItem[];
    const showUUID = get(action, ["data", "showUUID"]);

    const campaignItemByUUID = {
      ...state.campaignItems,
      ...keyBy(campaignItems, "uuid"),
    };
    const showCampaignItemUUIDs = {
      ...state.showCampaignItemUUIDs,
      [showUUID]: campaignItems.map(({ uuid }) => uuid),
    };

    const newCampaignItemByCampaignUUID = campaignItems?.reduce<
      CampaignItemsReduxState["campaignItemByCampaignUUID"]
    >((accu, campaignItem) => {
      const { campaignUUID, uuid } = campaignItem;

      if (!Array.isArray(accu[campaignUUID])) accu[campaignUUID] = [];

      accu[campaignUUID].push(uuid);

      return accu;
    }, {});

    return {
      ...state,
      campaignItems: campaignItemByUUID,
      showCampaignItemUUIDs,
      campaignItemByCampaignUUID: mergeWith(
        state.campaignItemByCampaignUUID,
        newCampaignItemByCampaignUUID,
        (entryValues: string[] = [], newValues: string[] = []) => {
          return uniq([...entryValues, ...newValues]);
        }
      ),
    };
  },
});

const updateCampaignItems = httpReducer(UPDATE_CAMPAIGN_ITEMS, initialState, {
  success: (state = initialState, action) => {
    const updateRequests = action.payload.body.updateRequestByCampaignItemUUID as {
      [key: string]: { delete: boolean };
    };
    const resp = action.payload.resp as ICampaignItem[];
    const deletedUUIDs = Object.entries(updateRequests)
      .filter(([, updateBody]) => updateBody.delete)
      .map(([uuid]) => uuid);
    const updatesByCampaignUUID = mapValues(groupBy(resp, "campaignUUID"), (items) =>
      items.map(({ uuid }) => uuid)
    );
    return {
      ...state,
      campaignItemByCampaignUUID: {
        ...mapValues(state.campaignItemByCampaignUUID, (uuids, campaignUUID) =>
          uniq([...uuids, ...(updatesByCampaignUUID?.[campaignUUID] ?? [])]).filter(
            (uuid: string) => uuid && !deletedUUIDs.includes(uuid)
          )
        ),
      },
      campaignItems: {
        ...omit(state.campaignItems, deletedUUIDs),
        ...keyBy(action.payload.resp, "uuid"),
      },
      validationErrors: [],
    };
  },
});

const removeCampaignItems = httpReducer(REMOVE_CAMPAIGN_ITEMS, initialState, {
  success: (state = initialState, action) => {
    const itemUUIDsToRemove = (action?.data ?? []) as string[];

    const allItems = Object.values(state.campaignItems);
    const newCampaignItems: Record<string, ICampaignItem> = {};
    const newCampaignItemByCampaignUUID: Record<string, string[]> = {};
    const newShowCampaignItemUUIDs: Record<string, string[]> = {};

    for (const item of allItems) {
      const { uuid, campaignUUID, showUUID } = item;
      if (!itemUUIDsToRemove.includes(uuid)) {
        // Add campaign item to map of campaign Items
        newCampaignItems[uuid] = { ...item };

        // Add campaign items to campaign map
        if (!Array.isArray(newCampaignItemByCampaignUUID[campaignUUID])) {
          newCampaignItemByCampaignUUID[campaignUUID] = [];
        }
        newCampaignItemByCampaignUUID[campaignUUID].push(uuid);

        // Add campaign items to show map
        if (!Array.isArray(newShowCampaignItemUUIDs[showUUID])) {
          newShowCampaignItemUUIDs[showUUID] = [];
        }
        newShowCampaignItemUUIDs[showUUID].push(uuid);
      }
    }

    return {
      ...state,
      campaignItems: newCampaignItems,
      campaignItemByCampaignUUID: newCampaignItemByCampaignUUID,
      showCampaignItemUUIDs: newShowCampaignItemUUIDs,
    };
  },
});

// we don't need anything beyond the default functionality of tracking success/error.
// TODO: add real reducer
const updateCampaignItem = httpReducer(UPDATE_CAMPAIGN_ITEM, initialState, {
  success: (state) => ({ ...state, validationErrors: undefined }),
});

const initiateAudioSwap = httpReducer(
  INITATE_AUDIO_SWAP,
  initialState,
  {
    success: (state, action) => {
      const updatedCampaignItem: ICampaignItem = action.payload.resp;
      const { uuid: updatedUUID } = updatedCampaignItem;
      return {
        ...state,
        campaignItems: {
          ...state.campaignItems,
          [updatedUUID]: {
            ...state.campaignItems[updatedUUID],
            ...updatedCampaignItem,
          },
        },
      };
    },
  },
  {
    loadingKeyName: "audioSwapReqIsLoading",
  }
);

const cancelAudioSwap = httpReducer(
  CANCEL_AUDIO_SWAP,
  initialState,
  {
    success: (state, action) => {
      const updatedCampaignItem: ICampaignItem = action.payload.resp;
      const { uuid: updatedUUID } = updatedCampaignItem;
      return {
        ...state,
        campaignItems: {
          ...state.campaignItems,
          [updatedUUID]: {
            ...updatedCampaignItem,
          },
        },
      };
    },
  },
  {
    loadingKeyName: "audioSwapReqIsLoading",
  }
);

const assignScriptsToItem = httpReducer(ASSIGN_SCRIPT_TO_ITEM, initialState, {
  success: (state = initialState, action) => {
    const resp: ICampaignItem[] = action.payload.resp;

    const newCampaignItems = { ...state.campaignItems };

    for (const item of resp) {
      const { uuid } = item;
      newCampaignItems[uuid] = { ...state.campaignItems[uuid], ...item };
      if (typeof item?.scriptUUID === "string" && item.scriptUUID.length > 0) {
        newCampaignItems[uuid].scriptUUID = item.scriptUUID;
      } else {
        delete newCampaignItems[uuid]?.scriptUUID;
      }
    }

    // Map by campaign UUID
    const newCampaignItemByCampaignUUID: CampaignItemsReduxState["campaignItemByCampaignUUID"] = {};
    const items = Object.values(newCampaignItems);
    for (const item of items) {
      const { campaignUUID, uuid } = item;
      if (Array.isArray(newCampaignItemByCampaignUUID[campaignUUID])) {
        newCampaignItemByCampaignUUID[campaignUUID].push(uuid);
      } else {
        newCampaignItemByCampaignUUID[campaignUUID] = [uuid];
      }
    }

    return {
      ...state,
      campaignItems: newCampaignItems,
      campaignItemByCampaignUUID: newCampaignItemByCampaignUUID,
      isLoading: false,
    };
  },
});

export default reduceReducers(
  resetCampaignItems,
  getCampaigns,
  createCampaignItem,
  getShowCampaignItem,
  getShowCampaignItems,
  removeCampaignItems,
  updateCampaignItems,
  updateCampaignItem,
  initiateAudioSwap,
  cancelAudioSwap,
  respondToCampaignOffer,
  assignScriptsToItem
);
