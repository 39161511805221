import { CheckCircleFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useMemo } from "react";
import { showSuccess } from "src/actions/app";
import { getYoutubeForShow } from "src/action_managers/shows";
import ClipboardAction from "src/components/lib/clipboard-action";
import Divider from "src/components/lib/divider";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import ExternalLink from "src/components/lib/external_link";
import HighlightBox from "src/components/lib/highlight_box";
import InfoTooltip from "src/components/lib/info";
import Loading from "src/components/lib/loading";
import MediaPlayer from "src/components/lib/media_player";
import { TwoLineCell } from "src/components/lib/tables/styled-table";
import { CampaignItemStateRunning, CampaignStyleCampaignAudio } from "src/constants/campaigns";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import facebook from "src/icons/facebook.svg";
import instagram from "src/icons/instagram.svg";
import twitter from "src/icons/twitter.svg";
import youtube from "src/images/youtube_logo.png";
import tikTok from "src/images/tiktok-logo.png";
import { formatMoney } from "src/lib/format-money";
import { hummanizeAudienceSize } from "src/lib/numbers";
import { IEpisode } from "src/reducers/episodes";
import { AdRead } from "src/reducers/public_show";
import { IShow } from "src/reducers/shows";
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export default function AdvertiserBrowseShowAbout({
  show,
}: {
  show: IShow & { episodes: IEpisode[] };
}) {
  const hosts = show?.advertisementSettings?.hosts || [];
  const averageEpisodeDuration = dayjs.duration(show.averageEpisodeDuration || 0).humanize();
  const showAdvertisingInfo =
    show.advertisementSettings && averageEpisodeDuration && show.estimatedEpisodeFrequency;
  const latestEpisode = show.episodes?.sort((a, b) => b.publishedAt - a.publishedAt)[0];

  const hostReadCPMS = [
    { fieldName: "hostReadPreRollCPM", label: "PRE-ROLL" },
    { fieldName: "hostReadMidRollCPM", label: "MID-ROLL" },
    { fieldName: "hostReadPostRollCPM", label: "POST-ROLL" },
  ];

  return (
    <section>
      {hosts && (
        <AboutSection name="Hosts">
          {hosts.map((host, i) => (
            <span key={i}>
              {host.fullName?.firstName} {host.fullName?.lastName}
            </span>
          ))}
        </AboutSection>
      )}

      <AboutSection name="Show Information">
        <Row gutter={12}>
          <Col xs={24} md={show.isRemote ? 12 : 8}>
            <HighlightBox
              className="bordered"
              title="Episode Frequency"
              value={show.estimatedEpisodeFrequency}
            />
          </Col>
          <Col xs={24} md={show.isRemote ? 12 : 8}>
            <HighlightBox
              className="bordered"
              title="Avg. Episode Downloads"
              value={hummanizeAudienceSize(show.averageEpisodeDownloads)}
            />
          </Col>
          {!show.isRemote && (
            <Col xs={24} md={8}>
              <HighlightBox
                className="bordered"
                title="Avg. Episode Length"
                value={averageEpisodeDuration}
              />
            </Col>
          )}
        </Row>

        <Row className="m-txs" gutter={12}>
          <Col xs={12}>
            <HighlightBox
              className="bordered"
              title={"Weekly Downloads"}
              value={hummanizeAudienceSize(show.estimatedWeeklyDownloads)}
            />
          </Col>
          <Col xs={12}>
            <HighlightBox
              className="bordered"
              title={"Last Episode"}
              value={
                latestEpisode ? dayjs(latestEpisode.publishedAt * 1000).fromNow() : <Loading />
              }
            />
          </Col>
        </Row>
      </AboutSection>

      {showAdvertisingInfo && (
        <AboutSection name="Advertising Information">
          {show.programmaticAdSettings?.midRollEnabled && (
            <span>
              <CheckCircleFilled className="color-primary m-rxxs" />{" "}
              <strong>Programmatic Ads</strong>
            </span>
          )}
          {show.advertisementSettings.isHostReadEnabled && (
            <div className="flex-column-container">
              <span>
                <CheckCircleFilled className="color-primary m-rxxs" />{" "}
                <strong>Host Read Ads</strong>
              </span>
              <span className="m-txs flex-row-container align-center">
                <strong>CPM</strong>
                <InfoTooltip helpText="CPM Rates (price per 1,000 downloads) are used for All Episodes campaigns that play across a show's entire catalog." />
              </span>
              <Row gutter={12}>
                {hostReadCPMS.map((cpm) => (
                  <Col xs={24} md={8} key={cpm.fieldName}>
                    <HighlightBox
                      className="bordered"
                      title={cpm.label}
                      value={formatMoney(
                        parseInt(
                          show.advertisementSettings[
                            cpm.fieldName as keyof typeof show.advertisementSettings
                          ] as string,
                          10
                        )
                      )}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </AboutSection>
      )}

      <SocialMedia show={show} />
      <SampleAdReads show={show} />
    </section>
  );
}

const AboutSection = ({ name, children }: { name: string; children: React.ReactNode }) => (
  <div className="m-bl flex-column-container">
    <h3 className="m-b0">{name}</h3>
    <Divider className="m-txxs m-bxs" />
    {children}
  </div>
);

const SocialMedia = ({ show }: { show: IShow }) => {
  const dispatch = useReduxDispatch();
  const channelsByShow = useSelectorTS((state) => state.youtube.channelsByShow);
  const channels = channelsByShow[show.uuid];
  const channel = channels?.[0];

  useEffect(() => {
    if (show) dispatch(getYoutubeForShow(show.uuid));
  }, [show]);

  const advertisementSettings = show?.advertisementSettings ?? {};
  const supportedSocialMedia = [
    { fieldName: "twitterLink", logo: twitter, label: "Twitter" },
    { fieldName: "instagramLink", logo: instagram, label: "Instagram" },
    { fieldName: "facebookLink", logo: facebook, label: "Facebook" },
    { fieldName: "youtubeLink", logo: youtube, label: "YouTube" },
    { fieldName: "tikTokLink", logo: tikTok, label: "TikTok" },
  ].filter(
    ({ fieldName }) => advertisementSettings[fieldName as keyof typeof advertisementSettings]
  );

  if (!supportedSocialMedia.length && !channel) return null;
  const hasUserProvidedYoutubeLink = supportedSocialMedia.find(
    ({ fieldName }) => fieldName === "youtubeLink"
  );

  return (
    <AboutSection name="Social Media">
      <Row gutter={12}>
        {supportedSocialMedia.map(({ fieldName, logo, label }) => {
          return (
            <Col xs={12} key={fieldName} className="m-bxs">
              <ExternalLink
                className={"no-style-link"}
                href={
                  advertisementSettings[fieldName as keyof typeof advertisementSettings] as any
                }>
                <div className="flex-row-container align-center">
                  <img src={logo} alt="logo" width={68} height={68} />
                  <strong className="m-lxs">{label}</strong>
                </div>
              </ExternalLink>
            </Col>
          );
        })}
        {channel && !hasUserProvidedYoutubeLink && (
          <Col xs={12} className="m-bxs">
            <ExternalLink
              className={"no-style-link"}
              href={`https://www.youtube.com/channel/${channel.id}`}>
              <div className="flex-row-container align-center">
                <img src={youtube} alt="logo" width={68} height={68} />
                <strong className="m-lxs">YouTube</strong>
              </div>
            </ExternalLink>
          </Col>
        )}
      </Row>
    </AboutSection>
  );
};

const SampleAdReads = ({ show }: { show: IShow }) => {
  const dispatch = useReduxDispatch();

  const sampleAdReads = useSelectorTS((state) => state.publicShows.adReadsByShowUUID?.[show.uuid]);
  const filteredAdReads = useMemo(() => {
    if (!sampleAdReads || !sampleAdReads.length) return [];

    // bucket reads by productname
    const sampleAdReadsByProductName: { [key: string]: AdRead[] } = {};
    sampleAdReads.forEach((adRead) => {
      if (!sampleAdReadsByProductName[adRead.productName]) {
        sampleAdReadsByProductName[adRead.productName] = [];
      }
      sampleAdReadsByProductName[adRead.productName].push(adRead);
    });

    // find three most common ad reads
    const mostCommonAdReads = Object.keys(sampleAdReadsByProductName)
      .sort((a, b) => sampleAdReadsByProductName[b].length - sampleAdReadsByProductName[a].length)
      .slice(0, 3)
      .map((productName) => sampleAdReadsByProductName[productName][0]);

    return mostCommonAdReads;
  }, [sampleAdReads]);

  if (!sampleAdReads?.length) return null;

  const getMenuItems = (file: any, disabled: boolean) => ({
    "Copy Audio File URL": {
      onSelect: () => {
        new ClipboardAction({ text: file.convertedURL, container: document.body });
        dispatch(showSuccess("URL Copied to Clipboard"));
      },
      disabled,
    },
  });

  return (
    <AboutSection name="Example Ad Reads">
      {filteredAdReads.map((adRead: AdRead, i: number) => {
        const isDisabled = adRead.style === CampaignStyleCampaignAudio;
        let endsAtText = `COMPLETED ON: ${dayjs.unix(adRead.completedAt).format("ll")}`;
        let exclusiveTag = null;
        if (adRead.state === CampaignItemStateRunning) {
          exclusiveTag = (
            <div style={{ flexBasis: "50px" }} className={""}>
              <ExclusiveTag>Live</ExclusiveTag>
            </div>
          );
          endsAtText = `EST END DATE: ${dayjs.unix(adRead.estimatedEndsAt).format("ll")}`;
        }
        return (
          <div
            key={i}
            className={"p-vxxs flex-row-container align-center"}
            style={{ borderBottom: "1px solid rgba(black, .1)" }}>
            <div>
              <TwoLineCell title={adRead.campaignName} subtitle={adRead.productName} />
            </div>
            <div className="flex-1" />
            {exclusiveTag}
            <div className={"small bold m-rxs"} style={{ width: 160, textAlign: "right" }}>
              {endsAtText}
            </div>
            <div className="past-campaigns-media-player-wrapper">
              <MediaPlayer
                mediaFileUUID={adRead.mediaFileUUID}
                disabled={isDisabled}
                infoBitText={isDisabled && "PRE-RECORDED AD"}
                menuItems={(file: any) => getMenuItems(file, isDisabled)}
                mini
              />
            </div>
          </div>
        );
      })}
    </AboutSection>
  );
};
