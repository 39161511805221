import QuestionIcon from "@iconify/icons-mdi/question-mark-circle";
import { Select, Table, Tooltip } from "antd";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import take from "lodash/take";
import { Moment } from "moment-timezone";
import numeral from "numeral";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import BlockFeature from "src/components/lib/block_feature";
import InfoTooltip from "src/components/lib/info";
import { showError, showInfo } from "../../../../actions/app";
import { DownloadStatsActionManager } from "../../../../action_managers/stats";
import { countryCodesMap } from "../../../../data/countries";
import countryDataToContinent from "../../../../data/countryDataToContinent.json";
import { useReduxDispatch, useSelectorTS } from "../../../../hooks/redux-ts";
import BlockImgSrc from "../../../../images/Block_Geo_Performance.png";
import { goToPricingPage } from "../../../../lib/config";
import { shouldShowTierPerm } from "../../../../lib/feature_flag";
import { downloadFile } from "../../../lib/download_file";
import AnalyticsMapBox from "../../../lib/mapbox/analytics-mapbox";
import UpgradeTag from "../../../lib/upgrade-tag";
import {
  alignment,
  ALL_EPISODES,
  ALL_PODCASTS,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  equalityFunc,
  GeoLocationPerformanceRequestPrefix,
  getEpisodeTitleSuffix,
  getListOfEpisodes,
  getRequestHash,
  middleEllipses,
  paginationConfig,
  RequestResponseDataPoint,
  StatsRequestFilter,
  useTierAnalyticsPerm,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";

// Utility Functions and types
const tableDropdownValues = ["City", "Region", "Country", "Continent"] as const;
interface TableDataPoint {
  key: string;
  name: string;
  downloads: number;
  percent: number;
}

const capitalizeWords = (words: string) =>
  words
    ?.split(" ")
    ?.map((word) => capitalize(word.trim()))
    ?.join(" ");

const subRegionToContinent = {
  "Australia and New Zealand": "Australia",
  Caribbean: "North America",
  "Central America": "North America",
  "Central Asia": "Asia",
  "Eastern Africa": "Africa",
  "Eastern Asia": "Asia",
  "Eastern Europe": "Europe",
  Melanesia: "Australia",
  Micronesia: "Australia",
  "Middle Africa": "Africa",
  "Northern Africa": "Africa",
  "Northern America": "North America",
  "Northern Europe": "Europe",
  Polynesia: "Australia",
  "South America": "South America",
  "South-Eastern Asia": "Asia",
  "Southern Africa": "Africa",
  "Southern Asia": "Asia",
  "Southern Europe": "Europe",
  "Western Africa": "Africa",
  "Western Asia": "Asia",
  "Western Europe": "Europe",
};

// transforms data based on dropdown value
const aggregateData: (
  data: RequestResponseDataPoint["json"],
  dropdownVal: (typeof tableDropdownValues)[number]
) => TableDataPoint[] = (data, dropdownVal) => {
  let total = 0;

  if (isEmpty(data)) {
    return [];
  }

  const mappedValues = data.reduce<Record<string, number>>((accu, curr) => {
    let [, bucketVal] = curr?.pathValues;

    if (dropdownVal === tableDropdownValues[3]) {
      const subregion =
        countryDataToContinent[bucketVal as keyof typeof countryDataToContinent]?.subregion;
      bucketVal = subRegionToContinent[subregion as keyof typeof subRegionToContinent];
    }

    if (bucketVal?.length > 0) {
      accu[bucketVal] =
        typeof accu[bucketVal] === "number" ? accu[bucketVal] + curr?.count : curr?.count;
      total += curr?.count;
    } else {
      // Unknowns, not counting them
    }

    return accu;
  }, {});

  const result = Object.keys(mappedValues).map((bucketVal) => {
    let name;

    switch (dropdownVal) {
      case tableDropdownValues[0]:
      case tableDropdownValues[1]:
      case tableDropdownValues[3]:
        name = capitalizeWords(bucketVal);
        break;
      case tableDropdownValues[2]:
        name = capitalizeWords(countryCodesMap[bucketVal as keyof typeof countryCodesMap]);
        break;
      default:
        name = capitalizeWords(countryCodesMap[bucketVal as keyof typeof countryCodesMap]);
        break;
    }

    return {
      key: bucketVal,
      name,
      downloads: mappedValues[bucketVal],
      percent: parseFloat(((mappedValues[bucketVal] / total) * 100).toFixed(2)),
    };
  });

  return result;
};

interface IGeoLocationPerformance {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
}

const GeoLocationPerformance: FunctionComponent<IGeoLocationPerformance> = ({
  dateRange,
  timeZone,
  selectedShow,
}) => {
  const dispatch = useReduxDispatch();
  const user = useSelectorTS((state) => state?.user?.user);
  const tierPermission = useTierAnalyticsPerm();
  const episodesByShow = useSelectorTS((state) => state?.episodesByShow, shallowEqual);

  const listOfEpisodes = getListOfEpisodes(selectedShow, episodesByShow);

  const [selectedEpisode, setSelectedEpisode] = useState<string>(ALL_EPISODES);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [dropdonwValue, setDropdownValue] = useState<(typeof tableDropdownValues)[number]>(
    tableDropdownValues[2]
  );

  const cacheID = getRequestHash(
    `${GeoLocationPerformanceRequestPrefix}-${dropdonwValue}-${selectedEpisode}`,
    selectedShow,
    dateRange
  );

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  let tableData = aggregateData(statsCacheData, dropdonwValue);
  tableData = isDataLoading
    ? []
    : orderBy(
        tableData.filter((item) => item.downloads > 0),
        ["downloads"],
        ["desc"]
      );

  const dataProvidedToTable = take(tableData, 50);

  const columns = useMemo(() => {
    return [
      {
        title: dropdonwValue,
        dataIndex: "name",
        className: "analyticsPage-table-cell",
        align: alignment[0],
        sorter: (a: TableDataPoint, b: TableDataPoint) =>
          typeof a?.name?.localeCompare(b?.name) === "number" ? a?.name?.localeCompare(b?.name) : 0,
      },
      {
        title: "Downloads",
        dataIndex: "downloads",
        className: "analyticsPage-table-cell",
        align: alignment[2],
        render: (num: number) => numeral(num).format("0,0"),
        sorter: (a: TableDataPoint, b: TableDataPoint) => a.downloads - b.downloads,
      },
      {
        title: "% of Total",
        dataIndex: "percent",
        className: "analyticsPage-table-cell",
        align: alignment[2],
        render: (percent: string) => {
          const number = parseFloat(percent);
          if (number < 1) {
            return `< 1%`;
          }
          return `${percent}%`;
        },
        sorter: (a: TableDataPoint, b: TableDataPoint) => a.percent - b.percent,
      },
    ];
  }, [dropdonwValue]);

  useEffect(() => {
    let id: any;

    if (isEmpty(statsCacheData)) {
      setIsDataLoading(true);
      id = setTimeout(() => {
        let bucketTerms;

        switch (dropdonwValue) {
          case tableDropdownValues[0]:
            bucketTerms = "download.metadata.city";
            break;
          case tableDropdownValues[1]:
            bucketTerms = "download.metadata.state";
            break;
          case tableDropdownValues[2]:
          case tableDropdownValues[3]:
            bucketTerms = "download.metadata.country";

            break;
          default:
            bucketTerms = "download.metadata.country";
            break;
        }

        const filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
          timezone: timeZone,
          bucketTerms,
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters.showUUID = selectedShow;
        }

        if (typeof selectedEpisode === "string" && selectedEpisode !== ALL_EPISODES) {
          filters.episodeUUID = selectedEpisode;
        }

        dispatch(
          new DownloadStatsActionManager({
            filters,
            user,
            requestID: cacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsDataLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsDataLoading(false);
          });
      }, 200);
    } else {
      setIsDataLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
    selectedEpisode,
    dropdonwValue,
  ]);

  useEffect(() => {
    setSelectedEpisode(ALL_EPISODES);
  }, [selectedShow]);

  // Handlers
  const handleTableFilter = (newDropdownValue: (typeof tableDropdownValues)[number]) => {
    setDropdownValue(newDropdownValue);
  };

  const handleEpisodeChange = (episode: string) => setSelectedEpisode(episode);

  const handleReportDownload = () => {
    if (!isDataLoading) {
      const episodeTitle = getEpisodeTitleSuffix(selectedShow, selectedEpisode, episodesByShow);

      const csv = Papa.unparse(
        tableData.map((point) => {
          return {
            [dropdonwValue?.toUpperCase()]: point.name,
            downloads: point.downloads,
            Percent_of_Total: point.percent,
          };
        })
      );
      downloadFile(
        `GeolocationPerformanceReport_${dateRange?.[0]?.format(
          "YYYY_MM_DD"
        )}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}${episodeTitle}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  const showMessage = !isDataLoading && dataProvidedToTable?.length === 0;

  // Tier Permission

  const preTierLaunchOptions = [
    {
      value: tableDropdownValues[0],
      enable: true,
    },
    { value: tableDropdownValues[2], enable: true },
  ];

  const filteredOptions = shouldShowTierPerm()
    ? tableDropdownValues.map((value) => {
        return {
          value,
          enable:
            value === tableDropdownValues[0] ||
            value === tableDropdownValues[2] ||
            tierPermission.Downloads.GeolocationPerformance.allOptions,
        };
      })
    : preTierLaunchOptions;

  const downloadSettings = tierPermission.Downloads.GeolocationPerformance.Widget
    ? {
        allow: tierPermission.General.ExportDataWidgetLevel,
        text: "Geolocation Performance Report",
        hanlder: handleReportDownload,
      }
    : undefined;

  return (
    <InfoCard download={downloadSettings}>
      <BlockFeature
        block={!tierPermission.Downloads.GeolocationPerformance.Widget}
        CTA={{
          text: (
            <span className="h3 default-font m-b0 p-hxs text-center">
              <strong>Geolocation Performance</strong> available starting from Growth Plan.
            </span>
          ),
          btn: {
            text: "Upgrade Your Plan",
            handler: () => goToPricingPage(),
          },
        }}
        blockByImg={{
          src: BlockImgSrc,
          minHeight: "500px",
        }}>
        <div className="analyticsPage-GeolocationPerformance">
          <div className="analyticsPage-GeolocationPerformance--title">
            <h3 className="m-v0 flex-row-container align-center">
              Geolocation Performance
              <InfoTooltip
                direction="top"
                helpText="Where your downloads are coming from. Only counts downloads that we have geolocation data for."
                baseIcon={QuestionIcon}
                height={20}
                style={{
                  color: "#C6C6C6",
                  bottom: ".125em",
                  position: "relative",
                  cursor: "pointer",
                }}
              />
            </h3>
          </div>
          <div className="analyticsPage-GeolocationPerformance--episodes">
            <Tooltip
              title="Select a podcast to filter by episode"
              trigger={selectedShow !== ALL_PODCASTS ? [] : ["hover"]}>
              <Select
                size="small"
                className="RC-Antd-Override-Dropdown"
                value={selectedEpisode}
                onSelect={handleEpisodeChange}
                disabled={selectedShow === ALL_PODCASTS}
                virtual={false}
                dropdownMatchSelectWidth={false}
                dropdownAlign={{
                  points: ["tr", "br"],
                  overflow: { adjustX: true, adjustY: true },
                }}
                dropdownStyle={{ maxWidth: "320px" }}
                style={{ width: "150px" }}>
                {listOfEpisodes.map(({ uuid, title }) => {
                  return (
                    <Select.Option
                      className="RC-Antd-Override-Dropdown-Option"
                      key={uuid}
                      value={uuid}>
                      {middleEllipses(title, 35, 8)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Tooltip>
          </div>
          <div className="analyticsPage-GeolocationPerformance--dropdown">
            <Select
              size="small"
              className="RC-Antd-Override-Dropdown"
              value={dropdonwValue}
              onSelect={handleTableFilter}
              dropdownMatchSelectWidth={
                tierPermission?.Downloads?.GeolocationPerformance?.allOptions || 200
              }
              style={{ minWidth: "100px" }}>
              {filteredOptions.map((option) => {
                return (
                  <Select.Option key={option.value} value={option.value} disabled={!option.enable}>
                    {option.enable ? (
                      option.value
                    ) : (
                      <div>
                        {option.value} <UpgradeTag />
                      </div>
                    )}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <Table
            className="analyticsPage-GeolocationPerformance--table analyticsPage-table"
            loading={isDataLoading}
            dataSource={dataProvidedToTable}
            columns={columns}
            scroll={{ x: true }}
            pagination={paginationConfig}
          />

          <AnalyticsMapBox
            className="analyticsPage-GeolocationPerformance--mapbox"
            minHeight={496}
            dateRange={dateRange}
            selectedShow={selectedShow}
            selectedEpisode={selectedEpisode}
            showMessage={showMessage}
          />
        </div>
      </BlockFeature>
    </InfoCard>
  );
};

const MemoizedDownLoadSection = React.memo(GeoLocationPerformance, equalityFunc);

export default MemoizedDownLoadSection;
