import bxLinkExternal from "@iconify/icons-bx/bx-link-external";
import { AsYouType } from "libphonenumber-js";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import React, { useEffect } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, Fields, getFormValues, reduxForm, submit } from "redux-form";
import { showWarning } from "../../actions/app";
import { hideModal } from "../../actions/modal";
import { AccountUpdateActionManager } from "../../action_managers/account";
import moneyIcon from "../../icons/money.png";
import FormElement from "../forms/form_element";
import { PhoneNumberInputComponent } from "../forms/phone_input/phone_input";
import Divider from "../lib/divider";
import { Icon } from "../lib/icon";
import InfoTooltip from "../lib/info";
import BaseModalV2 from "./base_modal_v2";

export const additionalContactFormName = "additionalContact";

export let PodcasterAdditionalContactForm = ({ handleSubmit, user }) => {
  const dispatch = useDispatch();
  const validationErrors = useSelector((state) => state.updateAccount.validationErrors);

  // show banner if validation fails
  useEffect(() => {
    if (validationErrors && validationErrors.length > 0) {
      dispatch(showWarning(validationErrors[0]?.errorMessage));
    }
  }, [validationErrors]);

  return (
    <form onSubmit={handleSubmit}>
      <h4>
        SMS{" "}
        <InfoTooltip
          helpText={
            "All phone numbers below will receive SMS notifications for all shows on this account."
          }
        />
      </h4>
      {user.phoneNumber && user.phoneNumberCountryCode && (
        <>
          <div className="redcircle-form-label c-black-100 text-uppercase">
            Account Holder Phone Number
          </div>
          <div className={"c-black-40"}>
            {new AsYouType(user.phoneNumberCountryCode).input(user.phoneNumber)}
            <InfoTooltip
              helpText={
                "You cannot edit this phone number because it is associated with your account"
              }
            />
          </div>
        </>
      )}
      {(!user.phoneNumber || !user.phoneNumberCountryCode) && (
        <Fields
          names={[`phoneNumber`, `phoneNumberCountryCode`]}
          component={FormElement}
          InputComponent={PhoneNumberInputComponent}
          label="Account Holder Phone Number"
          type="text"
        />
      )}

      <Divider marginBottom={16} />
      <FieldArray name={"ccPhoneNumbers"} component={PhoneNumberFields} />
      <Divider />
      <h4>
        Email{" "}
        <InfoTooltip
          helpText={
            "All email addresses below will receive email notifications for all shows on this account."
          }
        />
      </h4>
      <div className="redcircle-form-label c-black-100 text-uppercase">Account Holder Email</div>
      <div className={"c-black-50"}>
        {user.email}
        <InfoTooltip
          helpText={"You cannot edit this email because it is associated with your account"}
        />
      </div>
      <Divider marginBottom={16} />
      <FieldArray name={"ccEmails"} component={EmailFields} />
    </form>
  );
};

PodcasterAdditionalContactForm = connect((state) => ({
  user: state.user.user,
  initialValues: {
    ...state.user.user,
    ccPhoneNumbers: state.user.user.ccPhoneNumbers?.length ? state.user.user.ccPhoneNumbers : [{}],
    phoneNumberCountryCode: state.user.user.phoneNumberCountryCode?.length
      ? state.user.user.phoneNumberCountryCode
      : "US",

    ccEmails: state.user.user.ccEmails?.length ? state.user.user.ccEmails : [""],
  },
}))(
  reduxForm({
    form: additionalContactFormName,
    onSubmit: (data, dispatch, { user }) => {
      const filteredData = pick(data, [
        "ccEmails",
        "ccPhoneNumbers",
        "phoneNumber",
        "phoneNumberCountryCode",
      ]);

      filteredData.ccPhoneNumbers = filteredData.ccPhoneNumbers?.filter((a) => !isEmpty(a));
      filteredData.ccEmails = filteredData.ccEmails?.filter((a) => !isEmpty(a));

      dispatch(
        new AccountUpdateActionManager({
          userForm: filteredData,
          existingUser: user,
        }).run()
      ).then((resp) => resp.status === 200 && dispatch(hideModal()));
    },
  })(PodcasterAdditionalContactForm)
);

const PodcasterAdditionalContactModal = ({
  hideModal,
  shouldDisableSave,
  submitForm,
  initialValues,
}) => {
  const modalContent = (
    <Grid fluid>
      <Row>
        <Col xs={10} xsOffset={1}>
          <a
            href="https://redcircle-public.s3.us-east-2.amazonaws.com/monetization-whitepaper.pdf"
            target={"_blank"}
            rel="noreferrer"
            className={"bold"}>
            Guide to Podcast Monetization <Icon icon={bxLinkExternal} />
          </a>
          <h3>We will SMS and email you when you receive ad offers.</h3>
          <br />
          <PodcasterAdditionalContactForm initialValues={initialValues} />
        </Col>
      </Row>
    </Grid>
  );
  return (
    <BaseModalV2
      hideCloseButton={true}
      hideMiniTitleDivider={false}
      showContent={true}
      isMini={true}
      close={hideModal}
      title={
        <span>
          One last thing... <img src={moneyIcon} alt="money icon" />
        </span>
      }
      Content={modalContent}
      Footer={
        <Grid fluid>
          <Row>
            <Col xs={10} xsOffset={1}>
              <button
                className="m-ts btn btn-primary width-100"
                disabled={shouldDisableSave}
                onClick={submitForm}>
                Save
              </button>
            </Col>
          </Row>
        </Grid>
      }
    />
  );
};

const PhoneNumberFields = ({ fields }) => {
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={field}>
            <Fields
              key={field}
              names={[`${field}.phoneNumber`, `${field}.phoneNumberCountryCode`]}
              accessor={(props) => get(props, field)}
              component={FormElement}
              InputComponent={PhoneNumberInputComponent}
              label="Phone Number (Optional)"
            />
            <Icon
              iconName="close"
              style={{
                position: "absolute",
                right: 0,
                marginTop: "-48px",
                marginRight: "-20px",
                cursor: "pointer",
              }}
              onClick={() => fields.remove(index)}
            />
          </React.Fragment>
        );
      })}
      <div className={"text-end"}>
        <Button bsStyle="link" onClick={() => fields.push({})}>
          + Add Phone Number
        </Button>
      </div>
    </div>
  );
};

const EmailFields = ({ fields }) => {
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={field}>
            <Field
              key={field}
              name={field}
              component={FormElement}
              label="Email (Optional)"
              type="email"
            />
            <Icon
              iconName="close"
              style={{
                position: "absolute",
                right: 0,
                marginTop: "-48px",
                marginRight: "-20px",
                cursor: "pointer",
              }}
              onClick={() => fields.remove(index)}
            />
          </React.Fragment>
        );
      })}
      <div className={"text-end"}>
        <Button bsStyle="link" onClick={() => fields.push("")}>
          + Add Email Address
        </Button>
      </div>
    </div>
  );
};
export default connect(
  (state) => {
    const initialValues = {
      ccPhoneNumbers: [{}],
      ...state.user.user,
      ccEmails: state.user.user.ccEmails?.length ? state.user.user.ccEmails : [""],
    };
    const values = getFormValues(additionalContactFormName)(state);
    return {
      shouldDisableSave: !state?.user?.user?.phoneNumber && !values?.phoneNumber,
      initialValues,
    };
  },
  { hideModal, submitForm: () => submit(additionalContactFormName) }
)(PodcasterAdditionalContactModal);
