import { Checkbox, Divider, Form, Radio } from "antd";
import WarningPanelv2 from "src/components/lib/warning_panel/warning_panel_v2";
import { pacingToFriendly } from "src/constants/campaigns";
import { FrequencyCapConfig } from "src/reducers/campaigns/types";
import { FrequencyCapOptions } from "./frequency_cap";
import { useEffect } from "react";
import { COLORS } from "redcircle-ui";

export default function CampaignFormDistributionReach() {
  const form = Form.useFormInstance();
  const pacing = Form.useWatch("pacing", form);
  const frequencyConfigs = Form.useWatch("frequencyConfigs", form);
  const selectedStyle = { borderLeft: `1px solid ${COLORS.COLOR_PRIMARY}`, borderLeftWidth: "8px" };

  const handleFrequencyCapChange = (newFrequencyConfigs: FrequencyCapConfig[]) => {
    form.setFieldValue("frequencyConfigs", newFrequencyConfigs);
  };

  /**
   * Ensure hard dates flag is off when pacing is off
   */
  useEffect(() => {
    const hardEndDate = form.getFieldValue("hardEndDate");
    if (!pacing && hardEndDate) {
      form.setFieldValue("hardEndDate", false);
    }
  }, [pacing]);

  return (
    <div className="flex-column-container">
      <Form.Item name="pacing" className="m-b0">
        <Radio.Group className="flex-column-container">
          <WarningPanelv2
            className="m-bxs"
            outline={false}
            withShadow
            style={!pacing ? selectedStyle : undefined}>
            <Radio value={false}>
              <div className="m-lxs">
                <h3>{pacingToFriendly["false"]}</h3>
                <div>Ads for your campaign will be inserted as quickly as possible.</div>
              </div>
            </Radio>
          </WarningPanelv2>

          <WarningPanelv2 outline={false} withShadow style={pacing ? selectedStyle : undefined}>
            <Radio value={true}>
              <div className="m-lxs">
                <h3>{pacingToFriendly["true"]}</h3>
                <div>
                  Ads for your campaign will be inserted evenly across your campaign timeline.
                </div>
              </div>
            </Radio>
          </WarningPanelv2>
        </Radio.Group>
      </Form.Item>
      <Divider className="m-vxs" />
      <Form.Item name="frequencyConfigs" className="m-a0">
        <FrequencyCapOptions
          frequencyConfigs={frequencyConfigs}
          onFrequencyCapChange={handleFrequencyCapChange}
        />
      </Form.Item>
      {pacing && (
        <>
          <Divider className="m-vxs" />
          <label className="redcircle-form-label" htmlFor="hardEndDate">
            Campaign Deadlines
          </label>
          <Form.Item name="hardEndDate" className="m-a0" valuePropName="checked">
            <Checkbox>Set campaign end dates as hard deadlines</Checkbox>
          </Form.Item>
        </>
      )}

      <Divider className="m-vxs" />
      <label className="redcircle-form-label" htmlFor="recentEpisodesOnly">
        Limit to recent episodes?
      </label>
      <Form.Item name="recentEpisodesOnly" className="m-a0" valuePropName="checked">
        <Checkbox>Recent Episodes Only</Checkbox>
      </Form.Item>

      <Divider className="m-vxs" />
      <label className="redcircle-form-label" htmlFor="targetingOptions">
        Where in an episode would you like this audio placed?*
      </label>
      <Form.Item
        name="targetingOptionsArray"
        rules={[{ required: true, message: "Please select a targeting position" }]}>
        <Checkbox.Group>
          <Checkbox value={"preRoll"}>Pre-Roll</Checkbox>
          <Checkbox value={"midRoll"}>Mid-Roll</Checkbox>
          <Checkbox value={"postRoll"}>Post-Roll</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </div>
  );
}
