import isEmpty from "lodash/isEmpty";
import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import {
  CreditCardPaymentMethod,
  DistributionTypeDiscrete,
  InvoicingPaymentMethod,
} from "../../../constants/campaigns";
import RadioSelectFrame from "../../lib/radio-select-frame/radio-select-frame";

/* showPaymentType returns bool

   Only show payment selection screen on first shopping cart and for advertisers
   that have the option to select invoicing.
 */
export const showPaymentType = (invoicingEnabled, currentPaymentMethod, campaign) => {
  if (!isEmpty(currentPaymentMethod)) {
    return false;
  }
  if (campaign?.distributionType === DistributionTypeDiscrete) {
    return false;
  } // discrete campaigns must be invoicing
  return invoicingEnabled;
};

export const ChoosePaymentType = (props) => {
  const { paymentMethodType, setPaymentMethodType } = props;
  return (
    <Grid fluid>
      <Row>
        <Col xs={12} md={6} mdOffset={3}>
          Select how you’d like to pay. Whichever payment type you choose will be used for the
          entirety of the campaign.
          <RadioSelectFrame
            isSelected={paymentMethodType === CreditCardPaymentMethod}
            onClick={() => setPaymentMethodType(CreditCardPaymentMethod)}
            title="Pay By Credit Card"
            description="You’ll be charged incrementally as the campaign runs, up to your maximum budget amount."
          />
          <RadioSelectFrame
            isSelected={paymentMethodType === InvoicingPaymentMethod}
            onClick={() => setPaymentMethodType(InvoicingPaymentMethod)}
            title="Pay By Invoice"
            description="You’ll be invoiced incrementally by RedCircle, based on the number of insertions over that invoice period."
          />
        </Col>
      </Row>
    </Grid>
  );
};
