import { Dispatch } from "redux";
import DefaultActionManager from "../action_managers/default_action_manager";
import UserStore, { saveUserRole } from "../lib/user_store";
import { User, UserRole } from "src/reducers/user";
import { GetStateFunc, HTTPResponses } from "src/action_managers/base";
import { ReduxDispatch } from "src/hooks/redux-ts";
import { RESET_CAMPAIGNS } from "./campaigns";
import { postLoginDataFetch } from "src/action_managers/sign_in";
import { RESET_USER } from "./user";
import { RESET_CAMPAIGN_ITEMS } from "src/action_managers/campaigns";
import { RESET_SHOWS } from "./shows";
import getDefaultRole from "src/lib/user_roles";

export const adminLoginAsActionPrefix = "ADMIN_LOGIN_AS";

interface AdminUserResponse extends User {
  user: User;
  admin: User;
}

class AdminLoginAsActionManager extends DefaultActionManager<AdminUserResponse> {
  preCall(dispatch: ReduxDispatch): void {
    // Reset important redux store slices
    // with redux state data spilling over on account switch
    dispatch({ type: RESET_USER });
    dispatch({ type: RESET_SHOWS });
    dispatch({ type: RESET_CAMPAIGNS });
    dispatch({ type: RESET_CAMPAIGN_ITEMS });

    dispatch(this.defaultPreCallActionCreator()());
  }

  responseOK(dispatch: Dispatch, json: AdminUserResponse) {
    const user = json.user || json;
    const admin = json.admin;
    UserStore.save(user, { admin: true, ...(admin && { adminToken: admin.authToken }) });
    saveUserRole(getDefaultRole(user));
    dispatch(this.defaultSuccessActionCreator()(user));
  }

  postCall(
    dispatch: ReduxDispatch,
    response: HTTPResponses<AdminUserResponse>,
    getState: GetStateFunc
  ): Promise<HTTPResponses<AdminUserResponse>> {
    // If the response did not succeed no point in continuing
    if (response.status !== 200) return Promise.resolve(response);

    const user = response.json.user ?? response.json;

    /**
     * Post admin login need to reset redux state with new user specific data
     */
    return postLoginDataFetch(user).then(() => response);
  }
}

type AdminLoginBody =
  | {
      adminEmail: string;
      adminPassword: string;
      loginAsEmail: string;
    }
  | { adminToken: string; loginAsEmail: string };

export function adminLoginAs(body: AdminLoginBody) {
  return new AdminLoginAsActionManager({
    auth: false,
    route: "users/login-as",
    actionName: adminLoginAsActionPrefix,
    body: body,
    method: "post",
  }).run();
}
