import { getVettingInvitationsForCampaign } from "src/action_managers/vetting";
import { cancelInvitationsInCampaign } from "src/api/vetting";
import { Modal } from "redcircle-ui";
import { useReduxDispatch } from "src/hooks/redux-ts";

interface ICancelVettingInCartModal {
  campaignUUID?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelVettingInCartModal = ({
  campaignUUID = "",
  visible,
  setVisible,
}: ICancelVettingInCartModal) => {
  /**
   * Handlers
   */

  const dispatch = useReduxDispatch();

  const handleClose = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    cancelInvitationsInCampaign({ campaignUUID }).then((resp) => {
      if (resp.status === 204) {
        handleClose();
        // This is needed to reload invitations new expired status, since the api call does not return the new invitations statuses
        return dispatch(getVettingInvitationsForCampaign(campaignUUID));
      }
    });
  };

  return (
    <Modal size="xs" open={visible} onClose={handleClose} onSubmit={handleSubmit}>
      <Modal.Title>Cancel vetting in cart</Modal.Title>
      <Modal.Body>
        <p>
          Are you sure you want to cancel this cart's vetting? This will stop any podcasters from
          responding to the vetting request they've already been sent.
        </p>
        <p>
          You will be able to re-send a vetting request, but multiple vets should be avoided as to
          not overwork podcasters.
        </p>
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Close</Modal.CloseButton>
        <Modal.SubmitButton>Cancel Vetting</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelVettingInCartModal;
