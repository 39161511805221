import { Col, Row } from "antd";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { matchPath, withRouter } from "react-router-dom";
import { getScriptsForCampaign } from "src/action_managers/script";
import LoadingButton from "src/components/forms/loading_button/loading_button";
import { getTotalSpotsFromCampaignItem } from "src/components/pages/campaigns/campaign_page/campaign_page_utils";
import { CampaignSendMessages } from "src/constants/notificationMessages";
import { useSelectorTS } from "src/hooks/redux-ts";
import { isShowExcludingCampaign, isShowRedirected } from "src/lib/show";
import { validateCampaign } from "src/lib/validate-campaign";
import { showWarning } from "../../../actions/app";
import {
  assignScripts,
  campaignOneWeek,
  editCampaign,
  getCampaign,
  sendCampaign,
  updateCampaignItems,
} from "../../../action_managers/campaigns";
import { fetchPublicShowsByUUIDs } from "../../../action_managers/public_show";
import { getMyCards } from "../../../action_managers/subscriptions";
import {
  CampaignItemStateDraft,
  CampaignStateCreated,
  CreditCardPaymentMethod,
  DistributionTypeDiscrete,
  InvoicingPaymentMethod,
} from "../../../constants/campaigns";
import { useGetCampaignAndShows } from "../../../hooks/campaigns";
import { campaignIsDiscrete, isScriptRequired } from "../../../lib/campaigns";
import { useHttpDispatch } from "../../../lib/http";
import { UserContext } from "../../app";
import { oneLevelUp } from "../../lib/routing";
import { options } from "../../lib/stripe_form";
import { getSpotRateFromShow } from "../../lib/tables/campaign_show_list_table";
import {
  AddScript,
  addScriptValidate,
  createOrUpdateScript,
  getAddScriptInitialState,
} from "../add_script_modal/add_script_modal";
import { SummaryPage } from "../campaign_editor/summary_page";
import { MultiStepModal } from "../multi_step_modals/multi_step_modal";
import { CampaignIncompletePage } from "./campaign_incomplete_page";
import "./campaign_podcast_schedule.scss";
import { updateTimeline } from "./campaign_schedule_podcast_utils";
import { ChoosePaymentType, showPaymentType } from "./choose_payment_type";
import { getContinuousPage } from "./continuous_scheduler_page";
import { getPaymentConfirmationPage } from "./payment_confirmation_page";
import { getPaymentPage } from "./payment_page";
import SuccessPage from "./success_page";
import { AssignScript, calcInitialValues, calculateReqBody } from "./assign_script";

// helper functions
const shouldRemoveDraftItem = (show, campaign) =>
  !show?.advertisementSettings?.isHostReadEnabled ||
  isShowRedirected(show) ||
  isShowExcludingCampaign(show, campaign);

const CampaignSchedulePodcast = (props) => {
  const { campaigns } = props;

  const dispatch = useDispatch();
  const httpDispatch = useHttpDispatch();
  const [page2Ready, setPage2Ready] = useState(false);
  const [oneWeekAmount, updateOneWeekAmount] = useState(0);
  const [budgets, updateBudgets] = useState({});
  const [token, updateToken] = useState();
  const [card, updateCard] = useState();
  const [pageNumber, updatePageNumber] = useState(0);
  const [timeline, setTimeline] = useState();
  const match = matchPath(window.location.pathname, "/campaigns/:campaignUUID");
  const campaignUUID = match?.params?.campaignUUID;
  const stripeFormRef = useRef(null);
  const user = useContext(UserContext);
  const campaign = campaigns?.[campaignUUID];
  const initialPaymentMethod = campaign?.paymentType;
  const invoicingEnabled = user?.invoicingPaymentEnabled ?? false;
  const [paymentMethodType, setPaymentMethodType] = useState(
    invoicingEnabled ? undefined : CreditCardPaymentMethod
  );
  const campaignItems = useSelectorTS((state) =>
    state.campaignItems.campaignItemByCampaignUUID?.[campaign?.uuid]?.map(
      (uuid) => state.campaignItems.campaignItems[uuid]
    )
  );
  const {
    scriptsByCampaignUUID,
    scriptsByUUID,
    isLoading: isScriptsLoading,
  } = useSelectorTS((state) => state?.scripts);
  const scripts = scriptsByCampaignUUID[campaignUUID] || [];

  /**
   * Extra High level state info needed by parent component set by child page component
   */
  const [disableContinuousPage, setDisableContinuousPage] = useState({
    disable: false,
    disableMessage: undefined,
  });

  /**
   * Local State for script Assignments
   */
  const [localScriptAssignments, setLocalScriptAssignments] = useState(null);

  /**
   * Manual flag to disable scripts modals in case script is not
   * required and user manually skips the modal pages. this ensures modal
   * disabled states until user manually changes it
   */
  const [skipScriptPages, setSkipScripPages] = useState(false);

  useEffect(() => {
    updateTimeline(campaign, setTimeline, setTopOfPageWarning, pageNumber);
  }, [campaign?.startsAt]);
  useEffect(() => {
    if (pageNumber !== 0) {
      setTopOfPageWarning();
    }
  }, [pageNumber]);

  useGetCampaignAndShows(props, campaignUUID);
  useEffect(() => {
    campaign?.paymentType && setPaymentMethodType(campaign.paymentType);
  }, [campaign?.paymentType]);
  useEffect(() => {
    dispatch(getMyCards());
  }, [dispatch]);
  const [topOfPageWarning, setTopOfPageWarning] = useState();
  // Discrete Campaigns can only pay via Invoicing
  useEffect(() => {
    if (campaign?.distributionType === DistributionTypeDiscrete) {
      setPaymentMethodType(InvoicingPaymentMethod);
    }
  }, [campaign, setPaymentMethodType]);

  /**
   * throwing an error in this func will get caught in the Modal parent and not allow the user to go forward to the success modal page
   * Doing some error catching in this func to control the message provided to the user but then re-throwing the error
   */
  const onSubmit = async () => {
    try {
      // Do a check that all current draftItems have not had their setting updated and makes them unable to participate in the campaign

      const showUUIDs = draftItems.map(({ showUUID }) => showUUID);
      const showInfoListResp = await props.fetchPublicShowsByUUIDs(showUUIDs);

      if (showInfoListResp.status !== 200)
        throw new Error(CampaignSendMessages.Error.CouldNotSendCampaign); // something went wrong in grabbing current show info on draft items

      const itemsToRemove = showInfoListResp.json?.filter(shouldRemoveDraftItem);

      if (itemsToRemove?.length > 0) {
        await handleRemoveInvalidDraftItems(itemsToRemove, draftItems); // remove any items that are unable to join campaign
        throw new Error();
      }

      const resp = await props.sendCampaign(campaignUUID, card?.id, paymentMethodType);
      if (resp.status === 200) {
        props.getCampaign(campaignUUID);
      } else {
        // Forcing error to stop the user from heading to the next modal page since the campaign update failed
        throw new Error(CampaignSendMessages.Error.CouldNotSendCampaign);
      }
    } catch (err) {
      err?.message && props.showWarning(err.message, 5000);

      // Re-throwing exception so it is caught by Multi-step Modal's catch statement and not allow user to go to next page.
      throw err;
    }
  };

  const onScriptSubmit = async () => {
    try {
      setSkipScripPages(false);
      // Script creation
      await createOrUpdateScript({
        campaignUUID,
        scripts: formState,
        enableSuccessMessage: false,
      });
    } catch (err) {
      props.showWarning("There was an issue updating the script(s) for this campaign", 5000);
    }
  };

  const handleCardSubmit = async (values = {}) => {
    if (!values.card) {
      values = await stripeFormRef.current.submit();
      if (values.error) {
        return values.error;
      }
    }
    let { card, token } = values;
    updateToken(token);
    updateCard(card);
    updatePageNumber(2);
  };

  const draftItems = campaignItems?.filter((item) => item?.state === CampaignItemStateDraft) ?? [];
  const publicShows = useSelectorTS((state) => state.publicShows);
  const totalCost = campaignIsDiscrete(campaign)
    ? draftItems.reduce((agg, item) => {
        return (
          agg +
          getTotalSpotsFromCampaignItem(item) *
            getSpotRateFromShow(
              { ...publicShows[item.showUUID], offerRates: { ...item.offerRates } },
              campaign
            )
        );
      }, 0)
    : Object.keys(budgets).reduce((agg, uuid) => agg + budgets?.[uuid], 0);
  const getSchedulerPage = () => {
    return getContinuousPage({
      budgets,
      updateBudgets,
      timeline,
      setTimeline,
      draftItems,
      campaign,
      totalCost,
      httpDispatch,
      updateOneWeekAmount,
      disableContinuousPage,
      setDisableContinuousPage,
      ...props,
    });
  };

  const [isCampaignComplete, missingFields, fieldNames] = useMemo(() => {
    return validateCampaign(campaign);
  }, [campaign, campaign?.uuid]);

  const missingFieldToPageNumber = {
    name: 0,
    productName: 0,
    "brand.name": 1,
    "brand.domain": 1,
    "brand.description": 1,
    requiresEndorsement: 2,
  };

  const canEditCampaign = campaignItems?.every(
    (campaignItem) => campaignItem?.state === CampaignItemStateDraft
  );

  const handleCampaignEdit = (pageNumber) =>
    props.history.push(`/campaigns/${campaignUUID}/edit/${pageNumber}`);

  const handleClose = () => {
    props.closeModal();
    props.history.push(oneLevelUp(window.location));
  };

  const handleRemoveInvalidDraftItems = (itemsToRemove, draftItems) => {
    if (itemsToRemove?.length > 0) {
      const showToDraftItemMap = draftItems?.reduce((accu, item) => {
        accu[item.showUUID] = item;
        return accu;
      }, {});

      const req = itemsToRemove.reduce((accu, { uuid: showUUID }) => {
        accu[showToDraftItemMap[showUUID]?.uuid] = {
          delete: true,
        };
        return accu;
      }, {});
      const allPodsAreUnavailable = itemsToRemove?.length === draftItems?.length;
      const message = allPodsAreUnavailable
        ? `The podcast(s) you have selected are unfortunately no longer available to be part of this campaign. Please go back and choose other podcasts that are available.`
        : `${
            itemsToRemove?.length > 1
              ? `${itemsToRemove?.length} podcasts have been removed since they are`
              : `${itemsToRemove[0]?.title} has been removed since it is`
          } no longer available to participate in this campaign. Please recheck the campaign schedule information.`;
      return dispatch(updateCampaignItems(campaignUUID, req, message))
        .then(() => {
          allPodsAreUnavailable ? handleClose() : updatePageNumber(0);
        })
        .catch((err) => {
          handleClose();
        });
    }
  };

  // Handle check if draft-Items have become illegible to be in the campaign due to changes in RAP settings or redirected away

  useEffect(() => {
    if (draftItems && publicShows) {
      const itemsToRemove = draftItems
        .map(({ showUUID }) => publicShows?.[showUUID])
        .filter((show) => show && shouldRemoveDraftItem(show, campaign));

      handleRemoveInvalidDraftItems(itemsToRemove, draftItems);
    }
  }, [draftItems?.length, publicShows]);

  // Add Script logic
  const initialState = getAddScriptInitialState({
    campaign,
    scripts,
    type: "editAll",
    initialize: "empty",
  });

  const [formState, setFormState] = useState(initialState);

  const scriptRequired = useMemo(() => {
    return isScriptRequired(campaign);
  }, [campaign?.startsAt]);

  /**
   *  Resets skipScriptPages flag in case user changes the campaign start date
   *  which changes the script requirement flag
   */
  useEffect(() => {
    setSkipScripPages(false);
  }, [scriptRequired]);

  useEffect(() => {
    campaignUUID && dispatch(getScriptsForCampaign(campaignUUID));
  }, [campaignUUID]);

  useEffect(() => {
    if (campaign && scripts)
      setFormState(
        getAddScriptInitialState({ campaign, scripts, type: "editAll", initialize: "empty" })
      );
  }, [scripts?.length, campaign]);

  // ScriptDueDate is only used by Success Page. After payment confirmation page, the campaignItem(s) in draft state are updated
  // to sent state and scriptDueDate is set. Grabbing the scriptDueBy from first campaignItem found with scriptDueBy prop is
  // number. The selector will update once a campaignItem with a scriptDueBy prop is set to a number.
  const scriptDueDate = useSelectorTS((state) => {
    const campaignItemUUIDs = campaignUUID
      ? state?.campaignItems?.campaignItemByCampaignUUID?.[campaignUUID]
      : [];

    return campaignItemUUIDs
      ?.map((campaignItemUUID) => state?.campaignItems?.campaignItems?.[campaignItemUUID])
      ?.find((campaignItem) => typeof campaignItem?.scriptDueBy === "number")?.scriptDueBy;
  });

  // Handlers
  const handleAssignScripts = async () => {
    try {
      const requestBody = calculateReqBody(initialAssignedState, localScriptAssignments);
      await dispatch(assignScripts(campaignUUID, requestBody));
    } catch (err) {
      console.log("error", err);
    }
  };

  const initialAssignedState = calcInitialValues({
    items: draftItems,
    publicShows,
    scriptsByUUID,
  });

  // Initially sync assigned script local state
  useEffect(() => {
    if (localScriptAssignments === null && initialAssignedState !== null) {
      // Only fires once to remove initial state of null
      setLocalScriptAssignments?.(initialAssignedState);
    }
  }, [initialAssignedState, localScriptAssignments]);

  // When scripts are reloaded via create/update recalculate the assignments local state
  useEffect(() => {
    if (!isScriptsLoading) {
      setLocalScriptAssignments?.(initialAssignedState);
    }
  }, [isScriptsLoading]);

  const currentScriptsToBeAdded = formState?.filter((script) => !script.delete);

  const assignedScriptsFromPrevCarts =
    campaignItems?.filter((item) => !!item.scriptUUID)?.map((item) => item.scriptUUID) ?? [];

  const assignedItems = Object.keys(localScriptAssignments ?? {}).filter(
    (campaignItemUUID) => !!scriptsByUUID?.[localScriptAssignments?.[campaignItemUUID]?.scriptUUID]
  );
  const unAssignedItems = draftItems?.filter((item) => !assignedItems.includes(item?.uuid)) ?? [];
  const assignedScripts = Object.values(localScriptAssignments ?? {})
    .map((item) => item.scriptUUID)
    .filter((uuid) => !!scriptsByUUID?.[uuid])
    .concat(assignedScriptsFromPrevCarts);
  const unAssignedScripts =
    scripts?.filter((script) => !assignedScripts.includes(script.uuid)) ?? [];

  /**
   * Hide Assign Scripts modal
   *  - script is not required and no current scripts have been added
   *  - "assign script later" flag has not been manually unset by user.
   */
  const hideAssignScriptsModal =
    (!scriptRequired && currentScriptsToBeAdded.length === 0) || skipScriptPages;

  /**
   * Hide assign scripts check modal
   *  - no unassigned scripts
   *  - script is not required and no current scripts have been added
   *  - "assign script later" flag has not been manually unset by user.
   */
  const hideAssignmentCheckScripts = unAssignedScripts.length === 0 || hideAssignScriptsModal;

  /**
   * Hide assign campaign items check modal
   *  - no unassigned podcasts without a script
   *  - script is not required and no current scripts have been added
   *  - "assign script later" flag has not been manually unset by user.
   */
  const hideAssignmentCheckPodcasts = unAssignedItems.length === 0 || hideAssignScriptsModal;

  const scriptAssignmentPodcastCheckText = `The following podcasts have not been assigned a script. Are you sure you want to continue? ${
    scriptRequired
      ? "Because your campaign is less than 15 days out, a script is required for all podcasts for you to send the campaign."
      : "You will be able to assign a script later."
  }`;

  const pages = [
    getSchedulerPage(),
    {
      title: "Campaign Incomplete",
      subTitle:
        "You need to fill in all of the required fields for your campaign before you can send it to podcasts.",
      Content: <CampaignIncompletePage campaign={campaign} />,
      hide: isCampaignComplete,
      submitText: "Edit Campaign",
      onSubmit: () =>
        props.history.push(
          `/campaigns/${campaign?.uuid}/edit/${missingFieldToPageNumber[fieldNames[0]]}`
        ),
    },
    {
      title: `${campaign?.name}`,
      subTitle:
        "Here’s a summary of your campaign. Make sure everything looks good before sending your campaign.",
      Content: (
        <Row justify="center">
          <Col xs={20} className="p-hxxs">
            <SummaryPage campaign={campaign} updatePage={canEditCampaign && handleCampaignEdit} />
          </Col>
        </Row>
      ),
      hide: !isCampaignComplete,
      fillScreen: true,
      submitText: "Continue",
    },
    {
      title: "Add Script",
      subTitle: "Add the details for your campaign’s script.",
      Content: (
        <Row justify="center">
          <Col xs={20} className="p-hxxs">
            <div className="flex-column-container align-start">
              <div className="width-100">
                <AddScript
                  formState={formState}
                  setFormState={setFormState}
                  title="Script"
                  enableDelete
                  campaignUUID={campaignUUID}
                />
              </div>
            </div>
          </Col>
        </Row>
      ),
      shouldDisable: () => {
        const [isValid, errorValidation] = addScriptValidate(formState);

        return !isValid;
      },
      extraButton: (props) => {
        return scriptRequired ? (
          false
        ) : (
          <LoadingButton
            antdBtnProps={{ type: "link" }}
            onClick={() => {
              if (!skipScriptPages) {
                setSkipScripPages(true);
              }
              props?.nextPage?.(props.currentPageNumber + 4);
            }}>
            Add Script Later
          </LoadingButton>
        );
      },
      onSubmit: onScriptSubmit,
      submitText: "Continue",
      hide: campaign?.state !== CampaignStateCreated,
    },
    {
      title: "Assign Scripts to Podcasts",
      subTitle:
        "Select a script for each podcast. Promo codes will be auto-filled based on your selected script.",
      Content: (
        <Row justify={"center"}>
          <Col xs={20}>
            <AssignScript
              publicShowState={publicShows}
              campaignItems={draftItems}
              scripts={scripts}
              localScriptAssignments={localScriptAssignments}
              setLocalScriptAssignments={setLocalScriptAssignments}
            />
          </Col>
        </Row>
      ),
      submitText: "Continue",
      hide: () => hideAssignScriptsModal,
      onSubmit: handleAssignScripts,
    },
    {
      title: "Script Assignment Check - Scripts",
      Content: (
        <Row justify={"center"}>
          <Col xs={20}>
            <div className="m-hxxs">
              <h3>Unassigned Scripts</h3>
              <p>
                The following scripts have not been assigned to any podcasts, did you intend to
                continue without assigning them?
              </p>
              <ul>
                {unAssignedScripts.map((script) => (
                  <li key={script.name}>
                    <strong>{script.name}</strong>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      ),
      hide: () => hideAssignmentCheckScripts,
      submitText: "Continue",
    },
    {
      title: "Script Assignment Check - Podcasts",
      Content: (
        <Row justify={"center"}>
          <Col xs={20}>
            <div className="m-hxxs">
              <h3>No Script Assigned to Podcast</h3>
              <p>{scriptAssignmentPodcastCheckText}</p>
              <ul>
                {unAssignedItems.map((item) => {
                  const show = publicShows?.[item?.showUUID];
                  return (
                    <li key={show?.title}>
                      <strong>{show?.title}</strong>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
      ),
      hide: () => hideAssignmentCheckPodcasts,
      submitText: "Continue",
      disabled: scriptRequired,
    },
    {
      title: "Choose Payment Type",
      Content: (
        <ChoosePaymentType
          paymentMethodType={paymentMethodType}
          setPaymentMethodType={setPaymentMethodType}
        />
      ),
      hide: !showPaymentType(invoicingEnabled, initialPaymentMethod, campaign),
      disabled: !paymentMethodType,
      backFunc: () => setSkipScripPages(false),
    },

    getPaymentPage({
      options,
      card,
      token,
      budgets,
      updateBudgets,
      handleCardSubmit,
      setPage2Ready,
      stripeFormRef,
      page2Ready,
      paymentMethodType,
      ...props,
    }),
    getPaymentConfirmationPage({
      paymentMethodType,
      oneWeekAmount,
      totalCost,
      budgets,
      campaign,
      card,
      initialPaymentMethod,
      user,
      onSubmit,
    }),
    {
      title: "Success!",
      subTitle:
        "Your campaign has been sent to the podcasts you selected. They will now have 5 days to respond to your request.",
      hideBack: true,
      submitText: "Ok",
      Content: (
        <Row justify="center">
          <Col xs={20} className="p-hxxs">
            <SuccessPage scriptInCampaign={scripts?.length > 0} scriptDueDate={scriptDueDate} />
          </Col>
        </Row>
      ),
    },
  ].map((setting) => ({
    ...setting,
    isLoading: props.isLoading,
    showContent: !props.isLoading,
    fillScreen: true,
  }));
  return (
    <MultiStepModal
      commonSettings={{ topWarning: topOfPageWarning }}
      pageNumber={pageNumber}
      onUpdatePage={updatePageNumber}
      close={handleClose}
      isLoading={props.campaignItemsIsLoading || props.campaignSendIsLoading}
      pages={pages}
      modalProps={{ enforceFocus: false, autoFocus: false, restoreFocus: false }}
    />
  );
};

export default connect(
  (state) => ({
    campaigns: state.campaigns.campaigns,
    publicShows: state.publicShows,
    isLoading: state.campaigns.isLoading,
    campaignItemsIsLoading: state.campaignItems.isLoading,
    campaignSendIsLoading: state.campaigns.sendIsLoading,
    validationErrors: state.campaignItems.validationErrors,
  }),
  {
    getMyCards,
    getCampaign,
    editCampaign,
    sendCampaign,
    fetchPublicShowsByUUIDs,
    updateCampaignItems,
    campaignOneWeek,
    showWarning,
  }
)(withRouter(CampaignSchedulePodcast));
