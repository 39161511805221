import React, { useMemo, useState } from "react";
import {
  Checkbox,
  CheckboxProps,
  Divider,
  Dropdown,
  Input,
  MenuProps,
  Select,
  SelectProps,
  theme,
} from "antd";
import { ColumnsType } from "antd/es/table";
import LoadingImage from "src/components/lib/image/image";
import RCTable from "src/components/lib/tables/rc_table/rc_table";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShow, PublicShowsReduxState } from "src/reducers/public_show";
import { Script } from "src/reducers/scripts";
import RCButton from "src/components/lib/button/button";
import { DownOutlined } from "@ant-design/icons";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { CampaignPromoCodeType } from "src/reducers/campaigns/types";
import { IShowMap } from "src/reducers/shows/showReducer";
import { getPromoCode } from "src/lib/campaigns";
import "./assign_script.scss";

export const NO_SCRIPT = "No Script" as const;

interface IAssignScript {
  campaignItems: ICampaignItem[]; // current Draft Items for campaign
  publicShowState: PublicShowsReduxState;
  scripts: Script[]; // scripts for campaign
  localScriptAssignments: LocalScriptAssignments;
  setLocalScriptAssignments: React.Dispatch<React.SetStateAction<LocalScriptAssignments>>;
}

type AssignScriptTableRecord = ICampaignItem & { publicShow: PublicShow };

type ScriptAssignment = {
  scriptUUID: string;
  checked: boolean;
  promoCode: Script["promoCode"];
  promoCodeType?: Script["promoCodeType"];
};

export type LocalScriptAssignments = {
  [campaignItemUUID: string]: ScriptAssignment;
} | null;

/**
 * Calculate initial values for assign scripts modal
 */
export const calcInitialValues: (props: {
  items?: ICampaignItem[];
  publicShows?: PublicShowsReduxState | IShowMap;
  scriptsByUUID?: { [scriptUUID: string]: Script };
}) => LocalScriptAssignments = ({ items, publicShows, scriptsByUUID }) => {
  let currentScriptAssignments: LocalScriptAssignments = null;

  if (Array.isArray(items) && publicShows && scriptsByUUID) {
    currentScriptAssignments = items?.reduce((accu, campaignItem) => {
      const { scriptUUID, showUUID, uuid } = campaignItem;
      if (scriptUUID) {
        const script = scriptsByUUID[scriptUUID];
        const show = publicShows?.[showUUID];

        const defaultPromoCode = getPromoCode({ show, script, campaignItem });
        const promoCodeType = script?.promoCodeType;

        accu[uuid] = {
          checked: false,
          scriptUUID,
          promoCode: defaultPromoCode,
          promoCodeType,
        };
      } else {
        accu[uuid] = {
          checked: false,
          scriptUUID: NO_SCRIPT,
          promoCode: undefined,
          promoCodeType: undefined,
        };
      }

      return accu;
    }, {} as NonNullable<LocalScriptAssignments>);
  }

  return currentScriptAssignments;
};

/**
 * Helper to create request body for assign scripts request
 */

export const calculateReqBody = (
  initialValues?: LocalScriptAssignments,
  newValues?: LocalScriptAssignments
) => {
  let requestBody: {
    [campaignItemUUID: string]: {
      scriptUUID: string | null;
      promoCode?: string | null;
      promoCodeType: CampaignPromoCodeType;
    };
  } = {};

  const hasChanged = (initial: ScriptAssignment, next: ScriptAssignment) => {
    if (
      initial?.promoCode !== next?.promoCode ||
      initial?.promoCodeType !== next?.promoCodeType ||
      initial?.scriptUUID !== next?.scriptUUID
    ) {
      return true;
    }

    return false;
  };

  if (initialValues && newValues) {
    requestBody = Object.keys(newValues ?? {}).reduce(
      (accu, campaignItemUUID) => {
        const { scriptUUID, promoCode, promoCodeType } = newValues[campaignItemUUID];

        // Only send script assignments that have changed
        if (hasChanged(initialValues[campaignItemUUID], newValues[campaignItemUUID]))
          accu[campaignItemUUID] = {
            scriptUUID: scriptUUID === NO_SCRIPT ? null : scriptUUID,
            promoCode: promoCodeType === CampaignPromoCodeType.PromoCodeTypeNone ? null : promoCode,
            promoCodeType,
          };

        return accu;
      },
      {} as {
        [campaignItemUUID: string]: {
          scriptUUID: string | null;
          promoCode?: string | null;
          promoCodeType: CampaignPromoCodeType;
        };
      }
    );
  }

  return requestBody;
};

const AssignScript = (props: IAssignScript) => {
  const { token } = theme.useToken();

  const {
    campaignItems,
    publicShowState,
    scripts,
    localScriptAssignments,
    setLocalScriptAssignments,
  } = props;

  const showByCampaignItemUUID = campaignItems?.reduce(
    (accu, curr) => {
      if (curr?.uuid && curr?.showUUID) {
        accu[curr.uuid] = publicShowState[curr.showUUID];
      }
      return accu;
    },
    {} as { [campaignItemUUD: string]: PublicShow }
  );

  const [assignAllDropdown, setAssignAllDropdown] = useState<{
    open: boolean;
    promoCode: Script["promoCode"];
    scriptUUID: string | undefined;
  }>({ open: false, promoCode: undefined, scriptUUID: undefined });

  const scriptsByUUID = scripts?.reduce(
    (accu, curr) => {
      accu[curr.uuid] = { ...curr };
      return accu;
    },
    {} as { [scriptUUID: string]: Script }
  );

  const tableData: AssignScriptTableRecord[] = campaignItems.map((item) => {
    return { ...item, publicShow: publicShowState?.[item?.showUUID] };
  });

  const selectDropdownItems: SelectProps["options"] =
    scripts
      ?.map((script) => {
        const text =
          typeof script.name === "string" && script?.name?.length > 0 ? script.name : script.uuid;

        return {
          value: script.uuid,
          label: (
            <div className="width-100 ellipsis" style={{ display: "block" }}>
              {text}
            </div>
          ),
        };
      })
      .concat([{ value: NO_SCRIPT, label: <span>{NO_SCRIPT}</span> }]) ?? [];

  const assignAllItems: ItemType[] =
    [
      ...scripts?.map((script) => {
        const text =
          typeof script.name === "string" && script?.name?.length > 0 ? script.name : script.uuid;

        return {
          key: script.uuid,
          label: <span className="line-clamp-1">{text}</span>,
        };
      }),
      ...[{ key: NO_SCRIPT, label: NO_SCRIPT }],
    ] ?? [];

  const columns: ColumnsType<AssignScriptTableRecord> = [
    {
      key: "podcast",
      title: "PODCAST",
      render: (item: any, record) => {
        const { uuid: campaignItemUUID } = record;

        const checked = localScriptAssignments?.[campaignItemUUID]?.checked;

        return (
          <div className="flex-row-container align-center">
            <Checkbox
              className="m-rxxs"
              checked={checked}
              onChange={(e) => {
                setLocalScriptAssignments((prev) => {
                  const current = prev === null ? {} : prev;
                  const newState = { ...current };
                  newState[campaignItemUUID] = { ...current[campaignItemUUID] };
                  newState[campaignItemUUID].checked = e.target.checked;

                  return newState;
                });
              }}
            />
            <LoadingImage
              className="m-rxxs"
              src={record?.publicShow?.imageURL}
              width={40}
              height={40}
              wrapperStyle={{ minWidth: 40 }}
            />

            <span className="line-clamp-2">{record?.publicShow?.title}</span>
          </div>
        );
      },
    },
    {
      key: "script",
      title: "SCRIPT",
      width: "200px",
      render: (_, record) => {
        const { uuid: campaignItemUUID } = record;

        const scriptUUID = localScriptAssignments?.[campaignItemUUID]?.scriptUUID;

        const onChange: SelectProps["onChange"] = (newScriptUUID, options) => {
          const show = record.publicShow;
          setLocalScriptAssignments((prev) => {
            const newScript = scriptsByUUID?.[newScriptUUID];

            const nextState = { ...prev };

            nextState[campaignItemUUID] = {
              ...nextState?.[campaignItemUUID],
              scriptUUID: newScriptUUID,
              promoCode: getPromoCode({ campaignItem: record, show, script: newScript }),
              promoCodeType: newScript?.promoCodeType,
            };

            return nextState;
          });
        };

        return (
          <div>
            <Select
              value={scriptUUID ?? NO_SCRIPT}
              options={selectDropdownItems}
              style={{ width: "180px", maxWidth: "180px" }}
              size="small"
              onChange={onChange}
            />
          </div>
        );
      },
    },
    {
      key: "promoCode",
      title: "PROMO CODE / VANITY URL",
      width: "200px",
      render: (_, record) => {
        const { uuid: campaignItemUUID } = record;

        const scriptUUID = localScriptAssignments?.[campaignItemUUID]?.scriptUUID || "";
        const promoCodeType = scriptsByUUID?.[scriptUUID]?.promoCodeType;

        // If not script is found in the script assignment then use default text
        const promoCodeText =
          scriptUUID === NO_SCRIPT
            ? "Select a script to view promo code/ vanity URL."
            : localScriptAssignments?.[campaignItemUUID]?.promoCode;

        if (promoCodeType === CampaignPromoCodeType.PromoCodeTypePodcasterUnique) {
          return (
            <textarea
              className="rc-textarea-input"
              rows={2}
              value={promoCodeText}
              onChange={(e) => {
                setLocalScriptAssignments((prev) => {
                  const current = prev === null ? {} : prev;
                  const newState = { ...prev };
                  newState[campaignItemUUID] = { ...current[campaignItemUUID] };
                  newState[campaignItemUUID].promoCode = e.target.value;

                  return newState;
                });
              }}
            />
          );
        }

        return <span className="line-clamp-2">{promoCodeText}</span>;
      },
    },
  ];

  // Aggregate Checked State
  const numberSelected = useMemo(() => {
    return Object.keys(localScriptAssignments ?? {}).reduce((accu, campaignItemUUID) => {
      return localScriptAssignments?.[campaignItemUUID]?.checked ? accu + 1 : accu;
    }, 0);
  }, [localScriptAssignments]);

  const allChecked = campaignItems.length === numberSelected;

  const showAssignScriptBtn = numberSelected > 0 && numberSelected <= campaignItems.length;

  const showAssignScriptInput =
    scriptsByUUID?.[assignAllDropdown?.scriptUUID ?? ""]?.promoCodeType ===
    CampaignPromoCodeType.PromoCodeTypePodcasterUnique;

  // Handlers

  const handleCheckAll: CheckboxProps["onChange"] = (e) => {
    setLocalScriptAssignments((prev) => {
      const current = prev === null ? {} : prev;
      const nextState = { ...current };

      campaignItems.forEach(({ uuid }) => {
        nextState[uuid] = { ...current?.[uuid], checked: e.target.checked };
      });

      return nextState;
    });
  };

  // Saves dropdown script click state for the assign all dropdown. This is needed locally
  // to check which script was selected in the dropdown.
  const handleClickAssignAll: MenuProps["onClick"] = ({ key: newScriptUUID }) => {
    setAssignAllDropdown((prev) => {
      return { ...prev, scriptUUID: newScriptUUID, promoCode: undefined };
    });
  };

  // Updates all script assignments based on the script chosen in the assign all dropdown
  const handleUpdateAllItems = () => {
    setLocalScriptAssignments((prev) => {
      const { scriptUUID = "", promoCode: userTypedPromoCode } = assignAllDropdown;

      const script = scriptsByUUID?.[scriptUUID];

      const nextState = { ...prev };

      campaignItems.forEach((campaignItem) => {
        const { uuid } = campaignItem;
        const show = showByCampaignItemUUID[uuid];

        if (nextState[uuid].checked) {
          nextState[uuid] = {
            ...nextState[uuid],
            scriptUUID,
            promoCode: userTypedPromoCode || getPromoCode({ show, script, campaignItem }),
            promoCodeType: script?.promoCodeType,
          };
        }
      });

      return nextState;
    });

    setAssignAllDropdown((prev) => {
      return { ...prev, open: false };
    });
  };

  return (
    <div className="m-hxxs">
      <div className="flex-row-container justify-space-between align-center p-hxxs m-bxxs bg-gray-40 rounded-corners">
        <Checkbox checked={allChecked} className="p-lxxxs p-vxxs" onChange={handleCheckAll}>
          {numberSelected > 0 ? `${numberSelected} Podcast Selected` : "Select All"}
        </Checkbox>
        {showAssignScriptBtn && (
          <Dropdown
            open={assignAllDropdown.open}
            onOpenChange={(flag) =>
              setAssignAllDropdown((prev) => {
                return { ...prev, open: flag };
              })
            }
            menu={{
              selectable: true,
              items: assignAllItems,
              onClick: handleClickAssignAll,
            }}
            placement="bottomRight"
            trigger={["click"]}
            destroyPopupOnHide={true}
            dropdownRender={(menu) => {
              return (
                <div
                  style={{
                    backgroundColor: token.colorBgElevated,
                    borderRadius: token.borderRadiusLG,
                    boxShadow: token.boxShadowSecondary,
                    maxWidth: "200px",
                  }}>
                  {React.cloneElement(menu as React.ReactElement, {
                    style: { boxShadow: "none" },
                  })}
                  <Divider style={{ margin: 0 }} />
                  <div className="width-100 flex-row-container justify-space-between p-hxs p-vxxs">
                    {showAssignScriptInput && (
                      <div className="flex-row-container p-rxxs" style={{ flex: "1 1 auto" }}>
                        <Input
                          styles={{
                            input: {
                              padding: "0px 5px",
                              width: "100%",
                            },
                          }}
                          value={assignAllDropdown?.promoCode ?? ""}
                          onChange={(e) => {
                            setAssignAllDropdown((prev) => {
                              const nextState = {
                                ...prev,
                                promoCode: e.target.value,
                              };
                              return nextState;
                            });
                          }}
                        />
                      </div>
                    )}
                    <RCButton className="m-la" type="primary" onClick={handleUpdateAllItems}>
                      Ok
                    </RCButton>
                  </div>
                </div>
              );
            }}>
            <RCButton>
              <span> Assign Script</span>
              <DownOutlined />
            </RCButton>
          </Dropdown>
        )}
      </div>
      <RCTable
        dataSource={tableData}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 6,
        }}
      />
    </div>
  );
};

export { AssignScript };
