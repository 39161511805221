import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Modal } from "redcircle-ui";
import { hideModal } from "src/actions/modal";
import { WaitlistContent } from "src/components/pages/waitlist";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { WAITLIST_MODAL } from "../modal_root";

/**
 * Displays Create account waitlist content.
 */
const WaitlistModal = () => {
  const dispatch = useReduxDispatch();
  const [open, setOpen] = useState(true); // Used for better onClose modal animation control
  const modal = useSelectorTS((state) => state.modal);

  const openModalFlag = modal.modalType === WAITLIST_MODAL;

  /**
   * Sync Modal open flag with app redux modal state, only on open
   */
  useEffect(() => {
    if (openModalFlag) {
      setOpen(openModalFlag);
    }
  }, [openModalFlag]);

  /**
   * Manually close the modal
   */
  const onClose = () => {
    setOpen(false);
  };

  /**
   * On close animation complete, remove redux modal state
   */
  const afterClose = () => {
    dispatch(hideModal());
  };

  return (
    <Modal size="sm" open={open} onClose={onClose} onSubmit={onClose} afterClose={afterClose}>
      <Modal.Title>You are on the List!</Modal.Title>
      <Modal.Body>
        <Row>
          <Col>
            <WaitlistContent hideCTA />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer noBorder>
        <div className="flex-row-container justify-end width-100">
          <Modal.CloseButton>Close</Modal.CloseButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default WaitlistModal;
