import get from "lodash/get";
import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueSelector, submit } from "redux-form";
import { updateCurrentRole } from "../../../actions/account";
import { updatePassword } from "../../../action_managers/account";
import { loginByAdvertiserContactToken } from "../../../action_managers/forgot_password";
import UserRoles from "../../../constants/roles";
import { useComponentDidMount } from "../../../lib/hook";
import { saveUserRole } from "../../../lib/user_store";
import { MultiStepModal } from "../../modals/multi_step_modals/multi_step_modal";
import NewPasswordForm from "../../pages/forgot_password/new_password_form";

const AdvertiserWelcomeModal = (props) => {
  useComponentDidMount(() => {
    const { email, advertiserContactToken } = get(props, "match.params", {});
    props.loginByAdvertiserContactToken(atob(email), advertiserContactToken).then((resp) => {
      if (resp.status !== 200) {
        props.history.push("/");
      } else {
        props.updateCurrentRole(UserRoles.Advertiser);
        saveUserRole(UserRoles.Advertiser);
      }
    });
  }, []);

  const ModalContent = () => {
    return (
      <Grid fluid>
        <Row>
          <Col xs={10} xsOffset={1}>
            <h2>Welcome to RedCircle!</h2>
            <p>
              This is the advertising portal. Here you can browse the entire catalog of RedCircle
              podcasts that are available for advertising, as well as their rates, and other
              information.
            </p>
          </Col>
        </Row>
      </Grid>
    );
  };

  const onSubmit = (values) => {
    props.updatePassword(values.password, props.user).then((resp) => {
      if (resp.status === 200) {
        props.history.push("/campaigns");
      }
    });
  };

  const passwordReset = () => {
    return (
      <Grid fluid>
        <Row>
          <Col xs={10} xsOffset={1}>
            <NewPasswordForm
              hideSubmit
              password={props.password}
              onSubmit={onSubmit}
              validationErrors={props.validationErrors}
            />
          </Col>
        </Row>
      </Grid>
    );
  };
  const commonSettings = {
    isMini: true,
    showContent: true,
  };
  const pages = () => [
    {
      ...commonSettings,
      hideBack: true,
      Content: ModalContent(),
    },
    {
      ...commonSettings,
      hideBack: true,
      title: "First thing's first...",
      subTitle: "Before we get started, we need you to setup a password",
      Content: passwordReset(),
    },
  ];
  return (
    <MultiStepModal
      hideCloseButton
      pages={pages()}
      isLoading={props.isLoading}
      onSubmit={props.submitNewPassword}
    />
  );
};

export default connect(
  (state) => ({
    user: state.user.user,
    isLoading: state.updateAccount.isLoading,
    validationErrors: state.updateAccount.validationErrors,
    password: formValueSelector("new-password")(state, "password"),
  }),
  {
    updatePassword,
    updateCurrentRole,
    loginByAdvertiserContactToken,
    submitNewPassword: () => submit("new-password"),
  }
)(withRouter(AdvertiserWelcomeModal));
