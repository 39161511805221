import { WarningFilled } from "@ant-design/icons";
import { Divider, Popover } from "antd";
import Moment from "moment";
import React, { ReactNode } from "react";
import { classNames } from "react-extras";
import { Link } from "react-router-dom";
import ContextMenu from "src/components/lib/context_menu";
import Loading from "src/components/lib/loading";
import RCTable from "src/components/lib/tables/rc_table";
import { permissionTypes } from "src/constants/permission_roles";
import { useCanAccessBound, useHasAtLeastOnePermitted } from "src/lib/permissions";
import { AudioBlock } from "src/reducers/audio_blocks";
import { CustomAudio } from "src/reducers/custom_audio";
import { MediaFile } from "src/reducers/media_file_upload";
import { getCustomAudioInvalids } from "../dynamic_insertion_util";
import styles from "./audio_blocks.module.scss";
import {
  AD_TYPE_AUDIO_FILE,
  AD_TYPE_CROSS_PROMO,
  AD_TYPE_CUSTOM,
  AD_TYPE_DEFAULT,
  AD_TYPE_HUMANIZED,
  PLAY_ORDER_HUMANIZED,
  PLAY_ORDER_HUMANIZED_ADVERB,
  PLAY_ORDER_ICONS,
  PLAY_ORDER_INORDER,
  PLAY_ORDER_RANDOM,
} from "./consts";

interface IProps {
  audioBlocks: Record<string, AudioBlock>;
  customAudios: Record<string, CustomAudio>;
  mediaFiles: Record<string, MediaFile>;
  onDeleteBlock: (block: AudioBlock) => void;
  onEditBlock: (block: AudioBlock) => void;
  onAssignBlock: (block: AudioBlock) => void;
  onViewBlock: (block: AudioBlock) => void;
  isStatsLoading?: boolean;
}

const DEFAULT_AUDIO_BLOCK_TYPES = ["default-cross-promo", "default-redcircle-ad"];

export default function AudioBlocksTable({
  audioBlocks,
  customAudios,
  mediaFiles,
  onDeleteBlock,
  onEditBlock,
  onAssignBlock,
  onViewBlock,
  isStatsLoading,
}: IProps) {
  const canAccess = useCanAccessBound();
  const userCanAssign = useHasAtLeastOnePermitted(permissionTypes.bulkAssignAudioBlock);

  const columns: any[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (text: string, audioBlock: AudioBlock) => {
        const userCanEdit = canAccess(permissionTypes.editAudioBlock, audioBlock.uuid);
        const isDefault = DEFAULT_AUDIO_BLOCK_TYPES.includes(audioBlock.type);

        return (
          <Link
            style={{ color: "inherit" }}
            to={`/dynamic-insertion/audio-blocks/${userCanEdit && !isDefault ? "edit" : "view"}/${
              audioBlock.uuid
            }`}>
            <strong>{text}</strong>
          </Link>
        );
      },
      sorter: (a: AudioBlock, b: AudioBlock) => a.name.localeCompare(b.name),
    },
    {
      title: "Audio",
      key: "audio",
      dataIndex: "audio",
      render: (_: any, audioBlock: AudioBlock) => (
        <AudioDetailPopover
          audioBlock={audioBlock}
          customAudios={customAudios}
          mediaFiles={mediaFiles}>
          <span>
            Play <b>{audioBlock.limit}</b> of{" "}
            <b>{audioBlock.items ? audioBlock.items.length : "0"}</b>
          </span>
        </AudioDetailPopover>
      ),
      sorter: (a: AudioBlock, b: AudioBlock) => a.items.length - b.items.length,
    },
    {
      title: "Play Style",
      key: "playStyle",
      dataIndex: "playStyle",
      render: (text: string) => (
        <div className="flex-row-container align-center">
          {PLAY_ORDER_HUMANIZED[text as keyof typeof PLAY_ORDER_HUMANIZED]}
          <img src={PLAY_ORDER_ICONS[text as keyof typeof PLAY_ORDER_ICONS]} className="m-lxxs" />
        </div>
      ),
      sorter: (a: AudioBlock, b: AudioBlock) => a.playStyle.localeCompare(b.playStyle),
    },
    {
      title: "Updated At",
      key: "updatedAt",
      dataIndex: "updatedAt",
      defaultSortOrder: "descend",
      render: (updatedAt: number) => Moment.unix(updatedAt).format("MM/DD/YY h:mm A"),
      sorter: (a: AudioBlock, b: AudioBlock) => a.updatedAt - b.updatedAt,
    },
    {
      title: "Episodes",
      key: "episodes",
      dataIndex: "episodes",
      render: (text: string) => {
        if (isStatsLoading || text === undefined) return <Loading size="small" />;
        return text;
      },
    },
    {
      title: "Insertions Last 30D",
      key: "insertionsLast30Days",
      dataIndex: "insertionsLast30Days",
      render: (text: string) => {
        if (isStatsLoading || text === undefined) return <Loading size="small" />;
        return text;
      },
    },
    {
      key: "context",
      width: 50,
      render: (_: any, audioBlock: AudioBlock) => {
        const isDefault = DEFAULT_AUDIO_BLOCK_TYPES.includes(audioBlock.type);
        const userCanEdit = canAccess(permissionTypes.editAudioBlock, audioBlock.uuid);

        return (
          <ContextMenu
            noCircle
            menuItems={{
              ...(userCanAssign
                ? { "Assign To Insertion Points...": () => onAssignBlock(audioBlock) }
                : {}),
              ...(userCanEdit && !isDefault
                ? {
                    "Edit Block": () => onEditBlock(audioBlock),
                    "Delete Block": () => onDeleteBlock(audioBlock),
                  }
                : {
                    View: () => onViewBlock(audioBlock),
                  }),
            }}
          />
        );
      },
    },
  ];

  const data: any[] = Object.values(audioBlocks).map((audioBlock: AudioBlock) => ({
    key: `${audioBlock.name}-${audioBlock.createdAt}`,
    ...audioBlock,
    limit: audioBlock.limit === null ? audioBlock.items.length : audioBlock.limit,
    ...audioBlock.stats,
  }));

  return <RCTable columns={columns} dataSource={data} pagination={false} />;
}

const AudioDetailPopover = ({
  children,
  audioBlock,
  customAudios,
  mediaFiles,
}: {
  children: ReactNode;
  audioBlock: AudioBlock;
  customAudios?: Record<string, CustomAudio>;
  mediaFiles?: Record<string, MediaFile>;
}) => {
  const { items = [] } = audioBlock;
  const activeItems = items.slice(0, audioBlock.limit);
  const fallbackItems = items.slice(audioBlock.limit);

  const ContentList = ({
    children,
    start,
    className,
  }: {
    children: ReactNode;
    start: number;
    className?: string;
  }) => {
    if (audioBlock.playStyle === PLAY_ORDER_INORDER)
      return (
        <ol className={className} start={start}>
          {children}
        </ol>
      );

    if (audioBlock.playStyle === PLAY_ORDER_RANDOM)
      return <ul className={className}>{children}</ul>;

    return null;
  };

  const ContentListItems = ({ audioItems = [] }: any) => (
    <>
      {audioItems.map((audioFile: any, index: number) => {
        if ([AD_TYPE_CROSS_PROMO, AD_TYPE_DEFAULT].includes(audioFile.type)) {
          return (
            <li key={index}>
              {AD_TYPE_HUMANIZED[audioFile.type as keyof typeof AD_TYPE_HUMANIZED]}
            </li>
          );
        }

        if (audioFile.type === AD_TYPE_CUSTOM && customAudios) {
          const customAudio = customAudios[audioFile.customAudioUUID] || {};
          const isInvalid = Object.keys(getCustomAudioInvalids(customAudio)).length > 0;
          return (
            <li key={index} className={classNames(isInvalid && styles.invalid)}>
              {isInvalid && <WarningFilled className="m-rxxxs" />}
              {customAudio.name}
            </li>
          );
        }

        if (audioFile.type === AD_TYPE_AUDIO_FILE && mediaFiles) {
          const mediaFile = mediaFiles[audioFile.mediaFileUUID] || {};
          return <li key={index}>{mediaFile.fileName || "Audio File"}</li>;
        }
      })}
    </>
  );

  const content = (
    <div className="flex-column-container">
      <span className="m-bxxs">
        Play <b>{audioBlock.limit || "0"}</b> of{" "}
        <b>{audioBlock.items ? audioBlock.items.length : "0"}</b> audios{" "}
        <b>
          {
            PLAY_ORDER_HUMANIZED_ADVERB[
              audioBlock.playStyle as keyof typeof PLAY_ORDER_HUMANIZED_ADVERB
            ]
          }
        </b>
      </span>
      <div></div>

      <ContentList start={1} className="p-lxs m-bxxs">
        <ContentListItems audioItems={activeItems} />
      </ContentList>

      {fallbackItems.length > 0 && (
        <>
          <Divider className="m-t0 m-bxxs" />
          <span className="preheader m-bxxs">FALLBACK</span>
          <ContentList start={activeItems.length + 1} className="p-lxs m-b0">
            <ContentListItems audioItems={fallbackItems} />
          </ContentList>
        </>
      )}
    </div>
  );

  return <Popover content={content}>{children}</Popover>;
};
