import { Popover, Tooltip } from "antd";
import React from "react";
import { classNames } from "react-extras";
import { AiFillInfoCircle, AiOutlinePlus } from "react-icons/ai";
import { BsHandThumbsUpFill } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { ShowAlbumArt } from "src/components/lib/album_art";
import RCButton from "src/components/lib/button";
import EmptyStateBlock from "src/components/lib/empty_state_block";
import ExclusiveTag from "src/components/lib/exclusive_tag/exclusive_tag";
import Paginator from "src/components/lib/paginator";
import RCTable from "src/components/lib/tables/rc_table";
import { itunesCategoriesMap } from "src/data/itunes_categories";
import { getAverageCPM } from "src/lib/campaigns";
import { formatMoney } from "src/lib/format-money";
import { IShow } from "src/reducers/shows";
import styles from "./advertiser_browse_page.module.scss";
import PodcastMoreInfoPopover from "./podcast_more_info_popover";

interface IProps {
  view: string;
  results: IShow[];
  isLoading?: boolean;
  currentPage: string;
  onPageChange: (page: number) => void;
  onClickAdd: (show: IShow) => void;
  externalLink?: boolean; // changes all links to open in a new tab
  selectedShowUUIDs?: string[];
  disabledShowUUIDs?: Record<string, boolean>;
}

const DEFAULT_PAGE_SIZE = 100;
const VIEW_GRID = "grid";
const VIEW_LIST = "list";

const AdvertiseBrowseResults = React.forwardRef(
  (
    {
      view,
      results,
      isLoading,
      currentPage,
      onPageChange,
      onClickAdd,
      externalLink,
      selectedShowUUIDs,
      disabledShowUUIDs = {},
    }: IProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const location = useLocation();

    const humanizeDownloads = (downloads: number) => {
      if (downloads < 1000) return downloads;
      return Math.round(downloads / 100) / 10 + "k";
    };

    const tableColumns = [
      {
        title: "Podcast",
        dataIndex: "title",
        key: "title",
        render: (_: any, show: IShow) => (
          <TablePodcastCell show={show} externalLink={externalLink} />
        ),
      },
      {
        title: "Downloads",
        dataIndex: "downloads",
        key: "downloads",
        render: (text: number) => <strong>{humanizeDownloads(text)}</strong>,
      },
      {
        title: "CPM",
        dataIndex: "cpm",
        key: "cpm",
        render: (text: number, show: IShow) => <TableCPMCell text={text} show={show} />,
      },
      {
        dataIndex: "addAction",
        key: "addAction",
        render: (_: any, show: IShow & { isExcluded?: boolean }) => (
          <div className="flex-row-container align-center">
            <RCButton
              type="link"
              className="p-rxxxs"
              onClick={() => onClickAdd(show)}
              disabled={disabledShowUUIDs[show.uuid] || show.isExcluded}>
              {disabledShowUUIDs[show.uuid] && "Invited"}
              {!disabledShowUUIDs[show.uuid] &&
                (selectedShowUUIDs?.includes(show.uuid) ? "Remove" : "Add")}
            </RCButton>
            {show.isExcluded && (
              <Tooltip title={show.isExcluded ? "This podcast is excluded from the campaign" : ""}>
                <AiFillInfoCircle />
              </Tooltip>
            )}
          </div>
        ),
      },
    ];

    const dataSource = results.map((show: IShow) => ({
      ...show,
      key: show.uuid,
      downloads: show.estimatedWeeklyDownloads,
      cpm: getAverageCPM({ show }),
    }));

    return (
      <div className={styles["browse-shows-container"]} ref={ref}>
        {!isLoading && results.length === 0 && <EmptyStateBlock>No Podcasts Found</EmptyStateBlock>}

        {/* GRID VIEW */}
        {view === VIEW_GRID && results.length > 0 && (
          <div className={styles["browse-shows-grid"]}>
            {results.map((show: IShow & { isExcluded?: boolean }) => (
              <div
                key={show.uuid}
                className={classNames(styles["show-block"], "flex-column-container")}>
                <Link
                  to={{
                    pathname: `/browse/${show.uuid}`,
                    state: { prevPath: `${location.pathname}${location.search}` },
                  }}
                  target={externalLink ? "_blank" : undefined}
                  style={{ color: "inherit" }}>
                  <div style={{ position: "relative" }}>
                    <ShowAlbumArt show={show} className={styles["show-image"]} size="400x400" />
                    <div className={styles["add-button"]}>
                      <RCButton
                        type="primary"
                        size="small"
                        disabled={show.isExcluded}
                        onClick={(e) => {
                          e.preventDefault();
                          onClickAdd(show);
                        }}>
                        <AiOutlinePlus className="m-txxxs" />
                      </RCButton>
                    </div>
                  </div>
                  <strong className="line-clamp-2 m-txxs" title={show.title}>
                    {show.title}
                  </strong>
                </Link>

                <div className="flex-row-container align-center">
                  <small className="m-rxxxs">
                    {`${humanizeDownloads(show.estimatedWeeklyDownloads)} Weekly Downloads`}
                  </small>
                  <PodcastMoreInfoPopover show={show} />
                </div>
              </div>
            ))}
          </div>
        )}

        {/* LIST VIEW */}
        {view === VIEW_LIST && results.length > 0 && (
          <RCTable
            className={styles["browse-shows-table"]}
            columns={tableColumns}
            dataSource={dataSource}
            pagination={false}
          />
        )}

        {!isLoading && (
          <Paginator
            className="width-100 justify-center m-txxs m-bxxs"
            currentPage={currentPage ? parseInt(currentPage, 10) : 1}
            pageSize={DEFAULT_PAGE_SIZE}
            currentItems={results}
            onPageChange={onPageChange}
          />
        )}
      </div>
    );
  }
);
AdvertiseBrowseResults.displayName = "AdvertiseBrowseResults";

const TablePodcastCell = ({
  show,
  externalLink,
}: {
  show: IShow & { isRecommended?: boolean };
  externalLink?: boolean;
}) => {
  return (
    <div className="flex-row-container align-center">
      <Link
        to={`/browse/${show.uuid}`}
        style={{ color: "inherit" }}
        className="flex-row-container"
        target={externalLink ? "_blank" : undefined}>
        <ShowAlbumArt show={show} className={styles["table-image"]} size="64x64" />
      </Link>

      <div className="flex-column-container">
        <div className="flex-row-container align-center m-bxxxs">
          <Link
            to={{
              pathname: `/browse/${show.uuid}`,
              state: { prevPath: `${location.pathname}${location.search}` },
            }}
            target={externalLink ? "_blank" : undefined}
            style={{ color: "inherit" }}
            className="line-clamp-1">
            <strong>
              <h5 className="m-b0 m-rxxxs">{show.title}</h5>
            </strong>
          </Link>
          <PodcastMoreInfoPopover show={show} />
          {show.isRecommended && (
            <ExclusiveTag className="m-lxxxs">
              <span className="line-clamp-1">
                <BsHandThumbsUpFill />{" "}
                <span className={styles["recommended-tag"]}>TARGET AUDIENCE FIT</span>
              </span>
            </ExclusiveTag>
          )}
        </div>
        <small className="line-clamp-2">
          <>
            Category: {show.categoryUUIDs.map((uuid) => itunesCategoriesMap[uuid].name).join(", ")}
          </>
        </small>
      </div>
    </div>
  );
};

const TableCPMCell = ({ text, show }: { text: number; show: IShow }) => {
  const preRoll = show?.advertisementSettings?.hostReadPreRollCPM;
  const midRoll = show?.advertisementSettings?.hostReadMidRollCPM;
  const postRoll = show?.advertisementSettings?.hostReadPostRollCPM;

  if (!preRoll && !midRoll && !postRoll) return <strong>{formatMoney(text)}</strong>;

  const Content = (
    <div className="flex-row-container">
      <div className="flex-column-container align-center m-rxs">
        <strong>
          <small>PRE-ROLL</small>
        </strong>
        <strong>{formatMoney(preRoll)}</strong>
      </div>
      <div className="flex-column-container align-center m-rxs">
        <strong>
          <small>MID-ROLL</small>
        </strong>
        <strong>{formatMoney(midRoll)}</strong>
      </div>
      <div className="flex-column-container align-center">
        <strong>
          <small>POST-ROLL</small>
        </strong>
        <strong>{formatMoney(postRoll)}</strong>
      </div>
    </div>
  );

  return (
    <Popover content={Content}>
      <strong>{formatMoney(text)}</strong>
    </Popover>
  );
};

export default AdvertiseBrowseResults;
