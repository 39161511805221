import { Col, InputNumber, Row } from "antd";
import HighlightBox from "src/components/lib/highlight_box/highlight_box";
import { IAgeBucket, IListenerDemographics } from "src/reducers/shows";

const genderKeyToLabel: Record<string, string> = {
  female: "Female",
  male: "Male",
  unknown: "Unknown",
  nonBinary: "Non-binary",
};

const ageLabelToFriendly: Record<string, string> = {
  ["0-17"]: "<18",
  ["60-150"]: "60+",
  ["0-0"]: "Unknown",
};

export default function ListenerDemographicsForm({
  value,
  onChange,
}: {
  value?: IListenerDemographics;
  onChange?: (v: IListenerDemographics) => void;
}) {
  const handleGenderChange = (gender: string, genderValue: number | null) => {
    if (!value || !value.genderBreakdown) return;

    const newValue = { ...value };
    newValue.genderBreakdown = {
      ...newValue.genderBreakdown,
      [gender]: genderValue === null ? 0 : genderValue,
    };
    if (onChange) onChange(newValue as IListenerDemographics);
  };

  const handleGenderBlur = (gender: string) => {
    if (!value || !value.genderBreakdown) return;

    // reset gender if exceeds 100
    const total = Object.values(value.genderBreakdown).reduce((a, b) => a + b, 0);
    if (total > 100) {
      const otherTotals = Object.keys(value.genderBreakdown)
        .filter((k) => k !== gender)
        .reduce((a, b) => a + value.genderBreakdown[b as keyof typeof value.genderBreakdown], 0);
      handleGenderChange(gender, Math.max(100 - otherTotals, 0));
    }
  };

  const handleAgeChange = (age: IAgeBucket, ageValue: number | null) => {
    if (!value || !value.ageBuckets) return;
    const newValue = { ...value };
    const ageBucketIndex = newValue.ageBuckets.findIndex((a) => a.label === age.label);
    if (ageBucketIndex === -1) return;
    newValue.ageBuckets[ageBucketIndex].percentage = ageValue === null ? 0 : ageValue;
    if (onChange) onChange(newValue as IListenerDemographics);
  };

  const handleAgeBlur = (age: IAgeBucket) => {
    if (!value) return;

    // reset age if exceeds 100
    const total = value.ageBuckets.reduce((a, b) => a + b.percentage, 0);
    if (total > 100) {
      const otherTotals = value.ageBuckets
        .filter((a) => a.label !== age.label)
        .reduce((a, b) => a + b.percentage, 0);
      handleAgeChange(age, 100 - otherTotals);
    }
  };

  const remainingGenderPercentage =
    value && Math.max(100 - Object.values(value.genderBreakdown).reduce((a, b) => a + b, 0), 0);
  const remainingAgePercentage =
    value && Math.max(100 - value.ageBuckets.reduce((a, b) => a + b.percentage, 0), 0);

  return (
    <div>
      <Row>
        <Col xs={16}>
          <h5 className="m-bxxs">Listener Gender*</h5>
          <div className="m-bxs">
            {value &&
              Object.keys(value.genderBreakdown).map((gender) => {
                return (
                  <Row key={gender} className="m-bxxs">
                    <Col xs={12}>{genderKeyToLabel[gender]}</Col>
                    <Col xs={12}>
                      <InputNumber
                        value={value.genderBreakdown[gender as keyof typeof value.genderBreakdown]}
                        onChange={(v) => handleGenderChange(gender, v === null ? null : v)}
                        onBlur={() => handleGenderBlur(gender)}
                        className="m-rxxxs"
                        min={0}
                      />
                      <strong>%</strong>
                    </Col>
                  </Row>
                );
              })}
          </div>
        </Col>
        <Col xs={8} className="flex-row-container align-center">
          <HighlightBox title="Remaining Percentage">
            <h3>{remainingGenderPercentage}%</h3>
          </HighlightBox>
        </Col>
      </Row>
      <Row>
        <Col xs={16}>
          <h5 className="m-bxxs">Listener Age*</h5>
          <div>
            {value &&
              Object.values(value.ageBuckets).map((age) => {
                return (
                  <Row key={age.label} className="m-bxxs">
                    <Col xs={12}>{ageLabelToFriendly[age.label] || age.label}</Col>
                    <Col xs={12}>
                      <InputNumber
                        value={age.percentage}
                        onChange={(v) => handleAgeChange(age, v)}
                        onBlur={() => handleAgeBlur(age)}
                        className="m-rxxxs"
                        min={0}
                      />
                      <strong>%</strong>
                    </Col>
                  </Row>
                );
              })}
          </div>
        </Col>
        <Col xs={8} className="flex-row-container align-center">
          <HighlightBox title="Remaining Percentage">
            <h3>{remainingAgePercentage}%</h3>
          </HighlightBox>
        </Col>
      </Row>
    </div>
  );
}
