import isEmpty from "lodash/isEmpty";
import { ICampaign } from "src/reducers/campaigns/types";
import { User } from "src/reducers/user";
import { createPermission, CreatePermission } from "../action_managers/permissions";
import {
  AdvertiserPermissionTypes,
  permissionPresets,
  permissionTypes,
} from "../constants/permission_roles";
import { useSelectorTS } from "../hooks/redux-ts";
import { store } from "../index";
import { PermissionsReduxState } from "../reducers/permissions";
import { IRootState } from "../reducers/types";

export const canAccess = (
  user: any,
  permissionState: PermissionsReduxState,
  actionType: keyof typeof permissionTypes,
  objectUUID?: string
): boolean => {
  if (isEmpty(permissionState?.permissionsByKey)) {
    return false;
  }
  if (isOrgOwner(user, permissionState)) {
    return true;
  }
  // check for object specific permissions if provided and it exists
  if (objectUUID) {
    const objectKey = getPermissionKey(user.credentialUUID, objectUUID);
    const objectPermission = permissionState.permissionsByKey[objectKey];
    if (objectPermission) {
      return checkPermission(permissionState, objectKey, actionType);
    }
  }

  const ownerKey = getPermissionKey(user.credentialUUID, user.uuid);
  const orgPermission = permissionState.permissionsByKey[ownerKey];
  // check for any fallback "everything else" permissions
  if (orgPermission) {
    return checkPermission(permissionState, ownerKey, actionType);
  }
  return false;
};

export const isOrgOwner = (
  user: IRootState["user"]["user"],
  permissionState: PermissionsReduxState
) => {
  const ownerKey = getPermissionKey(user.credentialUUID, user.uuid);
  const orgPermission = permissionState.permissionsByKey[ownerKey];
  // orgOwner gets sudo access
  return orgPermission?.preset === permissionPresets.orgAdmin;
};

export const checkPermission = (
  permissionState: PermissionsReduxState,
  key: string,
  actionType: keyof typeof permissionTypes
): boolean => {
  const permission = permissionState.permissionsByKey[key];
  // override take priority if defined
  if (typeof permission.overrides?.[actionType] === "boolean") {
    return permission.overrides[actionType] as boolean;
  }
  return Boolean(permissionState.presets[permission.preset]?.[actionType]);
};

export const useCanAccess = (permissionType: keyof typeof permissionTypes, objectUUID?: string) => {
  const user = useSelectorTS((state) => state.user.user);
  const permissionState = useSelectorTS((state) => state.permissions);
  return canAccess(user, permissionState, permissionType, objectUUID);
};

export const useCanAccessBound = () => {
  const user = useSelectorTS((state) => state.user.user);
  const permissionState = useSelectorTS((state) => state.permissions);
  return (permissionType: keyof typeof permissionTypes, objectUUID?: string) => {
    return canAccess(user, permissionState, permissionType, objectUUID);
  };
};

const getPermissionKey = (credentialUUID: string, objectUUID: string) => {
  return `${credentialUUID}-${objectUUID}`;
};

export const hasAtLeastOnePermitted = (
  user: User,
  permissionState: PermissionsReduxState,
  permissionType: keyof typeof permissionTypes
) => {
  if (isEmpty(permissionState.permissionsByKey) || isEmpty(user)) {
    return false;
  }
  if (isOrgOwner(user, permissionState)) {
    return true;
  }
  return permissionState?.permissionsByCredentialUUID?.[user?.credentialUUID]?.some(
    (key: string) => {
      return checkPermission(permissionState, key, permissionType);
    }
  );
};

export const useHasAtLeastOnePermitted = (permissionType: keyof typeof permissionTypes) => {
  const user = useSelectorTS((state) => state.user.user);
  const userIsLoading = useSelectorTS((state) => state.user.isLoading);
  const permissionState = useSelectorTS((state) => state.permissions);
  if (!userIsLoading && !permissionState.isLoading) {
    return hasAtLeastOnePermitted(user, permissionState, permissionType);
  }
};

export const usePermissionIsLoading = () => {
  return useSelectorTS(permissionIsLoading);
};

export const permissionIsLoading = (state: IRootState) => {
  const permissionState = state.permissions;

  return (
    state.user.isLoading ||
    !permissionState.initialFetched ||
    permissionState.isLoading ||
    !state.shows.initialFetched ||
    permissionState.presetIsLoading
  );
};

/*
 * Creates explicit show permissions for all non-orgOwner credentials for a new show,
 * based on that credentials' org permission default preset
 *
 * To be used when user create/import new show. Will create an explicit show permission with the default org
 * preset role and will check if that role has overrides and udpate accordingly
 * */

export const addNewShowDefaultPermissions = (newShowUUID: string) => {
  // Add explicit default permission for this new show to all credentials

  const allPermissions = (store.getState() as IRootState)?.permissions?.allPermissions?.permissions;
  if (allPermissions?.length > 0) {
    // Extra precaution, this is a new show so no explicit permissions for this show should exist
    const showPermissionsExist = allPermissions?.some(
      (permission) => permission?.objectType === "show" && permission?.objectUUID === newShowUUID
    );

    if (!showPermissionsExist) {
      const overridesMap = allPermissions?.reduce((accu, curr) => {
        const role = curr?.preset;
        accu[role] = { ...accu[role], ...curr?.overrides };
        return accu;
      }, {} as Record<keyof typeof permissionPresets, Record<keyof typeof permissionTypes, boolean>>);

      const additions: CreatePermission[] = allPermissions
        ?.filter(
          (permission) =>
            permission?.objectType === "org" && permission?.preset !== permissionPresets.orgAdmin
        )
        .map((permission) => {
          return {
            credentialUUID: permission?.credentialUUID,
            objectType: "show",
            objectUUID: newShowUUID,
            overrides: !isEmpty(overridesMap[permission?.preset])
              ? overridesMap[permission?.preset]
              : undefined,
            preset: permission?.preset,
          };
        });

      if (additions?.length > 0) {
        store.dispatch(createPermission(additions)).catch((err: any) => {
          console.log(err);
        });
      }
    }
  }
};

/**
 * Determine if user has specific permission type for a campaign. Should only be used to
 * determine user permissions for campaigns (i.e. campaign pages)
 */
export const canAdvertiserAccess = (
  advertiserPermissionType: AdvertiserPermissionTypes,
  campaign?: ICampaign
) => {
  return campaign && Array.isArray(campaign?.authorizedPermissionTypes)
    ? campaign.authorizedPermissionTypes.includes(advertiserPermissionType)
    : false;
};
