import dayjs from "dayjs";
import { sortBy } from "lodash";
import { useMemo } from "react";
import {
  CampaignItemStateAccepted,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCompleted,
  CampaignItemStateDeclined,
  CampaignItemStateExpired,
  CampaignItemStateNeedsScript,
  CampaignItemStatePaused,
  CampaignItemStateRunning,
  CampaignItemStateSent,
  CampaignToDiscreteTargetingOptions,
} from "src/constants/campaigns";
import { useSelectorTS } from "src/hooks/redux-ts";
import { ICampaignItem } from "src/reducers/campaign_items";

export const useGetConflicts = (campaignItems: ICampaignItem[]) => {
  const campaigns = useSelectorTS((state) => state.campaigns.campaigns);
  return useMemo(() => {
    const weekToItemUUID: any = {};
    // this will produce { {weekToken + position}: list of item uuids with that week and position }
    campaignItems
      .filter(
        (item) =>
          item.flightConfigs?.length &&
          item.state !== CampaignItemStateDeclined &&
          item.state !== CampaignItemStateExpired
      )
      .forEach((item) =>
        item.flightConfigs.forEach((config) => {
          const position = CampaignToDiscreteTargetingOptions(campaigns[item.campaignUUID]);
          const key = config.weekStartAt + position;
          weekToItemUUID[key] = weekToItemUUID[key] || [];
          weekToItemUUID[key].push(item.uuid);
        })
      );
    const pendingUUIDs = new Set(
      campaignItems.filter((item) => item.state === CampaignItemStateSent).map((item) => item.uuid)
    );
    // if at least one of the timeslots has more than one item and one of those items is an incoming invite
    return Object.values(weekToItemUUID)
      .filter((uuids: any) => uuids.length > 2)
      .reduce((agg: any, uuids: any) => {
        uuids.forEach((uuid: any) => agg.add(uuid));
        return agg;
      }, new Set());
  }, [campaignItems.map((item) => item.uuid).join("|")]);
};

export const sortCampaignItems = (campaignItemsByState: any) => {
  const items = { ...campaignItemsByState };

  // modifies items obj in place
  const sortByKey = (toSortKey: string, key: string, reverse = true) => {
    items[toSortKey] = items[toSortKey].sort((a: any, b: any) => {
      if (typeof a[key] === "number" && typeof b[key] === "number") {
        return !reverse ? a[key] - b[key] : b[key] - a[key];
      }
      if (typeof a[key] === "string" && typeof b[key] === "string") {
        return !reverse ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      }
    });
  };

  sortByKey(CampaignItemStatePaused, "startAt");
  sortByKey(CampaignItemStateNeedsScript, "scriptDueBy", false);
  sortByKey(CampaignItemStateAwaitingAudio, "uploadAudioBy");
  sortByKey(CampaignItemStateSent, "respondByAt");

  sortByKey(CampaignItemStateAccepted, "startAt");
  sortByKey(CampaignItemStateRunning, "startAt");
  sortByKey(CampaignItemStateCompleted, "completedAt");
  sortByKey(CampaignItemStateExpired, "expiredAt");
  sortByKey(CampaignItemStateDeclined, "respondedAt");
  return items;
};

// this bypasses tag ordering and sorts based on due dates instead.
// used after sortCampaignItems at the table level
export const sortCampaignItemsByDueDate = (campaignItems: ICampaignItem[]) => {
  return [...campaignItems].sort((a, b) => {
    let aDate, bDate;
    if (a.state === CampaignItemStateAwaitingAudio) aDate = a.uploadAudioBy;
    if (a.state === CampaignItemStateSent) aDate = a.respondByAt;
    if (b.state === CampaignItemStateAwaitingAudio) bDate = b.uploadAudioBy;
    if (b.state === CampaignItemStateSent) bDate = b.respondByAt;
    if (!aDate || !bDate) return 0;
    return aDate - bDate;
  });
};

export const getCampaignItemStart = (campaignItem: ICampaignItem) => {
  if (!campaignItem.startAt || campaignItem.startAt < 0) return null;
  let start = dayjs.unix(campaignItem.startAt);
  if (campaignItem.flightConfigs?.length) {
    const newStart = sortBy(campaignItem.flightConfigs, "weekToken")[0]?.weekStartAt;
    start = dayjs.unix(parseInt(newStart)).tz("EST");
  }
  return start;
};
