import { useEffect, useRef, useState } from "react";

const UPDATE_INTERVAL = 1000;

export const useAudioPlayer = ({
  preload,
  autoplay,
}: {
  preload?: boolean;
  autoplay?: boolean;
}) => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
  const [currentVolume, setCurrentVolume] = useState(1);
  const [currentPlayRate, setCurrentPlayRate] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [title, setTitle] = useState("");
  const [playPromise, setPlayPromise] = useState<Promise<void> | null>(null); // tracks play to prevent stop before play errors
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const audioData = {
    isPlaying,
    title,
    currentTime,
    audio: currentAudio,
    src: currentAudio?.src,
    volume: currentVolume,
    playRate: currentPlayRate,
    duration: currentAudio?.duration,
  };

  // set keyboard handlers
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (currentAudio && e.key === " ") {
        e.preventDefault();
        if (isPlaying) {
          pause();
        } else {
          play();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [currentAudio, isPlaying]);

  // attach media handlers
  useEffect(() => {
    const onPause = () => {
      setIsPlaying(false);
    };
    const onPlay = () => {
      setIsPlaying(true);
    };
    const onEnded = () => {
      setIsPlaying(false);
    };
    currentAudio?.addEventListener("pause", onPause);
    currentAudio?.addEventListener("play", onPlay);
    currentAudio?.addEventListener("ended", onEnded);
    return () => {
      currentAudio?.removeEventListener("pause", onPause);
      currentAudio?.removeEventListener("play", onPlay);
      currentAudio?.removeEventListener("ended", onEnded);
    };
  }, [currentAudio, isPlaying]);

  // start timer on play
  useEffect(() => {
    if (isPlaying) {
      startTimer();
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (currentAudio) {
      setCurrentTime(0);
      currentAudio.volume = currentVolume;
      currentAudio.playbackRate = currentPlayRate;
      if (autoplay) play();
    }
  }, [currentAudio]);

  const startTimer = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (currentAudio) setCurrentTime(currentAudio.currentTime);
    }, UPDATE_INTERVAL);
  };

  const setAudio = (src: string) => {
    if (isPlaying) pause();
    const el = new Audio();
    el.preload = preload ? "auto" : "none";
    el.src = src;
    setCurrentAudio(el);
    setCurrentTime(0);
  };

  const play = () => {
    if (currentAudio && !playPromise) {
      const promise = currentAudio.play();
      setPlayPromise(promise);
      setIsPlaying(true);
    }
  };

  const pause = () => {
    if (currentAudio && playPromise) {
      playPromise
        .then(() => {
          currentAudio.pause();
          setPlayPromise(null);
          setIsPlaying(false);
        })
        .catch(() => {
          console.error("Failed to pause audio");
        });
    }
  };

  const stop = () => {
    if (currentAudio && playPromise) {
      playPromise
        .then(() => {
          currentAudio.pause();
          setPlayPromise(null);
          setIsPlaying(false);
          setCurrentAudio(null);
        })
        .catch(() => {
          console.error("Failed to stop audio");
        });
    }
  };

  const seek = (time: number) => {
    if (currentAudio) {
      currentAudio.currentTime = time;
      setCurrentTime(time);
    }
  };

  const setVolume = (volume: number) => {
    setCurrentVolume(volume);
    if (currentAudio) currentAudio.volume = volume;
  };

  const setPlayRate = (rate: number) => {
    setCurrentPlayRate(rate);
    if (currentAudio) currentAudio.playbackRate = rate;
  };

  return {
    audioData,
    setAudio,
    play,
    pause,
    stop,
    seek,
    setTitle,
    setVolume,
    setPlayRate,
  };
};
