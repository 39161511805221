import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { classNames } from "react-extras";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { showSuccess } from "src/actions/app";
import { submitEpisodesFeedImport } from "src/actions/feed_import";
import { fetchShow } from "src/action_managers/shows";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import Button from "src/components/lib/button";
import ClipboardAction from "src/components/lib/clipboard-action";
import ContextMenu from "src/components/lib/context_menu";
import ExpandableText from "src/components/lib/expandable_text";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import ProgressBar from "src/components/lib/progress_bar";
import ShowPageNav from "src/components/lib/show_page_nav";
import StatsWidgetContainerWrapper from "src/components/lib/stats/stats_widget_container_wrapper";
import AddTeamSeatToPodcast from "src/components/modals/add_teamSeat_to_podcast";
import { distributionKeys } from "src/constants/distribution";
import { permissionTypes } from "src/constants/permission_roles";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { useGetShowDetail, useShowNotification } from "src/hooks/shows";
import { useGetImportJobs } from "src/hooks/user";
import { getMegapodURL } from "src/lib/config";
import { getImportJob, getImportJobDetail } from "src/lib/import_jobs_helper";
import { addNewShowDefaultPermissions, useCanAccessBound } from "src/lib/permissions";
import { isShowRedirected, isShowRemote } from "src/lib/show";
import { minimumAllowedTier } from "src/lib/tier-utils";
import { Distributions } from "src/reducers/distributions";
import { IShow } from "src/reducers/shows";
import { tierLevel } from "src/reducers/user/types";
import ShowPageWrapper from "../show_page_wrapper";
import {
  DistributionWarningPanel,
  RedirectWarningPanel,
  VastUnverifiedWarningPanel,
} from "./warning_panels";

export default function ShowDetailsPage() {
  const { showUUID } = useParams<{ showUUID: string }>();
  const canAccess = useCanAccessBound();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useReduxDispatch();
  const [showTeamSeatsModal, setTeamSeatsModal] = useState(false);

  const {
    show,
    episodes = {},
    isLoading,
    distributions,
  } = useGetShowDetail(showUUID, {
    withEpisodes: true,
    withCampaigns: true,
    withDistributions: true,
  });
  const { tier } = useSelectorTS((state) => state.user);
  const { credentials } = useSelectorTS((state) => state.credentials);
  const { showNotification } = useShowNotification(showUUID);
  const { importJobs } = useGetImportJobs();
  const importJob = getImportJob(importJobs, show);
  const importJobDetails = getImportJobDetail(importJob, show);

  const userPermissionsCan = useMemo(() => {
    return {
      edit: canAccess(permissionTypes.editShow, showUUID),
      deleteOrRedirect: canAccess(permissionTypes.redirectShow, showUUID),
      viewExclusiveContent: canAccess(permissionTypes.viewExclusiveContent, showUUID),
      viewDonations: canAccess(permissionTypes.viewDonations, showUUID),
      viewStats: canAccess(permissionTypes.viewStats, showUUID),
      viewRap: canAccess(permissionTypes.viewRap, showUUID),
      viewEmbedPlayer: canAccess(permissionTypes.embedPlayer, showUUID),
      viewTeamSeats: minimumAllowedTier(tierLevel.pro, tier, false) && credentials?.length > 1,
      editDistribution: canAccess(permissionTypes.editDistribution, showUUID),
    };
  }, [showUUID, canAccess]);

  // track when new shows are added to conjure team seats modal - TODO: move this logic elsewhere?
  useEffect(() => {
    const { state }: { state: any } = location;
    if (state?.newShowUUID?.length > 0 && !showTeamSeatsModal) {
      if (userPermissionsCan.viewTeamSeats) {
        setTeamSeatsModal(true);
      }

      // Add explicit default permission for this new show to all credentials
      addNewShowDefaultPermissions(state.newShowUUID);
      // Remove state from route location
      history.replace(location.pathname, {});
    }
  }, [location]);

  const removeRouteMenuText = isShowRedirected(show) ? "Restore Podcast" : "Remove Podcast";
  const episodeCount = Object.keys(episodes).length;
  const isShowDemo = show?.isDemo;
  const showIsRemote = isShowRemote(show);
  const isShowVerified =
    show &&
    (!show.importedURL || show.redirectVerified === true) &&
    episodeCount > 0 &&
    !importJobDetails.shouldShowProgressBar;
  const hasMissingKeys =
    distributions &&
    distributionKeys.some(
      (item) => !distributions[item.key as Distributions] && !item.skipValidation
    );

  // UI FLAGS
  const showProgressBar = importJobDetails.shouldShowProgressBar;
  const showRedirectWarning =
    show &&
    !showProgressBar &&
    !showIsRemote &&
    importJobDetails.hasJobStarted &&
    !show.redirectVerified;
  const showDistributionWarning =
    show &&
    !showIsRemote &&
    !showProgressBar &&
    !showRedirectWarning &&
    hasMissingKeys &&
    episodeCount > 0 &&
    userPermissionsCan.editDistribution;
  const showVastUnverifiedWarning =
    show && showIsRemote && show.vastURLs?.every((v) => v.verified === false);

  const disabledTooltip =
    "This feature will become available when the redirect is complete or your first episode is published.";

  const handleDemoImport = () => {
    if (show && show.importedURL) {
      dispatch(submitEpisodesFeedImport(showUUID, show.importedURL, 128)).then(
        (res) => res.status === 200 && (dispatch(fetchShow(showUUID)) as any)
      );
    }
  };

  const contextMenuItems: Record<string, any> = {
    "VAST Setup": {
      onSelect: () => history.push(`/shows/${showUUID}/vast-setup`),
      hide: !showIsRemote,
    },
    "Podcast Settings": {
      onSelect: () => history.push(`/shows/${showUUID}/edit`),
      hide: !userPermissionsCan.edit || isShowRedirected(show) || showIsRemote,
    },
    "View Team Members": {
      onSelect: () => setTeamSeatsModal(true),
      hide: !userPermissionsCan.edit || !userPermissionsCan.viewTeamSeats || isShowRedirected(show),
    },
    [removeRouteMenuText]: {
      onSelect: () => history.push(`/shows/${show?.uuid}/remove`),
      hide: !userPermissionsCan.deleteOrRedirect,
    },
  };

  return (
    <ShowPageWrapper className="show-details-page" show={show} isLoading={isLoading}>
      <Breadcrumbs
        crumbs={[
          { path: "/shows", name: "All Podcasts" },
          { path: `/shows/${showUUID}`, name: show?.title, active: true },
        ]}
      />

      {isLoading && <Loading />}
      {show && showProgressBar && <ImportProgressBar show={show} details={importJobDetails} />}
      {show && showRedirectWarning && !showIsRemote && <RedirectWarningPanel show={show} />}
      {show && showDistributionWarning && <DistributionWarningPanel show={show} />}
      {show && showVastUnverifiedWarning && <VastUnverifiedWarningPanel show={show} />}

      {show && (
        <Row>
          <ShowPageWrapper.Sidebar />
          <ShowPageWrapper.Body>
            <ShowPageWrapper.Header title={show?.title}>
              {!isShowDemo && (userPermissionsCan.edit || userPermissionsCan.deleteOrRedirect) && (
                <ContextMenu noCircle={true} menuItems={contextMenuItems} />
              )}
            </ShowPageWrapper.Header>

            <Page.Section
              className={isShowRedirected(show) ? "pointer-events-none semi-transparent" : ""}>
              <ExpandableText dangerousText={show.description} heightToWidth={4} />
              {!isShowDemo && !showIsRemote && (
                <div className={classNames("m-txs fs-15 flex-column-container")}>
                  {show && <ShowPageCopyLinks show={show} />}
                </div>
              )}
              {isShowDemo && (
                <div className="flex-row-container justify-center w-100">
                  <Button type="primary" size="large" onClick={handleDemoImport}>
                    Import My Show
                  </Button>
                </div>
              )}
            </Page.Section>

            {!isShowDemo && (
              <>
                <Page.Divider className="m-ts" />

                <Page.Section
                  className={isShowRedirected(show) ? "pointer-events-none semi-transparent" : ""}>
                  <Row gutter={16}>
                    <Col xs={24} md={12}>
                      <ShowPageNav
                        title="Episodes"
                        subtitle={
                          showIsRemote
                            ? "Manage episodes for this podcast"
                            : "Manage, add, and edit episodes for this podcast"
                        }
                        linkTo={`/shows/${show.uuid}/ep`}
                      />
                    </Col>
                    {!showIsRemote && (
                      <Col xs={24} md={12}>
                        <ShowPageNav
                          title="Distribution"
                          subtitle="List this podcast on Apple Podcasts, Spotify, and more"
                          linkTo={`/shows/${show.uuid}/distribution`}
                          disabled={!isShowVerified}
                          disabledTooltip={disabledTooltip}
                        />
                      </Col>
                    )}

                    {userPermissionsCan.viewExclusiveContent && !showIsRemote && (
                      <Col xs={24} md={12}>
                        <ShowPageNav
                          title="Exclusive Content"
                          subtitle="Allow listeners to get access to your exclusive content"
                          linkTo={`/shows/${show.uuid}/i/exclusive-content`}
                          disabled={!isShowVerified}
                          disabledTooltip={disabledTooltip}
                        />
                      </Col>
                    )}

                    {userPermissionsCan.viewDonations && !showIsRemote && (
                      <Col xs={24} md={12}>
                        <ShowPageNav
                          title="Donations"
                          subtitle="Allow listeners to donate one-time or monthly"
                          linkTo={`/shows/${show.uuid}/i/donations`}
                          disabled={!isShowVerified}
                          disabledTooltip={disabledTooltip}
                        />
                      </Col>
                    )}

                    {userPermissionsCan.viewStats && (
                      <Col xs={24} md={12}>
                        <ShowPageNav
                          title="Analytics"
                          subtitle="View analytics about this podcast and its episodes"
                          linkTo={show.isRemote ? `/stats/earnings` : `/stats/downloads`}
                          linkState={{ defaultStatsShowUUID: showUUID }}
                          disabled={!isShowVerified && !showIsRemote}
                          disabledTooltip={showIsRemote ? undefined : disabledTooltip}
                        />
                      </Col>
                    )}

                    {userPermissionsCan.viewRap && (
                      <Col xs={24} md={12}>
                        <ShowPageNav
                          title="Advertising"
                          subtitle="View advertising offers that you've received for this podcast"
                          linkTo={`/shows/${show.uuid}/advertising`}
                          disabled={!isShowVerified && !showIsRemote}
                          disabledTooltip={showIsRemote ? undefined : disabledTooltip}
                          isAlerted={showNotification}
                        />
                      </Col>
                    )}

                    {userPermissionsCan.viewEmbedPlayer && !showIsRemote && (
                      <Col xs={24} md={12}>
                        <ShowPageNav
                          title="Embed Podcast Player"
                          subtitle="Customize your podcast player and embed it on your website"
                          linkTo={`/shows/${show.uuid}/embed-show`}
                          disabled={!isShowVerified}
                          disabledTooltip={disabledTooltip}
                        />
                      </Col>
                    )}
                  </Row>
                </Page.Section>
              </>
            )}
          </ShowPageWrapper.Body>

          {show && userPermissionsCan.viewStats && !showIsRemote && (
            <Row>
              <Col xs={24}>
                <StatsWidgetContainerWrapper uuid={showUUID} uuidType="showUUID" />
              </Col>
            </Row>
          )}
        </Row>
      )}

      <AddTeamSeatToPodcast
        visible={showTeamSeatsModal}
        setVisible={setTeamSeatsModal}
        showUUID={showUUID}
      />
    </ShowPageWrapper>
  );
}

const ImportProgressBar = ({ show, details }: { show: IShow; details: any }) => {
  return (
    <div className="m-bm">
      <ProgressBar
        title={`Importing and processing ${show.title}...`}
        current={details.numEpisodesImported}
        total={details.totalJobs}
      />
    </div>
  );
};

const ShowPageCopyLinks = ({ show }: { show: IShow }) => {
  const dispatch = useReduxDispatch();
  const copyToClipboard = useCallback((url: string, successMessage: string) => {
    new ClipboardAction({
      text: url,
      container: document.body,
    });
    dispatch(showSuccess(successMessage));
  }, []);

  const publicURL = `${getMegapodURL()}shows/${show.uuid}`;
  const rssURL = show?.rssURL;

  return (
    <>
      <small className="flex-row-container align-center">
        Public Podcast Page
        <Button
          type="link"
          className="p-a0 svg-button m-hxxs"
          onClick={() => copyToClipboard(publicURL, "Copied to clipboard!")}>
          <CopyOutlined style={{ color: "#577D9E" }} />
        </Button>
        <a href={publicURL} target="_blank" rel="noreferrer">
          <Button type="link" className="p-a0 svg-button">
            <LinkOutlined style={{ color: "#577D9E" }} />
          </Button>
        </a>
      </small>
      {rssURL && (
        <small className="flex-row-container align-center">
          RSS Feed{" "}
          <Button
            type="link"
            className="p-a0 svg-button m-hxxs"
            onClick={() => copyToClipboard(rssURL, "Copied to clipboard!")}>
            <CopyOutlined style={{ color: "#577D9E" }} />
          </Button>{" "}
          <a href={rssURL} target="_blank" rel="noreferrer">
            <Button type="link" className="p-a0 svg-button">
              <LinkOutlined style={{ color: "#577D9E" }} />
            </Button>
          </a>
        </small>
      )}
    </>
  );
};
