import { ReduxDispatch } from "src/hooks/redux-ts";
import DefaultActionManager from "../action_managers/default_action_manager";
import { IShow } from "src/reducers/shows";

export const showFeedImportActionName = "SHOW_FEED_IMPORT";
export const episodeFeedImportActionName = "EPISODE_FEED_IMPORT";
export const validateFeedURLActionName = "VALIDATE_FEED_URL";

interface IShowFeedImportRequest {
  feedURL: string;
  isRemote?: boolean;
}

export function validateFeedURLForRemote({ feedURL }: { feedURL: string }) {
  return new DefaultActionManager({
    auth: true,
    route: `vast/remote-host?feedURL=${encodeURIComponent(feedURL)}`,
    actionName: showFeedImportActionName,
  }).run();
}

export function submitShowFeedImport({ feedURL, isRemote = false }: IShowFeedImportRequest) {
  return new ShowFeedImportActionManager({
    auth: true,
    method: "post",
    route: "shows/import",
    body: { feedURL, isRemote },
    actionName: showFeedImportActionName,
  }).run();
}

export function submitEpisodesFeedImport(showUUID: string, feedURL: string, bitrate: number) {
  return new EpisodeFeedImportActionManager({
    auth: true,
    method: "post",
    route: "shows/" + showUUID + "/import",
    body: {
      feedURL,
      bitrate,
    },
    actionName: episodeFeedImportActionName,
  }).run();
}

class EpisodeFeedImportActionManager extends DefaultActionManager<object> {
  responseOK(dispatch: ReduxDispatch, json: any) {
    super.responseOK(dispatch, json);
    this.showSuccess(
      dispatch,
      "Success! Your podcast has been created and episodes are being imported now.  Should just take a few minutes."
    );
  }
}

class ShowFeedImportActionManager extends DefaultActionManager<IShow> {
  response400(dispatch: ReduxDispatch, json: any): any {
    super.response400(dispatch, json);

    const errorMessage = `Looks like something went wrong importing your feed: ${json.message}. Check your podcast RSS feed and give it another go. If that doesn't work contact support, we're happy to help.`;
    this.showError(dispatch, errorMessage);
  }
}
