import { Col, Divider, Dropdown, MenuProps, Row, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { ExpandableConfig } from "antd/es/table/interface";
import dayjs from "dayjs";
import React, { MouseEvent, useState } from "react";
import { classNames } from "react-extras";
import { Link, useHistory } from "react-router-dom";
import { AlbumArt } from "src/components/lib/album_art";
import Button from "src/components/lib/button";
import RCButton from "src/components/lib/button/button";
import ContextMenu from "src/components/lib/context_menu/context_menu";
import ExclusiveTag from "src/components/lib/exclusive_tag/exclusive_tag";
import RCTable from "src/components/lib/tables/rc_table/rc_table";
import CancelVettingInCartModal from "src/components/modals/cancel_vetting_in_cart";
import { RemovePodcastFromCart } from "src/components/modals/remove_podcast_from_cart";
import SendVettingReminderModal from "src/components/modals/send_vetting_reminder";
import { CampaignItemVettingStatus } from "src/components/modals/vetting_form_modal/vetting_form_modal_helpers";
import { CampaignItemStateDraft, CampaignItemStateToFriendly } from "src/constants/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { SEND_CAMPAIGN_BLOCKED } from "src/constants/user_attributes";
import { OTHER_OPTION_VALUE } from "src/constants/vetting";
import { useSelectorTS } from "src/hooks/redux-ts";
import { useGetVettingInvitations, useGetVettingResponseMatrix } from "src/hooks/vetting";
import { getAverageCPM } from "src/lib/campaigns";
import { formatMoney } from "src/lib/format-money";
import { canAdvertiserAccess } from "src/lib/permissions";
import { userHasAttribute } from "src/lib/user";
import { isUUID } from "src/lib/uuid";
import {
  isInvitationPendingOnItem,
  isInvitationsPendingInCart,
  sortVettingInvitationsByStatus,
} from "src/lib/vetting";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShow } from "src/reducers/public_show";
import { VettingForm, VettingInvitation, VettingQuestion } from "src/reducers/vetting";
import { downloadCartInfo } from "./cart_csv";

interface IProps {
  campaign?: ICampaign;
  className?: string;
  hasSentAnyItems: boolean;
  updateCampaignMeta?: any;
  getDistribution?: any;
  setOpenVettingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CampaignDashboardCart({
  campaign,
  className,
  updateCampaignMeta,
  getDistribution,
  hasSentAnyItems,
  setOpenVettingModal,
}: IProps) {
  const history = useHistory();
  const campaignUUID = campaign?.uuid ?? "";

  const campaignItems = useSelectorTS(
    (state) =>
      (state.campaignItems.campaignItemByCampaignUUID?.[campaignUUID] ?? [])?.map(
        (itemUUID) => state.campaignItems.campaignItems?.[itemUUID]
      )
  );
  const drafts = campaignItems.filter((item) => item.state === CampaignItemStateDraft);

  const responseMatrix = useGetVettingResponseMatrix(campaign?.uuid);
  const { invitationsByCampaignUUID } = useGetVettingInvitations(campaign?.uuid);

  const publicShows = useSelectorTS((state) => state.publicShows);

  const showByCartItemUUID =
    drafts?.reduce(
      (accu, curr) => {
        accu[curr.uuid] = publicShows?.[curr.showUUID];
        return accu;
      },
      {} as { [campaignItemUUID: string]: PublicShow }
    ) ?? {};

  const [reminderModalVisible, setReminderModalVisible] = useState(false);
  const [cancelVettingModalVisible, setCancelVettingModalVisible] = useState(false);

  const count = (drafts && drafts.length) || 0;
  const canShowAddButton = canAdvertiserAccess(permissionTypes.browsePodcasts, campaign);

  const canVetPodcasts = canAdvertiserAccess(permissionTypes.sendCampaign, campaign);

  const isVettingAlreadyInProgress = isInvitationsPendingInCart(
    drafts,
    invitationsByCampaignUUID[campaign?.uuid ?? ""]
  );

  const AddPodcastsButton = ({ className }: { className?: string }) => {
    if (!canShowAddButton) return null;

    return (
      <Button
        type={count ? "secondary" : "primary"}
        size="small"
        className={className}
        onClick={() => history.push(`/campaigns/${campaign?.uuid}/select`)}>
        Add Podcasts
      </Button>
    );
  };

  const BuildScheduleButton = ({ className }: { className?: string }) => {
    const canShowSchedulerButton = canAdvertiserAccess(permissionTypes.sendCampaign, campaign);

    if (!canShowSchedulerButton) return null;

    return (
      <Button
        type="primary"
        size="small"
        className={className}
        onClick={() => {
          history.push(`/campaigns/${campaign?.uuid}/schedule`);
        }}>
        Build Schedule
      </Button>
    );
  };

  const dropdownItems: MenuProps["items"] = [];
  if (canShowAddButton) {
    dropdownItems.push({
      key: "Add Podcasts",
      label: <Link to={`/campaigns/${campaign?.uuid}/select`}>Add Podcasts</Link>,
    });
  }

  dropdownItems.push({
    key: "Download Cart",
    label: (
      <span
        onClick={() => {
          if (campaign && drafts) {
            downloadCartInfo({
              campaign,
              campaignItems: drafts,
              publicShows,
              updateCampaignMeta,
              getDistribution,
              campaignVettingResponses: responseMatrix,
            });
          }
        }}>
        Download Cart
      </span>
    ),
  });

  if (canVetPodcasts) {
    if (isVettingAlreadyInProgress) {
      dropdownItems.push(
        {
          key: "Cancel Vetting",
          label: (
            <span
              onClick={() => {
                setCancelVettingModalVisible(true);
              }}>
              Cancel Vetting
            </span>
          ),
        },
        {
          key: "Send Reminders",
          label: <span onClick={() => setReminderModalVisible(true)}>Send Reminders</span>,
        }
      );
    } else {
      dropdownItems.push({
        key: "Vet Cart",
        label: (
          <span
            onClick={() => {
              setOpenVettingModal(true);
            }}>
            Vet Cart
          </span>
        ),
      });
    }
  }

  return (
    <Row className={classNames("campaign-cart", className)}>
      <Col className="flex-column-container width-100">
        <div className="flex-row-container align-center justify-space-between p-txs p-ls p-rs">
          <h3 className="m-b0">Cart ({count})</h3>
          {count > 0 ? (
            <div className="flex-row-container campaign-cart-cta">
              <Dropdown menu={{ items: dropdownItems }}>
                <Button type={"secondary"} size="small">
                  Cart Options
                </Button>
              </Dropdown>
              <BuildScheduleButton className="m-lxs" />
            </div>
          ) : (
            <AddPodcastsButton className="btn-small" />
          )}
        </div>

        <CartPodcastsDisplay
          draftItems={drafts}
          showByCartItemUUID={showByCartItemUUID}
          campaign={campaign}
          hasSentAnyItems={hasSentAnyItems}
          setOpenVettingModal={setOpenVettingModal}
        />
        <SendVettingReminderModal
          campaignUUID={campaign?.uuid}
          visible={reminderModalVisible}
          setVisible={setReminderModalVisible}
        />
        <CancelVettingInCartModal
          campaignUUID={campaign?.uuid}
          visible={cancelVettingModalVisible}
          setVisible={setCancelVettingModalVisible}
        />
      </Col>
    </Row>
  );
}

interface ICartPodcastsDisplay {
  showByCartItemUUID: Record<string, PublicShow>;
  draftItems?: ICampaignItem[];
  campaign?: ICampaign;
  hasSentAnyItems: boolean;
  setOpenVettingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CartPodcastsDisplay = ({
  draftItems,
  showByCartItemUUID,
  campaign,
  hasSentAnyItems,
  setOpenVettingModal,
}: ICartPodcastsDisplay) => {
  const count = Array.isArray(draftItems) ? draftItems.length : 0;

  const [isCartExpanded, setCartExpanded] = useState(false);

  const { invitationsByCampaignItemUUID } = useGetVettingInvitations(campaign?.uuid);

  const allPendingInvites =
    draftItems
      ?.reduce((accu, { uuid }) => {
        const [flag, pendingInvites] = isInvitationPendingOnItem(
          invitationsByCampaignItemUUID?.[uuid]
        );
        if (flag) {
          accu = [...accu, ...pendingInvites];
        }
        return accu;
      }, [] as VettingInvitation[])
      .sort((a, b) => b.sentAt - a.sentAt) ?? [];

  const numOfItemsUnVetted =
    draftItems?.reduce((accu, { uuid }) => {
      const invitations = invitationsByCampaignItemUUID[uuid] ?? [];
      if (invitations?.length === 0) {
        accu++;
      }
      return accu;
    }, 0) ?? 0;

  const latestVettingDueDate = allPendingInvites[0]?.responseDueAt;

  const isVettingInvitePendingInCart = allPendingInvites?.length > 0;

  //Handlers
  const handleClickShowMore = (event: MouseEvent) => {
    event.preventDefault();
    setCartExpanded((prev) => !prev);
  };

  const handleClickVetNowLink = () => {
    setOpenVettingModal(true);
  };

  if (count > 0) {
    return (
      <>
        <Divider className="m-txs m-bxs" orientation="left" orientationMargin={"24px"}>
          {isVettingInvitePendingInCart && (
            <div className="flex-column-container align-start justify-center fs-12">
              <span>
                Vetting in progress until {dayjs.unix(latestVettingDueDate).format("M/DD")}
              </span>
              {numOfItemsUnVetted > 0 && (
                <span className="fake-link" onClick={handleClickVetNowLink}>
                  {numOfItemsUnVetted} Unvetted Podcasts: Vet Now
                </span>
              )}
            </div>
          )}
        </Divider>
        <div className="flex-column-container align-center">
          {isCartExpanded ? (
            <CartDraftItemTable draftItems={draftItems} campaign={campaign} />
          ) : (
            <div className="flex-row-container align-center p-bxs p-ls p-rs flex-wrap width-100">
              {draftItems?.map((item) => {
                const show = showByCartItemUUID[item.uuid];

                return (
                  <Link to={`/browse/${show?.uuid}`} key={item.uuid}>
                    <Tooltip title={<span className="line-clamp-2">{show?.title}</span>}>
                      <AlbumArt
                        imageSize={"64x64"}
                        className={"small m-rxs m-bxxs"}
                        src={show?.imageURL}
                        title={show?.title}
                      />
                    </Tooltip>
                  </Link>
                );
              })}
            </div>
          )}

          <span onClick={handleClickShowMore} className="m-axxs ellipsis fake-link">
            {isCartExpanded ? `Hide Details` : "View Details"}
          </span>
        </div>
      </>
    );
  }

  if (!!draftItems || count === 0) {
    return (
      <>
        <Divider className="m-txs m-bxs" />
        <div className="flex-column-container align-center p-bxs p-ls p-rs">
          {!hasSentAnyItems ? (
            <>
              <span className="text-subtle">Your campaign has been created!</span>
              <span className="text-subtle">
                Now, invite the podcasts that you think would be a good fit for your campaign.
              </span>
            </>
          ) : (
            <span className="text-subtle">
              Invite additional podcasts that are a good fit for your campaign.
            </span>
          )}
        </div>
      </>
    );
  }

  return null;
};

interface ICartDraftItemTable {
  draftItems?: ICampaignItem[];
  campaign?: ICampaign;
}

const CartDraftItemTable = ({ draftItems, campaign }: ICartDraftItemTable) => {
  const publicShows = useSelectorTS((state) => state.publicShows);
  const { invitationsByCampaignItemUUID } = useGetVettingInvitations(campaign?.uuid);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const { formsByUUID } = useSelectorTS((state) => state.vetting);
  const [itemUUIDToRemove, setItemUUIDToRemove] = useState<string | undefined>();

  const handleExpand =
    (campaignItemUUID = "") =>
    () => {
      if (isUUID(campaignItemUUID)) {
        const isOpen = expandedRows.includes(campaignItemUUID);
        const newRows = isOpen
          ? expandedRows.filter((uuid) => uuid !== campaignItemUUID)
          : [...expandedRows, campaignItemUUID];

        setExpandedRows(newRows);
      }
    };

  const columns: ColumnsType<ICampaignItem> = [
    {
      key: "Podcast",
      title: "Podcast",
      dataIndex: "showUUID",
      width: "40%",
      sorter: (a, b) => {
        const showA = publicShows[a?.showUUID];
        const showB = publicShows[b?.showUUID];
        return (showA?.title ?? "").localeCompare(showB?.title ?? "");
      },
      render: (_, campaignItem) => {
        const show = publicShows[campaignItem?.showUUID];
        const totalExists =
          typeof campaignItem?.totalBudget === "number" && campaignItem?.totalBudget > 0;
        const total = formatMoney(campaignItem.totalBudget);
        const cpm = formatMoney(getAverageCPM({ show, campaign, campaignItem }));

        const isOpen = expandedRows.includes(campaignItem.uuid);
        const invitations = Array.isArray(invitationsByCampaignItemUUID[campaignItem?.uuid])
          ? invitationsByCampaignItemUUID[campaignItem?.uuid]
          : [];

        // Will expand if podcaster has submitted any responses to a previously vetting invite
        const showExpand =
          invitations.some((invite) => {
            return (invite?.responses ?? [])?.length > 0;
          }) && !userHasAttribute(SEND_CAMPAIGN_BLOCKED);

        return (
          <div className="flex-row-container align-center justify-start">
            <Link to={`/browse/${show?.uuid}`}>
              <AlbumArt
                src={show?.imageURL}
                style={{ width: 48, marginRight: 12 }}
                imageSize={"64x64"}
              />
            </Link>
            <div className="flex-column-container">
              <strong className="line-clamp-1 title" title={show?.title}>
                {show?.title}
              </strong>

              <div className="flex-row-container align-center m-txxxs">
                <span className="m-rxxs fs-13">
                  {totalExists && (
                    <>
                      Total: <strong>{total}</strong> |
                    </>
                  )}{" "}
                  CPM: <strong>{cpm}</strong>
                </span>
                {showExpand && (
                  <RCButton type="link" size="small" onClick={handleExpand(campaignItem?.uuid)}>
                    {isOpen ? "Hide Responses" : "Show Responses"}
                  </RCButton>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      key: "Vetting",
      title: "Vetting",
      dataIndex: "uuid",
      align: "center",
      width: "40%",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        const vettingA = invitationsByCampaignItemUUID[a?.uuid] ?? [];
        const vettingB = invitationsByCampaignItemUUID[b?.uuid] ?? [];
        return sortVettingInvitationsByStatus(vettingA, vettingB);
      },
      render: (_, campaignItem) => {
        const vettingInvitations = invitationsByCampaignItemUUID[campaignItem?.uuid] ?? [];
        return <CampaignItemVettingStatus vettingInvitations={vettingInvitations} />;
      },
    },
    {
      key: "Status",
      title: "status",
      dataIndex: "uuid",
      width: "100px",
      align: "center",
      render: (_, campaignItem) => {
        return (
          <ExclusiveTag className="no-wrap tag-draft">
            {CampaignItemStateToFriendly[campaignItem?.state]}
          </ExclusiveTag>
        );
      },
    },
    {
      key: "Menu",
      title: " ",
      width: "50px",
      render: (_, campaignItem) => {
        const menuItems = {
          "Remove Podcast": {
            title: "Remove Podcast",
            onSelect: () => {
              setItemUUIDToRemove(campaignItem.uuid);
            },
          },
        };

        return <ContextMenu noCircle menuItems={menuItems} />;
      },
    },
  ];

  const dataSource = draftItems?.map((item) => {
    return { key: item.uuid, ...item };
  });

  const expandedContent: ExpandableConfig<ICampaignItem>["expandedRowRender"] = (
    record,
    index,
    indent,
    expanded
  ) => {
    const invitations = invitationsByCampaignItemUUID[record?.uuid];

    return (
      <div className="flex-column-container align-start">
        {invitations?.map((invitation) => {
          const form = formsByUUID[invitation.vettingFormUUID];
          return <QuestionAndResponse key={invitation.uuid} invitation={invitation} form={form} />;
        })}
      </div>
    );
  };

  return (
    <>
      <RCTable<ICampaignItem>
        className="width-100 p-hxs"
        columns={columns}
        dataSource={dataSource}
        expandable={{
          expandIcon: () => null,
          expandedRowKeys: expandedRows,
          expandedRowRender: expandedContent,
          showExpandColumn: false,
        }}
        pagination={false}
        scroll={{ x: "100%", y: 600 }}
      />
      <RemovePodcastFromCart
        itemUUIDToRemove={itemUUIDToRemove}
        setItemUUIDToRemove={setItemUUIDToRemove}
      />
    </>
  );
};

interface IQuestionAndResponse {
  invitation: VettingInvitation;
  form: VettingForm;
}

const QuestionAndResponse = ({ invitation, form }: IQuestionAndResponse) => {
  const questionMap = form.questions.reduce(
    (accu, curr) => {
      const { uuid } = curr;
      accu[uuid] = { ...curr, answer: [] };

      return accu;
    },
    {} as Record<string, VettingQuestion & { answer: string[]; other?: string }>
  );

  invitation.responses?.forEach(({ questionUUID, ...rest }) => {
    if (questionMap[questionUUID]) {
      questionMap[questionUUID] = { ...questionMap[questionUUID], ...rest };
    }
  });

  const questionsArray = Object.values(questionMap).sort((a, b) => a.order - b.order);

  const dateSent =
    typeof invitation?.sentAt === "number"
      ? `(Vetted ${dayjs.unix(invitation?.sentAt).format("M/DD")})`
      : "";

  return (
    <div className="m-bxxxs">
      <h5 className="m-bxxs">Responses {dateSent}</h5>
      <ol className="p-lxs">
        {questionsArray.map((question) => {
          const answers = (question?.answer ?? []).filter((value) => value !== OTHER_OPTION_VALUE);
          if (typeof question?.other === "string") answers.push(`Other: ${question.other}`);

          return (
            <li className="p-lxxxs m-bxxs" key={question.uuid}>
              <h5>{question.prompt}</h5>
              <p className="m-b0">{answers.join(", ")}</p>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
