import { useEffect } from "react";
import { useReduxDispatch, useSelectorTS } from "./redux-ts";
import { getCustomAudioForUser } from "src/action_managers/audio_management";
import { isEmpty } from "lodash";

const useGetCustomAudios = () => {
  const dispatch = useReduxDispatch();
  const { user, isLoading: isUserLoading } = useSelectorTS((state) => state.user);
  const { isLoading, customAudios, initialFetched } = useSelectorTS((state) => state.customAudios);

  useEffect(() => {
    if (!isUserLoading && user) {
      if (!initialFetched && !isLoading) {
        dispatch(getCustomAudioForUser(user));
      }
    }
  }, [initialFetched, isLoading, isUserLoading, isEmpty(user)]);

  return { customAudios, isLoading };
};

export { useGetCustomAudios };
