import React from "react";
import ExclusiveTag from "../exclusive_tag";
import { RadioInput } from "../radio-input/radio-input";
import "./radio-select-frame.scss";

const RadioSelectFrame = (props) => {
  const { title, description, isSelected = false, onClick, tag, footnote, disabled } = props;

  let classes = "radio-select-frame";
  if (isSelected) {
    classes += " radio-select-frame__selected";
  } else if (disabled) {
    classes += " radio-select-frame__disabled";
  }

  return (
    <div className={classes} onClick={onClick}>
      <div className="radio-select-frame__input">
        <RadioInput readOnly name={title} id={title} checked={isSelected} />
      </div>
      <div className="radio-select-frame__text">
        <div className="flex-row-container">
          <h3 className="bold m-a0 m-bxxxs no-padding">{title}</h3>
          {tag && <ExclusiveTag className="m-lxxs">{tag}</ExclusiveTag>}
        </div>
        <div>{description}</div>
        {footnote && <div className={"small bold text-subtle"}>{footnote}</div>}
      </div>
    </div>
  );
};

export const RadioSelectFrameField = (props) => {
  const { options, value, onChange } = props;

  return (
    <>
      {options.map((option) => {
        const { title, description, tag, optionValue, disabled, footnote } = option;

        return (
          <RadioSelectFrame
            disabled={disabled}
            isSelected={optionValue === value}
            key={title}
            title={title}
            description={description}
            tag={tag}
            onClick={() => {
              !disabled && onChange(optionValue);
            }}
            footnote={footnote}
          />
        );
      })}
    </>
  );
};

export default RadioSelectFrame;
