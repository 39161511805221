import { Col, DatePicker, DatePickerProps, Row, TableProps } from "antd";
import dayjs from "dayjs";
import Moment from "moment";
import numeral from "numeral";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import RCTable from "src/components/lib/tables/rc_table";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { localLongDate } from "src/lib/date";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShow, PublicShowsReduxState } from "src/reducers/public_show";
import useResizeObserver from "use-resize-observer";
import {
  getAverageCPM,
  getBudget,
  getDailyImpressions,
  getImpressionsFromBudget,
} from "../../../lib/campaigns";
import LoadingImage from "../../lib/image";
import { NegotiateRateV2 } from "../../lib/tables/campaign_show_list_table";
import { cloneDateAs3amEST, getCampaignMinimumStartDate } from "./campaign_schedule_podcast_utils";
import {
  createGetItemInfo,
  CustomBudgetCell,
  CustomImpressionsCell,
  CustomShowRemoveCell,
  CustomTimelineCell,
  MatchBudget,
  RCRangePicker,
} from "./continuous_scheduler_helpers";
import { CampaignItemVettingStatus } from "../vetting_form_modal/vetting_form_modal_helpers";
import { useGetVettingInvitations } from "src/hooks/vetting";
import { useForecastEndDates, useForecastImpressions } from "src/hooks/streamulator";

interface IContinuousScheduler {
  budgets: { [campaignItemUUID: string]: number };
  updateBudgets: React.Dispatch<React.SetStateAction<{ [campaignItemUUID: string]: number }>>;
  campaign: ICampaign;
  campaignItemsIsLoading: boolean;
  campaigns: { [campaignUUID: string]: ICampaign };
  draftItems: ICampaignItem[];
  timeline: [Moment.Moment, Moment.Moment];
  setTimeline: React.Dispatch<React.SetStateAction<[Moment.Moment, Moment.Moment] | undefined>>;
  publicShows: PublicShowsReduxState;
  totalCost: number;
  validationErrors: any;
  disableContinuousPage: { disable: boolean; disableMessage?: string };
  setDisableContinuousPage: React.Dispatch<
    React.SetStateAction<{ disable: boolean; disableMessage?: string }>
  >;
}

const Scheduler = (props: IContinuousScheduler) => {
  const {
    budgets,
    updateBudgets,
    campaign,
    publicShows,
    timeline,
    draftItems,
    setTimeline,
    setDisableContinuousPage,
  } = props;

  /**
   * Get campaignItem Info helper func
   */
  const getItemInfo = createGetItemInfo({ campaignItems: draftItems, publicShows });

  const { user } = useSelectorTS((state) => state.user);

  const { invitationsByCampaignItemUUID, invitationsByCampaignUUID } = useGetVettingInvitations(
    campaign.uuid
  );

  const startDate = timeline?.[0]?.unix();
  const endDate = timeline?.[1]?.unix();
  const isPacing = Boolean(campaign?.pacing);

  const isBudgetsEmpty = draftItems?.length > 0 && Object.keys(budgets)?.length === 0;

  const isPublicShowsLoaded =
    publicShows?.isLoading === false &&
    draftItems?.every(({ showUUID }) => publicShows?.[showUUID]?.uuid === showUUID);

  /**
   * Grabbing forecast end date
   */

  const minEndDatesState = useForecastEndDates({
    isPacing,
    user,
    campaign,
    draftItems,
    publicShows,
    budgets,
    startDate,
  });

  /**
   * Grabbing forecast Impressions
   */
  const maxImpressionsState = useForecastImpressions({
    isPacing,
    user,
    campaign,
    publicShows,
    draftItems,
    startDate,
    endDate,
  });

  const mapCampaignItemUUIDToShow = draftItems?.reduce(
    (accu, curr) => {
      const { showUUID, uuid } = curr;

      accu[uuid] = publicShows?.[showUUID] ?? {};

      return accu;
    },
    {} as { [campaignItemUUID: string]: PublicShow }
  );

  const mapCampaignItemToMaxBudget = draftItems?.reduce(
    (accu, curr) => {
      const { uuid } = curr;
      const { publicShow, campaignItem } = getItemInfo(uuid);
      const showUUID = publicShow?.uuid ?? "";
      const cpm = getAverageCPM({ show: publicShow, campaign, campaignItem, when: "final" });

      if (!maxImpressionsState.maxImpressions?.[showUUID]?.streamulatorErrored) {
        accu[uuid] =
          ((maxImpressionsState.maxImpressions[showUUID]?.impressions ?? 0) / 1000) * cpm;
      }

      return accu;
    },
    {} as { [campaignItemUUID: string]: number }
  );

  const nonPacingLatestEndDate =
    Object.values(minEndDatesState.minEndDates)?.length > 0
      ? Object.values(minEndDatesState.minEndDates)?.reduce((accu, curr) => {
          if (!curr.streamulatorErrored) {
            accu = accu >= curr.estimatedEndTime ? accu : curr.estimatedEndTime;
          }
          return accu;
        }, startDate)
      : endDate;

  const { ref: bodyRef, height: modalBodyHeight } = useResizeObserver<HTMLDivElement>();

  const listOfItems = draftItems?.map((item) => item.uuid) ?? [];
  const vettingOccurred = invitationsByCampaignUUID[campaign?.uuid]?.some((invite) =>
    listOfItems.includes(invite.campaignItemUUID)
  );

  /**
   * Setting initial Budgets (Should only run once, when budget map is empty)
   */
  useEffect(() => {
    if (
      !!campaign &&
      Array.isArray(draftItems) &&
      !!publicShows &&
      isPublicShowsLoaded &&
      typeof updateBudgets == "function" &&
      typeof startDate === "number" &&
      typeof endDate === "number" &&
      isBudgetsEmpty
    ) {
      const initialBudgetMap = draftItems?.reduce(
        (mapper, campaignItem) => {
          const { uuid, showUUID, totalBudget } = campaignItem;
          const publicShow = publicShows?.[showUUID];
          const cpm = getAverageCPM({ show: publicShow, campaign, campaignItem });
          const dailyImpressions = getDailyImpressions(publicShow);

          mapper[uuid] =
            typeof totalBudget === "number" && totalBudget > 0
              ? totalBudget
              : getBudget({ cpm, dailyImpressions, startDate, endDate });
          return mapper;
        },
        {} as { [campaignItemUUID: string]: number }
      );

      updateBudgets(initialBudgetMap);
    }
  }, [
    campaign,
    draftItems,
    publicShows,
    isPublicShowsLoaded,
    budgets,
    updateBudgets,
    startDate,
    endDate,
    isBudgetsEmpty,
    getItemInfo,
  ]);

  /**
   * Sync time line end date with latest end date from all calculated campaign Items
   * Should run after budget has been initialized. and only on non pacing campaigns
   */

  useEffect(() => {
    if (!isPacing && typeof nonPacingLatestEndDate === "number" && !isBudgetsEmpty) {
      setTimeline((prev) => {
        if (Array.isArray(prev)) {
          return [prev?.[0]?.clone(), Moment?.unix(nonPacingLatestEndDate)];
        }
        return prev;
      });
    }
  }, [isPacing, nonPacingLatestEndDate, isBudgetsEmpty]);

  /**
   * Sync Loading and Error states of streamulator forecast end points in
   * order to restrict user from submitting the modal page
   */
  useEffect(() => {
    if (Array.isArray(draftItems) && draftItems?.length === 0) {
      setDisableContinuousPage({
        disable: true,
        disableMessage: "Please add podcasts to the campaign.",
      });
    } else {
      const disable = maxImpressionsState.isLoading || minEndDatesState.isLoading;

      // For now going ot always assume the error is due to not being able to the predict inventory due to show being too new
      const disableMessage =
        maxImpressionsState.error ?? minEndDatesState.error
          ? "The inventory of a podcast(s) cannot be predicted. You can continue by sizing your campaigns manually."
          : undefined;
      setDisableContinuousPage((prev) => {
        if (prev.disable !== disable || prev.disableMessage !== disableMessage) {
          return {
            disable,
            disableMessage,
          };
        }

        return prev;
      });
    }
  }, [
    maxImpressionsState.isLoading,
    maxImpressionsState.error,
    minEndDatesState.isLoading,
    minEndDatesState?.error,
    draftItems?.length,
  ]);

  /**
   * Table Columns
   */

  const dataSource = [...draftItems].sort((a, b) => {
    const titleA = mapCampaignItemUUIDToShow?.[a.uuid]?.title?.toLowerCase() ?? "";
    const titleB = mapCampaignItemUUIDToShow?.[b.uuid]?.title?.toLowerCase() ?? "";

    return titleA.localeCompare(titleB);
  });

  const columns: TableProps<(typeof draftItems)[number]>["columns"] = [
    {
      key: "uuid",
      title: `PODCAST (${draftItems?.length ?? 0})`,
      dataIndex: "uuid",
      width: "35%",
      render: (uuid) => {
        const { publicShow } = getItemInfo(uuid);

        return (
          <div className="flex-row-container">
            <div>
              <LoadingImage width={48} height={48} src={publicShow?.imageURL ?? ""} />
            </div>
            <div className="flex-column-container justify-center m-lxs">
              <Link
                className="black-text bold fs-15 lh-xs line-clamp-3 capitalize"
                target="_blank"
                rel="noopener noreferrer"
                to={`/browse/${publicShow?.uuid}`}>
                {publicShow?.title}
              </Link>
              <span className="fs-11 lh-xs ls-.6 capitalize">
                Weekly Downloads:{" "}
                {numeral(publicShow?.estimatedWeeklyDownloads ?? 0).format("0.0a")}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      key: "CPM",
      title: "CPM",
      dataIndex: "uuid",
      width: "20%",
      render: (uuid) => {
        const { publicShow, campaignItem } = getItemInfo(uuid);

        if (!publicShow || !campaignItem) return null;

        return (
          <NegotiateRateV2
            campaign={campaign}
            uuid={uuid}
            show={publicShow}
            campaignItem={campaignItem}
            budgets={budgets}
            updateBudgets={updateBudgets}
            title={"CPM"}
            startDate={timeline?.[0]?.unix() ?? 0}
            endDate={timeline?.[1]?.unix() ?? 0}
            negotiatedRateValue={getAverageCPM({
              show: publicShow,
              campaign,
              campaignItem,
              when: "final",
            })}
            originalRateValue={getAverageCPM({
              show: publicShow,
              campaign,
              campaignItem,
              when: "original",
            })}
          />
        );
      },
    },
    {
      key: "downloads",
      title: "DOWNLOADS",
      dataIndex: "uuid",
      width: "20%",
      render: (uuid) => {
        const { publicShow, campaignItem } = getItemInfo(uuid);

        if (!publicShow || !campaignItem || !campaign) return null;

        const max = maxImpressionsState.maxImpressions[publicShow?.uuid]?.impressions;
        const enableMax =
          Boolean(campaign?.pacing) &&
          typeof max === "number" &&
          max >= 0 &&
          !maxImpressionsState.maxImpressions[publicShow?.uuid]?.streamulatorErrored;

        return (
          <CustomImpressionsCell
            campaignItemUUID={uuid}
            campaign={campaign}
            publicShow={publicShow}
            campaignItem={campaignItem}
            budgets={budgets}
            updateBudgets={updateBudgets}
            timeline={timeline}
            max={enableMax ? max : undefined}
          />
        );
      },
    },
    {
      key: "estimatedRunTime",
      title: "EST. timeline",
      dataIndex: "uuid",
      width: "20%",
      render: (uuid) => {
        const { publicShow, campaignItem } = getItemInfo(uuid);

        if (!publicShow || !campaignItem) return null;

        return (
          <CustomTimelineCell
            timeline={timeline}
            forecastEndDate={minEndDatesState.minEndDates?.[publicShow.uuid]?.estimatedEndTime}
            isLoading={minEndDatesState.isLoading}
          />
        );
      },
    },
    {
      key: "budget",
      title: "Budget",
      dataIndex: "uuid",
      width: "20%",
      render: (uuid) => {
        const { publicShow, campaignItem } = getItemInfo(uuid);

        if (!publicShow || !campaignItem) return null;

        const enableMax =
          Boolean(campaign?.pacing) && typeof mapCampaignItemToMaxBudget?.[uuid] === "number";

        return (
          <CustomBudgetCell
            campaignItemUUID={uuid}
            campaign={campaign}
            publicShow={publicShow}
            campaignItem={campaignItem}
            budgets={budgets}
            updateBudgets={updateBudgets}
            timeline={timeline}
            max={enableMax ? mapCampaignItemToMaxBudget?.[uuid] : undefined}
          />
        );
      },
    },
    {
      key: "remove",
      dataIndex: "uuid",
      width: 100,
      render: (uuid) => {
        if (!campaign) return null;

        return (
          <CustomShowRemoveCell
            campaignItemUUID={uuid}
            campaign={campaign}
            updateBudgets={updateBudgets}
          />
        );
      },
    },
  ];

  // When pacing enabled, remove estimated end date column
  if (isPacing) {
    columns.splice(3, 1);
  }

  if (vettingOccurred) {
    columns.splice(0, 0, {
      key: "vetting",
      title: ` `,
      dataIndex: "uuid",
      width: "25px",
      render: (uuid) => {
        const vettingInvitations = invitationsByCampaignItemUUID[uuid] ?? [];

        return <CampaignItemVettingStatus showOnlyIcon vettingInvitations={vettingInvitations} />;
      },
    });
  }

  const isTableReady =
    !!campaign &&
    !!user &&
    !isBudgetsEmpty &&
    isPublicShowsLoaded &&
    !maxImpressionsState?.isLoading &&
    !minEndDatesState?.isLoading;

  // total Aggregate impressions
  const aggregateImpressions =
    draftItems?.reduce((accu, { uuid }) => {
      const { campaignItem, publicShow } = getItemInfo(uuid);
      const cpm = getAverageCPM({ show: publicShow, campaign, campaignItem });
      const budget = budgets?.[uuid] ?? 0;

      const impressions = getImpressionsFromBudget({ cpm, budget });

      accu += Math.round(impressions);

      return accu;
    }, 0) ?? 0;

  // Total budget
  const aggregateBudget =
    (Object.values(budgets ?? {}).reduce((accu, curr) => accu + curr, 0) ?? 0) / 100;

  // handlers

  const onStartDateChange: DatePickerProps["onChange"] = (startDate) => {
    if (startDate) {
      const newStartDate = Moment.unix(cloneDateAs3amEST(startDate.unix()));
      setTimeline((prev) => {
        if (Array.isArray(prev)) {
          return [newStartDate, prev?.[1]?.clone()];
        }

        return prev;
      });
    }
  };

  const handleDisableDate: DatePickerProps["disabledDate"] = (date) => {
    if (date) {
      return date?.unix() < getCampaignMinimumStartDate(campaign);
    }
    return false;
  };

  const handleRowKey: TableProps<(typeof draftItems)[number]>["rowKey"] = (record) => record?.uuid;
  const handleRowClassName: TableProps<(typeof draftItems)[number]>["rowClassName"] = (record) => {
    const showUUID = record?.showUUID;
    const error = isPacing
      ? maxImpressionsState?.maxImpressions[showUUID]?.streamulatorErrored
      : minEndDatesState?.minEndDates[showUUID]?.streamulatorErrored;
    return error ? "forecast-error" : "";
  };

  const newTableHeight =
    typeof modalBodyHeight === "number" ? modalBodyHeight - (isPacing ? 200 : 240) : 300;

  return (
    <div ref={bodyRef} className="campaign-continuous-wrapper">
      <Row>
        <Col xs={24} xl={{ span: 22, offset: 1 }}>
          <div className="flex-row-container m-bxs width-100">
            <div>
              <div className="bold uppercase fs-12 lh-xs m-bxxxs" style={{ letterSpacing: "10%" }}>
                {isPacing ? "EVENLY PACED FROM" : "WHEN WILL THIS PROMOTION START?"}
              </div>
              {isPacing ? (
                <RCRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  minimumDate={getCampaignMinimumStartDate(campaign)}
                  onDateSubmit={([start, end]) => {
                    setTimeline([
                      Moment.unix(cloneDateAs3amEST(start)),
                      Moment.unix(cloneDateAs3amEST(end)),
                    ]);
                  }}
                />
              ) : (
                <DatePicker
                  className="m-bxxs"
                  value={dayjs.unix(startDate)}
                  format={"MM/DD/YYYY"}
                  allowClear={false}
                  onChange={onStartDateChange}
                  disabledDate={handleDisableDate}
                  placeholder="Start date"
                  popupStyle={{ zIndex: 1111 }}
                />
              )}
            </div>

            <div className="flex-row-container align-end m-la">
              <MatchBudget
                budgets={budgets}
                updateBudgets={updateBudgets}
                isPacing={Boolean(campaign?.pacing)}
                mapCampaignItemToMaxBudgets={mapCampaignItemToMaxBudget}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="flex-grow">
        <Col xs={24} xl={{ span: 22, offset: 1 }}>
          <RCTable
            className="scroll-max-override"
            loading={!isTableReady}
            dataSource={dataSource}
            rowClassName={handleRowClassName}
            columns={columns}
            size="small"
            rowKey={handleRowKey}
            pagination={false}
            scroll={{ y: newTableHeight }}
          />
        </Col>
      </Row>
      <Row className="m-vxxs">
        <Col xs={24} xl={{ span: 22, offset: 1 }}>
          <div className="flex-row-container align-center justify-space-between m-bxxxs">
            <span className="bold capitalize fs-20 lh-20">Estimated Downloads</span>
            <span className="fs-15 lh-m">{numeral(aggregateImpressions).format("0,0")}</span>
          </div>
          {!isPacing && (
            <div className="flex-row-container align-center justify-space-between m-bxxxs">
              <span className="bold capitalize fs-20 lh-20">Date Range</span>
              <span className="fs-15 lh-m">
                {`${localLongDate(startDate)} - ${localLongDate(nonPacingLatestEndDate)}`}
              </span>
            </div>
          )}
          <div className="flex-row-container align-center justify-space-between">
            <span className="bold capitalize fs-m lh-l">Total cost</span>
            <span className="bold fs-20 lh-20">{numeral(aggregateBudget).format("$0,0.00")}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const ContinuousScheduler = React.memo(Scheduler);
