import { Checkbox, Divider, Form, Input, Radio } from "antd";
import { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import QuillEditor from "src/components/forms/quill_component";
import {
  ProductExchangeTypeInstructions,
  ProductExchangeTypeMailing,
  ProductExchangeTypeNone,
} from "src/constants/campaigns";
import { ICampaign } from "src/reducers/campaigns/types";

const SHARING_URL = "https://support.redcircle.com/sharing-product-samples-with-podcasters";

export default function CampaignFormProduct({ editingCampaign }: { editingCampaign?: ICampaign }) {
  const form = Form.useFormInstance();
  const productExchangeType = Form.useWatch("productExchangeType", form);
  const [multipleCode, setMultipleCodes] = useState(
    editingCampaign && !!editingCampaign.redemptionCodes
  );

  const isInstructions = productExchangeType === ProductExchangeTypeInstructions;
  const isMailing = productExchangeType === ProductExchangeTypeMailing;

  return (
    <div className="flex-column-container">
      <label className="redcircle-form-label fs-11" htmlFor="productExchangeType">
        Will the podcasters receive free product samples for this campaign?*
      </label>

      <a
        href={SHARING_URL}
        target="_blank"
        rel="noreferrer"
        className="flex-row-container align-center">
        <strong>Learn more about sharing product</strong>
        <AiOutlineArrowRight className="m-lxxxs" />
      </a>

      <Form.Item name="productExchangeType" className="flex-column-container m-txs">
        <Radio.Group className="flex-column-container">
          <Radio value={ProductExchangeTypeNone} className="m-bs">
            No samples for this campaign
          </Radio>
          <Radio value={ProductExchangeTypeMailing} className="m-bs">
            Yes, brand will ship the product to the podcaster
          </Radio>
          <Radio value={ProductExchangeTypeInstructions}>
            Yes, brand will provide instructions for podcaster to redeem online
          </Radio>
        </Radio.Group>
      </Form.Item>

      {(isMailing || isInstructions) && (
        <div className="m-lxxs text-block-expanded">
          <label className="redcircle-form-label fs-11" htmlFor="productExchangeInstructions">
            {isMailing && "Product exchange details for podcasters*"}
            {isInstructions && "Product exchange details for podcasters to redeem online*"}
          </label>
          <Form.Item
            name="productExchangeInstructions"
            className="m-bxxs"
            rules={[
              {
                required: isMailing || isInstructions,
                message: "Please provide instructions for podcasters",
              },
            ]}>
            <QuillEditor
              placeholder={
                isInstructions
                  ? `example: "Go to https://nike.com and add the Nike Air Max to your cart."`
                  : isMailing
                    ? `example: "We will ship you a free pair of Nike Air Max."`
                    : ""
              }
            />
          </Form.Item>
          <small>
            <span>All Podcasters will see exactly what is in this box.</span>
            {isInstructions &&
              ` If you have one standard code that
            *all* podcasts can use (eg NIKE10) to checkout on your website, enter it above. If you
            have a list of *unique* codes (one per podcast host), use the section below to paste the
            list of unique codes.`}
          </small>
          {isInstructions && (
            <div className="flex-row-container m-ts m-bxxs">
              <Checkbox
                className="m-rxxs"
                checked={multipleCode}
                onChange={(value) => setMultipleCodes(value.target.checked)}
              />
              Brand will provide multiple redemption codes (one per podcast host)
            </div>
          )}
          {multipleCode && (
            <>
              <label className="redcircle-form-label fs-11" htmlFor="redemptionCodeString">
                List of unique redemption codes (codes only)
              </label>
              <Form.Item name="redemptionCodeString" className="m-bxxs">
                <Input.TextArea
                  placeholder={`NIKE123, NIKE456, NIKE789`}
                  size="large"
                  autoSize={{ minRows: 2, maxRows: 10 }}
                />
              </Form.Item>
              <small>
                When podcasts accept your campaign offer, we will provide each host with a unique
                code from this list. We suggest that you add more codes than you think you’ll need.
                We will let you know which codes we match to which shows.
              </small>
            </>
          )}
        </div>
      )}

      <Divider />
      <label className="redcircle-form-label fs-11" htmlFor="endorsement">
        Will the podcaster need to personally endorse this product?*
      </label>
      <Form.Item name="requiresEndorsement" className="flex-column-container m-txs">
        <Radio.Group className="flex-column-container">
          <Radio value={false} className="m-bs">
            No
          </Radio>
          <Radio value={true} className="m-bs">
            Yes
          </Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
}
