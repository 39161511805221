import { Select, Switch, Table } from "antd";
import { map } from "lodash";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import moment, { Moment } from "moment-timezone";
import numeral from "numeral";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { shouldShowAnalyticsUpgrade } from "src/lib/feature_flag";
import { ICampaign } from "src/reducers/campaigns/types";
import { showError, showInfo } from "../../../../actions/app";
import { InsertionStatsActionManager } from "../../../../action_managers/stats";
import { useReduxDispatch, useSelectorTS } from "../../../../hooks/redux-ts";
import { downloadFile } from "../../../lib/download_file";
import { svgIcon as Icon } from "../../../lib/icon";
import {
  alignment,
  ALL_AUDIO_BLOCKS,
  ALL_CAMPAIGNS,
  ALL_CUSTOM_AUDIO,
  ALL_PODCASTS,
  Breakpoint,
  companyStartDate,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  DropdownIntervalValues,
  getDropdownDefaultValueFromDates,
  getDropdownOptionsFromDates,
  getDurationTextFromDates,
  getIntervalFromDropdown,
  getMaskFromDropdown,
  getRequestHash,
  getTotalFromData,
  graphRedColor,
  InsertionGraphTypes,
  insertionRequestPrefix,
  middleEllipses,
  paginationConfig,
  reportFormatDateFromInterval,
  RequestResponseDataPoint,
  sortNameAlphabetical,
  StatsRequestFilter,
  tooltipTitleFactory,
  useFetchAllShows,
  yAxisLabelFormatter,
} from "../analyticsUtility";
import Area from "../RedCircle_graphs/Area";
import { useGetCustomAudios } from "src/hooks/custom_audio";
import { isUUID } from "src/lib/uuid";

type InsertionCounterMap = Record<(typeof InsertionGraphTypes)[number], number>;
type TableInsertionCounterMap = Record<
  string,
  Record<(typeof InsertionGraphTypes)[number], number>
>;

// Utility functions
const transformDataToCumulative = (data: DataPoint[], startMap: InsertionCounterMap) => {
  const counterMap: Record<(typeof InsertionGraphTypes)[number], number> = {
    "Total Dynamic Insertions": startMap[InsertionGraphTypes[0]],
    "RAP Host-Read": startMap[InsertionGraphTypes[1]],
    "RAP Programmatic": startMap[InsertionGraphTypes[2]],
    "Cross Promotions": startMap[InsertionGraphTypes[3]],
    "Other Dynamic Insertions": startMap[InsertionGraphTypes[4]],
  };

  const result = data.map((item) => {
    counterMap[item.type] += item.count;
    return { ...item, count: counterMap[item.type] };
  });

  return result;
};

const getCumulativeStart = (
  data: RequestResponseDataPoint["json"],
  audioBlock: string,
  campaign: string
) => {
  const counterMap: Record<(typeof InsertionGraphTypes)[number], number> = {
    "Total Dynamic Insertions": 0,
    "RAP Host-Read": 0,
    "RAP Programmatic": 0,
    "Cross Promotions": 0,
    "Other Dynamic Insertions": 0,
  };

  if (isEmpty(data)) {
    return counterMap;
  }

  data.forEach((item) => {
    const [, isProgrammatic, type, showUUID, audioBlockUUID, campaignUUID] = item?.pathValues;

    if (type === "ad") {
      // campaign
      if (isProgrammatic === "false") {
        if (campaign === ALL_CAMPAIGNS || campaign === campaignUUID) {
          counterMap[InsertionGraphTypes[1]] += item?.count;
        }
      } else {
        // Programmatic
        counterMap[InsertionGraphTypes[2]] += item?.count;
      }
    } else if (type === "cross_promo") {
      counterMap[InsertionGraphTypes[3]] += item?.count;
    } else if (type === "audio_file" || type === "custom_audio") {
      // Other Dynamic Insertion
      if (audioBlock === ALL_AUDIO_BLOCKS || audioBlock === audioBlockUUID) {
        counterMap[InsertionGraphTypes[4]] += item?.count;
      }
    } else {
      // should never get here
    }
  });

  return counterMap;
};

const transformTableDataToCumulative = (
  data: TableDataPoint[],
  startMap: TableInsertionCounterMap = {}
) => {
  const result = data.map((item) => {
    const { showUUID, type, count } = item;

    if (isEmpty(startMap[showUUID])) {
      startMap[showUUID] = {
        "Total Dynamic Insertions": 0,
        "RAP Host-Read": 0,
        "RAP Programmatic": 0,
        "Cross Promotions": 0,
        "Other Dynamic Insertions": 0,
      };
    }

    startMap[showUUID][type] += count;

    return { ...item, count: startMap[showUUID][type] };
  });

  return result;
};

const getTableCumulativeStart = ({
  data,
  audioBlock,
  campaign,
  customAudio,
}: {
  data: RequestResponseDataPoint["json"];
  audioBlock: string;
  customAudio: string;
  campaign: string;
}) => {
  const counterMap: TableInsertionCounterMap = {};

  if (isEmpty(data)) {
    return {};
  }

  data.forEach((item) => {
    const [, isProgrammatic, type, showUUID, audioBlockUUID, campaignUUID] = item?.pathValues;

    if (isEmpty(counterMap[showUUID])) {
      counterMap[showUUID] = {
        "Total Dynamic Insertions": 0,
        "RAP Host-Read": 0,
        "RAP Programmatic": 0,
        "Cross Promotions": 0,
        "Other Dynamic Insertions": 0,
      };
    }

    if (type === "ad") {
      // campaign
      if (isProgrammatic === "false") {
        if (campaign === ALL_CAMPAIGNS || campaign === campaignUUID) {
          counterMap[showUUID][InsertionGraphTypes[1]] += item?.count;
        }
      } else {
        // Programmatic
        counterMap[showUUID][InsertionGraphTypes[2]] += item?.count;
      }
    } else if (type === "cross_promo") {
      // cross promotion
      counterMap[showUUID][InsertionGraphTypes[3]] += item?.count;
    } else if (type === "audio_file" || type === "custom_audio") {
      // other insertion
      if (
        (audioBlock === ALL_AUDIO_BLOCKS || audioBlock === audioBlockUUID) &&
        (customAudio === ALL_CUSTOM_AUDIO || customAudio === campaignUUID)
      ) {
        counterMap[showUUID][InsertionGraphTypes[4]] += item?.count;
      }
    } else {
      // should never get here
    }
  });

  return counterMap;
};

const getCampaignListFromData = (
  data: RequestResponseDataPoint["json"],
  campaigns: { [campaignUUID: string]: ICampaign }
) => {
  const campaignListMap: {
    [campaignUUID: string]: {
      uuid: string;
      name: string;
    };
  } = {};

  if (isEmpty(data) || isEmpty(campaigns)) {
    return [];
  }

  data.forEach((item) => {
    const [, isProgrammatic, type, showUUID, audioBlockUUID, campaignUUID] = item?.pathValues;

    if (type === "ad") {
      // campaign
      if (isProgrammatic === "false") {
        if (!campaignListMap?.[campaignUUID]) {
          campaignListMap[campaignUUID] = {
            name: campaigns?.[campaignUUID]?.name,
            uuid: campaignUUID,
          };
        }
      } else {
        // Programmatic
      }
    } else if (type === "cross_promo") {
      // cross promotion
    } else if (type === "audio_file") {
      // other insertion
    } else {
      // should never get here
    }
  });

  const result = Object.values(campaignListMap)?.sort((a, b) => a.name?.localeCompare(b.name));

  return result;
};

// Graph config tooltip formatter
const customTooltip = (title: any, data: any) => {
  return `
    <div class="analyticsPage-GraphTooltip">
      <span class="analyticsPage-GraphTooltip--title">${title}</span>
        ${data
          ?.map((item: any) => {
            return `
              <div class="analyticsPage-GraphTooltip--item"> 
                <span style="background-color:${item?.color}"> </span>  
                <span class="text">${item?.name}</span>
                <span>${item?.value}</span>
              </div>`;
          })
          .join("")}
    </div>`;
};

const transformMapToDataPoint = (
  dataMap: Record<string, number>,
  type: (typeof InsertionGraphTypes)[number]
) => {
  const result: DataPoint[] = Object.keys(dataMap).map((date) => {
    return {
      date: parseInt(date) * 1000,
      count: dataMap[parseInt(date)],
      type,
    };
  });
  return result;
};

const getGraphTitle = (
  data: DataPoint[],
  dateRange: [Moment, Moment],
  graphType: (typeof InsertionGraphTypes)[number],
  isCumulative: boolean,
  loading: boolean
) => {
  if (isEmpty(data) || loading) {
    return "";
  }

  const titleMap = {
    [InsertionGraphTypes[0]]: "Insertions",
    [InsertionGraphTypes[1]]: InsertionGraphTypes[1] + " Insertions",
    [InsertionGraphTypes[2]]: InsertionGraphTypes[2] + " Insertions",
    [InsertionGraphTypes[3]]: InsertionGraphTypes[3] + " Insertions",
    [InsertionGraphTypes[4]]: InsertionGraphTypes[4],
  };

  let cumulativeData = data[data?.length - 1]?.count;

  if (graphType === InsertionGraphTypes[0]) {
    cumulativeData = InsertionGraphTypes.reduce((accu, curr) => {
      if (curr !== InsertionGraphTypes[0]) {
        const filterdata = data.filter((item) => item.type === curr);
        return accu + filterdata[filterdata?.length - 1].count;
      }

      return accu;
    }, 0);
  }

  const total = isCumulative ? cumulativeData : getTotalFromData<DataPoint>(data, 0);

  if (total === 0) {
    return "";
  }

  return `${numeral(total).format("0,0")} ${titleMap[graphType]} ${
    isCumulative ? "" : getDurationTextFromDates(dateRange)
  }`;
};

const CSVReportTitle = {
  [InsertionGraphTypes[0]]: "TotalInsertions",
  [InsertionGraphTypes[1]]: "RAPHostReadInsertions",
  [InsertionGraphTypes[2]]: "RAPProgrammaticInsertions",
  [InsertionGraphTypes[3]]: "ExclusiveSubscriptionsInsertions",
  [InsertionGraphTypes[4]]: "OtherInsertions",
};

const graphColorMap: Record<(typeof InsertionGraphTypes)[number], string> = {
  "Total Dynamic Insertions": "",
  "RAP Host-Read": graphRedColor,
  "RAP Programmatic": "rgba(255,156,160,.7)",
  "Cross Promotions": "rgba(255,156,160,.4)",
  "Other Dynamic Insertions": "rgba(255,156,160,.1)",
};

// Graph Configs
const defaultPointConfig = {
  size: 4,
  shape: "circle",
  style: (datum: any) => {
    return {
      fill: "white",
      stroke: graphColorMap[datum?.type as (typeof InsertionGraphTypes)[number]],
      lineWidth: 0.8,
    };
  },
};

const defaultLineConfig = (graphType: (typeof InsertionGraphTypes)[number]) => {
  return {
    style: (datum: any) => {
      return {
        stroke:
          graphType === InsertionGraphTypes[0]
            ? graphColorMap[datum?.type as (typeof InsertionGraphTypes)[number]]
            : graphRedColor,
      };
    },
  };
};

const defaultAreaStyle = (graphType: (typeof InsertionGraphTypes)[number]) => {
  return (datum: any) => {
    return {
      fill:
        graphType === InsertionGraphTypes[0]
          ? graphColorMap[datum?.type as (typeof InsertionGraphTypes)[number]]
          : graphRedColor,
    };
  };
};

const defaultColorConfig = ({ type }: any) => {
  return graphColorMap[type as (typeof InsertionGraphTypes)[number]];
};

interface TableDataPoint {
  key: string;
  date: number;
  count: number;
  showTitle: string;
  showUUID: string;
  type: (typeof InsertionGraphTypes)[number];
}

interface DataPoint {
  date: number;
  count: number;
  type: (typeof InsertionGraphTypes)[number];
}

interface IInsertionData {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
  graphType: (typeof InsertionGraphTypes)[number];
}

const InsertionData: FunctionComponent<IInsertionData> = ({
  dateRange,
  timeZone,
  selectedShow,
  graphType,
}) => {
  const dispatch = useReduxDispatch();
  const user = useSelectorTS((state) => state?.user?.user);

  const { campaigns } = useSelectorTS((state) => state?.campaigns);

  const { audioBlocks } = useSelectorTS((state) => state?.audioBlocks);

  const { allShows } = useFetchAllShows();
  const { customAudios } = useGetCustomAudios();

  const dropdownOptions = getDropdownOptionsFromDates(dateRange);
  const dropdownDefaultValue = getDropdownDefaultValueFromDates(dateRange);

  const [dropdownVal, setDropdownVal] =
    useState<(typeof DropdownIntervalValues)[number]>(dropdownDefaultValue);

  const [campaignDropdown, setCampaignDropdown] = useState<string>(ALL_CAMPAIGNS);
  const [audioBlock, setAudioBlock] = useState<string>(ALL_AUDIO_BLOCKS);
  const [customAudio, setCustomAudio] = useState<string>(ALL_CUSTOM_AUDIO);

  const [isCumulative, setIsCumulative] = useState<boolean>(false);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [isCumulativeLoading, setIsCumulativeLoading] = useState<boolean>(false);

  const cacheID = getRequestHash(insertionRequestPrefix, selectedShow, dateRange, dropdownVal);

  const cumulativeCacheID = getRequestHash(
    `${insertionRequestPrefix}-Cumulative`,
    selectedShow,
    dateRange
  );

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  const statsCacheCumulativeData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cumulativeCacheID],
    shallowEqual
  );

  const campaignList = getCampaignListFromData(statsCacheData, campaigns);

  const transformDataToGraph = (data: RequestResponseDataPoint["json"]) => {
    if (isEmpty(data) || data?.length === 0) {
      return [];
    }

    const RAPHostReadMap: Record<number, number> = {};
    const RAPProgrammaticMap: Record<number, number> = {};
    const CrossPromoMap: Record<number, number> = {};
    const OtherMap: Record<number, number> = {};

    data.forEach((insertion) => {
      const [, isProgrammatic, type, showUUID, audioBlockUUID, campaignUUID] =
        insertion?.pathValues;

      if (type === "ad") {
        // campaign
        if (isProgrammatic === "false") {
          if (campaignDropdown === ALL_CAMPAIGNS || campaignDropdown === campaignUUID) {
            RAPHostReadMap[insertion?.date] =
              typeof RAPHostReadMap[insertion?.date] === "number"
                ? RAPHostReadMap[insertion?.date] + insertion?.count
                : insertion?.count;

            RAPProgrammaticMap[insertion?.date] =
              typeof RAPProgrammaticMap[insertion?.date] === "number"
                ? RAPProgrammaticMap[insertion?.date] + 0
                : 0;

            CrossPromoMap[insertion?.date] =
              typeof CrossPromoMap[insertion?.date] === "number"
                ? CrossPromoMap[insertion?.date] + 0
                : 0;

            OtherMap[insertion?.date] =
              typeof OtherMap[insertion?.date] === "number" ? OtherMap[insertion?.date] + 0 : 0;
          }
        } else {
          // Programmatic
          RAPProgrammaticMap[insertion?.date] =
            typeof RAPProgrammaticMap[insertion?.date] === "number"
              ? RAPProgrammaticMap[insertion?.date] + insertion?.count
              : insertion?.count;

          RAPHostReadMap[insertion?.date] =
            typeof RAPHostReadMap[insertion?.date] === "number"
              ? RAPHostReadMap[insertion?.date] + 0
              : 0;

          CrossPromoMap[insertion?.date] =
            typeof CrossPromoMap[insertion?.date] === "number"
              ? CrossPromoMap[insertion?.date] + 0
              : 0;

          OtherMap[insertion?.date] =
            typeof OtherMap[insertion?.date] === "number" ? OtherMap[insertion?.date] + 0 : 0;
        }
      } else if (type === "cross_promo") {
        // cross promotion or other insertion
        CrossPromoMap[insertion?.date] =
          typeof CrossPromoMap[insertion?.date] === "number"
            ? CrossPromoMap[insertion?.date] + insertion?.count
            : insertion?.count;

        RAPProgrammaticMap[insertion?.date] =
          typeof RAPProgrammaticMap[insertion?.date] === "number"
            ? RAPProgrammaticMap[insertion?.date] + 0
            : 0;

        RAPHostReadMap[insertion?.date] =
          typeof RAPHostReadMap[insertion?.date] === "number"
            ? RAPHostReadMap[insertion?.date] + 0
            : 0;
        OtherMap[insertion?.date] =
          typeof OtherMap[insertion?.date] === "number" ? OtherMap[insertion?.date] + 0 : 0;
      } else if (type === "audio_file" || type === "custom_audio") {
        // cross promotion or other insertion
        if (
          (audioBlock === ALL_AUDIO_BLOCKS || audioBlock === audioBlockUUID) &&
          (customAudio === ALL_CUSTOM_AUDIO || customAudio === campaignUUID)
        ) {
          OtherMap[insertion?.date] =
            typeof OtherMap[insertion?.date] === "number"
              ? OtherMap[insertion?.date] + insertion?.count
              : insertion?.count;

          RAPProgrammaticMap[insertion?.date] =
            typeof RAPProgrammaticMap[insertion?.date] === "number"
              ? RAPProgrammaticMap[insertion?.date] + 0
              : 0;

          RAPHostReadMap[insertion?.date] =
            typeof RAPHostReadMap[insertion?.date] === "number"
              ? RAPHostReadMap[insertion?.date] + 0
              : 0;

          CrossPromoMap[insertion?.date] =
            typeof CrossPromoMap[insertion?.date] === "number"
              ? CrossPromoMap[insertion?.date] + 0
              : 0;
        }
      } else {
        // should never get here
      }
    });

    const HostRead = transformMapToDataPoint(RAPHostReadMap, InsertionGraphTypes[1]);
    const Programmatic = transformMapToDataPoint(RAPProgrammaticMap, InsertionGraphTypes[2]);
    const CrossPromotions = transformMapToDataPoint(CrossPromoMap, InsertionGraphTypes[3]);
    const Other = transformMapToDataPoint(OtherMap, InsertionGraphTypes[4]);

    const graphData = HostRead.concat(Programmatic, CrossPromotions, Other);

    return graphData;
  };

  const transformDataToTable: (data: RequestResponseDataPoint["json"]) => {
    key: string;
    date: number;
    count: number;
    showTitle: string;
    showUUID: string;
    type: (typeof InsertionGraphTypes)[number];
  }[] = (data) => {
    if (isEmpty(data) || data?.length === 0) {
      return [];
    }

    const aggregateMap: {
      [uniqueKey: string]: {
        key: string;
        date: number;
        count: number;
        showTitle: string;
        showUUID: string;
        type: (typeof InsertionGraphTypes)[number];
      };
    } = {};

    data.forEach((insertion) => {
      const [dateString, isProgrammatic, type, showUUID, audioBlockUUID, campaignUUID] =
        insertion?.pathValues;

      let graphType: (typeof InsertionGraphTypes)[number] = InsertionGraphTypes[0];

      if (type === "ad") {
        // campaign
        if (isProgrammatic === "false") {
          if (campaignDropdown === ALL_CAMPAIGNS || campaignDropdown === campaignUUID) {
            graphType = InsertionGraphTypes[1];
          }
        } else {
          // Programmatic
          graphType = InsertionGraphTypes[2];
        }
      } else if (type === "cross_promo") {
        graphType = InsertionGraphTypes[3];
      } else if (type === "audio_file" || type === "custom_audio") {
        // cross promotion or other insertion

        if (
          (audioBlock === ALL_AUDIO_BLOCKS || audioBlock === audioBlockUUID) &&
          (customAudio === ALL_CUSTOM_AUDIO || customAudio === campaignUUID)
        ) {
          graphType = InsertionGraphTypes[4];
        }
      }

      // Table will be created with each row
      const uniqueKey = `${showUUID}-${graphType}-${dateString}`;

      if (!aggregateMap[uniqueKey]) {
        aggregateMap[uniqueKey] = {
          key: uniqueKey,
          date: insertion?.date * 1000,
          count: 0,
          showTitle: allShows?.[showUUID]?.title as string,
          showUUID,
          type: graphType,
        };
      }

      aggregateMap[uniqueKey].count += insertion?.count;
    });

    return Object.values(aggregateMap);
  };

  // GRAPH DATA TRANSFORMATIONS

  const AllGraphData = transformDataToGraph(statsCacheData);

  // Filter data based on graph type;
  const graphDataFilteredByType =
    graphType === InsertionGraphTypes[0]
      ? AllGraphData
      : AllGraphData.filter((item) => item.type === graphType);

  // Aggregate Graph data to cumulative
  const startMap = getCumulativeStart(statsCacheCumulativeData, audioBlock, campaignDropdown);
  const cumulativeData = transformDataToCumulative(graphDataFilteredByType, startMap);

  const dataProvidedToGraph = isCumulative ? cumulativeData : graphDataFilteredByType;

  // Table Data transformation
  const tableData = transformDataToTable(statsCacheData);

  const tableCumulativeStart = getTableCumulativeStart({
    data: statsCacheCumulativeData,
    audioBlock,
    campaign: campaignDropdown,
    customAudio,
  });

  const tableDataFiltered =
    graphType === InsertionGraphTypes[0]
      ? tableData
      : tableData.filter((item) => item.type === graphType);

  const aggregatedTableData = isCumulative
    ? transformTableDataToCumulative(tableDataFiltered, tableCumulativeStart)
    : tableDataFiltered;

  const dataProvidedToTable = orderBy(aggregatedTableData, ["date"], ["desc"]);

  const isGraphLoading = isDataLoading || isCumulativeLoading;

  // Title
  const title = getGraphTitle(
    dataProvidedToGraph,
    dateRange,
    graphType,
    isCumulative,
    isGraphLoading
  );

  const interval = getIntervalFromDropdown(dropdownVal);

  const columns = [
    {
      title: "Podcast",
      dataIndex: "showTitle",
      className: "analyticsPage-table-cell",
      align: alignment[0],
    },
    {
      title: "Insertion Type",
      dataIndex: "type",
      className: "analyticsPage-table-cell",
      align: alignment[0],
      responsive: ["md", "lg", "xl", "xxl"] as Breakpoint[],
    },
    {
      title: "Date",
      dataIndex: "date",
      className: "analyticsPage-table-cell",
      align: alignment[0],
      render: (date: number) => reportFormatDateFromInterval(interval, date),
      sorter: (a: DataPoint, b: DataPoint) => a.date - b.date,
    },
    {
      title: "insertions",
      dataIndex: "count",
      className: "analyticsPage-table-cell",
      align: alignment[2],
      render: (revenue: number) => numeral(revenue).format("0,0[.]00"),
    },
  ];

  const listOfAudioBlocks = map(audioBlocks, (val) => val)
    ?.filter((val) => val?.type === "custom")
    ?.sort(sortNameAlphabetical); // Displaying all available Audio blocks

  const listOfCustomAudios = Object.values(customAudios).sort(sortNameAlphabetical); // Displaying all available custom audios

  // Handle Insertions Section Overview
  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheData)) {
      setIsDataLoading(true);
      id = setTimeout(() => {
        let filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
          timezone: timeZone,
          type: "insertions",
          interval: getIntervalFromDropdown(dropdownVal),
          bucketTerms:
            "insertion.isProgrammatic,insertion.type,insertion.showUUID,insertion.audioBlockUUID,insertion.campaignUUID",
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters = { ...filters, showUUID: selectedShow };
        }

        dispatch(
          new InsertionStatsActionManager({
            filters,
            user,
            requestID: cacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsDataLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsDataLoading(false);
          });
      }, 250);
    } else {
      setIsDataLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
    dropdownVal,
  ]);

  // Handles fetching data before startdate in order to get cumulative  data
  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheCumulativeData)) {
      setIsCumulativeLoading(true);
      id = setTimeout(() => {
        const filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [companyStartDate.unix(), dateRange?.[0]?.unix()].join(","),
          timezone: timeZone,
          type: "insertions",
          interval: getIntervalFromDropdown(dropdownVal),
          bucketTerms:
            "insertion.isProgrammatic,insertion.type,insertion.showUUID,insertion.audioBlockUUID,insertion.campaignUUID",
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters.showUUID = selectedShow;
        }

        dispatch(
          new InsertionStatsActionManager({
            filters,
            user,
            requestID: cumulativeCacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsCumulativeLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsCumulativeLoading(false);
          });
      }, 250);
    } else {
      setIsCumulativeLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [dateRange?.[0]?.format(dateFormatStringDiff), selectedShow, dropdownVal]);

  useEffect(() => {
    setDropdownVal(dropdownDefaultValue);
  }, [dropdownDefaultValue]);

  useEffect(() => {
    // On tab change, reset audio block filter
    audioBlock !== ALL_AUDIO_BLOCKS && setAudioBlock(ALL_AUDIO_BLOCKS);

    // On tab change, reset campaign filter
    campaignDropdown !== ALL_CAMPAIGNS && setCampaignDropdown(ALL_CAMPAIGNS);
  }, [graphType]);

  // Handlers
  const handleIntervalChange = (newDropdownValue: (typeof DropdownIntervalValues)[number]) => {
    setDropdownVal(newDropdownValue);
  };
  const handleSwitchChange = (checked: boolean) => setIsCumulative(checked);

  const handleCampaignSwitch = (newDropdownValue: any) => {
    setCampaignDropdown(newDropdownValue);
  };

  const handleAudioBlockSwitch = (newDropdownValue: any) => {
    setAudioBlock(newDropdownValue);
  };

  const handleCustomAudioSwitch = (newCustomAudioVal: string) => {
    setCustomAudio(newCustomAudioVal);
  };

  const handleReportDownload = () => {
    if (!isGraphLoading) {
      const csv = Papa.unparse(
        dataProvidedToTable.map((point) => {
          return {
            PodcastName: point.showTitle,
            "Revenue Type": point.type,
            Date: reportFormatDateFromInterval(interval, point.date),
            [`Insertions_${isCumulative ? "Cumulative" : "OverTime"}`]: point.count,
          };
        })
      );

      const aggregateTitle = isCumulative ? "Cumulative" : "OverTime";

      downloadFile(
        `DynamicInsertionsReport_${CSVReportTitle[graphType]}_${aggregateTitle}_${
          campaignDropdown !== ALL_CAMPAIGNS ? campaignDropdown + "_" : ""
        }${audioBlock !== ALL_AUDIO_BLOCKS ? audioBlock + "_" : ""}${dateRange?.[0]?.format(
          "YYYY_MM_DD"
        )}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  return (
    <>
      <h3 className="analyticsPage-title">{title}</h3>
      <div className="flex-row-container align-center m-bxxs">
        <h4 className="h4 m-a0 bold capitalize">Insertions</h4>

        <span style={{ marginLeft: "auto" }}>
          <Select
            size="small"
            className="RC-Antd-Override-Dropdown"
            value={dropdownVal}
            onSelect={handleIntervalChange}
            style={{ width: "160px" }}>
            {dropdownOptions.map((val) => {
              return (
                <Select.Option key={val} value={val}>
                  {val}
                </Select.Option>
              );
            })}
          </Select>
        </span>
        {graphType === InsertionGraphTypes[4] &&
          listOfAudioBlocks?.length > 0 &&
          shouldShowAnalyticsUpgrade() && (
            <>
              <span className="m-lxxs">
                <Select
                  size="small"
                  className="RC-Antd-Override-Dropdown"
                  value={audioBlock}
                  virtual={false}
                  onSelect={handleAudioBlockSwitch}
                  dropdownMatchSelectWidth={false}
                  dropdownAlign={{
                    points: ["tr", "br"],
                    overflow: { adjustX: true, adjustY: true },
                  }}
                  dropdownStyle={{ maxWidth: "250px" }}
                  style={{ width: "160px" }}>
                  <Select.Option key={ALL_AUDIO_BLOCKS} value={ALL_AUDIO_BLOCKS}>
                    {ALL_AUDIO_BLOCKS}
                  </Select.Option>
                  {listOfAudioBlocks?.map((val) => {
                    return (
                      <Select.Option
                        className="RC-Antd-Override-Dropdown-Option"
                        key={val?.uuid}
                        value={val?.uuid}>
                        {middleEllipses(val?.name, 30, 8)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </span>
              <span className="m-lxxs">
                <Select
                  size="small"
                  className="RC-Antd-Override-Dropdown"
                  value={customAudio}
                  virtual={false}
                  onSelect={handleCustomAudioSwitch}
                  dropdownMatchSelectWidth={false}
                  dropdownAlign={{
                    points: ["tr", "br"],
                    overflow: { adjustX: true, adjustY: true },
                  }}
                  dropdownStyle={{ maxWidth: "250px" }}
                  style={{ width: "160px" }}>
                  <Select.Option key={ALL_CUSTOM_AUDIO} value={ALL_CUSTOM_AUDIO}>
                    {ALL_CUSTOM_AUDIO}
                  </Select.Option>
                  {listOfCustomAudios?.map((val) => {
                    return (
                      <Select.Option
                        className="RC-Antd-Override-Dropdown-Option"
                        key={val?.uuid}
                        value={val?.uuid}>
                        {middleEllipses(val?.name, 30, 8)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </span>
            </>
          )}

        {graphType === InsertionGraphTypes[1] &&
          campaignList?.length > 0 &&
          shouldShowAnalyticsUpgrade() && (
            <span className="m-lxxs">
              <Select
                size="small"
                className="RC-Antd-Override-Dropdown"
                value={campaignDropdown}
                virtual={false}
                onSelect={handleCampaignSwitch}
                dropdownMatchSelectWidth={false}
                dropdownAlign={{
                  points: ["tr", "br"],
                  overflow: { adjustX: true, adjustY: true },
                }}
                dropdownStyle={{ maxWidth: "250px" }}
                style={{ width: "160px" }}>
                <Select.Option key={ALL_CAMPAIGNS} value={ALL_CAMPAIGNS}>
                  {ALL_CAMPAIGNS}
                </Select.Option>
                {campaignList?.map((val) => {
                  return (
                    <Select.Option
                      className="RC-Antd-Override-Dropdown-Option"
                      key={val?.uuid}
                      value={val?.uuid}>
                      {middleEllipses(val?.name, 30, 8)}
                    </Select.Option>
                  );
                })}
              </Select>
            </span>
          )}
      </div>
      <Area<DataPoint>
        data={dataProvidedToGraph}
        loading={isGraphLoading}
        xField="date"
        yField="count"
        seriesField="type"
        legend={false as const}
        line={defaultLineConfig(graphType)}
        point={defaultPointConfig}
        isStack={true}
        areaStyle={defaultAreaStyle(graphType)}
        color={defaultColorConfig}
        tooltip={{
          customContent: customTooltip,
          title: tooltipTitleFactory(interval),
          showCrosshairs: true,
        }}
        meta={{
          date: {
            type: "timeCat",
            mask: getMaskFromDropdown(dropdownVal),
            range: [0, 1],
          },
          count: {
            formatter: yAxisLabelFormatter,
          },
        }}
      />
      <div className="flex-row-container justify-center align-center m-vxs">
        <span className="area-switch-text h3 m-a0">Over Time</span>
        <Switch
          className="RC-Antd-Override-Switch m-hs m-yauto"
          checked={isCumulative}
          onClick={handleSwitchChange}
        />
        <span className="area-switch-text h3 m-a0">Cumulative</span>
      </div>
      <Table
        className="analyticsPage-table"
        loading={isGraphLoading}
        dataSource={dataProvidedToTable}
        columns={columns}
        scroll={{ x: true }}
        pagination={paginationConfig}
      />
      <div className="flex-row-container align-center m-txs">
        <Icon
          name="reportDownload"
          height={16}
          width={16}
          style={{
            bottom: ".125em",
            position: "relative",
            cursor: "pointer",
          }}
          onClick={handleReportDownload}
        />
        <h4 className="h4 m-v0 m-hxxs">Dynamic Insertions Report</h4>
      </div>
    </>
  );
};

// Optimizing re-rendering from parent.
const areEqual = (prevProps: IInsertionData, nextProps: IInsertionData) => {
  const dateRangeIsSame =
    moment(prevProps?.dateRange[0]).format(dateFormatStringDiff) ===
      moment(nextProps?.dateRange[0]).format(dateFormatStringDiff) &&
    moment(prevProps?.dateRange[1]).format(dateFormatStringDiff) ===
      moment(nextProps?.dateRange[1]).format(dateFormatStringDiff);

  const timeZoneIsSame = prevProps?.timeZone === nextProps?.timeZone;
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;
  const graphTypeIsSame = prevProps?.graphType === nextProps?.graphType;

  return dateRangeIsSame && timeZoneIsSame && selectShowIsSame && graphTypeIsSame;
};

const MemoizedInsertionData = React.memo(InsertionData, areEqual);

export default MemoizedInsertionData;
