import { uniq } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { getMarkersPage } from "src/action_managers/audio_management";
import Button from "src/components/lib/button";
import EmptyStateBlock from "src/components/lib/empty_state_block";
import Loading from "src/components/lib/loading";
import { permissionTypes } from "src/constants/permission_roles";
import { useGetAudioBlocks } from "src/hooks/audio_blocks";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { useGetAllEpisodes } from "src/hooks/shows";
import { useCanAccessBound } from "src/lib/permissions";
import { Marker } from "src/reducers/markers";
import InsertionPointsCreateModal from "./insertion_points_create_modal";
import InsertionPointsTable from "./insertion_points_table";

const PAGESIZE = 100;

export default function InsertionPointsSection() {
  const dispatch = useReduxDispatch();
  const canAccess = useCanAccessBound();
  const { isLoading: isEpisodesLoading, episodes } = useGetAllEpisodes();
  const { isLoading: isAudioBlocksLoading, audioBlocks } = useGetAudioBlocks();
  const {
    isLoading: isMarkersLoading,
    markers,
    markersPageUUIDs: recentMarkerUUIDs,
  } = useSelectorTS((state) => state.markers);
  const [showCreateModal, setCreateModal] = useState(false);

  const isLoading = isEpisodesLoading || isMarkersLoading;
  const numMarkers = recentMarkerUUIDs && recentMarkerUUIDs.length;
  const numEpisodes = episodes && Object.keys(episodes).length;

  const markersByEpisodeUUID = useMemo(() => {
    const markerValues: Marker[] = Object.values(markers);
    return markerValues.reduce((acc: Record<string, Marker[]>, marker: Marker) => {
      if (!acc[marker.episodeUUID]) {
        return { ...acc, [marker.episodeUUID]: [marker] };
      } else {
        return { ...acc, [marker.episodeUUID]: [...acc[marker.episodeUUID], marker] };
      }
    }, {});
  }, [numMarkers]);

  const episodesFiltered = useMemo(() => {
    if (!numEpisodes || !numMarkers) return [];
    const uniqueEpisodeUUIDs: string[] = uniq(
      recentMarkerUUIDs.map((markerUUID: string) => markers[markerUUID].episodeUUID)
    );
    return uniqueEpisodeUUIDs
      .filter((episodeUUID) => canAccess(permissionTypes.viewDynamicInsertion, episodeUUID))
      .map((episodeUUID) => episodes[episodeUUID])
      .filter((episode) => episode)
      .reverse();
  }, [numEpisodes, numMarkers]);

  useEffect(() => {
    dispatch(getMarkersPage(PAGESIZE));
  }, []);

  return (
    <div>
      <div className="flex-row-container justify-space-between align-center m-bxs">
        <h3 className="m-b0">Insertion Points</h3>
        <Button type="secondary" onClick={() => setCreateModal(true)}>
          Add New Insertion Point
        </Button>
      </div>

      {isLoading && <Loading />}

      {!isLoading && !numMarkers && (
        <EmptyStateBlock>
          <span>
            None of your episodes have insertion points yet. Insertion points allow you to choose
            places that RedCircle can insert content into your podcast episode. You can manage your
            content yourself, or let RedCircle do it for you.{" "}
            <a
              href="https://support.redcircle.com/insertion-points?_ga=2.195770540.1479813099.1660117154-1406937417.1649223454&_gac=1.257356153.1657134963.Cj0KCQjw5ZSWBhCVARIsALERCvybxjm50QrkoIiAGIdqQ97TDo6ffmxfAuhaLogmimvfCJ7hhWUCEeAaArBCEALw_wcB"
              target="_blank"
              rel="noreferrer">
              Learn More
            </a>
          </span>
        </EmptyStateBlock>
      )}

      {!isLoading && !!numMarkers && (
        <InsertionPointsTable
          episodes={episodesFiltered}
          markersByEpisodeUUID={markersByEpisodeUUID}
          audioBlocks={audioBlocks}
        />
      )}

      <InsertionPointsCreateModal visible={showCreateModal} onClose={() => setCreateModal(false)} />
    </div>
  );
}
