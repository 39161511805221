import { Form } from "antd";
import { IListenerDemographics } from "src/reducers/shows";
import ListenerDemographicsForm from "./listener_demographics_form";

export default function RAPSettingsFormListeners() {
  return (
    <Form.Item
      name="listenerDemographics"
      rules={[
        {
          validator: (rule, value: IListenerDemographics) => {
            if (!value) return Promise.resolve();

            const totalGenderPercentage =
              value?.genderBreakdown &&
              Object.values(value.genderBreakdown).reduce((a, b) => a + b, 0);
            const totalAgePercentage = value?.ageBuckets?.reduce((a, b) => a + b.percentage, 0);

            if (totalGenderPercentage !== 100 || totalAgePercentage !== 100) {
              return Promise.reject("The total percentage for each demographic must equal 100%.");
            }
            return Promise.resolve();
          },
        },
      ]}>
      <ListenerDemographicsForm />
    </Form.Item>
  );
}
