import isEmpty from "lodash/isEmpty";
import numeral from "numeral";
import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "src/components/lib/button";
import InfoTooltip from "src/components/lib/info";
import { showError, showInfo } from "../../../../actions/app";
import { DownloadStatsActionManager } from "../../../../action_managers/stats";
import { useReduxDispatch, useSelectorTS } from "../../../../hooks/redux-ts";
import HighlightBox from "../../../lib/highlight_box";
import {
  ALL_PODCASTS,
  downloadsOverviewRequestPrefix,
  getTotalFromData,
  isExclusiveContentEnabled,
  RequestResponseDataPoint,
  StatsRequestFilter,
  useSharedSubscriptionRequest,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";

const KPI = [
  "Total Downloads",
  "Avg Episode Downloads",
  "Avg Weekly Downloads",
  "Exclusive Subscribers",
] as const;

interface IDownloadsOverview {
  selectedShow: string;
  timeZone: string;
}

const DownloadsOverview: FunctionComponent<IDownloadsOverview> = ({ selectedShow, timeZone }) => {
  const dispatch = useReduxDispatch();
  const user = useSelectorTS((state) => state?.user?.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const history = useHistory();

  const requestID = `${downloadsOverviewRequestPrefix}-${selectedShow}`;

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[requestID],
    shallowEqual
  );
  const subscriptionsByShowUUID = useSelectorTS(
    (state) => state?.subscriptions?.subscriptionsByShowUUIDs
  );
  const [showIsLoaded, allShowSubscriptionsLoaded] = useSharedSubscriptionRequest();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  // Total Downloads
  const shouldShowPrexistingTotal =
    selectedShow === ALL_PODCASTS
      ? Object.values(shows).some((show) => show?.preexistingTotalDownloads > 0)
      : shows[selectedShow]?.preexistingTotalDownloads > 0;

  const preExistingTotal =
    selectedShow === ALL_PODCASTS
      ? Object.values(shows).reduce((accu, curr) => (accu += curr?.preexistingTotalDownloads), 0)
      : shows[selectedShow]?.preexistingTotalDownloads;

  const totalDownloads = getTotalFromData(statsCacheData);
  const totalWithPreexisting = totalDownloads + preExistingTotal;

  const totalNumberOfShows = Object.keys(shows).length;

  // AVG Episode Downloads
  const averageEpisodeDownloads =
    selectedShow === ALL_PODCASTS
      ? Object.keys(shows).reduce((accu, key) => {
          return (accu += shows[key]?.averageEpisodeDownloads);
        }, 0) / totalNumberOfShows
      : shows[selectedShow]?.averageEpisodeDownloads;

  // AVG Weekly Downloads
  const averageWeeklyDownloads =
    selectedShow === ALL_PODCASTS
      ? Object.keys(shows).reduce((accu, key) => {
          return (accu += shows[key]?.estimatedWeeklyDownloads);
        }, 0) / totalNumberOfShows
      : shows[selectedShow]?.estimatedWeeklyDownloads;

  // Est Subscribers
  let estimatedSubscribers;
  if (selectedShow === ALL_PODCASTS) {
    estimatedSubscribers =
      Object.keys(subscriptionsByShowUUID).reduce((accu, key) => {
        return (accu += subscriptionsByShowUUID?.[key]?.filter(
          (subscription: any) =>
            subscription.state === "active" && subscription.type === "exclusive"
        ).length);
      }, 0) / totalNumberOfShows;
  } else {
    estimatedSubscribers = isEmpty(subscriptionsByShowUUID?.[selectedShow])
      ? 0
      : subscriptionsByShowUUID?.[selectedShow]?.filter(
          (subscription: any) =>
            subscription.state === "active" && subscription.type === "exclusive"
        ).length;
  }

  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheData)) {
      setIsDataLoading(true);
      id = setTimeout(() => {
        const filters: StatsRequestFilter = {
          isUnique: true,
          timeRange: "allTime",
          timezone: timeZone,
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters.showUUID = selectedShow;
        }

        dispatch(
          new DownloadStatsActionManager({
            filters,
            user,
            requestID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsDataLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsDataLoading(false);
          });
      }, 250);
    } else {
      setIsDataLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [selectedShow]);

  const showMiniButton =
    selectedShow &&
    selectedShow !== ALL_PODCASTS &&
    estimatedSubscribers === 0 &&
    !isExclusiveContentEnabled(shows[selectedShow]);

  const KPIMap: Record<
    (typeof KPI)[number],
    { loading: boolean; value: number; content: ReactNode }
  > = {
    [KPI[0]]: {
      loading: isDataLoading,
      value: totalDownloads,
      content: shouldShowPrexistingTotal ? (
        <div>
          <h3 className="highlight-box__stat m-a0">
            {numeral(totalWithPreexisting).format("0,0")}*
          </h3>
          <p className="m-a0 fs-11 lh-s">
            *{numeral(totalDownloads).format("0,0")} since joining RedCircle
          </p>
        </div>
      ) : (
        <h3 className="highlight-box__stat m-a0">{numeral(totalDownloads).format("0,0")}</h3>
      ),
    },
    [KPI[1]]: {
      loading: !showIsLoaded,
      value: averageEpisodeDownloads,
      content: (
        <h3 className="highlight-box__stat m-a0">
          {averageEpisodeDownloads === 0 ? (
            <span>
              Calculating
              <InfoTooltip
                direction="top"
                helpText="Number will refresh once we have more data from your podcast"
                style={{
                  color: "#C6C6C6",
                }}
              />
            </span>
          ) : (
            numeral(averageEpisodeDownloads).format("0,0")
          )}
        </h3>
      ),
    },
    [KPI[2]]: {
      loading: !showIsLoaded,
      value: averageWeeklyDownloads,
      content: (
        <h3 className="highlight-box__stat m-a0">
          {averageWeeklyDownloads === 0 ? (
            <span>
              Calculating
              <InfoTooltip
                direction="top"
                helpText="Number will refresh once we have more data from your podcast"
                style={{
                  color: "#C6C6C6",
                }}
              />
            </span>
          ) : (
            numeral(averageWeeklyDownloads).format("0,0")
          )}
        </h3>
      ),
    },
    [KPI[3]]: {
      loading: !allShowSubscriptionsLoaded,
      value: estimatedSubscribers,
      content: showMiniButton ? (
        <Button
          type="secondary"
          size="small"
          onClick={() => history.push(`/shows/${selectedShow}/i/exclusive-content`)}>
          Opt In
        </Button>
      ) : (
        <h3 className="highlight-box__stat m-a0">
          {numeral(estimatedSubscribers).format("0,0")}
        </h3>
      ),
    },
  };

  return (
    <InfoCard title={{ text: "Downloads Overview" }}>
      <div className="analyticsPage-DownloadsOverview">
        {KPI.map((key) => {
          return (
            <HighlightBox key={key} analytics={true} title={key} isLoading={KPIMap[key].loading}>
              {KPIMap[key].content}
            </HighlightBox>
          );
        })}
      </div>
    </InfoCard>
  );
};

// Optimizing re-rendering from parent.
const areEqual = (prevProps: IDownloadsOverview, nextProps: IDownloadsOverview) => {
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;
  const timeZoneIsSame = prevProps?.timeZone === nextProps?.timeZone;
  return selectShowIsSame && timeZoneIsSame;
};

const MemoizedDownLoadSection = React.memo(DownloadsOverview, areEqual);

export default MemoizedDownLoadSection;
