import isEmpty from "lodash/isEmpty";
import React from "react";
import { classNames, If } from "react-extras";
import ContextMenu from "../context_menu";
import Table from "./table";

const additionalStyle = () => ({
  style: { overflow: "visible" },
});
const tBodyAdditionalStyle = () => ({ style: { overflow: "visible", minHeight: "64px" } });
const tdAdditionalStyle = () => ({
  style: {
    overflow: "visible",
    padding: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export const StyledTable = (props) => (
  <Table
    getTdProps={tdAdditionalStyle}
    getTbodyProps={tBodyAdditionalStyle}
    getTableProps={additionalStyle}
    noDataText={""}
    getTheadProps={() => ({
      style: {
        display: "none",
      },
    })}
    {...props}
  />
);

export const TwoLineCell = ({ title, subtitle, noMaxWidth = false }) => (
  <div className={"two-line-table-row"}>
    <div className={classNames("title", noMaxWidth && "no-max-width")}>{title}</div>
    <div className="subtitle">{subtitle}</div>
  </div>
);

export const TwoLineTableRow = ({ title, subtitle, onClick = undefined, menuItems = [] }) => (
  <div
    className={classNames("flex-row-container align-center ", onClick && "pointer")}
    onClick={() => onClick && onClick()}>
    <TwoLineCell title={title} subtitle={subtitle} />
    <div className="flex-1" />
    <If condition={!isEmpty(menuItems)}>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <ContextMenu noCircle={true} menuItems={menuItems} />
      </div>
    </If>
  </div>
);
