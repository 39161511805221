import { Collapse, Input, Modal } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Field, Form, FormProps } from "react-final-form";
import { showWarning } from "../../../actions/app";
import { initiateAudioSwap } from "../../../action_managers/campaigns";
import { getMediaFile } from "../../../action_managers/shows";
import UploadModalActionManager from "../../../action_managers/upload_modal";
import { allowedAudioTypes } from "../../../constants/audio_management";
import { useReduxDispatch, useSelectorTS } from "../../../hooks/redux-ts";
import { store } from "../../../index";
import { getPromoCode, isAudioSwapRequested } from "../../../lib/campaigns";
import type { MediaFile } from "../../../reducers/media_file_upload/types";
import { IRootState } from "../../../reducers/types";
import FileUploadV2 from "../../forms/file_upload/file_upload_v2";
import FormElement from "../../forms/form_element";
import LoadingButton from "../../forms/loading_button/loading_button";
import Divider from "../../lib/divider";
import MediaPlayer from "../../lib/media_player/media_player";
import { RadioInputField } from "../../lib/radio-input/radio-input";
import WarningPanelv2 from "../../lib/warning_panel/warning_panel_v2";
import "./swap_audio_modal.scss";

const { Panel } = Collapse;

const AudioSwapReasons = [
  "Providing a unique, refreshed read (no issues)",
  "Incorrect content (eg said the wrong promo code)",
  "Issues with audio quality",
  "Uploaded the wrong file",
  "Other (please specify):",
] as const;

const MediaFileNeedsConversionStates: MediaFile["conversionState"][] = [
  "processing",
  "needs_processing",
];

type AudioSwapForm = {
  campaignItemUUID: string;
  fileUpload: string | undefined;
  reason: (typeof AudioSwapReasons)[number] | undefined;
  otherReason: string | undefined;
  feedCTA?: string;
};

interface ISwapAudioModal {
  visible: boolean;
  setVisible: (newState: boolean) => void;
  onCancel?: () => void;
  campaignItemUUID: string;
}

const SwapAudioModal = (props: ISwapAudioModal & { formProps: FormProps<AudioSwapForm> }) => {
  const { visible, setVisible, campaignItemUUID, formProps } = props;
  const { user } = useSelectorTS((state) => state.user);
  const mediaFiles = useSelectorTS((state) => state.mediaFiles);
  const isLoading = useSelectorTS((state) => state.mediaFileUpload?.isLoading);
  const campaignItem = useSelectorTS(
    (state) => state?.campaignItems?.campaignItems[campaignItemUUID]
  );
  const campaign = useSelectorTS(
    (state) => state?.campaigns?.campaigns?.[campaignItem?.campaignUUID]
  );

  const { scriptsByUUID } = useSelectorTS((state) => state?.scripts);

  const script = scriptsByUUID[campaignItem?.scriptUUID ?? ""];

  const show = useSelectorTS(
    (state) =>
      state.shows?.shows?.[campaignItem?.showUUID] || state?.publicShows?.[campaignItem?.showUUID]
  );

  const dispatch = useReduxDispatch();

  const isPodcasterViewing = user.uuid === campaignItem?.creatorUUID;
  const { isAudioSwapActive, isPodcasterInitiated } = isAudioSwapRequested(campaignItem);

  const showWarningPanel = isAudioSwapActive && isPodcasterViewing && !isPodcasterInitiated;

  const [currentPage, setCurrentPage] = useState(0);

  const { submitting } = formProps;
  const { getState, submit, reset, initialize } = formProps?.form || {};
  const values = getState ? getState()?.values : ({} as AudioSwapForm);
  const { fileUpload: mediaFileUUID = undefined, reason = undefined, feedCTA = "" } = values;

  const validation = formValidation(
    values,
    isPodcasterViewing,
    isPodcasterInitiated,
    isAudioSwapActive
  );

  // Helper
  const isFileConversionNeeded = () => {
    if (isPodcasterViewing && typeof mediaFileUUID === "string") {
      const currentMediaFile = mediaFiles?.[mediaFileUUID];
      return MediaFileNeedsConversionStates.includes(currentMediaFile.conversionState);
    }
    return false;
  };

  const valid = isEmpty(validation) && !isFileConversionNeeded();

  const radioOptions: {
    value: string;
    display: string;
    isOther?: boolean;
    optionalFields?: (props: { onChange: (input: string) => void; value: string }) => JSX.Element;
  }[] = useMemo(() => {
    return [
      {
        value: AudioSwapReasons[0],
        display: AudioSwapReasons[0],
      },
      {
        value: AudioSwapReasons[1],
        display: AudioSwapReasons[1],
      },
      {
        value: AudioSwapReasons[2],
        display: AudioSwapReasons[2],
      },
      {
        value: AudioSwapReasons[3],
        display: AudioSwapReasons[3],
      },
      {
        value: AudioSwapReasons[4],
        display: AudioSwapReasons[4],
        optionalFields: () => {
          return (
            <Field name="otherReason">
              {({ input: { onChange } }) => {
                return (
                  <FormElement
                    componentClass="textarea"
                    maxLength="350"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      onChange(e.currentTarget?.value)
                    }
                  />
                );
              }}
            </Field>
          );
        },
      },
    ];
  }, []);

  const showPodcasterExtraFields =
    (mediaFileUUID && isPodcasterViewing && !isAudioSwapActive) ||
    (mediaFileUUID && isPodcasterViewing && isAudioSwapActive && isPodcasterInitiated);

  const pages = [
    {
      title: (
        <div>
          <h3 className="m-bxxs">Replace Advertisement Audio</h3>
          <p className="title--8 m-b0">
            {modalSubtitle(
              isAudioSwapActive,
              isPodcasterInitiated,
              isPodcasterViewing,
              show?.title
            )}
          </p>
          <Divider marginTop={16} marginBottom={0} />
        </div>
      ),
      body: (
        <div>
          {showWarningPanel && (
            <WarningPanelv2 className="" disabled={false}>
              <p className="uppercase title--1" style={{ color: "#000000" }}>
                feedback from brand
              </p>
              <p>{campaignItem?.swapAudioInfo?.reason}</p>
            </WarningPanelv2>
          )}
          <Collapse ghost className="antd-collapse-override">
            <Panel
              header={<span className="color-primary bold fs-15 lh-15">Script Details</span>}
              key="1">
              <div className="flex-column-container align-start">
                <ScriptItem
                  title="campaign promotion code"
                  text={getPromoCode({ campaign, campaignItem, show, script })}
                />
                <ScriptItem title="Script or talking points" text={script?.content} isHTML={true} />
                <ScriptItem
                  title="Requirements from brand"
                  text={script?.readerGuidance}
                  isHTML={true}
                />
              </div>
            </Panel>
          </Collapse>

          {isPodcasterViewing && (
            <div className="m-bxs">
              <h3 className="title--1 bold uppercase m-bxxs" style={{ color: "#000000" }}>
                ADD CODE TO SHOW NOTES (OPTIONAL)
              </h3>
              <Field name="feedCTA">
                {({ input: { onChange } }) => {
                  return (
                    <Input.TextArea
                      className="p-axxs m-bxxxs"
                      styles={{
                        textarea: {
                          maxHeight: "100px",
                        },
                      }}
                      value={feedCTA}
                      onChange={(e) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  );
                }}
              </Field>
              <p className="fs-13 lh-s">
                The message above will be automatically added and removed from your episode
                description during the campaign.
              </p>
            </div>
          )}

          {isPodcasterViewing && (
            <h3 className="title--1 bold uppercase m-bxxs" style={{ color: "#000000" }}>
              New MP3 audio (this will replace the current audio immediately)
            </h3>
          )}
          <Field name="fileUpload">
            {({ input: { onChange } }) => {
              return (
                isPodcasterViewing && (
                  <FileUploadV2
                    onChange={handleFileUpload(onChange)}
                    isLoading={isLoading}
                    mediaFiles={mediaFiles}
                    mediaFileUUID={mediaFileUUID}
                    showMaxFileSize={false}
                    acceptedFileExtensions={allowedAudioTypes.join(",")}
                  />
                )
              );
            }}
          </Field>

          {mediaFileUUID && isPodcasterViewing && (
            <div>
              <MediaPlayer mediaFileUUID={mediaFileUUID} />
            </div>
          )}

          <div>
            {showPodcasterExtraFields && (
              <>
                <p className="title--6">Let the brand know why you’re replacing this audio file:</p>
                <Divider marginBottom={5} marginTop={5} />
              </>
            )}

            {showPodcasterExtraFields && (
              <Field name="reason">
                {({ input: { onChange } }) => {
                  return (
                    mediaFileUUID &&
                    isPodcasterViewing && (
                      <RadioInputField
                        value={reason}
                        defaultValue={undefined}
                        className=""
                        onChange={onChange}
                        options={radioOptions}
                      />
                    )
                  );
                }}
              </Field>
            )}

            {!isPodcasterViewing && <p className="bold fs-15 lh-s">Instructions for Podcaster:</p>}

            {!isPodcasterViewing && (
              <Field name="reason">
                {({ input: { onChange } }) => {
                  return (
                    <FormElement
                      componentClass="textarea"
                      maxLength="600"
                      minLength="25"
                      placeholder="Please be specific about the issue so the podcaster knows exactly what they need to fix."
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        onChange(e.currentTarget?.value)
                      }
                    />
                  );
                }}
              </Field>
            )}
          </div>
        </div>
      ),
      footer: (
        <div className="flex-row-container align-center justify-space-between">
          <button className="btn btn-link capitalize" onClick={() => setVisible(false)}>
            cancel
          </button>
          <LoadingButton
            onClick={() => {
              submit &&
                submit()?.then((resp: any) => {
                  if (resp?.status === 200) setCurrentPage((prev) => (prev + 1) % pages.length);
                });
            }}
            className="btn btn-primary btn-large fs-xs lh-s p-hl m-b0 capitalize"
            disabledMessage={Object.values(validation)?.[0]}
            isLoading={submitting || isFileConversionNeeded()}
            disabled={!valid}>
            {isPodcasterViewing ? "Replace Audio" : "Submit & Pause Campaign"}
          </LoadingButton>
        </div>
      ),
    },
    {
      title: (
        <div>
          <h4 className="bold title--2 m-bxxs">
            {isPodcasterViewing ? "Advertisement Audio Replaced" : "Campaign Paused"}
          </h4>
          <Divider marginTop={16} marginBottom={0} />
        </div>
      ),
      body: (
        <p className="title--4 m-vs">
          {isPodcasterViewing
            ? "Please note, it may take your listeners up to 24 hours to hear this updated audio if they already downloaded episodes with the previous audio inserted."
            : "The campaign is paused on this podcast and we’ll notify you once they replace their audio."}
        </p>
      ),
      footer: (
        <div className="flex-row-container align-center justify-end">
          <LoadingButton
            onClick={() => handleCancel()}
            className="btn btn-primary btn-large fs-xs lh-s p-hl m-b0 uppercase">
            ok
          </LoadingButton>
        </div>
      ),
    },
  ];

  // Handlers
  const handleFileUpload = (onChange: (prop: any) => void) => {
    return (files: any) => {
      const file = files[0];

      return dispatch(
        new UploadModalActionManager({
          file,
          fileName: file.name,
          authToken: user.authToken,
        }).run()
      ).then((resp) => {
        if (resp.status === 200) {
          const json = resp?.json;
          const mediaFile = json[0];
          onChange(mediaFile.uuid);
          handleEnsureMediaFileConverted(user.authToken, mediaFile.uuid);
        }
      });
    };
  };

  const handleEnsureMediaFileConverted = (podcasterAuthToken: string, mediaFileUUID: string) => {
    const pollingFrequency = 1500;

    const getConversionState = () => {
      setTimeout(() => {
        dispatch(getMediaFile(mediaFileUUID, podcasterAuthToken)).then(
          (resp: { status: number; json: MediaFile }) => {
            if (MediaFileNeedsConversionStates.includes(resp.json?.conversionState)) {
              getConversionState();
            } else if (resp.json?.conversionState === "failed") {
              // Adding for better user experience, unlikely to happen. Usually due to corrupted file
              // The error is caught here and in the audio swap request response. This gives user earlier
              dispatch(
                showWarning(
                  "Media file was successfully loaded but failed in a conversion process. Please load the file again before submitting the form.",
                  5000
                )
              );
            }
          }
        );
      }, pollingFrequency);
    };

    getConversionState();
  };

  const handleCancel = () => {
    reset?.();
    setVisible(false);
    setCurrentPage(0);
  };

  // Effects

  useEffect(() => {
    initialize?.({
      campaignItemUUID: campaignItem?.uuid,
      feedCTA: campaignItem?.feedCTA,
    });
  }, [campaignItem?.uuid, campaignItem?.feedCTA]);

  return (
    <Modal
      wrapClassName="RC-Antd-Override-Modal footer-shadow"
      width={600}
      open={visible}
      centered={true}
      onCancel={() => handleCancel()}
      title={pages[currentPage]?.title}
      footer={pages[currentPage]?.footer}>
      {pages[currentPage]?.body}
    </Modal>
  );
};

interface IScriptItem {
  title: string | JSX.Element;
  text?: string | string[];
  isHTML?: boolean;
}

const ScriptItem = (props: IScriptItem) => {
  const { title, text, isHTML = false } = props;

  const style = { fontSize: "16px", lineHeight: "20px", marginBottom: "0px!important" };

  let content = undefined;

  if (typeof text !== "undefined") {
    if (Array.isArray(text)) {
      content = (text as Array<string>).map((item, index) => {
        return isHTML ? (
          <li key={index} style={style} dangerouslySetInnerHTML={{ __html: item }} />
        ) : (
          <li key={index} style={style}>
            {item}
          </li>
        );
      });
    } else {
      content = isHTML ? (
        <li style={style} className="m-b0" dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <li style={style}>{text}</li>
      );
    }
  }

  return (
    <div className="flex-column-container align-start m-bs">
      <h4 className="title--1 uppercase" style={{ color: "#000000" }}>
        {title}
      </h4>
      <ul className="script-item-list">{content}</ul>
    </div>
  );
};

// Helper Form Wrapper
const FinalFormWrapper = <T, FormValues>(formConfig: FormProps<FormValues>) => {
  return (WrappedCompoonent: any) => {
    return (props: T) => {
      return (
        <Form<FormValues> {...formConfig}>
          {({ handleSubmit, ...formProps }) => {
            return (
              <form onSubmit={handleSubmit}>
                <WrappedCompoonent {...props} formProps={formProps} />
              </form>
            );
          }}
        </Form>
      );
    };
  };
};

// Helper Form Props
const formValidation = (
  values: AudioSwapForm,
  isPodcasterViewing: boolean,
  isPodcasterInitiated: boolean,
  isAudioSwapActive: boolean
) => {
  const { reason, fileUpload, otherReason } = values;

  const errors: Partial<Record<keyof AudioSwapForm, string>> = {};

  if (isPodcasterViewing) {
    // Validate file Upload
    switch (fileUpload) {
      case undefined:
        errors["fileUpload"] = "Please upload an audio file";
        break;
      default:
        if (fileUpload?.length < 1) {
          errors["fileUpload"] = "Please upload an audio file";
        }
    }

    if (!isAudioSwapActive || isPodcasterInitiated) {
      // Validate Reason
      switch (reason) {
        case undefined:
          errors["reason"] = "Reason cannot be empty";
          break;
        default:
          if (!AudioSwapReasons.includes(reason) && reason?.length < 1) {
            errors["reason"] = "Please provide a reason";
          }
      }

      // Validate Podcaster Other long text reason
      if (reason === AudioSwapReasons[4]) {
        switch (otherReason) {
          case undefined:
            errors["otherReason"] = "Please provide the reason for the audio swap request";
            break;
          default:
            if (otherReason?.length < 1) {
              errors["otherReason"] = "Please provide the reason for the audio swap request";
            }
        }
      }
    }
  } else {
    // Validate Reason for Advertiser
    switch (reason) {
      case undefined:
        errors["reason"] = "Reason cannot be empty";
        break;
      default:
        if (reason?.length < 25) {
          errors["reason"] = "Your reason must be 25 characters or more";
        }
    }
  }

  return errors;
};

const modalSubtitle = (
  isAudioSwapActive: boolean,
  isPodcasterInitiated: boolean,
  isPodcasterViewing: boolean,
  showName?: string
) => {
  if (isPodcasterViewing) {
    if (isAudioSwapActive) {
      if (isPodcasterInitiated) {
        return "Replace your existing audio file with new audio and select a reason why. The new audio file will replace the current audio immediately.";
      } else {
        return "Please review the brand’s feedback and script below then upload new ad audio.";
      }
    }

    return "Replace your existing audio file with new audio and select a reason why. The new audio file will replace the current audio immediately.";
  } else {
    return (
      <span>
        Is there an issue with this podcast's ad audio? Once you submit this request, the campaign
        will be paused on {showName ? <b>{showName}</b> : "this Podcast"} until they upload
        replacement audio.
      </span>
    );
  }
};

const formSubmission: FormProps<AudioSwapForm>["onSubmit"] = (values: AudioSwapForm) => {
  const { campaignItemUUID, fileUpload, reason, otherReason, feedCTA = "" } = values;
  const reasonSubmission = reason === AudioSwapReasons[4] ? otherReason : reason;

  const state: IRootState = store.getState();

  const user = state?.user?.user;
  const campaignItem = state?.campaignItems?.campaignItems?.[campaignItemUUID];

  const isPodcaster = user.uuid === campaignItem?.creatorUUID;

  return store.dispatch(
    initiateAudioSwap({
      isPodcaster,
      campaignItemUUID,
      newMediaFileUUID: fileUpload,
      reason: reasonSubmission,
      feedCTA,
    })
  );
};

export default FinalFormWrapper<ISwapAudioModal, AudioSwapForm>({
  onSubmit: formSubmission,
})(SwapAudioModal);
