import { Budget } from "./budgets";
import { IShow } from "./show";

export interface IFlightConfig {
  weekStartAt: string;
  targetEpisodeCountInTimeRange: number;
  assignedEpisodeUUIDs: { [key: string]: string };
  invoicedEpisodeUUIDs?: { [key: string]: Record<string, unknown> };
}

export interface IOfferRates {
  enabled: boolean;
  finalAdjustedMidRollCPM: number;
  finalAdjustedPostRollCPM: number;
  finalAdjustedPreRollCPM: number;
  finalAdjustedSpotRate: number;
  originalMidRollCPM: number;
  originalPostRollCPM: number;
  originalPreRollCPM: number;
  originalSpotRate: number;
}

// CampaignItemStateDraft represents a campaign item type in draft state
export type CampaignItemState =
  | "vetting" // this is a FE only state, vetting is actually a separately tracked entity
  | "draft"
  | "sent"
  | "running"
  | "paused"
  | "awaiting-audio-upload"
  | "audio-swap-requested"
  | "accepted"
  | "declined"
  | "completed"
  | "expired"
  | "awaiting-script";

export interface ICampaignItem {
  uuid: string;
  campaignUUID: string;
  campaignName: string;
  shoppingCartUUID?: string;
  creatorUUID: string;
  showUUID: string;
  budgetUUID?: string;
  adUUID?: string;
  mediaFileUUID?: string;
  paidUpToAmount: number;
  paidOutAt?: number;
  invoicedUpToAmount: number;
  totalBudget: number; // total Budget in cents i.e (10000 => $100) or (25000 => $250)
  totalCreatorAmount: number;
  invoicedUpToCreatorAmount: number;
  startAt: number; // Campaign Start Date
  respondByAt?: number; // Show Response Due Date
  respondedAt?: number;
  pacingEstimatedEndAt?: number; // On paced campaigns, campaign Item pacing estimated end date
  state: CampaignItemState;
  completedAt?: number;
  createdAt: number;
  updatedAt: number;
  uploadAudioBy?: number; // Audio Due Date
  scriptDueBy?: number; // Script Due Date
  transactionUUID?: string;
  sentAt: number; // Shopping Cart Sent Date
  promoCode?: string;
  redemptionCodes: string[];
  advertisingCutBasisPoints: number;
  flightConfigs: IFlightConfig[];
  spotRate: number;
  webhookURL: string;
  declinedReason: string;
  offerRates: IOfferRates;
  show?: IShow;
  swapAudioInfo: {
    initiatorUserUUID: string;
    requestedAt: number;
    reason: string;
  };
  audioLastUploadedAt: number;
  budget?: Budget;
  expiredAt?: number;

  scriptUUID?: string;
  feedCTA?: string;
}

/**
 * Type guard to check if an unknown value is a valid campaignItem object,
 * re enforces the Campaign type post check
 *
 * @param value
 * @returns Boolean
 */
export function isCampaignItem(value?: unknown): value is ICampaignItem {
  const stateValuesMapCheck: Record<CampaignItemState, true> = {
    draft: true,
    sent: true,
    running: true,
    paused: true,
    "awaiting-audio-upload": true,
    "audio-swap-requested": true,
    accepted: true,
    declined: true,
    completed: true,
    expired: true,
    "awaiting-script": true,
    vetting: true,
  };

  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "campaignUUID" in value &&
    "showUUID" in value &&
    "creatorUUID" in value &&
    "state" in value &&
    typeof value.uuid === "string" &&
    typeof value.state === "string" &&
    typeof value.campaignUUID === "string" &&
    typeof value.showUUID === "string"
  ) {
    return Object.keys(stateValuesMapCheck).includes(value.state);
  }

  return false;
}
