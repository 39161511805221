import { CONVERSION_STATE_KEY_DONE } from "src/constants/conversion_states";
import { CustomAudio } from "src/reducers/custom_audio";
import { MediaFile } from "src/reducers/media_file_upload";

type InvalidReasons = { paused?: boolean; date?: boolean; impressions?: boolean };

// gathers all reasons for custom audio to be skipped
export const getCustomAudioInvalids = (customAudio?: CustomAudio): InvalidReasons => {
  if (!customAudio) return {};
  const invalids: InvalidReasons = {};

  if (customAudio.isPaused) invalids.paused = true;
  if (customAudio.endAt && customAudio.endAt < Date.now() / 1000) invalids.date = true;
  if (customAudio.startAt && customAudio.startAt > Date.now() / 1000) invalids.date = true;
  if (
    customAudio.insertions &&
    customAudio.maxInsertions &&
    customAudio.insertions === customAudio.maxInsertions
  ) {
    invalids.impressions = true;
  }
  return invalids;
};

export const mediaFileReady = (mediaFile: MediaFile): boolean => {
  if (!mediaFile) {
    return false;
  }

  return mediaFile.conversionState === CONVERSION_STATE_KEY_DONE;
};
