import React, { useState } from "react";
import { Modal } from "redcircle-ui";
import { showError } from "../../actions/app";
import { SignOutActionManager } from "../../action_managers/account";
import { deleteCredential } from "../../action_managers/credentials";
import { useReduxDispatch, useSelectorTS } from "../../hooks/redux-ts";
import "./modal.scss";

// TYPES
interface IDeleteTeamSeat {
  credentialUUID: string;
  visible: boolean; // Parent controlled modal visibility
  setVisible: React.Dispatch<React.SetStateAction<boolean>>; // Parent change function form visibility
}

const DeleteTeamSeatModal = (props: IDeleteTeamSeat) => {
  const { credentialUUID, visible, setVisible } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useSelectorTS((state) => state?.user);
  const dispatch = useReduxDispatch();

  // Hanlders
  const handleCloseModal = () => setVisible(false);
  const handleDeleteCredential = async () => {
    try {
      setIsSubmitting(true);
      await dispatch(deleteCredential(credentialUUID));
      setVisible(false);
      // User removed themselves, log then out
      if (credentialUUID === user.credentialUUID) {
        dispatch(new SignOutActionManager({ token: user.authToken }).run());
      }
    } catch (err) {
      dispatch(
        showError(
          "There was an error attempting to remove the team seat, Please contact your Org admin or try again later.",
          5000
        )
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={visible} onClose={handleCloseModal} size="xs">
      <Modal.Title>Delete User from Org</Modal.Title>
      <Modal.Body>
        Are you sure? This action will permanently delete this user’s account from RedCircle.
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton />
        <Modal.SubmitButton isLoading={isSubmitting} onClick={handleDeleteCredential}>
          Delete User
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTeamSeatModal;
