import { Checkbox, Form, InputNumber, Tooltip } from "antd";
import { AiFillInfoCircle } from "react-icons/ai";
import EmptyStateBlock from "src/components/lib/empty_state_block/EmptyStateBlock";
import { calculateDefaultCPMsForShow, validateCPM } from "src/lib/rap";
import { IShow } from "src/reducers/shows";

const MIN_CPM = "0";
const MAX_CPM = "100";

export default function RAPSettingsFormRates({ show }: { show?: IShow }) {
  const form = Form.useFormInstance();

  const isHostReadEnabled = Form.useWatch(["advertisementSettings", "isHostReadEnabled"], form);
  const hostReadPreRollCPM = Form.useWatch(["advertisementSettings", "hostReadPreRollCPM"], form);
  const hostReadMidRollCPM = Form.useWatch(["advertisementSettings", "hostReadMidRollCPM"], form);
  const hostReadPostRollCPM = Form.useWatch(["advertisementSettings", "hostReadPostRollCPM"], form);

  const defaultCPMsByType = calculateDefaultCPMsForShow(show);
  const cpmTypeToDefault = {
    preRoll: defaultCPMsByType.hostRead.discount,
    midRoll: defaultCPMsByType.hostRead.default,
    postRoll: defaultCPMsByType.hostRead.discount,
  };

  const generateCPMFeedback = (cpm: number, type: "preRoll" | "midRoll" | "postRoll") => {
    const [hasError, message] = validateCPM(cpm * 100, cpmTypeToDefault[type]);
    if (!hasError && message) {
      return <small className={hasError ? "color-error" : "color-warning-dark"}>{message}</small>;
    }
  };

  return (
    <div>
      <Form.Item name={["advertisementSettings", "isHostReadEnabled"]} valuePropName="checked">
        <Checkbox>
          <div className="flex-row-container align-center">
            <h3 className="m-rxxxs">Host-Read Ads</h3>
            <Tooltip title="You will read and record a 60 second ad endorsement in your own voice. Brands will provide script or talking points for these campaigns.">
              <AiFillInfoCircle />
            </Tooltip>
          </div>
        </Checkbox>
      </Form.Item>

      {!isHostReadEnabled && (
        <EmptyStateBlock>
          <strong className="redcircle-form-label">WHAT ARE HOST-READ ADS?</strong>
          You will read and record a 60 second ad endorsement in your own voice. Brands will provide
          script or talking points for these campaigns.
        </EmptyStateBlock>
      )}

      {isHostReadEnabled && (
        <div className="m-ls">
          <h5>CPM Rates</h5>
          <p>
            CPM is the price that you want advertisers to pay for every 1000 downloads when they
            purchase across All Episodes on your show’s catalog.
          </p>
          <div className="flex-row-container">
            <div className="m-rxs flex-1">
              <strong className="redcircle-form-label">PRE-ROLL</strong>
              <Form.Item
                name={["advertisementSettings", "hostReadPreRollCPM"]}
                className="m-b0"
                rules={[
                  {
                    validator: async (_, value) => {
                      const [hasError, message] = validateCPM(
                        value * 100,
                        cpmTypeToDefault.preRoll
                      );
                      return hasError ? Promise.reject(message) : Promise.resolve();
                    },
                  },
                ]}>
                <InputNumber
                  prefix="$"
                  placeholder="0.00"
                  size="large"
                  className="width-100"
                  min={MIN_CPM}
                  max={MAX_CPM}
                  precision={2}
                />
              </Form.Item>
              {generateCPMFeedback(hostReadPreRollCPM, "preRoll")}
            </div>
            <div className="m-rxs flex-1">
              <strong className="redcircle-form-label">MID-ROLL</strong>
              <Form.Item
                name={["advertisementSettings", "hostReadMidRollCPM"]}
                className="m-b0"
                rules={[
                  {
                    validator: async (_, value) => {
                      const [hasError, message] = validateCPM(
                        value * 100,
                        cpmTypeToDefault.midRoll
                      );
                      return hasError ? Promise.reject(message) : Promise.resolve();
                    },
                  },
                ]}>
                <InputNumber
                  prefix="$"
                  placeholder="0.00"
                  size="large"
                  className="width-100"
                  min={MIN_CPM}
                  max={MAX_CPM}
                  precision={2}
                />
              </Form.Item>
              {generateCPMFeedback(hostReadMidRollCPM, "midRoll")}
            </div>
            <div className="flex-1">
              <strong className="redcircle-form-label">POST-ROLL</strong>
              <Form.Item
                name={["advertisementSettings", "hostReadPostRollCPM"]}
                className="m-b0"
                rules={[
                  {
                    validator: async (_, value) => {
                      const [hasError, message] = validateCPM(
                        value * 100,
                        cpmTypeToDefault.postRoll
                      );
                      return hasError ? Promise.reject(message) : Promise.resolve();
                    },
                  },
                ]}>
                <InputNumber
                  prefix="$"
                  placeholder="0.00"
                  size="large"
                  className="width-100"
                  min={MIN_CPM}
                  max={MAX_CPM}
                  precision={2}
                />
              </Form.Item>
              {generateCPMFeedback(hostReadPostRollCPM, "postRoll")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
