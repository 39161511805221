import { Tree } from "antd";
import Slider from "antd/es/slider";
import groupBy from "lodash/groupBy";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { itunesCategoriesMap } from "../../../data/itunes_categories";
import { hummanizeNumber } from "../../../lib/numbers";
import { CheckMark } from "../utility_components";

export const CheckBoxFilter = ({ options, onChange, values, close }) => {
  const [internalValues, setValues] = useState({});
  useEffect(() => {
    // the internal representation is {[categoryUUID]: bool}, whereas the API uses []categoryUUID. so we have to convert it.
    if (values) {
      setValues(values.reduce((agg, next) => ({ ...agg, [next]: true }), {}));
    } else {
      setValues({});
    }
  }, [values]);
  return (
    <div className={"p-hxs p-txs p-bxl"} style={{ maxHeight: 300, overflow: "auto" }}>
      {options.map(({ value, label }) => {
        return (
          <div className={"p-vxxxs flex-row-container"} key={value}>
            <div className={"position-relative"} style={{ width: 20, height: 10 }}>
              <CheckMark
                checked={!!internalValues[value]}
                onClick={(e) => {
                  setValues({
                    ...internalValues,
                    [value]: e.target.checked,
                  });
                }}
              />
            </div>
            <div className={"m-lxxs"}>{label}</div>
          </div>
        );
      })}
      <FilterFooter
        onReset={() => {
          onChange();
          setValues({});
          close();
        }}
        onOk={(e) => {
          e.preventDefault();
          const output = Object.entries(internalValues)
            .filter(([key, val]) => val)
            .map(head);
          onChange(output);
          close();
        }}
      />
    </div>
  );
};

const FilterFooter = ({ onReset, onOk }) => {
  return (
    <div
      className={"flex-row-container justify-space-between m-txxs p-vxxs p-hxs width-100"}
      style={{ position: "absolute", bottom: 0, background: "white", left: 0 }}>
      <button
        className="btn btn-tertiary p-hxs p-vxxxs m-b0"
        style={{ minWidth: "0" }}
        onClick={onReset}>
        Reset
      </button>
      <button
        className="btn btn-primary p-hxs p-vxxxs  m-b0"
        style={{ minWidth: "0" }}
        onClick={onOk}>
        OK
      </button>
    </div>
  );
};

export const SliderFilter = ({
  min,
  max,
  close,
  onChange,
  formatNumber = hummanizeNumber,
  values: externalValues,
}) => {
  const [values, setValues] = useState([min, max]);
  const [sliderValues, setSliderValues] = useState([0, 100]);
  useEffect(() => {
    if (externalValues) {
      setValues(externalValues);
    } else {
      setSliderValues([0, 100]);
      setValues([min, max]);
    }
  }, [externalValues, min, max]);
  const sliderValueToActualValue = (sliderVal) => {
    return Math.round(((sliderVal / 100) * (max - min) + min) / 100) * 100;
  };
  const onAfterChange = (sliderValues) => {
    setValues(sliderValues.map(sliderValueToActualValue));
  };
  return (
    <div className={"p-axs"} style={{ width: 200, height: 152, borderRadius: 16 }}>
      <div>
        <span className="bold black-text">Filtered: </span>
        {values.map(formatNumber).join(" - ")}
      </div>
      <Slider
        range
        onAfterChange={onAfterChange}
        defaultValue={[0, 100]}
        onChange={setSliderValues}
        value={sliderValues}
        tipFormatter={(val) => formatNumber(sliderValueToActualValue(val))}
      />
      <FilterFooter
        onReset={() => {
          onChange();
          setValues([min, max]);
          setSliderValues([0, 100]);
          close();
        }}
        onOk={(e) => {
          onChange(values);
          close();
        }}
      />
    </div>
  );
};

export const CategoryFilter = ({ categories, ...rest }) => {
  const getTree = () => {
    if (!categories) {
      return [];
    }
    const categoriesAndParentsUUIDs = categories.reduce((agg, uuid) => {
      const category = { ...itunesCategoriesMap[uuid] };
      if (isEmpty(category)) {
        return agg;
      }
      agg.add(uuid);
      if (category.group_uuid) {
        agg.add(category.group_uuid);
      }
      return agg;
    }, new Set());
    const categoriesAndParents = Array.from(categoriesAndParentsUUIDs).map((uuid) => ({
      ...itunesCategoriesMap[uuid],
      key: uuid,
      title: itunesCategoriesMap[uuid].name,
    }));
    const nodeEdge = groupBy(Array.from(categoriesAndParents), "group_uuid");
    const tree = nodeEdge.undefined.map((category) => ({
      ...category,
      children: nodeEdge[category.uuid],
    }));
    //Sort tree data
    Array.isArray(tree) &&
      tree.sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0));
    Array.isArray(tree) &&
      tree.forEach(({ children }) =>
        children?.sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0))
      );
    return tree;
  };

  const onCheck = () => {};

  return (
    <div className={"p-vxs"} style={{ minWidth: 250 }}>
      <Tree checkStrictly={false} onCheck={onCheck} treeData={getTree()} {...rest} />
    </div>
  );
};
