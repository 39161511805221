import keyBy from "lodash/keyBy";
import { GET_EPISODE } from "../../actions/episodes";
import { EPISODE_DELETE } from "../../actions/episode_delete_modal";
import {
  EPISODE_PUT,
  EPISODE_PUT_START,
  EPISODE_PUT_SUCCESS,
  SHOW_EPISODE_FETCH,
  SHOW_EPISODE_LIST_FETCH,
} from "../../actions/shows";
import {
  UPLOAD_TO_YOUTUBE,
  VALIDATE_EPISODE_CREATE,
  VALIDATE_EPISODE_UPDATE,
} from "../../action_managers/shows";
import { httpMultipleActionsReducer, httpReducer, reduceReducers } from "../../lib/create-action";
import { ValidationError } from "../types";
import { IEpisode } from "./types";

const initialShowEpisodesState = {} as {
  [key: string]: {
    isLoading: boolean;
    episodes: { [key: string]: IEpisode };
    errorMessage?: string;
    isPutting: boolean;
    newUUID?: string;
    validationErrors: ValidationError;
  };
};

export type episodeByShowType = typeof initialShowEpisodesState;

const getEpisode = httpMultipleActionsReducer(
  [
    GET_EPISODE,
    SHOW_EPISODE_FETCH,
    UPLOAD_TO_YOUTUBE,
    EPISODE_PUT,
    VALIDATE_EPISODE_UPDATE,
    VALIDATE_EPISODE_CREATE,
  ],
  initialShowEpisodesState,
  {
    failure: (state, action) => {
      const showUUID = action?.data?.showUUID;
      if (!showUUID) {
        return state;
      }
      return {
        ...state,
        [showUUID]: {
          ...state[showUUID],
          isLoading: false,
          isPutting: false,
          validationErrors: action.errors,
        },
      };
    },
    failureBadRequest: (state, action) => {
      const episode = action.payload.body;
      const showUUID = episode.showUUID;
      return {
        ...state,
        [showUUID]: {
          ...state[showUUID],
          isLoading: false,
          isPutting: false,
          validationErrors: action.payload.validationErrors,
        },
      };
    },
    started: (state, action) => {
      const showUUID = action.data?.showUUID;
      if (!showUUID) {
        return state;
      }
      return {
        ...state,
        [showUUID]: {
          ...state[showUUID],
          episodes: {},
          isLoading: true,
          newUUID: null,
          validationErrors: [],
          ...(action.type === EPISODE_PUT_START && { isPutting: true }),
        },
      };
    },
    success: (state, action) => {
      const episode = action.payload?.resp || action.episode;
      const showUUID = episode.showUUID;
      return {
        ...state,
        [showUUID]: {
          ...state[showUUID],
          ...(action.type === EPISODE_PUT_SUCCESS && {
            isLoading: false,
            isPutting: false,
            isSuccess: true,
            newUUID: episode.uuid,
          }),
          episodes: {
            ...state[showUUID]?.episodes,
            [episode.uuid]: episode,
          },
          isLoading: false,
        },
      };
    },
  }
);

const episodesByShow = httpReducer(SHOW_EPISODE_LIST_FETCH, initialShowEpisodesState, {
  success: (state, action) => {
    return {
      ...state,
      [action.data.showUUID]: {
        episodes: keyBy(action.payload.resp, "uuid"),
        isLoading: false,
      },
    };
  },
});
const deleteEpisode = httpReducer(EPISODE_DELETE, initialShowEpisodesState, {
  success: (state, action) => {
    const newState = { ...state };
    delete newState[action.data.showUUID]?.episodes?.[action.data.episodeUUID];
    return newState;
  },
});

export default reduceReducers(episodesByShow, getEpisode, deleteEpisode);
