import AlertIcon from "@iconify/icons-mdi/alert";
import { Icon } from "@iconify/react";
import {
  DatePicker,
  InputNumber,
  InputNumberProps,
  Popconfirm,
  Popover,
  PopoverProps,
  Tooltip,
} from "antd";
import Moment from "moment";
import numeral from "numeral";
import React, { useEffect, useRef, useState } from "react";
import { updateCampaignItems } from "src/action_managers/campaigns";
import { ForecastEndDateResponse, ForecastImpressionResponse } from "src/api/campaigns";
import RCButton from "src/components/lib/button/button";
import { useReduxDispatch } from "src/hooks/redux-ts";
import {
  getAverageCPM,
  getBudget,
  getDailyImpressions,
  getImpressionsFromBudget,
  getImpressionsWOBudget,
} from "src/lib/campaigns";
import { localDate, startOfDay, UnixTimeStamp } from "src/lib/date";
import { formatMoney } from "src/lib/format-money";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShow } from "src/reducers/public_show";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import type { Moment as MomentType } from "moment";
import { AiOutlineClose } from "react-icons/ai";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";

/**
 * Helper Reducers
 */

/**
 * Reducer function to capture the state of the streamulator forecast impressions response
 */
export interface IMaxImpressionReducer<
  T = { [showUUID: string]: { impressions: number; streamulatorErrored: boolean } },
> {
  (
    state: { isLoading: boolean; error?: string; maxImpressions: T },
    action:
      | { type: "request" }
      | { type: "success"; maxImpressions: T }
      | { type: "failure"; error?: string; maxImpressions: T }
  ): { isLoading: boolean; error?: string; maxImpressions: T };
}

export const maxImpressionReducer: IMaxImpressionReducer = (state, action) => {
  switch (action.type) {
    case "request":
      return {
        ...state,
        isLoading: true,
      };
    case "success":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        maxImpressions: action.maxImpressions,
      };
    case "failure":
      return {
        ...state,
        isLoading: false,
        error: action?.error,
        maxImpressions: action.maxImpressions,
      };

    default:
      return { ...state };
  }
};

/**
 * Reducer function to capture the state of the streamulator forecast min end dates response
 */
export interface IMinEndDatesReducer<T = ForecastEndDateResponse[keyof ForecastEndDateResponse]> {
  (
    state: {
      isLoading: boolean;
      error?: string;
      minEndDates: T;
    },
    action:
      | { type: "request" }
      | { type: "success"; minEndDates: T }
      | { type: "failure"; error?: string; minEndDates: T }
  ): {
    isLoading: boolean;
    error?: string;
    minEndDates: T;
  };
}

export const minEndDatesReducer: IMinEndDatesReducer = (state, action) => {
  switch (action.type) {
    case "request":
      return {
        ...state,
        isLoading: true,
      };
    case "success":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        minEndDates: action.minEndDates,
      };
    case "failure":
      return {
        ...state,
        isLoading: false,
        error: action.error,
        minEndDates: action.minEndDates,
      };

    default:
      return { ...state };
  }
};

/**
 * Helper Funcs and Components
 */

export const createGetItemInfo = ({
  campaignItems,
  publicShows,
}: {
  campaignItems?: ICampaignItem[];
  publicShows?: Record<string, PublicShow>;
}) => {
  return (campaignItemUUID: string) => {
    const campaignItem = campaignItems?.find((item) => item.uuid === campaignItemUUID);
    const showUUID = campaignItem?.showUUID ?? "";
    const publicShow = publicShows?.[showUUID];
    return {
      campaignItem,
      publicShow,
    };
  };
};

export const convertRespToImpressionsMap = (resp: ForecastImpressionResponse) => {
  const map = Object.entries(resp?.["resultsByShowUUID"] ?? {}).reduce(
    (mapper, curr) => {
      const [showUUID, val] = curr;

      mapper[showUUID] = {
        impressions: Object.values(val.impressionsByPosition ?? {}).reduce((accu, impressions) => {
          return accu + impressions;
        }, 0),
        streamulatorErrored: val.streamulatorErrored,
      };
      return mapper;
    },
    {} as Record<string, { impressions: number; streamulatorErrored: boolean }>
  );

  return map;
};

export interface ICustomCell {
  campaignItemUUID: string;
  publicShow: PublicShow;
  campaignItem: ICampaignItem;
  campaign: ICampaign;
  timeline: [Moment.Moment, Moment.Moment];
  budgets: { [campaignItemUUID: string]: number };
  updateBudgets: React.Dispatch<React.SetStateAction<{ [campaignItemUUID: string]: number }>>;
  max?: number;
}

export const CustomTimelineCell = ({
  timeline,
  forecastEndDate,
  isLoading,
}: Pick<ICustomCell, "timeline"> & {
  forecastEndDate?: UnixTimeStamp | null;
  isLoading?: boolean;
}) => {
  const itemStartDate = timeline?.[0]?.unix() ?? 0;

  return (
    <div className="blocktile fs-13 lh-xs p-vxxxs">
      {`${localDate(itemStartDate)} - ${
        typeof forecastEndDate === "number" && !isLoading
          ? localDate(forecastEndDate)
          : "...loading"
      }`}
    </div>
  );
};

export const CustomImpressionsCell = ({
  campaignItemUUID,
  campaign,
  publicShow,
  campaignItem,
  timeline,
  budgets,
  updateBudgets,
  max,
}: ICustomCell) => {
  const cpm = getAverageCPM({ show: publicShow, campaign, campaignItem });
  const dailyImpressions = getDailyImpressions(publicShow);
  const isPacing = Boolean(campaign?.pacing);
  const isMaxEnabled = isPacing && typeof max === "number" && max >= 0;

  let impressions = getImpressionsWOBudget({
    dailyImpressions,
    startDate: timeline?.[0]?.unix() ?? 1,
    endDate: timeline?.[1]?.unix() ?? 1,
  });

  if (typeof budgets?.[campaignItemUUID] === "number") {
    impressions = getImpressionsFromBudget({ cpm, budget: budgets[campaignItemUUID] });
  }

  const [newTempImpressions, setNewTempImpressions] = useState(impressions);
  const [isPopoverOpen, setPopoverOpen] = useState<boolean | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  const onUpdateImpressions = () => {
    const newBudget = (newTempImpressions / 1000) * cpm;

    updateBudgets((prev) => {
      const newBudgets = { ...prev };
      newBudgets[campaignItemUUID] = newBudget;
      return newBudgets;
    });

    setPopoverOpen(false);
  };

  const handleOpenChange: PopoverProps["onOpenChange"] = (visible) => {
    if (!visible) {
      setTimeout(() => {
        setNewTempImpressions(impressions);
      }, 50);
    } else {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 50);
    }

    setPopoverOpen(visible);
  };

  const handleInputChange: InputNumberProps["onChange"] = (value) => {
    if (typeof value === "number") {
      setNewTempImpressions(value);
    }
  };

  // Sync local impression state with impression changes from budget re-calculations
  useEffect(() => setNewTempImpressions(impressions), [impressions]);

  const isTooHigh = isMaxEnabled ? newTempImpressions > max : false;

  const tooltipMessage = isTooHigh
    ? "This count exceeds the estimated maximum downloads for your timeline. It is unlikely this downloads count will complete for dates you've selected."
    : undefined;

  const status: InputNumberProps["status"] = isTooHigh ? "warning" : undefined;

  return (
    <Popover
      trigger={["click"]}
      zIndex={1100}
      open={isPopoverOpen}
      onOpenChange={handleOpenChange}
      content={
        <div className="flex-column-container">
          <div className="flex-row-container align-center justify-space-between">
            <strong>{`Downloads: ${
              isMaxEnabled ? `(Est. Max ${numeral(max).format("0,0")})` : ""
            }`}</strong>
            <RCButton type="link" className="p-a0" onClick={() => setPopoverOpen(false)}>
              <AiOutlineClose />
            </RCButton>
          </div>
          <div className="input-number-container">
            <InputNumber
              style={{ width: "100%" }}
              keyboard={true}
              step={1}
              value={Math.round(newTempImpressions)}
              onChange={handleInputChange}
              status={status}
              ref={inputRef}
            />
            {isTooHigh && (
              <span className="input-number-container-warning">Over Downloads Limit</span>
            )}
          </div>
          <RCButton
            tooltipMessage={tooltipMessage}
            tooltipPlacement={"bottom"}
            type={"secondary"}
            onClick={onUpdateImpressions}>
            Update Downloads
          </RCButton>
        </div>
      }>
      <div className="blocktile fs-13 lh-xs p-vxxxs pointer">
        {numeral(impressions).format("0,0")}
      </div>
    </Popover>
  );
};

export const CustomBudgetCell = ({
  campaignItemUUID,
  campaign,
  publicShow,
  campaignItem,
  timeline,
  budgets,
  updateBudgets,
  max = 0,
}: ICustomCell) => {
  const cpm = getAverageCPM({ show: publicShow, campaign, campaignItem });
  const dailyImpressions = getDailyImpressions(publicShow);
  const isPacing = Boolean(campaign?.pacing);
  const isMaxEnabled = isPacing && typeof max === "number" && max >= 0;

  let budget = getBudget({
    cpm,
    dailyImpressions,
    startDate: timeline?.[0]?.unix(),
    endDate: timeline?.[1]?.unix(),
  });

  if (typeof budgets?.[campaignItemUUID] === "number") {
    budget = budgets?.[campaignItemUUID];
  }

  const [newTempBudget, setNewTempBudget] = useState(budget);
  const [isPopoverOpen, setPopoverOpen] = useState<boolean | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  const isTooHigh = isMaxEnabled ? newTempBudget > max : false;

  const tooltipMessage =
    isTooHigh && typeof max === "number"
      ? "This budget exceeds the estimated maximum budget for your timeline. It is unlikely this budget will complete for dates you've selected."
      : undefined;

  const status: InputNumberProps["status"] = isTooHigh ? "warning" : undefined;

  const onUpdateBudget = () => {
    updateBudgets((prev) => {
      const newBudgets = { ...prev };
      newBudgets[campaignItemUUID] = newTempBudget;
      return newBudgets;
    });
    setPopoverOpen(false);
  };

  /**
   * Reset input value on popover close
   */
  const handleOpenChange: PopoverProps["onOpenChange"] = (visible) => {
    if (!visible) {
      setTimeout(() => {
        setNewTempBudget(budget);
      }, 50);
    } else {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 50);
    }

    setPopoverOpen(visible);
  };

  const handleInputChange: InputNumberProps["onChange"] = (value) => {
    if (typeof value === "number") {
      const newVal = Number.parseInt((value * 100).toFixed(0));
      setNewTempBudget(newVal);
    }
  };

  useEffect(() => {
    setNewTempBudget(budget);
  }, [budget]);

  const newVal = Math.round(newTempBudget) / 100;
  const currentBudgetIsOverMax = isPacing && isMaxEnabled && budget > max + 1; // Due to rounding need to add 1 cent, still reliable

  return (
    <Popover
      trigger={["click"]}
      zIndex={1100}
      open={isPopoverOpen}
      onOpenChange={handleOpenChange}
      content={
        <div className="flex-column-container">
          <div className="flex-row-container align-center justify-space-between">
            <strong>{`Budget: ${isMaxEnabled ? `(Est. Max ${formatMoney(max)} )` : ""}`}</strong>
            <RCButton type="link" className="p-a0" onClick={() => setPopoverOpen(false)}>
              <AiOutlineClose />
            </RCButton>
          </div>
          <div className="input-number-container">
            <InputNumber
              addonBefore="$"
              style={{ width: "100%" }}
              keyboard={true}
              step={1}
              value={newVal}
              onChange={handleInputChange}
              status={status}
              ref={inputRef}
            />
            {isTooHigh && <span className="input-number-container-warning">Over Budget</span>}
          </div>

          <RCButton
            tooltipMessage={tooltipMessage}
            tooltipPlacement={"bottom"}
            type={"secondary"}
            onClick={onUpdateBudget}>
            Update Budget
          </RCButton>
        </div>
      }>
      <div className="blocktile fs-13 lh-xs p-vxxxs pointer">
        {numeral(budget / 100).format("$0,0.00")}{" "}
        {currentBudgetIsOverMax && (
          <Tooltip title="This budget exceeds the estimated maximum budget for your timeline. It is unlikely this budget will complete for dates you've selected.">
            <Icon icon={AlertIcon} color="#d48806" className={""} height={14} />
          </Tooltip>
        )}
      </div>
    </Popover>
  );
};

export const CustomShowRemoveCell = ({
  campaignItemUUID,
  campaign,
  updateBudgets,
}: Pick<ICustomCell, "campaignItemUUID" | "campaign" | "updateBudgets">) => {
  const [tempAllowOpen, setTempAllowOpen] = useState<boolean>(false);

  const dispatch = useReduxDispatch();

  const onRemoveShow = () => {
    updateBudgets((prev) => {
      const newBudgets = { ...prev };
      delete newBudgets[campaignItemUUID];
      return newBudgets;
    });
  };

  const onConfirmRemove = () => {
    onCancelRemove();
    dispatch(updateCampaignItems(campaign.uuid, { [campaignItemUUID]: { delete: true } })).then(
      () => {
        onRemoveShow();
      }
    );
  };

  const onCancelRemove = () => {
    setTempAllowOpen(false);
  };

  const handleClick = () => {
    setTempAllowOpen(true);
  };

  return (
    <Popconfirm
      title="Are you sure you want to remove this show from cart?"
      onConfirm={onConfirmRemove}
      onCancel={onCancelRemove}
      open={tempAllowOpen}
      trigger={["click"]}
      zIndex={1100}
      okText="Yes"
      cancelText="Cancel">
      <div
        className="fs-9 lh-12 semi-bold text-center pointer"
        style={{ color: "#577D9E" }}
        onClick={handleClick}>
        Remove Show
      </div>
    </Popconfirm>
  );
};

export const MatchBudget = ({
  budgets,
  updateBudgets,
  mapCampaignItemToMaxBudgets,
  isPacing,
}: {
  budgets: { [campaignItemUUID: string]: number };
  updateBudgets: React.Dispatch<React.SetStateAction<{ [campaignItemUUID: string]: number }>>;
  mapCampaignItemToMaxBudgets: { [campaignItemUUID: string]: number };
  isPacing: boolean;
}) => {
  const totalBudget = Object.values(budgets ?? {}).reduce((accu, curr) => accu + curr, 0) ?? 0;
  const totalMax =
    Object.values(mapCampaignItemToMaxBudgets ?? {}).reduce((accu, curr) => accu + curr, 0) ?? 0;

  const isMaxEnabled = isPacing && typeof totalMax === "number" && totalMax > 0;

  const [newTotalBudget, setNewTotalBudget] = useState(totalBudget);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => setNewTotalBudget(totalBudget), [totalBudget]);

  const onUpdateAllBudgets = () => {
    updateBudgets((prev) => {
      const newBudgets = { ...prev };
      const count = Object.keys(newBudgets)?.length;

      const equalDivideBudget = newTotalBudget / count;

      if (isPacing) {
        for (const uuid in newBudgets) {
          const specificMax = mapCampaignItemToMaxBudgets?.[uuid] ?? 0;
          const weight = totalMax > 0 ? specificMax / totalMax : 1 / count;

          newBudgets[uuid] = newTotalBudget * weight;
        }
      } else {
        for (const uuid in newBudgets) {
          newBudgets[uuid] = equalDivideBudget;
        }
      }

      return newBudgets;
    });

    setPopoverOpen(false);
  };

  /**
   * Reset input value on popover close
   */
  const handleOpenChange: PopoverProps["onOpenChange"] = (visible) => {
    if (!visible) {
      setTimeout(() => {
        setNewTotalBudget(totalBudget);
      }, 50);
    } else {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 50);
    }

    setPopoverOpen(visible);
  };

  const handleInputChange: InputNumberProps["onChange"] = (value) => {
    if (typeof value === "number") {
      const newVal = Number.parseInt((value * 100).toFixed(0));
      setNewTotalBudget(newVal);
    }
  };

  const newVal = Math.round(newTotalBudget) / 100;

  const isTooHigh = isMaxEnabled ? newTotalBudget > totalMax : false;

  const tooltipMessage =
    isTooHigh && typeof totalMax === "number"
      ? "This budget exceeds the estimated maximum budget for your timeline. It is unlikely this budget will complete for dates you've selected."
      : undefined;

  const status: InputNumberProps["status"] = isTooHigh ? "warning" : undefined;

  return (
    <Popover
      trigger={["click"]}
      zIndex={1100}
      open={isPopoverOpen}
      onOpenChange={handleOpenChange}
      content={
        <div className="flex-column-container">
          <div className="flex-row-container align-center justify-space-between">
            <strong>{`Total Budget: ${
              isMaxEnabled ? `(Est. Max ${formatMoney(totalMax)} )` : ""
            }`}</strong>
            <RCButton type="link" className="p-a0" onClick={() => setPopoverOpen(false)}>
              <AiOutlineClose />
            </RCButton>
          </div>
          <div className="input-number-container">
            <InputNumber
              addonBefore="$"
              style={{ width: "100%" }}
              keyboard={true}
              step={1}
              value={newVal}
              onChange={handleInputChange}
              status={status}
              ref={inputRef}
            />
            {isTooHigh && <span className="input-number-container-warning">Over Budget</span>}
          </div>

          <RCButton
            tooltipMessage={tooltipMessage}
            tooltipPlacement={"bottom"}
            type={"secondary"}
            onClick={onUpdateAllBudgets}>
            Balance Spend to Budget
          </RCButton>
        </div>
      }>
      <RCButton type="link">Match Budget</RCButton>
    </Popover>
  );
};

const { RangePicker } = DatePicker;

export interface IRCRangePicker {
  startDate: UnixTimeStamp;
  endDate: UnixTimeStamp;
  onDateSubmit: ([startDate, endDate]: [UnixTimeStamp, UnixTimeStamp]) => void;
  minimumDate?: UnixTimeStamp;
}

export const RCRangePicker = ({
  startDate,
  endDate,
  onDateSubmit,
  minimumDate,
}: IRCRangePicker) => {
  const [open, setOpen] = useState<boolean | undefined>(false);
  const [tempTimeline, setTempTimeline] = useState<[UnixTimeStamp, UnixTimeStamp]>([
    startDate,
    endDate,
  ]);

  // Sync Controlled inputs for startDate and endDate
  useEffect(() => setTempTimeline([startDate, endDate]), [startDate, endDate]);

  const onCalendarChange: RangePickerProps["onCalendarChange"] = (values) => {
    const start = values?.[0]?.unix() ?? 0;
    const end = values?.[1]?.unix() ?? 0;
    setTempTimeline([start, startOfDay(end)]);
  };

  const onOk = () => {
    onDateSubmit([...tempTimeline]);
    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const isMinimumDateProvided = typeof minimumDate === "number";

  const handleDisableDate: RangePickerProps["disabledDate"] = (date) => {
    if (isMinimumDateProvided) {
      return date?.unix() < startOfDay(minimumDate);
    }
    return false;
  };

  const dayjsStart = dayjs.unix(tempTimeline[0]);
  const dayjsEnd = dayjs.unix(tempTimeline[1]);

  return (
    <RangePicker
      defaultValue={[dayjsStart, dayjsEnd]}
      value={[dayjsStart, dayjsEnd]}
      format={"MM/DD/YYYY"}
      open={open}
      onOpenChange={(open) => {
        if (open) setOpen(true);
      }}
      allowClear={false}
      onCalendarChange={onCalendarChange}
      disabledDate={isMinimumDateProvided ? handleDisableDate : undefined}
      renderExtraFooter={() => {
        return (
          <div className="flex-row-container align-center p-axs">
            <RCButton className="m-la" type="secondary" onClick={onCancel}>
              Close
            </RCButton>

            <RCButton className="m-lxxs" type="primary" onClick={onOk}>
              Set timeline
            </RCButton>
          </div>
        );
      }}
    />
  );
};
