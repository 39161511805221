import { Select, Switch } from "antd";
import isEmpty from "lodash/isEmpty";
import reduce from "lodash/reduce";
import moment, { Moment } from "moment-timezone";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import BlockFeature from "src/components/lib/block_feature";
import { showError, showInfo } from "../../../../actions/app";
import { DownloadStatsActionManager } from "../../../../action_managers/stats";
import { useReduxDispatch, useSelectorTS } from "../../../../hooks/redux-ts";
import BlockImgSrc from "../../../../images/Block_Download_Exclusive.png";
import { goToPricingPage } from "../../../../lib/config";
import { IEpisode } from "../../../../reducers/episode_by_show/types";
import { downloadFile } from "../../../lib/download_file";
import {
  ALL_PODCASTS,
  customTooltip,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  DropdownIntervalValues,
  ExclusiveDownloadsGraphDataPoint,
  getDropdownDefaultValueFromDates,
  getDropdownOptionsFromDates,
  getIntervalFromDropdown,
  getMaskFromDropdown,
  getRequestHash,
  RequestResponseDataPoint,
  StatsRequestFilter,
  tooltipTitleFactory,
  useInitialDataRequest,
  useTierAnalyticsPerm,
  yAxisLabelFormatter,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";
import Area from "../RedCircle_graphs/Area";

// Gets original over time data and transforms it to cumulative
const transformDataToCommulative = (data: ExclusiveDownloadsGraphDataPoint[]) => {
  const result: ExclusiveDownloadsGraphDataPoint[] = [];
  data?.reduce((accu, curr) => {
    result.push({
      date: curr.date,
      count: accu + curr.count,
    });
    return accu + curr.count;
  }, 0);

  return result;
};

const exclusiveDownloadsPrefix = "Exclusive-Downloads";

const areaGradient = {
  steps: [
    {
      percent: 0,
      color: "rgba(38, 115, 221, 0.04)",
    },
    {
      percent: 0.5,
      color: "rgba(38, 115, 221, 0.6)",
    },
    {
      percent: 1,
      color: "rgba(38, 115, 221, 1)",
    },
  ],
  direction: 270,
};

interface IExclusiveDownloads {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
}

const ExclusiveDownloads: FunctionComponent<IExclusiveDownloads> = ({
  dateRange,
  timeZone,
  selectedShow,
}) => {
  const dispatch = useReduxDispatch();
  const user = useSelectorTS((state) => state?.user?.user);
  const tierPermission = useTierAnalyticsPerm();
  const episodesByShow = useSelectorTS((state) => state?.episodesByShow);
  const episodesMap = reduce<typeof episodesByShow, Record<string, IEpisode>>(
    episodesByShow,
    (result, value) => {
      return { ...result, ...value.episodes };
    },
    {}
  );

  const [, , initialDataIsReady] = useInitialDataRequest();

  const dropdownOptions = getDropdownOptionsFromDates(dateRange);
  const dropdownDefaultValue = getDropdownDefaultValueFromDates(dateRange);

  const [dropdownVal, setDropdownVal] =
    useState<typeof DropdownIntervalValues[number]>(dropdownDefaultValue);

  const [isCumulative, setIsCumulative] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const cacheID = getRequestHash(exclusiveDownloadsPrefix, selectedShow, dateRange, dropdownVal);

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  const transformData = (data: RequestResponseDataPoint["json"]) => {
    if (isEmpty(data)) {
      return [];
    }

    // filter data by episodes that are set to exclusiv eonly
    const fitleredData = data.filter((item) => {
      const episodeUUID = item?.pathValues?.[1];
      return (
        episodesMap[episodeUUID]?.shouldPublishToSubscribers &&
        !episodesMap[episodeUUID]?.shouldPublishToNonSubscribers
      );
    });

    // aggregate all episode download data by date
    const aggregateByDate = fitleredData.reduce((accu, curr) => {
      accu[curr?.date] =
        typeof accu[curr?.date] === "number" ? accu[curr?.date] + curr?.count : curr?.count;
      return accu;
    }, {} as Record<number, number>);

    // map to each date
    const result = Object.keys(aggregateByDate).map((date) => {
      return {
        date: parseInt(date) * 1000,
        count: aggregateByDate[parseInt(date)],
      };
    });
    return result;
  };

  const data = transformData(statsCacheData);
  const cumulativeData = transformDataToCommulative(data);

  const interval = getIntervalFromDropdown(dropdownVal);

  useEffect(() => {
    let id: any;
    if (initialDataIsReady) {
      if (isEmpty(statsCacheData)) {
        setIsLoading(true);
        id = setTimeout(() => {
          const filters: StatsRequestFilter = {
            isUnique: true,
            arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
            timezone: timeZone,
            interval: getIntervalFromDropdown(dropdownVal),
            bucketTerms: "download.episodeUUID",
          };

          if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
            filters.showUUID = selectedShow;
          }

          dispatch(
            new DownloadStatsActionManager({
              filters,
              user,
              requestID: cacheID,
            }).run()
          )
            .then((resp: RequestResponseDataPoint) => {
              if (resp?.status !== 200) {
                dispatch(
                  showInfo("Please wait a few seconds and try the data request again", 5000)
                );
              }
              setIsLoading(false);
            })
            .catch(() => {
              dispatch(
                showError("An error has occured please reload the page and try again", 5000)
              );
              setIsLoading(false);
            });
        }, 250);
      } else {
        setIsLoading(false);
      }
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
    dropdownVal,
    initialDataIsReady,
  ]);

  useEffect(() => {
    setDropdownVal(dropdownDefaultValue);
  }, [dropdownDefaultValue]);

  // Handlers
  const handleIntervalChange = (newDropdownValue: typeof DropdownIntervalValues[number]) => {
    setDropdownVal(newDropdownValue);
  };

  const handleSwitchChange = (checked: boolean) => setIsCumulative(checked);

  const handleReportDownload = () => {
    if (!isLoading) {
      const currentData = isCumulative ? cumulativeData : data;
      const csv = Papa.unparse(
        currentData.map((point) => {
          return {
            date: moment(point.date).utc().format("MM/DD/YYYY hh:mm:ss [UTC]"),
            [`Downloads_${isCumulative ? "Cumulative" : "Overtime"}`]: point.count,
          };
        })
      );
      downloadFile(
        `ExclusiveDownloadsReport_${
          isCumulative ? "Cumulative" : "OverTime"
        }-${dateRange?.[0]?.format("YYYY_MM_DD")}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  const downloadSettings = tierPermission.Downloads.ExclusiveDownloads
    ? {
        allow: tierPermission.General.ExportDataWidgetLevel,
        text: "Exclusive Downloads Report",
        hanlder: handleReportDownload,
      }
    : undefined;

  return (
    <InfoCard
      title={{
        text: `Exclusive Downloads`,
        tooltipText:
          "Number of downloads that are exclusive feed only (eg excludes downloads from episodes that are on both your public and exclusive feeds)",
      }}
      download={downloadSettings}>
      <BlockFeature
        block={!tierPermission.Downloads.ExclusiveDownloads}
        CTA={{
          text: (
            <span className="h3 default-font m-b0 p-hxs text-center">
              <strong>Exclusive Downloads</strong> available starting from Growth Plan.
            </span>
          ),
          type: "red",
          btn: {
            text: "Upgrade Your Plan",
            handler: () => goToPricingPage(),
          },
        }}
        blockByImg={{
          src: BlockImgSrc,
          minHeight: "400px",
        }}>
        <div style={{ height: "100%", width: "100%" }}>
          <div className="flex-row-container align-center m-bxxs">
            <h4 className="h4 m-a0 bold capitalize">Downloads</h4>
            <span
              style={{
                height: "6px",
                width: "16px",
                borderRadius: "4px",
                margin: "0px 10px",
                position: "relative",
                bottom: ".125em",
                backgroundColor: `${areaGradient?.steps?.[areaGradient?.steps?.length - 1]?.color}`,
              }}
            />
            <span style={{ marginLeft: "auto" }}>
              <Select
                size="small"
                className="RC-Antd-Override-Dropdown"
                value={dropdownVal}
                onSelect={handleIntervalChange}
                style={{ width: "100px" }}>
                {dropdownOptions.map((val) => {
                  return (
                    <Select.Option key={val} value={val}>
                      {val}
                    </Select.Option>
                  );
                })}
              </Select>
            </span>
          </div>
          <Area<ExclusiveDownloadsGraphDataPoint>
            data={isCumulative ? cumulativeData : data}
            loading={isLoading}
            xField="date"
            yField="count"
            tooltip={{
              customContent: customTooltip,
              title: tooltipTitleFactory(interval),
            }}
            meta={{
              date: {
                type: "timeCat",
                mask: getMaskFromDropdown(dropdownVal),
              },
              count: {
                formatter: yAxisLabelFormatter,
              },
            }}
          />
          <div className="flex-row-container justify-center align-center m-vxs">
            <span className="area-switch-text h3 m-a0">Over Time</span>
            <Switch
              className="RC-Antd-Override-Switch m-hs m-yauto"
              checked={isCumulative}
              onClick={handleSwitchChange}
            />
            <span className="area-switch-text h3 m-a0">Cumulative</span>
          </div>
        </div>
      </BlockFeature>
    </InfoCard>
  );
};

export default ExclusiveDownloads;
