import { useState } from "react";
import { Button, Modal } from "redcircle-ui";
import UploadModalActionManager from "src/action_managers/upload_modal";
import FileUploadV2 from "src/components/forms/file_upload/file_upload_v2";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "src/reducers/shows";
import MediaPlayer from "../../../lib/media_player";
import ScriptDetails from "./script_details";

interface IProps {
  campaignItem: ICampaignItem;
  show: IShow;
  onBack: () => void;
  onSubmit: (mediaFileUUID: string) => void;
}

export default function UploadAudioPage({ campaignItem, show, onBack, onSubmit }: IProps) {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const { isLoading } = useSelectorTS((state) => state.mediaFileUpload);
  const mediaFiles = useSelectorTS((state) => state.mediaFiles);

  const [mediaFileUUID, setMediaFileUUID] = useState(null);
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const uploadFile = (files: any) => {
    const file = files[0];
    return dispatch(
      new UploadModalActionManager({
        file,
        fileName: file.name,
        authToken: user.authToken,
      }).run()
    ).then((resp: any) => {
      if (resp.status === 200) {
        const json = resp.json;
        const mediaFile = json[0];
        setMediaFileUUID(mediaFile.uuid);
      }
    });
  };

  const handleSubmit = async () => {
    if (!mediaFileUUID) return;
    setSubmitLoading(true);
    await onSubmit(mediaFileUUID);
    setSubmitLoading(false);
  };

  return (
    <>
      <div className="p-xxl p-bs">
        <ScriptDetails campaignItem={campaignItem} show={show} />

        <p className="redcircle-form-label m-tm">Upload MP3</p>
        <FileUploadV2
          onChange={uploadFile}
          isLoading={isLoading}
          mediaFileUUID={mediaFileUUID}
          mediaFiles={mediaFiles}
          showMaxFileSize={false}
        />
        <MediaPlayer mediaFileUUID={mediaFileUUID} />
      </div>

      <Modal.Footer>
        <Button onClick={onBack} type="link" className="p-a0">
          Back
        </Button>
        <Modal.SubmitButton
          isLoading={isSubmitLoading}
          onClick={handleSubmit}
          disabled={!mediaFileUUID}>
          Submit
        </Modal.SubmitButton>
      </Modal.Footer>
    </>
  );
}
