/* eslint-disable @typescript-eslint/no-empty-function */
import { ConfigProvider } from "antd";
import merge from "lodash/merge";
import { createContext, useContext, useState } from "react";
import { COLORS } from "../theme/colors";

interface IConfigProvider {
  theme?: any;
  children: any;
}

const defaultTheme = {
  token: {
    // SEED TOKENS
    colorPrimary: COLORS.PRIMARY_COLOR,
    fontFamily: `'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,

    // MAP TOKENS
    colorLink: COLORS.PRIMARY_COLOR,
    colorLinkHover: COLORS.PRIMARY_COLOR_HOVER,
    colorLinkActive: COLORS.PRIMARY_COLOR_ACTIVE,
    colorPrimaryBgHover: COLORS.PRIMARY_COLOR_ACTIVE,
  },
};

// Usage: const { theme, setTheme, mergeTheme } = useThemeContext();
const ThemeContext = createContext({
  theme: defaultTheme,
  setTheme: (value: any) => {},
  mergeTheme: (value: any) => {},
});
export const useThemeContext = () => useContext(ThemeContext);

export const RCConfigProvider = ({ theme = {}, children }: IConfigProvider) => {
  const [combinedTheme, setTheme] = useState(merge(defaultTheme, theme));

  const mergeTheme = (value: any) => {
    setTheme(merge(combinedTheme, value));
  };

  return (
    <ThemeContext.Provider value={{ theme: combinedTheme, setTheme, mergeTheme }}>
      <ThemeContext.Consumer>
        {({ theme: consumerTheme }: { theme: any }) => {
          return <ConfigProvider theme={consumerTheme}>{children}</ConfigProvider>;
        }}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  );
};
