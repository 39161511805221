import isEmpty from "lodash/isEmpty";
import parseInt from "lodash/parseInt";
import * as moment from "moment";
import numeral from "numeral";

export function hummanizeNumber(number) {
  if (number < 10000) {
    return number.toLocaleString();
  }
  if (number < 100000) {
    return numeral(number).format("0.0a");
  }
  return numeral(number).format("0.0a");
}

export function hummanizeAudienceSize(number) {
  return numeral(number).format("0a");
}

export const secondsToString = (seconds, withMs = false) => {
  const duration = moment.duration(seconds * 1000);
  const epoch = moment.utc(duration.asMilliseconds());
  if (duration.hours() && withMs) {
    return epoch.format("hh:mm:ss.SS");
  } else if (duration.hours()) {
    return epoch.format("hh:mm:ss");
  } else if (withMs) {
    return epoch.format("mm:ss.SS");
  }

  return epoch.format("mm:ss");
};

export const isValidTimeString = (timeString) => {
  const pieces = timeString.split(":");
  if (isEmpty(pieces) || pieces.length === 1) {
    return false;
  }

  if (pieces.length === 2) {
    const minutes = pieces[0];
    const seconds = pieces[1];

    return !isNaN(minutes) && !isNaN(seconds);
  }
  if (pieces.length === 3) {
    const hours = pieces[0];
    const minutes = pieces[1];
    const seconds = pieces[2];
    return !isNaN(hours) && !isNaN(minutes) && !isNaN(seconds);
  }

  return false;
};

export const stringToSeconds = (timeString) => {
  if (!isValidTimeString(timeString)) {
    return undefined;
  }

  const pieces = timeString.split(":");
  if (pieces.length === 2) {
    const minutes = parseInt(pieces[0]);
    const seconds = parseFloat(pieces[1]);
    return minutes * 60 + seconds;
  }
  if (pieces.length === 3) {
    const hours = parseInt(pieces[0]);
    const minutes = parseInt(pieces[1]);
    const seconds = parseFloat(pieces[2]);
    return hours * 3600 + minutes * 60 + seconds;
  }

  return undefined;
};
