import { useEffect, useRef } from "react";
import { getCampaign, getCampaignsForUser, getCampaignTags } from "src/action_managers/campaigns";
import UserRoles from "src/constants/roles";
import { userHasRole } from "src/lib/user";
import { useReduxDispatch, useSelectorTS } from "./redux-ts";
import { isUUID } from "src/lib/uuid";

const MAX_ATTEMPTS = 5;

export const useGetCampaignItems = (campaignUUID?: string) => {
  const dispatch = useReduxDispatch();
  const {
    campaignItemByCampaignUUID,
    isLoading: isCampaignItemsLoading,
    campaignItems: campaignItemsByUUID,
  } = useSelectorTS((state) => state.campaignItems);

  const campaignItemsUUIDs = campaignUUID ? campaignItemByCampaignUUID[campaignUUID] : undefined;

  const campaignItems = campaignItemsUUIDs?.map((uuid) => campaignItemsByUUID[uuid]);
  const isUUIDAvailable = typeof campaignUUID === "string" && isUUID(campaignUUID);

  const isCampaignItemsAvailable = !!campaignItems;

  // Capture fail fetch attempts
  const failedAttempts = useRef<number>(1);
  // Capture the first successful fetch
  const success = useRef<boolean>(false);

  // Reset fetch attempts counter on campaignUUID change
  useEffect(() => {
    failedAttempts.current = 1;
    success.current = false;
  }, [campaignUUID]);

  useEffect(() => {
    if (!isCampaignItemsLoading) {
      // Currently no other campaignsItems are being fetched
      if (isUUIDAvailable) {
        // UUID is currently available
        if (!isCampaignItemsAvailable) {
          // Campaign is not found in Redux state
          if (!success.current && failedAttempts.current < MAX_ATTEMPTS) {
            // MAX attempts have not been reached
            dispatch(getCampaign(campaignUUID))
              .then(() => (success.current = true))
              .catch(() => failedAttempts.current++);
          }
        }
      }
    }
  }, [campaignUUID, isCampaignItemsLoading, isUUIDAvailable, isCampaignItemsAvailable]);

  return campaignItems;
};
