import React, { useEffect } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { submit } from "redux-form";
import { showWarning } from "../../../actions/app";
import Divider from "../../lib/divider";
import {
  additionalContactFormName,
  PodcasterAdditionalContactForm,
} from "../../modals/podcaster_additional_contact_modal";

const ManageNotifications = () => {
  const dispatch = useDispatch();
  const validationErrors = useSelector((state) => state.updateAccount.validationErrors);

  useEffect(() => {
    if (validationErrors && validationErrors.length > 0) {
      dispatch(showWarning(validationErrors[0]?.errorMessage));
    }
  }, [validationErrors]);

  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <h3>Add Additional Contacts</h3>
          <p>
            If you have additional team members (eg manager, business partner) that should also
            receive transactional email and SMS communications from RedCircle, add their email
            addresses and phone numbers below. Please note they will not have account login access,
            but they will receive notifications for all shows on this account.
          </p>
          <Divider marginBottom={16} />
          <PodcasterAdditionalContactForm />
        </Col>
      </Row>
      <Row>
        <Col xs={6} xsOffset={3}>
          <Button
            onClick={() => dispatch(submit(additionalContactFormName))}
            bsStyle={"primary"}
            className={"width-100"}>
            Save
          </Button>
        </Col>
      </Row>
    </Grid>
  );
};

export default ManageNotifications;
