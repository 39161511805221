import fetch from "cross-fetch";
import getAPIPrefix from "../lib/api_prefix";
import { IShow } from "../reducers/shows/types";
import { User } from "../reducers/user";

function fetchShowsForUser(user: User) {
  return fetch(getAPIPrefix() + "users/" + user.uuid + "/shows", {
    method: "get",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
  });
}

function fetchShow(showUUID: string, user: User) {
  return fetch(getAPIPrefix() + "shows/" + showUUID, {
    method: "get",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
  });
}

function createShow(show: IShow, user: User) {
  return fetch(getAPIPrefix() + "shows", {
    method: "post",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
    body: JSON.stringify(show),
  });
}

function updateShow(show: IShow, user: User) {
  return fetch(getAPIPrefix() + "shows/" + show.uuid, {
    method: "put",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
    body: JSON.stringify(show),
  });
}

function validatePromoCode(showUUID: string, code: string) {
  return fetch(`${getAPIPrefix()}shows/${showUUID}/validate-promo-code/${code}`, {
    method: "get",
  });
}

const showAPI = {
  fetchShowsForUser,
  fetchShow,
  createShow,
  updateShow,
  validatePromoCode,
};

export default showAPI;
