// isSuccessStatus returns true if status_code is (200, 204, etc.)
import { useDispatch } from "react-redux";

export function isSuccessStatus(httpStatusCode) {
  return Math.floor(httpStatusCode / 100) === 2;
}

export const handleRespPromise = (resp) =>
  resp.status === 200
    ? Promise.resolve(resp)
    : Promise.reject(new HttpError(`Call Failed with status ${resp.status}`));

export class HttpError extends Error {
  constructor(message) {
    super(message);
    this.name = "HttpError";
  }
}

export const useHttpDispatch = () => {
  const dispatch = useDispatch();
  return useHttpDispatchInternal(dispatch);
};

const useHttpDispatchInternal = (dispatch) => (callback) => {
  return dispatch(callback).then(handleRespPromise);
};
