import capitalize from "lodash/capitalize";
import find from "lodash/find";
import forEach from "lodash/forEach";
import get from "lodash/get";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import maxBy from "lodash/maxBy";
import React, { useContext, useMemo, useRef, useState } from "react";
import { Col, ControlLabel, FormGroup, Overlay, OverlayTrigger, Popover } from "react-bootstrap";
import { classNames, If } from "react-extras";
import NumberFormat from "react-number-format";
import { Provider, useDispatch, useSelector, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { permissionTypes } from "src/constants/permission_roles";
import { canAdvertiserAccess } from "src/lib/permissions";
import { getSampleAdReads, updateCampaignItems } from "../../../action_managers/campaigns";
import { CampaignStyleHostRead, CampaignStyleToDisplay } from "../../../constants/campaigns";
import { itunesCategoryUUIDToName } from "../../../data/itunes_categories";
import Magnifier from "../../../icons/magnifier.svg";
import MagnifierHover from "../../../icons/magnifier_hovered.svg";
import MagnifierPressed from "../../../icons/magnifier_pressed.svg";
import thumbsUpIcon from "../../../icons/thumbup.svg";
import {
  getAverageCPM,
  getBudget,
  getDailyImpressions,
  getImpressionsFromBudget,
} from "../../../lib/campaigns";
import { shouldShowRateNegotiation } from "../../../lib/feature_flag";
import { formatMoney } from "../../../lib/format-money";
import { itunesCategoriesByUUID } from "../../../lib/itunes_categories";
import { hummanizeAudienceSize } from "../../../lib/numbers";
import { ageOptions } from "../../modals/campaign_editor/target_audience";
import Divider from "../divider";
import ExclusiveTag from "../exclusive_tag";
import { svgIcon as Icon } from "../icon";
import InfoTooltip from "../info";
import MediaPlayer from "../media_player";
import { DefaultShowSearchImage } from "../show_search/show_search";

export const TitleContainer = ({ width, subTitle, campaign, showCatAmt }) => {
  const show = useContext(ShowContext);
  return (
    <Col xs={width} className={"overflow-hidden flex-1"}>
      <TitleContainerCell
        show={show}
        subTitle={subTitle}
        campaign={campaign}
        showCatAmt={showCatAmt}
      />
    </Col>
  );
};

export const RecommendedTag = ({ width, subTitle, campaign }) => {
  const show = useContext(ShowContext);
  return (
    <div>
      {show.recommended && (
        <ExclusiveTag>
          <span style={{ whiteSpace: "nowrap" }}>
            <img src={thumbsUpIcon} className={"m-rxxs"} />
            TARGET AUDIENCE FIT
          </span>
        </ExclusiveTag>
      )}
    </div>
  );
};

export const TitleContainerCell = ({ show, subTitle, campaign, showCatAmt = false }) => {
  const categoryName = get(itunesCategoriesByUUID, [head(show.categoryUUIDs), "name"], "");
  const categoriesLength =
    show?.categoryUUIDs?.length > 1 ? `... and ${show?.categoryUUIDs?.length - 1} more` : "";
  subTitle = subTitle ?? `Category: ${categoryName} ${showCatAmt ? categoriesLength : ""}`;
  return (
    <div className={"black-text flex-row-container justify-space-between width-100"}>
      <div className={"width-100"} style={{ paddingLeft: "4px" }}>
        <Link
          className={"no-style-link title-container width-100"}
          target="_blank"
          rel="noopener noreferrer"
          to={`/browse/${show.uuid}`}>
          <div className={"bold title flex-row-container width-100"}>
            <div style={{ display: "block", maxWidth: "300px" }} className={"ellipsis"}>
              {show.title}
            </div>
            {campaign && (
              <div>
                <MagnifierToolTip show={show} campaign={campaign} />
              </div>
            )}
          </div>
          <div className={"category small"}>{subTitle}</div>
        </Link>
      </div>
    </div>
  );
};

export const MagnifierToolTip = ({ show, campaign }) => {
  const [pressed, setPressed] = useState(false);
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const adReads = useSelector(
    (state) => state.publicShows.adReadsByShowUUID?.[show.uuid]?.[0] ?? {}
  );

  const listenerDemographics = show?.listenerDemographics;
  let primaryGender;
  let genderPercentage;
  let primaryAge;
  let agePercentage;
  let audienceInTargetAge;
  let targetAges = ["any"];

  if (!isEmpty(listenerDemographics)) {
    const genders = listenerDemographics.genderBreakdown;
    primaryGender = Object.keys(genders).reduce((a, b) => (genders[a] > genders[b] ? a : b));
    genderPercentage = Math.round(genders[primaryGender] * 100) + "%";
    primaryGender = capitalize(primaryGender);

    const ages = listenerDemographics.ageBuckets;
    primaryAge = maxBy(ages, (a) => a.percentage).label;
    agePercentage = Math.round(find(ages, { label: primaryAge })?.percentage * 100) + "%";
    primaryAge = find(ageOptions, { value: primaryAge })?.display;

    targetAges = !isEmpty(campaign.targetAges) ? campaign.targetAges : ["any"];
    if (isEqual(targetAges, ["any"])) {
      audienceInTargetAge = "100%";
    } else {
      let matchingAgesPercent = 0;
      forEach(ages, (age, i) => {
        if (targetAges.includes(age?.label)) {
          matchingAgesPercent += ages[i].percentage;
        }
      });
      audienceInTargetAge = Math.round(matchingAgesPercent * 100) + "%";
    }
  }

  let image = Magnifier;
  if (hover && !pressed) {
    image = MagnifierHover;
  }
  if (pressed) {
    image = MagnifierPressed;
  }
  // this looks shady, but because this is taking place inside of a tooltip, which is not in the
  // normal react dom tree, we have to ensure that the connect function underneath has the
  // store provided through context
  const store = useStore();
  const mediaPlayer = adReads.mediaFileUUID ? (
    <Provider store={store}>
      <MediaPlayer mediaFileUUID={adReads.mediaFileUUID} mini={true} />
    </Provider>
  ) : null;

  const overlay = () => {
    let adDetails = Object.entries(campaign.targetingOptions)
      .filter(([, enabled]) => enabled)
      .map(([key]) => {
        const position = key.slice(0, -4);
        return position[0].toUpperCase() + position.slice(1) + "-Roll";
      })
      .join(", ");
    adDetails = CampaignStyleToDisplay[campaign.style] + " " + adDetails;
    if (campaign.style === CampaignStyleHostRead) {
      adDetails = "0:60 " + adDetails;
    }
    return (
      <Popover id={"show-popover"} bsClass={"p-axxs popover"} style={{ width: 248 }}>
        <div>
          <div className={"bold m-bxxxs"}>{show.title}</div>
          <ExclusiveTag>{itunesCategoryUUIDToName(show.categoryUUIDs[0])}</ExclusiveTag>
          <Divider marginTop={16} marginBottom={16} />
          <div style={{ fontSize: 11 }}>
            <div className="flex-row-container align-center m-bxxxs">
              <span className="bold m-rxxxs">Hosts: </span>
              <div>
                {(show.advertisementSettings?.hosts ?? []).map((host, i) => {
                  return (
                    <div key={i}>
                      {host.fullName?.firstName} {host.fullName?.lastName}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={"m-bxxxs"}>
              <span className="bold">Episode Frequency: </span>
              {show.estimatedEpisodeFrequency}
            </div>
            <If condition={!isEmpty(primaryGender)}>
              <div className="m-bxxxs">
                <span className="bold">Primary Gender: </span>
                {primaryGender} ({genderPercentage})
              </div>
            </If>
            <If condition={!isEmpty(primaryAge)}>
              <div className="m-bxxxs">
                <span className="bold">Primary Age: </span>
                {primaryAge} ({agePercentage})
              </div>
            </If>
            <If condition={!isEmpty(audienceInTargetAge) && !isEqual(targetAges, ["any"])}>
              <div className="m-bxxxs">
                <span className="bold">Audience In Target Age: </span>
                {audienceInTargetAge}
              </div>
            </If>
            <div className={"m-bxxxs"}>
              <span className="bold">Ad Details: </span>
              {adDetails}
            </div>
            {adReads.mediaFileUUID && (
              <div className={"flex-row-container align-center"}>
                <div className="bold m-rxxxs">Past Host-Read Example Read: </div> {mediaPlayer}
              </div>
            )}
          </div>
        </div>
      </Popover>
    );
  };
  return (
    <OverlayTrigger
      trigger={["click"]}
      placement={"right"}
      overlay={overlay()}
      onExit={() => {
        setPressed(false);
      }}
      onEnter={() => {
        setPressed(true);
      }}
      rootClose>
      <img
        style={{ height: "20px", width: "20px" }}
        src={image}
        alt=""
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(getSampleAdReads(show.uuid));
        }}
      />
    </OverlayTrigger>
  );
};

export const Image = ({ width, ...rest }) => {
  const show = useContext(ShowContext);
  return (
    <Col xs={width} {...rest}>
      <Link target="_blank" rel="noopener noreferrer" to={`/browse/${show.uuid}`}>
        {DefaultShowSearchImage(show)}
      </Link>
    </Col>
  );
};

export const AudienceSize = ({ width }) => {
  const show = useContext(ShowContext);
  return (
    <Col xs={width}>
      <div className={"bold"}>
        {hummanizeAudienceSize(get(show, "estimatedWeeklyDownloads", 0))}
      </div>
    </Col>
  );
};

export const EpisodeSize = ({ width }) => {
  const show = useContext(ShowContext);
  return (
    <Col xs={width}>
      <div className={"bold"}>{hummanizeAudienceSize(get(show, "averageEpisodeDownloads", 0))}</div>
    </Col>
  );
};

export const getSpotRateFromShow = (show, campaign, getOriginal = false) => {
  let path = "midRollSpotRate";
  if (!campaign?.targetingOptions) {
    return 0;
  }

  if (show?.offerRates?.enabled && !getOriginal) {
    return show?.offerRates?.finalAdjustedSpotRate;
  }
  Object.entries(campaign.targetingOptions).map(([position, shouldTarget]) => {
    if (shouldTarget) {
      path = position + "SpotRate";
    }
  });
  return get(show, ["advertisementSettings", path], 0);
};

export const SpotRate = ({ width, campaign }) => {
  const show = useContext(ShowContext);

  return (
    <Col xs={width}>
      <div className={"text-end bold"}>{formatMoney(getSpotRateFromShow(show, campaign))}</div>
    </Col>
  );
};

export const NegotiateRateV2 = ({
  show,
  uuid,
  campaign,
  campaignItem,
  title,
  startDate,
  endDate,
  negotiatedRateValue,
  originalRateValue,
  budgets,
  updateBudgets,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const isNegotiatedRateAvailable = Boolean(campaignItem?.offerRates?.enabled);
  const dailyImpressions = getDailyImpressions(show);

  const [formValue, setFormValue] = useState(
    isNegotiatedRateAvailable ? parseFloat((negotiatedRateValue / 100).toFixed(2)) : null
  );
  const [isSelected, setIsSelected] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const canNegotiate =
    useSelector((state) => state.user.user?.userAttributes?.canNegotiate === "true") &&
    canAdvertiserAccess(permissionTypes.negotiateRates, campaign);

  const percentageOff = useMemo(() => {
    if (formValue === null || formValue === undefined) {
      return null;
    }
    if (formValue === originalRateValue / 100) {
      return 0;
    }

    return Math.round(((formValue - originalRateValue / 100) / (originalRateValue / 100)) * 100);
  }, [formValue, originalRateValue]);

  const feedback = useMemo(() => {
    if (percentageOff === null || percentageOff === undefined) {
      return {
        status: "idle",
        message: null,
      };
    } else if (percentageOff < -50) {
      return {
        status: "warning",
        message: "Offer amount too low",
      };
    } else if (percentageOff >= -50 && percentageOff < -30) {
      return {
        status: "warning",
        message: "Offer likely to be declined. We recommend increasing this amount.",
      };
    } else if (
      percentageOff >= -30 &&
      percentageOff <= 0 &&
      formValue !== originalRateValue / 100
    ) {
      return {
        status: "success",
        message: null,
      };
    } else if (percentageOff === 0 && formValue === originalRateValue / 100) {
      return {
        status: "warning",
        message: "Your offer amount is the same as the podcaster's rate.",
      };
    } else if (percentageOff > 0 && formValue > originalRateValue / 100) {
      return {
        status: "warning",
        message: "Your offer amount is higher than the podcaster's rate.",
      };
    } else {
      return {
        status: "idle",
        message: null,
      };
    }
  }, [formValue, originalRateValue]);

  const saveNewRate = () => {
    const newRateValue = Math.floor(formValue * 100);

    const budget = budgets[uuid];

    const currentCPM = getAverageCPM({ show, campaign, campaignItem, when: "final" });

    const currentImpressions = getImpressionsFromBudget({ cpm: currentCPM, budget });

    dispatch(
      updateCampaignItems(
        campaign.uuid,
        {
          [uuid]: {
            ...(startDate && {
              startAt: startDate,
            }),
            offerRates: {
              enabled: true,
              ...(title === "CPM"
                ? {
                    originalPreRollCPM: show?.advertisementSettings?.hostReadPreRollCPM,
                    originalMidRollCPM: show?.advertisementSettings?.hostReadMidRollCPM,
                    originalPostRollCPM: show?.advertisementSettings?.hostReadPostRollCPM,
                    finalAdjustedPreRollCPM: newRateValue,
                    finalAdjustedMidRollCPM: newRateValue,
                    finalAdjustedPostRollCPM: newRateValue,
                  }
                : {
                    originalSpotRate: originalRateValue,
                    finalAdjustedSpotRate: newRateValue,
                  }),
            },
          },
        },
        `You’ve updated the rate on ${show.title} to ${formatMoney(newRateValue)}.`,
        "large"
      )
    ).then((resp) => {
      if (resp?.status === 200) {
        const newBudget = getBudget({
          cpm: newRateValue,
          impressions: currentImpressions,
        });

        updateBudgets((prev) => {
          const newBudgets = { ...prev };
          newBudgets[uuid] = newBudget;
          return newBudgets;
        });

        setFormValue(parseFloat((newRateValue / 100).toFixed(2)));
      }
    });

    ref.current.hide();
  };

  const removeNewRate = () => {
    const budget = budgets[uuid];

    const currentCPM = getAverageCPM({ show, campaign, campaignItem, when: "final" });
    const originalCPM = getAverageCPM({ show, campaign, campaignItem, when: "original" });

    const currentImpressions = getImpressionsFromBudget({ cpm: currentCPM, budget });

    dispatch(
      updateCampaignItems(
        campaign.uuid,
        {
          [uuid]: {
            ...(startDate && {
              startAt: startDate,
            }),
            offerRates: {
              enabled: false,
            },
          },
        },
        `The rate on ${show.title} has been reset to ${formatMoney(originalRateValue)}.`,
        "large"
      )
    ).then((resp) => {
      if (resp?.status === 200) {
        const newBudget = getBudget({
          cpm: originalCPM,
          impressions: currentImpressions,
        });

        updateBudgets((prev) => {
          const newBudgets = { ...prev };
          newBudgets[uuid] = newBudget;
          return newBudgets;
        });

        setFormValue(null);
      }
    });
    ref.current.hide();
  };

  const reSyncFormValueWithCPM = () => {
    setFormValue(
      isNegotiatedRateAvailable ? parseFloat((negotiatedRateValue / 100).toFixed(2)) : null
    );
    setIsSelected(false);
  };

  // FEATURE FLAG, DELETE THIS (shouldShowRateNegotiation) LATER TO REMOVE URL BASED FLAG
  const enabledRateNegotiation = shouldShowRateNegotiation() || canNegotiate;
  const extraOptions = enabledRateNegotiation ? {} : { trigger: [] };
  // FEATURE FLAG

  const popover = (
    <Popover id="popover-basic" bsClass="renegotiate-popup-container popover">
      <div className="renegotiate-popup">
        <div className="renegotiate-popup-top">
          <div className="renegotiate-popup-top__text">
            <span className="renegotiate-popup-top__text--title">
              Rate Negotiation
              <Icon name="money-tag" className="renegotiate-popup-top__text--svg" />
            </span>
            <span className="renegotiate-popup-top__text--subtitle">
              The podcaster will have one opportunity to accept or decline your offer
            </span>
          </div>
          <div className="renegotiate-popup-top__cpm">
            <span className="renegotiate-popup-top__cpm--title">
              {title === "CPM" ? title : "spot"} rate
            </span>
            <span className="renegotiate-popup-top__cpm--subtitle">
              {formatMoney(originalRateValue)}
            </span>
          </div>
        </div>
        <div className={`renegotiate-popup-bottom ${feedback.status}`}>
          <FormGroup
            controlId="renegotiate-cpm-form"
            className="renegotiate-popup-bottom__formGroup">
            <ControlLabel className="renegotiate-popup-bottom__label">
              Rate you want to offer this show{" "}
              <InfoTooltip
                helpText="This is the rate that will be sent to the podcaster."
                direction="top"
                height={14}
                style={{ color: "#c6c6c6", marginBottom: 2 }}
              />
            </ControlLabel>
            <div className="renegotiate-popup-bottom__input">
              <NumberFormat
                className="renegotiate-popup-bottom__input--value"
                value={formValue}
                thousandSeparator={true}
                decimalScale={2}
                onValueChange={(newVal) => {
                  const { formattedValue, floatValue } = newVal;
                  if (floatValue == null) {
                    setFormValue(null);
                  } else if (floatValue < 0) {
                    setFormValue(0);
                  } else {
                    setFormValue(floatValue);
                  }
                }}
                prefix="$ "
                autoFocus
              />
              <div className="renegotiate-popup-bottom__input--ribbon">
                {percentageOff === null ? (
                  "% off"
                ) : (
                  <>
                    <Icon
                      name="money-tag"
                      className="renegotiate-popup-bottom__input--ribbon--svg"
                    />
                    {`${percentageOff}% ${percentageOff <= 0 ? "off" : "higher"}`}
                  </>
                )}
              </div>
            </div>
            <span className="renegotiate-popup-bottom__feedback">{feedback?.message}</span>
          </FormGroup>
          <div className="renegotiate-popup-bottom__btnGroup">
            <button onClick={removeNewRate} disabled={!isNegotiatedRateAvailable}>
              Remove
            </button>
            <button onClick={() => ref.current.hide()}>Cancel</button>
            <button
              disabled={feedback?.status !== "success" && feedback?.status !== "warning"}
              onClick={saveNewRate}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger
        key="negotiate-CPM"
        trigger="click"
        placement="right"
        ref={ref}
        onEnter={() => setIsSelected(true)}
        onExited={reSyncFormValueWithCPM}
        overlay={popover}
        {...extraOptions}>
        <div
          className={classNames("campaign-podcast-selector-table-cell--CPM", {
            disable: !enabledRateNegotiation,
          })}>
          <span className={"campaign-podcast-selector-table-cell--CPM__subtitle"}>
            {isNegotiatedRateAvailable
              ? formatMoney(negotiatedRateValue)
              : formatMoney(originalRateValue)}

            <If condition={enabledRateNegotiation}>
              {isNegotiatedRateAvailable ? (
                <Icon
                  name="money-tag"
                  className="campaign-podcast-selector-table-cell--CPM__svg2"
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: `${
                      isSelected ? "#577d9e" : isHover ? "#E5E5E5" : "transparent"
                    }`,
                    fill: `${isSelected ? "white" : "#577d9e"}`,
                    padding: `${isSelected ? "2px" : isHover ? "2px" : "0px"}`,
                  }}
                />
              ) : (
                <Icon
                  name="edit"
                  className="campaign-podcast-selector-table-cell--CPM__svg"
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: `${
                      isSelected ? "#c4c4c4" : isHover ? "#E5E5E5" : "transparent"
                    }`,
                  }}
                />
              )}
            </If>
          </span>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export const AverageCPM = ({ width, campaign }) => {
  const show = useContext(ShowContext);
  if (!campaign) {
    return (
      <Col xs={width}>
        <div className={"bold"}>{formatMoney(getAverageCPM({ show, campaign }))}</div>
      </Col>
    );
  }
  const campaignStyle = campaign.style;
  const prefix = campaignStyle === "hostRead" ? "hostRead" : "preRecorded";

  const overlay = () => (
    <Popover id={"CPM-popover"} bsClass={"p-a0 popover"}>
      <div className={"cpm-popover flex-row-container"}>
        {["PreRollCPM", "MidRollCPM", "PostRollCPM"].map((fieldName) => (
          <div key={fieldName}>
            <div className={"bold small bg-mblue p-bxxs p-txs p-hxs line-height-1em"}>
              {fieldName.slice(0, -3).toUpperCase()}
            </div>
            <div className={"bold text-center p-hxxs p-txxs p-bxs  line-height-1em"}>
              {formatMoney(show?.advertisementSettings?.[prefix + fieldName] ?? 0)}
            </div>
          </div>
        ))}
      </div>
    </Popover>
  );

  return (
    <Col xs={width}>
      <OverlayTrigger trigger={["hover", "focus"]} placement={"top"} overlay={overlay()}>
        <div className={"bold"}>{formatMoney(getAverageCPM({ show, campaign }))}</div>
      </OverlayTrigger>
    </Col>
  );
};

export const AddToCampaign = ({ width, onRemove, onClick, selectedShows, disabledShows }) => {
  const show = useContext(ShowContext);

  if (disabledShows.has(show.uuid)) {
    return <span className="c-black-30">Invited</span>;
  }
  const selectState = selectedShows[show.uuid] ?? 0b00;

  return (
    <Col xs={width}>
      {selectState & 0b1 ? (
        <div className="fake-link text-end" onClick={() => onRemove(show)}>
          Remove
        </div>
      ) : (
        <div className="fake-link text-end" onClick={() => onClick(show)}>
          Add
        </div>
      )}
    </Col>
  );
};

export const useTriggerIf = (conditions, Content, Triggercontent) => {
  const [showPopover, updateShowPopover] = useState(false);
  const close = () => updateShowPopover(false);
  const ref = useRef(null);
  return (
    <div>
      {Content({ ref, onClick: () => updateShowPopover(true) })}
      {conditions && (
        <Overlay
          rootClose
          placement={"bottom"}
          show={showPopover}
          onHide={() => updateShowPopover(false)}
          container={ref.current}
          target={() => ref.current}>
          {typeof Triggercontent === "function" ? Triggercontent(close) : Triggercontent}
        </Overlay>
      )}
    </div>
  );
};

const ShowContext = React.createContext();

export const renderToContext = (children) => (text, show) => {
  if (!show || isEmpty(show)) {
    return null;
  }
  return <ShowContext.Provider value={show}>{children}</ShowContext.Provider>;
};
