import { Alert, Image } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { Button as BootrapBtn, Col, Grid, Row as BSRow } from "react-bootstrap";
import { Row } from "antd";
import { classNames } from "react-extras";
import { useHistory, useParams } from "react-router-dom";
import { Field, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import useSubscriptions from "src/hooks/subscriptions";
import { showError, showWarning } from "../../../actions/app";
import { getShowCampaigns } from "../../../actions/campaigns";
import { getShow, getShowForUser, ShowUpdateActionManager } from "../../../action_managers/shows";
import showAPI from "../../../api/shows";
import { inactiveCampaignItemsList } from "../../../constants/campaigns";
import { showMessages } from "../../../constants/notificationMessages";
import { tierLevels } from "../../../constants/tiers";
import { useReduxDispatch, useSelectorTS } from "../../../hooks/redux-ts";
import imgDelete from "../../../icons/delete.png";
import imgRedirect from "../../../icons/redirect.png";
import { getPricingPageURL } from "../../../lib/config";
import { shouldShowTierPerm } from "../../../lib/feature_flag";
import { getActiveHostedPodcasts, isShowRedirected, isShowRemote } from "../../../lib/show";
import { ICampaignItem } from "../../../reducers/campaign_items/types";
import { IShow } from "../../../reducers/shows/types";
import Checkbox from "../../forms/checkbox";
import FormElement from "../../forms/form_element";
import LoadingButton from "../../forms/loading_button/loading_button";
import Divider from "../../lib/divider";
import ExternalLink from "../../lib/external_link";
import FormDropDown from "../../lib/form_dropdown";
import { svgIcon as Icon } from "../../lib/icon";
import Spinner from "../../lib/spinner";
import ShowPageWrapper from "./show_page_wrapper";
import "./show_remove_page.scss";
import Page from "src/components/lib/page/page";
import Breadcrumbs from "src/components/lib/breadcrumbs/breadcrumbs";

interface Params {
  showUUID: string;
}

const hostingPlatformsList = [
  "Acast",
  "Anchor",
  "Art19",
  "Audioboom",
  "Blubrry",
  "Buzzsprout",
  "Libsyn",
  "Megaphone",
  "Omny",
  "Podbean",
  "Self-Hosted",
  "Spreaker",
  "Simplecast",
  "Soundcloud",
  "Sounder.fm",
  "Other",
] as const;

const formName = "showRemovePage";

interface IReduxFormProps {
  hostingPlatform: (typeof hostingPlatformsList)[number];
  rssFeed: string;
  reason: string;
  redirectIUnderstand: boolean;
  deleteIUnderstand: boolean;
}

const ShowRemovePage = (props: InjectedFormProps<IReduxFormProps>) => {
  const { handleSubmit, reset, submitting } = props;
  const history = useHistory();
  const dispatch = useReduxDispatch();
  const params = useParams<Params>();
  const showUUID = params.showUUID;
  const { tier, user } = useSelectorTS((state) => state?.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const currentShow = shows[showUUID] ?? null;
  const campaignItemByUUID: Record<string, ICampaignItem> = useSelectorTS(
    (state) => state?.campaignItems?.campaignItems ?? null
  );
  const currentShowCampaignItemsByUUID: string[] =
    useSelectorTS((state) => state?.campaignItems?.showCampaignItemUUIDs?.[showUUID]) ?? [];
  const formValues = useSelectorTS((state) => getFormValues(formName)(state) as IReduxFormProps);
  const { deleteIUnderstand, redirectIUnderstand, hostingPlatform, rssFeed, reason } =
    formValues ?? {};

  const isShowInfoLoading = useSelectorTS(
    (state) => state?.shows?.isLoading || state?.campaignItems?.isLoading
  );

  const { showSubscriptions } = useSubscriptions({ showUUID });

  const activeSubscribers = showSubscriptions.filter((sub) => sub.state === "active");

  // Calculate Button allow submission
  const deletePodcastEnabled = deleteIUnderstand;
  const redirectPodcastEnabled =
    redirectIUnderstand &&
    hostingPlatform?.length > 1 &&
    rssFeed?.length > 1 &&
    reason?.trim()?.length >= 1;
  const isButtonloading = submitting;

  // Show has active Campaign Items
  const campaignItems = currentShowCampaignItemsByUUID.map((uuid) => campaignItemByUUID[uuid]);
  const activeCampaignItems = campaignItems.filter(
    ({ state }) => !inactiveCampaignItemsList.has(state)
  );

  // Flags
  const showHasActiveCampaigns = activeCampaignItems?.length > 1;

  const showHasRedirected = Number(currentShow?.redirectAwayURL?.length) > 1;

  const showIsSpecialRedirect = currentShow?.softRedirectAwayURL?.length > 1;

  const showIsRemote = isShowRemote(currentShow);

  let showHasRedirectedRecently = false;

  if (showHasRedirected && moment.unix(currentShow?.redirectedAwayAt).isValid()) {
    const today = moment();
    const allowDeleteShowDate = moment.unix(currentShow?.redirectedAwayAt).add(30, "days");
    showHasRedirectedRecently = today.isBefore(allowDeleteShowDate);
  }

  //Helpers

  const tierCoreRedirectDisabled = () => {
    if (!shouldShowTierPerm()) return false; // Ignore pre launch

    if (tier.level !== tierLevels.core) return false; // Logic only for core plan

    const listOfShows = Object.keys(shows);

    const activeShows = listOfShows.filter((showUUID) => !isShowRedirected(shows[showUUID]));

    if (listOfShows?.length > 1 && activeShows?.length >= 1) return isShowRedirected(currentShow);

    return false;
  };

  // Click handlers
  const onDeleteSubmit = () => {
    return dispatch(
      new ShowUpdateActionManager({
        show: { uuid: showUUID, isHardDeleted: true, isVisible: false },
        user,
        successMessage: `Your podcast ${currentShow?.title} was successfully deleted.`,
        large: true,
      }).run()
    ).then((resp) => {
      if (resp?.status === 200) {
        history.push(`/shows`);
      }
    });
  };

  const onRedirectSubmit = () => {
    return dispatch(
      new ShowUpdateActionManager({
        show: {
          uuid: showUUID,
          redirectAwayURL: rssFeed,
          redirectedAwayAt: moment().unix(),
          redirectAwayFeedback: {
            destination: hostingPlatform,
            reason,
          },
        },
        user,
        successMessage: `Your podcast ${currentShow?.title} has been redirected.`,
        large: true,
      }).run()
    ).then((resp) => {
      if (resp?.status === 200) {
        // do nothing
      }
    });
  };

  const onRemoveRedirectSubmit = async () => {
    try {
      // Check for tier core hack
      if (tier.level === tierLevels.core && !tierCoreRedirectDisabled()) {
        // Using showApi to fetch shows to not cause weird re render in dropdown due to redux update
        const currentActiveShows = await showAPI
          .fetchShowsForUser(user)
          .then((resp) => resp.json())
          .then((resp: IShow[]) => getActiveHostedPodcasts(resp));

        if (currentActiveShows?.length >= 1) {
          // Once confirmed that the app state is outdated update shows in redux state
          await dispatch(getShowForUser(user));

          return dispatch(showWarning(showMessages.Warning.TierARedirectHack, 15000));
        }
      }

      // Normal logic for removing redirect
      const resp = await dispatch(
        new ShowUpdateActionManager({
          show: {
            uuid: showUUID,
            redirectAwayURL: null,
            redirectedAwayAt: null,
            redirectAwayFeedback: {},
          },
          user,
          noMessage: true,
        }).run()
      );

      if (resp?.status === 200) {
        dispatch(
          showWarning(
            "You've removed the redirect. To complete this process, please go to the previous host's website and add your RedCircle RSS Feed.",
            10000
          )
        );
        reset();
      }
    } catch (err) {
      // Erro most likely caused by internet connection
      dispatch(showError(showMessages.Error.PleaseTryAgain, 6000));
    }
  };

  // Dropdowns
  const dropdowns = [
    {
      type: "delete",
      imgUrl: imgDelete,
      title: "Delete Podcast",
      content: (
        isShowInfoLoading: boolean,
        showHasActiveCampaigns: boolean,
        showHasRedirected: boolean,
        showHasRedirectedRecently: boolean
      ) => {
        if (isShowInfoLoading) {
          return <Spinner />;
        }

        if (showHasActiveCampaigns) {
          return pleaseContactSupport("delete", 1);
        }

        if (showHasRedirected && showHasRedirectedRecently) {
          return pleaseContactSupport("delete", 2);
        }

        const bulletPoints = [];

        if (currentShow?.isRemote) {
          bulletPoints.push("Permanently remove your show from RedCircle");
        } else {
          bulletPoints.push(
            "Permanently delete your RSS feed",
            "Remove your podcast from all listening apps (eg Apple, Spotify, etc)"
          );
        }

        bulletPoints.push("Be irreversible");

        return (
          <div className="p-ts m-bl">
            <h3 className="m-bxxs">This action will:</h3>
            <ul className="m-a0 p-a0 list-style-none m-bs">
              {bulletPoints.map((text) => {
                return (
                  <li key={text} className="flex-row-container align-center">
                    <Icon
                      name="disable-mouse"
                      height={20}
                      width={20}
                      fill={"#979797"}
                      className="m-rs"
                    />
                    {text}
                  </li>
                );
              })}
            </ul>
            <Field
              component={FormElement}
              InputComponent={Checkbox}
              className="showRemovePage__checkbox"
              useAntd={true}
              name="deleteIUnderstand"
              checkboxLabel={
                <span className="bold user-form__fine-print">I understand and agree</span>
              }
              type="checkbox"
            />
            <br />
            <LoadingButton
              isLoading={isButtonloading}
              Button={BootrapBtn}
              className="btn-primary btn-large fs-xs lh-s p-hxl"
              disabled={!deletePodcastEnabled}
              onClick={handleSubmit(onDeleteSubmit)}>
              Permanently Delete
            </LoadingButton>
          </div>
        );
      },
    },
    {
      type: "redirect",
      imgUrl: imgRedirect,
      title: "Redirect Podcast",
      content: (
        isShowInfoLoading: boolean,
        showHasActiveCampaigns: boolean,
        showHasRedirected: boolean,
        showHasRedirectedRecently: boolean,
        showIsSpecialRedirect: boolean,
        tierCoreRedirectDisabled: boolean
      ) => {
        if (isShowInfoLoading) {
          return <Spinner />;
        }

        if (showHasActiveCampaigns) {
          return pleaseContactSupport("redirect", 1);
        }

        if (showIsSpecialRedirect) {
          return pleaseContactSupport("redirect", 3);
        }

        if (showHasRedirected) {
          return (
            <div className="p-ts">
              <p className="m-bm">Your podcast has been redirected to the new RSS feed:</p>
              <Field
                name="rssFeed"
                type="text"
                placeholder={currentShow?.redirectAwayURL}
                component={FormElement}
                disabled
                required
              />
              {tierCoreRedirectDisabled ? (
                <p className="m-bl">
                  To remove the redirect, you’ll need to remove the other active podcasts on your
                  account or{" "}
                  <ExternalLink href={getPricingPageURL()}>Upgrade Your Plan</ExternalLink>
                </p>
              ) : (
                <LoadingButton
                  isLoading={isButtonloading}
                  Button={BootrapBtn}
                  className="btn-primary btn-large fs-xs lh-s p-hxl"
                  onClick={handleSubmit(onRemoveRedirectSubmit)}>
                  Remove Redirect
                </LoadingButton>
              )}
            </div>
          );
        }

        return (
          <div className="p-ts">
            <p className="m-bl">
              We’re sorry to see you go, but here to help you move your podcast to another hosting
              platform.
            </p>
            <Field
              component={FormElement}
              name="hostingPlatform"
              label="YOUR NEW HOSTING PLATFORM *"
              className="showRemovePage__dropdown"
              type="select"
              componentClass="select"
              aria-placeholder="Select your new host from the "
              required
              value="">
              <option value="" disabled>
                Select your new host from the dropdown
              </option>
              {hostingPlatformsList.map((platform) => {
                return (
                  <option key={platform} value={platform}>
                    {platform}
                  </option>
                );
              })}
            </Field>
            <Field
              name="rssFeed"
              label="Your new rss feed *"
              type="text"
              placeholder="https://newhost.com/feedURL"
              component={FormElement}
              required
            />
            <Field
              name="reason"
              label="Why are you leaving redcircle? *"
              type="text"
              placeholder="Please let us know why you’re leaving and any feedback on your RedCircle experience."
              component={FormElement}
              required
            />
            <Field
              component={FormElement}
              InputComponent={Checkbox}
              className="showRemovePage__checkbox"
              useAntd={true}
              name="redirectIUnderstand"
              checkboxLabel={
                <span className="user-form__fine-print">
                  <strong>I understand:</strong> this will redirect my podcast off of RedCircle, and
                  any changes I make in my RedCircle account will not be updated for listeners.
                </span>
              }
              type="checkbox"
            />
            <br />
            <LoadingButton
              isLoading={isButtonloading}
              Button={BootrapBtn}
              className="btn-primary btn-large fs-xs lh-s p-hxl"
              disabled={!redirectPodcastEnabled}
              onClick={handleSubmit(onRedirectSubmit)}>
              Redirect
            </LoadingButton>
          </div>
        );
      },
    },
  ];

  /**
   * Remove Redirect panel
   */
  if (showIsRemote) {
    dropdowns.splice(1, 1);
  }

  useEffect(() => {
    if (!currentShow) {
      dispatch(getShow(showUUID));
    }
    if (!campaignItemByUUID) {
      dispatch(getShowCampaigns(showUUID));
    }
  }, []);

  return (
    <ShowPageWrapper show={currentShow}>
      <Breadcrumbs
        crumbs={[
          { path: "/shows", name: "All Podcasts" },
          { path: `/shows/${showUUID}`, name: currentShow?.title },
          { path: `/shows/${showUUID}/remove`, name: "Remove", active: true },
        ]}
      />

      <Row>
        <ShowPageWrapper.Sidebar />
        <ShowPageWrapper.Body>
          <ShowPageWrapper.Header title={"Remove Podcast"} />
          <Page.Section>
            <form>
              <Grid fluid className="showRemovePage">
                {dropdowns.map(({ imgUrl, title, content, type }) => {
                  return (
                    <React.Fragment key={title}>
                      <BSRow
                        className={classNames("flex-row-container align-baseline", {
                          showRemovePage__disable:
                            showHasActiveCampaigns ||
                            (type === "delete" && showHasRedirectedRecently) ||
                            (type == "redirect" && showIsSpecialRedirect),
                        })}>
                        <Col xs={1}>
                          <Image
                            className="showRemovePage__img"
                            height={48}
                            width={48}
                            preview={false}
                            src={imgUrl}
                            alt={`${title} Icon`}
                          />
                        </Col>
                        <Col xs={11}>
                          <FormDropDown label={title}>
                            {content(
                              isShowInfoLoading,
                              showHasActiveCampaigns,
                              showHasRedirected,
                              showHasRedirectedRecently,
                              showIsSpecialRedirect,
                              tierCoreRedirectDisabled()
                            )}
                          </FormDropDown>
                        </Col>
                      </BSRow>
                      <Divider marginTop={16} marginBottom={24} />
                    </React.Fragment>
                  );
                })}
                {activeSubscribers?.length > 0 && (
                  <Alert
                    type="warning"
                    message={`${
                      currentShow ? currentShow?.title : "Podcast"
                    } currently has active subscribers. These subscriptions will still remain active even after podcast redirect/removal. Please ensure you communicate any relevant updates of the podcast to your subscribers.`}
                  />
                )}
              </Grid>
            </form>
          </Page.Section>
        </ShowPageWrapper.Body>
      </Row>
    </ShowPageWrapper>
  );
};

// helper warning content function
const pleaseContactSupport = (type: "delete" | "redirect", contentVersion = 1) => {
  let title = "";
  let text = "";

  switch (contentVersion) {
    case 1:
      title = `Please contact support to ${type} this podcast`;
      text =
        "Your podcast is part of a live campaign in the RedCircle Ad Platform (RAP) and requires additional assistance to redirect.";
      break;
    case 2:
      title = `Check back later to delete your podcast`;
      text =
        "Your podcast has been recently redirected. You will be able to delete your podcast 30 days after you’ve redirected.";
      break;
    case 3:
      title = `Please contact support to redirect this podcast`;
      text = "Your podcast requires additional assistance to redirect.";
      break;
    default:
      title = `Please contact support to ${type} this podcast`;
      text =
        "Your podcast is part of a live campaign in the RedCircle Ad Platform (RAP) and requires additional assistance to redirect.";
  }
  return (
    <div className="flex-column-container align-center">
      <h3 className="m-txs m-b0 text-center">{title}</h3>
      <div className="flex-row-container justify-center align-top m-vl">
        <Icon name="lightbulb" height={32} width={32} className="m-rxxs" />
        <p className="lh-s m-b0" style={{ width: "60%" }}>
          {text}
        </p>
      </div>
      {contentVersion !== 2 && (
        <span className="small-highlight lh-s showRemovePage__text">
          Please email <a href="mailto:support@getredcircle.com">support@getredcircle.com</a> for
          assistance.
        </span>
      )}
    </div>
  );
};

const reduxFormWrapper = reduxForm<IReduxFormProps>({
  form: formName,
})(ShowRemovePage);

export default reduxFormWrapper;
