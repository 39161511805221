import { itunesCategories } from "../data/itunes_categories";
import { itunesCategoryGroups } from "../data/itunes_category_groups";

export const itunesCategoriesByUUID = {};
for (var category of itunesCategories) {
  itunesCategoriesByUUID[category.uuid] = category;
}

export function getItunesCategoryStructure(categoryUUIDs) {
  var structure = {};
  for (var categoryUUID of categoryUUIDs) {
    category = itunesCategoriesByUUID[categoryUUID];
    if (category.group_uuid) {
      if (structure[categoryUUID]) {
        structure[categoryUUID].children.push(category);
      } else {
        structure[categoryUUID] = {
          name: itunesCategoryGroups[category.group_uuid],
          children: [category],
        };
      }
    } else {
      structure[categoryUUID] = {
        name: category.name,
      };
    }
  }

  return structure;
}
