import { Divider as AntDivider } from "antd";
import React from "react";
import { classNames } from "react-extras";
import BackButton from "../back_button";
import HelmetPageTitle from "../helmet_page_title/helmet_page_title";
import styles from "./page.module.scss";

interface IPageProps {
  children: React.ReactNode;
  className?: string;
  pageTitle?: string;
  style?: React.CSSProperties;
}

interface ISectionProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

interface IBackProps {
  to: string;
  children: string;
}

interface IDividerProps {
  className?: string;
}

const Page: React.FC<IPageProps> & {
  Title: React.FC<ISectionProps>;
  Header: React.FC<ISectionProps>;
  Section: React.FC<ISectionProps>;
  Sidebar: React.FC<ISectionProps>;
  Back: React.FC<IBackProps>;
  Divider: React.FC<IDividerProps>;
} = ({ children, className, pageTitle, style }) => {
  return (
    <div className={classNames(className, styles.page)} style={style}>
      {pageTitle && <HelmetPageTitle title={pageTitle} />}
      <main data-main="main-content">{children}</main>
    </div>
  );
};

const Title: React.FC<ISectionProps> = ({ children, className }) => (
  <h1 className={classNames(className, styles.title)}>{children}</h1>
);
Page.Title = Title;

const Header: React.FC<ISectionProps> = ({ children, className, style }) => (
  <header className={classNames(styles.header, className)} style={style}>
    {children}
  </header>
);
Page.Header = Header;

const Section: React.FC<ISectionProps> = ({ children, className, style }) => (
  <section className={classNames(styles.section, className)} style={style}>
    {children}
  </section>
);
Page.Section = Section;

const Sidebar: React.FC<ISectionProps> = ({ children, className, style }) => (
  <aside className={classNames(styles.sidebar, className)} style={style}>
    {children}
  </aside>
);
Page.Sidebar = Sidebar;

const Back: React.FC<IBackProps> = ({ children, to }) => <BackButton to={to} text={children} />;
Page.Back = Back;

const Divider: React.FC<IDividerProps> = ({ className }: { className?: string }) => (
  <AntDivider className={classNames("m-txxs", className)} />
);
Page.Divider = Divider;

export default Page;
