import UserRoles from "src/constants/roles";
import { User } from "src/reducers/user";

export const GTM_CONSTS = {
  SIGN_UP_SUCCESS: "sign_up_success",
  LOG_IN_SUCCESS: "log_in_success",
  LOG_OUT: "log_out",
};

export const GoogleTagManager = {
  fireEvent: (event: string, params: Record<any, any> = {}) => {
    if (window) (window as any).dataLayer.push({ event: event, ...params });
  },
  configUser: (user: User) => {
    // initializes the user in the data layer with attached parameters
    const roles = user.roles ? Object.keys(user.roles) : [];
    // role priority is: admin, advertiser, creator, sponsor
    let role;
    if (roles.includes(UserRoles.Admin)) {
      role = UserRoles.Admin;
    } else if (roles.includes(UserRoles.Advertiser)) {
      role = UserRoles.Advertiser;
    } else if (roles.includes(UserRoles.Creator)) {
      role = UserRoles.Creator;
    } else if (roles.includes(UserRoles.Sponsor)) {
      role = UserRoles.Sponsor;
    }
    if (window) (window as any).dataLayer.push({ user_roles: roles, user_role: role });
  },
};
