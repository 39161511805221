import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { string } from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteCustomAudio,
  getAudioBlocksForCustomAudios,
  getCustomAudioForUser,
  getCustomAudioMediaFile,
  getInsertionsForCustomAudios,
  getMediaFilesForCustomAudios,
} from "src/action_managers/audio_management";
import BlockFeature from "src/components/lib/block_feature";
import RCButton from "src/components/lib/button/button";
import Loading from "src/components/lib/loading";
import { mediaFileReady } from "src/components/pages/dynamic_insertion/dynamic_insertion_util";
import { permissionTypes } from "src/constants/permission_roles";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { goToPricingPage } from "src/lib/config";
import { useCanAccessBound } from "src/lib/permissions";
import { minimumAllowedTier } from "src/lib/tier-utils";
import { CustomAudio } from "src/reducers/custom_audio";
import { tierLevel } from "src/reducers/user/types";
import CustomAudioDrawer from "./custom_audio_drawer";
import CustomAudioTable from "./custom_audio_table";
import { AsyncFunction, parallelLimit } from "async";
import { chunk } from "lodash";

export default function CustomAudioSection() {
  const dispatch = useReduxDispatch();
  const history = useHistory();
  const { tab, modifier, objectUUID } = useParams<{
    tab?: string;
    modifier?: string;
    objectUUID?: string;
  }>();

  const { isLoading: isUserLoading, user, tier } = useSelectorTS((state) => state.user);
  const {
    isLoading: isAudioLoading,
    customAudios,
  }: { isLoading: boolean; customAudios: Record<string, CustomAudio> } = useSelectorTS(
    (state) => state.customAudios
  );

  const [query, setQuery] = useState("");
  const [record, setRecord] = useState<any>();
  const [isBatchLoading, setIsBatchLoading] = useState(false);
  const isDrawerOpen = tab === "custom-audio" && (modifier === "edit" || modifier === "create");
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const canAccess = useCanAccessBound();
  const canViewCustomAudio = minimumAllowedTier(tierLevel.growth, tier, true);
  // For context on why we're using audio block permissions for custom audio,
  // and why we use "create" for every operation, including update, see:
  // notion.so/getredcircle/3324d0863df6457c919207f8495194a0
  const userCanCreateAndEdit = canAccess(permissionTypes.createAudioBlock);

  const isTableLoading = isAudioLoading || isBatchLoading;

  useEffect(() => {
    if (isInitialLoading && !isUserLoading && !isAudioLoading) {
      setIsInitialLoading(false);
    }
  }, [isUserLoading, isAudioLoading]);

  useEffect(() => {
    if (!tab || tab === "custom-audio") getData();
  }, [tab]);

  useEffect(() => {
    const timers: NodeJS.Timer[] = [];

    Object.values(customAudios)
      .filter(
        (customAudio: CustomAudio) =>
          customAudio.mediaFile && !mediaFileReady(customAudio.mediaFile)
      )
      .forEach((customAudio: CustomAudio) => {
        timers.push(setTimeout(() => dispatch(getCustomAudioMediaFile(customAudio)), 3000));
      });

    return () => {
      timers.length > 0 && timers.forEach((timer) => clearTimeout(timer));
    };
  }, [customAudios]);

  useEffect(() => {
    // change record based on uuid
    if (objectUUID && customAudios[objectUUID]) {
      setRecord(customAudios[objectUUID]);
    }
  }, [objectUUID, customAudios]);

  const getData = () => {
    dispatch(getCustomAudioForUser(user))
      .then(({ json: customAudios }) => {
        if (customAudios) {
          const CUSTOM_AUDIOS_CHUNK_SIZE = 100;
          const PARALLEL_BATCH_SIZE = 3;

          const tasks: AsyncFunction<unknown, Error>[] = [];

          chunk(customAudios as CustomAudio[], CUSTOM_AUDIOS_CHUNK_SIZE).forEach(
            (customAudiosBatch) => {
              tasks.push((callback: any) => {
                dispatch(getAudioBlocksForCustomAudios(user, customAudiosBatch)).then((resp) =>
                  callback(null, resp)
                );
              });

              tasks.push((callback: any) => {
                dispatch(getMediaFilesForCustomAudios(customAudiosBatch)).then((resp) =>
                  callback(null, resp)
                );
              });

              tasks.push((callback: any) => {
                dispatch(getInsertionsForCustomAudios(customAudiosBatch)).then((resp) =>
                  callback(null, resp)
                );
              });
            }
          );

          setIsBatchLoading(true);

          parallelLimit(tasks, PARALLEL_BATCH_SIZE, (err, result) => {
            setIsBatchLoading(false);
          });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const handleAddAudio = () => {
    setQuery("");
    history.push("/dynamic-insertion/custom-audio/create");
  };

  const handleEditAudio = (record: any) => {
    if (userCanCreateAndEdit) {
      setRecord(record);
      history.push(`/dynamic-insertion/custom-audio/edit/${record.uuid}`);
    }
  };

  const handleDeleteAudio = async (record: any) => {
    return dispatch(deleteCustomAudio(record.uuid)).then(() => {
      getData();
    });
  };

  const handleDrawerClose = () => {
    setRecord(null);
    history.push("/dynamic-insertion/custom-audio");
  };

  return (
    <div className="flex-column-container">
      <div className="flex-row-container justify-space-between align-center m-bxs">
        <h3 className="m-b0">Custom Audio</h3>

        {!isInitialLoading && canViewCustomAudio && userCanCreateAndEdit && (
          <RCButton type="secondary" onClick={handleAddAudio}>
            Add New Audio
          </RCButton>
        )}
      </div>

      {isInitialLoading && <Loading />}
      {!isInitialLoading && (
        <BlockFeature
          block={!isUserLoading && !canViewCustomAudio}
          blockbyBlur
          minHeight={400}
          CTA={{
            text: "Custom Audio available starting from Growth Plan",
            btn: { text: "Upgrade Your Plan", handler: () => goToPricingPage() },
          }}>
          <div className="flex-row-container m-bxs">
            <Input
              type="search"
              size="large"
              placeholder="Search Custom Audio"
              prefix={<SearchOutlined />}
              allowClear
              value={query}
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>

          <CustomAudioTable
            customAudios={customAudios}
            query={query}
            tier={tier}
            onEditAudio={handleEditAudio}
            isLoading={isTableLoading}
          />

          <CustomAudioDrawer
            visible={isDrawerOpen}
            record={record}
            tier={tier}
            onClose={handleDrawerClose}
            onDelete={handleDeleteAudio}
          />
        </BlockFeature>
      )}
    </div>
  );
}
