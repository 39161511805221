import { TTriCheckboxState } from "src/components/lib/checkbox";
import { IEpisode } from "src/reducers/episode_by_show";
import { Marker } from "src/reducers/markers";

export const getCellCheckedState = (
  marker: Marker,
  markersToUpdate: Record<string, boolean>,
  audioBlockUUID: string
): TTriCheckboxState => {
  if (markersToUpdate[marker.uuid]) return "checked";
  if (markersToUpdate[marker.uuid] === false) return "unchecked";
  if (audioBlockUUID && marker.audioBlockUUID === audioBlockUUID) return "checked";
  return marker.audioBlockUUID ? "indeterminate" : "unchecked";
};

// converts { markerUUID: marker } to { episodeUUID: { markerUUID: marker } }
export const transformMarkersByEpisodeUUID = (markers: any) => {
  const transformed: Record<string, any> = {};
  if (!markers) return transformed;
  Object.keys(markers).forEach((markerUUID) => {
    const marker = markers[markerUUID];
    const { episodeUUID } = marker;
    if (!transformed[episodeUUID]) {
      transformed[episodeUUID] = { [markerUUID]: marker };
    } else {
      transformed[episodeUUID][markerUUID] = marker;
    }
  });
  return transformed;
};

// HELPER FUNCTIONS FOR ASSIGNMENT OPTIONS
export type TEnhancedEpisode = IEpisode & { markers: Record<string, Marker> };
type assignmentFunc = (
  rollType: string,
  episodes: TEnhancedEpisode[]
) => Record<string, boolean | undefined>;

const getMarkers = (rollType: string, episodes: TEnhancedEpisode[]) => {
  const episodesWithMarkers = episodes
    .filter((episode) => episode.markers && Object.keys(episode.markers).length)
    .map((episode) => {
      return {
        ...episode,
        markers: Object.values(episode.markers)
          .filter((marker) => marker.position === rollType)
          .sort((a: any, b: any) => a.offsetBytes - b.offsetBytes),
      };
    });
  return episodesWithMarkers;
};

const getFirstMarkersAssign: assignmentFunc = (rollType, episodes) => {
  const episodesWithMarkers = getMarkers(rollType, episodes);
  const firstMarkers: Marker[] = [];
  episodesWithMarkers.forEach((episode) => {
    if (episode.markers.length) firstMarkers.push(episode.markers[0]);
  });
  return firstMarkers.reduce((acc, marker) => ({ ...acc, [marker.uuid]: true }), {});
};

const getFirstAvailableMarkers: assignmentFunc = (rollType, episodes) => {
  const episodesWithMarkers = getMarkers(rollType, episodes);
  const firstAvailableMarkers: Marker[] = [];
  episodesWithMarkers.forEach((episode) => {
    const markers = Object.values(episode.markers).filter((marker) => !marker.audioBlockUUID); // marker can't have audioBlock
    if (markers.length) firstAvailableMarkers.push(markers[0]);
  });
  return firstAvailableMarkers.reduce((acc, marker) => ({ ...acc, [marker.uuid]: true }), {});
};

const getLastMarkersAssign: assignmentFunc = (rollType, episodes) => {
  const episodesWithMarkers = getMarkers(rollType, episodes);
  const lastMarkers: Marker[] = [];
  episodesWithMarkers.forEach((episode) => {
    if (episode.markers.length) lastMarkers.push(episode.markers[episode.markers.length - 1]);
  });
  return lastMarkers.reduce((acc, marker) => ({ ...acc, [marker.uuid]: true }), {});
};

const getLastAvailableMarkers: assignmentFunc = (rollType, episodes) => {
  const episodesWithMarkers = getMarkers(rollType, episodes);
  const lastAvailableMarkers: Marker[] = [];
  episodesWithMarkers.forEach((episode) => {
    const markers = Object.values(episode.markers).filter((marker) => !marker.audioBlockUUID); // marker can't have audioBlock
    if (markers.length) lastAvailableMarkers.push(markers[markers.length - 1]);
  });
  return lastAvailableMarkers.reduce((acc, marker) => ({ ...acc, [marker.uuid]: true }), {});
};

const getAllMarkersAssign: assignmentFunc = (rollType, episodes) => {
  const episodesWithMarkers = getMarkers(rollType, episodes);
  const allMarkers: Marker[] = [];
  episodesWithMarkers.forEach((episode) => allMarkers.push(...episode.markers));
  return allMarkers.reduce((acc, marker) => ({ ...acc, [marker.uuid]: true }), {});
};

const getAllMarkersUnassign: assignmentFunc = (rollType, episodes) => {
  const episodesWithMarkers = getMarkers(rollType, episodes);
  const allMarkers: Marker[] = [];
  episodesWithMarkers.forEach((episode) => allMarkers.push(...episode.markers));
  return allMarkers.reduce((acc, marker) => ({ ...acc, [marker.uuid]: false }), {});
};

// map these options to a function that will process episodes respectively
export const ASSIGNMENT_OPTIONS: Record<string, assignmentFunc> = {
  First: getFirstMarkersAssign,
  "First Available": getFirstAvailableMarkers,
  Last: getLastMarkersAssign,
  "Last Available": getLastAvailableMarkers,
  All: getAllMarkersAssign,
  // "All Available": assignToAllAvailable,
  "Unassign All": getAllMarkersUnassign,
};
