import { MediaFile } from "../media_file_upload";

export type MediaFileReduxState = {
  [mediaFileUUID: string]: MediaFile;
};

/**
 *  Type guard to check if an unknown value is a valid MediaFile object,
 * @param value
 * @returns {Boolean}
 */
export function isMediaFile(value?: unknown): value is MediaFile {
  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "userUUID" in value &&
    "fileName" in value &&
    "url" in value &&
    "createdAt" in value &&
    "updatedAt" in value &&
    typeof value.uuid === "string" &&
    typeof value.userUUID === "string" &&
    typeof value.fileName === "string" &&
    typeof value.url === "string" &&
    typeof value.createdAt === "number" &&
    typeof value.updatedAt === "number"
  ) {
    return true;
  }

  return false;
}
