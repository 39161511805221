import React, { ChangeEvent, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { If } from "react-extras";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  PaymentRequestButtonElement,
  PostalCodeElement,
  ReactStripeElements,
} from "react-stripe-elements";
import { showWarning } from "../../../actions/app";
import { addCard } from "../../../action_managers/subscriptions";
import { useReduxDispatch } from "../../../hooks/redux-ts";
import LoadingButton from "../../forms/loading_button/loading_button";
import "./stripe_form.scss";

const styles = {
  base: {
    lineHeight: "48px",
    fontSize: "20px",
    fontFamily: "Gilroy, sans-serif",
  },
  invalid: {
    color: "#ea404d",
  },
};
const elementClasses = {
  focus: "focus",
  empty: "empty",
  invalid: "invalid",
};

export const options = {
  fonts: [
    {
      src: "url(https://pod-public-media-files.s3.us-east-2.amazonaws.com/assets/fonts/gilroy-regular.woff)",
      family: "Gilroy",
      style: "normal",
    },
  ],
};

interface IAddCardFormProps {
  user?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  canMakePayment: boolean;
  paymentRequest?: any;
  width?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: (resp: { card?: string; error?: string }) => Promise<any>;
  ctaText?: string;
}

export function AddCardForm(props: IAddCardFormProps & ReactStripeElements.InjectedStripeProps) {
  const dispatch = useReduxDispatch();
  const showWarningAction = (message: string) => dispatch(showWarning(message));
  const addCardAction = (id: string) => dispatch(addCard(id));
  const [name, setName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!props.onSubmit) {
      return;
    }
    setSubmitting(true);
    const resp = await props.stripe?.createToken({ name });

    if (resp?.error) {
      showWarningAction(resp.error.message ?? "");
      props.onSubmit({ error: resp.error?.message });
      setSubmitting(false);
    }
    const addCardResp = await addCardAction(resp?.token?.id ?? "");
    if (addCardResp?.status !== 200) {
      setSubmitting(false);
      return;
    }
    await props.onSubmit({ card: addCardResp?.json });
    setSubmitting(false);
  };
  return (
    <form action="#" className={"stripe-add-card-view"} {...(props.onSubmit && { onSubmit })}>
      <If condition={!props.user && !!props.onChange}>
        <>
          <label htmlFor={"email-input"}>Email</label>
          <input
            id={"email-input"}
            required
            type="email"
            style={styles.base}
            className={"width-100"}
            onChange={props.onChange}
          />
        </>
      </If>
      <label>Name On Card</label>
      <input
        required
        type="name"
        style={styles.base}
        className={"width-100"}
        onChange={(e) => {
          setName(e.target.value);
          if (props.onNameChange) {
            props.onNameChange(e);
          }
        }}
      />
      <Row>
        <Col xs={12}>
          <label>
            Card Number
            <CardNumberElement classes={elementClasses} style={styles} />
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <label className={"third-width"}>
            Expiration Date
            <CardExpiryElement classes={elementClasses} style={styles} />
          </label>
        </Col>
        <Col xs={12} md={4}>
          <label className={"third-width"}>
            Expiration CVC
            <CardCVCElement classes={elementClasses} style={styles} />
          </label>
        </Col>
        <Col xs={12} md={4}>
          <label className={"third-width"}>
            Postal Code
            <PostalCodeElement style={styles} classes={elementClasses} />
          </label>
        </Col>
      </Row>
      {props.canMakePayment && (
        <div className={"or-divider"}>
          <div className={"space"} />
          <div className={"or bold"}>or</div>
          <div className={"space"} />
        </div>
      )}
      {props.canMakePayment && (
        <PaymentRequestButtonElement
          paymentRequest={props.paymentRequest}
          className="PaymentRequestButton"
          style={{
            // For more details on how to style the Payment Request Button, see:
            // https://stripe.com/docs/elements/payment-request-button#styling-the-element
            paymentRequestButton: {
              theme: "light",
              height: "64px",
            },
          }}
        />
      )}
      {props.onClick && (
        <button className={"fake-link"} onClick={props.onClick}>
          Back to Select Card
        </button>
      )}
      {props.onSubmit && (
        <LoadingButton
          type={"submit"}
          className="btn btn-primary width-100 m-tm"
          isLoading={submitting}>
          {props.ctaText ?? "Confirm"}
        </LoadingButton>
      )}
    </form>
  );
}

export default injectStripe(AddCardForm);
