import { SHOW_TIP } from "../../actions/shows";
import {
  FETCH_SUBSCRIPTIONS_BY_SHOWUUID,
  SUBSCRIBE,
  SUBSCRIPTION_FETCH,
  SUBSCRIPTION_FETCH_FOR_USER,
  UPDATE_SUBSCRIPTION,
} from "../../action_managers/subscriptions";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { Subscription, SubscriptionsReduxState } from "./types";

const initialState: SubscriptionsReduxState = {
  isLoading: false,
  subscriptions: {},
  subscriptionsByShowUUIDs: {},
};

const subscriptions = httpReducer(SUBSCRIPTION_FETCH, initialState, {
  success: (state, action) => {
    const subscription = action.payload.resp;
    return {
      ...state,
      subscriptions: {
        ...state.subscriptions,
        [subscription.uuid]: subscription,
      },
    };
  },
});

const subscribe = httpReducer(
  SUBSCRIBE,
  initialState,
  {
    success: (state, action) => {
      const subscription = action.payload.resp;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [subscription.uuid]: subscription,
        },
      };
    },
  },
  { loadingKeyName: "subscribeIsLoading" }
);

const tip = httpReducer(SHOW_TIP, initialState, {}, { loadingKeyName: "subscribeIsLoading" });

const updateSubscription = httpReducer(
  UPDATE_SUBSCRIPTION,
  initialState,
  {
    success: (state, action) => {
      const subscription = action.payload.resp;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [subscription.uuid]: subscription,
        },
      };
    },
  },
  { loadingKeyName: "subscribeIsLoading" }
);

const subscriptionForUser = httpReducer(SUBSCRIPTION_FETCH_FOR_USER, initialState, {
  success: (state, action) => ({
    ...state,
    subscriptions: action.payload.resp.reduce(
      (agg: SubscriptionsReduxState["subscriptions"], subscription: Subscription) => {
        agg[subscription.uuid] = subscription;
        return agg;
      },
      { ...state.subscriptions }
    ),
  }),
});

const subscriptionsByShowUUID = httpReducer(FETCH_SUBSCRIPTIONS_BY_SHOWUUID, initialState, {
  success: (state, action) => ({
    ...state,
    subscriptionsByShowUUIDs: {
      ...state.subscriptionsByShowUUIDs,
      [action.data.showUUID]: action.payload.resp,
    },
  }),
});

export default reduceReducers(
  subscriptions,
  subscriptionForUser,
  subscriptionsByShowUUID,
  subscribe,
  updateSubscription,
  tip
);
