import { Switch, useHistory } from "react-router-dom";
import { ModalLauncher } from "src/components/lib/modal_launcher";
import { RCRoute } from "src/components/lib/private_route";
import {
  CAMPAIGN_COPY_MODAL,
  CAMPAIGN_EDITOR_MODAL,
  CAMPAIGN_PODCAST_SCHEDULE,
  CAMPAIGN_PODCAST_SELECTOR,
} from "src/components/modals/modal_root";
import CampaignListPage from "src/components/pages/campaigns/campaign_list_page/campaign_list_page";
import CampaignPage from "src/components/pages/campaigns/campaign_page";
import { permissionTypes } from "src/constants/permission_roles";
import UserRoles from "src/constants/roles";

const CampaignRoutes = () => {
  const history = useHistory();
  return (
    <Switch>
      {/* Campaign List Page */}
      <RCRoute
        roles={[UserRoles.Advertiser]}
        path={["/campaigns", "/campaigns/new/:pageNumber?", "/campaigns/copy/:campaignUUID"]}
        exact>
        {/* Create New Campaign Modal */}
        <RCRoute path="/campaigns/new/:pageNumber?">
          <ModalLauncher
            key={"campaign-new-modal"}
            modalType={CAMPAIGN_EDITOR_MODAL}
            modalProps={{ closeRoute: () => history.push("/campaigns") }}
          />
        </RCRoute>

        {/* Copy Campaign Modal */}
        <RCRoute path="/campaigns/copy/:campaignUUID" exact>
          <ModalLauncher
            key={"campaign-copy-modal"}
            modalType={CAMPAIGN_COPY_MODAL}
            modalProps={{ closeRoute: () => history.push("/campaigns") }}
          />
        </RCRoute>

        {/* Campaign List page does not need campaign permissions to be viewed, 
        possibility of no campaign object available (zero state) so no permissions available to 
        to reference  */}
        <RCRoute path={["/campaigns", "/campaigns/new", "/campaigns/copy/:campaignUUID"]} exact>
          <CampaignListPage />
        </RCRoute>
      </RCRoute>

      {/* Campaign Details Page */}
      <RCRoute
        path="/campaigns/:campaignUUID/:modifier?/:pageNumber?"
        paramKey="campaignUUID"
        permissionType={permissionTypes.viewCampaign}
        exact
        roles={[UserRoles.Advertiser]}>
        {/* Add Podcasts Modal */}
        <RCRoute path="/campaigns/:campaignUUID/select" exact roles={[UserRoles.Advertiser]}>
          <ModalLauncher key={"podcast-selector"} modalType={CAMPAIGN_PODCAST_SELECTOR} />
        </RCRoute>

        {/* Campaign Editing Modal */}
        <RCRoute
          path={["/campaigns/:campaignUUID/edit", "/campaigns/:campaignUUID/edit/:pageNumber"]}
          paramKey="campaignUUID"
          permissionType={permissionTypes.editCampaign}
          roles={[UserRoles.Advertiser]}
          exact>
          <ModalLauncher
            key={"campaign-edit-modal"}
            modalType={CAMPAIGN_EDITOR_MODAL}
            modalProps={{ isEditing: true }}
          />
        </RCRoute>

        {/* Campaign Scheduling Modal */}
        <RCRoute
          path="/campaigns/:campaignUUID/schedule"
          paramKey="campaignUUID"
          permissionType={permissionTypes.sendCampaign}
          roles={[UserRoles.Advertiser]}
          exact>
          <ModalLauncher key={"campaign-schedule-modal"} modalType={CAMPAIGN_PODCAST_SCHEDULE} />
        </RCRoute>

        <CampaignPage key="campaign-page" />
      </RCRoute>
    </Switch>
  );
};

export default CampaignRoutes;
