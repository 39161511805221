/*
 * this is copied from browse-promotions. it is a work in progress. it's merged because the BE
 * isn't ready yet. will finish when adding to campaigns becomes available
 * TODO: migrate classnames
 *  TODO: will migrate the browse promotinos search to use this
 * TODO: make the style more flexible

 * */

import { Table } from "antd";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import React, { Component } from "react";
import { Dropdown, Checkbox } from "antd";
import { Col, FormControl, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import RCButton from "src/components/lib/button";
import { AiOutlineFilter } from "react-icons/ai";
import {
  clearShows,
  getActiveCategories,
  searchDirectoryShows,
  searchShows,
} from "../../../action_managers/show_search";
import { searchNames } from "../../../constants/bucketed_audience_size";
import { primaryColor, primaryColor20 } from "../../../constants/css";
import { SHOW_SEARCH_NUM_RECOMMENDED_TO_SHOW } from "../../../constants/search";
import { itunesCategoriesMap } from "../../../data/itunes_categories";
import leftButtonIcon from "../../../icons/left_button.svg";
import closeButton from "../../../icons/modal-close-icon.svg";
import rightButtonIcon from "../../../icons/right_button.svg";
import { isDev, isStaging } from "../../../lib/config";
import { formatMoney } from "../../../lib/format-money";
import { getImageURL } from "../../../lib/get_image_url";
import { hummanizeNumber } from "../../../lib/numbers";
import Spinner from "../../lib/spinner";
import RedCircleSelect from "../redcircle-select";
import { CategoryFilter, SliderFilter } from "./filter_components";
import "./show_search.scss";

class ShowSearch extends Component {
  state = {
    searchValue: "",
    bucketedAudienceSize: undefined,
    categoryUUIDs: undefined,
    debouncing: false,
    showExtraFilters: false,
    isDefaultSearch: false,
    includeNSFW: false,
  };

  isAdSearch = () => {
    return this.props.additionalFilters?.showSearchType === "ads";
  };
  componentDidMount() {
    this.restoreToDefualt();
    if (this.isAdSearch()) {
      this.props.getActiveCategories();
    }
    if (this.props.hideColumnDropdowns) {
      this.setState({ showExtraFilters: true });
    }
  }

  updateSearchText = (e) => {
    this.setState({ searchValue: e.target.value });

    e.target.value ? this.searchShows() : this.debouncedSearch();
  };

  restoreToDefualt() {
    this.setState({ ...this.getDefaultFilters() });
    if (this.props.firstSearchFilters) {
      this.debouncedSearch();
    } else {
      this.props.clearShows();
    }
  }

  getDefaultFilters() {
    return { ...this.props.additionalFilters, ...this.props.firstSearchFilters };
  }

  formatRangeQueries = (stateKey, ESKey) => {
    if (!this.state[stateKey]) {
      return;
    }
    return ["min", "max"].reduce((agg, next, index) => {
      agg[next + ESKey] = this.state[stateKey][index];
      return agg;
    }, {});
  };

  getSearchTermsFromState = (state) => {
    let searchTerms = {
      ...(this.props.additionalFilters && { ...this.props.additionalFilters }),
      ...(state.searchValue && { query: state.searchValue }),
      ...(state.categoryUUIDs && { categoryUUIDs: state.categoryUUIDs }),
      ...(state.CPM && { minAverageCPM: state.CPM[0], maxAverageCPM: state.CPM[1] }),
      ...(state.weeklyDownloads && {
        minEstimatedWeeklyDownloads: state.weeklyDownloads[0],
        maxEstimatedWeeklyDownloads: state.weeklyDownloads[1],
      }),
      ...this.formatRangeQueries("spotRate", "SpotRate"),
      ...(!(isDev() || isStaging()) && { userUUID: this.props.user.uuid }),
      ...(state.episodeDownloads && {
        minAverageEpisodeDownloads: state.episodeDownloads[0],
        maxAverageEpisodeDownloads: state.episodeDownloads[1],
      }),
      ...(state.includeNSFW && { includeNSFW: true }),
    };

    this.props.extraFilterOptions &&
      this.props.extraFilterOptions.forEach(({ name, searchMapper }) => {
        if (state[name]) {
          searchTerms = {
            ...searchTerms,
            ...(searchMapper ? searchMapper(state[name]) : { [name]: state[name] }),
          };
        }
      });
    // Two cases.
    // 1) All is selected, only search shows of similar size. Defined as shows of same size and one category up/below.
    // 2) Specific size is selected then search `bucketedAudienceSize` to specify single size
    if (!state.bucketedAudienceSize) {
      this.props.useDirectoryShows &&
        this.props.searchDirectoryShows({
          ...(state.searchValue && { query: state.searchValue }),
          ...(state.categoryUUIDs && { categoryUUIDs: [state.categoryUUIDs] }),
        });
      searchTerms.bucketedAudienceSizes = this.props.bucketedAudienceSizes;
    } else {
      searchTerms.bucketedAudienceSize = state.bucketedAudienceSize;
    }
    return searchTerms;
  };

  debouncedSearch = debounce(() => {
    this.setState({ debouncing: false });
    let searchTerms = this.getSearchTermsFromState(this.state);
    this.setState({
      isDefaultSearch: isEqual(searchTerms, this.getSearchTermsFromState(this.getDefaultFilters())),
    });
    this.props.searchShows(searchTerms);
  }, 500);

  searchShows = () => {
    this.setState({ debouncing: true });
    this.debouncedSearch();
  };

  getFilterDropdown = (setting) => {
    if (this.props.hideColumnDropdowns) {
      return undefined;
    }
    if (typeof setting !== "string" && !setting.dropdown) {
      return setting;
    }
    const dropdowns = {
      categoryUUIDs: ({ confirm }) => (
        <div className={"p-vxs p-lxs"} style={{ minWidth: 250 }}>
          <CategoryFilter
            checkable
            categories={this.getCategories()}
            onCheck={this.handleDetailSelect(setting)}
            close={confirm}
            height={250}
            checkedKeys={this.state[setting]}
            selectable={false}
          />
        </div>
      ),
      weeklyDownloads: ({ confirm }) =>
        this.sliderFilter(setting, { close: confirm, min: 450, max: 1000000 }),
      CPM: ({ confirm }) =>
        this.sliderFilter(setting, {
          close: confirm,
          min: 400,
          max: 5500,
          formatNumber: formatMoney,
        }),
      episodeDownloads: ({ confirm }) =>
        this.sliderFilter(setting, { close: confirm, min: 500, max: 200000 }),
      spotRate: ({ confirm }) =>
        this.sliderFilter(setting, {
          close: confirm,
          min: 500,
          max: 400000,
          formatNumber: formatMoney,
        }),
    };

    return dropdowns[setting] || null;
  };

  sliderFilter = (key, props) => (
    <SliderFilter values={this.state[key]} onChange={this.handleDetailSelect(key)} {...props} />
  );

  renderSearchResult = () => {
    if (this.props.renderer) {
      return this.props.renderer(this.getResults());
    }
    if (this.props.columns) {
      const columns = this.props.columns.map(({ filterDropdown, ...column }) => {
        return {
          ...column,
          ...(!this.props.hideColumnDropdowns &&
            filterDropdown && {
              filterDropdown: this.getFilterDropdown(filterDropdown),
            }),
          title: column.titleMapper ? column.titleMapper(this.state) : column.title,
        };
      });
      return (
        <Table
          rowKey={"uuid"}
          loading={this.props.isLoading}
          dataSource={this.getResults()}
          columns={columns}
          rowClassName={"black-text"}
          pagination={false}
          {...this.props.tableProps}
        />
      );
    }
    if (!this.getResults().length || this.props.isLoading) {
      return <Spinner isLoading={this.props.isLoading} />;
    }

    return this.getResults().map((show) => {
      const content = this.props.renderSearchRow
        ? this.props.renderSearchRow(show)
        : DefaultSearchResultRow(show);

      if (this.props.linkTo && this.props.linkTo(show)) {
        var linkTo = show.isDirectoryShow
          ? `/promotions/directory-shows/${show.uuid}`
          : `/promotions/shows/${show.uuid}`;
        return (
          <Link to={linkTo} className="no-style-link" key={show.uuid}>
            <div className={"show-search__search-result"}>{content}</div>
          </Link>
        );
      }

      return (
        <div key={show.uuid} className={"show-search__search-result"}>
          {content}
        </div>
      );
    });
  };

  handleDetailSelect = (filterKey) => (e) => {
    let value;
    if (Array.isArray(e)) {
      value = e;
    } else if (Array.isArray(e?.checked) || Array.isArray(e?.halfChecked)) {
      value = []
        .concat(Array.isArray(e?.checked) && e.checked)
        .concat(Array.isArray(e?.halfChecked) && e.halfChecked);
    } else {
      value = e?.value === "all" || e === null ? undefined : e?.value;
    }

    this.setState({ [filterKey]: value });
    this.searchShows();
  };

  getCategories = () => {
    return this.props.activeAdCategories ?? [];
  };

  detailedSearch = () => {
    if (!this.state.showExtraFilters) {
      return null;
    }
    let audienceSizes = searchNames;
    if (this.props.bucktedAudienceSizes) {
      audienceSizes = filter(audienceSizes, (o) => {
        return this.props.bucketedAudienceSizes.includes(o.value);
      });
    }

    const filters = [...(this.props.extraFilterOptions ?? [])];
    if (!this.props.hideFilters) {
      filters.unshift({
        name: "bucketedAudienceSize",
        placeholder: "Weekly Downloads",
        options: [{ name: "All Valid Weekly Downloads", value: "all" }, ...audienceSizes].map(
          ({ name, value }) => ({ value, label: name })
        ),
      });
    }

    return (
      <Row className="detailed-search-row flex-row-container p-hxs">
        {filters.map(({ options, name, placeholder, multiSelect }) => {
          return (
            <RedCircleSelect
              isMulti={multiSelect}
              key={name}
              placeholder={placeholder}
              className={"flex-1"}
              options={options}
              onChange={this.handleDetailSelect(name)}
            />
          );
        })}
      </Row>
    );
  };
  getResults = () => {
    let search = orderBy(this.props.search, ["estimatedWeeklyDownloads"], ["desc"]);
    if (this.props.recommendedShows) {
      const recommendedUUIDs = new Set(this.props.recommendedShows.map(({ uuid }) => uuid));
      if (this.state.isDefaultSearch) {
        // concat up a list of:
        search = this.props.recommendedShows
          // 1. the top 15 recommended shows
          .slice(0, SHOW_SEARCH_NUM_RECOMMENDED_TO_SHOW)
          // 2. and the union of the 16th+ recommended show and all the search
          //    results, sorted by estimatedWeeklyDownloads
          .concat(
            orderBy(
              this.props.recommendedShows.slice(SHOW_SEARCH_NUM_RECOMMENDED_TO_SHOW).concat(search),
              ["estimatedWeeklyDownloads"],
              ["desc"]
            )
          );

        // save the current order, we'll want to restore this later (de-duping
        // will mess it up)
        search = search.map((show, i) => ({ ...show, rank: i }));

        // de-dupe
        search = uniqBy(search, "uuid");

        // restore the sort order
        search = orderBy(search, ["rank"], ["asc"]);
      }
      // if a show met the demo filtering, mark it as "recommended" so it can
      // be labeled as such
      search = search.map((show) =>
        recommendedUUIDs.has(show.uuid) ? { ...show, recommended: true } : show
      );
    }
    return this.props.useDirectoryShows
      ? search.concat(this.props.directoryShowsSearchResults)
      : search;
  };

  renderFilterTags = () => {
    let filtersTagsText = [];
    if (this.state.categoryUUIDs) {
      const childCategories = this.state.categoryUUIDs.map((uuid) => itunesCategoriesMap[uuid]);
      const lists = groupBy(childCategories, "group_uuid");
      const parentWithNoChild = groupBy(
        lists?.undefined?.filter((category) => !lists[category.uuid]),
        "uuid"
      );
      const categoryTags = Object.entries({ ...lists, ...parentWithNoChild })
        .filter(([key]) => key !== "undefined")
        .map(([parentUUID, items]) => ({
          display: `Category: [${itunesCategoriesMap[parentUUID]?.name}] ${items
            .map((item) => item.name)
            .join(", ")}`,
          onClick: () => {
            const newCategoryUUIDs = this.state.categoryUUIDs.filter((uuid) => {
              return uuid !== parentUUID && itunesCategoriesMap[uuid]?.group_uuid !== parentUUID;
            });
            this.handleDetailSelect("categoryUUIDs")(newCategoryUUIDs);
          },
        }));

      filtersTagsText = filtersTagsText.concat(categoryTags);
    }
    const additionalTagsTexts = [
      { key: "weeklyDownloads", display: "Weekly Downloads", formatNumber: hummanizeNumber },
      { key: "CPM", display: "CPM", formatNumber: formatMoney },
      { key: "episodeDownloads", display: "Episode Downloads", formatNumber: hummanizeNumber },
      { key: "spotRate", display: "Spot Rate", formatNumber: formatMoney },
    ]
      .filter(({ key }) => this.state[key])
      .map(({ key, display, formatNumber }) => ({
        onClick: () => {
          this.handleDetailSelect(key)(undefined);
        },
        display: `${display}: ${this.state[key].map(formatNumber).join(" - ")}`,
      }));

    filtersTagsText = filtersTagsText.concat(additionalTagsTexts);
    const getButton = ({ scrollOffset, position, iconSrc }) => {
      return (
        <div
          style={{
            position: "absolute",
            [position]: 0,
            top: 0,
            height: 32,
          }}
          className={"pointer"}
          onClick={() => {
            if (this.filterRef) {
              this.filterRef.scrollLeft += scrollOffset;
            }
          }}>
          <img src={iconSrc} alt="" style={{ position: "relative", top: -7 }} />
        </div>
      );
    };
    return (
      <div className={"position-relative"}>
        <div
          className={"flex-row-container align-center show-search-filter-tag-container"}
          ref={(ref) => (this.filterRef = ref)}>
          {filtersTagsText.map(({ display, onClick }) => (
            <div
              key={display}
              className={"p-hxs p-vxxxs m-rxxs rounded-corners-large"}
              style={{
                fontSize: 13,
                background: primaryColor20,
                border: `1px solid ${primaryColor}`,
                whiteSpace: "nowrap",
              }}>
              <span>{display}</span>
              <img
                style={{ height: 18, cursor: "pointer", marginLeft: 4 }}
                src={closeButton}
                onClick={onClick}
                alt="close"
              />
            </div>
          ))}
        </div>
        {getButton({ position: "left", scrollOffset: -30, iconSrc: leftButtonIcon })}
        {getButton({ position: "right", scrollOffset: 30, iconSrc: rightButtonIcon })}
      </div>
    );
  };

  renderFilterMenu() {
    return [
      {
        key: "includeNSFW",
        label: (
          <RCButton
            type="link"
            className="p-a0"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ includeNSFW: !this.state.includeNSFW });
              this.searchShows();
            }}>
            <Checkbox checked={this.state.includeNSFW} className="m-rxxs" />
            Include NSFW Content
          </RCButton>
        ),
      },
    ];
  }

  render() {
    return (
      <CSSTransition in={this.props.show} classNames={"browse-search"} timeout={500} unmountOnExit>
        <Grid fluid>
          <Row className="m-bxs">
            <Col xs={12} className="flex-row-container align-center">
              <FormControl
                type="text"
                onChange={this.updateSearchText}
                value={this.state.searchValue}
                className="browse-promotion-search__search-box m-b0"
                placeholder="Search"
              />
              <Dropdown menu={{ items: this.renderFilterMenu() }} trigger={["click"]}>
                <RCButton type="link" size="large">
                  <AiOutlineFilter />
                </RCButton>
              </Dropdown>
            </Col>
          </Row>
          {this.detailedSearch()}
          {this.renderFilterTags()}
          {this.renderSearchResult()}
          <If
            condition={
              !this.props.search.length &&
              !!this.state.searchValue &&
              !this.props.isLoading &&
              !this.state.debouncing
            }>
            <div className="text-center browse-promotion-search__no-results">
              We couldn
              {"'"}t find any podcasts that match your search.
            </div>
          </If>
          <If
            condition={
              !this.props.search.length &&
              !this.state.searchValue &&
              !this.props.isLoading &&
              !this.state.debouncing
            }>
            <div className="text-center browse-promotion-search__no-results">
              Start your search for a partner podcast.
            </div>
          </If>
        </Grid>
      </CSSTransition>
    );
  }
}

export const DefaultShowSearchImage = (show) => {
  return (
    <div
      className="show-search__search-picture"
      style={{
        backgroundImage: "url(" + getImageURL(show.imageURL, "64x64") + ")",
      }}
    />
  );
};

export const DefaultSearchResultRow = (show) => {
  return (
    <div className={"browse-promotion-search__search-result"}>
      {DefaultShowSearchImage(show)}
      <span className="browse-promotion-search__title">{show.title}</span>
      <span className="browse-promotion-search__spacer" />
      <span className="browse-promotions-search__arrow glyphicon glyphicon-chevron-right" />
    </div>
  );
};

export default connect(
  (state) => {
    return {
      search: state.browsePromotion.search.results,
      directoryShowsSearchResults: state.browsePromotion.searchDirectoryShows.results,
      isLoading: state.browsePromotion.search.isLoading,
      user: state.user.user,
      activeAdCategories: state.browsePromotion.activeAdCategories.categories,
    };
  },
  {
    getActiveCategories,
    searchShows,
    searchDirectoryShows,
    clearShows,
  }
)(ShowSearch);
