import React, { SVGAttributes } from "react";
import backChevron from "../../icons/back-chevron.svg";
import backPlay from "../../icons/back.svg";
import calendar from "../../icons/calendar-icon.svg";
import check from "../../icons/check.svg";
import down from "../../icons/down-chevron.svg";
import downTriangle from "../../icons/down-triangle.svg";
import ellipses from "../../icons/ellipses-icon.svg";
import filter from "../../icons/filter.svg";
import forward from "../../icons/forward-chevron.svg";
import forwardPlay from "../../icons/forward.svg";
import leftTriangle from "../../icons/left-triangle.svg";
import lightbulb from "../../icons/lightbulb.svg";
import loadingButton from "../../icons/loading-button.svg";
import loading from "../../icons/loading-icon.svg";
import loadingSpinner from "../../icons/loading-spinner-red.svg";
import loadingSpinnerWhite from "../../icons/loading-spinner-white.svg";
import modalClose from "../../icons/modal-close-icon.svg";
import pan from "../../icons/pan.svg";
import pause from "../../icons/pause.svg";
import playBlack from "../../icons/play-black.svg";
import play from "../../icons/play.svg";
import rightTriangls from "../../icons/right-triangle.svg";
import search from "../../icons/search.svg";
import upChevron from "../../icons/up-chevron.svg";
import upTriangle from "../../icons/up-triangle.svg";
import uploadLarge from "../../icons/upload-icon-large.svg";
import uploadWhite from "../../icons/upload-icon-white.svg";
import upload from "../../icons/upload-icon.svg";
import warning from "../../icons/warning.svg";
import close from "../../icons/x.png";
import zoomIn from "../../icons/zoom-in.svg";
import zoomOut from "../../icons/zoom-out.svg";

const settings = {
  backPlay,
  forwardPlay,
  pan,
  pause,
  play,
  playBlack,
  zoomIn,
  zoomOut,
  backChevron,
  calendar,
  check,
  down,
  downTriangle,
  ellipses,
  filter,
  forward,
  leftTriangle,
  loadingButton,
  loading,
  loadingSpinner,
  loadingSpinnerWhite,
  modalClose,
  rightTriangls,
  search,
  upChevron,
  upTriangle,
  uploadLarge,
  uploadWhite,
  upload,
  warning,
  close,
  lightbulb,
};

interface IIcon extends React.ImgHTMLAttributes<HTMLImageElement> {
  iconName: keyof typeof settings;
  name?: string;
  disabled?: boolean;
}

export const Icon: (props: IIcon) => JSX.IntrinsicElements["img"] | null = (props) => {
  const { iconName, name, disabled = false, ...otherProps } = props;

  if (!settings[iconName]) {
    return null;
  }
  return (
    <img
      src={settings[iconName]}
      alt={name || iconName}
      style={{ padding: 3, cursor: disabled ? "auto" : "pointer" }}
      {...otherProps}
    />
  );
};

// Add new Icon names here as well for name type checking for svgIcon
const SVGIconAllowedNames = [
  "disable-mouse",
  "edit",
  "lightbulb",
  "money-tag",
  "info",
  "newTag",
  "question",
  "reportDownload",
] as const;

interface IsvgIcon extends SVGAttributes<SVGElement> {
  name: typeof SVGIconAllowedNames[number];
}

export const svgIcon = React.forwardRef<SVGSVGElement, IsvgIcon>(({ name, ...rest }, ref) => {
  const getViewBox: (name: IsvgIcon["name"]) => string = (name) => {
    switch (name) {
      case "disable-mouse":
        return "0 0 24 24";
      case "edit":
      case "money-tag":
        return "0 0 16 16";
      case "info":
        return "0 0 10 10";
      case "lightbulb":
        return "0 0 32 32";
      case "newTag":
        return "0 0 33 14";
      case "question":
        return "0 0 20 20";
      case "reportDownload":
        return "0 0 20 20";
      default:
        return "0 0 16 16";
    }
  };

  const getPath: (name: IsvgIcon["name"]) => React.ReactFragment | JSX.IntrinsicElements["path"] = (
    name
  ) => {
    switch (name) {
      case "disable-mouse":
        return (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22ZM7.094 18.32L18.32 7.094C19.5165 8.63317 20.1098 10.5563 19.9885 12.5021C19.8671 14.4478 19.0394 16.2823 17.6608 17.6608C16.2823 19.0394 14.4478 19.8671 12.5021 19.9885C10.5563 20.1098 8.63317 19.5165 7.094 18.32ZM5.68 16.906C4.48347 15.3668 3.89016 13.4437 4.01154 11.4979C4.13291 9.55218 4.96062 7.7177 6.33916 6.33916C7.7177 4.96062 9.55218 4.13291 11.4979 4.01154C13.4437 3.89016 15.3668 4.48347 16.906 5.68L5.68 16.906Z"
            fill="#979797"
          />
        );
      case "edit":
        return (
          <path d="M3.5 10.6263V12.5013H5.375L10.905 6.97125L9.03 5.09625L3.5 10.6263ZM12.355 5.52125C12.55 5.32625 12.55 5.01125 12.355 4.81625L11.185 3.64625C10.99 3.45125 10.675 3.45125 10.48 3.64625L9.565 4.56125L11.44 6.43625L12.355 5.52125Z" />
        );
      case "info":
        return (
          <path d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5.5 7.5H4.5V4.5H5.5V7.5ZM5.5 3.5H4.5V2.5H5.5V3.5Z" />
        );
      case "lightbulb":
        return (
          <>
            <path
              d="M16 2C13.4068 2 10.9198 3.03016 9.08609 4.86385C7.2524 6.69753 6.22224 9.18455 6.22224 11.7778C6.20675 13.1989 6.51052 14.6053 7.11113 15.8933C7.40871 16.4415 7.74125 16.97 8.10669 17.4756C8.81875 18.4301 9.41548 19.4654 9.88447 20.56C10.2072 21.7186 10.3834 22.9131 10.4089 24.1156H12.1867C12.1594 22.7043 11.9472 21.3027 11.5556 19.9467C11.0425 18.709 10.3798 17.5388 9.58224 16.4622C9.25964 16.0236 8.96285 15.5665 8.69335 15.0933C8.21577 14.0481 7.97883 12.909 8.00002 11.76C8.00002 9.63002 8.84615 7.58727 10.3523 6.08115C11.8584 4.57502 13.9012 3.72889 16.0311 3.72889C18.1611 3.72889 20.2039 4.57502 21.71 6.08115C23.2161 7.58727 24.0622 9.63002 24.0622 11.76C24.0639 12.9204 23.8025 14.0662 23.2978 15.1111C23.0283 15.5843 22.7315 16.0413 22.4089 16.48C21.6157 17.5515 20.956 18.7156 20.4445 19.9467C20.0513 21.3085 19.8391 22.7161 19.8134 24.1333H21.5911C21.6167 22.9309 21.7929 21.7364 22.1156 20.5778C22.5846 19.4832 23.1813 18.4478 23.8934 17.4933C24.2602 16.9764 24.5928 16.436 24.8889 15.8756C25.4895 14.5875 25.7933 13.1811 25.7778 11.76C25.7731 9.16985 24.7409 6.6874 22.9077 4.85756C21.0745 3.02771 18.5902 2 16 2Z"
              fill="black"
            />
            <path
              d="M21.2089 25.9102H10.7645C10.5759 25.9102 10.395 25.9851 10.2616 26.1184C10.1283 26.2518 10.0533 26.4327 10.0533 26.6213C10.0533 26.8099 10.1283 26.9907 10.2616 27.1241C10.395 27.2575 10.5759 27.3324 10.7645 27.3324H21.2089C21.3975 27.3324 21.5784 27.2575 21.7117 27.1241C21.8451 26.9907 21.92 26.8099 21.92 26.6213C21.92 26.4327 21.8451 26.2518 21.7117 26.1184C21.5784 25.9851 21.3975 25.9102 21.2089 25.9102Z"
              fill="black"
            />
            <path
              d="M19.5556 28.5781H12.4445C12.2559 28.5781 12.075 28.653 11.9417 28.7864C11.8083 28.9198 11.7334 29.1006 11.7334 29.2892C11.7334 29.4778 11.8083 29.6587 11.9417 29.7921C12.075 29.9254 12.2559 30.0003 12.4445 30.0003H19.5556C19.7442 30.0003 19.9251 29.9254 20.0585 29.7921C20.1918 29.6587 20.2667 29.4778 20.2667 29.2892C20.2667 29.1006 20.1918 28.9198 20.0585 28.7864C19.9251 28.653 19.7442 28.5781 19.5556 28.5781Z"
              fill="black"
            />
            <path
              d="M15.3956 9.67915L12.9689 12.1058C12.8464 12.2411 12.7805 12.4183 12.785 12.6008C12.7895 12.7833 12.864 12.957 12.9931 13.0861C13.1221 13.2152 13.2959 13.2897 13.4784 13.2942C13.6609 13.2987 13.8381 13.2328 13.9734 13.1103L16.4 10.6658C16.4714 10.6011 16.5289 10.5226 16.5691 10.435C16.6092 10.3475 16.6311 10.2527 16.6335 10.1563C16.6358 10.06 16.6186 9.96427 16.5828 9.87482C16.5471 9.78538 16.4935 9.70413 16.4254 9.63601C16.3573 9.5679 16.276 9.51433 16.1866 9.47856C16.0971 9.4428 16.0014 9.42558 15.9051 9.42795C15.8088 9.43032 15.7139 9.45223 15.6264 9.49236C15.5388 9.53248 15.4603 9.58998 15.3956 9.66137V9.67915Z"
              fill="black"
            />
          </>
        );
      case "money-tag":
        return (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.968 8.83129L7.15618 1.00444C6.46246 0.310721 5.3122 0.335095 4.58754 1.06163L4.50411 1.14506L4.47223 1.11319C3.6707 0.310721 2.33764 0.343532 1.49486 1.18725L1.25581 1.42724C0.413031 2.27001 0.381158 3.60495 1.18269 4.40742L1.2155 4.44023L1.06082 4.59585C0.33616 5.32051 0.309911 6.47264 1.00457 7.16824L8.81642 14.9951C9.51014 15.6897 10.6623 15.6644 11.3851 14.9388L14.9127 11.4046C15.6383 10.6781 15.6627 9.52689 14.968 8.83129ZM3.6932 5.33832C2.88136 5.33832 2.2242 4.65022 2.22233 3.79995C2.22233 2.95061 2.88136 2.26251 3.6932 2.26251C4.50504 2.26251 5.15939 2.95154 5.1622 3.79995C5.15939 4.65022 4.50317 5.33832 3.6932 5.33832ZM12.1313 12.7527L11.1151 11.7355C10.5038 12.1696 9.73794 12.3065 9.06766 12.0749C8.99123 12.0493 8.92081 12.0085 8.86069 11.9548C8.80058 11.9011 8.75202 11.8358 8.71799 11.7627C8.6823 11.6876 8.66224 11.6059 8.65901 11.5228C8.65579 11.4396 8.66948 11.3567 8.69924 11.279C8.75708 11.1236 8.87294 10.9967 9.02243 10.9251C9.17193 10.8534 9.3434 10.8425 9.50077 10.8946C9.72388 10.9715 9.97887 10.9387 10.2142 10.8309L9.03954 9.65157C8.14614 10.2909 6.95931 10.2722 6.23466 9.54939C5.50531 8.82098 5.48938 7.63041 6.13716 6.73888L5.14158 5.74236L5.73124 5.1527L6.73339 6.1511C7.35023 5.71893 8.11708 5.58206 8.7883 5.81455C8.86566 5.84147 8.9368 5.88371 8.99747 5.93874C9.05814 5.99378 9.10709 6.06048 9.14139 6.13487C9.1757 6.20925 9.19465 6.28979 9.19712 6.37166C9.1996 6.45354 9.18554 6.53507 9.15578 6.61139C9.09641 6.766 8.98 6.89195 8.83053 6.96328C8.68106 7.03462 8.50992 7.0459 8.35238 6.99481C8.12926 6.917 7.87615 6.94887 7.64178 7.05387L8.82486 8.23226C9.71263 7.60885 10.8854 7.63416 11.6044 8.35225C12.3235 9.07128 12.3516 10.2394 11.7291 11.1224L12.7416 12.1312L12.1313 12.7527Z"
          />
        );
      case "question":
        return (
          <path
            d="M9.99935 0.667969C4.84518 0.667969 0.666016 4.84714 0.666016 10.0013C0.666016 15.1555 4.84518 19.3346 9.99935 19.3346C15.1535 19.3346 19.3327 15.1555 19.3327 10.0013C19.3327 4.84714 15.1535 0.667969 9.99935 0.667969ZM9.99935 15.418C9.53893 15.418 9.16601 15.045 9.16601 14.5846C9.16601 14.1242 9.53893 13.7513 9.99935 13.7513C10.4598 13.7513 10.8327 14.1242 10.8327 14.5846C10.8327 15.045 10.4598 15.418 9.99935 15.418ZM11.3098 10.8451C11.1211 10.9179 10.9588 11.0459 10.8439 11.2124C10.7291 11.3789 10.6671 11.5761 10.666 11.7784V12.2513C10.666 12.343 10.591 12.418 10.4993 12.418H9.49935C9.40768 12.418 9.33268 12.343 9.33268 12.2513V11.8034C9.33268 11.3221 9.47226 10.8471 9.74726 10.4513C10.016 10.0638 10.391 9.76797 10.8327 9.59922C11.541 9.3263 11.9993 8.73255 11.9993 8.08463C11.9993 7.16588 11.1014 6.41797 9.99935 6.41797C8.89726 6.41797 7.99935 7.16588 7.99935 8.08463V8.24297C7.99935 8.33463 7.92435 8.40963 7.83268 8.40963H6.83268C6.74101 8.40963 6.66601 8.33463 6.66601 8.24297V8.08463C6.66601 7.26588 7.02435 6.5013 7.67435 5.93255C8.29935 5.38463 9.12435 5.08463 9.99935 5.08463C10.8743 5.08463 11.6993 5.38672 12.3243 5.93255C12.9743 6.5013 13.3327 7.26588 13.3327 8.08463C13.3327 9.2888 12.5389 10.3721 11.3098 10.8451Z"
            fill="#C6C6C6"
          />
        );
      case "reportDownload":
        return (
          <path
            d="M10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0ZM10 17.8261C5.67717 17.8261 2.17392 14.3217 2.17392 10C2.17392 5.67827 5.67717 2.17392 10 2.17392C14.3228 2.17392 17.8261 5.67827 17.8261 10C17.8261 14.3217 14.3228 17.8261 10 17.8261ZM11.9565 5.81522H8.04347V10.2609H5.59783L10 14.4022L14.4022 10.2609H11.9565L11.9565 5.81522Z"
            fill="#577D9E"
          />
        );
      case "newTag":
        return (
          <>
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H29C31.2091 0 33 1.79086 33 4V10C33 12.2091 31.2091 14 29 14H4C1.79086 14 0 12.2091 0 10V4Z"
              fill="#EA404D"
            />
            <path
              d="M9.68109 4.4V7.816L7.28109 4.4H6.44109V10H7.54509V6.576L9.94509 10H10.7851V4.4H9.68109ZM13.7279 8.944V7.688H15.8479V6.648H13.7279V5.456H16.0479V4.4H12.6239V10H16.0879V8.944H13.7279ZM18.8341 10H20.0901L21.1381 6.304L22.1941 10H23.4501L25.0181 4.4H23.8581L22.7861 8.552L21.6101 4.4H20.6661L19.4981 8.552L18.4261 4.4H17.2661L18.8341 10ZM26.6023 8.12H27.4183L27.6263 6V4.4H26.3863V6L26.6023 8.12ZM27.0103 10.104C27.3863 10.104 27.6903 9.8 27.6903 9.424C27.6903 9.048 27.3863 8.744 27.0103 8.744C26.6343 8.744 26.3303 9.048 26.3303 9.424C26.3303 9.8 26.6343 10.104 27.0103 10.104Z"
              fill="white"
            />
          </>
        );
      default:
        return (
          <path d="M10 20c-5.523 0-10-4.477-10-10s4.477-10 10-10v0c5.523 0 10 4.477 10 10s-4.477 10-10 10v0zM12 7c0 0.28-0.21 0.8-0.42 1l-1.58 1.58c-0.57 0.58-1 1.6-1 2.42v1h2v-1c0-0.29 0.21-0.8 0.42-1l1.58-1.58c0.57-0.58 1-1.6 1-2.42 0-2.209-1.791-4-4-4s-4 1.791-4 4v0h2c0-1.105 0.895-2 2-2s2 0.895 2 2v0zM9 15v2h2v-2h-2z" />
        );
    }
  };

  return (
    <svg
      ref={ref}
      viewBox={getViewBox(name)}
      {...rest}
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg">
      {getPath(name)}
    </svg>
  );
});
