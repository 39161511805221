import { useReducer } from "react";
import { User } from "src/reducers/user";

export const useCredentialLoginFlow = () => {
  const initialState: {
    isLoading: boolean;
    user?: User;
    start: boolean;
    error: boolean;
    success: boolean;
    userInitAccept: boolean;
  } = {
    isLoading: false,
    user: undefined,
    start: false,
    error: false,
    success: false,
    userInitAccept: false,
  };

  const CredentialLoginFlowReducer = (
    state: typeof initialState,
    action: { type: "start" | "error" | "success" | "pre-start"; payload?: User }
  ) => {
    switch (action.type) {
      case "pre-start":
        return { ...state, userInitAccept: true };
      case "start":
        return { ...state, start: true, isLoading: true };
      case "error":
        return { ...state, isLoading: false, error: true };
      case "success":
        return { ...state, isLoading: false, success: true, user: action?.payload };
      default:
        return { ...state };
    }
  };
  const [credState, credDispatch] = useReducer(CredentialLoginFlowReducer, initialState);

  return [credState, credDispatch] as const;
};
