import { Col, Row } from "antd";
import React from "react";
import HighlightBox from "src/components/lib/highlight_box";
import { getCampaignItemPaidAmount, getCampaignItemTotalAmount } from "src/lib/campaigns";
import { formatMoney } from "src/lib/format-money";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { getCampaignProgress } from "../../campaigns/campaign_page/campaign_progress";

export default function ShowCampaignMetadata({
  campaign,
  campaignItem,
  className,
  isLoading,
}: {
  campaign: ICampaign;
  campaignItem: ICampaignItem;
  className?: string;
  isLoading: boolean;
}) {
  const totalAmount = getCampaignItemTotalAmount(campaignItem);
  const totalPaid = getCampaignItemPaidAmount(campaignItem);
  const progress = `${getCampaignProgress(campaignItem).percent.toFixed(2)}%`;

  return (
    <Row gutter={[16, 16]} className={className}>
      <Col xs={12} md={8}>
        <HighlightBox
          className="bordered"
          title="total amount"
          value={formatMoney(totalAmount)}
          isLoading={isLoading || !campaignItem}
        />
      </Col>
      <Col xs={12} md={8}>
        <HighlightBox
          className="bordered"
          title="total paid"
          value={formatMoney(totalPaid)}
          isLoading={isLoading || !campaignItem}
        />
      </Col>
      <Col xs={12} md={8}>
        <HighlightBox
          className="bordered"
          title="progress"
          value={progress}
          isLoading={isLoading || !campaignItem}
        />
      </Col>
    </Row>
  );
}
