import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Checkbox, Input, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { classNames } from "react-extras";
import { Modal } from "redcircle-ui";
import { getMarkersByShowUUID } from "src/action_managers/markers";
import { getEpisodeByShow, getShowForUser } from "src/action_managers/shows";
import { AlbumArt } from "src/components/lib/album_art";
import Button from "src/components/lib/button";
import { TriCheckbox } from "src/components/lib/checkbox";
import EmptyStateBlock from "src/components/lib/empty_state_block";
import Loading from "src/components/lib/loading";
import SelectDropdown from "src/components/lib/select_dropdown";
import RCTable from "src/components/lib/tables/rc_table";
import { permissionTypes } from "src/constants/permission_roles";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { useCanAccessBound } from "src/lib/permissions";
import { AudioBlock } from "src/reducers/audio_blocks";
import { IEpisode } from "src/reducers/episode_by_show";
import { Marker } from "src/reducers/markers";
import styles from "./audio_blocks.module.scss";
import {
  ASSIGNMENT_OPTIONS,
  getCellCheckedState,
  TEnhancedEpisode,
  transformMarkersByEpisodeUUID,
} from "./bulk_assign_helpers";

interface IProps {
  audioBlock: AudioBlock;
  onSubmit: any;
}

export default function BulkAssignForm({ audioBlock, onSubmit }: IProps) {
  const canAccess = useCanAccessBound();
  const dispatch = useReduxDispatch();

  // SHOWS
  const { shows } = useSelectorTS((state) => state.shows);
  const showList = Object.keys(shows)
    .filter((showUUID) => shows[showUUID].isVisible)
    .filter((showUUID) => canAccess(permissionTypes.bulkAssignAudioBlock, showUUID))
    .map((showUUID) => ({ title: shows[showUUID]?.title, uuid: showUUID }))
    .sort((a, b) => a.title.localeCompare(b.title));

  // EPISODES
  const [selectedShowUUID, setSelectedShowUUID] = useState<string>();
  const show = selectedShowUUID && shows[selectedShowUUID];

  const { isLoading: isEpisodesLoading, ...episodesByShow } = useSelectorTS(
    (state) => state.episodesByShow
  );
  const { markers, isLoading: isMarkersLoading } = useSelectorTS((state) => state.markers);
  const episodes = selectedShowUUID && episodesByShow[selectedShowUUID]?.episodes;
  const markersByEpisodeUUID = useMemo(() => transformMarkersByEpisodeUUID(markers), [markers]);

  // UPDATES
  const [markersToUpdate, setMarkersToUpdate] = useState<Record<string, boolean>>({});
  const markersAdded = Object.values(markersToUpdate).filter((m) => m === true).length;
  const markersRemoved = Object.values(markersToUpdate).filter((m) => m === false).length;

  // UI
  const [showFilterDrawer, setFilterDrawer] = useState(false);
  const [filterTerm, setFilterTerm] = useState("");
  const [filters, setFilters] = useState<Record<string, boolean>>({
    PREROLL: true,
    MIDROLL: true,
    POSTROLL: true,
  });
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!shows) dispatch(getShowForUser());
  }, []);

  const handleSelectShow = (showUUID: string) => {
    setSelectedShowUUID(showUUID);
    if (!episodesByShow[showUUID]) dispatch(getEpisodeByShow(showUUID));
    if (!markers?.markerUUIDsByShow?.[showUUID]) dispatch(getMarkersByShowUUID(showUUID));
  };

  const handleSelectBulkOption = (value: string, rollType: string) => {
    const toMark = ASSIGNMENT_OPTIONS[value](rollType, data);
    updateMarkers(toMark);
  };

  const handleSelectCheckbox = (markerUUID: string) => {
    updateMarkers({ [markerUUID]: undefined });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit(markersToUpdate).then(() => {
      setMarkersToUpdate({});
      setSubmitting(false);
    });
  };

  // bulk assign markers given { markerUUID: newState }
  const updateMarkers = (markersToAssign: Record<string, boolean | undefined>) => {
    const newMarkersToUpdate = { ...markersToUpdate };
    Object.keys(markersToAssign).forEach((markerUUID: string) => {
      const marker = markers[markerUUID];
      const targetState = markersToAssign[markerUUID as keyof typeof markersToAssign];
      const currentState = getCellCheckedState(marker, markersToUpdate, audioBlock.uuid);

      // checking // unchecking
      if (targetState == true && currentState !== "checked") {
        if (newMarkersToUpdate[markerUUID] === false) {
          delete newMarkersToUpdate[markerUUID];
        } else {
          newMarkersToUpdate[markerUUID] = true;
        }
      } else if (targetState == false && currentState === "checked") {
        if (newMarkersToUpdate[markerUUID]) {
          delete newMarkersToUpdate[markerUUID];
        } else {
          newMarkersToUpdate[markerUUID] = false;
        }
      }

      // toggling
      if (targetState === undefined) {
        if (currentState === "checked") {
          if (newMarkersToUpdate[markerUUID]) {
            delete newMarkersToUpdate[markerUUID];
          } else {
            newMarkersToUpdate[markerUUID] = false;
          }
        } else if (currentState === "indeterminate") {
          newMarkersToUpdate[markerUUID] = true;
        } else if (currentState === "unchecked") {
          if (newMarkersToUpdate[markerUUID] === false) {
            delete newMarkersToUpdate[markerUUID];
          } else {
            newMarkersToUpdate[markerUUID] = true;
          }
        }
      }
    });
    setMarkersToUpdate(newMarkersToUpdate);
  };

  const columns = [
    {
      title: () => <b>Episode</b>,
      dataIndex: "title",
      key: "title",
      minWidth: 200,
      render: (_: any, episode: IEpisode) => <TableCellEpisode episode={episode} />,
    },
    ...["PREROLL", "MIDROLL", "POSTROLL"]
      .filter((rollType) => filters[rollType])
      .map((rollType) => ({
        title: () => {
          return (
            <div className="flex-column-container">
              <b>
                <small>{rollType}</small>
              </b>
              <SelectDropdown
                onSelect={(value) => handleSelectBulkOption(value, rollType)}
                options={Object.keys(ASSIGNMENT_OPTIONS)}
                value="Assign To"
              />
            </div>
          );
        },
        dataIndex: rollType,
        key: rollType,
        width: 150,
        render: (_: any, episode: IEpisode) => (
          <TableCellMarkers
            episode={episode}
            markersToUpdate={markersToUpdate}
            audioBlockUUID={audioBlock.uuid}
            rollType={rollType}
            onCellChange={handleSelectCheckbox}
          />
        ),
      })),
  ];

  const data: TEnhancedEpisode[] = useMemo(() => {
    if (episodes) {
      return Object.values(episodes)
        .filter((episode: any) => (filterTerm ? episode.title.includes(filterTerm) : true))
        .sort((a: any, b: any) => a.publishedAt - b.publishedAt)
        .map((episode: any) => ({
          key: episode.uuid,
          ...episode,
          imageURL: episode.imageURL || show.imageURL,
          markers: markersByEpisodeUUID[episode.uuid] || {},
        }));
    }
    return [];
  }, [episodes, markers, filterTerm]);

  return (
    <>
      <Modal.Body>
        <p>
          Select the insertion points where you’d like this audio block to play. To assign in bulk,
          use the dropdowns for each roll position.
        </p>

        <div className="flex-column-container">
          <div className={styles["bulk-assign-toolbar"]}>
            <Select
              size="large"
              placeholder="Select Show"
              className="flex-1"
              onChange={handleSelectShow}>
              {showList.map((show: any) => (
                <Select.Option key={show.uuid} value={show.uuid}>
                  {show.title}
                </Select.Option>
              ))}
            </Select>
            <div className="flex-row-container align-center justify-space-between">
              <div
                className={classNames(
                  styles["bulk-assign-add-remove"],
                  "flex-row-container align-center"
                )}>
                <span>
                  <b>{markersAdded}</b> Added, <b>{markersRemoved}</b> Removed
                </span>
                {Object.keys(markersToUpdate).length > 0 && (
                  <Button type="link" className="p-a0 m-lxs" onClick={() => setMarkersToUpdate({})}>
                    Reset
                  </Button>
                )}
              </div>
              <Button type="link" onClick={() => setFilterDrawer(!showFilterDrawer)}>
                {showFilterDrawer ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              </Button>
            </div>
          </div>

          {showFilterDrawer && (
            <div className={classNames(styles["bulk-assign-toolbar"], "m-txs")}>
              <Input
                type="search"
                allowClear
                placeholder="Filter Episodes..."
                onChange={(e) => setFilterTerm(e.target.value)}
              />
              <div className="flex-row-container align-center">
                {["PREROLL", "MIDROLL", "POSTROLL"].map((rollType) => (
                  <div key={rollType} className="flex-row-container align-center m-ls">
                    <Checkbox
                      checked={filters[rollType]}
                      onChange={() => setFilters({ ...filters, [rollType]: !filters[rollType] })}
                    />
                    <span className="m-lxxs">{rollType}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {isEpisodesLoading && <Loading className="m-ts" />}
          {!isEpisodesLoading && selectedShowUUID && (
            <div className="width-100 m-txs" style={{ overflow: "auto" }}>
              {data.length == 0 && (
                <EmptyStateBlock>
                  {filterTerm ? "No Episodes Found" : "No Episodes"}
                </EmptyStateBlock>
              )}
              {data.length != 0 && (
                <RCTable
                  columns={columns}
                  dataSource={data}
                  pagination={{
                    defaultPageSize: 100,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                  size="small"
                  scroll={{ y: 350 }}
                />
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton
          disabled={isSubmitting || !Object.keys(markersToUpdate).length}
          onClick={handleSubmit}>
          Submit
        </Modal.SubmitButton>
      </Modal.Footer>
    </>
  );
}

const TableCellEpisode = ({ episode }: { episode: IEpisode }) => {
  return (
    <div
      className={classNames(styles["bulk-assign-episode-cell"], "flex-row-container align-center")}>
      <AlbumArt className="m-rxxs" style={{ width: 48 }} src={episode.imageURL} alt="album-art" />
      <b>{episode.title}</b>
    </div>
  );
};

const TableCellMarkers = ({
  episode,
  markersToUpdate,
  audioBlockUUID,
  rollType,
  onCellChange,
}: any) => {
  const { markers } = episode;
  const sorted = Object.values(markers)
    .filter((marker: any) => marker.position == rollType)
    .sort((a: any, b: any) => a.offsetBytes - b.offsetBytes);
  return (
    <div className="flex-row-container flex-wrap">
      {sorted.map((marker: any) => (
        <TriCheckbox
          className="m-l0 m-rxxs"
          key={marker.uuid}
          state={getCellCheckedState(marker as Marker, markersToUpdate, audioBlockUUID)}
          onChange={() => onCellChange(marker.uuid)}
        />
      ))}
    </div>
  );
};
