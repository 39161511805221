import openInNew from "@iconify-icons/mdi/open-in-new";
import Icon from "@iconify/react";
import React from "react";
import { ReactComponent as SVGApple } from "../../../../icons/apple-podcasts.svg";
import { ReactComponent as SVGGoogle } from "../../../../icons/google-podcasts.svg";
import { ReactComponent as SVGSpotify } from "../../../../icons/spotify.svg";
import InfoCard from "../InfoCard";

const items = [
  {
    title: "Apple",
    link: "https://podcastsconnect.apple.com/",
    svg: <SVGApple />,
  },
  {
    title: "Spotify",
    link: "https://podcasters.spotify.com/",
    svg: <SVGSpotify />,
  },
  {
    title: "Google",
    link: "https://podcastsmanager.google.com/",
    svg: <SVGGoogle />,
  },
];

const QuickAccess = () => {
  return (
    <InfoCard
      title={{
        text: "Quick Access to Other Analytics Dashboards",
      }}>
      <div className="analyticsPage-quickaccess">
        {items.map((item) => {
          return (
            <div key={item.title} className="analyticsPage-quickaccess--container">
              <div className="analyticsPage-quickaccess--title">
                {item?.svg}
                <span className="analyticsPage-quickaccess--text">{item.title}</span>
              </div>

              <a
                className="analyticsPage-quickaccess--btn"
                target="_blank"
                href={item?.link}
                rel="noreferrer">
                {`${item.title} Dashboard`}
                <Icon icon={openInNew} />
              </a>
            </div>
          );
        })}
      </div>
    </InfoCard>
  );
};

export default QuickAccess;
