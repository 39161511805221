import fetch from "cross-fetch";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { RECAPTCHA_SITE_TOKEN } from "src/constants/recaptcha";
import { ReduxDispatch } from "src/hooks/redux-ts";
import { IRootState, ValidationError } from "src/reducers/types";
import getAPIPrefix from "../lib/api_prefix";
import { getActionNames } from "../lib/create-action";
import ActionManager, { GetStateFunc } from "./base";

export type ARGS<TRequestBody extends object | string = any> = {
  // Required
  route: string | ((props: IRootState) => string);
  actionName: string;

  // Required for Post Requests
  body?: TRequestBody | ((props: IRootState) => TRequestBody);

  auth?: boolean;
  method?: "get" | "put" | "post" | "delete" | "GET" | "PUT" | "POST" | "DELETE";

  // enable google captcha wrapper, default false
  withCaptcha?: boolean;

  // Stringify body object in request. Default is useRawInput  = false, i.e. always stringify
  useRawInput?: boolean;

  // Customizes Success message and notification type
  successMessage?: string;
  messageSize?: "large" | undefined;

  // Optional request ID, used mostly in stats apis, not sure what else its used as but it flows through the action creators if needed
  requestID?: string;

  // Optional extra data that will be included in all action creators under "data" prop
  actionData?: any;

  // Optional - removes notifications
  silent?: boolean;

  // Optional headers to pass along the fetch call
  // eslint-disable-next-line no-undef
  headers?: HeadersInit;
};

export type OptionalActionManagerArgs = Partial<
  Omit<ARGS, "body" | "actionName" | "method" | "route" | "auth">
>;

const defaultArgs: Partial<ARGS> = {
  method: "get",
  auth: false,
};

class DefaultActionManager<
  SuccessResponse extends object = Record<string, any>,
  RequestBody extends object = Record<string, any>,
  ErrorResponse extends object = Record<string, any>,
> extends ActionManager<SuccessResponse, ARGS<RequestBody>, ErrorResponse> {
  actionNames: ReturnType<typeof getActionNames>;

  constructor({ actionName, ...args }: ARGS<RequestBody>) {
    super({ actionName, ...defaultArgs, ...args });
    if (!actionName) {
      throw Error("actionName must be provided");
    }

    if (!this.args.route) {
      throw Error("route must be provided");
    }

    if ((!this.args.method || this.args.method === "get") && !!this.args.body) {
      throw Error("get requests cannot take a body");
    }
    this.actionNames = getActionNames(actionName);
  }

  defaultPreCallActionCreator = () => () => {
    return {
      type: this.actionNames.started,
      body: this.args.body,
      ...this.defaultActionFields(),
    };
  };

  defaultSuccessActionCreator = () => (json: Partial<SuccessResponse>) => {
    return {
      type: this.actionNames.success,
      payload: {
        resp: json,
        body: this.args.body,
      },
      ...this.defaultActionFields(),
    };
  };

  default400ActionCreator = () => (validationErrors: ValidationError) => {
    return {
      type: this.actionNames.failureBadRequest,
      payload: {
        error: this.defaultErrorMessage(),
        validationErrors: validationErrors,
        body: this.args.body,
      },
      ...this.defaultActionFields(),
    };
  };

  defaultErrorActionCreator = () => (errorMessage?: string) => {
    return this.defaultFailureActionCreator(
      this.actionNames.failure,
      errorMessage || this.defaultErrorMessage()
    );
  };

  defaultActionFields = () => ({
    data: this.args.actionData,
    requestID: this.args.requestID,
  });

  defaultFailureActionCreator = (actionName: string, response: any) => {
    return {
      data: this.args.actionData,
      requestID: this.args.requestID,
      type: actionName,
      payload: {
        error: response.errorMessage || this.defaultErrorMessage(),
        status: response.status,
      },
    };
  };

  preCall(dispatch: ReduxDispatch) {
    dispatch(this.defaultPreCallActionCreator()());
  }

  getBody(getState: GetStateFunc): string {
    if (this.args.useRawInput) {
      if (typeof this.args.body === "function") {
        return this.args.body(getState()) as unknown as string;
      }
      return this.args.body as unknown as string;
    }

    if (typeof this.args.body === "function") {
      return JSON.stringify(this.args.body(getState()));
    }

    return JSON.stringify(this.args.body);
  }

  execute(dispatch: ReduxDispatch, getState: GetStateFunc) {
    let route = this.args.route;
    if (typeof route === "function") {
      route = route(getState());
    }

    const fetchEndpoint = () => {
      return fetch(`${getAPIPrefix()}${route}`, {
        body: this.getBody(getState),
        method: this.args.method,
        headers: {
          Authorization: this.args.auth
            ? `Bearer ${get(getState(), "user.user.authToken", "")}`
            : "",
          ...this.args.headers,
        },
      });
    };

    // Should always be available but check grecaptchha is loaded just in case
    if (this.args.withCaptcha && !!window.grecaptcha && !!window.grecaptcha.execute) {
      return new Promise((resolve, reject) => {
        return window?.grecaptcha
          .execute(RECAPTCHA_SITE_TOKEN, { action: this.args.actionName })
          .then((token: any) => {
            this.args.headers = { ...this.args.headers, Recaptcha: token };
            fetchEndpoint().then(resolve).catch(reject);
          });
      }) as Promise<Response>;
    }

    return fetchEndpoint();
  }

  responseOK(dispatch: ReduxDispatch, json: Partial<SuccessResponse>): any {
    const successMessage = get(this.args, "successMessage", "");
    if (!isEmpty(successMessage)) {
      this.showSuccess(dispatch, successMessage);
    }

    return dispatch(this.defaultSuccessActionCreator()(json));
  }

  response400(dispatch: ReduxDispatch, json: any) {
    if (json.validationErrors && json.validationErrors.length) {
      return dispatch(this.default400ActionCreator()(json.validationErrors));
    }
    this.responseError(dispatch, 400, json);
  }

  responseError(dispatch: ReduxDispatch, statusCode: number, json: any) {
    const errorMessage = json.errorMessage || json.message || this.defaultErrorMessage();
    dispatch(this.defaultErrorActionCreator()(errorMessage));
    this.showError(dispatch, errorMessage);
  }
}

export const defaultActionManagerRunner = <SuccessResponse extends object = Record<string, any>>(
  args: ARGS
) => new DefaultActionManager<SuccessResponse>(args).run();

export default DefaultActionManager;
