import { useEffect } from "react";
import { getCampaignRecommendedShows } from "src/action_managers/show_search";
import { ICampaign } from "src/reducers/campaigns/types";
import { IShow } from "src/reducers/shows";
import { useReduxDispatch, useSelectorTS } from "./redux-ts";

export const useGetCampaignRecommendedShows = (campaign?: ICampaign) => {
  const { listenerDemoRecommendedShows } = useSelectorTS((state) => state.browsePromotion);
  const dispatch = useReduxDispatch();
  const shouldGetRecommendedShows =
    campaign && campaign.uuid && (campaign.targetAges?.length || campaign.targetGender !== "all");

  useEffect(() => {
    if (!shouldGetRecommendedShows) return;

    const filters = {
      ...(campaign.targetGender &&
        campaign.targetGender !== "all" && { primaryGender: campaign.targetGender }),
      ...(campaign.targetAges?.length && { ageBuckets: campaign.targetAges.join(",") }),
      ...(campaign?.style === "hostRead" && { isHostReadEnabled: true }),
      ...(campaign?.style === "campaignAudio" && { isPreRecordedEnabled: true }),
    };

    if (Object.keys(filters).length === 0) return;

    dispatch(
      getCampaignRecommendedShows({
        ...filters,
        minEstimatedWeeklyDownloads: 5000,
      })
    );
  }, [campaign]);

  // filter recommended shows based on campaign target demographics
  const getFilteredRecommendedShows = (recommendedShows: IShow[], campaign?: ICampaign) => {
    if (!recommendedShows || !campaign || !shouldGetRecommendedShows) return [];

    // we'll track scores for each show - each percentage of the target demographic will add to the score
    const showScores: Record<string, number> = {};
    recommendedShows.forEach((show) => {
      const showAgeDemo = show.listenerDemographics?.ageBuckets;
      let ageScore = 0;
      if (campaign.targetAges?.length && showAgeDemo?.length) {
        ageScore = campaign.targetAges.reduce((acc, targetAgeBucket) => {
          const bucket = showAgeDemo.find((bucket) => bucket.label === targetAgeBucket);
          return acc + (bucket?.percentage || 0);
        }, 0);
      }

      const showGenderDemo = show.listenerDemographics?.genderBreakdown;
      let genderScore = 0;
      if (campaign.targetGender && campaign.targetGender !== "all" && showGenderDemo) {
        genderScore = showGenderDemo[campaign.targetGender as keyof typeof showGenderDemo];
      }

      showScores[show.uuid] = ageScore + genderScore;
    });

    // sort by downloads first, then score
    const sortedShows = recommendedShows
      .filter((show) => showScores[show.uuid] > 0)
      .sort((a, b) => {
        const aScore = showScores[a.uuid] || 0;
        const bScore = showScores[b.uuid] || 0;

        if (aScore === bScore) {
          return b.estimatedWeeklyDownloads - a.estimatedWeeklyDownloads;
        } else {
          return bScore - aScore;
        }
      });
    return sortedShows;
  };

  const filteredShows = getFilteredRecommendedShows(listenerDemoRecommendedShows.shows, campaign);
  return { isLoading: listenerDemoRecommendedShows.isLoading, shows: filteredShows };
};
