import React from "react";
import { classNames } from "react-extras";
import { Dropdown } from "src/components/lib/dropdown";
import icon from "../../../icons/ellipses-icon.svg";
import RCButton from "../button/button";
import "./context_menu.scss";

type onSelect = () => void;

type TMenuItem = {
  onSelect: onSelect;
  title?: string;
  disabled?: boolean;
  hide?: boolean;
  toolTipText?: string;
};

export type MenuItems = Record<string, TMenuItem | onSelect>;

interface IProps {
  className?: string;
  noCircle?: boolean;
  text?: string;
  menuItems: MenuItems;
}

export default function ContextMenu({ className, noCircle, text, menuItems }: IProps) {
  return (
    <span
      className={classNames(
        "seedcast-context-menu-wrapper",
        noCircle && "seedcast-context-menu-wrapper--no-circle",
        className
      )}>
      <Dropdown menuItems={menuItems}>
        <RCButton type="link" data-testid="context-menu-button" className="seedcast-context-menu">
          {text || <img src={icon} className="context-menu__ellipsis" alt="menu" />}
        </RCButton>
      </Dropdown>
    </span>
  );
}
