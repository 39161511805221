import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { If } from "react-extras";
import { Field, change, formValueSelector } from "redux-form";
import { ACTIONS, CATEGORIES, gtagEvent } from "../../../lib/google_analytics";
import Autocomplete from "../../forms/autocomplete/autocomplete";
import FormElement from "../../forms/form_element";
import Divider from "../../lib/divider";
import LoadingImage from "../../lib/image";
import "./import_form.scss";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { searchDirectoryShows } from "src/actions/directory_shows";
import { Checkbox } from "antd";

export const IMPORT_FORM_NAME = "feed-import";

interface IImportForm {
  onUpdateCertification: (e: any) => void;
  onSubmit?: () => void;
  close: () => void;
  handleSubmit?: any;
  validationErrors: any;
}

const ImportForm = (props: IImportForm) => {
  const { onUpdateCertification, validationErrors } = props;

  const [formState, setFormState] = useState({
    searchMode: true,
    isLoading: false,
  });

  const dispatch = useReduxDispatch();

  const searchResults = useSelectorTS((state) => state.directoryShows.results);
  const isLoading = useSelectorTS(
    (state) => state.showFeedImport.isLoading || state.episodeFeedImport.isLoading
  );
  const currentRSSValue = useSelectorTS((state) =>
    formValueSelector(IMPORT_FORM_NAME)(state, "feedURL")
  );

  // Helpers
  const searchDirectory = (query: string) => {
    gtagEvent(ACTIONS.SEARCH, CATEGORIES.DIRECTORY_SHOWS);
    return dispatch(searchDirectoryShows(query));
  };

  const setFeedURL = (url: string) => {
    dispatch(change(IMPORT_FORM_NAME, "feedURL", url));
  };

  const toggleFeedInput = (e: any) => {
    e.preventDefault();
    setFormState((prev) => {
      return { ...prev, searchMode: false };
    });
    gtagEvent(ACTIONS.CLICK, CATEGORIES.FEED_INPUT_TOGGLE);
  };

  const renderOwnershipCert = () => {
    return (
      <If condition={Boolean(currentRSSValue)}>
        <div className="import-podcast-checkbox-content flex-row-container m-txs">
          <Checkbox onChange={onUpdateCertification}>
            <span className="m-lxxxs">
              I certify that I own the rights to this podcast and am in compliance with our{" "}
              <a target="_blank" href="https://redcircle.com/terms" rel="noopener noreferrer">
                Terms of Service
              </a>
            </span>
          </Checkbox>
        </div>
      </If>
    );
  };

  return (
    <>
      {formState?.searchMode && (
        <div>
          <Field
            component={FormElement}
            InputComponent={Autocomplete}
            name="showName"
            label="Podcast Name"
            type="text"
            limit={10}
            placeholder="Enter Podcast Name"
            validationErrors={validationErrors}
            suggestions={searchResults || []}
            fetchSuggestions={(value: string) => {
              return searchDirectory(value);
            }}
            isLoading={isLoading}
            clearSuggestions={() => {}}
            getItemValue={(item: any) => {
              return item.trackName;
            }}
            renderItem={(item: any) => {
              return (
                <span key={item.uuid}>
                  <LoadingImage
                    src={item.imageURL}
                    alt="album-art"
                    className={"directory-show-image"}
                  />
                  {item.trackName}
                </span>
              );
            }}
            onSelect={(suggestion: any) => {
              setFeedURL(suggestion.feedURL);
            }}
          />
          {renderOwnershipCert()}
          <Divider marginTop={16} marginBottom={16} />
          Not sure about your podcast name? &nbsp;
          <Button
            className="btn-link import-form__rss-feed-switch"
            data-cy="switch-manual-url"
            onClick={toggleFeedInput}>
            <span className={"bold"}>Enter RSS feed</span>
          </Button>
        </div>
      )}

      {!formState?.searchMode && (
        <div>
          <Field
            component={FormElement}
            name="feedURL"
            label="RSS Feed"
            type="text"
            placeholder="RSS Feed URL"
            validationErrors={validationErrors}
          />
          {renderOwnershipCert()}
          <Divider marginTop={16} marginBottom={16} />
          Not sure about your RSS feed?&nbsp;
          <Button
            className="btn-link import-form__rss-feed-switch bold"
            onClick={(e) => {
              e.preventDefault();
              setFormState((prev) => {
                return { ...prev, searchMode: true };
              });
            }}>
            <span className={"bold"}>Enter podcast name</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default ImportForm;
