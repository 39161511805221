import { Card, Input, Modal } from "antd";
import { isEmpty } from "lodash";
import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { store } from "src";
import { showError, showSuccess, showWarning } from "src/actions/app";
import { getCampaign } from "src/action_managers/campaigns";
import {
  CreateMultipleScriptsResponse,
  UpdateMultipleScriptsResponse,
  createMultipleScripts,
  deleteScript,
  getScriptsForCampaign,
  updateMultipleScripts,
} from "src/action_managers/script";
import FormElement from "src/components/forms/form_element";
import QuillEditor from "src/components/forms/quill_component";
import { RadioInputField } from "src/components/lib/radio-input/radio-input";
import {
  PromoCodeTypeNone,
  PromoCodeTypePodcasterUnique,
  PromoCodeTypeStandard,
} from "src/constants/campaigns";
import { addScriptModalMessages } from "src/constants/notificationMessages";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { CampaignPromoCodeType, ICampaign } from "src/reducers/campaigns/types";
import { Script } from "src/reducers/scripts";
import LoadingButton from "../../forms/loading_button/loading_button";
import Divider from "../../lib/divider";
import "./add_script_modal.scss";
import { AiOutlineClose } from "react-icons/ai";
import { newUUID } from "src/lib/uuid";
import RCButton from "src/components/lib/button/button";
import InfoTooltip from "src/components/lib/info/tooltip";

/**
 * Add Script Stand Alone Modal
 */

type IAddScriptModal = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>> | ((flag: boolean) => void);
  onCancel?: () => void;
  campaignUUID: string;
  scriptUUID?: string;
  type: "create" | "duplicate" | "edit" | "editAll"; // when available
  enableDelete?: boolean;
};

interface ScriptForm {
  key: string;
  scriptUUID?: string;
  name: string;
  script: string;
  scriptTip: string;
  promoCode?: string;
  promoCodeType?: ICampaign["promoCodeType"];
  delete?: true;
}
// Stand Alone Add script Modal
const AddScriptModal = (props: IAddScriptModal) => {
  const { visible, setVisible, onCancel, campaignUUID, scriptUUID, enableDelete, type } = props;

  const campaign = useSelectorTS((state) => state.campaigns?.campaigns?.[campaignUUID]);

  const { scriptsByCampaignUUID } = useSelectorTS((state) => state?.scripts);
  const scripts = scriptsByCampaignUUID[campaignUUID];
  const dispatch = useReduxDispatch();

  const initialState = getAddScriptInitialState({ campaign, scripts, scriptUUID, type });

  const [formState, setFormState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const [isValid] = addScriptValidate(formState);

  // Handlers
  const handleCancel = () => {
    setVisible(false);
    setFormState(getAddScriptInitialState({ campaign, scripts, scriptUUID, type }));
  };

  const submitForm = async () => {
    setIsLoading(true);
    let resp = null;

    try {
      resp = await createOrUpdateScript({
        campaignUUID,
        scripts: formState,
        enableSuccessMessage: false,
      });

      // Once the script has been updated the campaign state would have updated as well, need to refetch it.
      await dispatch(getCampaign(campaignUUID));
    } finally {
      setIsLoading(false);
      setVisible(false);
    }

    return resp;
  };

  // Effects
  useEffect(() => {
    if (campaignUUID?.length > 0) {
      dispatch(getScriptsForCampaign(campaignUUID));
    }
  }, [campaignUUID]);

  useEffect(() => {
    visible && setFormState(getAddScriptInitialState({ campaign, scripts, scriptUUID, type }));
  }, [visible]);

  let modalTitle = "Scripts";

  switch (type) {
    case "create":
      modalTitle = "Create Script";
      break;
    case "duplicate":
      modalTitle = "Duplicate Script";
      break;
    case "edit":
      modalTitle = "Edit Script";
      break;
    case "editAll":
      modalTitle = "Edit Scripts";
      break;
  }

  return (
    <Modal
      wrapClassName="RC-Antd-Override-Modal footer-shadow"
      width={800}
      bodyStyle={{ maxHeight: "60vh" }}
      open={visible}
      centered={true}
      onCancel={() => handleCancel()}
      title={
        <div>
          <h4 className="fs-28 lh-m m-bxxs">{modalTitle}</h4>
          <p className="fs-15 lh-s m-b0">Add the details for your campaign’s script(s). </p>
          <Divider marginTop={16} marginBottom={0} />
        </div>
      }
      footer={
        <div className="flex-row-container align-center justify-space-between">
          <button className="btn btn-link capitalize bold" onClick={() => handleCancel()}>
            cancel
          </button>
          <LoadingButton disabled={!isValid} isLoading={isLoading} onClick={submitForm}>
            Save Scripts
          </LoadingButton>
        </div>
      }>
      <AddScript
        formState={formState}
        setFormState={setFormState}
        enableDelete={enableDelete}
        campaignUUID={campaignUUID}
      />
    </Modal>
  );
};

/**
 * Separated the components of the form to rebuild them outside this Modal, When reading the form to the Multi-step modal
 */

/**
 * Add Script Body
 */

export const AddScript = ({
  formState,
  setFormState,
  title,
  enableDelete,
  campaignUUID,
}: {
  formState: ScriptForm[];
  setFormState: React.Dispatch<React.SetStateAction<ScriptForm[]>>;
  title?: string;
  enableDelete?: boolean;
  campaignUUID: string;
}) => {
  const currentScriptsBeingEdited = formState?.filter((script) => !script?.delete) ?? [];

  const dispatch = useReduxDispatch();

  const campaignItems = useSelectorTS(
    (state) =>
      state?.campaignItems?.campaignItemByCampaignUUID[campaignUUID]?.map(
        (campaignItemUUID) => state.campaignItems?.campaignItems?.[campaignItemUUID]
      ) ?? []
  );

  const scriptIsAssignedToAPodcast = (scriptUUID?: string) => {
    if (typeof scriptUUID === "string" && scriptUUID?.length > 0) {
      return campaignItems?.some((item) => item.scriptUUID === scriptUUID);
    }

    return false;
  };

  // Helper to update form State
  const setScriptState = (propName: keyof ScriptForm, key: string, value: string) => {
    setFormState((prev) => {
      const newResp = [...prev];
      const index = newResp.findIndex((script) => script.key === key);
      if (index >= 0) {
        const oldObject = { ...newResp[index] };
        const newObject = { ...oldObject, [propName]: value };
        newResp[index] = newObject;
      }

      return newResp;
    });
  };

  const addNewScript = () => {
    setFormState((prev) => {
      const newResp = [...prev];
      newResp.push({
        key: newUUID(),
        name: "",
        script: "",
        scriptTip: "",
        promoCodeType: CampaignPromoCodeType.PromoCodeTypeNone,
      });

      return newResp;
    });
  };

  const handleDelete = (scriptUniqueKey: string) => () => {
    const allowDelete = !scriptIsAssignedToAPodcast(scriptUniqueKey);

    if (allowDelete) {
      setFormState((prev) => {
        const newState = [...prev];
        const indexToDelete = newState.findIndex((script) => script.key === scriptUniqueKey);
        if (indexToDelete >= 0) {
          newState[indexToDelete] = { ...newState[indexToDelete], delete: true };
        }

        return newState;
      });
    } else {
      dispatch(
        showWarning("Cannot delete a script that is currently assigned to a podcast.", 5000)
      );
    }
  };

  return (
    <>
      {currentScriptsBeingEdited?.map((scriptState, index) => {
        const showInput = scriptState["promoCodeType"] === PromoCodeTypeStandard;

        const promoCodeTypeOptions: {
          value: ICampaign["promoCodeType"];
          display: ReactNode;
          infoText?: string;
        }[] = [
          {
            value: PromoCodeTypePodcasterUnique,
            display: "Each Podcaster should use their own code or vanity URL tag",
            infoText:
              "You'll be able to download a list of the show's unique codes/URL tags from your Campaign Dashboard when they accept your campaign.",
          },

          {
            value: PromoCodeTypeStandard,
            display: (
              <div className="flex-column-container justify-start align-start">
                <div className="flex-row-container align-center">
                  <span>Promotion code or vanity URL is provided in the script</span>
                </div>

                {showInput && (
                  <Input
                    className="m-txxs"
                    styles={{
                      input: {
                        padding: "0px 5px",
                        width: "100%",
                      },
                    }}
                    value={scriptState["promoCode"]}
                    onChange={(e) => {
                      setScriptState("promoCode", scriptState.key, e?.target?.value ?? "");
                    }}
                  />
                )}
              </div>
            ),
          },
          {
            value: PromoCodeTypeNone,
            display: "No promotion code or vanity URL",
          },
        ];

        return (
          <Card
            className="m-bs"
            title={
              title && (
                <div className="bold fs-15 lh-s">
                  {title} {index + 1}
                </div>
              )
            }
            extra={
              enableDelete ? (
                <AiOutlineClose className="pointer" onClick={handleDelete(scriptState?.key)} />
              ) : undefined
            }
            key={scriptState?.key}>
            <div className="add-script-modal">
              <FormElement
                name="name"
                label="script name"
                type="text"
                placeholder="Name of the New Script"
                value={scriptState["name"]}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setScriptState("name", scriptState.key, e?.target?.value ?? "");
                }}
              />
              <FormElement
                name="script"
                label="Talking points Or Script"
                InputComponent={QuillEditor}
                type="textarea"
                componentClass="textarea"
                placeholder="Provide a script and/or general talking points that you want the podcaster to say. Please make sure there’s enough information for a 0:60 sec read."
                i
                value={scriptState["script"]}
                onChange={(val: string) => {
                  setScriptState("script", scriptState.key, val);
                }}
              />
              <FormElement
                name="scriptTip"
                label="Additional Requirements? (optional)"
                InputComponent={QuillEditor}
                type="textarea"
                componentClass="textarea"
                placeholder="e.g. 60 sec minimum"
                value={scriptState["scriptTip"]}
                onChange={(val: string) => {
                  setScriptState("scriptTip", scriptState.key, val);
                }}
              />
              <p className="uppercase bold fs-11 lh-xs">Promotion Code or vanity url</p>
              <RadioInputField
                value={scriptState["promoCodeType"]}
                defaultValue={undefined}
                className=""
                onChange={(val: ScriptForm["promoCodeType"]) => {
                  val && setScriptState("promoCodeType", scriptState.key, val);
                }}
                options={promoCodeTypeOptions}
              />
            </div>
          </Card>
        );
      })}
      <RCButton className="m-bxs" type="default" onClick={addNewScript}>
        + New Script
      </RCButton>
    </>
  );
};

// Helper Functions

//Helpers

export const createOrUpdateScript = async ({
  campaignUUID,
  scripts,
  enableSuccessMessage = true,
}: {
  campaignUUID: string;
  scripts: ScriptForm[];
  enableSuccessMessage: boolean;
}) => {
  const creates = scripts
    .filter((request) => !request?.scriptUUID && !request?.delete)
    .map((item) => ({
      ...item,
      content: item.script,
      readerGuidance: item.scriptTip,
      campaignUUID,
    }));

  const updates = scripts
    .filter(
      (request) =>
        typeof request?.scriptUUID === "string" && request.scriptUUID.length > 0 && !request?.delete
    )
    .map((item) => ({
      ...item,
      content: item.script,
      readerGuidance: item.scriptTip,
    })) as Required<ScriptForm>[];

  const deletes = scripts.filter(
    (request) =>
      typeof request.scriptUUID == "string" &&
      request.scriptUUID.length > 0 &&
      Boolean(request?.delete)
  );

  try {
    if (deletes.length > 0) {
      for (const item of deletes) {
        if (item.scriptUUID) {
          await store.dispatch(deleteScript(item.scriptUUID));
        }
      }
    }

    let createResponse: CreateMultipleScriptsResponse | null = null;
    let updateResponse: UpdateMultipleScriptsResponse | null = null;

    if (creates.length > 0) {
      createResponse = (await store.dispatch(
        createMultipleScripts(campaignUUID, creates)
      )) as CreateMultipleScriptsResponse;
    }

    if (updates.length > 0) {
      const body = updates.reduce(
        (accu, curr) => {
          const scriptUUID = curr?.scriptUUID;
          accu[scriptUUID] = { ...curr };
          return accu;
        },
        {} as Parameters<typeof updateMultipleScripts>[1]
      );

      updateResponse = (await store.dispatch(
        updateMultipleScripts(campaignUUID, body)
      )) as UpdateMultipleScriptsResponse;
    }

    if (createResponse) {
      enableSuccessMessage &&
        store.dispatch(showSuccess(addScriptModalMessages.Success.CreatedScript));

      if (Array.isArray(createResponse.failures) && createResponse.failures.length > 0) {
        // TODO, based on error messages will update this in staging, for now just show first error
        store.dispatch(showError(createResponse.failures[0].error));
      }
    }

    if (updateResponse) {
      enableSuccessMessage &&
        store.dispatch(showSuccess(addScriptModalMessages.Success.UpdatedScript));

      if (Array.isArray(updateResponse.failures) && updateResponse.failures.length > 0) {
        store.dispatch(showError(updateResponse.failures[0].error));
      }

      return [createResponse, updateResponse];
    }
  } catch (err: unknown) {
    store.dispatch(showError(addScriptModalMessages.Error.CouldNotUpdate));
    throw err;
  }
};

type ScriptInitState = {
  campaign: ICampaign;
  scripts: Script[];
  scriptUUID?: string;
  duplicate?: boolean;
  type: "create" | "duplicate" | "edit" | "editAll";
  initialize?: "empty"; // When type is "editAll" and no scripts currently available. can initialize state as empty or a blank script to start with.
};

export const getAddScriptInitialState: (props: ScriptInitState) => ScriptForm[] = ({
  campaign,
  scripts,
  scriptUUID,
  type,
  initialize,
}) => {
  const { script = "", scriptTip = "", promoCodeType } = campaign || {};
  let newState: ScriptForm[] = [];
  switch (type) {
    case "create":
      newState = [
        {
          key: newUUID(),
          name: "",
          script: "",
          scriptTip: "",
          promoCodeType: CampaignPromoCodeType.PromoCodeTypeNone,
          promoCode: "",
        },
      ];
      break;
    case "duplicate":
      if (typeof scriptUUID == "string" && scriptUUID?.length > 0) {
        const currentScript = scripts.find((script) => script.uuid === scriptUUID);
        if (currentScript) {
          const {
            uuid,
            readerGuidance,
            content,
            name = "",
            promoCodeType,
            promoCode,
          } = currentScript;

          newState = [
            {
              key: uuid,
              name: `${name} (copy)`,
              script: content,
              scriptTip: readerGuidance,
              promoCodeType,
              promoCode,
            },
          ];
        }
      }
      break;
    case "edit":
      if (typeof scriptUUID == "string" && scriptUUID?.length > 0) {
        const currentScript = scripts.find((script) => script.uuid === scriptUUID);
        if (currentScript) {
          const {
            uuid,
            readerGuidance,
            content,
            name = "",
            promoCodeType,
            promoCode,
          } = currentScript;

          newState = [
            {
              key: newUUID(),
              scriptUUID: uuid,
              name: name,
              script: content,
              scriptTip: readerGuidance,
              promoCodeType,
              promoCode,
            },
          ];
        }
      }
      break;
    case "editAll":
      newState =
        scripts?.length > 0
          ? scripts?.map(
              ({ uuid, content, readerGuidance, promoCodeType, name = "", promoCode }) => {
                return {
                  key: uuid,
                  scriptUUID: uuid,
                  name: name,
                  script: content,
                  scriptTip: readerGuidance,
                  promoCodeType,
                  promoCode,
                };
              }
            )
          : initialize === "empty"
            ? []
            : [
                {
                  key: newUUID(),
                  name: "",
                  script,
                  scriptTip,
                  promoCodeType,
                },
              ];
      break;
    default:
      newState = [
        {
          key: newUUID(),
          name: "",
          script,
          scriptTip,
          promoCodeType,
        },
      ];
      break;
  }

  return newState;
};

export const addScriptValidate: (
  formState: ScriptForm[]
) => [boolean, Record<keyof ScriptForm, string>] = (formState) => {
  const errorValidation = formState
    ?.filter((script) => !script?.delete) // filter out script items marked for deletion
    .reduce(
      (accu, scriptState) => {
        const keys = Object.keys(scriptState) as (keyof ScriptForm)[];

        const singleErrorValidation = keys.reduce(
          (accu, key) => {
            switch (key) {
              case "name":
                if (Number(scriptState[key]?.length) === 0) {
                  accu[key] = "Please provide a name for the script";
                }
                return accu;
              case "script":
                if (
                  !scriptState[key] ||
                  Number(scriptState[key]?.length) === 0 ||
                  scriptState[key] === "<p><br></p>"
                ) {
                  accu[key] = "Please provide the script description";
                }
                return accu;

              case "promoCodeType":
                if (
                  typeof scriptState[key] !== "string" ||
                  Number(scriptState[key]?.length) === 0
                ) {
                  accu[key] = "Please choose the promotion code option";
                }
                return accu;
              default:
                return accu;
            }
          },
          {} as Record<keyof ScriptForm, string>
        );

        accu = { ...accu, ...singleErrorValidation };

        return accu;
      },
      {} as Record<keyof ScriptForm, string>
    );

  const isValid = isEmpty(errorValidation);

  return [isValid, errorValidation];
};

export default AddScriptModal;
