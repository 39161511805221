import keyBy from "lodash/keyBy";
import {
  canInstantPayoutActionName,
  getTransactionForUserActionName,
} from "../../action_managers/transactions";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { ReduxTransactionState } from "./types";

const initialState: ReduxTransactionState = {
  isLoading: false,
  userTransactions: [],
  transactions: {},
  canInstantPay: false,
};

const getTransactionForUser = httpReducer(getTransactionForUserActionName, initialState, {
  success: (state, action) => ({
    ...state,
    userTransactions: action.payload.resp,
    transactions: keyBy(action.payload.resp, "uuid"),
  }),
});

const canInstantPay = httpReducer(canInstantPayoutActionName, initialState, {
  success: (state, action) => ({ ...state, canInstantPay: action.payload.resp.enabled }),
});

export default reduceReducers(getTransactionForUser, canInstantPay);
