import React from "react";
import {
  SummaryPageInfo,
  SummaryPageSectionWrapper,
} from "src/components/lib/summary-page-sections/summary-page-sections";
import { CampaignStyleToDisplay } from "src/constants/campaigns";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { targetingOptionsToPlacements } from "./show_campaign_page_util";

export default function ShowCampaignAdDetails({
  campaign,
  campaignItem,
}: {
  campaign: ICampaign;
  campaignItem?: ICampaignItem;
}) {
  if (!campaignItem) return null;
  return (
    <SummaryPageSectionWrapper title={"Advertisement Details"}>
      <>
        <SummaryPageInfo
          title={"Ad Format"}
          text={CampaignStyleToDisplay[campaign.style as keyof typeof CampaignStyleToDisplay]}
          tooltipText={
            "The type of ad audio: either an ad read and recorded by the host, or a pre-recorded ad uploaded by the brand"
          }
        />
        {campaign.requiresEndorsement !== undefined && (
          <SummaryPageInfo
            title={"Personal Endorsement from Podcaster?"}
            noColon
            newline
            text={
              campaign?.requiresEndorsement
                ? "Yes, podcaster will need to personally endorse this product."
                : "No, podcaster is not required to personally endorse this product."
            }
          />
        )}
        <SummaryPageInfo
          title={"Ad Placement"}
          tooltipText={"Where in an episode the audio will be inserted"}
          text={targetingOptionsToPlacements(campaign.targetingOptions)}
        />
        <SummaryPageInfo
          title={"Ad Distribution"}
          tooltipText={"Where on your show the ad will be distributed"}
          subCopy={<AdDistributionCopy campaign={campaign} campaignItem={campaignItem} />}
          text={campaign?.recentEpisodesOnly ? "Recent Episodes" : "All Episodes"}
        />
      </>
    </SummaryPageSectionWrapper>
  );
}

const AdDistributionCopy = ({
  campaign,
}: {
  campaign: ICampaign;
  campaignItem?: ICampaignItem;
}) => {
  if (campaign?.recentEpisodesOnly) {
    return (
      <span style={{ fontSize: 13 }}>
        This advertisement will be distributed across recent episodes on your show where there are{" "}
        {targetingOptionsToPlacements(campaign.targetingOptions)} Ad Insertion Points.
      </span>
    );
  }

  return (
    <span style={{ fontSize: 13 }}>
      This advertisement will be distributed across All Episodes on your show where there are{" "}
      {targetingOptionsToPlacements(campaign.targetingOptions)} Ad Insertion Points. Because the
      brand is buying downloads across your entire catalog, you <strong>do not</strong> have to
      publish new episodes on a specific release schedule.
    </span>
  );
};
