import React, { useEffect, useState } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { hideModal } from "../../actions/modal";
import { getStripeLoginLink } from "../../action_managers/stripe_connect";
import {
  canInstantPayout,
  TransactionWithdrawActionManager,
} from "../../action_managers/transactions";
import { DistributionTypeContinuous } from "../../constants/campaigns";
import { useGetUser, useReduxDispatch, useSelectorTS } from "../../hooks/redux-ts";
import { getPricingPageURL } from "../../lib/config";
import { WithTierProps, withTiers } from "../../lib/tier-utils";
import FormElement from "../forms/form_element";
import ExternalLink from "../lib/external_link";
import { RadioSelectFrameField } from "../lib/radio-select-frame/radio-select-frame";
import BaseModalV2 from "./base_modal_v2";

const TransferToBankModal = () => {
  const dispatch = useReduxDispatch();
  const close = () => {
    dispatch(hideModal());
  };
  useEffect(() => {
    dispatch(canInstantPayout());
  }, []);
  const user = useSelectorTS((state) => state.user.user);
  return (
    <BaseModalV2
      Content={
        <FormContent
          onSubmit={(props: any) => {
            const instant = props.speed === "instant";
            dispatch(new TransactionWithdrawActionManager({ user, instant }).run()).then(
              (resp) => resp.status === 200 && close()
            );
          }}
        />
      }
      close={close}
      columns={10}
      isMini={true}
      modalProps={{}}
      offset={1}
    />
  );
};

const Content = (props: InjectedFormProps & WithTierProps) => {
  const canInstantPay = useSelectorTS((state) => state.transactions.canInstantPay);
  const upperLimitWarning = () => {
    return (
      <span>
        Stripe limits debit card withdrawals to $3,000. To transfer your full balance, connect a
        bank account in your Stripe Account.
      </span>
    );
  };

  const dispatch = useReduxDispatch();
  const user = useSelectorTS((state) => state.user.user);
  const [stripeLink, setStripeLink] = useState("");
  useEffect(() => {
    if (!stripeLink && !canInstantPay) {
      dispatch(getStripeLoginLink(user)).then((resp) => {
        if (resp.status === 200) {
          setStripeLink(resp.json.link);
        }
      });
    }
  }, [stripeLink, canInstantPay]);

  const distributionTypeOptions = [
    {
      tag: "No FEE",
      optionValue: "standard",
      title: "Standard Payout",
      footnote: "Est: 5-7 business days",
    },
    {
      tag: "1% STRIPE fee",
      optionValue: "instant",
      title: "Instant Payout",
      description: "Get paid faster with Instant Payouts. Stripe requires a debit card to use.",
      footnote: "Est: 1 business day",
      warning: upperLimitWarning(),
      disabled: !canInstantPay,
    },
  ];

  const showWarnings = () => {
    if (!props.tier?.level) return;
    if (!props.hasAllowedTier("growth", false)) {
      return (
        <span className={"small color-primary"}>
          This feature is available starting from the RedCircle Growth Plan.{" "}
          <a className={"bold"} href={getPricingPageURL()}>
            Upgrade Your Plan
          </a>
        </span>
      );
    } else if (!canInstantPay) {
      return (
        <span className={"small color-primary"}>
          To access this feature, connect a debit card in your{" "}
          <ExternalLink to={stripeLink} className={"bold"}>
            Stripe Account
          </ExternalLink>
          .
        </span>
      );
    }
  };

  return (
    <Grid fluid>
      <Col xs={10} xsOffset={1}>
        <Row className={"m-bl"}>
          <h2>Transfer To Bank</h2>
          <form onSubmit={props.handleSubmit}>
            <Field
              name={"speed"}
              label={"How do you want to tranfer to bank?"}
              component={FormElement}
              InputComponent={RadioSelectFrameField}
              options={distributionTypeOptions}
              defaultValue={DistributionTypeContinuous}
              required={true}
            />
            <div className={"m-bs"}>{showWarnings()}</div>
            <Button
              disabled={props.pristine}
              type="submit"
              bsStyle={"primary"}
              className={"width-100"}>
              Transfer To Bank
            </Button>
          </form>
        </Row>
      </Col>
    </Grid>
  );
};

const FormContent = reduxForm({
  form: "transfer-to-bank",
})(withTiers(Content));
export default TransferToBankModal;
