import { stringify } from "query-string";
import { statsRequestError, statsRequestStart, statsRequestSuccess } from "../actions/stats";
import statsAPI, { StatsRequestFilterWBody } from "../api/stats";
import { getUserTimezone } from "../lib/timezones";
import ActionManager from "./base";
import DefaultActionManager from "./default_action_manager";
import { ReduxDispatch } from "src/hooks/redux-ts";
import { IRootState } from "src/reducers/types";
import { StatsRequestFilter } from "src/components/pages/analytics/analyticsUtility";
import { User } from "redcircle-types";
import { UnixTimeStamp } from "src/lib/date";

export const fetchGeoStatsActionName = "FETCH_GEO_STATS";
export class DownloadStatsActionManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong fetching stats. Please try again later.";
  }

  preCall(dispatch: ReduxDispatch): void {
    dispatch(statsRequestStart(this.args.requestID));
  }

  execute(dispatch: ReduxDispatch, getState: () => IRootState): Promise<Response> {
    return statsAPI.fetchDownloads({
      filters: this.args.filters,
      user: this.args.user,
      scalar: this.args.scalar,
    });
  }

  responseOK(dispatch: ReduxDispatch, json: Record<string, any>) {
    dispatch(statsRequestSuccess(this.args.requestID, json));
  }

  response400(dispatch: ReduxDispatch, json: any): void {
    this.responseError(dispatch, 400, json);
  }

  responseError(dispatch: ReduxDispatch, statusCode: number, json: any): void {
    const errorMessage = json.errorMessage || json.message || this.defaultErrorMessage();
    dispatch(statsRequestError(this.args.requestID));
    this.showError(dispatch, errorMessage);
  }

  // Handles unknown exceptions.  Override for customizations.
  catchError(dispatch: ReduxDispatch, err?: Error | undefined): void {
    dispatch(statsRequestError(this.args.requestID));
    this.showError(dispatch, this.defaultErrorMessage());
  }
}

export class InsertionStatsActionManager extends DownloadStatsActionManager {
  execute() {
    return statsAPI.fetchInsertions({
      filters: this.args.filters,
      user: this.args.user,
      scalar: this.args.scalar,
    });
  }
}

/**
 * Action Manager for Insertions stats fetch request using request body instead of query params
 */
export class InsertionsStatsWBodyActionManager extends ActionManager<
  { date: UnixTimeStamp; count: number; pathValues: string[] }[], // Success type
  { filters: StatsRequestFilterWBody; requestID: string }, // Sets the 'args' type of this class
  { statusCode: number; message: string } // Error type
> {
  defaultErrorMessage() {
    return "Uh oh, something went wrong fetching stats. Please try again later.";
  }

  preCall(dispatch: ReduxDispatch): void {
    dispatch(statsRequestStart(this.args.requestID));
  }

  execute(dispatch: ReduxDispatch, getState: () => IRootState): Promise<Response> {
    const user = getState()?.user?.user;

    return statsAPI.fetchStatsWithBody({
      endpoint: "insertions",
      filters: this.args.filters,
      user,
    });
  }

  responseOK(dispatch: ReduxDispatch, json: Record<string, any>) {
    dispatch(statsRequestSuccess(this.args.requestID, json));
  }

  response400(dispatch: ReduxDispatch, json: any): void {
    this.responseError(dispatch, 400, json);
  }

  responseError(dispatch: ReduxDispatch, statusCode: number, json: any): void {
    const errorMessage = json.errorMessage || json.message || this.defaultErrorMessage();
    dispatch(statsRequestError(this.args.requestID));
    this.showError(dispatch, errorMessage);
  }

  // Handles unknown exceptions.  Override for customizations.
  catchError(dispatch: ReduxDispatch, err?: Error | undefined): void {
    dispatch(statsRequestError(this.args.requestID));
    this.showError(dispatch, this.defaultErrorMessage());
  }
}

export function fetchGeoStats(filters: StatsRequestFilter, requestID: string) {
  const finalFilters: Partial<StatsRequestFilter> = {
    timezone: getUserTimezone(),
  };
  (Object.keys(filters) as (keyof StatsRequestFilter)[]).forEach((item) => {
    if (filters[item]) {
      finalFilters[item] = filters[item];
    }
  });
  return new DefaultActionManager({
    auth: true,
    route: `stats/downloads-by-geo?${stringify(finalFilters)}`,
    actionName: fetchGeoStatsActionName,
    requestID,
  }).run();
}
