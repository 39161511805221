import { Form, Input, InputNumber, Radio } from "antd";
import { ICampaignItem } from "redcircle-types";
import { Modal } from "redcircle-ui";
import { declineCampaignOffer } from "src/actions/campaigns";
import { useDispatchTS } from "src/hooks/redux-ts";

const DECLINE_REASONS = [
  "Not interested in this brand/product",
  "Currently advertising with a competitor of this brand/product",
  "Offer amount is too low",
];

const DeclineCampaignOfferModal = ({
  campaignItem,
  closeModal,
  history,
}: {
  campaignItem: ICampaignItem;
  closeModal: () => void;
  history: any;
}) => {
  const showUUID = campaignItem?.showUUID;

  const [form] = Form.useForm();
  const reason = Form.useWatch("reason", form);
  const dispatch = useDispatchTS();

  const handleSubmit = () => {
    const reason = form.getFieldValue("reason");
    const acceptableAmount = form.getFieldValue("acceptableAmount");
    const otherReason = form.getFieldValue("otherReason");
    let payloadReason = reason === "Other" ? otherReason : reason;
    if (reason === DECLINE_REASONS[2] && acceptableAmount) {
      payloadReason += `. Minimum acceptable amount: ${acceptableAmount}`;
    }

    if (campaignItem) {
      dispatch(declineCampaignOffer(campaignItem.uuid, payloadReason)).then((res) => {
        if (res.status === 200) {
          history.push(`/shows/${showUUID}/advertising`);
        }
        closeModal();
      });
    }
  };

  return (
    <Modal open onClose={closeModal} size="sm">
      <Modal.TitleSection>
        <h2>Are you sure?</h2>
        <span>
          Once you decline this offer, you will not be able to change your response for this
          campaign.
        </span>
      </Modal.TitleSection>
      <Modal.Body>
        Please let us know why you're declining this campaign:
        <Form form={form} initialValues={{ reason: DECLINE_REASONS[0] }}>
          <Form.Item name="reason" rules={[{ required: true }]}>
            <Radio.Group className="flex-column-container m-lxs p-txs">
              {DECLINE_REASONS.map((reason) => (
                <Radio key={reason} value={reason} className="m-txxs m-bxxs">
                  {reason}
                </Radio>
              ))}
              {reason === DECLINE_REASONS[2] && (
                <Form.Item
                  label="Your minimum acceptable amount (optional)"
                  name="acceptableAmount"
                  className="flex-row-container align-center">
                  <InputNumber prefix="$" precision={2} />
                </Form.Item>
              )}
              <Radio value="Other" className="m-txxs m-bxxs">
                Other (please specify)
              </Radio>
              {reason === "Other" && (
                <Form.Item name="otherReason" rules={[{ required: true }]}>
                  <Input.TextArea className="w-100" autoSize={{ minRows: 2, maxRows: 5 }} />
                </Form.Item>
              )}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton />
        <Modal.SubmitButton onClick={handleSubmit}>Decline Offer</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeclineCampaignOfferModal;
