import { Input, InputProps } from "antd";
import { useState } from "react";
import { Modal } from "redcircle-ui";
import { remindVettingInvitationByCart } from "src/action_managers/vetting";
import { useReduxDispatch } from "src/hooks/redux-ts";

interface ISendVettingReminderModal {
  campaignUUID?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendVettingReminderModal = ({
  campaignUUID = "",
  visible,
  setVisible,
}: ISendVettingReminderModal) => {
  const dispatch = useReduxDispatch();

  const [message, setMessage] = useState<string | undefined>();

  /**
   * Handlers
   */

  const handleChangeMessage: InputProps["onChange"] = (e) => {
    setMessage(e.target.value);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    dispatch(remindVettingInvitationByCart({ campaignUUID, message })).then((resp) => {
      if (resp.status === 200) {
        handleClose();
      }
    });
  };

  return (
    <Modal size="sm" open={visible} onClose={handleClose} onSubmit={handleSubmit}>
      <Modal.Title>Send Reminder to Podcasts</Modal.Title>
      <Modal.Body>
        <p>
          Are you sure you want to send an extra reminder to podcasters? <strong>NOTE:</strong> any
          automatic reminders will be skipped for 48 hours after you send this.
        </p>
        <div className="m-vxxs">
          <label htmlFor="message" className="m-bxxxs fs-15 bold">
            Optional Message
          </label>
          <Input id="message" value={message} onChange={handleChangeMessage} />
        </div>
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton>Send Reminder</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default SendVettingReminderModal;
