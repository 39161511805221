export const TARGETING_RULES_FETCH_START = "TARGETING_RULES_FOR_USER_FETCH_START";
export const TARGETING_RULES_FETCH_SUCCESS = "TARGETING_RULES_FOR_USER_FETCH_SUCCESS";
export const TARGETING_RULES_FETCH_ERROR = "TARGETING_RULES_FOR_USER_FETCH_ERROR";

export const TARGETING_RULE_PUT_START = "TARGETING_RULE_PUT_START";
export const TARGETING_RULE_PUT_SUCCESS = "TARGETING_RULE_PUT_SUCCESS";
export const TARGETING_RULE_PUT_ERROR = "TARGETING_RULE_PUT_ERROR";
export const TARGETING_RULE_PUT_INVALID = "TARGETING_RULE_PUT_INVALID";
export const TARGETING_RULE_PUT_CLEAR = "TARGETING_RULE_PUT_CLEAR";
export const TARGETING_RULE_PUT_SET_TYPE = "TARGETING_RULE_PUT_SET_TYPE";

export function targetingRulesFetchStart() {
  return { type: TARGETING_RULES_FETCH_START };
}

export function targetingRulesFetchSuccess(rules) {
  return { type: TARGETING_RULES_FETCH_SUCCESS, rules };
}

export function targetingRulesFetchError() {
  return { type: TARGETING_RULES_FETCH_ERROR };
}

export function targetingRulePutClear() {
  return { type: TARGETING_RULE_PUT_CLEAR };
}

export function targetingRulePutStart() {
  return { type: TARGETING_RULE_PUT_START };
}

export function targetingRulePutSuccess(rule) {
  return { type: TARGETING_RULE_PUT_SUCCESS, rule };
}

export function targetingRulePutError() {
  return { type: TARGETING_RULE_PUT_ERROR };
}

export function targetingRulePutInvalid(validationErrors) {
  return { type: TARGETING_RULE_PUT_INVALID, validationErrors };
}

export function targetingRulePutSetRuleType(ruleType) {
  return { type: TARGETING_RULE_PUT_SET_TYPE, ruleType };
}
