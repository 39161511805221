import { Select } from "antd";
import Input from "antd/lib/input/Input";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getEpisodeByShow } from "src/action_managers/shows";
import { AlbumArt } from "src/components/lib/album_art";
import EmptyStateBlock from "src/components/lib/empty_state_block";
import { Modal } from "redcircle-ui";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { IEpisode } from "src/reducers/episodes";
import styles from "./insertion_points.module.scss";

interface IProps {
  visible: boolean;
  onClose: () => void;
}

export default function InsertionPointsCreateModal({ visible, onClose }: IProps) {
  const dispatch = useReduxDispatch();
  const { isLoading, shows } = useSelectorTS((state) => state.shows);
  const { isLoading: isEpisodesLoading, ...episodesByShow } = useSelectorTS(
    (state) => state.episodesByShow
  );
  const [selectedShowUUID, setSelectedShowUUID] = useState("");
  const [filterTerm, setFilterTerm] = useState("");
  const showsExist = Object.values(shows).length > 0;
  const nonRemoteShows = Object.keys(shows).reduce((acc, showUUID) => {
    if (shows[showUUID].isRemote) return acc;
    return { ...acc, [showUUID]: shows[showUUID] };
  }, {});
  const isAllShowsRemote = Object.values(shows).every((show) => show.isRemote);

  const episodes: Record<string, IEpisode> = selectedShowUUID
    ? episodesByShow[selectedShowUUID]?.episodes
    : {};

  const episodesList = Object.values(episodes).filter((episode) =>
    filterTerm ? episode.title.includes(filterTerm) : true
  );

  const handleSelectShow = (showUUID: string) => {
    setSelectedShowUUID(showUUID);
    if (!episodesByShow[showUUID]) dispatch(getEpisodeByShow(showUUID));
  };

  return (
    <Modal size="sm" open={visible} onClose={onClose}>
      <Modal.Title>Choose an Episode</Modal.Title>
      <Modal.Body>
        {!showsExist && (
          <EmptyStateBlock>
            You must have at least one podcast to create insertion points.{" "}
            <span>
              <Link to="/shows">Add a podcast</Link> to get started.
            </span>
          </EmptyStateBlock>
        )}
        {showsExist && isAllShowsRemote && (
          <EmptyStateBlock>
            You must have at least one podcast hosted on RedCircle to create insertion points.{" "}
            <span>
              <Link to="/shows">Add a podcast</Link> to get started.
            </span>
          </EmptyStateBlock>
        )}
        {showsExist && !isAllShowsRemote && (
          <Select
            className="width-100 m-bs"
            size="large"
            placeholder="Select Show"
            onChange={handleSelectShow}>
            {Object.keys(nonRemoteShows).map((showUUID) => (
              <Select.Option key={showUUID} value={showUUID}>
                {shows[showUUID].title}
              </Select.Option>
            ))}
          </Select>
        )}

        {selectedShowUUID && (
          <div className="flex-column-container">
            <span className="m-bxxs">
              Select which episode you'd like to add or edit insertion points:
            </span>
            <Input
              className="m-bxs"
              type="search"
              placeholder="Filter Episodes..."
              allowClear
              onChange={(e) => setFilterTerm(e.target.value)}
            />
            <div className={styles["episodes-list-container"]}>
              {episodesList.map((episode) => (
                <Link
                  to={`/dynamic-insertion/insertion-points/episodes/${episode.uuid}`}
                  key={episode.uuid}>
                  <div className={styles["episodes-list"]}>
                    <AlbumArt
                      className="m-rxxs"
                      style={{ width: 48 }}
                      src={episode.imageURL || shows[selectedShowUUID].imageURL}
                      alt="album-art"
                    />
                    <b>{episode.title}</b>
                  </div>
                </Link>
              ))}
              {!Object.values(episodes).length && <EmptyStateBlock>No Episodes</EmptyStateBlock>}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
