import flatMap from "lodash/flatMap";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import moment, { Moment } from "moment-timezone";
import numeral from "numeral";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import BlockFeature from "src/components/lib/block_feature";
import { showError, showInfo } from "../../../../actions/app";
import { DownloadStatsActionManager } from "../../../../action_managers/stats";
import { useReduxDispatch, useSelectorTS } from "../../../../hooks/redux-ts";
import BlockImgSrc from "../../../../images/Block_Download_TimeMap.png";
import { goToPricingPage } from "../../../../lib/config";
import { downloadFile } from "../../../lib/download_file";
import {
  ALL_PODCASTS,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  equalityFunc,
  getRequestHash,
  RequestResponseDataPoint,
  StatsRequestFilter,
  TimeMapDatPoint,
  useTierAnalyticsPerm,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";
import HeatMap from "../RedCircle_graphs/Heatmap";

const timeZone = moment.tz.guess();

const timeValues = [
  "11pm",
  "10pm",
  "9pm",
  "8pm",
  "7pm",
  "6pm",
  "5pm",
  "4pm",
  "3pm",
  "2pm",
  "1pm",
  "12pm",
  "11am",
  "10am",
  "9am",
  "8am",
  "7am",
  "6am",
  "5am",
  "4am",
  "3am",
  "2am",
  "1am",
  "12am",
];

// Utility function for graph
const transformData = (data: RequestResponseDataPoint["json"]) => {
  if (isEmpty(data)) {
    return [];
  }

  const aggregateByday = data.reduce((accu, curr) => {
    const currentMoment = moment(curr?.date * 1000);
    const day = currentMoment.tz(timeZone).format("ddd");
    const hour = currentMoment.tz(timeZone).format("ha");
    if (isEmpty(accu[day])) {
      accu[day] = {};
    }

    if (isEmpty(accu[day][hour])) {
      accu[day][hour] = 0;
    }

    accu[day][hour] += curr.count;

    return accu;
  }, {} as Record<string, Record<string, number>>);

  const newData = flatMap(aggregateByday, (hourMap, day) => {
    return map(hourMap, (downloads, hour) => {
      return {
        day,
        hour,
        downloads,
      };
    });
  });

  return newData?.every((timeslot) => timeslot?.downloads === 0) ? [] : newData;
};

// graph config tooltip formatter

const customTooltip = (title: any, data: any) => {
  const item = data?.[0];
  return `<div class="analyticsPage-GraphTooltip">
  <span class="analyticsPage-GraphTooltip--title">${item?.data?.day} ${item?.data?.hour}</span>
  <div class="analyticsPage-GraphTooltip--item"> 
    <span style="background-color:${item?.color}"> </span>  
    <span>${numeral(item?.data?.downloads).format("0,0a")}</span>
  </div>
  <span class="analyticsPage-GraphTooltip--text">Downloads</span>
  </div>`;
};

const timeMapPrefix = "TimeMap";

interface ITimeMap {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
}

const TimeMap: FunctionComponent<ITimeMap> = ({ dateRange, timeZone, selectedShow }) => {
  const dispatch = useReduxDispatch();
  const user = useSelectorTS((state) => state?.user?.user);
  const tierPermission = useTierAnalyticsPerm();
  const cacheID = getRequestHash(timeMapPrefix, selectedShow, dateRange);

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  const [isDataLoading, setIsDataLoading] = useState(true);

  const dataProvidedToGraph = transformData(statsCacheData);

  //   Handle data fetching for line graph on date range/interval change
  useEffect(() => {
    let id: any;

    if (isEmpty(statsCacheData)) {
      setIsDataLoading(true);
      id = setTimeout(() => {
        let filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
          timezone: timeZone,
          interval: "1h",
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters = { ...filters, showUUID: selectedShow };
        }

        dispatch(
          new DownloadStatsActionManager({
            filters,
            user,
            requestID: getRequestHash(timeMapPrefix, selectedShow, dateRange),
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsDataLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsDataLoading(false);
          });
      }, 250);
    } else {
      setIsDataLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
  ]);

  // Handlers

  const handleReportDownload = () => {
    if (!isDataLoading) {
      const dayMap = dataProvidedToGraph.reduce((accu, curr) => {
        const { day, hour, downloads } = curr;
        if (!accu[day]) {
          accu[day] = {};
        }
        accu[day][hour] = downloads;
        return accu;
      }, {} as Record<string, Record<string, number>>);

      const times = timeValues.reverse();

      const csv = Papa.unparse(
        times.map((time) => {
          return {
            Time: time,
            Monday: dayMap.Mon[time],
            Tuesday: dayMap.Tue[time],
            Wednesday: dayMap.Wed[time],
            Thursday: dayMap.Thu[time],
            Friday: dayMap.Fri[time],
            Saturday: dayMap.Sat[time],
            Sunday: dayMap.Sun[time],
          };
        })
      );
      downloadFile(
        `DownloadTimeOfDayReport_${dateRange?.[0]?.format(
          "YYYY_MM_DD"
        )}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  const downloadSettings = tierPermission.Downloads.DownloadTimeOfDay
    ? {
        allow: tierPermission.General.ExportDataWidgetLevel,
        text: "Download Time of Day Report",
        hanlder: handleReportDownload,
      }
    : undefined;

  return (
    <InfoCard
      title={{
        text: `Download Time of Day`,
        tooltipText:
          "Please select a time range with full weeks to normalize this data (eg Last 7 Days, 30 Days, etc.)",
      }}
      download={downloadSettings}>
      <BlockFeature
        block={!tierPermission.Downloads.DownloadTimeOfDay}
        CTA={{
          text: (
            <span className="h3 default-font m-b0 p-hxs text-center">
              <strong>Download Time of Day</strong> available from Growth Plan.
            </span>
          ),
          btn: {
            text: "Upgrade Your Plan",
            handler: () => goToPricingPage(),
          },
        }}
        blockByImg={{
          src: BlockImgSrc,
          minHeight: "700px",
        }}>
        <div className="analyticsPage-graphContainer--TimeMap">
          <HeatMap<TimeMapDatPoint>
            data={dataProvidedToGraph}
            loading={isDataLoading}
            xField="day"
            yField="hour"
            colorField="downloads"
            tooltip={{
              customContent: customTooltip,
            }}
            meta={{
              day: {
                type: "cat",
                values: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
              },
              hour: {
                type: "cat",
                ticks: [
                  "10pm",
                  "8pm",
                  "6pm",
                  "4pm",
                  "2pm",
                  "12pm",
                  "10am",
                  "8am",
                  "6am",
                  "4am",
                  "2am",
                  "12am",
                ],

                values: timeValues,
              },
              downloads: {
                formatter: (downloads) => numeral(downloads).format("0,0"),
              },
            }}
          />
        </div>
      </BlockFeature>
    </InfoCard>
  );
};

const MemoizedTimeMap = React.memo(TimeMap, equalityFunc);

export default MemoizedTimeMap;
