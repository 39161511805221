/*
 * store.js
 */

import { Store, applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk"; // aka this is your rootReducer
import { isProduction } from "./lib/config";
import mainReducer from "./reducers";
import { IRootState } from "./reducers/types";
import { ReduxDispatch } from "./hooks/redux-ts";

interface RCStore extends Store<IRootState> {
  dispatch: ReduxDispatch;
}

const configureStore = () => {
  let composeEnhancers = compose;
  const hasReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (hasReduxDevTools) {
    composeEnhancers = isProduction()
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25, maxAge: 100 });
  }

  const store = createStore(mainReducer, composeEnhancers(applyMiddleware(thunk))) as RCStore;

  if (!isProduction()) {
    if (module.hot) {
      module.hot.accept("./reducers", () => {
        store.replaceReducer(mainReducer);
      });
    }
  }

  return store;
};
export default configureStore;
