import moment from "moment-timezone";
import React from "react";
import WarningPanelv2 from "../../lib/warning_panel/warning_panel_v2";

const SuccessPage = ({ scriptInCampaign, scriptDueDate }) => {
  const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
  const dueDate = moment.unix(scriptDueDate);
  const today = moment();
  const days = dueDate.diff(today, "days");

  const renderScriptInCampaign = () => (
    <>
      You have <strong>{days} days</strong> to add and assign a script to the podcasts in your
      campaign. Your script is due by{" "}
      <strong>
        {dueDate.format(`M/D/YY hh:mm A`)} {timezone}
      </strong>{" "}
      and can be added from the <strong>Scripts</strong> tab on your campaign dashboard.
      <br />
      <br />
    </>
  );

  return (
    <WarningPanelv2>
      <h3>Next Steps</h3>
      {!scriptInCampaign && renderScriptInCampaign()}
      As podcasts accept your campaign, be sure to <strong>Download Podcast Info</strong> for
      important information that you will need.
      <br />
      <br />
      Depending on your campaign, this could include info like mailing addresses so you can ship
      free products, unique show promo codes so you can add them in your system, and which
      redemption promo codes were matched to which hosts.
      <br />
      <br />
      <strong>Reminder:</strong> The ‘Download Podcast Info’ list will update as podcasts accept, so
      continue to check back and re-export as needed so you don’t miss any show information.
    </WarningPanelv2>
  );
};

export default SuccessPage;
