import DefaultActionManager from "../action_managers/default_action_manager";

export const RESET_CAMPAIGNS = "RESET_CAMPAIGNS";
export const FETCH_SHOW_CAMPAIGNS_ACTION = "FETCH_SHOW_CAMPAIGNS";
export const FETCH_SHOW_CAMPAIGN_ACTION = "FETCH_SHOW_CAMPAIGN";
export const RESPOND_TO_CAMPAIGN_OFFER_ACTION = "RESPOND_TO_CAMPAIGN_OFFER";

export const getShowCampaigns = (showUUID: string) =>
  new DefaultActionManager({
    route: `shows/${showUUID}/campaigns`,
    auth: true,
    actionName: FETCH_SHOW_CAMPAIGNS_ACTION,
    actionData: {
      showUUID,
    },
  }).run();

export const getShowCampaign = (showUUID: string, campaignUUID: string, options = {}) => {
  return new DefaultActionManager({
    route: `shows/${showUUID}/campaigns/${campaignUUID}`,
    auth: true,
    actionName: FETCH_SHOW_CAMPAIGN_ACTION,
    actionData: {
      showUUID,
      campaignUUID,
    },
    silent: options.silent,
  }).run();
};

export const respondToCampaignOffer = ({
  campaignItemUUID,
  state,
  mediaFileUUID,
  feedCTA,
}: {
  campaignItemUUID: string;
  state: string;
  mediaFileUUID?: string;
  feedCTA?: string;
}) => {
  const body = { state, ...(mediaFileUUID && { mediaFileUUID }), feedCTA };
  return new DefaultActionManager({
    route: `campaign-items/${campaignItemUUID}/respond-to-offer`,
    auth: true,
    actionName: RESPOND_TO_CAMPAIGN_OFFER_ACTION,
    actionData: {
      campaignItemUUID,
    },
    body,
    method: "post",
  }).run();
};

export const declineCampaignOffer = (campaignItemUUID: string, reason?: string) => {
  const body = { state: "declined", declinedReason: reason };
  return new DefaultActionManager({
    route: `campaign-items/${campaignItemUUID}/respond-to-offer`,
    auth: true,
    actionName: RESPOND_TO_CAMPAIGN_OFFER_ACTION,
    actionData: {
      campaignItemUUID,
    },
    body,
    method: "post",
  }).run();
};
