// USER
import type { User, UserAttributes, UserRole, TierState, Tier } from "redcircle-types";
export type { User, UserRole, UserAttributes, TierState, Tier } from "redcircle-types";
export interface UserReduxState {
  user: User;
  currentRole: UserRole;
  isLoading: boolean;
  importJobIsLoading?: boolean;
  initialFetchCredInvite: boolean;
  tier: Tier;
  importJobs: IImportJob[];
}

/**
 * core       => TierA
 * growth     => TierB
 * pro        => TierC
 * enterprise => TierD
 * og         => tierOG
 */
export const tierLevel = {
  core: "core",
  growth: "growth",
  pro: "pro",
  enterprise: "enterprise",
  og: "og",
} as const;

export interface IImportJob {
  uuid: string;
  showUUID: string;
  userUUID: string;
  totalEpisodes: number;
  episodesImported: number;
  mediaFilesImported: number;
  expectedMediaFilesImported: number;
  errors: any;
  startedAt: number;
  finishedAt: number;
  state: string;
}
