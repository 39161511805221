import baselineFileCopy from "@iconify/icons-ic/baseline-file-copy";
import { Icon } from "@iconify/react";
import React from "react";
import Clipboard from "react-clipboard.js";
import ExternalLink from "../components/lib/external_link";
import InfoTooltip from "../components/lib/info/tooltip";
import SpotifyDistribution from "../components/lib/spotify_distribution";
import YoutubeButton from "../components/lib/youtube_button";
import { useSelectorTS } from "../hooks/redux-ts";
import amazonImage from "../images/amazon.jpg";
import appleImage from "../images/apple_podcasts.jpg";
import googleImage from "../images/google.png";
import radioPublicImage from "../images/radio_public.jpg";
import spotifyImage from "../images/spotify.png";
import stitcherImage from "../images/stitcher.jpg";
import youtubeImage from "../images/youtube_logo.png";
import { goToPricingPage } from "../lib/config";
import { colors } from "../lib/design";
import { tierLevel } from "../reducers/types";

type DistributionReachLevels = "large" | "medium" | "small";

interface IDistributionReachLevelConfig {
  name: string;
  key: string;
  color: string; // TODO make enum of all design colors
}

export const reach: Record<DistributionReachLevels, IDistributionReachLevelConfig> = {
  large: {
    name: "Large",
    key: "large",
    color: colors.primaryColor,
  },
  medium: {
    name: "Moderate",
    key: "medium",
    color: colors.burgundy,
  },
  small: {
    name: "Small",
    key: "small",
    color: colors.mediumBlue,
  },
};

export const distributionKeyNames = {
  applePodcasts: "applePodcasts",
  spotify: "spotify",
  stitcher: "stitcher",
  youtube: "youtube",
  googlePodcasts: "googlePodcasts",
  radioPublic: "radioPublic",
  amazonMusic: "amazonMusic",
} as const;

interface IInstruction {
  showUUID: string;
  rssFeed: string;
  showSuccess: (msg: string, timeout?: number, large?: boolean) => void;
  distributionConfirmed: boolean;
  distributionName: string;
  distributionLink: string;
}

interface IDistributionConfigItem {
  key: (typeof distributionKeyNames)[keyof typeof distributionKeyNames];
  name: string;
  reach: IDistributionReachLevelConfig;
  image: string;
  placeholder?: string; // confirm input placeholder text
  skipValidation?: boolean; // Used for distribution Banner logic
  forceComplete?: boolean; // force distribution complete status (because recircle handles it automatically)
  tabs: Array<{
    formatTabTitle: typeof formatTabTitle;
    Instruction: JSX.Element[] | React.FC<IInstruction>;
  }>;
  CompletedInstruction: React.FC<IInstruction>;
}

// Utility Helper to set tabTitle
const formatTabTitle = (phrase: string, tabNumber: number): string => {
  switch (tabNumber) {
    case 0:
      return `I'm already on ${phrase}`;
    case 1:
      return `Distribute to ${phrase}`;
    default:
      return `${phrase}`;
  }
};

// Template instructions for Completed distribution
const CompletedInstructionTemplate: React.FC<IInstruction> = ({
  showSuccess,
  distributionName,
  distributionLink,
}) => (
  <div className="distribution-form-row-instructions">
    <p className="distribution-form-row-instructions--text">
      You’ve successfully confirmed your distribution to {distributionName}! 🙌
    </p>
    <Clipboard
      onSuccess={() => {
        showSuccess(`${distributionName} link copied to clipboard`);
      }}
      option-text={() => {
        return `${distributionLink}`;
      }}
      className="distribution-form-row-instructions--clipboard">
      <span>{distributionLink}</span>
      <span>
        <Icon icon={baselineFileCopy} style={{ color: "#577D9E", fontSize: "20px" }} />
        <InfoTooltip
          className={"info-tooltip--no-top-bump"}
          direction={"top"}
          style={{ color: "#969696" }}
          helpText={"Copy to clipboard"}
        />
      </span>
    </Clipboard>
  </div>
);

export const distributionKeys: IDistributionConfigItem[] = [
  {
    key: distributionKeyNames.applePodcasts,
    name: "Apple Podcasts",
    reach: reach.large,
    image: appleImage,
    skipValidation: false,
    placeholder: "https://podcasts.apple.com/us/podcast/podcast-name/id#",
    tabs: [
      {
        formatTabTitle,
        Instruction({ children }) {
          return (
            <div className="distribution-form-row-instructions">
              <h3 className="distribution-form-row-instructions--title">
                Confirm your distribution to apple podcasts
              </h3>
              <ol className="distribution-form-row-instructions--list">
                <li>
                  Paste your show’s Apple Podcasts link below and click <strong>‘Confirm.’</strong>{" "}
                  You can find this link by searching for your show on Apple Podcasts, clicking the
                  three dots ‘...’ then clicking ‘Copy Link.’
                </li>
              </ol>
              {children}
              <ExternalLink
                href="https://support.redcircle.com/distributing-your-podcast-to-apple-podcasts"
                className="distribution-form-row-instructions--link">
                {`Learn More >`}
              </ExternalLink>
            </div>
          );
        },
      },
      {
        formatTabTitle,
        Instruction({ children, rssFeed, showSuccess }) {
          return (
            <>
              <div className="distribution-form-row-instructions">
                <p className="distribution-form-row-instructions--text">
                  You need to have at least one episode published (this can be a trailer) before
                  Apple will register your podcast.
                </p>
                <h3 className="distribution-form-row-instructions--title">
                  Instructions to distribute to apple podcasts
                </h3>
                <ol className="distribution-form-row-instructions--list">
                  <li>
                    Sign in to{" "}
                    <a
                      href="https://podcastsconnect.apple.com/"
                      target="_blank"
                      rel="noopener noreferrer">
                      Apple Podcasts Connect
                    </a>
                    .
                  </li>
                  <li>
                    If you haven’t set up your Apple Podcasts Connect account yet, you’ll be
                    prompted to click <strong>‘Get Started’</strong> then create an Account Name,
                    select your Account Type (Individual or Company), agree to their terms of
                    service, and click <strong>‘Save.’</strong>
                  </li>
                  <li>
                    Once you’re in your Apple Podcasts Connect dashboard, click the plus button{" "}
                    <strong>‘+’</strong> on the top left and select <strong>‘New Show.’</strong>
                  </li>
                  <li>
                    From the Add a Show modal, select{" "}
                    <strong>‘Add existing show (RSS feed).’</strong>
                  </li>
                  <li>
                    Copy your RSS Feed URL below and paste it in the RSS Feed URL text box in Apple
                    Podcasts Connect.
                  </li>

                  <Clipboard
                    onSuccess={() => {
                      showSuccess("RSS Feed copied to clipboard");
                    }}
                    option-text={() => {
                      return `${rssFeed}`;
                    }}
                    className="distribution-form-row-instructions--clipboard">
                    <span>{rssFeed}</span>
                    <span>
                      <Icon
                        icon={baselineFileCopy}
                        style={{ color: "#577D9E", fontSize: "20px" }}
                      />
                      <InfoTooltip
                        className={"info-tooltip--no-top-bump"}
                        direction={"top"}
                        style={{ color: "#969696" }}
                        helpText={"Copy to clipboard"}
                      />
                    </span>
                  </Clipboard>
                  <li>
                    Important: Make sure you keep both the RSS Feed Access selected for ‘Make my
                    feed publicly available’ and User Access selected for ‘Give everyone access.’
                  </li>
                  <li>
                    Once all of that is filled out, click <strong>‘Add.’</strong> Your podcast will
                    now be in Draft state on Apple Podcasts Connect (NOTE: your show is NOT
                    submitted yet). Apple will process your show details which may take up to a few
                    days.
                  </li>
                  <li>
                    You’ll need to go back into your{" "}
                    <a
                      href="https://podcastsconnect.apple.com/"
                      target="_blank"
                      rel="noopener noreferrer">
                      Apple Podcasts Connect
                    </a>{" "}
                    account once your show details are processed and you’ll see a ‘Submit for
                    Review’ button when you click into your show. Before submitting, fill out the
                    remaining information:
                    <ul
                      className="distribution-form-row-instructions--list m-lxxs"
                      style={{ listStyleType: "disc" }}>
                      <li className="m-b0">
                        <strong>Show Information tab:</strong> In the Show Details section fill out
                        Update Frequency and in the Show Contact section fill out Name, Phone
                        Number, and Email
                      </li>
                      <li>
                        <strong>Availability and Rights tab:</strong> Select your Content Rights
                        (Does or does not have third-party content)
                      </li>
                    </ul>
                  </li>
                  <li>
                    Click <strong>‘Save’</strong> and then to finally submit your podcast to Apple,
                    click <strong>‘Submit for Review.’</strong>
                  </li>
                  <li>
                    Apple usually takes a few days to review and list your podcast. Once your
                    podcast is accepted, search for your show on the Apple Podcasts app, click the
                    three dots ‘...’ then click ‘Copy Link.’ Paste the link below and click{" "}
                    <strong>‘Confirm.’</strong>
                  </li>
                </ol>
                {children}
                <ExternalLink
                  href="https://support.redcircle.com/distributing-your-podcast-to-apple-podcasts"
                  className="distribution-form-row-instructions--link">
                  {`Learn More >`}
                </ExternalLink>
              </div>
            </>
          );
        },
      },
    ],
    CompletedInstruction: CompletedInstructionTemplate,
  },
  {
    key: distributionKeyNames.spotify,
    name: "Spotify",
    reach: reach.large,
    image: spotifyImage,
    skipValidation: false,
    placeholder: "https://open.spotify.com/show/2LPKaKt6ICoO8eBk7NLtif",
    tabs: [
      {
        formatTabTitle,
        Instruction({ showSuccess, rssFeed, children }) {
          return (
            <div className="distribution-form-row-instructions">
              <h3 className="distribution-form-row-instructions--title">
                Confirm your distribution to spotify
              </h3>
              <ol className="distribution-form-row-instructions--list">
                <li>
                  If you haven’t already claimed your podcast on{" "}
                  <ExternalLink
                    href="https://podcasters.spotify.com/"
                    className="distribution-form-row-instructions--link">
                    Spotify for Podcasters
                  </ExternalLink>
                  , please sign in and paste your RSS Feed URL. Spotify will send you an email to
                  verify you are the owner, and you will need to enter some metadata about your show
                  (eg country, language, etc). Select <strong>‘RedCircle’</strong> as your hosting
                  provider if prompted.
                </li>
                <Clipboard
                  onSuccess={() => {
                    showSuccess(`RSS Feed link copied to clipboard`);
                  }}
                  option-text={() => {
                    return `${rssFeed}`;
                  }}
                  className="distribution-form-row-instructions--clipboard">
                  <span>{rssFeed}</span>
                  <span>
                    <Icon icon={baselineFileCopy} style={{ color: "#577D9E", fontSize: "20px" }} />
                    <InfoTooltip
                      className={"info-tooltip--no-top-bump"}
                      direction={"top"}
                      style={{ color: "#969696" }}
                      helpText={"Copy to clipboard"}
                    />
                  </span>
                </Clipboard>
                <li>
                  Once you’ve claimed your podcast on{" "}
                  <ExternalLink
                    href="https://podcasters.spotify.com/"
                    className="distribution-form-row-instructions--link">
                    Spotify for Podcasters
                  </ExternalLink>
                  , make sure the <strong>‘Licensor / Hosting Provider’</strong> says{" "}
                  <strong>‘RedCircle’</strong> or our older name <strong>'SeedCast'</strong> (you
                  can find this under{" "}
                  <ExternalLink
                    href="https://podcasters.spotify.com/dash"
                    className="distribution-form-row-instructions--link">
                    'Settings'
                  </ExternalLink>
                  ). If it doesn't, click <strong>'Update'</strong>, re-enter your RSS Feed URL, and
                  select <strong>'RedCircle'</strong> from the hosting provider drop down.
                </li>
                <li>
                  Paste your show’s Spotify link below and click <strong>‘Confirm.’</strong> You can
                  find this link from{" "}
                  <ExternalLink
                    href="https://podcasters.spotify.com/"
                    className="distribution-form-row-instructions--link">
                    Spotify for Podcasters
                  </ExternalLink>{" "}
                  or search for your show on Spotify {">"} click the three dots ‘...’ {">"} ‘Share’{" "}
                  {">"} ‘Copy Show Link.’
                </li>
              </ol>
              {children}
              <ExternalLink
                href="https://support.redcircle.com/distributing-your-podcast-to-spotify"
                className="distribution-form-row-instructions--link">
                {`Learn More >`}
              </ExternalLink>
            </div>
          );
        },
      },
      {
        formatTabTitle,
        Instruction({ showUUID }) {
          return (
            <div className="distribution-form-row-instructions">
              <h3 className="distribution-form-row-instructions--title">
                Instructions to distribute to spotify
              </h3>
              <ol className="distribution-form-row-instructions--list">
                <li>
                  Click <strong>'Confirm'</strong> below and we'll distribute your show to Spotify
                  for you.
                </li>
              </ol>
              <SpotifyDistribution showUUID={showUUID} />
            </div>
          );
        },
      },
    ],
    CompletedInstruction: CompletedInstructionTemplate,
  },
  {
    key: distributionKeyNames.amazonMusic,
    name: "Amazon Music",
    reach: reach.medium,
    skipValidation: true,
    image: amazonImage,
    placeholder: "https://music.amazon.com/podcasts/uuid/podcast-name",
    tabs: [
      {
        formatTabTitle,
        Instruction({ children }) {
          return (
            <div className="distribution-form-row-instructions">
              <h3 className="distribution-form-row-instructions--title">
                Confirm your distribution to amazon music
              </h3>
              <ol className="distribution-form-row-instructions--list">
                <li>
                  Go to{" "}
                  <ExternalLink
                    href="https://music.amazon.com/podcasts"
                    className="distribution-form-row-instructions--link">
                    Amazon Music
                  </ExternalLink>
                  , search for your show at the top of the page, and select your show from the list.
                </li>
                <li>
                  From your show’s Amazon Music page, copy the page’s URL. Paste the link below and
                  click <strong>‘Confirm.’</strong>
                </li>
              </ol>
              {children}
            </div>
          );
        },
      },
      {
        formatTabTitle,
        Instruction({ children, showSuccess, rssFeed }) {
          return (
            <div className="distribution-form-row-instructions">
              <p className="distribution-form-row-instructions--text">
                You need to have at least one episode published (this can be a trailer) before
                Amazon Music will register your podcast.
              </p>
              <h3 className="distribution-form-row-instructions--title">
                Instructions to distribute to amazon music
              </h3>
              <ol className="distribution-form-row-instructions--list">
                <li>
                  To add your podcast to Amazon Music and Audible, fill out{" "}
                  <ExternalLink
                    href="http://amazon.com/podcasters"
                    className="distribution-form-row-instructions--link">
                    Amazon’s podcast form.
                  </ExternalLink>
                </li>
                <li>
                  You will need to input some information about your show. When it asks for your RSS
                  Feed, copy your RSS Feed URL below and paste it in the Amazon form.
                </li>
                <Clipboard
                  onSuccess={() => {
                    showSuccess(`RSS Feed link copied to clipboard`);
                  }}
                  option-text={() => {
                    return `${rssFeed}`;
                  }}
                  className="distribution-form-row-instructions--clipboard">
                  <span>{rssFeed}</span>
                  <span>
                    <Icon icon={baselineFileCopy} style={{ color: "#577D9E", fontSize: "20px" }} />
                    <InfoTooltip
                      className={"info-tooltip--no-top-bump"}
                      direction={"top"}
                      style={{ color: "#969696" }}
                      helpText={"Copy to clipboard"}
                    />
                  </span>
                </Clipboard>
                <li>
                  For the Hosting Provider, please select <strong>‘RedCircle.’</strong>
                </li>
                <li>
                  Agree to the Content License Agreement and submit the form. It will take Amazon 24
                  hours to list your show. If you can’t find your show on Amazon Music after 24
                  hours, please reach out to their Podcast Team directly at podcasters@amazon.com
                  for assistance.
                </li>
                <li>
                  Once your show is listed, go to{" "}
                  <ExternalLink
                    href="https://music.amazon.com/podcasts"
                    className="distribution-form-row-instructions--link">
                    Amazon Music
                  </ExternalLink>
                  , search for your show at the top of the page, and select your show from the list.
                  Copy your show’s page URL, paste the link below, and click{" "}
                  <strong>‘Confirm.’</strong>
                </li>
              </ol>
              {children}
            </div>
          );
        },
      },
    ],
    CompletedInstruction: CompletedInstructionTemplate,
  },
  {
    key: distributionKeyNames.youtube,
    name: "YouTube",
    reach: reach.small,
    image: youtubeImage,
    skipValidation: true,
    tabs: [
      {
        formatTabTitle,
        Instruction() {
          const { tier } = useSelectorTS((state) => state?.user);
          switch (tier.level) {
            case tierLevel.core:
              return (
                <div className="distribution-form-row-instructions">
                  <p className="distribution-form-row-instructions--text">
                    Publishing videos directly to YouTube is available starting from the RedCircle
                    Pro Plan
                  </p>
                  <UpgradeBtn />
                </div>
              );
            case tierLevel.growth:
              return (
                <div className="distribution-form-row-instructions">
                  <p className="distribution-form-row-instructions--text">
                    Publishing videos directly to YouTube is available starting from Pro Plan. Note:
                    in your current plan, we will still generate a video file for you that you can
                    download and manually upload to YouTube.
                  </p>
                  <UpgradeBtn />
                </div>
              );
            case tierLevel.pro:
            case tierLevel.enterprise:
              return (
                <div className="distribution-form-row-instructions">
                  <p className="distribution-form-row-instructions--text">
                    When you distribute to YouTube, we’ll create videos for your episodes and
                    publish them directly to your connected YouTube account.
                  </p>
                  <YoutubeButton />
                </div>
              );
            case tierLevel.og:
              return (
                <div className="distribution-form-row-instructions">
                  <p className="distribution-form-row-instructions--text">
                    Connecting your YouTube account allows us to start generating video versions of
                    your podcast that you can download. Once your show reaches 1,000 weekly
                    downloads, you'll be able to automatically publish the videos straight to
                    YouTube.
                  </p>
                  <YoutubeButton />
                </div>
              );
            default:
              return (
                <div className="distribution-form-row-instructions">
                  <p className="distribution-form-row-instructions--text">
                    Connecting your YouTube account allows us to start generating video versions of
                    your podcast that you can download. Once your show reaches 1,000 weekly
                    downloads, you'll be able to automatically publish the videos straight to
                    YouTube.
                  </p>
                  <YoutubeButton />
                </div>
              );
          }
        },
      },
    ],
    CompletedInstruction() {
      return (
        <div className="distribution-form-row-instructions">
          <p className="distribution-form-row-instructions--text">
            All set! Your YouTube account is connected.
          </p>
          <YoutubeButton />
        </div>
      );
    },
  },
];

const UpgradeBtn = () => (
  <button
    className="btn btn-primary"
    onClick={() => goToPricingPage()}
    style={{
      display: "block",
      margin: "10px auto 0px 0px",
      padding: "6px 16px",
      minWidth: "auto",
    }}>
    Upgrade Plan
  </button>
);
