import { Col, Row, Steps } from "antd";
import { useCallback } from "react";
import Modal, { IModalProps } from "./Modal";
import styles from "./MultiModal.module.scss";

interface IMultiModalProps extends Pick<IModalProps, "size" | "loading"> {
  open?: boolean;
  currentPage?: number;
  pages: Array<{
    id?: string;
    pretitle?: string | JSX.Element;
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    labelSection?: string; // for nav sections
    label?: string | JSX.Element; // for nav labels - JSX elements can be used here for links
    body: string | JSX.Element;
    bodyClassName?: string;
    footer?: string | JSX.Element; // if footer is included, onSubmit will not be called
  }>;
  onClose: () => void;
  onSubmit?: () => void;
  withNav?: boolean;
  maskClosable?: boolean;
  navStyle?: "steps" | "list" | undefined;
}
export type MultiModalPages = IMultiModalProps["pages"];

interface IMultiModalNavProps {
  currentPage: number;
  pages: IMultiModalProps["pages"];
  children: React.ReactNode;
  style?: "dot" | "steps" | "list" | undefined;
}

// Multi-step modal for onboarding, wizards, creation flows, etc.
// this should be a fully controlled component with a currentPage prop
export default function MultiModal({
  open = true,
  currentPage = 0,
  pages,
  onClose,
  navStyle,
  size,
  loading,
  maskClosable = false,
}: IMultiModalProps) {
  const page = pages[currentPage];
  if (!page) return null;

  const children = (
    <>
      <Modal.TitleSection>
        {page.pretitle && <span>{page.pretitle}</span>}
        <h2 className="m-bxxxs">{page.title}</h2>
        {page.subtitle && <span>{page.subtitle}</span>}
      </Modal.TitleSection>
      {page.body && <Modal.Body className={page.bodyClassName}>{page.body}</Modal.Body>}
    </>
  );

  return (
    <Modal open={open} onClose={onClose} size={size} loading={loading} maskClosable={maskClosable}>
      {navStyle ? (
        <NavWrapper currentPage={currentPage} pages={pages} style={navStyle}>
          {children}
        </NavWrapper>
      ) : (
        children
      )}

      {page.footer && <Modal.Footer>{page.footer}</Modal.Footer>}
    </Modal>
  );
}

const NavWrapper = ({ currentPage, pages, children, style }: IMultiModalNavProps) => {
  // do we need to split by sections?
  const sections = pages.reduce(
    (acc, page) => {
      if (page.labelSection) acc[page.labelSection] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );

  const renderSteps = useCallback(() => {
    // check if sections exist
    if (!sections || Object.keys(sections).length === 0) {
      return (
        <Steps
          direction="vertical"
          current={currentPage}
          size="small"
          items={pages.map((page, idx) => ({ title: page.label || page.title }))}
          progressDot={style === "dot"}
        />
      );
    } else {
      return (
        <div>
          {Object.keys(sections).map((section, idx) => {
            const sectionPages = pages.filter((page) => page.labelSection === section);
            return (
              <div key={idx}>
                <h5 className="uppercase color-gray-light font-size-lg m-bxxs">{section}</h5>
                <Steps
                  direction="vertical"
                  current={currentPage}
                  size="small"
                  items={sectionPages.map((page) => ({ title: page.label || page.title }))}
                  progressDot={style === "dot"}
                />
              </div>
            );
          })}
        </div>
      );
    }
  }, [pages]);

  const renderList = useCallback(() => {
    // check if sections exist
    if (!sections || Object.keys(sections).length === 0) {
      return (
        <ol className="list-style-none p-a0">
          {pages.map((page, idx) => {
            return <li key={idx}>{page.label || page.title}</li>;
          })}
        </ol>
      );
    } else {
      return (
        <div>
          {Object.keys(sections).map((section, idx) => {
            const sectionPages = pages.filter((page) => page.labelSection === section);
            return (
              <div key={idx}>
                <h5 className="uppercase color-gray font-size-lg m-bxxs">{section}</h5>
                <ol className="list-style-none p-a0">
                  {sectionPages.map((page, idx) => {
                    return <li key={idx}>{page.label || page.title}</li>;
                  })}
                </ol>
              </div>
            );
          })}
        </div>
      );
    }
  }, [pages]);

  return (
    <Modal.Body>
      <Row>
        <Col xs={24} sm={6} className={styles["sidebar-responsive"]}>
          <Modal.Sidebar>
            <div className={styles.sidebar}>
              {style === "steps" && renderSteps()}
              {style === "list" && renderList()}
            </div>
          </Modal.Sidebar>
        </Col>
        <Col xs={24} sm={18}>
          {children}
        </Col>
      </Row>
    </Modal.Body>
  );
};
