import dayjs from "dayjs";
import Moment from "moment";
import { UnixTimeStamp } from "src/lib/date";
import { ICampaign } from "src/reducers/campaigns/types";
import {
  CampaignStyleHostRead,
  ProductExchangeTypeInstructions,
  ProductExchangeTypeMailing,
} from "../../../constants/campaigns";
import { campaignIsDiscrete } from "../../../lib/campaigns";

export const campaignHasProductExchange = (campaign: ICampaign) => {
  return (
    campaign?.style === CampaignStyleHostRead &&
    (campaign?.productExchangeType === ProductExchangeTypeInstructions ||
      campaign?.productExchangeType === ProductExchangeTypeMailing)
  );
};

/**
 * Clones the original date and sets the clone to 3am EST of that same day.
 *
 * For Campaigns start and estimated end dates, we historically set the date to 3 am Eastern time
 * Unsure of the original reason why and keeping this for consistency
 * @param UnixTimeStamp - The original date as unix time stamp
 * @returns UnixTimeStamp - Returns the unix time stamp of the clone set to 3am EST of the same day
 */
export const cloneDateAs3amEST = (unixTimeStamp: UnixTimeStamp) => {
  const date = dayjs.unix(unixTimeStamp).utcOffset(-5, true).startOf("day").add(3, "h");

  return date.unix();
};

export const getCampaignMinimumStartDate = (campaign: ICampaign) => {
  return dayjs().utcOffset(-5, true).startOf("d").add(10, "day").add(3, "h").unix();
};

export const updateTimeline = (
  campaign: ICampaign,
  setTimeline: React.Dispatch<React.SetStateAction<[Moment.Moment, Moment.Moment] | undefined>>,
  setTopOfPageWarning: React.Dispatch<React.SetStateAction<{ title: string; text: string }>>,
  pageNumber: number
) => {
  const MIN_CAMPAIGN_TIMELINE_IN_DAYS = 30;

  if (!campaign) {
    return;
  }
  const minStartDate = Moment.unix(getCampaignMinimumStartDate(campaign));
  if (campaign?.startsAt && campaign?.estimatedEndsAt && pageNumber === 0) {
    const startsAt = Moment.unix(campaign.startsAt);
    const endsAt = Moment.unix(campaign.estimatedEndsAt);
    if (startsAt.isBefore(minStartDate)) {
      const diff = endsAt.diff(startsAt, "h");
      setTimeline([minStartDate, minStartDate.clone().add(diff, "h")]);
      if (campaignIsDiscrete(campaign)) {
        setTopOfPageWarning({
          title: "Updated timeline",
          text: "We have updated your campaign start date since you last saved this campaign.",
        });
      }
      return;
    }
    setTimeline([startsAt, endsAt]);
  } else if (!campaignIsDiscrete(campaign)) {
    setTimeline([
      minStartDate,
      minStartDate.clone().startOf("d").add(MIN_CAMPAIGN_TIMELINE_IN_DAYS, "d"),
    ]);
  }
};
